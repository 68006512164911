import React, { createContext, FC, useContext } from "react";
import {
  initialState,
  SessionContext,
  useSessionManager,
} from "./SessionManager";
import Loading from "./ui/Loading";

// Make organization and user guaranteed (not null or undefined)
type SessionWithUserContext = Omit<SessionContext, "organization" | "user"> & {
  organization: Omit<NonNullable<SessionContext["organization"]>, "__typename">;
  user: Omit<NonNullable<SessionContext["user"]>, "__typename">;
};

export const useSessionWithUser = () => useContext(Session);

// Extend the base session context with organization and user initialization values
const Session = createContext<SessionWithUserContext>({
  ...initialState,
  organization: { id: "", name: "" },
  user: {
    id: "",
    name: "",
    email: "",
    superuser: false,
    organization: null,
    organizationPermissions: [],
    userInvites: [],
  },
});

const SessionWithUser: FC<{}> = ({ children }) => {
  // Intake the upstream session value and conditionally render
  const sessionValue = useSessionManager();
  const { organization, user } = sessionValue;

  if (!organization || !user) {
    return <Loading variant="circle" />;
  }

  return (
    <Session.Provider value={{ ...sessionValue, organization, user }}>
      {children}
    </Session.Provider>
  );
};

export default SessionWithUser;
