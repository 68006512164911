import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { UtilityMeterReadingModelFragment } from "../api/fragments/UtilityMeterReadingModel.generated";
import { energySourceName } from "../lib/energySource";
import FormattedRange from "../lib/FormattedRange";
import LabelledValue from "../lib/LabelledValue";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";

interface Props {
  entity: UtilityMeterReadingModelFragment;
}

const UtilityMeterDialog: React.FC<Props> = ({ entity }) => {
  const { formatNumber } = useIntl();
  const { utilityMeter, startOn, endOn, cost, quantity, unit } = entity;

  return (
    <Gaps spacing={2}>
      <FormSection label="Meter info">
        <Grid container>
          <Grid item xs={6}>
            <LabelledValue
              title="Meter name"
              value={
                <Typography variant="h6" component="span">
                  {utilityMeter.externalId || "—"}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <LabelledValue
              title="Energy source"
              value={
                <Typography variant="h6" component="span">
                  {energySourceName(utilityMeter.energySource)}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </FormSection>
      <Divider />
      <FormSection label="Billing period">
        <Grid container>
          <Grid item xs={12}>
            <LabelledValue
              title="Date range"
              value={
                <Typography variant="h6" component="span">
                  <FormattedRange
                    from={new Date(startOn)}
                    to={new Date(endOn)}
                  />
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </FormSection>
      <Divider />
      <FormSection label="Reading">
        <Grid container>
          <Grid item xs={6}>
            <LabelledValue
              title="Amount"
              value={
                <Typography variant="h6" component="span">{`${formatNumber(
                  quantity
                )} ${unit}`}</Typography>
              }
            />
          </Grid>
          {!!cost && (
            <Grid item xs={6}>
              <LabelledValue
                title="Cost (USD)"
                value={
                  <Typography variant="h6" component="span">{`${formatNumber(
                    cost.value,
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )}`}</Typography>
                }
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
    </Gaps>
  );
};

export default UtilityMeterDialog;
