import { BuildingUseType } from "../api/graphql";

const titleByType: Record<BuildingUseType, string> = {
  ADULT_EDUCATION: "Adult Education",
  AUTOMOBILE_DEALERSHIP: "Automobile Dealership",
  BANK_BRANCH: "Bank Branch",
  BAR_OR_NIGHTCLUB: "Bar/Nightclub",
  BARRACKS: "Barracks",
  COLLEGE_OR_UNIVERSITY: "College/University",
  CONVENIENCE_STORE_WITH_GAS_STATION: "Convenience Store with Gas Station",
  CONVENIENCE_STORE_WITHOUT_GAS_STATION:
    "Convenience Store without Gas Station",
  CONVENTION_CENTER: "Convention Center",
  COURTHOUSE: "Courthouse",
  DISTRIBUTION_CENTER: "Distribution Center",
  ENCLOSED_MALL: "Enclosed Mall",
  FAST_FOOD_RESTAURANT: "Fast Food Restaurant",
  FINANCIAL_OFFICE: "Financial Office",
  FIRE_STATION: "Fire Station",
  GROCERY_STORE: "Grocery Store",
  HOSPITAL_GENERAL: "Hospital - General",
  HOSPITAL_OTHER: "Hospital - Other",
  HOSPITAL_SPECIALTY: "Hospital - Specialty",
  HOSPITAL_SURGICAL: "Hospital - Surgical",
  HOTEL: "Hotel",
  K12_SCHOOL: "K-12 School",
  LABORATORY: "Laboratory",
  LIBRARY: "Library",
  LIFESTYLE_CENTER: "Lifestyle Center",
  MAILING_CENTER_OR_POST_OFFICE: "Mailing Center/Post Office",
  MEDICAL_OFFICE: "Medical Office",
  MULTIFAMILY_HOUSING: "Multifamily Housing",
  NONREFRIGERATED_WAREHOUSE: "Non-Refrigerated Warehouse",
  OFFICE: "Office",
  OTHER: "Other",
  OTHER_RESTAURANT_OR_BAR: "Other - Restaurant/Bar",
  OUTPATIENT_REHAB_OR_PHYSICAL_THERAPY: "Outpatient Rehab/Physical Therapy",
  POLICE_STATION: "Police Station",
  PRESCHOOL_OR_DAYCARE: "Pre-school/Daycare",
  PRISON_OR_INCARCERATION: "Prison/Incarceration",
  REFRIGERATED_WAREHOUSE: "Refrigerated Warehouse",
  RESIDENCE_HALL_OR_DORMITORY: "Residence Hall/Dormitory",
  RESIDENTIAL_CARE_FACILITY: "Residential Care Facility",
  RESTAURANT: "Restaurant",
  RETAIL_STORE: "Retail Store",
  SELFSTORAGE_FACILITY: "Self-Storage Facility",
  SENIOR_CARE_COMMUNITY: "Senior Care Community",
  SOCIAL_OR_MEETING_HALL: "Social/Meeting Hall",
  STRIP_MALL: "Strip Mall",
  TECHNOLOGY_OR_SCIENCE: "Technology/Science",
  TRANSPORTATION_TERMINAL_OR_STATION: "Transportation Terminal/Station",
  URGENT_CARE_OR_CLINIC_OR_OTHER_OUTPATIENT:
    "Urgent Care/Clinic/Other Outpatient",
  VETERINARY_OFFICE: "Veterinary Office",
  VOCATIONAL_SCHOOL: "Vocational School",
  WHOLESALE_CLUB_OR_SUPERCENTER: "Wholesale Club/Supercenter",
};

const primaryFunctionTitle = (code: BuildingUseType): string =>
  titleByType[code];

export default primaryFunctionTitle;
