import React from "react";
import { useIntl } from "react-intl";
import {
  Area,
  ComposedChart,
  Label,
  Legend,
  LegendPayload,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EnergySource, EnergyUnitType } from "../api/graphql";
import Energy from "../lib/Energy";
import { energySourceName } from "../lib/energySource";
import formatter from "../lib/formatter";
import gradient from "../lib/gradient";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
  yAxisOffset,
} from "../lib/reporting/config";
import { Reference } from "../lib/types/Reports";
import unique from "../lib/unique";
import assignedEnergyColor from "./assignedEnergyColor";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  data: Reference[];
}

type DataPoint = {
  [key in EnergySource]?: number;
} & {
  airTemp: number;
  timestamp: number;
};

export function formatReport(data: Reference[]): DataPoint[] {
  return data
    .map(
      ({
        interval: { daysInPeriod, midpoint },
        energySource,
        airTemp,
        billed,
      }) => ({
        [energySource]:
          new Energy(billed).as(EnergyUnitType.KBTU).quantity / daysInPeriod,
        airTemp: airTemp,
        timestamp: new Date(midpoint).valueOf(),
      })
    )
    .sort((a, b) => a.timestamp - b.timestamp);
}

const TEMP_RANGE = [-25, 120]; // values taken from backend validations
const tempPosition = (n: number): number =>
  (n - TEMP_RANGE[0]) / (TEMP_RANGE[1] - TEMP_RANGE[0]);

const heatGradient = gradient([
  { color: "#0000ff", offset: 0 },
  { color: "#0000ff", offset: tempPosition(20) },
  { color: "#ffffff", offset: tempPosition(60) },
  { color: "#ffffff", offset: tempPosition(70) },
  { color: "#ff0000", offset: tempPosition(100) },
  { color: "#ff0000", offset: 1 },
]);

export function createTemperatureTicks(
  tempMin: number,
  tempMax: number,
  interval: number
) {
  const roundedTempMin = Math.floor(tempMin / interval) * interval;
  const roundedTempMax = Math.ceil(tempMax / interval) * interval;
  const numItems = (roundedTempMax - roundedTempMin) / interval + 1;

  return [...Array(numItems)].map((el, idx) => roundedTempMin + interval * idx);
}

const ReferencePeriodChart: React.FC<Props> = ({ aspect, data }) => {
  const energySources = unique(data.map((p) => p.energySource));
  const report = formatReport(data);
  const intl = useIntl();

  // TODO: <Area /> doesn't render correctly with negative values
  const tempMin = Math.floor(Math.min(...report.map((p) => p.airTemp)));
  const tempMax = Math.ceil(Math.max(...report.map((p) => p.airTemp)));

  const timeMin = report[0].timestamp;
  const timeMax = report[report.length - 1].timestamp;

  const formatLegend: LegendPayload[] = assignedEnergyColor(energySources).map(
    ({ energySource, color }, idx) => {
      return {
        value: energySourceName(energySource),
        type: "line",
        color,
        id: `ID-${idx}`,
      };
    }
  );

  return (
    <ResponsiveContainer aspect={aspect}>
      <ComposedChart data={report}>
        <YAxis
          yAxisId="temperature"
          orientation="right"
          domain={[tempMin, tempMax]}
          ticks={createTemperatureTicks(tempMin, tempMax, 5)}
          interval={0}
          tickLine={false}
          tick={{ fontSize }}
        >
          <Label value="Temperature (°F)" angle={-90} fontSize={fontSize} />
        </YAxis>
        <YAxis
          yAxisId="energy"
          tickFormatter={formatter}
          tickLine={false}
          tick={{ fontSize }}
        >
          <Label
            value="kBTU / Day"
            angle={-90}
            position="insideLeft"
            offset={yAxisOffset}
            fontSize={fontSize}
          />
        </YAxis>
        <XAxis
          type="number"
          domain={[timeMin, timeMax]}
          dataKey="timestamp"
          tickFormatter={(value) =>
            intl.formatDate(value, { year: "numeric", month: "short" })
          }
          tickLine={false}
          tick={{ fontSize }}
        />
        <Tooltip
          isAnimationActive={tooltipAnimationActive}
          content={
            <CustomTooltip
              labelFormatting={(v) => {
                return intl.formatDate(v, { year: "numeric", month: "short" });
              }}
              formatting={formatter}
            />
          }
        />

        <Legend payload={formatLegend} verticalAlign="top" align="center" />
        <defs>
          <linearGradient id="heat-x">
            {report.map(({ timestamp, airTemp }, idx) => (
              <stop
                key={`${timestamp}-${idx}`}
                offset={(timestamp - timeMin) / (timeMax - timeMin)}
                stopColor={heatGradient(tempPosition(airTemp))}
              />
            ))}
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={chartAnimationActive}
          yAxisId="temperature"
          dataKey="airTemp"
          type="monotone"
          fill="url(#heat-x)"
          strokeWidth={1}
          stroke="#aaa"
          name="Air Temperature"
          fillOpacity={1}
        />
        {assignedEnergyColor(energySources).map(({ energySource, color }) => (
          <Line
            isAnimationActive={chartAnimationActive}
            key={energySource}
            type="monotoneX"
            dataKey={energySource}
            yAxisId="energy"
            connectNulls
            stroke={color}
            strokeWidth={3}
            dot
            name={energySourceName(energySource)}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ReferencePeriodChart;
