import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  CardContent,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { FC, useEffect, useMemo, useState } from "react";
import { FormattedDate } from "react-intl";
import { UserModelFragment } from "../api/fragments/UserModel.generated";
import { Maybe, OrganizationRoleType } from "../api/graphql";
import DismissDialog from "../DocumentCenter/DismissDialog";
import clampFileName from "../lib/clampFileName";
import pluckNodes from "../lib/pluckNodes";
import sortAlpha from "../lib/sortAlpha";
import { avatarInitials } from "../OrgDashboard/OrgUsers";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import FormDialog from "../ui/FormDialog";
import MetaData from "../ui/MetaData";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import StripeCard from "../ui/StripeCard";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle, SectionTitle } from "../ui/Typography";
import BuildingSearch from "./BuildingSearch";
import {
  DocumentQueryDocument,
  DocumentQueryQuery,
} from "./DocumentQuery.generated";
import DocumentSharingSkeleton from "./DocumentSharingSkeleton";
import InterruptNavigation from "./InterruptNavigation";
import { SetDocumentPermissionsDocument } from "./SetDocumentPermissions.generated";
import UserSearch from "./UserSearch";

export type Building = DocumentQueryQuery["organization"]["buildings"]["edges"][number]["node"];
export type User = UserModelFragment & {
  role: Maybe<OrganizationRoleType>;
};

interface Props {
  documentId: string;
  onDismiss: () => void;
}

type UnstageEntity<T> = (v: T) => void;

const UserListItem: FC<{
  user: User;
  onUnstage: UnstageEntity<User>;
  isDocumentOwner?: boolean;
}> = ({ user, onUnstage, isDocumentOwner }) => {
  const { user: currentUser } = useSessionWithUser();

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>{avatarInitials(user.name)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography>
            {user.name}{" "}
            {isDocumentOwner && (
              <Chip
                component="span"
                label="Document owner"
                variant="outlined"
                size="small"
              />
            )}
          </Typography>
        }
        secondary={user.email}
      />
      {user.role === OrganizationRoleType.MEMBER &&
        user.id !== currentUser.id &&
        !isDocumentOwner && (
          <ListItemSecondaryAction>
            <Button size="small" onClick={() => onUnstage(user)}>
              Remove
            </Button>
          </ListItemSecondaryAction>
        )}
    </ListItem>
  );
};

const BuildingListItem: FC<{
  building: Building;
  onUnstage: UnstageEntity<Building>;
}> = ({ building, onUnstage }) => {
  return (
    <ListItem>
      <ListItemText
        primary={building.name}
        secondary={
          <Typography variant="caption">
            <MetaData>
              <span>
                {building.address.locality}, {building.address.region}
              </span>
            </MetaData>
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <Button size="small" onClick={() => onUnstage(building)}>
          Remove
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const DocumentSharing: FC<Props> = ({ documentId, onDismiss }) => {
  const { organization } = useSessionWithUser();
  const createToast = useToastContext();

  const [setDocumentPermissions] = useMutation(SetDocumentPermissionsDocument);
  const { data, loading, error } = useQuery(DocumentQueryDocument, {
    variables: {
      organizationId: organization.id,
      documentId,
    },
  });

  const [stagedUsers, setStagedUsers] = useState<User[]>([]);
  const [stagedBuildings, setStagedBuildings] = useState<Building[]>([]);
  const [suggestedUsers, setSuggestedUsers] = useState<User[]>([]);

  useEffect(() => {
    if (data) {
      const {
        users: docUsers,
        buildings: docBuildings,
      } = data.organizationDocument;

      setStagedUsers(docUsers);
      setStagedBuildings(pluckNodes(docBuildings));
    }
  }, [data]);

  // Diffed from the existing users on the document
  const searchableUsers = useMemo(() => {
    if (!data) return [];

    const { users: orgUsers } = data.organization;

    return pluckNodes(orgUsers).filter(
      (u) => !stagedUsers.some((du) => du.id === u.id)
    );
  }, [data, stagedUsers]);

  // Diffed from the existing buildings on the document
  const searchableBuildings = useMemo(() => {
    if (!data) return [];

    const { buildings: orgBuildings } = data.organization;

    return pluckNodes(orgBuildings).filter(
      (b) => !stagedBuildings.some((bu) => bu.id === b.id)
    );
  }, [data, stagedBuildings]);

  if (error) throw error;

  if (loading || !data)
    return (
      <DismissDialog
        fullScreen
        title={<PageTitle>Document permissions</PageTitle>}
        onDismiss={onDismiss}
      >
        <DocumentSharingSkeleton />;
      </DismissDialog>
    );

  const onStageUser = (user: User) => {
    setStagedUsers([...stagedUsers, user]);
  };

  /**
   * Yields those users outside of the currently staged users
   * and document users from the building's permission list
   *
   * We combine document permissions and building permissions
   * to find outliers when a building is added to staging
   */
  const suggestedBuildingUsers = (building: Building): User[] => {
    const combineStagingAndPermissions = data.organizationDocument.users.concat(
      stagedUsers
    );

    return building.permissions
      .map((p) => p.user)
      .filter(
        (p) => !combineStagingAndPermissions.find((user) => user.id === p.id)
      );
  };

  const onStageBuilding = (building: Building) => {
    setSuggestedUsers(suggestedBuildingUsers(building));
    setStagedBuildings([...stagedBuildings, building]);
  };

  const unstageUser = (user: User) => {
    setStagedUsers([...stagedUsers.filter((su) => su.id !== user.id)]);
  };

  const unstageBuilding = (building: Building) => {
    setStagedBuildings([
      ...stagedBuildings.filter((sb) => sb.id !== building.id),
    ]);
  };

  const onSave = async () => {
    try {
      await setDocumentPermissions({
        variables: {
          usersInput: {
            documentId,
            userIds: stagedUsers.map((su) => su.id),
          },
          buildingsInput: {
            documentId,
            buildingIds: stagedBuildings.map((sb) => sb.id),
          },
        },
      });

      onDismiss(); // Close the dialog when preferences saved
      createToast("Sharing preferences were saved", "success");
    } catch (err) {
      createToast(err.message || "Error saving permissions", "error");
    }
  };

  const ownerUsers = stagedUsers.filter(
    (u) => u.role === OrganizationRoleType.OWNER
  );
  const adminUsers = stagedUsers.filter(
    (u) => u.role === OrganizationRoleType.ADMIN
  );
  const memberUsers = stagedUsers.filter(
    (u) => u.role === OrganizationRoleType.MEMBER
  );

  return (
    <DismissDialog
      fullScreen
      onDismiss={onDismiss}
      onConfirm={onSave}
      confirmText="Save preferences"
      title={
        <>
          <PageTitle>Document permissions</PageTitle>
          <Typography variant="body2">
            <MetaData>
              <span>
                {clampFileName(data.organizationDocument.file.filename)}
              </span>
              <span>Created by {data.organizationDocument.createdBy.name}</span>
              <span>
                Last edited{" "}
                <FormattedDate value={data.organizationDocument.updatedAt} />
              </span>
            </MetaData>
          </Typography>
        </>
      }
    >
      <div>
        <InterruptNavigation
          users={[data.organizationDocument.users, stagedUsers]}
          buildings={[
            pluckNodes(data.organizationDocument.buildings),
            stagedBuildings,
          ]}
        />
        {suggestedUsers.length > 0 && (
          <FormDialog
            title="Confirm user permissions"
            submitName="Yes"
            dismissName="No"
            onCancel={() => setSuggestedUsers([])}
            onSubmit={() => {
              setStagedUsers([...suggestedUsers, ...stagedUsers]);
              setSuggestedUsers([]);
            }}
            isSubmitting={false}
          >
            <Typography>
              The following users have access to this building but do not have
              access to this document. Give them document access?
            </Typography>
            <br />
            <Alert icon={false} severity="info">
              <Typography component="ul">
                {suggestedUsers.map((u) => (
                  <Typography component="li" key={u.id}>
                    {u.name}
                    <Typography
                      component="div"
                      variant="caption"
                      color="textSecondary"
                    >
                      {u.email}
                    </Typography>
                  </Typography>
                ))}
              </Typography>
            </Alert>
          </FormDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SectionTitle>Linked users</SectionTitle>
            <StripeCard>
              <CardContent>
                <UserSearch
                  users={searchableUsers}
                  onUserSelect={onStageUser}
                />
                <List>
                  {[ownerUsers, adminUsers, memberUsers].map((collection) => {
                    return [...collection]
                      .sort((a, b) => sortAlpha(a.name, b.name))
                      .map((u) => (
                        <UserListItem
                          user={u}
                          key={u.id}
                          onUnstage={unstageUser}
                          isDocumentOwner={
                            data.organizationDocument.createdBy.id === u.id
                          }
                        />
                      ));
                  })}
                </List>
              </CardContent>
            </StripeCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionTitle>Linked buildings</SectionTitle>
            <StripeCard>
              <CardContent>
                <BuildingSearch
                  buildings={searchableBuildings}
                  onBuildingSelect={onStageBuilding}
                />
                <List>
                  {stagedBuildings.length > 0 ? (
                    [...stagedBuildings]
                      .sort((a, b) => sortAlpha(a.name, b.name))
                      .map((b) => (
                        <BuildingListItem
                          building={b}
                          key={b.id}
                          onUnstage={unstageBuilding}
                        />
                      ))
                  ) : (
                    <PlaceholderContainer>
                      <PlaceholderSegment subheader="This document has no linked buildings" />
                    </PlaceholderContainer>
                  )}
                </List>
              </CardContent>
            </StripeCard>
          </Grid>
        </Grid>
      </div>
    </DismissDialog>
  );
};

export default DocumentSharing;
