const MAX_CHARS = 45;
const REPEATER = "...";
const POST_REPEAT_CHARS = 3;

export const replaceWithRepeater = (str: string, maxChars: number) => {
  if (maxChars >= str.length) return str;
  const pre = str.substring(0, maxChars - REPEATER.length - POST_REPEAT_CHARS);
  const post = str.substring(str.length - POST_REPEAT_CHARS);
  return [pre, REPEATER, post].join("");
};

const clampFileName = (name: string) => {
  if (name.length <= MAX_CHARS) return name;

  if (!name.includes(".")) {
    return replaceWithRepeater(name, MAX_CHARS);
  } else {
    const lastPeriod = name.lastIndexOf(".");
    const extension = name.substring(lastPeriod);
    const nameWithoutExtension = name.substring(0, lastPeriod);

    return `${replaceWithRepeater(
      nameWithoutExtension,
      MAX_CHARS - extension.length
    )}${extension}`;
  }
};

export default clampFileName;
