import { useEffect, useRef, useState } from "react";

/**
 * Toggles a boolean value if the user has pressed the target keys simultaneously
 *
 * @param targetKeys An array of keys the user must press simultaneously
 */
const useToggleKeyPress = (targetKeys: string[]): boolean => {
  const [toggled, setToggled] = useState<boolean>(false);
  const pressedKeys = useRef<Record<string, true>>({});

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (targetKeys.includes(event.key)) {
        pressedKeys.current[event.key] = true;
      }

      // Toggle keysPressed only if the target binding has been pressed
      if (targetKeys.every((tk) => pressedKeys.current[tk])) {
        setToggled(!toggled);
      }
    };

    const onKeyUp = (event: KeyboardEvent) =>
      delete pressedKeys.current[event.key];

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [toggled, targetKeys]);

  return toggled;
};

export default useToggleKeyPress;
