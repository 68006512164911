import { MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import useDeleteEntity from "../lib/useDeleteEntity";
import ConfirmationDialog from "../ui/ConfirmationDialog";
import { useToastContext } from "../ui/ToastProvider";
import { Document } from "./DocumentCenter";
import { TrashDocumentDocument } from "./TrashDocument.generated";

type Props = { doc: Document };

const TrashDocumentMenuItem: React.FC<Props> = ({ doc }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [trashDocument] = useDeleteEntity(TrashDocumentDocument);
  const createToast = useToastContext();

  const onDeleteDoc = async () => {
    try {
      await trashDocument(doc, { input: { documentId: doc.id } });
      createToast("File was successfully moved to trash", "success");
      setOpen(false);
    } catch (err) {
      createToast(err.message || "Trash error", "error");
    }
  };

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>Delete</MenuItem>
      {open && (
        <ConfirmationDialog
          header="Move this document to the trash?"
          description="Trashed documents can be restored for up to 90 days."
          onConfirm={onDeleteDoc}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default TrashDocumentMenuItem;
