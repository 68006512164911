import { darken, lighten } from "@material-ui/core/styles/colorManipulator";
import { EnergySource } from "../api/graphql";

const brand = {
  gray: "#515d61",
  blue: "#0278af",
};

const colors = {
  blue: {
    xxlight: "#f5f8fa",
    xlight: "#bcf8ec",
    light: "#019bd7",
    main: brand.blue,
    dark: "#005b85",
  },
  blueAlt: {
    main: "#69cdff",
  },
  orange: {
    light: "#F8C077",
    main: "#f4a135",
  },
  green: {
    main: "#06ad84",
  },
  gray: {
    xxLight: "#f4f4f4",
    xLight: "#e4e4e4",
    light: "#b0b0b0",
    main: "#808080",
    dark: brand.gray,
  },
  lavender: {
    main: "#b5b4ff",
  },
  red: {
    main: "#ee4266",
  },
  redAlt: {
    main: "#f28383",
  },
  yellow: {
    light: "#fff4e5",
  },
  white: "#ffffff",
  black: "#000000",
};

/** Used for our base and accent colors for primary and secondary navigation components in layout */
export const navigation = {
  primaryNavBase: lighten(brand.gray, 0.3),
  primaryNavAccent: lighten(brand.gray, 0.5),
  primaryNavAccentVariant: lighten(brand.gray, 0.6),
  secondaryNavBase: darken(colors.gray.dark, 0.1),
  secondaryNavAccent: colors.blue.main,
};

export const modelFit = {
  good: "#0278af",
  moderate: "#f4a135",
  inadequate: "#899093",
  missing: "#c9d1d5",
};

export const energySourceColors: Record<EnergySource, string> = {
  ELECTRICITY: colors.blue.main,
  NATURAL_GAS: colors.orange.main,
  PROPANE: colors.orange.light,
  // TODO: Find variations for these, once we start using them in DM adjustments
  STEAM: colors.gray.dark,
  CHILLED_WATER: colors.gray.dark,
  FUEL_OIL2: colors.gray.dark,
};

export default colors;
