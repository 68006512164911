import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { GraphQLError } from "graphql";
import React from "react";
import { useForm } from "react-hook-form";
import {
  descriptionError,
  longTextError,
  nameError,
  textFieldLimits,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import InputChars from "../ui/InputChars";
import { TextField } from "../ui/TextField";

export interface Props {
  name: string;
  description: string;
  notes: string;
  onSubmit: (values: Fields) => void;
  onCancel: () => void;
  error?: GraphQLError;
}

interface Fields {
  name: string;
  description: string;
  notes: string;
}

const validations = ({ name, description, notes }: Fields) => ({
  name: nameError(name),
  description: descriptionError(description),
  notes: longTextError(notes),
});

const useStyles = makeStyles(({ spacing }) => ({
  buttonWidth: {
    minWidth: spacing(12),
  },
}));

const EditModelDetails: React.FC<Props> = ({
  name,
  description,
  notes,
  onSubmit,
  onCancel,
  error,
}) => {
  const { buttonWidth } = useStyles();

  const defaultValues = {
    name,
    description,
    notes,
  };

  const {
    watch,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<Fields>({
    defaultValues,
    resolver: validatorsResolver(validations),
  });

  return (
    <form noValidate>
      <Dialog open maxWidth="sm" fullWidth>
        <DialogTitle>Edit model details</DialogTitle>
        <DialogContent dividers>
          {error && <FormHelperText error>{error.message}</FormHelperText>}
          <TextField
            name="name"
            inputRef={register}
            error={errors.name?.message}
            type="text"
            label="Model name"
            required
            size="small"
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.name.max,
                minLength: textFieldLimits.name.min,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.name.max}
            current={watch("name")}
          />
          <TextField
            inputRef={register}
            error={errors.description?.message}
            name="description"
            type="text"
            label="Description"
            required
            size="small"
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.description.max,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.description.max}
            current={watch("description")}
          />
          <TextField
            inputRef={register}
            error={errors.notes?.message}
            name="notes"
            type="text"
            label="Notes"
            size="small"
            multiline
            rows={4}
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.notes.max,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.notes.max}
            current={watch("notes")}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={buttonWidth}
            color="primary"
            variant="outlined"
            disabled={isSubmitting}
            onClick={onCancel}
            size="small"
          >
            Cancel
          </Button>
          <Button
            className={buttonWidth}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            size="small"
          >
            {isSubmitting ? <CircularProgress size={20} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default EditModelDetails;
