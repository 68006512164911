import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import React, { FC } from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

interface Props {}

const NoTransaction: FC<Props> = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderSegment
        icon={MonetizationOnIcon}
        header="No Transaction"
        subheader="No Transaction has been activated for this building. Send a request
          to support@energyrm.com"
      />
    </PlaceholderContainer>
  );
};

export default NoTransaction;
