import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, { FC, useState } from "react";
import { LogicalOperator } from "./filterReducer";

interface Props {
  value: LogicalOperator;
  onChange: (value: LogicalOperator) => void;
}

const StyledButton = styled(Button)({
  padding: "1px 9px",
  fontSize: "0.7125rem",
  "& .MuiButton-endIcon": {
    marginLeft: 0,
  },
});

const LogicalOperatorDropdown: FC<Props> = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (v: LogicalOperator) => {
    onChange(v);
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        onClick={handleClick}
        size="small"
        endIcon={<ArrowDropDownIcon fontSize="small" />}
      >
        {value}
      </StyledButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl}>
        <MenuItem
          dense
          selected={value === LogicalOperator.AND}
          onClick={() => {
            onClose(LogicalOperator.AND);
          }}
        >
          <ListItemText
            primary={<Typography variant="body1">AND Filtering</Typography>}
            secondary="Includes buildings that have at least the selected energy sources"
          />
        </MenuItem>
        <MenuItem
          dense
          selected={value === LogicalOperator.OR}
          onClick={() => {
            onClose(LogicalOperator.OR);
          }}
        >
          <ListItemText
            primary={<Typography variant="body1">OR Filtering</Typography>}
            secondary="Includes buildings that have any number of the selected energy sources"
          />
        </MenuItem>
        <MenuItem
          dense
          selected={value === LogicalOperator.EXACTLY}
          onClick={() => {
            onClose(LogicalOperator.EXACTLY);
          }}
        >
          <ListItemText
            primary={<Typography variant="body1">EXACT Filtering</Typography>}
            secondary="Includes buildings that only have the selected energy sources; all others are excluded"
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default LogicalOperatorDropdown;
