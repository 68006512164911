import React from "react";
import {
  Bar,
  BarChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EnergyEndUse, EnergySource, EnergyUnitType } from "../api/graphql";
import { endUseName, energyEndUses } from "../lib/endUse";
import Energy from "../lib/Energy";
import { energySourceName } from "../lib/energySource";
import formatter from "../lib/formatter";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
  yAxisOffset,
} from "../lib/reporting/config";
import { EndUse as AnnualizedEndUse } from "../lib/types/Reports";
import unique from "../lib/unique";
import assignedEnergyColor from "./assignedEnergyColor";
import CustomAxisTick from "./CustomAxisTick";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  data: AnnualizedEndUse[];
}

type DataPoint = {
  [key in EnergySource]?: number;
} & { endUse: EnergyEndUse };

export function formatReport(data: AnnualizedEndUse[]): DataPoint[] {
  // annualized consumption per energy source, for each end use
  return energyEndUses.map((endUse) =>
    data
      .filter((d) => d.endUse === endUse)
      .reduce<DataPoint>(
        (memo, { energySource, energy }) => ({
          ...memo,
          [energySource]: new Energy(energy).as(EnergyUnitType.KBTU).quantity,
        }),
        { endUse }
      )
  );
}

const EndUsesChart: React.FC<Props> = ({ data, aspect }) => {
  const sources = unique(data.map((es) => es.energySource));
  const report = formatReport(data);

  return (
    <ResponsiveContainer aspect={aspect}>
      <BarChart data={report} barCategoryGap={20}>
        <XAxis
          dataKey="endUse"
          interval={0}
          tick={(props) => <CustomAxisTick formatter={endUseName} {...props} />}
          tickLine={false}
          axisLine={false}
        />
        <YAxis tickFormatter={formatter} tickLine={false} tick={{ fontSize }}>
          <Label
            value="kBTU"
            position="insideLeft"
            angle={-90}
            offset={yAxisOffset}
            fontSize={fontSize}
          />
        </YAxis>
        <Legend
          verticalAlign="top"
          align="center"
          iconType="circle"
          wrapperStyle={{ top: -10 }}
        />
        <Tooltip
          isAnimationActive={tooltipAnimationActive}
          content={
            <CustomTooltip
              formatting={formatter}
              labelFormatting={(_) => null}
            />
          }
        />
        {assignedEnergyColor(sources).map(({ energySource, color }) => (
          <Bar
            isAnimationActive={chartAnimationActive}
            key={energySource}
            dataKey={energySource}
            stackId="a"
            fill={color}
            name={energySourceName(energySource)}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EndUsesChart;
