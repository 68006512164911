import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
} from "@material-ui/core";
import { defaultStyles, TooltipWithBounds } from "@visx/tooltip";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { adjustmentReason } from "../lib/adjustmentReason";
import displayUnit from "../lib/displayUnit";
import Energy from "../lib/Energy";
import { energySourceName } from "../lib/energySource";
import FormattedRange from "../lib/FormattedRange";
import { CombinedReadingAdjustment } from "./AdjustmentAnnotations";

interface Props {
  top: number | undefined;
  left: number | undefined;
  data: CombinedReadingAdjustment;
}

const TruncateNotes = styled(Typography)({
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
});

const AdjustmentTooltip: FC<Props> = ({ top, left, data }) => {
  const { formatNumber } = useIntl();

  return (
    <TooltipWithBounds
      key={Math.random()}
      top={top}
      left={left}
      style={{
        ...defaultStyles,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: 0,
        paddingTop: 5,
      }}
      offsetLeft={70}
    >
      <Box width={250}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="caption">
            {energySourceName(data.energySource)} adjustments
          </Typography>
          <Typography variant="body1">
            <b>
              <FormattedRange
                from={new Date(data.interval.start)}
                to={new Date(data.interval.end)}
              />
            </b>
          </Typography>
        </Box>
        <List dense>
          {data.adjustments.map((adj, idx, arr) => {
            const { quantity, unit, reason, notes } = adj;

            return (
              <>
                <ListItem key={adj.id}>
                  <ListItemText
                    primary={
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">
                          {adjustmentReason(reason)}
                        </Typography>
                        <Typography variant="body2">
                          {formatNumber(
                            new Energy({ quantity, unit }).quantity
                          )}{" "}
                          {displayUnit(unit)}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Box>
                        <TruncateNotes variant="caption">{notes}</TruncateNotes>
                      </Box>
                    }
                  />
                </ListItem>
                {idx !== arr.length - 1 && <Divider />}
              </>
            );
          })}
        </List>
      </Box>
    </TooltipWithBounds>
  );
};

export default AdjustmentTooltip;
