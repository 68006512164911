import millify from "millify";
import digits from "./digits";

export default function formatter(
  v: number | string | (string | number)[]
): any {
  return Array.isArray(v)
    ? v.map(formatter)
    : typeof v === "number"
    ? millify(digits(v, 3), { precision: 1 })
    : v;
}
