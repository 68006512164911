/**
 * groupBy will group an array of objects by a defined non-unique key.
 */
export default function groupBy<O, K extends string | number>(
  objs: O[],
  keyFn: (o: O) => K
): Record<K, O[]> {
  const result: Record<string | number, O[]> = {};
  for (const obj of objs) {
    const key = keyFn(obj);
    result[key] = result[key] ?? [];
    result[key].push(obj);
  }
  return result;
}
