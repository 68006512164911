import {
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import safeNumber from "../lib/safeNumber";

export type ControlledNumberFieldProps = Omit<
  TextFieldProps,
  | "value"
  | "onChange"
  | "defaultValue"
  | "error"
  | "helperText"
  | "type"
  | "size"
  | "InputProps"
> & {
  value: number | null | undefined;
  onChange: (v: number) => void;
  errorMsg?: string;
  precision?: number;
  prepend?: string;
  append?: string;
};

const StyledTextField = styled(TextField)(({ theme: { spacing } }) => ({
  maxWidth: spacing(15),
  "& .MuiInput-input": {
    textAlign: "right",
    "&::-webkit-inner-spin-button": {
      appearance: "none",
      margin: 0,
    },
    "&::-webkit-outer-spin-button": {
      appearance: "none",
      margin: 0,
    },
  },
}));

export const ControlledNumberField: FC<ControlledNumberFieldProps> = ({
  errorMsg,
  prepend,
  append,
  value,
  onChange,
  precision,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string | null>(null);

  const maybeRound = (n: number): number =>
    precision
      ? Math.round(n * Math.pow(10, precision)) / Math.pow(10, precision)
      : n;

  return (
    <StyledTextField
      type="number"
      error={!!errorMsg}
      helperText={errorMsg}
      size="small"
      onFocus={(e) => e.target.select()}
      onChange={(e) => {
        // If we get allowed `type="number"` characters like `-`, see if they are NaN first
        const tryParse = parseFloat(e.target.value);

        // Ex. If we get a negative sign, don't invoke onChange and instead
        // set an internal holding value
        if (isNaN(tryParse)) {
          setInternalValue(e.target.value);
          // Otherwise we definitely have a number and we invoke onChange
          // and reset the internal value so we can derive the real value
        } else {
          const num = Number(e.target.value);
          if (safeNumber(num)) {
            onChange(maybeRound(num));
            setInternalValue(null);
          }
        }
      }}
      InputProps={{
        startAdornment: prepend ? (
          <InputAdornment position="start">{prepend}</InputAdornment>
        ) : undefined,
        endAdornment: append ? (
          <InputAdornment position="end">{append}</InputAdornment>
        ) : undefined,
      }}
      value={
        // Listen for the internal value being `null`
        // If it's not null, then we know the user is trying to
        // prepend their input with a negative (or decimal)
        internalValue !== null
          ? internalValue
          : value == null
          ? ""
          : maybeRound(value)
      }
      {...props}
    />
  );
};

type NumberFieldProps = Omit<
  ControlledNumberFieldProps,
  "value" | "onChange"
> & {
  control: Control;
  name: string;
  defaultValue?: number;
  rules?: RegisterOptions;
};

/**
 * NumberField is a numeric input type that renders decimals as %. By default it has a precision of 2.
 */
const NumberField: React.FC<NumberFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  id,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || null}
      render={({ value, onChange }) => (
        <ControlledNumberField
          value={value}
          onChange={onChange}
          id={id || name}
          {...props}
        />
      )}
    />
  );
};
export default NumberField;
