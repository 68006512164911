import { CardContent } from "@material-ui/core";
import React from "react";
import Card from "../ui/Card";

export interface Props {}

const DeltaMeterChartCard: React.FC<Props> = ({ children }) => {
  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default DeltaMeterChartCard;
