import React, { FC, useState } from "react";
import { DeleteBuildingPermissionDocument } from "../BuildingPermission";
import useDeleteEntity from "../lib/useDeleteEntity";
import Button from "../ui/Button";
import ConfirmationDialog from "../ui/ConfirmationDialog";
import { useToastContext } from "../ui/ToastProvider";
import { BuildingPermission } from "./UserBuildings";

interface Props {
  permission: BuildingPermission;
}

const RevokeAccessConfirmation: FC<Props> = ({ permission }) => {
  const createToast = useToastContext();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [revokeAccessQuery] = useDeleteEntity(DeleteBuildingPermissionDocument);

  const onRevoke = async () => {
    try {
      await revokeAccessQuery(permission, {
        input: {
          buildingId: permission.building.id,
          userId: permission.user.id,
        },
      });
      createToast("Access to building was revoked", "success");
    } catch (err) {
      createToast(err.message, "error");
    }
  };

  return (
    <>
      {showDialog && (
        <ConfirmationDialog
          header="Revoke access?"
          description="This user will no longer have access to this building"
          onConfirm={() => onRevoke()}
          onClose={() => setShowDialog(false)}
        />
      )}
      <Button size="small" onClick={() => setShowDialog(true)}>
        Revoke access
      </Button>
    </>
  );
};

export default RevokeAccessConfirmation;
