import { useMutation } from "@apollo/client";
import React from "react";
import ConfirmationDialog from "../ui/ConfirmationDialog";
import { useToastContext } from "../ui/ToastProvider";
import { ResendUserInviteDocument } from "./ResendUserInvite.generated";

type Props = {
  email: string;
  name: string;
  userInviteId: string;
  onClose: () => void;
  onError?: () => Promise<unknown>;
};

const ResendUserInviteConfirmation: React.FC<Props> = ({
  email,
  name,
  userInviteId,
  onClose,
  onError,
}) => {
  const [resendInvite] = useMutation(ResendUserInviteDocument);
  const createToast = useToastContext();

  const doResend = async () => {
    try {
      await resendInvite({
        variables: { input: { userInviteId } },
      });
      createToast("Invite has been sent!", "success");
    } catch (e) {
      if (onError) {
        await onError();
      }
      createToast(e.message, "error");
    }

    onClose();
  };

  return (
    <ConfirmationDialog
      header="Resend Invite?"
      description={`Resend the invitation link to ${name} at ${email}? The invite will be valid for 7 days.`}
      onConfirm={doResend}
      onClose={onClose}
      confirmText="Resend"
    />
  );
};

export default ResendUserInviteConfirmation;
