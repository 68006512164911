import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import React, { FC, useState } from "react";
import { EnergyUnitType } from "../api/graphql";
import { useToastContext } from "../ui/ToastProvider";
import EditAdjustmentDialog, { Fields } from "./EditAdjustmentDialog";
import { UpdateDeltaMeterAdjustmentDocument } from "./UpdateDeltaMeterAdjustment.generated";

interface Props {
  deltaMeterAdjustmentId: string;
  defaultFields: Fields;
  adjustmentUnit: EnergyUnitType;
}

const EditAdjustmentButton: FC<Props> = ({
  deltaMeterAdjustmentId,
  defaultFields,
  adjustmentUnit,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [editAdjustment, { error: editAdjustmentError }] = useMutation(
    UpdateDeltaMeterAdjustmentDocument
  );

  const createToast = useToastContext();

  const onSubmit = async ({ notes }: Fields) => {
    try {
      await editAdjustment({
        variables: {
          input: {
            deltaMeterAdjustmentId,
            notes,
          },
        },
      });

      setShowDialog(false);
      createToast("Adjustment successfully edited", "success");
    } catch (err) {
      createToast(err.message, "error");
    }
  };

  return (
    <>
      {showDialog && (
        <EditAdjustmentDialog
          onSubmit={(fields) => onSubmit(fields)}
          onCancel={() => setShowDialog(false)}
          error={editAdjustmentError?.graphQLErrors[0]}
          defaultFields={defaultFields}
          adjustmentUnit={adjustmentUnit}
        />
      )}
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setShowDialog(true)}
      >
        Edit notes
      </Button>
    </>
  );
};

export default EditAdjustmentButton;
