import { Typography } from "@material-ui/core";
import React, { ReactNode } from "react";

/** A simple title label on top of a value */
const LabelledValue: React.FC<{ title: string; value: ReactNode }> = ({
  title,
  value,
}) => {
  return (
    <>
      <Typography variant="caption">{title}</Typography>
      <Typography>{value}</Typography>
    </>
  );
};

export default LabelledValue;
