import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { acceptInvite, withQuery } from "../lib/endpoints";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import { TablePlaceholder } from "../ui/PlaceholderSegment";
import { SectionTitle } from "../ui/Typography";

const MyUserInvites: React.FC = () => {
  const { user } = useSessionWithUser();
  const history = useHistory();
  if (!user) return <></>;
  return (
    <>
      <SectionTitle>Pending Organization Invites</SectionTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Organization</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user.userInvites.length > 0 ? (
            user.userInvites.map(({ id, organizationName, email }) => (
              <TableRow key={id}>
                <TableCell>{organizationName}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      history.push(withQuery(acceptInvite(), { inviteId: id }))
                    }
                  >
                    ACCEPT
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TablePlaceholder
              colSpan={3}
              subheader="You have no pending invites."
            />
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default MyUserInvites;
