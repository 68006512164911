import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import React from "react";
import { useLocation } from "react-router-dom";
import Loading from "../ui/Loading";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import ProductCopy from "../ui/ProductCopy";
import { PageTitle } from "../ui/Typography";
import { BuildingInsightsQueryDocument } from "./BuildingInsightsQuery.generated";
import BuildingInsightsReport from "./BuildingInsightsReport";
import FitnessAlert from "./FitnessAlert";

interface Props {
  id: string;
}

interface LocationState {
  calibratedModelId?: string;
  adjustedModelId?: string;
}

const Building: React.FC<Props> = ({ id }) => {
  const location = useLocation<LocationState>();

  const { data, loading, error } = useQuery(BuildingInsightsQueryDocument, {
    variables: {
      id,
      calibratedModelID: location.state?.calibratedModelId || null,
      adjustedModelID: location.state?.adjustedModelId || null,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (error) throw error;
  if (loading || !data) return <Loading variant="circle" />;

  const { building } = data;

  return (
    <>
      {building.calibratedModel && (
        <FitnessAlert fit={building.calibratedModel.fit} />
      )}
      {!data.building.calibratedModel ? (
        <>
          <PageTitle>{ProductCopy.ENERGY_ANALYTICS}</PageTitle>
          <PlaceholderContainer>
            <PlaceholderSegment
              icon={AssessmentIcon}
              header={`${ProductCopy.ENERGY_ANALYTICS} unavailable`}
              subheader={`Generate energy models to view your building's ${ProductCopy.ENERGY_ANALYTICS.toLowerCase()}.`}
            />
          </PlaceholderContainer>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <BuildingInsightsReport
              building={data.building}
              initialModel={data.building.calibratedModel}
              comparisonModel={data.building.calibratedModel!.adjustedModel!}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Building;
