import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  makeStyles,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import ApplicationNavigation, {
  applicationNavigationWidth,
} from "./ApplicationNavigation";
import EnergyRM from "./energyrm-logo_small.svg";
import OrganizationSwitcher from "./OrganizationSwitcher";
import { useSessionWithUser } from "./SessionWithUser";
import colors from "./ui/colors";
import ErrorBoundary from "./ui/ErrorBoundary";

interface Props {
  disablePadding?: boolean;
}

export const appBarHeight = 48;

const useDesktopLayout = makeStyles((theme) => ({
  container: {
    height: "100vh",
    display: "grid",
    gridTemplateAreas: `
      "ApplicationHeader ApplicationHeader"
      "ApplicationNav    ApplicationContent"
    `,
    gridTemplateRows: `${appBarHeight}px 1fr`,
    gridTemplateColumns: `${applicationNavigationWidth}px 1fr`,
    "@media print": {
      height: "100%",
      gridTemplateAreas: `
        "ApplicationHeader  ApplicationHeader"
        "ApplicationContent ApplicationContent"
      `,
    },
  },
  content: {
    gridArea: "ApplicationContent",
    overflow: "auto", // scrollable area
    position: "relative",
  },
  header: {
    gridArea: "ApplicationHeader",
    zIndex: theme.zIndex.appBar,
    backgroundColor: colors.blue.main,
    color: colors.white,
    position: "sticky",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navigation: {
    gridArea: "ApplicationNav",
    display: "flex", // stretch to full height
    "@media print": {
      display: "none",
    },
  },
}));

const LogoLink = styled(RouterLink)({
  height: "100%",
  padding: 2, // off-grid easing
  "& img": {
    height: "100%",
  },
});

const DesktopFrame: FC = () => {
  const classes = useDesktopLayout();
  const { user, organization } = useSessionWithUser();
  return (
    <>
      <header className={classes.header}>
        <LogoLink to="/">
          <img src={EnergyRM} alt="EnergyRM" />
        </LogoLink>
        <Box alignItems="right">
          {user.organizationPermissions.length > 1 || user.superuser ? (
            <OrganizationSwitcher />
          ) : (
            <Typography>{organization.name}</Typography>
          )}
        </Box>
      </header>

      <div className={classes.navigation}>
        <ApplicationNavigation />
      </div>
    </>
  );
};

const MiniLogo = styled("img")({
  height: 24,
});

const useMobileLayout = makeStyles({
  container: {},
  content: {
    position: "relative",
  },
  drawerPaper: {
    width: applicationNavigationWidth,
    border: "0px solid transparent",
  },
  drawerPaperBorder: {
    borderRight: "none !important",
  },
});

const MobileFrame: FC = () => {
  const { organization } = useSessionWithUser();
  const classes = useMobileLayout();
  /* drawer controls */
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      {/* Header */}
      <AppBar position="sticky" variant="outlined">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MiniLogo src={EnergyRM} alt="EnergyRM" />
          </IconButton>
          <Box flexGrow={1}>
            <Typography align="right">{organization.name}</Typography>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Left Menu */}
      <Drawer
        container={container}
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
          paperAnchorDockedLeft: classes.drawerPaperBorder,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <ApplicationNavigation />
      </Drawer>
    </>
  );
};

/**
 * ApplicationLayout implements a responsive layout that will not unmount children
 * on a breakpoint change. This is critical to print mode.
 */
const ApplicationLayout: FC<Props> = ({ children, disablePadding }) => {
  const { breakpoints } = useTheme();
  const desktop = useMediaQuery(breakpoints.up("sm"));

  const desktopClasses = useDesktopLayout();
  const mobileClasses = useMobileLayout();
  const classes = desktop ? desktopClasses : mobileClasses;

  const Frame = desktop ? DesktopFrame : MobileFrame;

  return (
    <div className={classes.container}>
      <Frame />
      <div className={classes.content}>
        <Box px={disablePadding ? 0 : 3} pt={disablePadding ? 0 : 2}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
      </div>
    </div>
  );
};

export default ApplicationLayout;
