import * as Types from '../api/graphql';

import { FileAssetModelFragment } from '../api/fragments/FileAssetModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FileAssetModelFragmentDoc } from '../api/fragments/FileAssetModel.generated';
export type RecentlyAddedDocumentsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type RecentlyAddedDocumentsQuery = { organization: { __typename: 'Organization', id: string, documents: { __typename: 'DocumentConnection', edges: Array<{ __typename: 'DocumentEdge', node: { __typename: 'OrganizationDocument', id: string, createdAt: string, createdBy: { __typename: 'User', id: string, name: string, email: string }, file: (
            { __typename: 'FileAsset' }
            & FileAssetModelFragment
          ) } }> } } };


export const RecentlyAddedDocumentsDocument: DocumentNode<RecentlyAddedDocumentsQuery, RecentlyAddedDocumentsQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RecentlyAddedDocuments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"documents"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FileAssetModel"}}]}}]}}]}}]}}]}}]}},...FileAssetModelFragmentDoc.definitions]};