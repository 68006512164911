import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React, { createContext, useContext, useState } from "react";

interface Context {
  createToast: (message: string, severity: Color) => void;
}

export const ToastContext = createContext<Context>({
  createToast: () => null,
});

const ToastProvider: React.FC<{}> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<Color>("success");

  const createToast = (message: string, severity: Color) => {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  };

  return (
    <ToastContext.Provider value={{ createToast }}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

export const useToastContext = () => {
  const { createToast } = useContext(ToastContext);
  return createToast;
};
