import { AxisBottom, AxisLeft, AxisRight, TickFormatter } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { curveMonotoneX } from "@visx/curve";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { scaleLinear, scaleTime } from "@visx/scale";
import { AreaClosed, Line, LinePath } from "@visx/shape";
import { Accessor } from "@visx/shape/lib/types";
import { useTooltip } from "@visx/tooltip";
import { VoronoiPolygon } from "@visx/voronoi";
import { NumberValue, ScaleLinear, ScaleTime } from "d3-scale";
import React, { FC, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { IntervalModelFragment } from "../api/fragments/IntervalModel.generated";
import { EnergyUnitType, WeatherInterval } from "../api/graphql";
import displayUnit from "../lib/displayUnit";
import Energy from "../lib/Energy";
import formatter from "../lib/formatter";
import minZero from "../lib/minZero";
import ChartTooltip from "../ui/charting/ChartTooltip";
import { energySourceColors } from "../ui/colors";
import { BillingPeriodConnector } from "./createBillingPeriodConnectors";
import Gradient from "./Gradient";
import { MeterReading } from "./NewModelDialogButton";
import { AggregateEnergyReadingModelFragment } from "./ReferencePeriodMetersQuery.generated";
import useVoronoi from "./useVoronoi";

export type ChartMeterData = MeterReading[];

interface ReferencePeriodPreviewChartProps {
  utilityMeterData: ChartMeterData;
  readingConnections: BillingPeriodConnector[];
  weatherData: WeatherInterval[];
  width: number;
  height: number;
  rangeStart: Date;
  rangeEnd: Date;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

interface BillingPeriodLineProps {
  reading: MeterReading;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  isWithinExclusion: boolean;
  isHovered: boolean;
}

interface BillingPeriodConnectorLineProps {
  connector: BillingPeriodConnector;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  isWithinExclusion: boolean;
}

interface WeatherProps {
  data: WeatherInterval[];
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
}

type ReadingAccessor = Accessor<AggregateEnergyReadingModelFragment, number>;
type WeatherAccessor = Accessor<WeatherInterval, number>;
type IntervalAccessor = Accessor<IntervalModelFragment, number>;

// Target chart energy unit
const targetUnit = EnergyUnitType.KBTU;

// Date accessors
const startDateAccessor: IntervalAccessor = (d) => new Date(d.start).valueOf();
const endDateAccessor: IntervalAccessor = (d) => new Date(d.end).valueOf();
const midpointDateAccessor: IntervalAccessor = (d) =>
  new Date(d.midpoint).valueOf();

// Consumption accessor
// We convert to desired units here in the aggregate chart and divide by days in reading period
const consumptionAccessor: ReadingAccessor = ({ quantity, unit, interval }) =>
  new Energy({ quantity, unit }).as(targetUnit).quantity /
  interval.daysInPeriod;

// Air temp accessor for weather
const airTempAccessor: WeatherAccessor = (d) => d.airTemp || 0;

// Axes label config
const tickFormatter: TickFormatter<NumberValue> = (v) =>
  formatter(typeof v === "number" ? v : v.valueOf());

/**
 * Determines whether the passed reading is within the excluded portion
 */
function isWithinExclusion<T extends { start: string; end: string }>(
  interval: T,
  rangeStart: Date,
  rangeEnd: Date
) {
  return (
    rangeStart.valueOf() > new Date(interval.start).valueOf() ||
    rangeEnd.valueOf() < new Date(interval.end).valueOf()
  );
}

const BillingPeriodLine: FC<BillingPeriodLineProps> = ({
  reading,
  xScale,
  yScale,
  isWithinExclusion,
  isHovered,
}) => {
  const exclusionOpacity = isWithinExclusion ? 0.1 : 1;
  const strokeWidth = isHovered ? 5 : 3;

  return (
    <LinePath
      data={[
        { ...reading, date: new Date(reading.interval.start) },
        { ...reading, date: new Date(reading.interval.end) },
      ]}
      x={(d) => xScale(d.date.valueOf()) || 0}
      y={(d) => yScale(consumptionAccessor(d)) || 0}
      stroke={energySourceColors[reading.energySource]}
      strokeWidth={strokeWidth}
      strokeOpacity={exclusionOpacity}
      curve={curveMonotoneX}
    />
  );
};

const BillingPeriodConnectorLine: FC<BillingPeriodConnectorLineProps> = ({
  connector,
  xScale,
  yScale,
  isWithinExclusion,
}) => {
  const exclusionOpacity = isWithinExclusion ? 0.1 : 0.75;

  return (
    <Group>
      <LinePath
        data={connector.points}
        x={(d) => xScale(d.date.valueOf()) || 0}
        y={(d) => yScale(consumptionAccessor(d.readingData)) || 0}
        stroke={energySourceColors[connector.energySource]}
        strokeDasharray="3,2"
        strokeWidth={1}
        strokeOpacity={exclusionOpacity}
        curve={curveMonotoneX}
      />
    </Group>
  );
};

const WeatherArea: FC<WeatherProps> = ({ data, xScale, yScale }) => {
  const TEMP_DOMAIN = [-25, 120]; // values taken from backend validations

  return (
    <Group>
      <Gradient
        id="linear-gradient"
        domainMin={TEMP_DOMAIN[0]}
        domainMax={TEMP_DOMAIN[1]}
        data={data.map((d) => ({
          yValue: d.airTemp || 0,
          xValue: new Date(d.interval.midpoint).valueOf(),
        }))}
        gradientStops={[
          { color: "#66a1d2", yValue: TEMP_DOMAIN[0] },
          { color: "#ffffff", yValue: 65 },
          { color: "#b252a1", yValue: TEMP_DOMAIN[1] },
        ]}
      />
      <AreaClosed
        data={data}
        x={(d, idx, arr) => {
          if (idx === 0) {
            // Starting element? Use the start date from the interval
            return xScale(startDateAccessor(d.interval)) || 0;
          } else if (idx === arr.length - 1) {
            // Ending element? Use the end date from the interval
            return xScale(endDateAccessor(d.interval)) || 0;
          } else {
            // Otherwise plot using the midpoint
            return xScale(midpointDateAccessor(d.interval)) || 0;
          }
        }}
        y={(d) => yScale(airTempAccessor(d)) || 0}
        yScale={yScale}
        fill="url('#linear-gradient')"
        opacity={0.75}
        stroke="black"
        strokeOpacity={0.1}
        curve={curveMonotoneX}
      />
    </Group>
  );
};

const ReferencePeriodPreviewChart: FC<ReferencePeriodPreviewChartProps> = ({
  utilityMeterData,
  readingConnections,
  weatherData,
  width,
  height,
  rangeStart,
  rangeEnd,
  margin,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  // Tooltips
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
  } = useTooltip<MeterReading>();

  // Bounds
  const xMax = minZero(width - margin.left - margin.right);
  const yMax = minZero(height - margin.top - margin.bottom);

  // Scales
  const xScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: [
          Math.min(
            ...utilityMeterData.map((d) => startDateAccessor(d.interval))
          ),
          Math.max(...utilityMeterData.map((d) => endDateAccessor(d.interval))),
        ],
      }),
    [utilityMeterData, xMax]
  );

  const yScaleConsumption = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [
          Math.min(...utilityMeterData.flatMap(consumptionAccessor)),
          Math.max(...utilityMeterData.flatMap(consumptionAccessor)),
        ],
      }),
    [utilityMeterData, yMax]
  );

  const yScaleTemperature = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [
          Math.min(...weatherData.flatMap(airTempAccessor)),
          Math.max(...weatherData.flatMap(airTempAccessor)),
        ],
      }),
    [weatherData, yMax]
  );

  const { voronoiPolygons, voronoiDiagram } = useVoronoi({
    data: utilityMeterData,
    xAccessor: (d) => xScale(midpointDateAccessor(d.interval)) || 0,
    yAccessor: (d) => yScaleConsumption(consumptionAccessor(d)) || 0,
    chartWidth: xMax,
    chartHeight: yMax,
  });

  const { formatDate } = useIntl();

  return (
    <div style={{ width, height }}>
      {tooltipData && (
        <ChartTooltip
          tooltipLines={[
            {
              label: "Average daily consumption",
              value: `${formatter(
                consumptionAccessor(tooltipData)
              )} ${displayUnit(targetUnit)}`,
            },
            {
              label: "Total consumption",
              value: `${formatter(
                consumptionAccessor(tooltipData) *
                  tooltipData.interval.daysInPeriod
              )} ${displayUnit(targetUnit)}`,
            },
          ]}
          title={`${formatDate(new Date(tooltipData.interval.start))} —
              ${formatDate(new Date(tooltipData.interval.end))}`}
          left={tooltipLeft! + margin.left}
          top={tooltipTop}
        />
      )}
      <svg width={width} height={height} ref={svgRef}>
        <Group
          top={margin.top}
          left={margin.left}
          onMouseMove={(event) => {
            if (!svgRef.current) return;
            const point = localPoint(svgRef.current, event);
            if (!point) return;
            const closest = voronoiDiagram.find(
              point.x - margin.left,
              point.y - margin.top
            );
            if (!closest) return;

            const { data } = closest;

            !isWithinExclusion(data.interval, rangeStart, rangeEnd) &&
              showTooltip({
                tooltipData: data,
                tooltipTop: yScaleConsumption(consumptionAccessor(data)),
                tooltipLeft: xScale(midpointDateAccessor(data.interval)),
              });
          }}
          onMouseLeave={hideTooltip}
        >
          <WeatherArea
            data={weatherData}
            xScale={xScale}
            yScale={yScaleTemperature}
          />
          {utilityMeterData.map((d) => {
            return (
              <BillingPeriodLine
                key={`${d.energySource}-${d.interval.id}`}
                reading={d}
                xScale={xScale}
                yScale={yScaleConsumption}
                isWithinExclusion={isWithinExclusion(
                  d.interval,
                  rangeStart,
                  rangeEnd
                )}
                isHovered={
                  d.interval.id === tooltipData?.interval.id &&
                  d.energySource === tooltipData.energySource
                }
              />
            );
          })}
          <RectClipPath
            id="connector-clip"
            x={0}
            y={0}
            height={yMax}
            width={xMax}
          />
          <Group clipPath="url(#connector-clip)">
            {readingConnections.map((rc, idx) => {
              const [start, end] = rc.points;

              return (
                <BillingPeriodConnectorLine
                  key={`${
                    rc.energySource
                  }-${rc.points[0].date.valueOf()}-${idx}`}
                  connector={rc}
                  xScale={xScale}
                  yScale={yScaleConsumption}
                  isWithinExclusion={isWithinExclusion(
                    {
                      start: start.date.toISOString(),
                      end: end.date.toISOString(),
                    },
                    rangeStart,
                    rangeEnd
                  )}
                />
              );
            })}
          </Group>
          {voronoiPolygons.map((vp) => {
            return (
              <VoronoiPolygon
                key={vp.data.interval.id}
                polygon={vp}
                fill="transparent"
                stroke="transparent"
              />
            );
          })}
          {/* Start of exclusion */}
          <rect
            x={0}
            y={0}
            width={xScale(rangeStart.valueOf())}
            height={yMax}
            fill="rgba(255, 255, 255, 0.8)"
          />
          {/* End of exclusion */}
          <rect
            x={xScale(rangeEnd.valueOf())}
            y={0}
            width={xMax - (xScale(rangeEnd.valueOf()) || 0)}
            height={yMax}
            fill="rgba(255, 255, 255, 0.8)"
          />
          <Line
            from={{ x: xScale(rangeStart.valueOf()), y: 0 }}
            to={{ x: xScale(rangeStart.valueOf()), y: yMax }}
            stroke="black"
            strokeOpacity={0.8}
            strokeWidth={1}
            pointerEvents="none"
          />
          <Line
            from={{ x: xScale(rangeEnd.valueOf()), y: 0 }}
            to={{ x: xScale(rangeEnd.valueOf()), y: yMax }}
            stroke="black"
            strokeOpacity={0.8}
            strokeWidth={1}
            pointerEvents="none"
          />
          <AxisLeft
            scale={yScaleConsumption}
            label={`${displayUnit(targetUnit)} / day`}
            tickFormat={tickFormatter}
            hideTicks
          />
          <AxisRight
            scale={yScaleTemperature}
            left={xMax}
            label="Degrees Fahrenheit"
            tickFormat={tickFormatter}
            hideTicks
          />
          <AxisBottom scale={xScale} top={yMax} hideAxisLine hideTicks />
        </Group>
      </svg>
    </div>
  );
};

export default ReferencePeriodPreviewChart;
