import { Maybe } from "graphql/jsutils/Maybe";
import { DateTime } from "luxon";

export function notEmptyDates(arr: Maybe<string>[]): Date[] {
  return arr.reduce<Date[]>((acc, curr) => {
    if (curr) {
      acc.push(new Date(curr));
    }
    return acc;
  }, []);
}

const daysSinceUpdate = (date: Date): number => {
  return Math.abs(DateTime.fromJSDate(date).diffNow("days").get("days"));
};

export const meterIsStale = (date: Date): boolean =>
  daysSinceUpdate(date) >= 31;

export const numStaleMeters = (dates: Date[]): number => {
  return dates.filter((d) => meterIsStale(d)).length;
};

/**
 * Takes in an array of dates and returns whether they are 31+ days in the past
 */
const requireMeterUpdate = (dates: Date[]): boolean => {
  return dates.some((d) => meterIsStale(d));
};

export default requireMeterUpdate;
