import { GraphQLError } from "graphql";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { DeltaMeterAdjustmentReason, EnergyUnitType } from "../api/graphql";
import { adjustmentReason } from "../lib/adjustmentReason";
import displayUnit from "../lib/displayUnit";
import LabelledValue from "../lib/LabelledValue";
import {
  longTextError,
  presenceError,
  textFieldLimits,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";
import { TextField } from "../ui/TextField";

export interface Fields {
  reason: DeltaMeterAdjustmentReason;
  notes: string;
  quantity: number;
}

interface DialogProps {
  onSubmit: (fields: Fields) => void;
  onCancel: () => void;
  error: GraphQLError | undefined;
  defaultFields: Fields;
  adjustmentUnit: EnergyUnitType;
}

const validations = ({ notes }: Fields) => ({
  notes: presenceError(notes) || longTextError(notes),
});

const EditAdjustmentDialog: FC<DialogProps> = ({
  onSubmit,
  onCancel,
  error,
  defaultFields,
  adjustmentUnit,
}) => {
  const {
    handleSubmit,
    errors,
    register,
    formState: { isSubmitting },
  } = useForm<Fields>({
    defaultValues: {
      notes: defaultFields.notes,
    },
    resolver: validatorsResolver(validations),
  });

  const { formatNumber } = useIntl();

  return (
    <FormDialog
      title="Edit non-routine adjustment"
      error={error}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitName="Update"
      isSubmitting={isSubmitting}
      maxWidth="sm"
    >
      <Gaps spacing={2}>
        <FormSection label="Information">
          <LabelledValue
            title="Reason"
            value={adjustmentReason(defaultFields.reason)}
          />
          <TextField
            required
            name="notes"
            inputRef={register}
            error={errors.notes?.message}
            type="text"
            id="notes"
            label="Notes"
            size="small"
            multiline
            rows={4}
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.notes.max,
              },
            }}
          />
        </FormSection>
        <FormSection label="Baseline adjustment">
          <LabelledValue
            title="Amount"
            value={`${formatNumber(defaultFields.quantity)} ${displayUnit(
              adjustmentUnit
            )}`}
          />
        </FormSection>
      </Gaps>
    </FormDialog>
  );
};

export default EditAdjustmentDialog;
