import { OrganizationRoleType } from "../api/graphql";

export default function displayUserRole(
  roleType: OrganizationRoleType
): string {
  switch (roleType) {
    case OrganizationRoleType.OWNER:
      return "Owner";
    case OrganizationRoleType.ADMIN:
      return "Admin";
    case OrganizationRoleType.MEMBER:
      return "Member";
    case OrganizationRoleType.DEACTIVATED:
      return "Deactivated";
  }
}
