import {
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";
import TimelineIcon from "@material-ui/icons/Timeline";
import clsx from "clsx";
import React, { FC } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useAuthN } from "./lib/AuthN";
import {
  account,
  buildingsList,
  dashboard,
  deltaMeters,
  isCurrentRoute,
  organizationById,
  transactions,
} from "./lib/endpoints";
import { useSessionWithUser } from "./SessionWithUser";
import { navigation } from "./ui/colors";
import ProductCopy from "./ui/ProductCopy";

export const applicationNavigationWidth = 80;

const useStyles = makeStyles((theme) => ({
  mainNav: {
    backgroundColor: navigation.primaryNavBase,
    height: "100%",
  },
  listItemLink: {
    color: theme.palette.common.white,
    height: applicationNavigationWidth * 0.8,
    maxWidth: "100%",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  listItemLinkSelected: {
    backgroundColor: `${navigation.primaryNavAccent} !important`,
    boxShadow: `inset 0px 0px ${theme.spacing(1)}px 0px ${
      navigation.primaryNavAccentVariant
    }`,
  },
  disabledListItem: {
    color: theme.palette.grey[500],
    cursor: "pointer",
  },
}));

type LabeledIconProps = { icon: React.ReactElement; text: string };

const LabeledIcon: FC<LabeledIconProps> = ({ icon, text }) => (
  <Grid container alignItems="center" direction="column">
    {icon}
    <Typography variant="caption" align="center" component="p">
      {text}
    </Typography>
  </Grid>
);

type ListItemLinkProps = LabeledIconProps & {
  to?: string;
  current: boolean;
};

const ListItemLink: FC<ListItemLinkProps> = ({ icon, text, to, current }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      component={RouterLink}
      disableGutters
      to={to || "#"}
      selected={current}
      className={clsx(classes.listItemLink, {
        [classes.disabledListItem]: !to,
        [classes.listItemLinkSelected]: current,
      })}
      title={text}
    >
      <LabeledIcon icon={icon} text={text} />
    </ListItem>
  );
};

const Copyright = () => {
  return (
    <Typography variant="caption" color="inherit" align="center">
      ©&nbsp;{new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://energyrm.com/">
        {ProductCopy.ENERGY_RM}
      </Link>
    </Typography>
  );
};

const ApplicationNavigation: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { user, organization } = useSessionWithUser();
  const { logOut } = useAuthN();

  const handleLogout = () => {
    history.push("/");
    logOut();
  };

  return (
    <Box className={classes.mainNav}>
      <Box display="flex" flexDirection="column" height={1}>
        <Box
          display="flex"
          flexDirection="column"
          height={1}
          justifyContent="space-between"
        >
          <List component="nav" disablePadding>
            <ListItemLink
              icon={<AssessmentIcon />}
              to={dashboard()}
              text={ProductCopy.DASHBOARD}
              current={isCurrentRoute.dashboard(location.pathname)}
            />
            <ListItemLink
              icon={<ApartmentIcon />}
              to={buildingsList()}
              text={ProductCopy.INSIGHTS}
              current={isCurrentRoute.insights(location.pathname)}
            />
            <ListItemLink
              icon={<TimelineIcon />}
              to={deltaMeters()}
              text={ProductCopy.DELTAMETER}
              current={isCurrentRoute.deltaMeters(location.pathname)}
            />
            <ListItemLink
              icon={<MonetizationOnIcon />}
              to={transactions()}
              text={ProductCopy.TRANSACTIONS}
              current={isCurrentRoute.transactions(location.pathname)}
            />
            <Divider />
            <ListItemLink
              icon={<PeopleIcon />}
              to={organizationById({ id: organization.id })}
              text={ProductCopy.USERS}
              current={isCurrentRoute.organizationUsers(location.pathname)}
            />
          </List>
          <List component="nav" disablePadding>
            <ListItemLink
              icon={<AccountCircle />}
              to={account()}
              text={user.name}
              current={isCurrentRoute.account(location.pathname)}
            />
            <ListItem
              button
              component="a"
              target="_blank"
              href="mailto:support@energyrm.com"
              className={classes.listItemLink}
            >
              <LabeledIcon
                icon={<ContactSupportIcon fontSize="small" />}
                text="Contact"
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleLogout()}
              className={classes.listItemLink}
            >
              <LabeledIcon
                icon={<ExitToAppIcon fontSize="small" />}
                text="Logout"
              />
            </ListItem>
            <ListItem className={classes.listItemLink} disableGutters>
              <Copyright />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplicationNavigation;
