import { Icon, makeStyles } from "@material-ui/core";
import React, { FC, ReactNode } from "react";

const useStyles = makeStyles({
  iconCentered: {
    verticalAlign: "middle",
    "& *": {
      verticalAlign: "text-bottom",
    },
  },
});

/**
 * Yields an icon that is centered vertically for use in Typography
 */
const TextIcon: FC<{ icon: ReactNode }> = ({ icon }) => {
  const { iconCentered } = useStyles();
  return (
    <Icon className={iconCentered} color="primary" fontSize="small">
      {icon}
    </Icon>
  );
};

export default TextIcon;
