import { makeStyles } from "@material-ui/core";
import * as React from "react";

interface Props {
  /**
   * Ratio of width to height as a percentage (0-100)
   */
  ratio: number;
}

const useStyles = makeStyles(() => ({
  ratio: {
    height: 0,
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
  reflow: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

/**
 * Provides a box with a fixed aspect ratio.
 */
const AspectRatio: React.FC<Props> = ({ ratio, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.ratio} style={{ paddingTop: `${100 / ratio}%` }}>
      <div className={classes.reflow}>{children}</div>
    </div>
  );
};

export default AspectRatio;
