import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  LinearProgress,
  styled,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { read, utils, WorkBook } from "xlsx";
import useQueue, { JobStatus } from "../lib/useQueue";
import { useSessionWithUser } from "../SessionWithUser";
import colors from "../ui/colors";
import Gaps from "../ui/Gaps";
import StripeCard from "../ui/StripeCard";
import { PageTitle, SectionTitle } from "../ui/Typography";
import { ImportBuildingDocument } from "./ImportBuildingMutation.generated";
import {
  BuildingImport,
  parseLoadFile,
  validateBuildingImport,
} from "./parseLoadFile";
import SelectFile from "./SelectFile";

const readFile = async (file: File) => {
  const data: string = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });

  const wb: WorkBook = read(data, { type: "binary", cellDates: true });
  return parseLoadFile(
    utils.sheet_to_json(wb.Sheets["Buildings"]),
    utils.sheet_to_json(wb.Sheets["Meter Readings"])
  );
};

const StyledCheckBox = styled(CheckBoxIcon)(({ theme }) => ({
  margin: `${theme.spacing(1)}px`,
  verticalAlign: "middle",
}));

const ActionBar = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  backgroundColor: colors.blue.xxlight,
  paddingBottom: `${theme.spacing(1)}px`,
  zIndex: theme.zIndex.appBar,
}));

const BuildingsImport: React.FC = () => {
  const [file, setFile] = useState<File | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);
  const { organization } = useSessionWithUser();

  const {
    jobs,
    enqueue,
    start,
    working,
    enable,
    enableAll,
    disable,
    disableAll,
  } = useQueue<BuildingImport>({
    concurrency: 5,
    worker: async (building) => {
      const validationError = validateBuildingImport(building);
      if (validationError) {
        throw new Error(validationError);
      }
      await importBuilding({
        variables: {
          input: {
            organizationId: organization.id,
            ...building,
          },
        },
      });
    },
  });

  const [importBuilding] = useMutation(ImportBuildingDocument);

  const jobsEnabled = jobs.filter((j) => j.status !== JobStatus.Disabled)
    .length;
  const jobsFinished = jobs.filter(
    (j) => j.status === JobStatus.Successful || j.status === JobStatus.Failed
  ).length;
  const jobsPending = jobs.filter((j) => j.status === JobStatus.Assigned)
    .length;

  return (
    <>
      <Box pb={2}>
        <PageTitle>Import Buildings</PageTitle>
      </Box>

      <Prompt
        when={file && jobsFinished < jobs.length}
        message="Your load file has not been fully imported. Leave anyway?"
      />

      {file && jobs.length > 0 ? (
        <>
          <ActionBar>
            <SectionTitle>{file.name}</SectionTitle>
            <Grid container>
              <Grid sm={8} container alignItems="center">
                <Typography>
                  {jobsFinished}/{jobs.length} Processed
                </Typography>
              </Grid>
              <Grid sm={4} container alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={start}
                  disabled={working || jobsFinished === jobs.length}
                >
                  Upload
                </Button>
              </Grid>
              <Grid sm={12}>
                <Checkbox
                  color="primary"
                  onChange={(_, checked) =>
                    checked ? enableAll() : disableAll()
                  }
                  disabled={working}
                  defaultChecked
                />
                Enable All
              </Grid>
            </Grid>
            {working && (
              <Box pt={1}>
                <LinearProgress
                  variant="buffer"
                  value={(jobsFinished / jobsEnabled) * 100}
                  valueBuffer={
                    ((jobsPending + jobsFinished) / jobsEnabled) * 100
                  }
                />
              </Box>
            )}
          </ActionBar>

          <Gaps spacing={1} pb={4}>
            {jobs.map(({ id, data, status, error }) => {
              return (
                <StripeCard orientation="left" key={data.externalId}>
                  <Box p={2}>
                    {status === JobStatus.Assigned ? (
                      <CircularProgress />
                    ) : status === JobStatus.Successful ? (
                      <StyledCheckBox htmlColor={colors.green.main} />
                    ) : (
                      <Checkbox
                        color="primary"
                        checked={status !== JobStatus.Disabled}
                        onChange={() =>
                          status === JobStatus.Disabled
                            ? enable(id)
                            : disable(id)
                        }
                        disabled={working}
                      />
                    )}
                    {data.name}
                    {status === JobStatus.Failed && (
                      <FormHelperText component="span" error>
                        {" "}
                        {error?.message}
                      </FormHelperText>
                    )}
                  </Box>
                </StripeCard>
              );
            })}
          </Gaps>
        </>
      ) : (
        <SelectFile
          onChange={(file) => {
            setFile(file);
            setProcessing(true);
            readFile(file).then((data) => {
              setProcessing(false);
              enqueue(data);
            });
          }}
          processing={processing}
        />
      )}
    </>
  );
};

export default BuildingsImport;
