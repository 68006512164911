import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import millify from "millify";
import React, { FC, ReactNode } from "react";
import { KeyMetricLarge } from "../ui/Typography";

interface MetricProps {
  value: number;
  subheader: string;
  labelFormatter?: (v: string) => ReactNode;
  loading?: boolean;
}

export const MetricSkeleton: FC = () => {
  return (
    <>
      <Typography variant="h5" color="primary">
        <Skeleton width={75} />
      </Typography>
      <Typography>
        <Skeleton width={125} />
      </Typography>
    </>
  );
};

const Metric: FC<MetricProps> = ({
  value,
  subheader,
  labelFormatter,
  loading,
}) => {
  if (loading) return <MetricSkeleton />;

  return (
    <>
      <KeyMetricLarge color="primary">
        {labelFormatter ? labelFormatter(millify(value)) : millify(value)}
      </KeyMetricLarge>
      <Typography variant="body2" color="textSecondary">
        {subheader}
      </Typography>
    </>
  );
};

export default Metric;
