import {
  Grid,
  makeStyles,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import {
  formatEndUseTableReport,
  formatEnergySourceTableReport,
  RowData,
} from "../lib/reporting/dataTable";
import { EndUse as AnnualizedEndUses } from "../lib/types/Reports";
import colors from "../ui/colors";
import DataTable from "../ui/DataTable";
import { Rates } from "./BuildingInsightsReport";

interface Props {
  model: AnnualizedEndUses[];
  comparisonModel: AnnualizedEndUses[];
  rates: Rates;
}

// Percentages may add up to over 100 due to rounding
function clampRange(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  negativeNum: {
    color: colors.red.main,
  },
  cell: {
    fontSize: typography.fontSize,
    fontWeight: typography.fontWeightMedium,
    color: palette.common.black,
    [breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

const TotalsRow: React.FC<{ data: RowData[] }> = ({ data }) => {
  const { cell } = useStyles();

  const baseline = data.reduce((a, c) => a + c.BASELINE, 0);
  const projected = data.reduce((a, c) => a + c.PROJECTED, 0);

  const totals = {
    BASELINE: Math.round(baseline),
    PROJECTED: Math.round(projected),
    DELTA_END_USE: clampRange(
      ((baseline - projected) / baseline) * 100,
      0,
      100
    ),
    EE_POTENTIAL: Math.round(data.reduce((a, c) => a + c.EE_POTENTIAL, 0)),
    DELTA_EE_POTENTIAL: clampRange(
      data.reduce((a, c) => a + c.DELTA_EE_POTENTIAL, 0),
      0,
      100
    ),
    EE_VALUE: Math.round(data.reduce((a, c) => a + c.EE_VALUE, 0)),
    DELTA_EE_VALUE: clampRange(
      data.reduce((a, c) => a + c.DELTA_EE_VALUE, 0),
      0,
      100
    ),
  };

  return (
    <>
      <TableCell className={cell}>
        <FormatCellNumber num={totals.BASELINE} />
      </TableCell>
      <TableCell className={cell}>
        <FormatCellNumber num={totals.PROJECTED} />
      </TableCell>
      <TableCell className={cell}>
        <FormatCellNumber num={totals.DELTA_END_USE} symbol="%" />
      </TableCell>
      <TableCell className={cell}>
        <FormatCellNumber num={totals.EE_POTENTIAL} />
      </TableCell>
      <TableCell className={cell}>
        <FormatCellNumber num={totals.DELTA_EE_POTENTIAL} symbol="%" />
      </TableCell>
      <TableCell className={cell}>
        <FormatCellNumber num={totals.EE_VALUE} symbol="$" />
      </TableCell>
      <TableCell className={cell}>
        <FormatCellNumber num={totals.DELTA_EE_VALUE} symbol="%" />
      </TableCell>
    </>
  );
};

const FormatCellNumber: React.FC<{ num: number; symbol?: "$" | "%" }> = ({
  num,
  symbol,
}) => {
  const { negativeNum } = useStyles();
  const { formatNumber } = useIntl();

  const formatter =
    symbol === "%"
      ? (num: number) => num.toFixed(1)
      : (num: number) => formatNumber(Math.round(num));

  const prefix = symbol === "$" ? "$" : "";
  const suffix = symbol === "%" ? "%" : "";

  const content = `${prefix}${formatter(num)}${suffix}`;

  // Guard against possible divide by zero errors
  if (!isFinite(num)) return <span>—</span>;
  if (num < 0) return <span className={negativeNum}>{content}</span>;
  return <span>{content}</span>;
};

const DeltaDataTable: React.FC<Props> = ({ model, comparisonModel, rates }) => {
  const { cell } = useStyles();

  const endUseReport = formatEndUseTableReport(model, comparisonModel, rates);

  const energySourceReport = formatEnergySourceTableReport(
    model,
    comparisonModel,
    rates
  );

  const customBodyRender = (value: number) => (
    <FormatCellNumber num={Math.round(value)} />
  );

  const endUseDataColumns = [
    {
      name: "END_USE",
      label: "End Use",
      options: {
        sort: false,
      },
    },
    {
      name: "ENERGY_SOURCE",
      label: "Energy Source",
    },
    {
      name: "BASELINE",
      label: "Baseline",
      options: {
        customBodyRender,
      },
    },
    {
      name: "PROJECTED",
      label: "Projected",
      options: {
        customBodyRender,
      },
    },
    {
      name: "DELTA_END_USE",
      label: "Δ End Use",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="%" />
        ),
      },
    },
    {
      name: "EE_POTENTIAL",
      label: "EE Opportunity",
      options: {
        customBodyRender,
      },
    },
    {
      name: "DELTA_EE_POTENTIAL",
      label: "EE Depth",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="%" />
        ),
      },
    },
    {
      name: "EE_VALUE",
      label: "EE Value",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="$" />
        ),
      },
    },
    {
      name: "DELTA_EE_VALUE",
      label: "% EE Value",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="%" />
        ),
      },
    },
  ];

  const energySourceDataColumns = [
    {
      name: "ENERGY_SOURCE",
      label: "Energy Source",
    },
    // Adds a meaningless entry to create an artificial pad that attempts to align both tables
    {
      name: "",
      label: "",
      options: {
        sort: false,
      },
    },
    {
      name: "BASELINE",
      label: "Baseline",
      options: {
        customBodyRender,
      },
    },
    {
      name: "PROJECTED",
      label: "Projected",
      options: {
        customBodyRender,
      },
    },
    {
      name: "DELTA_END_USE",
      label: "Δ Energy Source",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="%" />
        ),
      },
    },
    {
      name: "EE_POTENTIAL",
      label: "EE Opportunity",
      options: {
        customBodyRender,
      },
    },
    {
      name: "DELTA_EE_POTENTIAL",
      label: "EE Depth",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="%" />
        ),
      },
    },
    {
      name: "EE_VALUE",
      label: "EE Value",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="$" />
        ),
      },
    },
    {
      name: "DELTA_EE_VALUE",
      label: "% EE Value",
      options: {
        customBodyRender: (value: number) => (
          <FormatCellNumber num={value} symbol="%" />
        ),
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <Typography>End Use Data (kBTU)</Typography>
        <DataTable
          data={endUseReport}
          columns={endUseDataColumns}
          options={{
            customTableBodyFooterRender: () => {
              return (
                <TableFooter>
                  <TableRow>
                    <TableCell className={cell}>Totals</TableCell>
                    <TableCell className={cell}></TableCell>
                    <TotalsRow data={endUseReport} />
                  </TableRow>
                </TableFooter>
              );
            },
            // Returning a fragment rather than `null` suppresses the "null children" warning
            customFooter: () => <></>,
            pagination: false,
          }}
        />
      </Grid>
      <Grid item lg={12}>
        <Typography>Energy Source Data (kBTU)</Typography>
        <DataTable
          data={energySourceReport}
          columns={energySourceDataColumns}
          options={{
            customTableBodyFooterRender: () => {
              return (
                <TableFooter>
                  <TableRow>
                    <TableCell className={cell}>Totals</TableCell>
                    <TableCell>
                      {/* Adds a sufficient amount of padding to mimic the Energy Source column */}
                      <p style={{ color: "transparent", height: "1px" }}>
                        ---------------------------------
                      </p>
                    </TableCell>
                    <TotalsRow data={energySourceReport} />
                  </TableRow>
                </TableFooter>
              );
            },
            customFooter: () => <></>,
            pagination: false,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DeltaDataTable;
