import { styled } from "@material-ui/core";
import React, { FC, useRef } from "react";
import useDetectOffsetTop from "../lib/useDetectOffsetTop";

const HeightContainer = styled("div")({
  height: ({ offset }: { offset?: number }) => `calc(100vh - ${offset}px)`,
});

const FillHeight: FC = ({ children }) => {
  const container = useRef<HTMLDivElement>(null);
  const calculatedOffset = useDetectOffsetTop(container);

  return (
    <HeightContainer ref={container} offset={calculatedOffset}>
      {children}
    </HeightContainer>
  );
};

export default FillHeight;
