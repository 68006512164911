import { useMediaQuery, useTheme } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import assertFeature from "../lib/assertFeature";
import useLazyDialogData from "../lib/useLazyDialogData";
import { trackDocumentView } from "../lib/useRecentlyViewedDocuments";
import { useSessionWithUser } from "../SessionWithUser";
import { isImage } from "./FilenamePreview";
import { FilePreviewDocument } from "./FilePreviewQuery.generated";
import useDocumentDownload from "./useDocumentDownload";

type Doc = {
  id: string;
  file: { mimetype: string };
};

/**
 * useOpenDocument will choose the best way to open a document, either with a new window (download)
 * or a preview dialog. it also manages putting the document into your recently viewed LocalStorage
 * list.
 */
export const useOpenDocument = () => {
  const { organization } = useSessionWithUser();
  // preview dialog's lazy loading
  const {
    openDialog,
    closeDialog,
    data,
    loading: dialogLoading,
  } = useLazyDialogData(FilePreviewDocument, {
    // Need fresh file links at all times
    fetchPolicy: "no-cache",
  });

  // make dialog fullscreen when in mobile
  const { breakpoints } = useTheme();
  const fullscreen = useMediaQuery(breakpoints.down("sm"));

  // download/preview link url fetching for displaying things in new tab.
  const { getUrl } = useDocumentDownload();
  const [urlLoading, setUrlLoading] = useState(false);

  // combined loading state
  const loading = useMemo(() => dialogLoading || urlLoading, [
    dialogLoading,
    urlLoading,
  ]);

  const open = useCallback(
    async (doc: Doc) => {
      // register document as recently viewed!
      trackDocumentView(doc, organization.id);

      // preview-able files use the lazy dialog
      const canPreview =
        assertFeature("THIRD_PARTY_FILE_PREVIEWS") ||
        isImage(doc.file.mimetype);
      if (canPreview) {
        openDialog({ documentId: doc.id });
        return;
      }

      // everything else is downloaded in a new tab
      setUrlLoading(true);
      // get download URL
      const downloadUrl = await getUrl(doc.id, true);
      setUrlLoading(false);
      // open file in new tab
      window.open(downloadUrl, "_blank");
    },
    [getUrl, openDialog, organization.id]
  );

  return { open, closeDialog, data, loading, fullscreen };
};
