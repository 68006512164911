import { DateTime } from "luxon";

const empties = [undefined, null, ""];
export const presenceError = (val: any) =>
  empties.includes(val) || (Array.isArray(val) && val.length === 0)
    ? "Required"
    : null;

export const emailError = (val: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val || "")
    ? null
    : "Invalid email address";

export const matchError = (val1: string, val2: string) =>
  val1 === val2 ? null : "Must match";

export const lengthError = (
  val: string,
  { min, max }: { min?: number; max?: number }
) => {
  if (min && val.length < min)
    return `Too short: must be at least ${min} characters`;
  if (max && val.length > max) return `Too long: cannot exceed ${max} chars`;
  return null;
};

export const checkedError = (val: boolean) => {
  if (!val) return "Must be checked";
  return null;
};

export const dateError = (
  val: Date,
  config?: { gt?: Date; gte?: Date; lt?: Date; lte?: Date; message?: string }
) => {
  if (isNaN(val.getTime())) {
    return config?.message || "Invalid date";
  }

  if (config?.gt && !(val > config.gt)) {
    return (
      config?.message ||
      `Must be after ${config.gt.toISOString().split("T")[0]}`
    );
  }

  if (config?.gte && !(val >= config.gte)) {
    return (
      config?.message ||
      `Must be no earlier than ${config.gte.toISOString().split("T")[0]}`
    );
  }

  if (config?.lt && !(val < config.lt)) {
    return (
      config?.message ||
      `Must be before ${config.lt.toISOString().split("T")[0]}`
    );
  }

  if (config?.lte && !(val <= config.lte)) {
    return (
      config?.message ||
      `Must be no later than ${config.lte.toISOString().split("T")[0]}`
    );
  }

  return null;
};

export const isValidDate = (value: Date) =>
  DateTime.fromJSDate(value).isValid || "Invalid Date Format";

const isValidZip = (zip: string): boolean =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);

export const zipCodeError = (code: string) => {
  if (!isValidZip(code)) return "Zip code invalid";
  return null;
};

const isNum = (val: string): boolean => /^\d+$/.test(val);

export const stringNumberError = (val: string) => {
  // Strip the string of commas, if a user has entered them
  // 123,456 along with 123,,,,,,456 will pass
  const noCommas = val.replace(/,/g, "");

  if (!isNum(noCommas)) return "Not a valid number";
  return null;
};

export const numberError = (
  val: number,
  config?: {
    integer?: boolean;
    notZero?: boolean;
    gt?: number;
    gte?: number;
    lt?: number;
    lte?: number;
  }
) => {
  if (isNaN(val)) {
    return "Invalid number";
  }

  if (config?.integer && !(val % 1 === 0)) {
    return "Must be a whole number";
  }

  if (typeof config?.gt !== "undefined" && !(val > config.gt)) {
    return `Must be greater than ${config.gt}`;
  }

  if (typeof config?.notZero !== "undefined" && val === 0) {
    return `Must not be zero`;
  }

  if (typeof config?.gte !== "undefined" && !(val >= config.gte)) {
    return `Must be at least ${config.gte}`;
  }

  if (typeof config?.lt !== "undefined" && !(val < config.lt)) {
    return `Must be less than ${config.lt}`;
  }

  if (typeof config?.lte !== "undefined" && !(val <= config.lte)) {
    return `Must be no more than ${config.lte}`;
  }

  return null;
};

export const enumError = (
  val: number | string | null | undefined,
  options: (string | number)[]
) =>
  val == null || options.includes(val) ? null : `${val} is not a valid option.`;

export type FieldErrors<F> = { [k in keyof F]?: string | null };

/** the min/max characters of the text fields. */
export const textFieldLimits = {
  name: { min: 3, max: 255 },
  description: { max: 255 },
  notes: { max: 3000 },
};

export const nameError = (name: string) =>
  presenceError(name) || lengthError(name, textFieldLimits.name);

export const descriptionError = (description: string) =>
  presenceError(description) ||
  lengthError(description, textFieldLimits.description);

export const longTextError = (notes: string) =>
  lengthError(notes, textFieldLimits.notes);
