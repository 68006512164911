import { useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { buildingTransaction } from "../lib/endpoints";
import useLazyDialogData from "../lib/useLazyDialogData";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import { useToastContext } from "../ui/ToastProvider";
import { CreateTransactionDocument } from "./CreateTransactionMutation.generated";
import { GetAllDeltaMetersDocument } from "./GetAllDeltaMetersQuery.generated";
import NewTransactionDialog from "./NewTransactionDialog";

const NewTransactionButton: React.FC = () => {
  const {
    openDialog,
    closeDialog,
    data: dialogData,
    loading: dialogLoading,
  } = useLazyDialogData(GetAllDeltaMetersDocument);
  const { organization, isSuperuser } = useSessionWithUser();
  const [createTransaction] = useMutation(CreateTransactionDocument);
  const createToast = useToastContext();
  const history = useHistory();

  if (!isSuperuser || !organization) {
    return null;
  }

  return (
    <>
      <Button primary onClick={() => openDialog({ orgId: organization.id })}>
        {dialogLoading ? (
          <CircularProgress size={20} />
        ) : (
          "Create New Transaction"
        )}
      </Button>
      {dialogData && (
        <NewTransactionDialog
          deltaMeters={dialogData.organization.deltaMeters.edges.map(
            ({ node }) => node
          )}
          onSubmit={async (input) => {
            try {
              const { data } = await createTransaction({
                variables: { input },
              });
              createToast("Transaction successfully created.", "success");
              closeDialog();
              history.push(
                buildingTransaction({
                  buildingId: data!.createTransaction.transaction.building.id,
                })
              );
            } catch (err) {
              createToast(
                err.message || "Failed to create new transaction",
                "error"
              );
            }
          }}
          onCancel={closeDialog}
        />
      )}
    </>
  );
};

export default NewTransactionButton;
