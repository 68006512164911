import { useEffect, useRef } from "react";

// not ready for SSR
const useTimeout = () => {
  const timer = useRef<number | undefined>();
  useEffect(() => () => clearTimeout(timer.current));
  return {
    start: (cb: () => void, delay: number) => {
      clearTimeout(timer.current);
      timer.current = (setTimeout(cb, delay) as unknown) as number;
    },
    stop: () => {
      clearTimeout(timer.current);
    },
  };
};

export default useTimeout;
