import { useMutation } from "@apollo/client";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink, Redirect, useHistory } from "react-router-dom";
import AuthenticationLayout from "../AuthenticationLayout";
import assertFeature from "../lib/assertFeature";
import { useAuthN } from "../lib/AuthN";
import {
  privacyPolicy,
  signin as signInUrl,
  termsOfService,
} from "../lib/endpoints";
import removeEmpty from "../lib/removeEmpty";
import ServerErrors from "../lib/ServerErrors";
import useAuthNErrors from "../lib/useAuthNErrors";
import {
  checkedError,
  emailError,
  FieldErrors,
  lengthError,
  matchError,
  presenceError,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import { useSessionManager } from "../SessionManager";
import Button from "../ui/Button";
import { TextField } from "../ui/TextField";
import { CreateUserDocument } from "./CreateUser.generated";

interface Props {}

interface Fields {
  email: string;
  name: string;
  password: string;
  passwordConfirmation: string;
  acceptTerms: boolean;
}

const validations = (values: Fields): FieldErrors<Fields> =>
  removeEmpty({
    acceptTerms: checkedError(values.acceptTerms),
    name:
      presenceError(values.name) ||
      lengthError(values.name, { min: 3, max: 255 }),
    email: presenceError(values.email) || emailError(values.email),
    password: presenceError(values.password),
    passwordConfirmation: matchError(
      values.password,
      values.passwordConfirmation
    ),
  });

const Messages = {
  username_MISSING: "Email is required.",
  username_FORMAT_INVALID: "Email is invalid.",
  username_TAKEN: "Email is already registered.",
  password_MISSING: "Password is required.",
  password_INSECURE: "Password is weak.",
  default: "Something went wrong.",
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export type AccountCreationLocationState = {
  /** initial value for the email field */
  email?: string;
  /** invitation token if creating user for invite */
  inviteToken?: string;
  /** user-facing display message */
  message?: string;
  /** redirect url */
  then?: string;
};

const Register: React.FC<Props> = () => {
  const history = useHistory<AccountCreationLocationState>();
  const { signUp } = useAuthN();
  const { setUser } = useSessionManager();
  const [createUser] = useMutation(CreateUserDocument);
  const [authNErrors, catchAuthNErrors] = useAuthNErrors();
  const redirectLink = history.location.state?.then ?? "/";

  const onSubmit = async (fields: Fields) => {
    catchAuthNErrors(async () => {
      await signUp({ username: fields.email, password: fields.password });
      const result = await createUser({
        variables: {
          input: {
            name: fields.name,
            email: fields.email,
            inviteToken: history.location.state?.inviteToken,
          },
        },
      });
      if (result.data?.createUser.user) {
        setUser(result.data.createUser.user);
        history.push(redirectLink);
      }
    });
  };

  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = useForm<Fields>({
    defaultValues: {
      acceptTerms: false,
      name: "",
      email: history.location.state?.email ?? "",
      password: "",
      passwordConfirmation: "",
    },
    resolver: validatorsResolver(validations),
  });

  const classes = useStyles();

  if (!assertFeature("SIGN_UP") && !history.location.state?.inviteToken) {
    return <Redirect to={redirectLink} />;
  }

  return (
    <AuthenticationLayout title="Register">
      {history.location.state?.message && (
        <Typography>{history.location.state.message}</Typography>
      )}
      <form
        noValidate
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ServerErrors errors={authNErrors} messages={Messages} />

        <TextField
          name="name"
          type="name"
          label="Name"
          required
          inputRef={register}
          error={errors.name?.message}
          autoComplete="name"
        />

        <TextField
          name="email"
          type="email"
          label="Email Address"
          required
          inputRef={register}
          error={errors.email?.message}
          autoComplete="email"
        />

        <TextField
          name="password"
          type="password"
          label="Password"
          required
          inputRef={register}
          error={errors.password?.message}
          autoComplete="new-password"
        />

        <TextField
          name="passwordConfirmation"
          type="password"
          label="Confirm Password"
          required
          inputRef={register}
          error={errors.passwordConfirmation?.message}
          autoComplete="new-password"
        />

        <FormControlLabel
          control={
            <Controller
              name="acceptTerms"
              control={control}
              render={({ value, onChange }) => (
                <Checkbox
                  size="small"
                  color="primary"
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                />
              )}
            />
          }
          label={
            <>
              I have reviewed and accept the{" "}
              <Link
                href={termsOfService()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                href={privacyPolicy()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </>
          }
        />
        {errors.acceptTerms && (
          <FormHelperText error>{errors.acceptTerms.message}</FormHelperText>
        )}

        <Button
          type="submit"
          disabled={isSubmitting}
          fullWidth
          primary
          className={classes.submit}
        >
          Sign Up
        </Button>

        <Grid container>
          <Grid item xs>
            &nbsp;
          </Grid>
          <Grid item>
            <Link
              component={RouterLink}
              to={{ pathname: signInUrl(), state: history.location.state }}
              variant="body2"
            >
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </form>
    </AuthenticationLayout>
  );
};

export default Register;
