import { useMutation } from "@apollo/client";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { Currency, EnergySource, EnergyUnitType } from "../api/graphql";
import Button from "../ui/Button";
import { useToastContext } from "../ui/ToastProvider";
import NewUtilityMeterReadingDialog from "./NewUtilityMeterReadingDialog";
import { UpdateUtilityMeterReadingDocument } from "./UpdateUtilityMeterReading.generated";
import { MixedReading } from "./UtilityMeter";

interface Props {
  externalId: string;
  energySource: EnergySource;
  unit: EnergyUnitType;
  timeZone: string;
  reading: MixedReading;
}

const EditReadingButton: FC<Props> = ({
  externalId,
  energySource,
  unit,
  timeZone,
  reading,
}) => {
  const { formatDate } = useIntl();
  const createToast = useToastContext();
  const [showDialog, setShowdialog] = useState<boolean>(false);

  const [
    updateUtilityReading,
    { error: utilityUpdateReadingError },
  ] = useMutation(UpdateUtilityMeterReadingDocument);

  return (
    <>
      {showDialog && (
        <NewUtilityMeterReadingDialog
          locked={reading.locked}
          meterName={externalId || "Meter"}
          energySource={energySource}
          unit={unit}
          startOn={
            new Date(
              formatDate(reading.startOn, {
                timeZone,
              })
            )
          }
          endOn={
            new Date(
              formatDate(reading.endOn, {
                timeZone,
              })
            )
          }
          quantity={reading.quantity!}
          cost={reading.cost ?? undefined}
          onCancel={() => setShowdialog(false)}
          error={utilityUpdateReadingError?.graphQLErrors[0]}
          onSubmit={async (fields) => {
            try {
              const { startOn, endOn, quantity, cost } = fields;

              await updateUtilityReading({
                variables: {
                  input: {
                    utilityMeterReadingId: reading.id!,
                    quantity: Number(quantity) ?? undefined,
                    startOn,
                    endOn,
                    cost:
                      cost == null
                        ? cost
                        : {
                            value: Number(cost),
                            unit: Currency.USD,
                          },
                  },
                },
              });

              setShowdialog(false);
              createToast(`Reading was updated`, "success");
            } catch (err) {
              // API errors are handled via FormHelperText
            }
          }}
        />
      )}
      <Button size="small" onClick={() => setShowdialog(true)}>
        Edit
      </Button>
    </>
  );
};

export default EditReadingButton;
