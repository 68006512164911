import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteUtilityMeterReadingMutationVariables = Types.Exact<{
  input: Types.DeleteUtilityMeterReadingInput;
}>;


export type DeleteUtilityMeterReadingMutation = { deleteUtilityMeterReading: { __typename: 'DeleteUtilityMeterReadingResponse', utilityMeterReading: Types.Maybe<{ __typename: 'UtilityMeterReading', id: string }> } };


export const DeleteUtilityMeterReadingDocument: DocumentNode<DeleteUtilityMeterReadingMutation, DeleteUtilityMeterReadingMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteUtilityMeterReading"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteUtilityMeterReadingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUtilityMeterReading"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"utilityMeterReading"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]};