import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";

/** returns the value of the prop on the parsed query object */
export default function useSearchQueryValue(prop: string): string {
  const location = useLocation();
  const queryObj = React.useMemo(() => queryString.parse(location.search), [
    location.search,
  ]);
  const value = React.useMemo(() => (queryObj[prop] || "").toString(), [
    queryObj,
    prop,
  ]);
  return value;
}
