import {
  Box,
  Chip,
  Link,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { ChangeEvent, FC, useState } from "react";
import { FormattedNumber } from "react-intl";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import unique from "../lib/unique";
import usaStates from "../lib/usaStates";
import MetaData from "../ui/MetaData";
import { Building as BuildingOption } from "./OrganizationUser";

const ShowIcon = styled("div")({
  visibility: ({ selected }: { selected: boolean }) =>
    selected ? "visible" : "hidden",
});

const SelectAllOption = "select-all" as const;

interface Props {
  placeholder: string;
  value: BuildingOption[];
  onChange: (val: BuildingOption[]) => void;
  options: BuildingOption[];
  disabled?: boolean;
}

const filterOptions = createFilterOptions({
  stringify: (option: BuildingOption) => {
    const stateName =
      usaStates.find((s) => s.abbreviation === option.address.region)?.name ||
      "";

    return `${option.name},${option.majorTenant || ""},${stateName}`;
  },
  ignoreCase: true,
});

const Entry: FC<{ selected: boolean }> = ({ selected, children }) => (
  <>
    <ShowIcon selected={selected}>
      <DoneIcon />
    </ShowIcon>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div>{children}</div>
    </div>
    <ShowIcon selected={selected}>
      <CloseIcon />
    </ShowIcon>
  </>
);

const BuildingEntry: FC<{ building: BuildingOption; selected: boolean }> = ({
  building,
  selected,
}) => (
  <Entry selected={selected}>
    {building.name}
    <Typography variant="caption">
      <MetaData>
        <span>{primaryFunctionTitle(building.buildingUseType)}</span>
        <span>
          <FormattedNumber value={building.squareFeet} /> ft²
        </span>
        <span>{building.address.region}</span>
        {building.yearBuilt && <span>{`Built ${building.yearBuilt}`}</span>}
        {building.majorTenant && (
          <Box component="span" display="inline-flex" alignItems="center">
            <PersonPinIcon fontSize="inherit" />
            {building.majorTenant}
          </Box>
        )}
      </MetaData>
    </Typography>
  </Entry>
);

const SelectAllEntry: FC<{ buildingCount: number }> = ({ buildingCount }) => (
  <Entry selected={false}>
    <Typography component={Link} variant="caption">
      select all ({buildingCount})
    </Typography>
  </Entry>
);

const OmniSearch: FC<Props> = ({
  placeholder,
  onChange,
  value,
  options,
  disabled,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const handleClose = (_: ChangeEvent<{}>, reason: string) => {
    if (reason === "select-option" || reason === "remove-option") {
      return;
    }
    onChange(value);
    setSearchValue("");
  };

  const filtered = filterOptions(options, {
    inputValue: searchValue,
    getOptionLabel: () => "",
  });

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      forcePopupIcon={false}
      limitTags={5}
      disabled={disabled}
      getLimitTagsText={(more) => `+${more} more`}
      open={searchValue.length > 2}
      onClose={handleClose}
      inputValue={searchValue}
      onInputChange={(_, val, reason) => {
        if (reason === "reset") return;
        setSearchValue(val);
      }}
      value={value}
      onChange={(_, newValue) => {
        if (newValue.some((nv) => nv === SelectAllOption)) {
          // merge visible options into selected values
          onChange(
            unique(
              newValue.filter(
                (nv): nv is BuildingOption => nv !== SelectAllOption
              ),
              filtered
            )
          );
          // trigger close
          setSearchValue("");
        } else {
          onChange(newValue as BuildingOption[]);
        }
      }}
      noOptionsText="No buildings found"
      renderOption={(option, { selected }) =>
        option === SelectAllOption ? (
          <SelectAllEntry buildingCount={filtered.length} />
        ) : (
          <BuildingEntry building={option} selected={selected} />
        )
      }
      renderTags={(val, getTagProps) =>
        (val as typeof value).map((opt, idx) => (
          <Chip
            variant="outlined"
            label={opt.name}
            size="small"
            {...getTagProps({ index: idx })}
          />
        ))
      }
      options={[SelectAllOption, ...filtered]}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={value.length === 0 ? placeholder : ""}
          variant="outlined"
        />
      )}
    />
  );
};

export default OmniSearch;
