import { Grid, Paper, styled, TableContainer } from "@material-ui/core";
import React, { FC } from "react";
import { SkeletonAvatar, SkeletonTypography } from "../ui/Bones";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../ui/Table";

const SkeletonHeader: FC = () => {
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell>
        <SkeletonTypography />
      </TableCell>
      <TableCell>
        <SkeletonTypography />
      </TableCell>
      <TableCell>
        <SkeletonTypography />
      </TableCell>
      <TableCell>
        <SkeletonTypography />
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

const StyledRow = styled(TableRow)(({ theme }) => ({
  height: theme.spacing(8),
}));

const SkeletonRow: FC = () => {
  return (
    <StyledRow>
      <TableCell colSpan={6}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <SkeletonAvatar />
          </Grid>
          <Grid item xs={11}>
            <SkeletonTypography fullWidth />
          </Grid>
        </Grid>
      </TableCell>
    </StyledRow>
  );
};

const DocumentCenterListSkeleton: FC = () => {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <SkeletonHeader />
        </TableHead>
        <TableBody>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentCenterListSkeleton;
