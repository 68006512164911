import { Tooltip, ValueLabelProps } from "@material-ui/core";
import React, { FC } from "react";

const SliderValueLabel: FC<ValueLabelProps> = ({ children, open, value }) => {
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

export default SliderValueLabel;
