import { Box, Grid, styled, Typography } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import { FormattedNumber } from "react-intl";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import { useSessionWithUser } from "../SessionWithUser";
import MetaData from "../ui/MetaData";
import { HeaderTitle } from "../ui/Typography";
import { BuildingMetadataQueryQuery } from "./BuildingMetadataQuery.generated";
import { ShareBuildingButton } from "./ShareBuildingDialog";

interface Props {
  building: BuildingMetadataQueryQuery["building"];
  media?: ReactNode;
}

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
  },
}));

const BuildingProfileCard: FC<Props> = ({ media, building }: Props) => {
  const { organization } = useSessionWithUser();
  const { name, address, squareFeet, buildingUseType } = building;

  return (
    <Container>
      <Grid alignItems="center">
        <Box pr={1}>{media}</Box>
      </Grid>
      <Box flexGrow={1}>
        <HeaderTitle>{name}</HeaderTitle>
        <MetaData>
          <Typography component="span" variant="body2">
            {address.streetAddress}, {address.locality} {address.region}
          </Typography>
          <Typography component="span" variant="body2">
            <FormattedNumber value={squareFeet} /> ft²
          </Typography>
          <Typography component="span" variant="body2">
            {primaryFunctionTitle(buildingUseType)}
          </Typography>
        </MetaData>
      </Box>

      <Box displayPrint="none">
        <ShareBuildingButton
          buildingId={building.id}
          organizationId={organization.id}
        />
      </Box>
    </Container>
  );
};

export default BuildingProfileCard;
