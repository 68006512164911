import { Building } from ".";
import { BuildingModelFit } from "../api/graphql";
import sortAlpha from "../lib/sortAlpha";
import {
  distinctEnergySources,
  Filters,
  LogicalOperator,
  MinMax,
} from "./filterReducer";

const getRange = (nums: number[]): MinMax => {
  // Short circuit for slider components that don't have enough data to calculate
  if (nums.length === 0) return [0, 0];
  return [Math.min(...nums), Math.max(...nums)];
};

const expandRange = (nums: MinMax): MinMax => {
  return [Math.floor(nums[0]), Math.ceil(nums[1])];
};

export const getMajorTenants = (buildings: Building[]) =>
  Array.from(
    new Set(
      buildings
        .filter((b) => !!b.majorTenant)
        .map((b) => b.majorTenant!)
        .sort(sortAlpha)
    )
  );

export const getLeaseTypes = (buildings: Building[]) =>
  Array.from(
    new Set(
      buildings
        .filter((b) => !!b.leaseType)
        .map((b) => b.leaseType!)
        .sort(sortAlpha)
    )
  );

export const getBuildingUseTypes = (buildings: Building[]) =>
  Array.from(new Set(buildings.map((b) => b.buildingUseType).sort(sortAlpha)));

export const getUsStates = (buildings: Building[]) =>
  Array.from(new Set(buildings.map((b) => b.address.region).sort(sortAlpha)));

export const getEnergySources = (buildings: Building[]) =>
  Array.from(
    new Set(
      buildings
        .flatMap((b) => distinctEnergySources(b.utilityMeters))
        .sort(sortAlpha)
    )
  );

export const getBuildingSizeMinMax = (
  buildings: Building[]
): [number, number] => {
  const sizes = buildings.map((b) => b.squareFeet);

  return expandRange(getRange(sizes));
};

export const getYearBuiltMinMax = (buildings: Building[]): [number, number] => {
  const yearsBuilt = buildings
    .filter((b) => !!b.yearBuilt)
    .map((b) => b.yearBuilt!);

  return expandRange(getRange(yearsBuilt));
};

export const getEEenergyMinMax = (buildings: Building[]): [number, number] => {
  const eeEnergy = buildings.map(
    (b) => b.calibratedModel?.adjustedModel?.opportunity.energy.quantity || 0
  ); // TODO: how to manage buildings with no EE?

  return expandRange(getRange(eeEnergy));
};

export const getEEpercentMinMax = (buildings: Building[]): [number, number] => {
  const eePercents = buildings.map(
    (b) => b.calibratedModel?.adjustedModel?.opportunity.energyDepth || 0
  );

  return expandRange([
    Math.min(...eePercents) * 100,
    Math.max(...eePercents) * 100,
  ]);
};

export const getEEmoneyMinMax = (buildings: Building[]): [number, number] => {
  const eeMoney = buildings.map(
    (b) => b.calibratedModel?.adjustedModel?.opportunity.money.value || 0
  );

  return expandRange(getRange(eeMoney));
};

export const getEEmoneyPerAreaMinMax = (
  buildings: Building[]
): [number, number] => {
  const eeMoneysPerArea = buildings.map(
    (b) =>
      b.calibratedModel?.adjustedModel?.opportunity.moneyPerSquareFoot.value ||
      0
  );

  return expandRange(getRange(eeMoneysPerArea));
};

export const getEEenergyPerAreaMinMax = (
  buildings: Building[]
): [number, number] => {
  const eeEnergyPerArea = buildings.map(
    (b) =>
      b.calibratedModel?.adjustedModel?.opportunity.energyPerSquareFoot
        .quantity || 0
  );

  return expandRange(getRange(eeEnergyPerArea));
};

export const createDefaultFormOptions = (buildings: Building[]): Filters => {
  return {
    energySourcesLogicalOperator: LogicalOperator.AND,
    eePercent: getEEpercentMinMax(buildings),
    eeEnergy: getEEenergyMinMax(buildings),
    eeMoney: getEEmoneyMinMax(buildings),
    eeEnergyPerSquareUnit: getEEenergyPerAreaMinMax(buildings),
    eeMoneyPerSquareUnit: getEEmoneyPerAreaMinMax(buildings),
    buildingSize: getBuildingSizeMinMax(buildings),
    yearBuilt: getYearBuiltMinMax(buildings),
    leaseType: getLeaseTypes(buildings),
    majorTenant: getMajorTenants(buildings),
    buildingUseType: getBuildingUseTypes(buildings),
    location: getUsStates(buildings),
    energySources: getEnergySources(buildings),
    buildingName: "",
    sortBy: "eeMoney",
    modelFit: [
      BuildingModelFit.GOOD,
      BuildingModelFit.MODERATE,
      BuildingModelFit.POOR,
      "NONE",
    ],
  };
};
