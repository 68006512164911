import React, { FC } from "react";
import { useForm } from "react-hook-form";
import FormDialog from "../ui/FormDialog";
import Gaps from "../ui/Gaps";
import { SelectFieldController, SelectItem } from "../ui/SelectField";

interface Props {
  deltaMeters: { id: string; shortId: string; building: { name: string } }[];
  onSubmit: (fields: Fields) => void;
  onCancel: () => void;
}

interface Fields {
  deltaMeterId: string;
}

const NewTransactionDialog: FC<Props> = ({
  deltaMeters,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<Fields>();

  return (
    <FormDialog
      title="Add new transaction"
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitName="Add"
      isSubmitting={isSubmitting}
      maxWidth="sm"
    >
      <Gaps spacing={2}>
        <SelectFieldController
          required
          variant="outlined"
          name="deltaMeterId"
          label="Select DeltaMeter *"
          control={control}
          error={errors.deltaMeterId?.message}
        >
          {deltaMeters.map((deltaMeter) => (
            <SelectItem key={deltaMeter.id} value={deltaMeter.id}>
              {deltaMeter.building.name} ({deltaMeter.shortId})
            </SelectItem>
          ))}
        </SelectFieldController>
      </Gaps>
    </FormDialog>
  );
};

export default NewTransactionDialog;
