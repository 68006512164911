import { BuildingModelFit } from "../api/graphql";

export default function fitCompare(
  a: BuildingModelFit | undefined,
  b: BuildingModelFit | undefined
): -1 | 0 | 1 {
  if ((!a && !b) || a === b) {
    return 0;
  } else if (!a) {
    return -1; // b exists
  } else if (!b) {
    return 1; // a exists
  } else if (a === BuildingModelFit.POOR) {
    return -1; // b exists and is GOOD
  } else {
    return 1; // a is GOOD and b is POOR
  }
}
