import { styled } from "@material-ui/core";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GlyphCircle } from "@visx/glyph";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaStack, LinePath } from "@visx/shape";
import millify from "millify";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EnergyUnitType } from "../api/graphql";
import displayUnit from "../lib/displayUnit";
import Energy from "../lib/Energy";
import formatter from "../lib/formatter";
import minZero from "../lib/minZero";
import ChartTooltip from "../ui/charting/ChartTooltip";
import TooltipLine from "../ui/charting/TooltipLine";
import useChartTooltip from "../ui/charting/useChartTooltip";
import colors from "../ui/colors";
import { FormSections } from "./FinancialModelDashboard";
import {
  calculateCumulativeSum,
  DataPoint,
  EnergySourceConfig,
} from "./helpers";

interface Props {
  width: number;
  height: number;
  energySourceConfigs: EnergySourceConfig[];
}

type Accessor = (d: DataPoint) => number;

// Chart containing element must be position relative and intake width
// to correctly render tooltip using <TooltipWithBounds />
const ChartContainer = styled("div")({
  position: "relative",
  width: ({ width }: { width: number }) => width,
});

const targetUnit = EnergyUnitType.KBTU;

const margin = { top: 5, right: 20, bottom: 55, left: 55 };

export const formatChartData = (
  sources: EnergySourceConfig[],
  term: number,
  projectUncertainty: number,
  targetUnit: EnergyUnitType
) => {
  const annualEnergyFlows = [...Array(term)].map((_, idx) => {
    const totalAverageEfficiency = Energy.sumEnergies(
      sources.map((s) => s.eeQuantity)
    ).as(targetUnit);
    // First year won't produce energy as it's the implementation year
    const currentYear = idx + 1;

    return {
      low: totalAverageEfficiency.times(1 - projectUncertainty).quantity,
      average: totalAverageEfficiency.quantity,
      high: totalAverageEfficiency.times(1 + projectUncertainty).quantity,
      year: currentYear,
    };
  });

  return calculateCumulativeSum(annualEnergyFlows);
};

// Accessors
const yearAcessor: Accessor = (d) => d.year;
const averageAccessor: Accessor = (d) => d.average;
const lowAccessor: Accessor = (d) => d.low;
const highAccessor: Accessor = (d) => d.high;

const CumulativeEfficiencyChart: FC<Props> = ({
  height,
  width,
  energySourceConfigs,
}) => {
  const { watch } = useFormContext<FormSections>();
  const projectData = watch("projectData");
  const chartData = formatChartData(
    energySourceConfigs,
    projectData.term,
    projectData.projectUncertainty,
    targetUnit
  );

  // Scales
  const xMax = minZero(width - margin.left - margin.right);
  const yMax = minZero(height - margin.bottom - margin.top);

  const xScale = scaleLinear<number>({
    range: [0, xMax],
    domain: [
      Math.min(...chartData.map(yearAcessor)),
      Math.max(...chartData.map(yearAcessor)),
    ],
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, Math.max(...chartData.map(highAccessor))],
  });

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    hideTooltip,
    handleTooltip,
  } = useChartTooltip<DataPoint>({
    xScale,
    offset: margin.left,
    data: chartData,
    xAccessor: yearAcessor,
  });

  return (
    <ChartContainer width={width}>
      {tooltipData && (
        <ChartTooltip
          tooltipLines={[
            {
              label: "High",
              value: `${formatter(tooltipData.high)} ${displayUnit(
                targetUnit
              )}`,
            },
            {
              label: "Average",
              value: `${formatter(tooltipData.average)} ${displayUnit(
                targetUnit
              )}`,
              color: colors.blue.main,
            },
            {
              label: "Low",
              value: `${formatter(tooltipData.low)} ${displayUnit(targetUnit)}`,
            },
          ]}
          title={`Year ${tooltipData.year}`}
          left={tooltipLeft! + margin.left}
          top={tooltipTop}
        />
      )}
      <svg
        width={width}
        height={height}
        onMouseMove={handleTooltip}
        onMouseLeave={() => hideTooltip()}
      >
        <Group top={margin.top} left={margin.left}>
          {tooltipData && (
            <Group>
              <TooltipLine yMax={yMax} offsetLeft={tooltipLeft || 0} />
              <GlyphCircle
                key={`glyph-tooltip-${Math.random()}`}
                left={tooltipLeft}
                top={yScale(averageAccessor(tooltipData))}
                size={50}
                fill={colors.blue.main}
                strokeWidth={2}
              />
            </Group>
          )}
          <AreaStack
            top={0}
            left={0}
            keys={["CumulativeSum"]}
            data={chartData}
            x={(d) => xScale(yearAcessor(d.data)) || 0}
            y0={(d) => yScale(lowAccessor(d.data)) || 0}
            y1={(d) => yScale(highAccessor(d.data)) || 0}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack) || ""}
                  stroke="transparent"
                  fill={colors.blue.main}
                  opacity={0.33}
                />
              ))
            }
          </AreaStack>
          <LinePath
            data={chartData}
            x={(d) => xScale(yearAcessor(d)) || 0}
            y={(d) => yScale(averageAccessor(d)) || 0}
            stroke={colors.blue.main}
            strokeWidth={2}
            strokeOpacity={1}
          />
          <AxisLeft
            scale={yScale}
            tickFormat={(v) => millify(Number(v))}
            hideTicks
            label={`Energy efficiency production (${displayUnit(targetUnit)})`}
          />
          <AxisBottom
            scale={xScale}
            top={yMax}
            tickFormat={(v) => `Year ${v}`}
            hideAxisLine
            hideTicks
          />
        </Group>
      </svg>
    </ChartContainer>
  );
};

export default CumulativeEfficiencyChart;
