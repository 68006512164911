import { Box, Typography } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import { useIntl } from "react-intl";
import { DeltaMeterAdjustmentReason, EnergyUnitType } from "../api/graphql";
import { adjustmentReason } from "../lib/adjustmentReason";
import displayUnit from "../lib/displayUnit";
import Card, { CardActions, CardContent } from "../ui/Card";
import MetaData from "../ui/MetaData";
import { SectionTitle } from "../ui/Typography";

interface Props {
  reason: DeltaMeterAdjustmentReason;
  quantity: number;
  unit: EnergyUnitType;
  percentBaseline: number;
  notes: string | null;
  editAdjustment: ReactNode;
  deleteAdjustment: ReactNode;
}

const AdjustmentCard: FC<Props> = ({
  reason,
  quantity,
  unit,
  percentBaseline,
  notes,
  editAdjustment,
  deleteAdjustment,
}) => {
  const { formatNumber } = useIntl();

  return (
    <Card>
      <CardContent>
        <SectionTitle>{adjustmentReason(reason)}</SectionTitle>
        <Typography variant="body2">{notes}</Typography>
      </CardContent>
      <CardActions>
        <Box width={1} display="flex" justifyContent="space-between">
          <MetaData>
            <Typography component="span" variant="body1">
              Quantity: {formatNumber(quantity)} {displayUnit(unit)}
            </Typography>
            <Typography component="span" variant="body1">
              {percentBaseline.toFixed(1)}% of baseline
            </Typography>
          </MetaData>
          <Box display="inline-flex">
            <Box pr={1}>{editAdjustment}</Box>
            {deleteAdjustment}
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default AdjustmentCard;
