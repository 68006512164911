import { isApolloError } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { captureException } from "@sentry/browser";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { authentication } from "../lib/AuthN";
import NotFound from "./NotFound";

type Props = RouteComponentProps;

type State = {
  hasError: APIError | boolean;
};

enum APIError {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  NOT_FOUND = "NOT_FOUND",
}

const expiredSession = (error: Error): boolean =>
  isApolloError(error) &&
  error.graphQLErrors[0] &&
  error.graphQLErrors[0].extensions?.code === APIError.UNAUTHENTICATED;

const notFound = (error: Error): boolean =>
  isApolloError(error) &&
  error.graphQLErrors[0] &&
  error.graphQLErrors[0].extensions?.code === APIError.NOT_FOUND;

class ErrorBoundary extends React.Component<Props, State> {
  static contextType = authentication;
  context!: React.ContextType<typeof authentication>;
  state: State = { hasError: false };

  // classify error type for next render
  static getDerivedStateFromError(error: Error) {
    if (expiredSession(error)) {
      return { hasError: APIError.UNAUTHENTICATED };
    } else if (notFound(error)) {
      return { hasError: APIError.NOT_FOUND };
    }
    return { hasError: true };
  }

  // report or handle
  componentDidCatch(error: Error, { componentStack }: React.ErrorInfo) {
    if (expiredSession(error)) {
      this.context.logOut();
    } else if (notFound(error)) {
      return;
    } else {
      captureException(error, { extra: { componentStack } });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.state.hasError &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.dismiss();
    }
  }

  dismiss = () => {
    this.setState({ hasError: false });
    window.history.back();
  };

  render() {
    if (this.state.hasError === false) {
      return this.props.children;
    }

    if (this.state.hasError === APIError.UNAUTHENTICATED) {
      return null;
    }

    if (this.state.hasError === APIError.NOT_FOUND) {
      return <NotFound buttonText="Go Back" onClick={this.dismiss} />;
    }

    if (this.state.hasError === true) {
      return (
        <Dialog
          open
          onClose={this.dismiss}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Something went wrong.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This is unexpected! EnergyRM has been notified and will
              investigate.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.dismiss} color="primary">
              Go Back
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  }
}

export default withRouter(ErrorBoundary);
