import { useMutation } from "@apollo/client";
import React, { FC } from "react";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import { useToastContext } from "../ui/ToastProvider";
import { AdjustedModel, CalibratedModel } from "./BuildingInsightsReport";
import { UpdateDefaultModelsDocument } from "./UpdateDefaultModels.generated";

interface Props {
  buildingId: string;
  initialModel: CalibratedModel;
  comparisonModel: AdjustedModel;
}

const SetDefaultModelsButton: FC<Props> = ({
  buildingId,
  initialModel,
  comparisonModel,
}) => {
  const [updateDefaultModels] = useMutation(UpdateDefaultModelsDocument);
  const createToast = useToastContext();

  const onSubmit = async () => {
    try {
      await updateDefaultModels({
        variables: {
          input: {
            buildingId,
            adjustedModelId: comparisonModel.id,
          },
        },
      });

      createToast("Default models were updated", "success");
    } catch (err) {
      createToast(err.message || "Error setting default models", "error");
    }
  };

  // If both models are the defaults then we have no need to set defaults
  if (initialModel.default && comparisonModel.default) return null;

  return (
    <ButtonWithConfirmation
      size="small"
      header="Set default building energy models?"
      description="This will set the building's default energy models to the current selection"
      onClick={onSubmit}
    >
      Set defaults
    </ButtonWithConfirmation>
  );
};

export default SetDefaultModelsButton;
