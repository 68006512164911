import { Box, Grid, styled } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import { BuildingModelParametersFieldsFragment } from "../api/fragments/BuildingModelParametersFields.generated";
import { EnergySourceContribution } from "../api/graphql";
import { energySourceName } from "../lib/energySource";
import { sortBy } from "../lib/sortBy";
import colors from "../ui/colors";
import Gaps from "../ui/Gaps";
import Ratio from "../ui/Ratio";

type Props = {
  parameters: BuildingModelParametersFieldsFragment;
};

const hoursPerDay = 24;

const Header = styled("div")({
  color: colors.blue.dark,
  textTransform: "uppercase",
  fontWeight: "bold",
  flex: "0 0 auto",
});

const Label = styled("div")({
  fontWeight: "bold",
});

const Parameters = styled(Box)(({ theme }) => ({
  display: "grid",
  columnGap: `${theme.spacing(1)}px`,
  gridTemplateColumns: "2.5em 1fr",
}));

const Value = styled("span")({
  textAlign: "right",
  alignSelf: "center",
});

const Key = styled("span")({});

const SourcesGrid: FC<{ sources: EnergySourceContribution[] }> = ({
  sources,
}) => (
  <Parameters>
    {sortBy(sources, (s) => s.portion, "desc").map(
      ({ energySource, portion, performance }) => (
        <>
          <Value>{Math.round(portion * 100)}%</Value>
          <Key>
            {energySourceName(energySource)} (COP={performance.toFixed(2)})
          </Key>
        </>
      )
    )}
  </Parameters>
);

const ResponsiveLabel: FC<{ label: ReactNode }> = ({ label, children }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        {label}
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};

const ModelDetails: FC<Props> = ({ parameters }) => {
  return (
    <Gaps spacing={5}>
      <ResponsiveLabel label={<Header>Heating</Header>}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Label>Sources</Label>
            <SourcesGrid sources={parameters.spaceHeating.sources} />
            {parameters.spaceHeating.fanPortion > 0 && (
              <Parameters>
                <Value>
                  {Math.round(parameters.spaceHeating.fanPortion * 100)}%
                </Value>
                <Key>Forced Air Fans</Key>
              </Parameters>
            )}
          </Grid>
          <Grid item xs>
            <Label>Control Temperature</Label>
            <Parameters>
              <Value>{parameters.spaceHeating.setTemp.toFixed(1)}</Value>
              <Key>°F</Key>
            </Parameters>
          </Grid>
        </Grid>
      </ResponsiveLabel>
      <ResponsiveLabel label={<Header>Cooling</Header>}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Label>Sources</Label>
            <SourcesGrid sources={parameters.spaceCooling.sources} />
          </Grid>
          <Grid item xs>
            <Label>Control Temperature</Label>
            <Parameters>
              <Value>{parameters.spaceCooling.setTemp.toFixed(1)}</Value>
              <Key>°F</Key>
            </Parameters>
          </Grid>
        </Grid>
      </ResponsiveLabel>
      <ResponsiveLabel label={<Header>Hot Water</Header>}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Label>Sources</Label>
            <SourcesGrid sources={parameters.hotWater.sources} />
          </Grid>
          <Grid item xs>
            <Label>Control Temperature</Label>
            <Parameters>
              <Value>{parameters.hotWater.setTemp}</Value>
              <Key>°F</Key>
            </Parameters>
          </Grid>
          <Grid item xs={12}>
            <Label>Volume, Daily</Label>
            <Parameters>
              <Value>
                {(parameters.hotWater.volume * hoursPerDay).toFixed(2)}
              </Value>
              <Key>
                <Ratio top="gal" bottom="ft²" />
              </Key>
            </Parameters>
          </Grid>
        </Grid>
      </ResponsiveLabel>
      <ResponsiveLabel label={<Header>Electric Baseload</Header>}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Label>Interior, Daily</Label>
            <Parameters>
              <Value>{(parameters.lighting * hoursPerDay).toFixed(1)}</Value>
              <Key>
                <Ratio top="Wh" bottom="ft²" /> lighting
              </Key>
              {parameters.baseload.interior?.electricity && (
                <>
                  <Value>
                    {(
                      parameters.baseload.interior.electricity * hoursPerDay
                    ).toFixed(1)}
                  </Value>
                  <Key>
                    <Ratio top="Wh" bottom="ft²" /> plugs
                  </Key>
                </>
              )}
            </Parameters>
          </Grid>
          <Grid item xs>
            <Label>Exterior, Daily</Label>
            <Parameters>
              {parameters.baseload.exterior?.electricity && (
                <>
                  <Value>
                    {(
                      parameters.baseload.exterior.electricity * hoursPerDay
                    ).toFixed(1)}
                  </Value>
                  <Key>
                    <Ratio top="Wh" bottom="ft²" /> plugs
                  </Key>
                </>
              )}
            </Parameters>
          </Grid>
        </Grid>
      </ResponsiveLabel>
      <ResponsiveLabel label={<Header>Building Envelope</Header>}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Label>Transmittance, Daily</Label>
            <Parameters>
              <Value>
                {(parameters.shellHeatLoss * hoursPerDay).toFixed(1)}
              </Value>
              <Key>
                <Ratio top="BTU" bottom="ft² • °F" />
              </Key>
            </Parameters>
          </Grid>
          {parameters.spaceCooling.performance !== 1 && (
            <Grid item xs>
              <Label>Cooling Multiplier</Label>
              {parameters.spaceCooling.performance.toFixed(2)}
            </Grid>
          )}
        </Grid>
      </ResponsiveLabel>
    </Gaps>
  );
};

export default ModelDetails;
