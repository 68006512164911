import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { WeatherSource } from "../api/graphql";
import displayUnit from "../lib/displayUnit";
import Energy from "../lib/Energy";
import { defaultEnergySourceUnit, energySourceName } from "../lib/energySource";
import { sortBy } from "../lib/sortBy";
import { CardContent, CardHeader } from "../ui/Card";
import StripeCard from "../ui/StripeCard";
import {
  CalibratedModelData,
  FormSections,
  Rate,
  ScenarioEnergySourceData,
  ScenarioModelData,
} from "./FinancialModelDashboard";
import { ControlledFormattedNumberField } from "./FormattedNumberField";
import { fuelOrder } from "./fuelOrder";
import LineItem from "./LineItem";
import MoneyField from "./MoneyField";
import PercentField from "./PercentField";

interface Props {
  adjustedModelActualWeatherData: ScenarioModelData[];
  adjustedModelNormalWeatherData: ScenarioModelData[];
  calibratedModelData: CalibratedModelData;
}

const StyledMoneyField = styled(MoneyField)(({ theme: { spacing } }) => ({
  maxWidth: spacing(15),
}));

const Hide: FC<{ hidden: boolean }> = ({ hidden, children }) => (
  <Box display={hidden ? "none" : "block"}>{children}</Box>
);

export const StyledSelectField = styled(Select)(({ theme: { spacing } }) => ({
  minWidth: spacing(35),
}));

const ScenarioAndRatesForm: FC<Props> = ({
  calibratedModelData,
  adjustedModelActualWeatherData,
  adjustedModelNormalWeatherData,
}) => {
  const { register, control, watch, errors, setValue } = useFormContext<
    FormSections
  >();

  const customScenarioFields: ScenarioEnergySourceData[] = watch(
    "scenarioAndRates.customScenario.sources"
  );
  const usingCustomScenario: boolean = watch(
    "scenarioAndRates.usingCustomScenario"
  );
  const customRateFields: Rate[] = watch("scenarioAndRates.customRates");
  const usingCustomRates: boolean = watch("scenarioAndRates.usingCustomRates");
  const selectedModelId: string = watch("scenarioAndRates.selectedModelId");
  const defaultRateFields: Rate[] = watch("scenarioAndRates.defaultRates");
  const weatherSource: WeatherSource = watch(
    "scenarioAndRates.weatherSource"
  ) as WeatherSource;

  const selectedAdjustedModelWeather =
    weatherSource === WeatherSource.ACTUAL
      ? adjustedModelActualWeatherData
      : adjustedModelNormalWeatherData;

  return (
    <StripeCard data-testid="scenario-rates-form">
      <CardHeader
        title={
          <Typography variant="button" color="primary">
            Energy efficiency production
          </Typography>
        }
      />
      <CardContent>
        <form>
          <Controller
            control={control}
            name="scenarioAndRates.usingCustomScenario"
            render={({ onChange, value }) => (
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={value}
                  onChange={(event) => onChange(event.target.value === "true")}
                >
                  <FormControlLabel
                    label="Use scenario"
                    value={false}
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    label="Custom values"
                    value={true}
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          <LineItem label="Calibrated model">
            <Typography>{calibratedModelData.name}</Typography>
          </LineItem>
          <Hide hidden={!usingCustomScenario}>
            <br />
            {customScenarioFields.map((item, idx, arr) => {
              const targetCalibratedModelSource = calibratedModelData.sources.find(
                (cm) => cm.energySource === item.energySource
              );

              return (
                <Box pb={idx === arr.length ? 0 : 2} key={idx}>
                  <Grid container>
                    <input
                      type="hidden"
                      ref={register()}
                      name={`scenarioAndRates.customScenario.sources[${idx}].energySource`}
                      defaultValue={item.energySource}
                    />
                    <input
                      type="hidden"
                      ref={register()}
                      name={`scenarioAndRates.customScenario.sources[${idx}].unit`}
                      defaultValue={item.unit}
                    />
                    <Typography>
                      <b>{energySourceName(item.energySource!)}</b>
                    </Typography>
                    <LineItem
                      label={`Annual (${displayUnit(
                        defaultEnergySourceUnit(item.energySource!)
                      )})`}
                    >
                      <ControlledFormattedNumberField
                        places={0}
                        value={Math.round(
                          new Energy({
                            quantity:
                              targetCalibratedModelSource?.baseline || 0,
                            unit: targetCalibratedModelSource!.unit,
                          })
                            .as(defaultEnergySourceUnit(item.energySource))
                            .times(item.depth).quantity
                        )}
                        onChange={(e) => {
                          const depth = new Energy({
                            quantity: e,
                            unit: defaultEnergySourceUnit(item.energySource),
                          })
                            .as(targetCalibratedModelSource!.unit)
                            .divide(targetCalibratedModelSource?.baseline || 0)
                            .quantity;

                          setValue(
                            `scenarioAndRates.customScenario.sources[${idx}].depth`,
                            depth
                          );
                        }}
                      />
                    </LineItem>
                    <LineItem label="Energy efficiency depth">
                      <PercentField
                        control={control}
                        name={`scenarioAndRates.customScenario.sources[${idx}].depth`}
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                          min: {
                            value: -100,
                            message: "Value must be from -100% to 100%",
                          },
                          max: {
                            value: 100,
                            message: "Value must be from -100% to 100%",
                          },
                        }}
                        errorMsg={
                          errors.scenarioAndRates?.customScenario?.sources &&
                          errors.scenarioAndRates?.customScenario.sources[idx]
                            ?.depth?.message
                        }
                        defaultValue={item.depth}
                        placeholder="Depth"
                      />
                    </LineItem>
                  </Grid>
                </Box>
              );
            })}
          </Hide>
          <Hide hidden={usingCustomScenario}>
            <>
              <LineItem label="Scenario selection">
                <Controller
                  control={control}
                  name="scenarioAndRates.selectedModelId"
                  render={({ onChange, value }) => (
                    <FormControl>
                      <StyledSelectField
                        value={value}
                        onChange={onChange}
                        inputProps={{ "data-testid": "scenario-select" }}
                      >
                        {selectedAdjustedModelWeather.map((dfs, idx) => (
                          <MenuItem value={dfs.id} key={idx}>
                            {dfs.name}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                    </FormControl>
                  )}
                />
              </LineItem>
              <LineItem label="Scenario climate">
                <Controller
                  control={control}
                  name="scenarioAndRates.weatherSource"
                  render={({ onChange, value }) => (
                    <FormControl>
                      <StyledSelectField
                        value={value}
                        onChange={onChange}
                        inputProps={{ "data-testid": "weather-select" }}
                      >
                        <MenuItem value={WeatherSource.ACTUAL}>
                          Reference period
                        </MenuItem>
                        <MenuItem value={WeatherSource.NORMAL}>
                          30-year normals
                        </MenuItem>
                      </StyledSelectField>
                    </FormControl>
                  )}
                />
              </LineItem>
              <br />
              {sortBy(
                selectedAdjustedModelWeather.find(
                  (dfs) => dfs.id === selectedModelId
                )!.sources,
                fuelOrder
              ).map((s, idx, arr) => {
                const targetCalibratedModelSource = calibratedModelData.sources.find(
                  (cm) => cm.energySource === s.energySource
                );

                return (
                  <Box pb={idx === arr.length ? 0 : 2} key={idx}>
                    <Typography>
                      <b>{energySourceName(s.energySource!)}</b>
                    </Typography>
                    <LineItem
                      label={`Annual (${displayUnit(
                        defaultEnergySourceUnit(s.energySource!)
                      )})`}
                    >
                      <Typography>
                        <FormattedNumber
                          value={Math.round(
                            new Energy({
                              quantity:
                                targetCalibratedModelSource?.baseline || 0,
                              unit: targetCalibratedModelSource!.unit,
                            })
                              .times(s.depth)
                              .as(defaultEnergySourceUnit(s.energySource))
                              .quantity
                          )}
                        />
                      </Typography>
                    </LineItem>
                    <LineItem label="Energy efficiency depth">
                      <Typography>
                        <FormattedNumber value={s.depth * 100} />%
                      </Typography>
                    </LineItem>
                  </Box>
                );
              })}
            </>
          </Hide>
        </form>
      </CardContent>
      <CardHeader
        title={
          <Typography variant="button" color="primary">
            Utility expenses
          </Typography>
        }
      />
      <CardContent>
        <form>
          <Controller
            control={control}
            name="scenarioAndRates.usingCustomRates"
            render={({ onChange, value }) => (
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={value}
                  onChange={(event) => onChange(event.target.value === "true")}
                >
                  <FormControlLabel
                    label="Use EIA rates"
                    value={false}
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    label="Use custom rates"
                    value={true}
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          <Hide hidden={!usingCustomRates}>
            {customRateFields.map((item, idx, arr) => (
              <Box pb={idx === arr.length ? 0 : 2} key={idx}>
                <Grid container>
                  <input
                    type="hidden"
                    ref={register()}
                    name={`scenarioAndRates.customRates[${idx}].energySource`}
                    defaultValue={item.energySource}
                  />
                  <input
                    type="hidden"
                    ref={register()}
                    name={`scenarioAndRates.customRates[${idx}].unit`}
                    defaultValue={item.unit}
                  />
                  <input
                    type="hidden"
                    ref={register()}
                    name={`scenarioAndRates.customRates[${idx}].cost.unit`}
                    defaultValue={item.cost!.unit}
                  />
                  <Typography>
                    <b>{energySourceName(item.energySource!)}</b>
                  </Typography>
                  <LineItem label={`Cost per ${displayUnit(item.unit!)}`}>
                    <StyledMoneyField
                      control={control}
                      name={`scenarioAndRates.customRates[${idx}].cost.value`}
                      rules={{
                        valueAsNumber: true,
                        required: {
                          value: true,
                          message: "Required",
                        },
                        min: {
                          value: 0,
                          message: "Value cannot be negative",
                        },
                      }}
                      defaultValue={item.cost?.value ?? ""}
                      precision={3}
                    />
                  </LineItem>
                  <LineItem label="Annual escalation">
                    <PercentField
                      control={control}
                      name={`scenarioAndRates.customRates[${idx}].escalation`}
                      rules={{
                        required: {
                          value: true,
                          message: "Required",
                        },
                        min: {
                          value: 0,
                          message: "Value cannot be negative",
                        },
                        max: {
                          value: 100,
                          message: "Value must be from 0 to 100",
                        },
                      }}
                      errorMsg={
                        errors.scenarioAndRates?.customRates &&
                        errors.scenarioAndRates?.customRates[idx]?.escalation
                          ?.message
                      }
                      defaultValue={item.escalation}
                      placeholder="Escalation"
                    />
                  </LineItem>
                </Grid>
              </Box>
            ))}
          </Hide>
          <Hide hidden={usingCustomRates}>
            {defaultRateFields.map((drf, idx, arr) => (
              <Box pb={idx === arr.length ? 0 : 2} key={idx}>
                <input
                  type="hidden"
                  ref={register()}
                  name={`scenarioAndRates.defaultRates[${idx}].energySource`}
                />
                <input
                  type="hidden"
                  ref={register({ valueAsNumber: true })}
                  name={`scenarioAndRates.defaultRates[${idx}].cost.value`}
                />
                <input
                  type="hidden"
                  ref={register()}
                  name={`scenarioAndRates.defaultRates[${idx}].cost.unit`}
                />
                <input
                  type="hidden"
                  ref={register({ valueAsNumber: true })}
                  name={`scenarioAndRates.defaultRates[${idx}].escalation`}
                />
                <input
                  type="hidden"
                  ref={register()}
                  name={`scenarioAndRates.defaultRates[${idx}].unit`}
                />
                <input
                  type="hidden"
                  ref={register()}
                  name={`scenarioAndRates.defaultRates[${idx}].eiaSource`}
                />
                <Typography>
                  <b>{energySourceName(drf.energySource)}</b>
                </Typography>
                <LineItem
                  label={`Cost per ${displayUnit(drf.unit)}${
                    !usingCustomRates && "*"
                  }`}
                >
                  <Typography>
                    <FormattedNumber
                      value={drf.cost.value}
                      style={`currency`}
                      currency={drf.cost.unit}
                      minimumFractionDigits={3}
                    />
                  </Typography>
                </LineItem>
                <LineItem label="Annual escalation">
                  <Typography>
                    <FormattedNumber value={drf.escalation * 100} />%
                  </Typography>
                </LineItem>
              </Box>
            ))}
          </Hide>
        </form>
        {!usingCustomRates && (
          <Typography variant="caption" color="textSecondary" component="div">
            *Default rate data sourced from EIA
          </Typography>
        )}
      </CardContent>
    </StripeCard>
  );
};

export default ScenarioAndRatesForm;
