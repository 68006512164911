import {
  FormControlLabel,
  Switch as MuiSwitch,
  SwitchProps,
} from "@material-ui/core";
import React, { FC, ReactNode } from "react";

interface Props extends Omit<SwitchProps, "onChange" | "value" | "color"> {
  value: boolean;
  onChange: (v: boolean) => void;
  label: ReactNode;
}

const Switch: FC<Props> = ({ value, onChange, label, ...props }) => {
  return (
    <FormControlLabel
      control={
        <MuiSwitch
          color="primary"
          checked={value}
          value={value}
          onChange={({ target: { checked } }) => {
            onChange(checked);
          }}
          {...props}
        />
      }
      label={label}
    />
  );
};

export default Switch;
