import { InputAdornment } from "@material-ui/core";
import { GraphQLError } from "graphql";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { DeltaMeterAdjustmentReason, EnergyUnitType } from "../api/graphql";
import { adjustmentReason } from "../lib/adjustmentReason";
import displayUnit from "../lib/displayUnit";
import {
  longTextError,
  numberError,
  presenceError,
  textFieldLimits,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";
import { SelectFieldController, SelectItem } from "../ui/SelectField";
import { TextField } from "../ui/TextField";
export interface Fields {
  reason: DeltaMeterAdjustmentReason | "";
  notes: string;
  quantity: number | null;
}

interface DialogProps {
  onSubmit: (fields: Fields) => void;
  onCancel: () => void;
  error: GraphQLError | undefined;
  adjustmentUnit: EnergyUnitType;
}

const validations = ({ reason, notes, quantity }: Fields) => ({
  reason: presenceError(reason),
  notes: presenceError(notes) || longTextError(notes),
  quantity: numberError(quantity as number, { integer: true, notZero: true }),
});

const NewAdjustmentDialog: FC<DialogProps> = ({
  onSubmit,
  onCancel,
  error,
  adjustmentUnit,
}) => {
  const {
    handleSubmit,
    errors,
    register,
    control,
    formState: { isSubmitting },
  } = useForm<Fields>({
    defaultValues: {
      reason: "",
      notes: "",
      quantity: null,
    },
    resolver: validatorsResolver(validations),
  });

  return (
    <FormDialog
      title="Add non-routine adjustment"
      error={error}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitName="Add"
      isSubmitting={isSubmitting}
      maxWidth="sm"
    >
      <Gaps spacing={2}>
        <FormSection label="Information">
          <SelectFieldController
            variant="outlined"
            name="reason"
            label="Reason"
            control={control}
            error={errors.reason?.message}
          >
            {Object.values(DeltaMeterAdjustmentReason).map((k, idx) => (
              <SelectItem key={`nrea-reason-${idx}`} value={k}>
                {adjustmentReason(k)}
              </SelectItem>
            ))}
          </SelectFieldController>
          <TextField
            required
            name="notes"
            inputRef={register}
            error={errors.notes?.message}
            type="text"
            id="notes"
            label="Notes"
            size="small"
            multiline
            rows={4}
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.notes.max,
              },
            }}
          />
        </FormSection>
        <FormSection label="Baseline adjustment">
          <TextField
            required
            size="small"
            name="quantity"
            inputRef={register({
              valueAsNumber: true,
            })}
            type="number"
            id="baseline-adjustment"
            label="Amount (+/-)"
            error={errors.quantity?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {displayUnit(adjustmentUnit)}
                </InputAdornment>
              ),
            }}
          />
        </FormSection>
      </Gaps>
    </FormDialog>
  );
};

export default NewAdjustmentDialog;
