import { styled, Typography } from "@material-ui/core";
import React from "react";
import { useDropzone } from "react-dropzone";

type Props = {
  onChange: (file: File) => void;
  processing: boolean;
};

const Dropzone = styled("div")(({ theme: { palette, shape, spacing } }) => ({
  background: palette.grey[200],
  borderRadius: shape.borderRadius,
  margin: "auto",
  padding: spacing(10),
  textAlign: "center",
  "&:hover": {
    cursor: "pointer",
  },
}));

const SelectFile: React.FC<Props> = ({ onChange, processing }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    disabled: processing,
    maxFiles: 1,
    preventDropOnDocument: true,
    onDropAccepted: async (newFiles: File[]) => {
      onChange(newFiles[0]);
    },
  });

  return (
    <>
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />

        {processing ? (
          <Typography variant="overline">
            Processing file. This may take a minute.
          </Typography>
        ) : (
          <Typography variant="overline">
            Click to upload or drag &amp; drop a building load file.
          </Typography>
        )}
      </Dropzone>
      <Typography>
        <a href={process.env.REACT_APP_BUILDING_IMPORT_TEMPLATE_URL}>
          Download template
        </a>
      </Typography>
    </>
  );
};

export default SelectFile;
