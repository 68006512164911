import React from "react";
import { SvgIcon } from "@material-ui/core";

function SvgChangeMdOpt(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.792 29.548" {...props}>
      <g paintOrder="markers fill stroke">
        <path
          d="M44.792 0L0 29.548h44.792z"
          fillOpacity={0.3}
          style={{
            fontVariationSettings: "normal",
          }}
        />
        <path
          d="M28.275 10.89L-.001 29.542h28.276z"
          style={{
            fontVariationSettings: "normal",
          }}
        />
      </g>
    </svg>
  );
}

const ChangeMd = () => (
  <SvgIcon component={SvgChangeMdOpt} viewBox="0 5 50 24" fontSize="large" />
);

export default ChangeMd;
