import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

const InvalidTokenDialog: React.FC = () => (
  <Dialog
    open
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Something went wrong.</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        This invitation URL is expired or broken. Please check your email and
        try again. If the issue continues, ask your organization administrator
        to send a new invitation.
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default InvalidTokenDialog;
