import { getLocalStorage, setLocalStorage } from "./useLocalStorage";

interface Document {
  id: string;
}

const MAX_LIST_LENGTH = 10;

const key = (organizationId: string) => `recent-documents-${organizationId}`;

/**
 * trackDocumentView records the document in localStorage.
 *
 * This is stateless.
 */
export const trackDocumentView = (
  document: Document,
  organizationId: string
) => {
  const storageKey = key(organizationId);
  const previous = getLocalStorage<string[]>(storageKey) || [];
  const remaining = previous.filter((id) => id !== document.id);
  const current = [document.id, ...remaining].slice(0, MAX_LIST_LENGTH);
  setLocalStorage(storageKey, current);
};

/** Stateless retrieval of recently-viewed documents */
export const recentlyViewedDocuments = (organizationId: string): string[] =>
  getLocalStorage<string[]>(key(organizationId)) ?? [];
