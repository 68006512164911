import React, { FC } from "react";
import { Prompt } from "react-router";
import { Building, User } from "./DocumentSharing";

interface Props {
  users: [User[], User[]];
  buildings: [Building[], Building[]];
}

/**
 * Helper function to allow us to determine if two arrays of strings (ids) are equal
 */
export const containsIdentical = <T extends { id: string }>(
  input: T[],
  compare: T[]
): boolean => {
  return (
    JSON.stringify(input.map((i) => i.id).sort()) ===
    JSON.stringify(compare.map((c) => c.id).sort())
  );
};

const InterruptNavigation: FC<Props> = ({ users, buildings }) => {
  // If the users or buildings lists differ from the entity lists, interrupt
  const interruptNavigation =
    !containsIdentical(users[0], users[1]) ||
    !containsIdentical(buildings[0], buildings[1]);

  return (
    <Prompt
      when={interruptNavigation}
      message="Your sharing preferences are unsaved. Are you sure you want to continue?"
    />
  );
};

export default InterruptNavigation;
