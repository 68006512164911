import React from "react";
import { Control, Controller } from "react-hook-form";
import ButtonGroupSelect, { Props as ButtonsProps } from "./ButtonGroupSelect";
import SliderWithLabel, { Props as SliderProps } from "./SliderWithLabel";

export type Props = Omit<ButtonsProps & SliderProps, "value" | "onChange"> & {
  value: number;
  onChange: (v: number) => void;
};

const ButtonsWithSlider: React.FC<Props> = ({
  buttonConfig,
  value,
  onChange,
  ...rest
}) => (
  <>
    <ButtonGroupSelect
      buttonConfig={buttonConfig}
      value={value}
      onChange={(v) => onChange(v as number)}
    />
    <SliderWithLabel value={value} onChange={onChange} {...rest} />
  </>
);

export default ButtonsWithSlider;

export const ButtonsWithSliderController: React.FC<
  Omit<Props, "value" | "onChange"> & { name: string; control: Control }
> = ({ name, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ value, onChange }) => (
      <ButtonsWithSlider value={value} onChange={onChange} {...props} />
    )}
  />
);
