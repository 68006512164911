import { Box, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedDate } from "react-intl";
import { useHistory } from "react-router";
import { buildingInsights } from "../lib/endpoints";
import modelName from "../lib/modelName";
import removeEmpty from "../lib/removeEmpty";
import { presenceError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import AutocompleteController from "../ui/Autocomplete";
import DefaultModel from "../ui/DefaultModel";
import FormDialog from "../ui/FormDialog";
import MetaData from "../ui/MetaData";
import {
  AdjustedModel,
  Building,
  CalibratedModel,
} from "./BuildingInsightsReport";
import SetDefaultModelsButton from "./SetDefaultModelsButton";

const useStyles = makeStyles({
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

interface Props {
  building: Building;
  initialModel: CalibratedModel;
  comparisonModel: AdjustedModel;
}

interface Fields {
  initialSelected: CalibratedModel;
  compareSelected: AdjustedModel;
}

const validations = (values: Fields) =>
  removeEmpty({
    initialSelected: presenceError(values.initialSelected),
    compareSelected: presenceError(values.compareSelected),
  });

const ModelCompare: FC<Props> = ({
  building,
  initialModel,
  comparisonModel,
}) => {
  const { hover } = useStyles();
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { calibratedModels, id: buildingId } = building;

  const onSubmit = async ({ initialSelected, compareSelected }: Fields) => {
    setDialogOpen(false);
    history.push(buildingInsights({ buildingId }), {
      calibratedModelId: initialSelected.id,
      adjustedModelId: compareSelected.id,
    });
  };

  const { control, errors, handleSubmit, setValue, watch } = useForm<Fields>({
    defaultValues: {
      initialSelected: initialModel,
      compareSelected: comparisonModel,
    },
    resolver: validatorsResolver(validations),
  });

  const initialSelected = watch("initialSelected");

  useEffect(() => {
    setValue("compareSelected", null);
  }, [setValue, initialSelected]);

  const comparisonModels = useMemo(() => {
    if (initialSelected) {
      return (
        calibratedModels.find((m) => m.id === initialSelected.id)
          ?.adjustedModels || []
      );
    } else return [];
  }, [initialSelected, calibratedModels]);

  return (
    <>
      {dialogOpen && (
        <FormDialog
          title="Compare models"
          isSubmitting={false}
          onCancel={() => setDialogOpen(false)}
          onSubmit={handleSubmit(onSubmit)}
          submitName="Generate insights"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteController
                label="Select baseline"
                name="initialSelected"
                control={control}
                options={calibratedModels}
                getOptionLabel={(model) => modelName(model)}
                errorMessage={
                  !!errors.initialSelected &&
                  (errors.initialSelected as any).message
                }
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => (
                  <div>
                    {modelName(option)}
                    <Typography variant="caption">
                      <MetaData>
                        {option.default && (
                          <span>
                            <DefaultModel model={option} />
                          </span>
                        )}
                        <span>
                          Created:{" "}
                          <FormattedDate value={new Date(option.createdAt)} />{" "}
                          by {option.user?.name || "System"}
                        </span>
                      </MetaData>
                    </Typography>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteController
                disabled={!initialSelected}
                label="Select projected"
                name="compareSelected"
                control={control}
                options={comparisonModels}
                getOptionLabel={(model) => modelName(model)}
                errorMessage={
                  !!errors.compareSelected &&
                  (errors.compareSelected as any).message
                }
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => (
                  <div>
                    {modelName(option)}
                    <Typography variant="caption">
                      <MetaData>
                        {option.default && (
                          <span>
                            <DefaultModel model={option} />
                          </span>
                        )}
                        <span>
                          Created:{" "}
                          <FormattedDate value={new Date(option.createdAt)} />{" "}
                          by {option.user?.name || "System"}
                        </span>
                      </MetaData>
                    </Typography>
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </FormDialog>
      )}
      <Box>
        <Link
          component="span"
          className={hover}
          onClick={() => setDialogOpen(true)}
        >
          <Typography variant="body2" component="span">
            Now comparing: <b>{modelName(initialModel)}</b> to{" "}
            <b>{modelName(comparisonModel)}</b>
          </Typography>
        </Link>
        <Box display="inline" pl={1}>
          <SetDefaultModelsButton
            buildingId={building.id}
            initialModel={initialModel}
            comparisonModel={comparisonModel}
          />
        </Box>
      </Box>
    </>
  );
};

export default ModelCompare;
