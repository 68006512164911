import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { AdjustmentWithReadingModelFragment } from "../api/fragments/AdjustmentWithReadingModel.generated";
import { adjustmentReason } from "../lib/adjustmentReason";
import displayUnit from "../lib/displayUnit";
import Energy from "../lib/Energy";
import { energySourceName } from "../lib/energySource";
import FormattedRange from "../lib/FormattedRange";
import LabelledValue from "../lib/LabelledValue";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";

interface Props {
  entity: AdjustmentWithReadingModelFragment;
}

const AdjustmentDialog: React.FC<Props> = ({ entity }) => {
  const { formatNumber } = useIntl();
  const {
    reason,
    notes,
    quantity,
    unit,
    reading: { interval, energySource },
  } = entity;

  return (
    <Gaps spacing={2}>
      <FormSection label="Adjustment info">
        <Grid container>
          <Grid item xs={12}>
            <LabelledValue
              title="Affected dates"
              value={
                <Typography variant="body2">
                  <FormattedRange
                    from={new Date(interval.start)}
                    to={new Date(interval.end)}
                  />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <LabelledValue
              title="Quantity"
              value={
                <Typography variant="body2">
                  {formatNumber(new Energy({ quantity, unit }).quantity)}{" "}
                  {displayUnit(unit)}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <LabelledValue
              title="Energy source"
              value={
                <Typography variant="body2">
                  {energySourceName(energySource)}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </FormSection>
      <Divider />
      <FormSection label="Details">
        <Grid container>
          <Grid item xs={12}>
            <LabelledValue
              title="Reason"
              value={
                <Typography variant="body2">
                  {adjustmentReason(reason)}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LabelledValue
              title="Notes"
              value={<Typography variant="body2">{notes}</Typography>}
            />
          </Grid>
        </Grid>
      </FormSection>
    </Gaps>
  );
};

export default AdjustmentDialog;
