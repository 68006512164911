import { useTheme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import React from "react";
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  ResponsiveContainerProps,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export interface DataPoint {
  x: number;
  xLabel: React.ReactNode;
  y: number;
  yLabel: React.ReactNode;
}

interface Props {
  data: DataPoint[];
  xDomain: [number, number];
  xReferences?: number[];
  /** Renders the chart decorative in function */
  disableTooltip?: boolean;
}

const Sparkline: React.FC<
  Props & Omit<ResponsiveContainerProps, "children">
> = ({ data, xDomain, xReferences, disableTooltip, ...rest }) => {
  const { palette } = useTheme();
  const minY = Math.min(...data.map((d) => d.y));
  const maxY = Math.max(...data.map((d) => d.y));
  const [minX, maxX] = xDomain;

  return (
    <ResponsiveContainer aspect={10} {...rest}>
      <AreaChart data={data}>
        {xReferences &&
          xReferences.map((x) => <ReferenceLine key={x} x={x} stroke="#ddd" />)}
        <Area
          isAnimationActive={false}
          dataKey="y"
          stroke={palette.primary.main}
          animationDuration={500}
          dot={false}
          type="monotone"
          fill={fade(palette.primary.main, 0.5)}
        />
        <XAxis dataKey="x" type="number" hide domain={[minX, maxX]} />
        <YAxis hide domain={[minY, maxY]} />
        {!disableTooltip && (
          <Tooltip
            animationDuration={500}
            formatter={(_, __, { payload: { xLabel, yLabel } }) => [
              yLabel,
              xLabel,
            ]}
            labelFormatter={() => null}
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Sparkline;
