import { MutationResult, TypedDocumentNode, useMutation } from "@apollo/client";

/**
 * Manages deletion of most entites passed to it with `__typename` and `id` within the Apollo cache
 */
const useDeleteEntity = <T extends { __typename: string; id: string }, R, V>(
  deleteMutationDocument: TypedDocumentNode<R, V>
): [(entity: T, queryVariables: V) => Promise<void>, MutationResult<R>] => {
  const [deleteEntity, options] = useMutation(deleteMutationDocument);

  return [
    async (entity: T, queryVariables: V) => {
      try {
        await deleteEntity({
          variables: queryVariables,
          update(cache) {
            const entityToRemove = cache.identify({
              __typename: entity.__typename,
              id: entity.id,
            });
            cache.evict({ id: entityToRemove });
            cache.gc();
          },
        });
      } catch (err) {
        throw err;
      }
    },
    options,
  ];
};

export default useDeleteEntity;
