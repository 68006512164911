import { BuildingUseType } from "../api/graphql";

const BuildingUseTypeNames: Record<BuildingUseType, string> = {
  [BuildingUseType.BANK_BRANCH]: "Bank Branch",
  [BuildingUseType.FINANCIAL_OFFICE]: "Financial Office",
  [BuildingUseType.ADULT_EDUCATION]: "Adult Education",
  [BuildingUseType.COLLEGE_OR_UNIVERSITY]: "College/University",
  [BuildingUseType.K12_SCHOOL]: "K-12 School",
  [BuildingUseType.PRESCHOOL_OR_DAYCARE]: "Pre-school/Daycare",
  [BuildingUseType.VOCATIONAL_SCHOOL]: "Vocational School",
  [BuildingUseType.BAR_OR_NIGHTCLUB]: "Bar/Nightclub",
  [BuildingUseType.FAST_FOOD_RESTAURANT]: "Fast Food Restaurant",
  [BuildingUseType.RESTAURANT]: "Restaurant",
  [BuildingUseType.OTHER_RESTAURANT_OR_BAR]: "Other - Restaurant/Bar",
  [BuildingUseType.CONVENTION_CENTER]: "Convention Center",
  [BuildingUseType.SOCIAL_OR_MEETING_HALL]: "Social/Meeting Hall",
  [BuildingUseType.CONVENIENCE_STORE_WITH_GAS_STATION]:
    "Convenience Store with Gas Station",
  [BuildingUseType.CONVENIENCE_STORE_WITHOUT_GAS_STATION]:
    "Convenience Store without Gas Station",
  [BuildingUseType.GROCERY_STORE]: "Grocery Store",
  [BuildingUseType.WHOLESALE_CLUB_OR_SUPERCENTER]: "Wholesale Club/Supercenter",
  [BuildingUseType.HOSPITAL_GENERAL]: "Hospital - General",
  [BuildingUseType.HOSPITAL_OTHER]: "Hospital - Other",
  [BuildingUseType.HOSPITAL_SPECIALTY]: "Hospital - Specialty",
  [BuildingUseType.HOSPITAL_SURGICAL]: "Hospital - Surgical",
  [BuildingUseType.MEDICAL_OFFICE]: "Medical Office",
  [BuildingUseType.OUTPATIENT_REHAB_OR_PHYSICAL_THERAPY]:
    "Outpatient Rehab/Physical Therapy",
  [BuildingUseType.RESIDENTIAL_CARE_FACILITY]: "Residential Care Facility",
  [BuildingUseType.SENIOR_CARE_COMMUNITY]: "Senior Care Community",
  [BuildingUseType.URGENT_CARE_OR_CLINIC_OR_OTHER_OUTPATIENT]:
    "Urgent Care/Clinic/Other Outpatient",
  [BuildingUseType.BARRACKS]: "Barracks",
  [BuildingUseType.HOTEL]: "Hotel",
  [BuildingUseType.MULTIFAMILY_HOUSING]: "Multifamily Housing",
  [BuildingUseType.RESIDENCE_HALL_OR_DORMITORY]: "Residence Hall/Dormitory",
  [BuildingUseType.OFFICE]: "Office",
  [BuildingUseType.VETERINARY_OFFICE]: "Veterinary Office",
  [BuildingUseType.COURTHOUSE]: "Courthouse",
  [BuildingUseType.FIRE_STATION]: "Fire Station",
  [BuildingUseType.LIBRARY]: "Library",
  [BuildingUseType.MAILING_CENTER_OR_POST_OFFICE]: "Mailing Center/Post Office",
  [BuildingUseType.POLICE_STATION]: "Police Station",
  [BuildingUseType.PRISON_OR_INCARCERATION]: "Prison/Incarceration",
  [BuildingUseType.TRANSPORTATION_TERMINAL_OR_STATION]:
    "Transportation Terminal/Station",
  [BuildingUseType.AUTOMOBILE_DEALERSHIP]: "Automobile Dealership",
  [BuildingUseType.ENCLOSED_MALL]: "Enclosed Mall",
  [BuildingUseType.LIFESTYLE_CENTER]: "Lifestyle Center",
  [BuildingUseType.STRIP_MALL]: "Strip Mall",
  [BuildingUseType.RETAIL_STORE]: "Retail Store",
  [BuildingUseType.LABORATORY]: "Laboratory",
  [BuildingUseType.TECHNOLOGY_OR_SCIENCE]: "Technology/Science",
  [BuildingUseType.SELFSTORAGE_FACILITY]: "Self-Storage Facility",
  [BuildingUseType.DISTRIBUTION_CENTER]: "Distribution Center",
  [BuildingUseType.NONREFRIGERATED_WAREHOUSE]: "Non-Refrigerated Warehouse",
  [BuildingUseType.REFRIGERATED_WAREHOUSE]: "Refrigerated Warehouse",
  [BuildingUseType.OTHER]: "Other",
};

const normalizeName = (name: string): string => name.toLowerCase();

const BuildingUseTypesByName: Record<string, BuildingUseType> = Object.entries(
  BuildingUseTypeNames
).reduce((memo, [k, v]) => ({ ...memo, [normalizeName(v)]: k }), {});

export const buildingUseTypeByName = (
  name: string
): BuildingUseType | undefined => BuildingUseTypesByName[normalizeName(name)];
