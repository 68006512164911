import { useQuery } from "@apollo/client";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import millify from "millify";
import React, { FC } from "react";
import {
  calculateInvestmentStatus,
  calculateIRR,
} from "../FinancialModel/finance";
import {
  calculateRevenue,
  createCashFlows,
  createEnergySourceConfigs,
} from "../FinancialModel/helpers";
import IrrStatus from "../FinancialModelsList/IrrStatus";
import {
  financialModelById,
  financialModels as financialModelsPath,
} from "../lib/endpoints";
import Energy from "../lib/Energy";
import pluralize from "../lib/pluralize";
import sum from "../lib/sum";
import { SkeletonListItem, SkeletonTypography } from "../ui/Bones";
import { CardLinkedArea } from "../ui/Card";
import DashboardCard from "../ui/DashboardCard";
import MetaData from "../ui/MetaData";
import PlaceholderSegment from "../ui/PlaceholderSegment";
import ProductCopy from "../ui/ProductCopy";
import { KeyMetricSmall } from "../ui/Typography";
import {
  FinancialModelsDocument,
  FinancialModelsQuery,
} from "./FinancialModelsQuery.generated";
import ViewMoreLink from "./ViewMoreLink";
interface Props {
  buildingId: string;
}

type Model = FinancialModelsQuery["building"]["financialModels"][number];

const FinancialModelsSkeleton: FC = () => {
  return (
    <DashboardCard label={<SkeletonTypography size="medium" />}>
      <List disablePadding>
        <SkeletonListItem />
        <Divider />
        <SkeletonListItem />
        <Divider />
        <SkeletonListItem />
      </List>
    </DashboardCard>
  );
};

const ModelListItem: FC<{ model: Model; buildingId: string }> = ({
  model,
  buildingId,
}) => {
  // reconstruct energySourceConfigs
  const energySourceConfigs = (() => {
    const sources = model.calibratedModel.annualizedEnergySources.map((e) => ({
      energySource: e.energySource,
      unit: e.energy.unit,
      baseline: e.energy.quantity,
    }));

    return createEnergySourceConfigs(
      sources.map(({ energySource, baseline, unit }) => ({
        energySource,
        baseline: new Energy({ quantity: baseline, unit }),
      })),
      model.energyEfficiencyDepths,
      model.customRates || model.defaultRates
    );
  })();

  // key metric: EE Value
  const eeValue = sum(
    energySourceConfigs.map((config) => calculateRevenue(config, model.term))
  );

  // key metric: IRR
  const irr = (() => {
    const averageAnnualCashFlows = createCashFlows(
      energySourceConfigs,
      model.term,
      0,
      model.cashFlows,
      0
    ).map((d) => d.average);

    return calculateIRR(model.projectCost.value, averageAnnualCashFlows);
  })();

  // key metric: IRR status
  const status = calculateInvestmentStatus(
    (irr || 0) * 100,
    model.discountRate * 100
  );

  return (
    <>
      <CardLinkedArea
        to={financialModelById({ buildingId, financialModelId: model.id })}
      >
        <ListItem>
          <Box
            width={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ListItemText>
              <Typography>
                <b>{model.name}</b>
              </Typography>
              <MetaData>
                <span>{model.term}-year term</span>
              </MetaData>
            </ListItemText>
            <div>
              <Grid container spacing={3}>
                <Grid item>
                  <KeyMetricSmall align="right" color="primary">
                    ${millify(eeValue)}
                  </KeyMetricSmall>
                  <Typography variant="body2" color="textSecondary">
                    Energy efficiency value
                  </Typography>
                </Grid>
                <Grid item>
                  <KeyMetricSmall align="right" color="primary">
                    ${millify(model.projectCost.value)}
                  </KeyMetricSmall>
                  <Typography variant="body2" color="textSecondary">
                    Capital investment
                  </Typography>
                </Grid>
                <Grid item>
                  <IrrStatus status={status} irr={irr} />
                  <Typography variant="body2" color="textSecondary">
                    Internal rate of return (IRR)
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Box>
        </ListItem>
      </CardLinkedArea>
      <Divider />
    </>
  );
};

const FinancialModels: FC<Props> = ({ buildingId }) => {
  const { data, loading, error } = useQuery(FinancialModelsDocument, {
    variables: { buildingId },
  });

  if (error) throw error;

  if (loading || !data) {
    return <FinancialModelsSkeleton />;
  }

  const { building } = data;
  const { financialModels } = building;

  // If we don't have any models, display some CTA or fallback
  if (!financialModels || financialModels.length === 0) {
    return (
      <DashboardCard label={ProductCopy.FINANCIAL_MODELS}>
        <PlaceholderSegment
          fadeText
          icon={ShowChartIcon}
          header="Financial models unavailable"
        />
      </DashboardCard>
    );
  }

  const displayModels = financialModels.slice(0, 3);
  const remainder = financialModels.length - displayModels.length;

  return (
    <DashboardCard label={ProductCopy.FINANCIAL_MODELS}>
      <List disablePadding>
        {displayModels.map((fm) => {
          return (
            <ModelListItem key={fm.id} model={fm} buildingId={buildingId} />
          );
        })}
      </List>
      <ViewMoreLink path={financialModelsPath({ buildingId })}>
        {remainder > 0
          ? `${remainder} more ${pluralize("model", remainder)}`
          : "Manage financial models"}
      </ViewMoreLink>
    </DashboardCard>
  );
};

export default FinancialModels;
