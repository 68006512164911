const deltaMeterColors = {
  transparent: "transparent",
  red: "#C23B34",
  blue: {
    main: "#007CBA",
    light: "#69CDFF",
  },
  yellow: "#fc8f28",
  green: {
    light: "#B4E8DB",
    main: "#06AD84",
  },
  gray: {
    light: "#DEDEDE",
    dark: "#9C9C9C",
  },
};

export default deltaMeterColors;
