import { EnergySource } from "../api/graphql";
import { sortBy } from "../lib/sortBy";
import colors from "../ui/colors";
import energySourceOrder from "./energySourceOrder";

/**
 * Insights charts use this order to plot colors in a predictable way,
 * rather than tying them to energy sources
 */
// TODO: Figure out the other colors
const insightsColorOrder = [
  colors.blue.main,
  colors.orange.main,
  colors.orange.light,
  colors.gray.dark,
  colors.gray.light,
];

const assignedEnergyColor = (
  sources: EnergySource[]
): {
  energySource: EnergySource;
  color: string;
}[] => {
  return sortBy(sources, energySourceOrder).map((e, idx) => ({
    energySource: e,
    color: insightsColorOrder[idx % insightsColorOrder.length],
  }));
};

export default assignedEnergyColor;
