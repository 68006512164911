import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EnergyEndUse, EnergySource } from "../api/graphql";
import { endUseName, energyEndUses } from "../lib/endUse";
import { energySourceName } from "../lib/energySource";
import formatter from "../lib/formatter";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
  yAxisOffset,
} from "../lib/reporting/config";
import { calculateEndUseSum, toCurrency } from "../lib/reporting/dataTable";
import { EndUse as AnnualizedEndUse } from "../lib/types/Reports";
import unique from "../lib/unique";
import colors from "../ui/colors";
import assignedEnergyColor from "./assignedEnergyColor";
import { Rates } from "./BuildingInsightsReport";
import CustomAxisTick from "./CustomAxisTick";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  initialModel: AnnualizedEndUse[];
  comparisonModel: AnnualizedEndUse[];
  rates: Rates;
}

type DataPoint = {
  name: string;
  bar: [number, number];
  energySource?: EnergySource;
  color: string;
};

export function formatReport(
  baseline: AnnualizedEndUse[],
  comparison: AnnualizedEndUse[],
  rates: Rates
): DataPoint[] {
  const energySources = unique(baseline.map((d) => d.energySource));

  // merge baseline and comparison together by [endUse, energySource]
  const annualized = energyEndUses.flatMap((endUse) =>
    assignedEnergyColor(energySources).map(({ energySource, color }) => {
      const baselineEnergy = baseline.find(
        (d) => d.energySource === energySource && d.endUse === endUse
      )!.energy;

      const comparisonEnergy = comparison.find(
        (d) => d.energySource === energySource && d.endUse === endUse
      )!.energy;

      const calcCurrency = toCurrency(rates);

      return {
        endUse,
        energySource,
        baseline: calcCurrency(baselineEnergy, energySource),
        comparison: calcCurrency(comparisonEnergy, energySource),
        color,
      };
    })
  );

  // build waterfall of deltas, anchored by baseline and comparison
  const start = calculateEndUseSum(baseline, toCurrency(rates));
  let runningTotal = start;
  return [
    {
      name: "Baseline",
      bar: [start, 0],
      color: colors.gray.main,
    },
    ...annualized
      .filter(({ baseline, comparison }) => baseline !== comparison)
      .map(({ endUse, energySource, baseline, comparison, color }) => {
        const dataPoint = {
          name: endUseName(endUse),
          bar: [runningTotal + comparison - baseline, runningTotal] as [
            number,
            number
          ],
          energySource,
          color,
        };
        runningTotal = dataPoint.bar[0];
        return dataPoint;
      }),
    {
      name: "Projected",
      bar: [runningTotal, 0],
      color: colors.green.main,
    },
  ];
}

const EndUsesDeltasChart: React.FC<Props> = ({
  initialModel,
  comparisonModel,
  rates,
  aspect,
}) => {
  const report = formatReport(initialModel, comparisonModel, rates);

  return (
    <ResponsiveContainer aspect={aspect}>
      <BarChart data={report} barCategoryGap={0}>
        <XAxis
          dataKey="name"
          tick={(props) => (
            <CustomAxisTick
              formatter={(v: EnergyEndUse) => endUseName(v) || v}
              {...props}
            />
          )}
          interval={0}
          tickLine={false}
        />
        <YAxis tickFormatter={formatter} tickLine={false} tick={{ fontSize }}>
          <Label
            value="USD ($)"
            position="insideLeft"
            angle={-90}
            offset={yAxisOffset}
            fontSize={fontSize}
          />
        </YAxis>
        <Tooltip
          isAnimationActive={tooltipAnimationActive}
          content={
            <CustomTooltip
              bodyOverride={(p) => {
                let displayVal;
                if (Array.isArray(p.value)) {
                  displayVal = `$${formatter(p.value[0] - p.value[1])}`;
                }
                const prefix = p.payload!.energySource
                  ? energySourceName(p.payload!.energySource) + ": "
                  : "";

                return `${prefix}${displayVal}`;
              }}
            />
          }
        />
        <Bar dataKey="bar" isAnimationActive={chartAnimationActive}>
          {report.map((point: DataPoint) => (
            <Cell key={point.name} fill={point.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EndUsesDeltasChart;
