import { Box, Collapse, styled, Typography } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import millify from "millify";
import React, { Dispatch, FC, useState } from "react";
import { BuildingModelFit, EnergyUnitType } from "../api/graphql";
import { energySourceName } from "../lib/energySource";
import displayLeaseType from "../lib/LeaseType";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import usaStates from "../lib/usaStates";
import BrandRangeSlider from "../ui/BrandRangeSlider";
import { modelFit as modelFitColor } from "../ui/colors";
import {
  dollars,
  dollarsPerFootSquared,
  energyPerFootSquared,
  perFootSquared,
} from "../ui/labelFormatters";
import { SearchField } from "../ui/SearchField";
import SelectField, { SelectItem } from "../ui/SelectField";
import { CheckboxList } from "./CheckboxListFilter";
import { Filters, ModelFit } from "./filterReducer";
import LogicalOperatorDropdown from "./LogicalOperatorDropdown";
import { SortBy } from "./sortReducer";

const ScrollContainer = styled("div")(({ theme: { spacing } }) => ({
  height: "inherit",
  overflow: "auto",
  scrollbarWidth: "thin",
  paddingLeft: spacing(2),
  paddingRight: spacing(3),
}));

const CollapseHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(1),
  "&:hover": {
    cursor: "pointer",
  },
}));

const modelFitDisplay = (fit: ModelFit): string => {
  switch (fit) {
    case "NONE":
      return "Missing required data";
    case BuildingModelFit.POOR:
      return "Inadequate model";
    case BuildingModelFit.MODERATE:
      return "Moderate model";
    case BuildingModelFit.GOOD:
      return "Good model";
    default:
      return fit;
  }
};

const Label: FC<{ title: string }> = ({ title }) => (
  <Typography variant="body2">{title}</Typography>
);

interface Props {
  filters: Filters;
  defaultFormOptions: Filters;
  onFilterChange: Dispatch<React.SetStateAction<Filters>>;
}

const FilterForm: FC<Props> = ({
  filters,
  defaultFormOptions,
  onFilterChange,
}) => {
  const [showOpportunityFilters, collapseOpportunityFilters] = useState<
    boolean
  >(true);
  const [showBuildingInfoFilters, collapseBuildingInfoFilters] = useState<
    boolean
  >(true);

  const {
    eeEnergyPerSquareUnit,
    eeMoneyPerSquareUnit,
    eeMoney,
    eePercent,
    buildingSize,
    buildingUseType,
    energySources,
    leaseType,
    location,
    majorTenant,
    modelFit,
    yearBuilt,
  } = defaultFormOptions;

  return (
    <ScrollContainer>
      <SearchField
        onFocus={({ target }) => target.select()}
        placeholder="Search building name"
        value={filters.buildingName}
        onChange={(v) => onFilterChange({ ...filters, buildingName: v })}
      />
      <Box py={2}>
        <SelectField
          variant="outlined"
          label="Sort by"
          value={filters.sortBy}
          onChange={(v) => onFilterChange({ ...filters, sortBy: v as SortBy })}
        >
          <SelectItem value="eeMoney">Annual yield ($)</SelectItem>
          <SelectItem value="eeEnergy">Annual yield (kBTU)</SelectItem>
          <SelectItem value="eeMoneyPerSquareUnit">
            Annual yield ($/ft²)
          </SelectItem>
          <SelectItem value="eeEnergyPerSquareUnit">
            Annual yield (kBTU/ft²)
          </SelectItem>
          <SelectItem value="eeMoneyPercent">Annual yield (%)</SelectItem>
          <SelectItem value="buildingName">Building name</SelectItem>
          <SelectItem value="buildingSize">Building size</SelectItem>
          <SelectItem value="eePercent">Efficiency depth (%)</SelectItem>
          <SelectItem value="eui">Energy use intensity (EUI)</SelectItem>
        </SelectField>
      </Box>
      <CollapseHeader
        onClick={() => collapseBuildingInfoFilters(!showBuildingInfoFilters)}
      >
        <Typography variant="h6" color="textSecondary">
          Opportunity analysis
        </Typography>
        {showBuildingInfoFilters ? <ExpandLess /> : <ExpandMore />}
      </CollapseHeader>
      <Collapse in={showBuildingInfoFilters}>
        {eeMoney[0] !== eeMoney[1] && (
          <Box pb={2}>
            <Label title="Annual yield ($)" />
            <Box px={1}>
              <BrandRangeSlider
                value={filters.eeMoney}
                onChange={(v) => onFilterChange({ ...filters, eeMoney: v })}
                min={eeMoney[0]}
                max={eeMoney[1]}
                labelFormatter={dollars}
                valueLabelFormat={(v) => millify(v, { precision: 1 })}
              />
            </Box>
          </Box>
        )}
        {eeEnergyPerSquareUnit[0] !== eeEnergyPerSquareUnit[1] && (
          <Box pb={2}>
            <Label title="Annual yield (kBTU/ft²)" />
            <Box px={1}>
              <BrandRangeSlider
                value={filters.eeEnergyPerSquareUnit}
                onChange={(v) =>
                  onFilterChange({ ...filters, eeEnergyPerSquareUnit: v })
                }
                min={eeEnergyPerSquareUnit[0]}
                max={eeEnergyPerSquareUnit[1]}
                labelFormatter={energyPerFootSquared(EnergyUnitType.KBTU)}
              />
            </Box>
          </Box>
        )}
        {eeMoneyPerSquareUnit[0] !== eeMoneyPerSquareUnit[1] && (
          <Box pb={2}>
            <Label title="Annual yield ($/ft²)" />
            <Box px={1}>
              <BrandRangeSlider
                value={filters.eeMoneyPerSquareUnit}
                onChange={(v) =>
                  onFilterChange({ ...filters, eeMoneyPerSquareUnit: v })
                }
                min={eeMoneyPerSquareUnit[0]}
                max={eeMoneyPerSquareUnit[1]}
                labelFormatter={dollarsPerFootSquared}
                step={0.25}
              />
            </Box>
          </Box>
        )}
        {eePercent[0] !== eePercent[1] && (
          <Box pb={2}>
            <Label title="Energy depth (%)" />
            <Box px={1}>
              <BrandRangeSlider
                value={filters.eePercent}
                onChange={(v) => onFilterChange({ ...filters, eePercent: v })}
                min={eePercent[0]}
                max={eePercent[1]}
                labelFormatter={(v) => `${v}%`}
              />
            </Box>
          </Box>
        )}
      </Collapse>
      <CollapseHeader
        onClick={() => collapseOpportunityFilters(!showOpportunityFilters)}
      >
        <Typography variant="h6" color="textSecondary">
          Building information
        </Typography>
        {showOpportunityFilters ? <ExpandLess /> : <ExpandMore />}
      </CollapseHeader>
      <Collapse in={showOpportunityFilters}>
        <Box pb={2}>
          <CheckboxList
            title="Building type"
            options={buildingUseType}
            labelFormatter={(v) => primaryFunctionTitle(v)}
            value={filters.buildingUseType}
            onChange={(v) => onFilterChange({ ...filters, buildingUseType: v })}
          />
        </Box>
        <Box pb={2}>
          <CheckboxList
            title="State"
            showSearchBar
            options={location}
            labelFormatter={(v) =>
              usaStates.find((s) => s.abbreviation === v)?.name || ""
            }
            value={filters.location}
            onChange={(v) => onFilterChange({ ...filters, location: v })}
          />
        </Box>
        {buildingSize[0] !== buildingSize[1] && (
          <Box pb={2}>
            <Label title="Building size (ft²)" />
            <Box px={1}>
              <BrandRangeSlider
                value={filters.buildingSize}
                onChange={(v) =>
                  onFilterChange({ ...filters, buildingSize: v })
                }
                min={buildingSize[0]}
                max={buildingSize[1]}
                labelFormatter={perFootSquared}
                valueLabelFormat={(v) => millify(v, { precision: 1 })}
              />
            </Box>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">Energy sources</Typography>
          <LogicalOperatorDropdown
            value={filters.energySourcesLogicalOperator}
            onChange={(v) =>
              onFilterChange({ ...filters, energySourcesLogicalOperator: v })
            }
          />
        </Box>
        <Box pb={2}>
          <CheckboxList
            options={energySources}
            labelFormatter={(v) => energySourceName(v)}
            value={filters.energySources}
            onChange={(v) => onFilterChange({ ...filters, energySources: v })}
            disableReset
          />
        </Box>
        {leaseType.length > 0 && (
          <Box pb={2}>
            <CheckboxList
              title="Lease type"
              options={leaseType}
              labelFormatter={(v) => displayLeaseType(v)}
              value={filters.leaseType}
              onChange={(v) => onFilterChange({ ...filters, leaseType: v })}
            />
          </Box>
        )}
        {majorTenant.length > 0 && (
          <Box pb={2}>
            <CheckboxList
              title="Major tenant"
              showSearchBar
              options={majorTenant}
              value={filters.majorTenant}
              onChange={(v) => onFilterChange({ ...filters, majorTenant: v })}
            />
          </Box>
        )}
        {yearBuilt[0] !== yearBuilt[1] && (
          <Box pb={2}>
            <Label title="Year built" />
            <Box px={1}>
              <BrandRangeSlider
                value={filters.yearBuilt}
                onChange={(v) => onFilterChange({ ...filters, yearBuilt: v })}
                min={yearBuilt[0]}
                max={yearBuilt[1]}
              />
            </Box>
          </Box>
        )}
        <Box pb={2}>
          <CheckboxList
            title="Building model fit"
            options={modelFit}
            value={filters.modelFit}
            onChange={(v) => onFilterChange({ ...filters, modelFit: v })}
            labelFormatter={(v) => (
              <Box component="span" display="flex" alignContent="center">
                {modelFitDisplay(v)}
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{
                    fill:
                      v === "NONE"
                        ? modelFitColor.missing
                        : v === "GOOD"
                        ? modelFitColor.good
                        : v === "MODERATE"
                        ? modelFitColor.moderate
                        : modelFitColor.inadequate,
                  }}
                />
              </Box>
            )}
          />
        </Box>
      </Collapse>
    </ScrollContainer>
  );
};

export default FilterForm;
