import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { CardContent } from "../ui/Card";
import DashboardCard from "../ui/DashboardCard";
import Loading from "../ui/Loading";
import ModelDetails from "./ModelDetails";
import { ModelParametersQueryDocument } from "./ModelParametersQuery.generated";

interface Props {
  calibratedModelId: string;
}

const ModelParameters: FC<Props> = ({ calibratedModelId }) => {
  const { data, loading, error } = useQuery(ModelParametersQueryDocument, {
    variables: { id: calibratedModelId },
  });

  if (error) throw error;

  // TODO: Skeletonize!
  if (loading || !data) return <Loading variant="circle" />;

  const { calibratedModel: model } = data;

  return (
    <>
      <DashboardCard label="Model parameters">
        <CardContent>
          <ModelDetails parameters={model.buildingModel.parameters} />
        </CardContent>
      </DashboardCard>
      {/* <pre>{JSON.stringify({ overrides: model.overrides }, null, 2)}</pre> */}
    </>
  );
};

export default ModelParameters;
