import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useSessionWithUser } from "../SessionWithUser";
import { PageTitle } from "../ui/Typography";

const Greeting: FC<{}> = () => {
  const { organization, user } = useSessionWithUser();

  return (
    <>
      <PageTitle>Welcome back, {user.name}</PageTitle>
      <Typography component="span" color="textSecondary">
        Here's an overview of the {organization.name} organization
      </Typography>
    </>
  );
};

export default Greeting;
