/**
 * sortBy Sorts an array by the provided ranking function and ordering argument
 */
export function sortBy<T>(
  array: Readonly<T[]>,
  fn: (t: T) => number,
  direction: "asc" | "desc" = "asc"
): T[] {
  return array.slice().sort(sorter(fn, direction));
}

/**
 * sorter creates an (a, b) sorting function
 */
export function sorter<T>(
  fn: (t: T) => number,
  direction: "asc" | "desc" = "asc"
): (a: T, b: T) => number {
  return (a, b) => (direction === "desc" ? fn(b) - fn(a) : fn(a) - fn(b));
}
