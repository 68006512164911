import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { ReactNode } from "react";
import Button from "./Button";

interface Props {
  title: ReactNode;
  confirmText: string;
  width: "sm" | "md";
  onClose: () => void;
}

const InfoDialog: React.FC<Props> = ({
  children,
  title,
  confirmText,
  width,
  onClose,
}) => {
  return (
    <Dialog open fullWidth maxWidth={width} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button size="small" primary onClick={onClose}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
