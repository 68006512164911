import { Box, Grid, Paper } from "@material-ui/core";
import React from "react";
import { PageTitle } from "../ui/Typography";
import MyAccountPassword from "./MyAccountPassword";
import MyUserInvites from "./MyUserInvites";

interface Props {}

const MyAccount: React.FC<Props> = () => {
  return (
    <>
      <Box pb={1}>
        <PageTitle>My Account</PageTitle>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined">
            <Box p={2}>
              <MyAccountPassword />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined">
            <Box p={2}>
              <MyUserInvites />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MyAccount;
