import {
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { FC, useEffect, useRef, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { useIntl } from "react-intl";
import safeNumber from "../lib/safeNumber";

type ControlledMoneyFieldProps = Omit<
  TextFieldProps,
  | "value"
  | "onChange"
  | "defaultValue"
  | "error"
  | "helperText"
  | "type"
  | "size"
> & {
  value: number;
  onChange: (v: number) => void;
  /** The number of decimal places to show */
  precision: number;
  errorMsg?: string;
};

// Returns an integer value from a given currency string, representing cents
const numberFromCurrencyString = (currency: string): number =>
  parseFloat(currency.replace(".", "").split(",").join("")) || 0;

const StyledTextField = styled(TextField)({
  "& .MuiInput-input": {
    textAlign: "right",
  },
});

export const ControlledMoneyField: FC<ControlledMoneyFieldProps> = ({
  value,
  onChange,
  errorMsg,
  precision,
  ...props
}) => {
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const { formatNumber } = useIntl();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!inputRef.current || cursorPosition === null) {
      return;
    }
    const positionFromStart = inputRef.current.value.length - cursorPosition;
    inputRef.current.setSelectionRange(positionFromStart, positionFromStart);
  }, [cursorPosition, value]);

  return (
    <StyledTextField
      value={formatNumber(value ?? 0, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })}
      onChange={(e) => {
        setCursorPosition(
          e.target.value.length - (e.target.selectionStart || 0)
        );
        const num = numberFromCurrencyString(
          e.target.value.replace(/[^\d.-]/g, "")
        );
        if (safeNumber(num)) {
          onChange(num / Math.pow(10, precision));
        }
      }}
      size="small"
      fullWidth
      error={!!errorMsg}
      helperText={errorMsg}
      onFocus={(e) => e.target.select()}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      inputRef={inputRef}
      {...props}
    />
  );
};

type MoneyFieldProps = Omit<ControlledMoneyFieldProps, "value" | "onChange"> & {
  control: Control;
  name: string;
  defaultValue?: number;
  rules?: RegisterOptions;
};

/**
 * MoneyField is a numeric input type that parses and formats currency.
 */
const MoneyField: FC<MoneyFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  id,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || null}
      render={({ value, onChange }) => (
        <ControlledMoneyField
          value={value}
          onChange={onChange}
          id={id || name}
          {...props}
        />
      )}
    />
  );
};

export default MoneyField;
