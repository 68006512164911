import { useQuery } from "@apollo/client";
import { CardContent } from "@material-ui/core";
import React, { FC } from "react";
import SignatureChart from "../BuildingInsights/EnergySignatureChart";
import DashboardCard from "../ui/DashboardCard";
import Loading from "../ui/Loading";
import { EnergySignatureChartQueryDocument } from "./EnergySignatureChartQuery.generated";

interface Props {
  calibratedModelId: string;
}

const EnergySignatureChart: FC<Props> = ({ calibratedModelId }) => {
  const { data, loading, error } = useQuery(EnergySignatureChartQueryDocument, {
    variables: { id: calibratedModelId },
  });

  if (error) throw error;

  // TODO: Skeletonize!
  if (loading || !data) return <Loading variant="circle" />;

  const ASPECT = 20 / 9;
  const { calibratedModel: model } = data;

  return (
    <DashboardCard label="Baseline Model Fit, By Fuel">
      <CardContent>
        <SignatureChart
          aspect={ASPECT}
          buildingSquareFeet={model.building.squareFeet}
          data={model.reference}
        />
      </CardContent>
    </DashboardCard>
  );
};

export default EnergySignatureChart;
