import { localPoint } from "@visx/event";
import { useTooltip } from "@visx/tooltip";
import { ScaleLinear, ScaleTime } from "d3-scale";
import { MouseEvent } from "react";
interface HookArgs<T> {
  xScale: ScaleTime<number, number> | ScaleLinear<number, number>;
  xAccessor: (d: T) => number;
  offset: number;
  data: T[];
}

const useChartTooltip = <T extends {}>({
  xScale,
  offset,
  data,
  xAccessor,
}: HookArgs<T>) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
  } = useTooltip<T>();

  const handleTooltip = (event: MouseEvent) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    const x0 = xScale.invert(x - offset);

    const dateDiffs = data.map((d) => Math.abs(x0.valueOf() - xAccessor(d)));

    const closestIdx = dateDiffs.findIndex(
      (el) => el === Math.min(...dateDiffs)
    );
    const d = data[closestIdx];

    showTooltip({
      tooltipLeft: xScale(xAccessor(d)),
      tooltipTop: y,
      tooltipData: d,
    });
  };

  return { handleTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop };
};

export default useChartTooltip;
