import * as Types from '../api/graphql';

import { BuildingModelFragment } from '../api/fragments/BuildingModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { BuildingModelFragmentDoc } from '../api/fragments/BuildingModel.generated';
export type BuildingMetadataQueryQueryVariables = Types.Exact<{
  buildingId: Types.Scalars['ID'];
}>;


export type BuildingMetadataQueryQuery = { building: (
    { __typename: 'Building', organization: { __typename: 'Organization', id: string } }
    & BuildingModelFragment
  ) };


export const BuildingMetadataQueryDocument: DocumentNode<BuildingMetadataQueryQuery, BuildingMetadataQueryQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BuildingMetadataQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"buildingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"building"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"buildingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BuildingModel"}}]}}]}},...BuildingModelFragmentDoc.definitions]};