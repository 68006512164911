import { useState } from "react";

type AuthNErrors = {
  field: string;
  message: string;
}[];

function isAuthNErrors(e: unknown): e is AuthNErrors {
  return Array.isArray(e) && e[0].field && e[0].message;
}

export default function useAuthNErrors(): [
  string[],
  (cb: () => Promise<void>) => void
] {
  const [errors, setErrors] = useState<string[]>([]);
  return [
    errors,
    async (cb: () => void) => {
      try {
        await cb();
        setErrors([]);
      } catch (e) {
        if (isAuthNErrors(e)) {
          setErrors(e.map(({ field, message }) => `${field}_${message}`));
        } else throw e;
      }
    },
  ];
}
