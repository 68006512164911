import * as Types from '../api/graphql';

import { UserInviteModelFragment } from '../api/fragments/UserInviteModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { UserInviteModelFragmentDoc } from '../api/fragments/UserInviteModel.generated';
export type DeleteUserInviteMutationVariables = Types.Exact<{
  input: Types.DeleteUserInviteInput;
}>;


export type DeleteUserInviteMutation = { deleteUserInvite: { __typename: 'DeleteUserInviteResponse', userInvite: Types.Maybe<(
      { __typename: 'UserInvite' }
      & UserInviteModelFragment
    )> } };


export const DeleteUserInviteDocument: DocumentNode<DeleteUserInviteMutation, DeleteUserInviteMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUserInvite"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteUserInviteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUserInvite"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userInvite"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserInviteModel"}}]}}]}}]}},...UserInviteModelFragmentDoc.definitions]};