import { FormHelperText } from "@material-ui/core";
import React from "react";

interface Props {
  errors: string[];
  messages: {
    [key: string]: string;
    default: string;
  };
}

const ServerErrors: React.FC<Props> = ({ errors, messages }) => {
  if (errors.length === 0) return null;

  return (
    <>
      {errors.map((e) => (
        <FormHelperText error>{messages[e] || messages.default}</FormHelperText>
      ))}
    </>
  );
};

export default ServerErrors;
