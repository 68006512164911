export default function createTicks(
  dataMin: number,
  dataMax: number,
  { interval }: { interval: number }
): number[] {
  const min = dataMin - (dataMin % interval);
  const max =
    dataMax + (interval - 1) - ((dataMax + (interval - 1)) % interval);
  const steps = (max - min) / interval + 1;
  return new Array(steps).fill(0).map((_, i) => min + i * interval);
}
