import LuxonUtils from "@date-io/luxon";
import { makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import Lockable from "./Lockable";

interface PropOverrides {
  label?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  error?: string;
  suppressInternalErrors?: boolean;
  locked?: boolean;
  lockExplanation?: string;
}

export type Props = Omit<KeyboardDatePickerProps, keyof PropOverrides> &
  PropOverrides;

const useStyles = makeStyles(() => ({
  width: {
    width: "100%",
    minWidth: "150px",
  },
}));

const DatePicker: React.FC<Props> = ({
  value,
  onChange,
  error,
  suppressInternalErrors,
  locked,
  lockExplanation,
  label,
  ...rest
}) => {
  const { width } = useStyles();

  // let MUI's date picker errors show until we have our own to show instead
  const errorProps = error
    ? {
        helperText: error,
        error: true,
      }
    : suppressInternalErrors
    ? { helperText: "", error: false }
    : {};

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        className={width}
        variant="inline"
        margin="normal"
        label={
          label ? (
            <Lockable locked={!!locked} description={lockExplanation}>
              {label}
            </Lockable>
          ) : undefined
        }
        value={value}
        onChange={(dt) => {
          onChange(dt ? dt.startOf("day").toJSDate() : null);
        }}
        format="MM/dd/yyyy"
        disabled={locked}
        {...errorProps}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;

export const DatePickerController: React.FC<
  Omit<Props, "value" | "onChange"> & {
    name: string;
    control: Control;
    rules?: RegisterOptions;
  }
> = ({ name, control, rules, ...props }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ value, onChange }) => (
      <DatePicker value={value} onChange={onChange} {...props} />
    )}
  />
);
