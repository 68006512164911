import { EnergyEndUse, EnergySource, EnergyUnitType } from "../../api/graphql";
import Energy from "../Energy";
import { EndUse, TemperaturePerformance } from "../types/Reports";
import unique from "../unique";

export enum EnergyEfficiency {
  ENERGY_EFFICIENCY = "ENERGY_EFFICIENCY",
}

type DataPoint = {
  [key in EnergySource]?: number;
} &
  {
    [key in EnergyEndUse]?: number;
  } &
  {
    [key in EnergyEfficiency]?: number;
  };

function sumEndUses(endUses: EndUse[], resultEnergy: EnergyUnitType): number {
  return endUses
    .map(({ energy }) => new Energy(energy).as(resultEnergy).quantity)
    .reduce((a, c) => a + c, 0);
}

/**
 * Sum all constant energy expenditures (the baseload) for a single energy source (leaves out Heating and Cooling)
 */
function calcBaseload(endUses: EndUse[], resultEnergy: EnergyUnitType): number {
  return sumEndUses(
    endUses.filter(
      ({ endUse }) =>
        endUse !== EnergyEndUse.SPACE_COOLING &&
        endUse !== EnergyEndUse.SPACE_HEATING
    ),
    resultEnergy
  );
}

function formatPerformance(performance: TemperaturePerformance) {
  const energySources = unique(
    performance.endUses.map(({ energySource }) => energySource)
  );
  const dataPoint: DataPoint = {};
  const resultEnergy = EnergyUnitType.KBTU;

  [EnergyEndUse.SPACE_HEATING, EnergyEndUse.SPACE_COOLING].forEach((eu) => {
    dataPoint[eu] = sumEndUses(
      performance.endUses.filter(({ endUse }) => endUse === eu),
      resultEnergy
    );
  });

  energySources.forEach((energySource) => {
    dataPoint[energySource] = calcBaseload(
      performance.endUses.filter((data) => data.energySource === energySource),
      resultEnergy
    );
  });

  return dataPoint;
}

function formatEnergyEfficiency(
  baseline: TemperaturePerformance,
  comparison: TemperaturePerformance
) {
  const resultEnergy = EnergyUnitType.KBTU;

  const modeledEnergyTotal = sumEndUses(baseline.endUses, resultEnergy);

  const comparedEnergyTotal = sumEndUses(comparison.endUses, resultEnergy);

  return {
    [EnergyEfficiency.ENERGY_EFFICIENCY]:
      modeledEnergyTotal - comparedEnergyTotal,
  };
}

export const formatModeledChart = (performance: TemperaturePerformance[]) => {
  return performance.map((perf) => {
    const modeled = formatPerformance(perf);

    return { airTemp: perf.airTemp, ...modeled };
  });
};

export const formatProjectedChart = (
  baseline: TemperaturePerformance[],
  comparison: TemperaturePerformance[]
) => {
  return comparison.map((perf) => {
    const projected = formatPerformance(perf);
    const energyEfficiency = formatEnergyEfficiency(
      baseline.find((data) => data.airTemp === perf.airTemp)!,
      perf
    );

    return { airTemp: perf.airTemp, ...projected, ...energyEfficiency };
  });
};
