import * as AuthNJS from "keratin-authn";
import React, { useContext, useEffect } from "react";
import useAuthNErrors from "./useAuthNErrors";

export { useAuthNErrors };

interface Props {
  host: string;
  localStorageKey: string;
  onLogin?: () => void;
  onLogout?: () => void;
  onSignup?: () => void;
}

// TODO: export upstream
interface Credentials {
  [key: string]: string; // TODO: remove upstream
  username: string;
  password: string;
}

interface AuthContext {
  signUp: (c: Credentials) => Promise<void>;
  logIn: (c: Credentials) => Promise<void>;
  logOut: () => Promise<void>;
  resetPassword: (data: { password: string; token: string }) => Promise<void>;
}

export const authentication = React.createContext<AuthContext>({
  signUp: () => Promise.reject(),
  logIn: () => Promise.reject(),
  logOut: () => Promise.reject(),
  resetPassword: () => Promise.reject(),
});

export const useAuthN = () => useContext(authentication);

const AuthN: React.FC<Props> = ({
  children,
  onLogin,
  onLogout,
  onSignup,
  host,
  localStorageKey,
}) => {
  useEffect(() => {
    AuthNJS.setHost(host);
    AuthNJS.setLocalStorageStore(localStorageKey);
    AuthNJS.restoreSession().then(
      () => onLogin && onLogin(),
      () => onLogout && onLogout()
    );
  }, [host, localStorageKey, onLogin, onLogout]);

  return (
    <authentication.Provider
      value={{
        signUp: async (credentials) => {
          await AuthNJS.signup(credentials);
          onSignup && onSignup();
        },
        logIn: async (credentials) => {
          await AuthNJS.login(credentials);
          onLogin && onLogin();
        },
        logOut: async () => {
          await AuthNJS.logout();
          onLogout && onLogout();
        },
        resetPassword: async (data) => {
          await AuthNJS.resetPassword(data);
          onLogin && onLogin();
        },
      }}
    >
      {children}
    </authentication.Provider>
  );
};

export default AuthN;
