import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  styled,
  Typography,
} from "@material-ui/core";
import queue from "queue";
import React, { useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import clampFileName from "../lib/clampFileName";
import FileIcon from "../ui/FileIcon";
import Loading from "../ui/Loading";
import { useToastContext } from "../ui/ToastProvider";
import useLazyGetUploadUrlQuery from "./useLazyGetUploadUrlQuery";

const Dropzone = styled("div")(({ theme: { palette, shape, spacing } }) => ({
  background: palette.grey[200],
  borderRadius: shape.borderRadius,
  margin: "auto",
  padding: spacing(3),
  textAlign: "center",
  "&:hover": {
    cursor: "pointer",
  },
}));

const Label = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.grey[600],
}));

export type StoredFile = {
  key?: string;
  filename: string;
  mimetype: string;
};

type ControlledFileInputProps = {
  value: StoredFile[];
  onChange: (storedFiles: StoredFile[]) => void;
  disabled?: boolean;
  label?: string;
  maxFiles?: number;
  type?: string | string[];
};

/**
 * ControlledFileInput represents a list of files, some of which have already uploaded. The
 * caller may distinguish uploaded files by the presence of `key`, which is a unique identifier
 * in cloud storage.
 *
 * This input will permit adding files of the configured `type`, up to `maxFiles` total. Any
 * added files will begin uploading automatically, gaining a `key` once they have uploaded.
 */
export const ControlledFileInput: React.FC<ControlledFileInputProps> = ({
  value,
  onChange,
  disabled,
  label,
  maxFiles = 1,
  type,
}) => {
  const getUploadUrl = useLazyGetUploadUrlQuery();
  const createToast = useToastContext();

  const q = useMemo(() => queue({ concurrency: 1, autostart: true }), []);

  const valueRef = useRef<StoredFile[]>(value);
  valueRef.current = value;
  const uploader = (file: File) => async (): Promise<void> => {
    const { data } = await getUploadUrl();
    const { key, url } = data.uploadUrl;

    await fetch(url, {
      method: "PUT",
      headers: { "Content-Type": file.type },
      body: file,
    });

    // tell parent that this file has uploaded
    const idx = valueRef.current.findIndex(
      (v) => v.filename === file.name && v.mimetype === file.type
    );
    onChange([
      ...valueRef.current.slice(0, idx),
      {
        key,
        filename: file.name,
        mimetype: file.type,
      },
      ...valueRef.current.slice(idx + 1),
    ]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: type,
    maxFiles: maxFiles,
    multiple: maxFiles !== 1,
    preventDropOnDocument: true,
    onDropRejected: (fileRejections) => {
      createToast(fileRejections[0].errors[0].message, "error");
    },
    onDropAccepted: async (newFiles: File[]) => {
      for (const f of newFiles) {
        q.push(uploader(f));
      }
      onChange([
        ...value,
        ...newFiles.map((f) => ({ filename: f.name, mimetype: f.type })),
      ]);
    },
  });

  const allowAddingMoreFiles = maxFiles === 0 || value.length < maxFiles;

  return (
    <div>
      <Label variant="caption">{label}</Label>
      <List>
        {value.map((f, idx) => (
          <ListItem key={idx}>
            <ListItemIcon>
              <FileIcon type={f.mimetype} />
            </ListItemIcon>
            <Typography>{clampFileName(f.filename)}</Typography>
            <ListItemSecondaryAction>
              {f.key ? (
                <Button
                  onClick={() => {
                    // only uploaded files may be removed because we lack the
                    // ability to cancel an upload in the queue.
                    onChange(value.filter((v) => v.key !== f.key));
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Loading variant="circle" />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {!disabled && allowAddingMoreFiles && (
        <Dropzone {...getRootProps()}>
          <input data-testid="fileInput-input" {...getInputProps()} />
          <Typography variant="overline">
            Click to upload or drag &amp; drop a file.
          </Typography>
        </Dropzone>
      )}
    </div>
  );
};

type FileInputProps = Omit<ControlledFileInputProps, "value" | "onChange"> & {
  control: Control;
  name: string;
  rules?: RegisterOptions;
};

/**
 * FileInput is a react-hook-form controlled input representing a list of files, some of which
 * have already uploaded. The caller may distinguish uploaded files by the presence of `key`,
 * which is a unique identifier in cloud storage.
 *
 * FileInput will permit adding files of the configured `type`, up to `maxFiles` total. Any
 * added files will begin uploading automatically, gaining a `key` once they have uploaded.
 */
const FileInput: React.FC<FileInputProps> = ({
  control,
  name,
  rules,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          finished: (value: ControlledFileInputProps["value"]) =>
            value.every((v) => v.key),
        },
      }}
      render={(props) => (
        <ControlledFileInput
          value={props.value}
          onChange={props.onChange}
          {...rest}
        />
      )}
    />
  );
};

export default FileInput;
