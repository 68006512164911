import {
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash.debounce";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Control, Controller } from "react-hook-form";

interface Props
  extends Omit<TextFieldProps, "placeholder" | "value" | "onChange"> {
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
}

const SearchBarStyle = styled(TextField)({
  width: "100%",
  backgroundColor: "white",
  borderRadius: "25px",
  "& fieldset": {
    borderRadius: "25px",
  },
});

export const SearchField: FC<Props> = ({
  placeholder,
  value,
  onChange,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<string>(value);

  // Listener that will reset the internal value when we set value from the parent
  useEffect(() => {
    setInternalValue(value);
  }, [setInternalValue, value]);

  const search = useMemo(() => debounce((v) => onChange(v), 500), [onChange]);

  const handleChange = (value: string): void => {
    setInternalValue(value);
    search(value);
  };

  return (
    <SearchBarStyle
      variant="outlined"
      size="small"
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      value={internalValue}
      onChange={({ target: { value } }) => handleChange(value)}
      {...rest}
    />
  );
};

type SearchFieldControllerProps = Omit<Props, "value" | "onChange"> & {
  name: string;
  control: Control;
};

const SearchFieldController: FC<SearchFieldControllerProps> = ({
  name,
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <SearchField value={value} onChange={onChange} {...props} />
      )}
    />
  );
};

export default SearchFieldController;
