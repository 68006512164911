import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Button from "./Button";

type Props = {
  /** title of the dialog, concise question of what you need confirmed */
  header: string;
  /** optional content explaining descision */
  description?: string;
  /** func run on confirmation */
  onConfirm: () => void | Promise<void>;
  /** func called to close the dialog */
  onClose: () => void;
  confirmText?: string;
};

const ConfirmationDialog: React.FC<Props> = ({
  header,
  description,
  onConfirm,
  onClose,
  confirmText,
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const doConfirm = async () => {
    try {
      setSubmitting(true);
      await onConfirm();
      onClose();
    } catch (err) {
      throw err;
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="confirmation-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="confirmation-dialog-title">
          {header}
          {description && (
            <Typography color="textSecondary">{description}</Typography>
          )}
        </DialogTitle>
        <DialogActions>
          <Button
            autoFocus
            size="small"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            size="small"
            onClick={doConfirm}
            primary
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              confirmText ?? "OK"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
