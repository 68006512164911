/**
 * These are common MS Office mimetypes
 *
 * Reference: https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
 */
const msOfficeMimetypes = [
  // .doc
  "application/msword",
  // .dot
  "application/msword",
  //.docx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //.dotx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  //.docm
  "application/vnd.ms-word.document.macroEnabled.12",
  //.dotm
  "application/vnd.ms-word.template.macroEnabled.12",
  // .xls
  // .xlt
  // .xla
  "application/vnd.ms-excel",
  // .xlsx
  // .xltx
  // .xlsm
  // .xltm
  // .xlam
  // .xlsb
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  // .ppt
  // .pot
  // .pps
  // .ppa
  "application/vnd.ms-powerpoint",
  // .pptx
  // .potx
  // .ppsx
  // .ppam
  // .pptm
  // .potm
  // .ppsm
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  // .mdb
  "application/vnd.ms-access",
];

const msWordMimetypes = [
  // .doc
  "application/msword",
  // .dot
  "application/msword",
  //.docx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //.dotx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  //.docm
  "application/vnd.ms-word.document.macroEnabled.12",
  //.dotm
  "application/vnd.ms-word.template.macroEnabled.12",
];

const msExcelMimetypes = [
  // .xls
  // .xlt
  // .xla
  "application/vnd.ms-excel",
  // .xlsx
  // .xltx
  // .xlsm
  // .xltm
  // .xlam
  // .xlsb
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
];

const isMsOffice = (mimetype: string) => msOfficeMimetypes.includes(mimetype);

export const isMsExcel = (mimetype: string) =>
  msExcelMimetypes.includes(mimetype);

export const isMsWord = (mimetype: string) =>
  msWordMimetypes.includes(mimetype);

export default isMsOffice;
