import { useMutation } from "@apollo/client";
import { FormHelperText, MenuItem, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { FileAssetInput } from "../api/graphql";
import FormDialog from "../ui/FormDialog";
import { useToastContext } from "../ui/ToastProvider";
import FileInput from "../Uploads/FileInput";
import { Document } from "./DocumentCenter";
import { UpdateDocumentDocument } from "./UpdateDocument.generated";

type Props = { doc: Document };

interface Field {
  file: FileAssetInput[];
}

const ReplaceDocumentDialog: FC<{ doc: Document; onClose: () => void }> = ({
  doc,
  onClose,
}) => {
  const createToast = useToastContext();
  const [updateDocument] = useMutation(UpdateDocumentDocument);

  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<Field>({
    defaultValues: {
      file: [],
    },
  });

  const doReplace = async (field: Field) => {
    const { key, filename, mimetype } = field.file[0];

    try {
      await updateDocument({
        variables: {
          input: {
            documentId: doc.id,
            file: { key, filename, mimetype },
            // preserve original filename
            filename: doc.file.filename,
          },
        },
      });
      createToast("File was successfully replace", "success");
      onClose();
    } catch (err) {
      createToast(err.message || "Replace file error", "error");
    }
  };

  const files = watch("file");

  return (
    <FormDialog
      title="Replace document"
      onCancel={onClose}
      onSubmit={handleSubmit(doReplace)}
      isSubmitting={isSubmitting}
      submitName={files[0] && !files[0].key ? "Uploading ..." : "Replace"}
    >
      <Typography>
        Upload a new version of this document to replace the old one.
      </Typography>
      <FileInput
        name="file"
        control={control}
        rules={{
          validate: {
            length: (value: Field["file"]) =>
              value.length === 1 || "File is required",
          },
        }}
      />
      {errors.file && (
        <FormHelperText error>{(errors.file as any).message}</FormHelperText>
      )}
    </FormDialog>
  );
};

const ReplaceDocumentMenuItem: FC<Props> = ({ doc }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>Replace</MenuItem>
      {open && (
        <ReplaceDocumentDialog onClose={() => setOpen(false)} doc={doc} />
      )}
    </>
  );
};

export default ReplaceDocumentMenuItem;
