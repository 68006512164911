import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import { BuildingLoadFileQueryDocument } from "./BuildingLoadFileQuery.generated";

const useBuildingLoadFileQuery = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);
  const query = useCallback(
    (id: string) => {
      setLoading(true);
      const status = client.query({
        query: BuildingLoadFileQueryDocument,
        variables: { id },
        fetchPolicy: "no-cache",
      });
      status.finally(() => setLoading(false));
      return status;
    },
    [client]
  );
  return [query, { loading }] as const;
};

// useLazyQuery does not return a Promise
// TODO: revisit with apollo v3
export default useBuildingLoadFileQuery;
