import { Box, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { FC } from "react";
import { FormattedDate } from "react-intl";
import { IntervalModelFragment } from "../api/fragments/IntervalModel.generated";
import { MeterSource } from "../api/graphql";
import { buildingUtilityMeter } from "../lib/endpoints";
import { energySourceName } from "../lib/energySource";
import { meterIsStale } from "../lib/staleMeters";
import AlertChip from "../ui/AlertChip";
import { CardContent, CardLinkedArea } from "../ui/Card";
import EnergySourceIcon from "../ui/EnergySourceIcon";
import MetaData from "../ui/MetaData";
import PercentLoaded from "../ui/PercentLoaded";
import StripeCard from "../ui/StripeCard";
import { SectionTitle } from "../ui/Typography";
import { UtilityMetersQuery } from "./UtilityMetersQuery.generated";

type UtilityMeter = UtilityMetersQuery["building"]["utilityMeters"][number];
type ReadingsMissingAlert = UtilityMetersQuery["building"]["utilityMeters"][number]["alerts"][number] & {
  // Query has aliased property `missingAlertInterval`
  missingAlertInterval: IntervalModelFragment;
};

interface Props {
  meter: UtilityMeter;
  hasModels: boolean;
  buildingId: string;
  buildingTimeZone: string;
}

const percentYear = (numDays: number): number =>
  Math.round((numDays / 365) * 100);

const UtilityMeterListCard: FC<Props> = ({
  meter,
  hasModels,
  buildingId,
  buildingTimeZone,
}) => {
  // There should only be one ReadingsMissingAlert, so we use .find()
  const readingsMissingAlert = meter.alerts.find(
    (a): a is ReadingsMissingAlert => a.__typename === "ReadingsMissingAlert"
  );
  const lastReadOn: Date | null = meter.readings.lastReadOn
    ? new Date(meter.readings.lastReadOn)
    : null;

  return (
    <Grid item xs={12} sm={6}>
      <StripeCard orientation="left">
        <CardLinkedArea
          to={buildingUtilityMeter({
            buildingId,
            utilityMeterId: meter.id,
          })}
        >
          <CardContent>
            <Box display="flex" alignItems="center">
              <Box pr={1}>
                {hasModels ? (
                  <EnergySourceIcon
                    energySource={meter.energySource}
                    solar={meter.source === MeterSource.SOLAR}
                  />
                ) : !!readingsMissingAlert ? (
                  <PercentLoaded
                    value={percentYear(
                      readingsMissingAlert.missingAlertInterval?.daysInPeriod ||
                        0
                    )}
                  />
                ) : (
                  <CheckCircleIcon color="primary" fontSize="large" />
                )}
              </Box>
              <Box width={1}>
                <SectionTitle>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width={1}
                  >
                    {meter.externalId || meter.id}
                    {hasModels && lastReadOn
                      ? meterIsStale(lastReadOn) &&
                        hasModels && <AlertChip label="Meter update required" />
                      : !!readingsMissingAlert && (
                          <AlertChip label="12 months of readings required" />
                        )}
                  </Box>
                </SectionTitle>
                <MetaData>
                  <Typography component="span" variant="body2">
                    {energySourceName(meter.energySource)}{" "}
                    {meter.source === MeterSource.SOLAR && "(solar)"}
                  </Typography>
                  {lastReadOn && (
                    <Typography component="span" variant="body2">
                      Last read:{" "}
                      <FormattedDate
                        value={lastReadOn}
                        timeZone={buildingTimeZone}
                      />
                    </Typography>
                  )}
                  <Typography component="span" variant="body2">
                    {meter.readings.count} readings
                  </Typography>
                  {meter.utilityName && (
                    <Typography component="span" variant="body2">
                      Utility: {meter.utilityName}
                    </Typography>
                  )}
                </MetaData>
              </Box>
            </Box>
          </CardContent>
        </CardLinkedArea>
      </StripeCard>
    </Grid>
  );
};

export default UtilityMeterListCard;
