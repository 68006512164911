import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import React, { FC } from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

const NoPreview: FC = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderSegment
        icon={InsertDriveFileIcon}
        header="Preview unavailable"
        subheader="Please download the file instead."
      />
    </PlaceholderContainer>
  );
};

export const FileTooLarge: FC = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderSegment
        icon={InsertDriveFileIcon}
        header="Preview unavailable"
        subheader="This document's file size is too large. Please download the file instead."
      />
    </PlaceholderContainer>
  );
};

export default NoPreview;
