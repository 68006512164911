import { Box, CircularProgress, styled, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  value: number;
}

const LabelContainer = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const PercentLoaded: React.FC<Props> = ({ value }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" value={value} />
      <LabelContainer>
        <Typography variant="caption" color="textSecondary">
          {`${value}%`}
        </Typography>
      </LabelContainer>
    </Box>
  );
};

export default PercentLoaded;
