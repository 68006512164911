import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  makeStyles,
  styled,
  Switch,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import {
  Currency,
  EnergySource,
  EnergyUnitType,
  WeatherSource,
} from "../api/graphql";
import { financialModelById, financialModels } from "../lib/endpoints";
import LibEnergy from "../lib/Energy";
import { adjustedModelName, calibratedModelName } from "../lib/modelName";
import { sortBy } from "../lib/sortBy";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import Card, { CardActions, CardContent, CardHeader } from "../ui/Card";
import ChartLegend from "../ui/charting/ChartLegend";
import ResponsiveChart from "../ui/charting/ResponsiveChart";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import AnnualExpensesForm from "./AnnualExpensesForm";
import CumulativeCashFlowChart from "./CumulativeCashFlowChart";
import CumulativeEfficiencyChart from "./CumulativeEfficiencyChart";
import { DeleteFinancialModelDocument } from "./DeleteFinancialModel.generated";
import { fuelOrder } from "./fuelOrder";
import {
  calculateMaxInitialInvestment,
  createEnergySourceConfigs,
  Money,
} from "./helpers";
import { InitializeFinancialModelFormQuery } from "./InitializeFinancialModelForm.generated";
import KeyMetrics from "./KeyMetrics";
import ProjectDataForm from "./ProjectDataForm";
import ProjectMetadataForm from "./ProjectMetadataForm";
import ScenarioAndRatesForm from "./ScenarioAndRatesForm";
import { UpsertFinancialModelDocument } from "./UpsertFinancialModel.generated";
import useTrackFormCalculations from "./useTrackFormCalculations";

// We should never expect to receive a `null` calibrated model in this component
export type CalibratedModel = NonNullable<
  InitializeFinancialModelFormQuery["building"]["calibratedModel"]
>;

export type AdjustedModel = CalibratedModel["adjustedModels"][number];

interface Props {
  financialModelId?: string;
  buildingId: string;
  calibratedModelId: string;
  calibratedModelData: CalibratedModel;
  adjustedModelActualWeatherData: AdjustedModel[];
  adjustedModelNormalWeatherData: AdjustedModel[];
  defaultRates: Rate[];
  defaultMetadata: ProjectMetadata;
  expenses: Expense[];
  projectCost?: number;
  discountRate: number;
  usingCustomScenario?: boolean;
  usingCustomRates?: boolean;
  selectedModelId?: string;
  customDepths?: {
    sources: ScenarioEnergySourceData[];
  };
  customRates?: Rate[];
  term?: number;
  projectUncertainty?: number;
  weatherSource: WeatherSource;
}
export interface ScenarioEnergySourceData {
  energySource: EnergySource;
  unit: EnergyUnitType;
  depth: number;
}

export interface CalibrationEnergySourceData {
  energySource: EnergySource;
  unit: EnergyUnitType;
  baseline: number;
}

export interface CalibratedModelData {
  name: string;
  sources: CalibrationEnergySourceData[];
}

export interface ProjectData {
  discountRate: number;
  projectUncertainty: number;
  term: number;
  projectCost: Money;
}

export interface ScenarioModelData {
  id: string;
  name: string;
  sources: ScenarioEnergySourceData[];
}

export interface Rate {
  energySource: EnergySource;
  cost: Money;
  unit: EnergyUnitType;
  escalation: number;
  eiaSource?: string | null;
}

export interface EnergyEfficiencyProductionFields {
  usingCustomScenario: boolean;
  usingCustomRates: boolean;
  selectedModelId: string;
  customScenario: {
    sources: ScenarioEnergySourceData[];
  };
  defaultRates: Rate[];
  customRates: Rate[];
  weatherSource: WeatherSource;
}

export interface Expense {
  name: string;
  escalation: number;
  amount: Money;
}

export interface ProjectMetadata {
  name: string;
  notes: string;
}

export interface FormSections {
  expenses: Expense[];
  projectData: ProjectData;
  scenarioAndRates: EnergyEfficiencyProductionFields;
  nameAndNotes: ProjectMetadata;
}

const useStyles = makeStyles(({ palette }) => ({
  buttonSelected: {
    color: palette.common.white,
    backgroundColor: palette.grey[400],
    "&:hover": {
      backgroundColor: palette.grey[400],
    },
  },
  buttonText: {
    color: palette.grey[500],
  },
}));

const GridColumn = styled("div")(({ theme: { spacing } }) => ({
  display: "grid",
  gridTemplateRows: "auto",
  gridRowGap: spacing(2),
}));

/**
 * Indexes the default model to the first position
 */
export const sortDefaultFirst = <T extends { default: boolean }>(
  data: readonly T[]
): T[] => {
  return sortBy(data, (d) => (d.default ? -1 : 1));
};

/**
 * Indexes the default model to the first position and transforms the API data to something the form can digest
 */
const transformScenarioWithWeather = (
  data: AdjustedModel[],
  targetWeather: "actualOpportunity" | "normalizedOpportunity"
): ScenarioModelData[] => {
  return sortDefaultFirst(data).map((am) => ({
    id: am.id,
    name: adjustedModelName(am.name),
    sources: am[targetWeather].byEnergySource.map((e) => ({
      energySource: e.energySource,
      unit: e.energy.unit,
      depth: e.energyDepth,
    })),
  }));
};

const UNCERTAINTY_RATE = 0.1;
const TERM_LENGTH = 20;
const DISCOUNT_RATE = 0.08;

const FinancialModelDashboard: FC<Props> = ({
  financialModelId,
  buildingId,
  calibratedModelId,
  calibratedModelData,
  adjustedModelActualWeatherData,
  adjustedModelNormalWeatherData,
  defaultRates,
  defaultMetadata,
  projectCost,
  discountRate,
  usingCustomRates,
  usingCustomScenario,
  selectedModelId,
  customDepths,
  customRates,
  term,
  expenses,
  projectUncertainty,
  weatherSource = WeatherSource.ACTUAL,
}) => {
  const { palette } = useTheme();
  const { buttonSelected, buttonText } = useStyles();
  const history = useHistory();
  const [chartType, setChartType] = useState<"cash" | "energy">("cash");
  const [isDiscounted, setIsDiscounted] = useState<boolean>(true);
  const createToast = useToastContext();
  const [upsertModel] = useMutation(UpsertFinancialModelDocument);
  const [deleteFinancialModel] = useMutation(DeleteFinancialModelDocument);
  const [allowUnsavedNavigation, setAllowUnsavedNavigation] = useState<boolean>(
    false
  );

  // Transform calibrated model data
  const transformedCalibratedModelData: CalibratedModelData = {
    name: calibratedModelName(calibratedModelData.name),
    sources: calibratedModelData.annualizedEnergySources.map((e) => ({
      energySource: e.energySource,
      unit: e.energy.unit,
      baseline: e.energy.quantity,
    })),
  };

  // Transform the adjusted model data for form
  const transformedActualWeatherData = transformScenarioWithWeather(
    adjustedModelActualWeatherData,
    "actualOpportunity"
  );
  const transformedNormalWeatherData = transformScenarioWithWeather(
    adjustedModelNormalWeatherData,
    "normalizedOpportunity"
  );

  const pickedAdjustedModelWeather: ScenarioModelData[] =
    weatherSource === WeatherSource.ACTUAL
      ? transformedActualWeatherData
      : transformedNormalWeatherData;

  const scenarioAndRates = {
    usingCustomScenario: usingCustomScenario || false,
    usingCustomRates: usingCustomRates || false,
    // We index the default model first via sortDefaultFirst(), so we pick [0]
    selectedModelId: selectedModelId || pickedAdjustedModelWeather[0].id,
    customScenario: {
      sources: sortBy(
        customDepths?.sources ||
          transformedCalibratedModelData.sources.map((cm) => ({
            energySource: cm.energySource,
            unit: cm.unit,
            // Initial depth is picked from the default scenario as well
            depth: pickedAdjustedModelWeather[0].sources.find(
              (s) => s.energySource === cm.energySource
            )!.depth,
          })),
        fuelOrder
      ),
    },
    defaultRates: sortBy(defaultRates, fuelOrder),
    customRates: sortBy(customRates || defaultRates, fuelOrder),
    weatherSource: weatherSource,
  };

  /**
   * Calculate the maximum initial investment using initial default values
   */
  const maxInvestment = calculateMaxInitialInvestment(
    createEnergySourceConfigs(
      transformedCalibratedModelData.sources.map((s) => ({
        energySource: s.energySource,
        baseline: new LibEnergy({ quantity: s.baseline, unit: s.unit }),
      })),
      pickedAdjustedModelWeather.find(
        (m) => m.id === scenarioAndRates.selectedModelId
      )!.sources,
      scenarioAndRates.defaultRates
    ),
    term || TERM_LENGTH,
    projectUncertainty || UNCERTAINTY_RATE,
    expenses,
    discountRate || DISCOUNT_RATE
  );

  const projectData = {
    discountRate: discountRate || DISCOUNT_RATE,
    projectUncertainty: projectUncertainty || UNCERTAINTY_RATE,
    term: term || TERM_LENGTH,
    projectCost: { value: projectCost ?? maxInvestment, unit: Currency.USD },
  };

  const formMethods = useForm<FormSections>({
    mode: "onChange",
    defaultValues: {
      expenses,
      projectData,
      scenarioAndRates,
      nameAndNotes: defaultMetadata,
    },
  });

  const {
    IRR,
    NPV,
    annualCashFlows,
    averageNominalAnnualCashFlow,
    eeValuePerSource,
    energySourceConfigs,
  } = useTrackFormCalculations(formMethods, {
    calibratedModelData: transformedCalibratedModelData,
    adjustedModelActualWeatherData: transformedActualWeatherData,
    adjustedModelNormalWeatherData: transformedNormalWeatherData,
  });

  const onDelete = async () => {
    if (financialModelId) {
      try {
        setAllowUnsavedNavigation(true);
        await deleteFinancialModel({
          variables: { input: { financialModelId } },
        });
        createToast("Financial model successfully deleted.", "success");
        history.push(financialModels({ buildingId }));
      } catch (e) {
        createToast(e.message, "error");
        setAllowUnsavedNavigation(false);
      }
    }
  };
  const onSubmit = async (fields: FormSections) => {
    try {
      const { data } = await upsertModel({
        variables: {
          input: {
            id: financialModelId || null,
            calibratedModelId,
            adjustedModelId: fields.scenarioAndRates.usingCustomScenario
              ? null
              : fields.scenarioAndRates.selectedModelId,
            weatherSource: fields.scenarioAndRates.weatherSource,
            projectCost: fields.projectData.projectCost,
            projectUncertainty: fields.projectData.projectUncertainty,
            discountRate: fields.projectData.discountRate,
            name: fields.nameAndNotes.name,
            notes: fields.nameAndNotes.notes,
            term: fields.projectData.term,
            cashFlows: fields.expenses
              ? fields.expenses.map((ex) => ({
                  name: ex.name,
                  escalation: ex.escalation,
                  amount: ex.amount,
                }))
              : [],
            customRates: fields.scenarioAndRates.usingCustomRates
              ? fields.scenarioAndRates.customRates.map((cr) => ({
                  energySource: cr.energySource,
                  escalation: cr.escalation,
                  cost: cr.cost,
                  unit: cr.unit,
                  eiaSource: null,
                }))
              : null,
            defaultRates: fields.scenarioAndRates.defaultRates.map((dr) => ({
              energySource: dr.energySource,
              escalation: dr.escalation,
              cost: dr.cost,
              unit: dr.unit,
              eiaSource: dr.eiaSource,
            })),
            energyEfficiencyDepths: fields.scenarioAndRates.usingCustomScenario
              ? fields.scenarioAndRates.customScenario.sources.map((cs) => ({
                  depth: cs.depth,
                  energySource: cs.energySource,
                }))
              : (fields.scenarioAndRates.weatherSource === WeatherSource.ACTUAL
                  ? transformedActualWeatherData
                  : transformedNormalWeatherData
                )
                  .find(
                    (am) => am.id === fields.scenarioAndRates.selectedModelId
                  )!
                  .sources.map((am) => ({
                    depth: am.depth,
                    energySource: am.energySource,
                  })),
          },
        },
      });

      if (!financialModelId) {
        setAllowUnsavedNavigation(true);
        history.push(
          financialModelById({
            buildingId,
            financialModelId: data!.upsertFinancialModel.financialModel.id,
          })
        );
      } else {
        formMethods.reset({
          ...fields,
        });
      }

      createToast("Financial model was saved", "success");
    } catch (err) {
      createToast(err.message, "error");
    }
  };

  return (
    <>
      <Prompt
        when={formMethods.formState.isDirty && !allowUnsavedNavigation}
        message="Your investment has unsaved changes. Are you sure you wish to continue?"
      />
      <Box pb={1} display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <PageTitle>Projected investment overview</PageTitle>
          {formMethods.formState.isDirty && (
            <Box pl={2}>
              <Chip label="Changes not saved" size="small" color="primary" />
            </Box>
          )}
        </Box>
        {!!financialModelId && (
          <ButtonWithConfirmation
            header="Delete this financial model?"
            description="Deleting this model will permanently remove it from the building. This cannot be undone."
            size="small"
            onClick={onDelete}
          >
            Delete model
          </ButtonWithConfirmation>
        )}
      </Box>
      <FormProvider {...formMethods}>
        <KeyMetrics
          IRR={IRR}
          NPV={NPV}
          annualCashFlows={annualCashFlows}
          averageAnnualCashFlow={averageNominalAnnualCashFlow}
          eeValuePerSource={eeValuePerSource}
        />
      </FormProvider>
      <Box pb={2}>
        <Card>
          <CardHeader
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="button" color="primary">
                  {chartType === "cash"
                    ? `Projected cumulative cash flow ${
                        isDiscounted ? "(Discounted)" : ""
                      }`
                    : "Projected cumulative energy efficiency production"}
                </Typography>
                <ChartLegend
                  items={[
                    {
                      label:
                        chartType === "cash"
                          ? "Avg. cumulative USD"
                          : "Avg. cumulative energy efficiency",
                      shape: "line",
                      color: palette.primary.main,
                    },
                    {
                      label: "Uncertainty band",
                      shape: "circle",
                      color: palette.primary.main,
                      colorOpacity: 0.33,
                    },
                  ]}
                />
              </Box>
            }
          />
          <CardContent>
            <ResponsiveChart ratio={20 / 6}>
              {({ width, height }) => (
                <FormProvider {...formMethods}>
                  {chartType === "cash" ? (
                    <CumulativeCashFlowChart
                      width={width}
                      height={height}
                      discounted={isDiscounted}
                      energySourceConfigs={energySourceConfigs}
                    />
                  ) : (
                    <CumulativeEfficiencyChart
                      width={width}
                      height={height}
                      energySourceConfigs={energySourceConfigs}
                    />
                  )}
                </FormProvider>
              )}
            </ResponsiveChart>
          </CardContent>
          <CardActions>
            <Box display="flex" justifyContent="space-between" width={1}>
              <Box>
                <Button
                  size="small"
                  onClick={() => setChartType("cash")}
                  className={chartType === "cash" ? buttonSelected : buttonText}
                >
                  Cumulative cash flow
                </Button>
                <Button
                  size="small"
                  onClick={() => setChartType("energy")}
                  className={
                    chartType === "energy" ? buttonSelected : buttonText
                  }
                >
                  Cumulative energy
                </Button>
              </Box>
              {chartType === "cash" && (
                <Box pr={2}>
                  <FormControlLabel
                    labelPlacement="start"
                    label={
                      <Typography variant="caption">
                        Enable discounted
                      </Typography>
                    }
                    control={
                      <Switch
                        size="small"
                        color="primary"
                        checked={isDiscounted}
                        onChange={() => setIsDiscounted(!isDiscounted)}
                      />
                    }
                  />
                </Box>
              )}
            </Box>
          </CardActions>
        </Card>
      </Box>
      <Box
        pt={3}
        pb={1}
        display="flex"
        alignContent="center"
        justifyContent="space-between"
      >
        <PageTitle>Financial model</PageTitle>
      </Box>
      <Grid container>
        <FormProvider {...formMethods}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <GridColumn>
                <ProjectDataForm
                  IRR={IRR}
                  eeValuePerSource={eeValuePerSource}
                />
                <AnnualExpensesForm />
              </GridColumn>
            </Grid>
            <Grid item xs={12} lg={6}>
              <GridColumn>
                <ScenarioAndRatesForm
                  adjustedModelActualWeatherData={transformedActualWeatherData}
                  adjustedModelNormalWeatherData={transformedNormalWeatherData}
                  calibratedModelData={transformedCalibratedModelData}
                />
                <ProjectMetadataForm onSubmit={onSubmit} />
              </GridColumn>
            </Grid>
          </Grid>
        </FormProvider>
      </Grid>
    </>
  );
};

export default FinancialModelDashboard;
