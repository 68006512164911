import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import {
  ControlledNumberField,
  ControlledNumberFieldProps,
} from "./NumberField";

type ControlledPercentFieldProps = Omit<
  ControlledNumberFieldProps,
  "value" | "onChange" | "defaultValue" | "prepend" | "append"
> & {
  value: number | null | undefined;
  onChange: (n: number) => void;
};

export const ControlledPercentField: React.FC<ControlledPercentFieldProps> = ({
  value,
  onChange,
  precision,
  ...props
}) => (
  <ControlledNumberField
    value={value == null ? value : value * 100}
    onChange={(v) => onChange(v / 100)}
    precision={precision ?? 2}
    append="%"
    {...props}
  />
);

type PercentFieldProps = Omit<
  ControlledPercentFieldProps,
  "value" | "onChange"
> & {
  control: Control;
  name: string;
  defaultValue?: number;
  rules?: RegisterOptions;
};

/**
 * PercentField is a numeric input type that renders decimals as %. By default it has a precision of 2.
 */
const PercentField: React.FC<PercentFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  id,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || null}
      render={({ value, onChange }) => (
        <ControlledPercentField
          value={value}
          onChange={onChange}
          id={id || name}
          {...props}
        />
      )}
    />
  );
};
export default PercentField;
