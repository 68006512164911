import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { FC } from "react";
import { FormattedDate } from "react-intl";
import clampFileName from "../lib/clampFileName";
import FormDialog from "../ui/FormDialog";
import MetaData from "../ui/MetaData";
import { OrganizationDocument } from "./OrganizationUser";
import { OrganizationUserBuildingsQuery } from "./OrganizationUserBuildingsQuery.generated";

interface Props {
  user: OrganizationUserBuildingsQuery["user"];
  unsharedDocuments: OrganizationDocument[];
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  loading: boolean;
}

const ConfirmDocumentSharing: FC<Props> = ({
  user,
  unsharedDocuments,
  onSubmit,
  onCancel,
  loading,
}) => {
  return (
    <FormDialog
      title="Confirm document sharing"
      isSubmitting={loading}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitName="Yes"
      dismissName="No"
    >
      <Typography>
        These buildings contain documents linked to them that are unshared with{" "}
        {user.name}. Do you wish to share the following documents with them?
      </Typography>
      <br />
      <Alert icon={false} severity="info">
        <Typography component="ul">
          {unsharedDocuments.map((doc) => (
            <Typography component="li" key={doc.id}>
              {clampFileName(doc.file.filename)}
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                <MetaData>
                  <span>Created by {doc.createdBy.name}</span>
                  <span>
                    Last updated <FormattedDate value={doc.updatedAt} />
                  </span>
                </MetaData>
              </Typography>
            </Typography>
          ))}
        </Typography>
      </Alert>
    </FormDialog>
  );
};

export default ConfirmDocumentSharing;
