import queue from "queue";
import { useCallback, useState } from "react";

type Props<JobData> = {
  worker: (data: JobData) => Promise<any>;
  concurrency: number;
};

export enum JobStatus {
  Queued,
  Disabled,
  Assigned,
  Successful,
  Failed,
}

type JobWrapper<JobData> = Readonly<{
  id: number;
  data: JobData;
  status: JobStatus;
  error?: Error;
}>;

let jobId = 0;
const nextJobId = (): number => ++jobId;

export default function useQueue<JobData>({
  concurrency,
  worker,
}: Props<JobData>) {
  const [working, setWorking] = useState<boolean>(false);
  const [jobs, setJobs] = useState<Readonly<JobWrapper<JobData>[]>>([]);

  const enqueue = useCallback((jobs: JobData[]): void => {
    setJobs((previous) => [
      ...previous,
      ...jobs.map((job) => ({
        id: nextJobId(),
        data: job,
        status: JobStatus.Queued,
      })),
    ]);
  }, []);

  const updateJob = useCallback(
    (jobId: number, changes: Partial<JobWrapper<JobData>>): void => {
      setJobs((previous) => {
        const jobIndex = previous.findIndex(({ id }) => id === jobId);
        const updatedJob = {
          ...previous[jobIndex],
          ...changes,
        };

        return [
          ...previous.slice(0, jobIndex),
          updatedJob,
          ...previous.slice(jobIndex + 1),
        ];
      });
    },
    []
  );

  /** sets the status of all jobs, ignoring Successful ones. */
  const setStatusAll = useCallback((newStatus: JobStatus): void => {
    setJobs((previous) => {
      return previous.map((job) => ({
        ...job,
        status: job.status === JobStatus.Successful ? job.status : newStatus,
      }));
    });
  }, []);

  const start = useCallback(() => {
    if (working) {
      throw new Error("Queue is working.");
    }
    const q = queue({ concurrency, autostart: true });
    for (const job of jobs) {
      if (job.status !== JobStatus.Queued) {
        continue;
      }
      // don't set working until we have at least one job to do.
      // this is idempotent.
      setWorking(true);

      q.push(async () => {
        updateJob(job.id, { status: JobStatus.Assigned });
        try {
          await worker(job.data);
          updateJob(job.id, { status: JobStatus.Successful });
        } catch (e) {
          if (e instanceof Error) {
            updateJob(job.id, { status: JobStatus.Failed, error: e });
            return;
          }
          throw e;
        }
      });
    }

    q.on("end", () => setWorking(false));
  }, [concurrency, working, jobs, updateJob, worker]);

  const disable = useCallback(
    (jobId: number) => updateJob(jobId, { status: JobStatus.Disabled }),
    [updateJob]
  );

  const enable = useCallback(
    (jobId: number) => updateJob(jobId, { status: JobStatus.Queued }),
    [updateJob]
  );

  const disableAll = useCallback(() => setStatusAll(JobStatus.Disabled), [
    setStatusAll,
  ]);

  const enableAll = useCallback(() => setStatusAll(JobStatus.Queued), [
    setStatusAll,
  ]);

  return {
    jobs,
    working,
    enqueue,
    start,
    disable,
    disableAll,
    enable,
    enableAll,
  };
}
