import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Delete as DeleteIcon, Tune as ReviseIcon } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { FormattedDate } from "react-intl";
import BuildingModelStatus from "../BuildingModelStatus";
import { earliest, latest } from "../lib/dates";
import FormattedRange from "../lib/FormattedRange";
import isReadOnly from "../lib/isReadOnly";
import modelDescription from "../lib/modelDescription";
import modelName from "../lib/modelName";
import modelUserName from "../lib/modelUserName";
import Button from "../ui/Button";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import Card, { CardActions, CardContent, CardHeader } from "../ui/Card";
import DefaultModel from "../ui/DefaultModel";
import MetaData from "../ui/MetaData";
import { PageTitle, SectionTitle } from "../ui/Typography";
import { CalibratedModelQuery } from "./CalibratedModel.generated";

const useStyles = makeStyles(() => ({
  actionButtons: {
    justifyContent: "right",
  },
  paramDisplayTitle: {
    fontSize: 3,
  },
}));

export interface Props {
  squareFeet: number;
  model: CalibratedModelQuery["calibratedModel"];
  onDelete: () => void;
  onRevise?: () => void;
  onEdit?: () => void;
}

const ModelDetailCard: React.FC<Props> = ({
  model,
  onDelete,
  onRevise,
  onEdit,
  children,
}) => {
  const { actionButtons } = useStyles();

  return (
    <Card>
      <CardHeader
        title={<PageTitle>{modelName(model)}</PageTitle>}
        subheader="Calibrated model"
        action={<DefaultModel model={model} />}
      />

      <CardContent>
        <Grid container>
          <Grid item md={5} xs={12}>
            <Box pr={2}>
              <SectionTitle>Description</SectionTitle>
              <Typography>{modelDescription(model)}</Typography>
              {model.notes && (
                <>
                  <SectionTitle>Notes</SectionTitle>
                  <Typography>{model.notes}</Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <SectionTitle>
              {model.system ? "System Values" : "Adjustments"}
            </SectionTitle>
            {children}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={actionButtons}>
        <MetaData>
          <span>
            <BuildingModelStatus model={{ fit: model.fit }} />{" "}
            <Typography variant="caption" color="textSecondary">
              Reference period:{" "}
              <FormattedRange
                from={earliest(
                  model.reference.map((i) => new Date(i.interval.start))
                )}
                to={latest(
                  model.reference.map((i) => new Date(i.interval.end))
                )}
              />
            </Typography>
          </span>
          <Typography variant="caption" color="textSecondary">
            Created: <FormattedDate value={new Date(model.createdAt)} /> by{" "}
            {modelUserName(model.user)}
          </Typography>
        </MetaData>
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          {!model.system && onEdit && (
            <Box pr={1}>
              <Button startIcon={<EditIcon />} size="small" onClick={onEdit}>
                Edit
              </Button>
            </Box>
          )}
          <Box pr={1}>
            <ButtonWithConfirmation
              header="Delete this model?"
              description="All associated Scenarios will be lost. This action cannot be undone"
              onClick={onDelete}
              size="small"
              startIcon={<DeleteIcon />}
            >
              Delete
            </ButtonWithConfirmation>
          </Box>
          {onRevise && !isReadOnly(model.building.access) && (
            <Button onClick={onRevise} size="small" startIcon={<ReviseIcon />}>
              Revise calibrated model
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default ModelDetailCard;
