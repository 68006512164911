import { Box, BoxProps, makeStyles } from "@material-ui/core";
import React from "react";

type Props = Omit<BoxProps, "gridGap"> & {
  spacing: number;
};

const useStyles = makeStyles(({ spacing }) => ({
  grid: {
    display: "grid",
    gap: (props: Props) => `${spacing(props.spacing)}px`,
  },
}));

/**
 * Creates a CSS Grid with gaps of the specified size. Extend with other grid
 * attributes using the MUI Box API.
 */
const Gaps: React.FC<Props> = ({ children, spacing, ...rest }) => {
  const { grid } = useStyles({ spacing });
  return (
    <Box className={grid} {...rest}>
      {children}
    </Box>
  );
};

export default Gaps;
