import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import InfoIcon from "@material-ui/icons/Info";
import React, { FC, forwardRef, Ref, useState } from "react";

export interface Props {
  title: string;
  content: string;
}

const Transition = forwardRef((props: TransitionProps, ref: Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
));

const HelpMe: FC<Props> = ({ title, content }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setOpen(true)}>
        <InfoIcon fontSize="small" color="disabled" />
      </IconButton>
    </>
  );
};

export default HelpMe;
