import { Group } from "@visx/group";
import { ScaleTime } from "d3-scale";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdjustmentWithReadingModelFragment } from "../api/fragments/AdjustmentWithReadingModel.generated";
import { EnergySource } from "../api/graphql";
import { buildingDeltaMeterReading } from "../lib/endpoints";
import { energySourceColor } from "../lib/energySource";
import unique from "../lib/unique";

export interface CombinedReadingAdjustment {
  readingId: string;
  interval: {
    start: string;
    end: string;
  };
  energySource: EnergySource;
  netQuantity: number;
  baseline: number;
  adjustments: Omit<AdjustmentWithReadingModelFragment, "reading">[];
}

interface Props {
  xScale: ScaleTime<number, number>;
  marginTop: number;
  marginLeft: number;
  adjustmentData: CombinedReadingAdjustment[];
  chartHeight: number;
  buildingId: string;
  containerHeight: number;
  onMouseOver: (
    combinedReadingAdjustment: CombinedReadingAdjustment,
    offsetTop: number,
    offsetLeft: number
  ) => void;
  onMouseOut: () => void;
}

interface RectProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  fillOpacity?: number;
}

const Highlight: FC<RectProps> = (props) => <rect {...props} />;

const AdjustmentAnnotations: FC<Props> = ({
  xScale,
  marginTop,
  marginLeft,
  adjustmentData,
  chartHeight,
  buildingId,
  containerHeight,
  onMouseOver,
  onMouseOut,
}) => {
  const history = useHistory();
  const [hoveredReadingId, setHoveredReadingId] = useState<string | null>(null);

  const distinctEnergySources = unique(
    adjustmentData.map((a) => a.energySource)
  );

  // Const height for a brush; we accept whitespace rather than resizing the deltameter chart
  const BRUSH_HEIGHT: number = 10;

  // Space between the chart and the brushes
  const bottomPadding = 5;

  return (
    <>
      <Group top={marginTop} left={marginLeft}>
        {distinctEnergySources.map((source, idx) => {
          const shiftHeight = (idx + 1) * BRUSH_HEIGHT - bottomPadding;

          return (
            <Group key={source}>
              {adjustmentData
                .filter((ad) => ad.energySource === source)
                .map((ad) => {
                  // Start of date range
                  const x0 = xScale(new Date(ad.interval.start).valueOf()) || 0;

                  // End of date range
                  const x1 = xScale(new Date(ad.interval.end).valueOf()) || 0;

                  const y = chartHeight + containerHeight - shiftHeight;

                  const intervalWidth = x1 - x0;

                  return (
                    <Group key={ad.readingId}>
                      {hoveredReadingId === ad.readingId && (
                        <Highlight
                          x={x0}
                          y={0}
                          width={intervalWidth}
                          height={chartHeight}
                          fill={"#000000"}
                          fillOpacity={0.075}
                        />
                      )}
                      <Group
                        style={{ cursor: "pointer" }}
                        onMouseOver={() => {
                          setHoveredReadingId(ad.readingId);
                          onMouseOver(ad, 20, x1);
                        }}
                        onMouseOut={() => {
                          setHoveredReadingId(null);
                          onMouseOut();
                        }}
                        onClick={() =>
                          history.push(
                            buildingDeltaMeterReading({
                              buildingId,
                              readingId: ad.readingId,
                            })
                          )
                        }
                      >
                        <rect
                          x={x0}
                          y={y}
                          width={intervalWidth}
                          height={BRUSH_HEIGHT}
                          fill={energySourceColor(ad.energySource)}
                          fillOpacity={
                            hoveredReadingId === ad.readingId ? 1 : 0.5
                          }
                        />
                      </Group>
                    </Group>
                  );
                })}
            </Group>
          );
        })}
      </Group>
    </>
  );
};

export default AdjustmentAnnotations;
