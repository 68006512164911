import { Box, Link } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  to: string;
  label: string;
}

const BackButton: React.FC<Props> = ({ to, label }) => {
  return (
    <Box display="flex" alignItems="center" pb={1}>
      <ArrowBackIosIcon fontSize="small" color="primary" />
      <Link component={RouterLink} variant="body2" to={to}>
        {label}
      </Link>
    </Box>
  );
};

export default BackButton;
