import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/Speed";
import React, { useState } from "react";
import { EnergySource, MeterSource } from "../api/graphql";
import useHasModels from "../Building/useHasModels";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import ProductCopy from "../ui/ProductCopy";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import { CreateUtilityMeterDocument } from "./CreateUtilityMeter.generated";
import NewUtilityMeterDialog from "./NewUtilityMeterDialog";
import UtilityMeterListCard from "./UtilityMeterListCard";
import { UtilityMetersDocument } from "./UtilityMetersQuery.generated";

export interface Props {
  buildingId: string;
}

const UtilityMetersList: React.FC<Props> = ({ buildingId }) => {
  const createToast = useToastContext();
  const [showNewMeterDialog, setShowNewMeterDialog] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery(UtilityMetersDocument, {
    variables: { buildingId },
  });
  const [createUtilityMeter, { error: createUtilityMeterError }] = useMutation(
    CreateUtilityMeterDocument
  );
  const { hasModels } = useHasModels(buildingId);

  if (error) throw error;

  if (loading || !data) return <Loading variant="circle" />;

  return (
    <>
      <Box pb={1} display="flex" justifyContent="space-between">
        <PageTitle>{ProductCopy.UTILITY_METERS}</PageTitle>
        <Button
          size="small"
          onClick={() => setShowNewMeterDialog(true)}
          locked={hasModels}
          tooltip={hasModels ? "Building models refer to this data" : undefined}
        >
          Add Meter
        </Button>
      </Box>
      {data.building.utilityMeters.length === 0 && (
        <Box pb={15}>
          <PlaceholderContainer>
            <PlaceholderSegment
              icon={SpeedIcon}
              header="Add meters"
              subheader="EnergyRM requires at least 12 months of training data to
                calibrate your building’s yearly behavior"
              buttonText="Add your first utility meter"
              onButtonClick={() => setShowNewMeterDialog(true)}
            />
          </PlaceholderContainer>
        </Box>
      )}
      <Grid container spacing={2}>
        {data.building.utilityMeters.map((meter) => (
          <UtilityMeterListCard
            key={meter.id}
            meter={meter}
            hasModels={hasModels}
            buildingId={buildingId}
            buildingTimeZone={data.building.address.timeZone}
          />
        ))}
      </Grid>
      {showNewMeterDialog && (
        <NewUtilityMeterDialog
          onCancel={() => setShowNewMeterDialog(false)}
          onSubmit={async ({ meterId, energySource, unit, utilityName }) => {
            try {
              const res = await createUtilityMeter({
                variables: {
                  input: {
                    buildingId,
                    externalId: meterId,
                    unit,
                    utilityName,
                    energySource:
                      energySource === "SOLAR"
                        ? EnergySource.ELECTRICITY
                        : energySource,
                    source:
                      energySource === "SOLAR"
                        ? MeterSource.SOLAR
                        : MeterSource.GRID,
                  },
                },
              });

              refetch();
              setShowNewMeterDialog(false);
              createToast(
                `${res.data?.createUtilityMeter.utilityMeter.externalId} was created`,
                "success"
              );
            } catch (err) {
              // Errors are handled through the 'error' prop of the form, via form helper text
            }
          }}
          error={createUtilityMeterError?.graphQLErrors[0]}
        />
      )}
    </>
  );
};

export default UtilityMetersList;
