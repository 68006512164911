import { useSessionWithUser } from "../SessionWithUser";
import { Document } from "./DocumentCenter";

/**
 * File owners, superusers, and organization Owners and Admins may modify documents
 */
export default function useUserCanModify(document: Document) {
  const {
    isSuperuser,
    isOrgAdmin,
    user: { id },
  } = useSessionWithUser();

  return isSuperuser || isOrgAdmin || document.createdBy.id === id;
}
