import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BuildingMetadataQueryQuery } from "./Building/BuildingMetadataQuery.generated";
import {
  buildingById,
  buildingDeltaMeter,
  buildingDocumentCenter,
  buildingEditInfo,
  buildingInsights,
  buildingTransaction,
  calibratedModels,
  financialModels,
  isCurrentBuildingRoute,
  utilityMeters,
} from "./lib/endpoints";
import { navigation } from "./ui/colors";
import ProductCopy from "./ui/ProductCopy";

interface Props {
  building: BuildingMetadataQueryQuery["building"];
}

const useStyles = makeStyles((theme) => ({
  secondaryNav: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
  },
  entityName: {
    maxWidth: "100%",
    "& *": {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold,
  },
  menuItemIndented: {
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  menuItem: {
    borderLeft: `3px solid transparent`,
    color: navigation.secondaryNavBase,
  },
  menuItemSelected: {
    backgroundColor: "transparent !important",
    color: navigation.secondaryNavAccent,
    borderLeft: `3px solid ${navigation.secondaryNavAccent}`,
    borderRadius: "3px",
    "& span": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

interface MenuItemProps {
  path: string;
  disabled?: boolean;
  icon?: ReactNode;
  current: boolean;
  indent?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({
  path,
  disabled,
  icon,
  current,
  children,
  indent,
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <ListItem
      button
      disabled={disabled}
      onClick={() => history.push(path)}
      selected={current}
      className={clsx(classes.menuItem, {
        [classes.menuItemIndented]: indent,
      })}
      classes={{
        selected: classes.menuItemSelected,
      }}
    >
      {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
    </ListItem>
  );
};

const BuildingNavigation: FC<Props> = ({ building }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { id } = building;

  return (
    <Box className={classes.secondaryNav}>
      <List component="nav" dense disablePadding>
        <MenuItem
          path={buildingById({ id })}
          current={isCurrentBuildingRoute.buildingById(pathname, id)}
        >
          Home
        </MenuItem>
        <MenuItem
          path={buildingEditInfo({ buildingId: id })}
          current={isCurrentBuildingRoute.buildingEditInfo(pathname, id)}
        >
          Fields
        </MenuItem>
        <MenuItem
          path={utilityMeters({ buildingId: id })}
          current={isCurrentBuildingRoute.utilityMeters(pathname, id)}
        >
          Utilities
        </MenuItem>
        <MenuItem
          path={buildingDocumentCenter({ buildingId: id })}
          current={isCurrentBuildingRoute.buildingDocumentCenter(pathname, id)}
        >
          Documents
        </MenuItem>
        <br />
        <MenuItem
          path={calibratedModels({ buildingId: id })}
          current={isCurrentBuildingRoute.calibratedModels(pathname, id)}
        >
          {ProductCopy.ENERGY_MODELS}
        </MenuItem>
        <MenuItem
          path={buildingInsights({ buildingId: id })}
          current={isCurrentBuildingRoute.buildingInsights(pathname, id)}
        >
          Insights report
        </MenuItem>
        <MenuItem
          path={financialModels({
            buildingId: id,
          })}
          current={isCurrentBuildingRoute.financialModels(pathname, id)}
        >
          {ProductCopy.FINANCIAL_MODELS}
        </MenuItem>
        <MenuItem
          path={buildingDeltaMeter({ buildingId: id })}
          current={isCurrentBuildingRoute.buildingDeltaMeter(pathname, id)}
        >
          {ProductCopy.DELTAMETER}
        </MenuItem>
        <MenuItem
          path={buildingTransaction({ buildingId: id })}
          current={
            isCurrentBuildingRoute.buildingTransaction(pathname, id) ||
            isCurrentBuildingRoute.buildingTransactionInvoices(pathname, id)
          }
        >
          Transactions
        </MenuItem>
      </List>
    </Box>
  );
};

export default BuildingNavigation;
