/**
 * Creates an Array.prototype.sort() callback that sorts words A-Z
 */
const sortAlpha = (a: string, b: string): number => {
  const aLower = a.toLowerCase();
  const bLower = b.toLowerCase();

  return aLower.localeCompare(bLower);
};

export default sortAlpha;
