import {
  Box,
  Button,
  Grid,
  Icon,
  makeStyles,
  styled,
  SvgIconTypeMap,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { SectionTitle } from "./Typography";

const useStyles = makeStyles(({ palette }) => ({
  placeholder: {
    backgroundColor: palette.grey[200],
  },
  fadedText: {
    "& *": {
      color: palette.grey[600],
    },
  },
}));

interface Props {
  icon?: OverridableComponent<SvgIconTypeMap>;
  header?: string;
  subheader?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  /** Will override buttonText and onButtonClick if provided */
  buttonComponent?: ReactNode;
  fadeText?: boolean;
}

const PlaceholderSegment: React.FC<Props> = ({
  icon,
  header,
  subheader,
  onButtonClick,
  buttonText,
  buttonComponent,
  fadeText = false,
}) => {
  const classes = useStyles();

  return (
    <Box p={5}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={clsx({
          [classes.fadedText]: fadeText,
        })}
      >
        {!!icon && <Icon style={{ fontSize: 50 }} component={icon} />}
        {!!header && <SectionTitle>{header}</SectionTitle>}
        {!!subheader && <Typography variant="body2">{subheader}</Typography>}
        {!!buttonComponent ? (
          <>
            <br />
            {buttonComponent}
          </>
        ) : (
          !!buttonText && (
            <>
              <br />
              <Button
                color="primary"
                variant="contained"
                onClick={onButtonClick}
              >
                {buttonText}
              </Button>
            </>
          )
        )}
      </Grid>
    </Box>
  );
};

export default PlaceholderSegment;

export const TablePlaceholder: React.FC<
  Props & { colSpan: number; background?: boolean }
> = ({ colSpan, background, ...props }) => {
  const { placeholder } = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={background ? placeholder : ""}>
        <PlaceholderSegment {...props} />
      </TableCell>
    </TableRow>
  );
};

export const PlaceholderContainer = styled("div")(({ theme: { palette } }) => ({
  border: `1px solid ${palette.grey[300]}`,
  borderRadius: "5px",
  boxShadow: "0 2px 25px 0 rgba(34, 36,38, 0.08) inset",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));
