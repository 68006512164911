import {
  LazyQueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
} from "@apollo/client";
import { useState } from "react";
import { useToastContext } from "../ui/ToastProvider";

/**
 * useLazyDialogData encapsulates a pattern for dialogs that require a lazy query
 * before opening. the activating button should have a loading state.
 */
const useLazyDialogData = <T, V>(
  documentNode: TypedDocumentNode<T, V>,
  config: Omit<LazyQueryHookOptions, "onCompleted"> = {}
) => {
  const createToast = useToastContext();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [getResult, { data, loading }] = useLazyQuery<T>(documentNode, {
    ...config,
    // result is available now, so we can open the dialog
    onCompleted: () => setDialogOpen(true),
    // if there is an error, alert the user
    onError: () => createToast("Could not load", "error"),
  });

  return {
    openDialog: (variables: V) => getResult({ variables }),
    closeDialog: () => setDialogOpen(false),
    data: dialogOpen ? data : undefined,
    loading,
  };
};

export default useLazyDialogData;
