import {
  Currency,
  EnergySource,
  EnergyUnitType,
  MeterSource,
  MoneyInput,
} from "../api/graphql";
import { buildingUseTypeByName } from "./buildingUseType";
import { leaseTypeByName } from "./LeaseType";
import usaStates from "./usaStates";

export type Cell = string | number | Date | undefined;
export type Row = Record<string, Cell>;
export type Sheet = Row[];

const cellHasContent = (cell: Cell): boolean =>
  !!cell && cell.toString().trim() !== "";

const rowHasContent = (row: Row): boolean =>
  Object.values(row).some(cellHasContent);

/**
 * sheetParser returns a function that can parse spreadsheets-as-json
 */
export default function sheetParser<T extends Record<string, any>>(
  formatter: (row: Row) => T
): (sheet: Sheet) => T[] {
  return (sheet) => {
    const result: T[] = [];
    for (const row of sheet) {
      const formattedRow = formatter(row);
      if (rowHasContent(formattedRow)) {
        result.push(formattedRow);
      }
    }
    return result;
  };
}

export const parseAsString = (value: Cell): string | undefined =>
  value?.toString();

export const parseAsDate = (value: Cell): Date | undefined => {
  if (value === undefined) {
    return;
  }
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return;
  }
  return date;
};

export const parseAsInt = (value: Cell): number | undefined => {
  if (value === undefined) {
    return;
  }
  const int = parseInt(value.toString(), 10);
  if (isNaN(int)) {
    return;
  }
  return int;
};

export const parseAsYear = (value: Cell): number | undefined => {
  if (value === undefined) {
    return;
  }
  return value.toString().match(/[^\d]/)
    ? parseAsDate(value)?.getUTCFullYear()
    : parseAsInt(value) || undefined;
};

export const parseAsUSZip = (value: Cell): string | undefined =>
  value === undefined ? undefined : value.toString().padStart(5, "0");

export const parseAsUSState = (value: Cell): string | undefined =>
  value === undefined
    ? undefined
    : usaStates.find(
        (state) => state.name.toLowerCase() === value.toString().toLowerCase()
      )?.abbreviation || value.toString();

export const parseAsEnergySource = (value: Cell): string | undefined => {
  if (value === undefined) {
    return;
  }
  switch (value.toString().toLowerCase()) {
    case "electric":
    case "electricity":
    case "solar":
      return EnergySource.ELECTRICITY;
    case "chilled water":
      return EnergySource.CHILLED_WATER;
    case "fuel oil 2":
    case "fuel oil #2":
    case "fuel oil no 2":
      return EnergySource.FUEL_OIL2;
    case "natural gas":
      return EnergySource.NATURAL_GAS;
    case "propane":
      return EnergySource.PROPANE;
    case "steam":
      return EnergySource.STEAM;
    default:
      return value.toString();
  }
};

export const parseAsBuildingUseType = (value: Cell): string | undefined => {
  if (value === undefined) {
    return;
  }
  return buildingUseTypeByName(value.toString()) || value.toString();
};

export const parseAsEnergyUnit = (value: Cell): string | undefined => {
  if (value === undefined) {
    return;
  }
  switch (value.toString().toLowerCase()) {
    case "wh":
    case "kwh":
    case "mwh":
    case "therm":
    case "dth":
    case "btu":
    case "kbtu":
    case "mmbtu":
    case "cf":
    case "ccf":
    case "hcf":
    case "kcf":
    case "mcf":
    case "m3":
    case "gal":
    case "lbs":
    case "klbs":
    case "mlbs":
    case "gj":
      return value.toString().toUpperCase() as EnergyUnitType;
    case "gal propane":
      return EnergyUnitType.GAL;
    case "therms":
      return EnergyUnitType.THERM;
    default:
      return value.toString();
  }
};

export const parseAsMeterSource = (value: Cell): MeterSource => {
  return value?.toString().toLowerCase() === "solar"
    ? MeterSource.SOLAR
    : MeterSource.GRID;
};

export const parseAsMoney = (
  value: Cell,
  currency: Currency
): MoneyInput | undefined =>
  value === undefined
    ? undefined
    : isNaN(parseFloat(value.toString()))
    ? undefined
    : {
        value: parseFloat(value.toString()),
        unit: currency,
      };

export const parseAsLeaseType = (value: Cell): string | undefined =>
  value === undefined
    ? undefined
    : leaseTypeByName(value.toString()) || value.toString();
