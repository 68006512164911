import { ApolloProvider } from "@apollo/client";
import { Box, createMuiTheme, ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { FC, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from "react-router-dom";
import AcceptInvite from "./AcceptInvite";
import ApplicationLayout from "./ApplicationLayout";
import Building from "./Building";
import BuildingsImport from "./BuildingsImport";
import BuildingsList from "./BuildingsList";
import client from "./client";
import DeltaMetersList from "./DeltaMetersList";
import Documentation from "./Documentation";
import DocumentCenter from "./DocumentCenter/DocumentCenter";
import AuthN from "./lib/AuthN";
import * as endpoints from "./lib/endpoints";
import MyAccount from "./MyAccount";
import OrganizationUser from "./OrganizationUser";
import OrganizationUsers from "./OrganizationUsers";
import OrgDashboard from "./OrgDashboard/OrgDashboard";
import PageTracker from "./PageTracker";
import Register from "./Register";
import { AuthenticatedRoute } from "./RequireUser";
import ResetPassword from "./ResetPassword";
import SessionManager from "./SessionManager";
import SessionWithUser from "./SessionWithUser";
import Signin from "./Signin";
import TermsOfService from "./TermsOfService";
import TransactionsList from "./TransactionsList/TransactionsList";
import { AppRouteParams } from "./ui/AppRouteParams";
import colors from "./ui/colors";
import { MUIDataTableOverrides } from "./ui/DataTable";
import Loading from "./ui/Loading";
import NotFound from "./ui/NotFound";
import ToastProvider from "./ui/ToastProvider";

export const theme = createMuiTheme({
  palette: {
    primary: colors.blue,
    secondary: colors.orange,
    success: colors.green,
    info: colors.gray,
    warning: colors.orange,
    error: colors.red,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: colors.blue.xxlight,
        },
      },
    },
    // Require in top-level overrides for the third-party MUIDataTable component's target classes
    ...MUIDataTableOverrides,
  },
});

const LazyPrivacyPolicy = lazy(() => import("./PrivacyPolicy"));

const ContentPadding: FC = ({ children }) => {
  return (
    <Box px={3} pt={1}>
      {children}
    </Box>
  );
};

function App() {
  const history = useHistory();
  return (
    <Suspense fallback={<Loading variant="circle" />}>
      <ApolloProvider client={client}>
        <SessionManager>
          {({ getCurrentUser, clearCurrentUser }) => (
            <AuthN
              host={process.env.REACT_APP_AUTHN_URL || ""}
              localStorageKey="erm"
              onLogin={getCurrentUser}
              onLogout={clearCurrentUser}
            >
              <IntlProvider locale="en-US">
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Helmet>
                    <title>EnergyRM</title>
                  </Helmet>
                  <ToastProvider>
                    <Router>
                      <Route path="/">
                        <PageTracker />
                      </Route>
                      <Switch>
                        <Route path={endpoints.register()}>
                          <Register />
                        </Route>
                        <Route exact path={endpoints.signin()}>
                          <Signin />
                        </Route>
                        <Route exact path={endpoints.acceptInvite()}>
                          <AcceptInvite />
                        </Route>
                        <Route exact path={endpoints.resetPassword()}>
                          <ResetPassword />
                        </Route>
                        <Route exact path={endpoints.privacyPolicy()}>
                          <LazyPrivacyPolicy />
                        </Route>
                        <Route exact path={endpoints.termsOfService()}>
                          <TermsOfService />
                        </Route>
                        <AuthenticatedRoute>
                          <SessionWithUser>
                            <ApplicationLayout disablePadding>
                              <Switch>
                                <Route exact path={endpoints.documentation()}>
                                  <Documentation />
                                </Route>
                                <Route exact path={endpoints.dashboard()}>
                                  <ContentPadding>
                                    <OrgDashboard />
                                  </ContentPadding>
                                </Route>
                                <Route exact path={endpoints.account()}>
                                  <ContentPadding>
                                    <MyAccount />
                                  </ContentPadding>
                                </Route>
                                <Route exact path={endpoints.documentCenter()}>
                                  <ContentPadding>
                                    <DocumentCenter />
                                  </ContentPadding>
                                </Route>
                                <Route exact path={endpoints.insights()}>
                                  <Redirect to={endpoints.dashboard()} />
                                </Route>
                                <Route exact path={endpoints.buildingsList()}>
                                  <ContentPadding>
                                    <BuildingsList />
                                  </ContentPadding>
                                </Route>
                                <AuthenticatedRoute
                                  superuserOnly
                                  exact
                                  path={endpoints.buildingsImport()}
                                >
                                  <ContentPadding>
                                    <BuildingsImport />
                                  </ContentPadding>
                                </AuthenticatedRoute>
                                <Route
                                  path={endpoints.buildingById({
                                    id: AppRouteParams.buildingId,
                                  })}
                                  render={({
                                    match,
                                  }: RouteComponentProps<{
                                    id: string;
                                  }>) => <Building id={match.params.id} />}
                                />
                                <Route
                                  exact
                                  path={endpoints.organizationById({
                                    id: AppRouteParams.organizationId,
                                  })}
                                  render={({
                                    match,
                                  }: RouteComponentProps<{
                                    organizationId: string;
                                  }>) => (
                                    <ContentPadding>
                                      <OrganizationUsers
                                        id={match.params.organizationId}
                                      />
                                    </ContentPadding>
                                  )}
                                />
                                <Route
                                  exact
                                  path={endpoints.organizationUser({
                                    organizationId:
                                      AppRouteParams.organizationId,
                                    userId: AppRouteParams.userId,
                                  })}
                                  render={({
                                    match,
                                  }: RouteComponentProps<{
                                    organizationId: string;
                                    userId: string;
                                  }>) => (
                                    <ContentPadding>
                                      <OrganizationUser
                                        organizationId={
                                          match.params.organizationId
                                        }
                                        userId={match.params.userId}
                                      />
                                    </ContentPadding>
                                  )}
                                />
                                <Route exact path={endpoints.deltaMeters()}>
                                  <ContentPadding>
                                    <DeltaMetersList />
                                  </ContentPadding>
                                </Route>
                                <Route exact path={endpoints.transactions()}>
                                  <ContentPadding>
                                    <TransactionsList />
                                  </ContentPadding>
                                </Route>
                                <Route>
                                  <NotFound
                                    buttonText="Back to EnergyRM"
                                    onClick={() => history.push("/")}
                                  />
                                </Route>
                              </Switch>
                            </ApplicationLayout>
                          </SessionWithUser>
                        </AuthenticatedRoute>
                      </Switch>
                    </Router>
                  </ToastProvider>
                </ThemeProvider>
              </IntlProvider>
            </AuthN>
          )}
        </SessionManager>
      </ApolloProvider>
    </Suspense>
  );
}

export default App;
