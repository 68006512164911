import { Box, CircularProgress, Grid, useTheme } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { EnergySource, WeatherInterval } from "../api/graphql";
import pluckNodes from "../lib/pluckNodes";
import useLazyDialogData from "../lib/useLazyDialogData";
import BrandRangeSlider from "../ui/BrandRangeSlider";
import Button from "../ui/Button";
import ResponsiveChart from "../ui/charting/ResponsiveChart";
import DatePicker from "../ui/DatePicker";
import FormDialog from "../ui/FormDialog";
import { SectionTitle } from "../ui/Typography";
import createBillingPeriodConnectors from "./createBillingPeriodConnectors";
import {
  AggregateEnergyReadingModelFragment,
  ReferencePeriodMetersQueryDocument,
  ReferencePeriodMetersQueryQuery,
} from "./ReferencePeriodMetersQuery.generated";
import ReferencePeriodPreviewChart from "./ReferencePeriodPreviewChart";

interface Props {
  buildingId: string;
}

// TODO: turn into a fragment
export type UtilityMeter = ReferencePeriodMetersQueryQuery["building"]["aggregateMeters"][number];
export type MeterReading = AggregateEnergyReadingModelFragment & {
  energySource: EnergySource;
};

/**
 * Finds the min and max dates from a list of utility meters and
 * their respective reading intervals
 */
// TODO: fallback for this if interval is undefined?
const findMinMaxDates = (utilityMeters: UtilityMeter[]) => {
  return [
    DateTime.min(
      ...utilityMeters.map((um) =>
        DateTime.fromISO(um.readings.interval!.start)
      )
    ).toJSDate(),
    DateTime.max(
      ...utilityMeters.map((um) => DateTime.fromISO(um.readings.interval!.end))
    ).toJSDate(),
  ] as const;
};

interface NewModelDialogProps {
  onClose: () => void;
  utilityMeters: UtilityMeter[];
  weatherData: WeatherInterval[];
}

const NewModelDialog: FC<NewModelDialogProps> = ({
  onClose,
  utilityMeters,
  weatherData,
}) => {
  const [minDate, maxDate] = findMinMaxDates(utilityMeters);
  const [rangeStart, setRangeStart] = useState<Date>(minDate);
  const [rangeEnd, setRangeEnd] = useState<Date>(maxDate);

  const onReset = () => {
    setRangeStart(minDate);
    setRangeEnd(maxDate);
  };

  const onSubmit = () => {
    alert(JSON.stringify({ rangeStart, rangeEnd }));
  };

  // Must memoize to improve performance across renders when dragging slider
  const utilityMeterChartData = useMemo(
    () =>
      utilityMeters.flatMap((d) =>
        pluckNodes(d.readings).map((r) => ({
          ...r,
          // Attach meter energySource to reading
          energySource: d.energySource,
        }))
      ),
    [utilityMeters]
  );

  const billingPeriodConnectors = useMemo(
    () => utilityMeters.flatMap((um) => createBillingPeriodConnectors(um, 1)),
    [utilityMeters]
  );

  const { spacing } = useTheme();
  const { formatDate } = useIntl();

  const dateFormatter = (v: number) => formatDate(new Date(v));

  return (
    <FormDialog
      title="Select reference period"
      onCancel={onClose}
      onSubmit={onSubmit}
      isSubmitting={false}
      maxWidth="lg"
    >
      <pre>{JSON.stringify({ rangeStart, rangeEnd }, null, 2)}</pre>
      <div>
        <Button onClick={() => onReset()}>Reset form</Button>
      </div>
      <Grid container direction="row">
        <Grid item>
          <DatePicker
            value={rangeStart}
            minDate={minDate}
            maxDate={rangeEnd}
            onChange={(val) => {
              if (val && !isNaN(val.getTime())) setRangeStart(val);
            }}
          />
        </Grid>
        <Grid item>
          <DatePicker
            value={rangeEnd}
            minDate={rangeStart}
            maxDate={maxDate}
            onChange={(val) => {
              if (val && !isNaN(val.getTime())) setRangeEnd(val);
            }}
          />
        </Grid>
      </Grid>
      <SectionTitle>Aggregate meters</SectionTitle>

      <ResponsiveChart ratio={20 / 4}>
        {({ width, height }) => (
          <ReferencePeriodPreviewChart
            utilityMeterData={utilityMeterChartData}
            weatherData={weatherData}
            readingConnections={billingPeriodConnectors}
            width={width}
            height={height}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            margin={{
              top: spacing(1),
              right: spacing(7),
              bottom: spacing(4),
              left: spacing(7),
            }}
          />
        )}
      </ResponsiveChart>
      <Box px={7}>
        <BrandRangeSlider
          min={minDate.valueOf()}
          max={maxDate.valueOf()}
          labelFormatter={dateFormatter}
          valueLabelFormat={dateFormatter}
          value={[rangeStart.valueOf(), rangeEnd.valueOf()]}
          updateImmediately
          onChange={([start, end]) => {
            setRangeStart(new Date(start));
            setRangeEnd(new Date(end));
          }}
        />
      </Box>
    </FormDialog>
  );
};

const ReferencePeriodSelectionButton: FC<Props> = ({ buildingId }) => {
  const { openDialog, closeDialog, data, loading } = useLazyDialogData(
    ReferencePeriodMetersQueryDocument
  );

  return (
    <>
      {data && (
        <NewModelDialog
          utilityMeters={data.building.aggregateMeters}
          weatherData={data.building.referenceWeather}
          onClose={() => closeDialog()}
        />
      )}
      <Button onClick={() => openDialog({ buildingId })} size="small">
        {loading ? <CircularProgress size={15} /> : "Create energy model"}
      </Button>
    </>
  );
};

export default ReferencePeriodSelectionButton;
