import queryString from "query-string";
export type UrlGenerator<T> = (config: T) => string;

export const withQuery = (url: string, query: { [key: string]: any }): string =>
  `${url}?${queryString.stringify(query)}`;

// Main routes
export const dashboard: UrlGenerator<void> = () => "/";

export const insights: UrlGenerator<void> = () => "/insights";

export const buildingsList: UrlGenerator<void> = () => "/buildings";

export const buildingsImport: UrlGenerator<void> = () => "/buildings/import";

export const buildingById: UrlGenerator<{ id: string }> = ({ id }) =>
  `${buildingsList()}/${id}`;

export const buildingInsights: UrlGenerator<{ buildingId: string }> = ({
  buildingId,
}) => `${buildingById({ id: buildingId })}${insights()}`;

export const buildingEditInfo: UrlGenerator<{ buildingId: string }> = ({
  buildingId,
}) => `${buildingById({ id: buildingId })}/edit`;

export const utilityMeters: UrlGenerator<{ buildingId: string }> = ({
  buildingId,
}) => `${buildingById({ id: buildingId })}/utilityMeters`;

export const buildingUtilityMeter: UrlGenerator<{
  buildingId: string;
  utilityMeterId: string;
}> = ({ buildingId, utilityMeterId }) =>
  `${buildingById({ id: buildingId })}/utilityMeters/${utilityMeterId}`;

export const calibratedModels: UrlGenerator<{ buildingId: string }> = ({
  buildingId,
}) => `${buildingById({ id: buildingId })}/models`;

export const calibratedModelById: UrlGenerator<{ id: string }> = ({ id }) =>
  `/models/${id}`;

export const buildingCalibratedModel: UrlGenerator<{
  buildingId: string;
  calibratedModelId: string;
}> = ({ buildingId, calibratedModelId }) =>
  `${buildingById({ id: buildingId })}${calibratedModelById({
    id: calibratedModelId,
  })}`;

export const organizationById: UrlGenerator<{ id: string }> = ({ id }) =>
  `/organizations/${id}`;

export const organizationUser: UrlGenerator<{
  userId: string;
  organizationId: string;
}> = ({ userId, organizationId }) =>
  `${organizationById({ id: organizationId })}/users/${userId}`;

export const deltaMeters: UrlGenerator<void> = () => "/deltameters";
const deltaMeter: UrlGenerator<void> = () => "/deltameter";

export const buildingDeltaMeter: UrlGenerator<{
  buildingId: string;
}> = ({ buildingId }) => `${buildingById({ id: buildingId })}${deltaMeter()}`;

export const buildingDeltaMeterReading: UrlGenerator<{
  buildingId: string;
  readingId: string;
}> = ({ buildingId, readingId }) =>
  `${buildingDeltaMeter({ buildingId })}/readings/${readingId}`;

export const financialModels: UrlGenerator<{ buildingId: string }> = ({
  buildingId,
}) => `${buildingById({ id: buildingId })}/financial-models`;

export const newFinancialModel: UrlGenerator<{ buildingId: string }> = ({
  buildingId,
}) => `${financialModels({ buildingId })}/new`;

export const financialModelById: UrlGenerator<{
  buildingId: string;
  financialModelId: string;
}> = ({ buildingId, financialModelId }) =>
  `${financialModels({ buildingId })}/${financialModelId}`;

export const transactions: UrlGenerator<void> = () => "/transactions";
const transaction: UrlGenerator<void> = () => "/transaction";

export const buildingTransaction: UrlGenerator<{
  buildingId: string;
}> = ({ buildingId }) => `${buildingById({ id: buildingId })}${transaction()}`;

export const buildingTransactionInvoices: UrlGenerator<{
  buildingId: string;
}> = ({ buildingId }) => `${buildingTransaction({ buildingId })}/invoices`;

export const documentCenter: UrlGenerator<void> = () => "/document-center";

export const buildingDocumentCenter: UrlGenerator<{ buildingId: string }> = ({
  buildingId,
}) => `${buildingById({ id: buildingId })}${documentCenter()}`;

// User & Account Things
export const account: UrlGenerator<void> = () => "/account";

export const signin: UrlGenerator<void> = () => "/signin";

// Account creation, help, & legal
export const register: UrlGenerator<void> = () => "/register";
// acceptInvite is also used on the backend in createUser email generation.
export const acceptInvite: UrlGenerator<void> = () => "/accept-invite";
export const resetPassword: UrlGenerator<void> = () => "/reset-password";
export const privacyPolicy: UrlGenerator<void> = () => "/privacy-policy";
export const termsOfService: UrlGenerator<void> = () => "/terms-of-service";
export const documentation: UrlGenerator<void> = () => "/documentation";

// Misc errors
export const e404: UrlGenerator<void> = () => "/404";

/** Allows us to check whether current path names match given route views */
export const isCurrentRoute: Record<string, (pathname: string) => boolean> = {
  dashboard: (pathname) => pathname === dashboard(),
  insights: (pathname) => isCurrentRoute.buildingList(pathname),
  buildingList: (pathname) =>
    (!pathname.includes(deltaMeter()) &&
      !pathname.includes(transaction()) &&
      pathname.startsWith(buildingsList())) ||
    pathname.startsWith(calibratedModelById({ id: "" })),
  deltaMeters: (pathname) =>
    pathname.startsWith(deltaMeters()) || pathname.includes(deltaMeter()),
  transactions: (pathname) =>
    pathname.startsWith(transactions()) || pathname.includes(transaction()),
  organizationUsers: (pathname: string) =>
    pathname.startsWith(organizationById({ id: "" })),
  account: (pathname) => pathname === account(),
};

/** Allows us to check whether current building-specific path names match given route views */
export const isCurrentBuildingRoute: Record<
  string,
  (pathname: string, buildingId: string) => boolean
> = {
  buildingById: (pathname, id) => pathname === buildingById({ id }),
  buildingEditInfo: (pathname, id) =>
    pathname === buildingEditInfo({ buildingId: id }),
  buildingInsights: (pathname, id) =>
    pathname === buildingInsights({ buildingId: id }),
  utilityMeters: (pathname, id) =>
    pathname.startsWith(utilityMeters({ buildingId: id })),
  calibratedModels: (pathname, id) =>
    pathname.startsWith(
      calibratedModels({
        buildingId: id,
      })
    ),
  financialModels: (pathname, id) =>
    pathname.startsWith(
      financialModels({
        buildingId: id,
      })
    ),
  buildingDeltaMeter: (pathname, id) =>
    pathname.startsWith(buildingDeltaMeter({ buildingId: id })),
  buildingTransaction: (pathname, id) =>
    pathname.startsWith(buildingTransaction({ buildingId: id })) &&
    !pathname.startsWith(
      buildingTransactionInvoices({
        buildingId: id,
      })
    ),
  buildingTransactionInvoices: (pathname, id) =>
    pathname.startsWith(
      buildingTransactionInvoices({
        buildingId: id,
      })
    ),
  buildingDocumentCenter: (pathname, id) =>
    pathname.startsWith(buildingDocumentCenter({ buildingId: id })),
};
