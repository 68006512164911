import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateBuildingCalibratedModelMutationVariables = Types.Exact<{
  input: Types.UpdateBuildingCalibratedModelInput;
}>;


export type UpdateBuildingCalibratedModelMutation = { updateBuildingCalibratedModel: { __typename: 'UpdateBuildingCalibratedModelResponse', buildingCalibratedModel: { __typename: 'BuildingCalibratedModel', id: string, name: Types.Maybe<string>, description: Types.Maybe<string>, notes: Types.Maybe<string> } } };


export const UpdateBuildingCalibratedModelDocument: DocumentNode<UpdateBuildingCalibratedModelMutation, UpdateBuildingCalibratedModelMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBuildingCalibratedModel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateBuildingCalibratedModelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBuildingCalibratedModel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"buildingCalibratedModel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"notes"}}]}}]}}]}}]};