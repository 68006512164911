import React from "react";
import { SvgIcon } from "@material-ui/core";

function SvgBulbLgOpt(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23.52 36.446" {...props}>
      <path d="M10.3 36.078a2.932 2.932 0 01-1.14-1.007c-.236-.351-.43-.691-.43-.756 0-.065 1.361-.117 3.043-.117 1.682 0 3.043.052 3.043.117s-.194.405-.43.756a3.133 3.133 0 01-2.613 1.376c-.577 0-.936-.09-1.473-.369zM6.402 32.93c-.158-.379-.168-.566-.049-.928l.15-.453 10.611.069.11.427c.077.296.051.57-.084.893l-.194.466H6.6zm.004-2.587c-.244-.472-.175-1.087.164-1.461.203-.224.554-.24 5.203-.24 4.65 0 5.001.016 5.204.24.339.374.408.989.164 1.46l-.215.416H6.62l-.215-.415zm-.049-2.685c-.087-.104-.214-.595-.283-1.091-.314-2.245-.757-3.242-2.9-6.525C1.117 16.887.593 15.732.2 13.48-.459 9.704.5 6.37 3.04 3.597 4.33 2.19 6.49.9 8.552.301c.894-.259 1.343-.3 3.221-.3 1.879 0 2.328.041 3.222.3 2.053.595 4.226 1.892 5.493 3.278 2.455 2.686 3.433 5.926 2.879 9.54-.371 2.422-1.028 3.903-3.15 7.102-.73 1.102-1.463 2.272-1.628 2.6-.542 1.075-.983 2.484-1.12 3.584-.075.595-.204 1.162-.286 1.262-.228.275-10.597.266-10.826-.009z" />
    </svg>
  );
}

const BulbLg = () => (
  <SvgIcon component={SvgBulbLgOpt} viewBox="0 0 24 35" fontSize="large" />
);

export default BulbLg;
