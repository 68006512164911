import { Button, makeStyles } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { Alert } from "@material-ui/lab";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { financialModels, utilityMeters } from "../lib/endpoints";

interface Props {
  message: string;
  actionText?: string;
  to?: string;
}

const useStyles = makeStyles(({ palette }) => ({
  standardInfo: {
    backgroundColor: lighten(palette.primary.main, 0.8),
    "& *": {
      color: palette.primary.dark,
    },
    "@media print": {
      display: "none",
    },
  },
}));

const ActionBanner: FC<Props> = ({ message, actionText, to }) => {
  const classes = useStyles();

  return (
    <Alert
      classes={{
        standardInfo: classes.standardInfo,
      }}
      action={
        actionText &&
        to && (
          <Button variant="outlined" component={RouterLink} to={to}>
            {actionText}
          </Button>
        )
      }
      severity="info"
    >
      {message}
    </Alert>
  );
};

export const UnmodeledActionBanner: FC<{ buildingId: string }> = ({
  buildingId,
}) => {
  return (
    <ActionBanner
      message="Next, enter utility meter data and generate energy models."
      actionText="Continue"
      to={utilityMeters({ buildingId })}
    />
  );
};

export const ModeledActionBanner: FC<{ buildingId: string }> = ({
  buildingId,
}) => {
  return (
    <ActionBanner
      message="Next, create a financial model to inform your project proposal."
      actionText="Continue"
      to={financialModels({ buildingId })}
    />
  );
};

export default ActionBanner;
