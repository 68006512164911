import { DateTime, Interval } from "luxon";
import { DeltaMeterReportModelFragment } from "../api/fragments/DeltaMeterReportModel.generated";
import { EnergySource, EnergyUnitType } from "../api/graphql";
import sum from "../lib/sum";
import targetUnitConverter from "../lib/targetUnitConverter";
import { Forecast, Reading, ReportPeriod } from "./DeltaMeterChart";

/** Determines whether or not report data includes a tracker model */
const hasTrackerValues = (data: DeltaMeterReportModelFragment[]): boolean =>
  data.some((d) => d.reading?.tracker != null || d.forecast.tracker != null);

/**
 * Grabs all distinct energy sources from a report
 */
export const distinctEnergySources = (
  data: DeltaMeterReportModelFragment[]
): EnergySource[] => Array.from(new Set(data.map((d) => d.energySource)));

/**
 * Reduce interval values to a daily average. Used per single energy source
 */
export const reduceIntervalForecasts = (data: ReportPeriod[]): Forecast => {
  const forecastDays = sum(data.map((d) => d.interval.daysInPeriod));

  return {
    airTemp: sum(data.map((d) => d.forecast.airTemp)) / data.length,
    baseline: sum(data.map((d) => d.forecast.baseline)) / forecastDays,
    tracker: sum(data.map((d) => d.forecast.tracker || 0)) / forecastDays,
  };
};

/**
 * Reduce interval values to a daily average. Used per single energy source
 */
export const reduceIntervalReadings = (
  data: ReportPeriod[]
): Reading | null => {
  const periodWithReadings = data.filter((d) => !!d.reading);

  if (periodWithReadings.length === 0) return null;

  const readingDays = sum(
    periodWithReadings.map((d) => d.interval.daysInPeriod)
  );

  return {
    airTemp:
      sum(periodWithReadings.map((d) => d.reading!.airTemp)) /
      periodWithReadings.length,
    baseline:
      sum(periodWithReadings.map((d) => d.reading!.baseline)) / readingDays,
    tracker:
      sum(periodWithReadings.map((d) => d.reading?.tracker || 0)) / readingDays,
    actual: sum(periodWithReadings.map((d) => d.reading!.actual)) / readingDays,
  };
};

/**
 * Finds the earliest and latest dates from a dataset
 */
export const endcapDates = (
  data: DeltaMeterReportModelFragment[]
): { start: DateTime; end: DateTime } => {
  return {
    start: DateTime.min(
      ...data.map((d) => DateTime.fromISO(d.interval.start, { zone: "UTC" }))
    ),
    end: DateTime.max(
      ...data.map((d) => DateTime.fromISO(d.interval.end, { zone: "UTC" }))
    ),
  };
};

/**
 * Finds the latest start date and earliest end date from a report
 */
export function overlappingInterval(
  data: DeltaMeterReportModelFragment[]
): Interval {
  const intervals: Interval[] = distinctEnergySources(data).map((source) => {
    const { start, end } = endcapDates(
      data.filter((d) => d.energySource === source)
    );
    return Interval.fromDateTimes(start, end);
  });

  return intervals.reduce((acc, c) => {
    return c.intersection(acc)!;
  }, intervals[0]);
}

/**
 * Splits an interval into calendar months
 */
export function createCalendarMonths(interval: Interval): Interval[] {
  const startUTC = interval.start.toUTC();
  const endUTC = interval.end.toUTC();
  const months: Interval[] = [
    Interval.fromDateTimes(startUTC, startUTC.endOf("month")),
  ];
  while (months[months.length - 1].end < interval.end) {
    const lastMonth = months[months.length - 1];
    const monthStart = lastMonth.end.plus({ millisecond: 1 });
    months.push(
      Interval.fromDateTimes(
        monthStart,
        DateTime.min(endUTC, monthStart.endOf("month"))
      )
    );
  }

  return months;
}

/**
 * Intakes all monthly bucket intervals
 * Finds overlap between energy source readings
 * Calculates partial readings based on interval overlap
 * Sums up all per-day values
 */
export function apportionSourcePerMonth(
  intervals: Interval[],
  data: DeltaMeterReportModelFragment[],
  targetUnit: EnergyUnitType
): ReportPeriod[] {
  const toTargetUnit = targetUnitConverter(targetUnit);

  return intervals.map((interval) => {
    const transformedData: ReportPeriod[] = data
      // Filter out dates with no overlap
      .filter((d) => {
        return Interval.fromDateTimes(
          DateTime.fromISO(d.interval.start),
          DateTime.fromISO(d.interval.end)
        ).overlaps(interval);
      })
      // Map each relevant datum to a proportion of the interval it overlaps with
      .map((d) => {
        const dateRange = Interval.fromDateTimes(
          DateTime.fromISO(d.interval.start),
          DateTime.fromISO(d.interval.end)
        );

        const sharedDays = interval.intersection(dateRange)!;
        const percentOverlap =
          sharedDays.count("days") / d.interval.daysInPeriod;

        // Find the total energy for a meter read, multiplied by the number of days it's overlapping the interval
        return {
          energySource: d.energySource,
          interval: {
            start: sharedDays.start.toISO(),
            end: sharedDays.end.toISO(),
            daysInPeriod: sharedDays.count("days"),
          },
          forecast: {
            airTemp: d.forecast.airTemp,
            baseline:
              toTargetUnit(d.forecast.baseline, d.unit) * percentOverlap,
            tracker:
              toTargetUnit(d.forecast.tracker || 0, d.unit) * percentOverlap,
          },
          reading: d.reading
            ? {
                airTemp: d.reading.airTemp,
                baseline:
                  toTargetUnit(d.reading.adjustedBaseline, d.unit) *
                  percentOverlap,
                tracker:
                  toTargetUnit(d.reading.tracker || 0, d.unit) * percentOverlap,
                actual: toTargetUnit(d.reading.actual, d.unit) * percentOverlap,
              }
            : null,
          unit: targetUnit,
        };
      });

    return {
      energySource: null,
      interval: {
        start: interval.start.toISO(),
        end: interval.end.toISO(),
        daysInPeriod: interval.count("days"),
      },
      forecast: reduceIntervalForecasts(transformedData),
      reading: reduceIntervalReadings(transformedData),
      unit: targetUnit,
    };
  });
}

/**
 * Creates a combined report for the graph
 */
export default function aggregateReport(
  report: DeltaMeterReportModelFragment[],
  targetUnit: EnergyUnitType
): ReportPeriod[] {
  const overlap = overlappingInterval(report);
  const monthsDates = createCalendarMonths(overlap);

  const hasTracker = hasTrackerValues(report);

  const combinedEnergyPeriods: ReportPeriod[][] = distinctEnergySources(
    report
  ).map((source) => {
    return apportionSourcePerMonth(
      monthsDates,
      report.filter((r) => r.energySource === source),
      targetUnit
    );
  });

  return monthsDates.map(
    (interval, idx): ReportPeriod => {
      const currentSlice: ReportPeriod[] = combinedEnergyPeriods.map(
        (c) => c[idx]
      );
      const containsAllReadings: boolean = currentSlice.every(
        (c) => !!c.reading
      );

      return {
        energySource: null,
        interval: {
          start: interval.start.toISO(),
          end: interval.end.toISO(),
          daysInPeriod: interval.count("days"),
        },
        forecast: {
          airTemp:
            sum(currentSlice.map((cs) => cs.forecast.airTemp)) /
            currentSlice.length,
          baseline: sum(currentSlice.map((cs) => cs.forecast.baseline)),
          tracker: hasTracker
            ? sum(currentSlice.map((cs) => cs.forecast.tracker || 0))
            : null,
        },
        reading: containsAllReadings
          ? {
              airTemp:
                sum(currentSlice.map((cs) => cs.reading!.airTemp)) /
                currentSlice.length,
              baseline: sum(currentSlice.map((cs) => cs.reading!.baseline)),
              tracker: hasTracker
                ? sum(currentSlice.map((cs) => cs.reading!.tracker || 0))
                : null,
              actual: sum(currentSlice.map((cs) => cs.reading!.actual)),
            }
          : null,
        unit: targetUnit,
      };
    }
  );
}

/**
 * Report for a single energy source
 */
export function singleFuelReport(
  report: DeltaMeterReportModelFragment[],
  targetUnit: EnergyUnitType
): ReportPeriod[] {
  const toTargetUnit = targetUnitConverter(targetUnit);

  return report.map((r) => {
    const { daysInPeriod } = r.interval;
    const hasTracker = hasTrackerValues(report);

    return {
      energySource: r.energySource,
      interval: r.interval,
      forecast: {
        airTemp: r.forecast.airTemp,
        baseline: toTargetUnit(r.forecast.baseline, r.unit) / daysInPeriod,
        tracker: hasTracker
          ? toTargetUnit(r.forecast.tracker || 0, r.unit) / daysInPeriod
          : null,
      },
      reading: !!r.reading
        ? {
            airTemp: r.reading.airTemp,
            baseline:
              toTargetUnit(r.reading.adjustedBaseline, r.unit) / daysInPeriod,
            tracker: hasTracker
              ? toTargetUnit(r.reading.tracker || 0, r.unit) / daysInPeriod
              : null,
            actual: toTargetUnit(r.reading.actual, r.unit) / daysInPeriod,
          }
        : null,
      unit: targetUnit,
    };
  });
}
