import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import Lockable from "./Lockable";

type TextFieldProps = Omit<
  MUITextFieldProps,
  "variant" | "margin" | "error"
> & {
  error?: string;
  locked?: boolean;
  lockExplanation?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
  error,
  locked,
  lockExplanation,
  disabled,
  label,
  ...props
}) => (
  <Tooltip title={(locked && lockExplanation) || ""}>
    <MUITextField
      variant="outlined"
      margin="normal"
      fullWidth
      type="text"
      error={!!error}
      helperText={error}
      id={props.name}
      disabled={locked || disabled}
      label={label ? <Lockable locked={!!locked}>{label}</Lockable> : undefined}
      {...props}
    />
  </Tooltip>
);
