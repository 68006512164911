import { voronoi } from "@visx/voronoi";

interface HookArgs<T> {
  data: T[];
  xAccessor: (v: T) => number;
  yAccessor: (v: T) => number;
  chartWidth: number;
  chartHeight: number;
}

function useVoronoi<T>({
  data,
  xAccessor,
  yAccessor,
  chartWidth,
  chartHeight,
}: HookArgs<T>) {
  const voronoiLayout = voronoi<T>({
    x: xAccessor,
    y: yAccessor,
    width: chartWidth,
    height: chartHeight,
  });

  const voronoiDiagram = voronoiLayout(data);
  const voronoiPolygons = voronoiDiagram.polygons();

  return { voronoiPolygons, voronoiDiagram };
}

export default useVoronoi;
