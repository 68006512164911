import { Alert } from "@material-ui/lab";
import React, { FC } from "react";
import { BuildingModelFit } from "../api/graphql";

interface Props {
  fit: BuildingModelFit;
}

const FitnessAlert: FC<Props> = ({ fit }) => {
  return (
    <>
      {fit === BuildingModelFit.POOR && (
        <Alert severity="warning">
          Building model is not a good fit. Check data.
        </Alert>
      )}
      {fit === BuildingModelFit.MODERATE && (
        <Alert severity="info">
          Building model is a moderate fit. Some variance expected.
        </Alert>
      )}
    </>
  );
};

export default FitnessAlert;
