import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import fourOhFour from "../assets/404.svg";
import wave from "../assets/wave.svg";
import Button from "./Button";

const useStyles = makeStyles(() => ({
  imageSize: {
    width: "100%",
    maxWidth: 700,
  },
  bg: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

type Props = {
  onClick: () => void;
  buttonText: string;
};

const NotFound: React.FC<Props> = ({ onClick, buttonText }) => {
  const { imageSize, bg } = useStyles();

  return (
    <>
      <img src={wave} alt="wave" className={bg} />
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={fourOhFour} alt="404" className={imageSize} />
        <Box pb={3}>
          <Typography variant="h4" align="center" color="primary">
            <b>Page not found</b>
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            <em>"Not all who wander are lost"</em>
          </Typography>
        </Box>
        <Button primary onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </>
  );
};

export default NotFound;
