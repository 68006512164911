import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  buildingCalibratedModel,
  calibratedModels,
  e404,
} from "../lib/endpoints";
import isReadOnly from "../lib/isReadOnly";
import modelName from "../lib/modelName";
import useDeleteEntity from "../lib/useDeleteEntity";
import { useSessionWithUser } from "../SessionWithUser";
import BackButton from "../ui/BackButton";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import { useToastContext } from "../ui/ToastProvider";
import { SectionTitle } from "../ui/Typography";
import AdjustedModelCard from "./AdjustedModelCard";
import { CalibratedModelDocument } from "./CalibratedModel.generated";
import CalibratedModelDetailCard from "./CalibratedModelDetailCard";
import CalibratedModelDetailFragment from "./CalibratedModelDetailFragment";
import { CreateBuildingAdjustedModelDocument } from "./CreateBuildingAdjustedModel.generated";
import { DeleteBuildingCalibratedModelDocument } from "./DeleteBuildingCalibratedModel.generated";
import EditModelDetailsDialog from "./EditModelDetailsDialog";
import NewAdjustedModelDialog from "./NewAdjustedModelDialog";
import NewCalibratedModelDialog from "./NewCalibratedModelDialog";
import { ReviseBuildingCalibratedModelDocument } from "./ReviseBuildingCalibratedModel.generated";
import { UpdateBuildingCalibratedModelDocument } from "./UpdateBuildingCalibratedModel.generated";

interface Props {
  calibratedModelId: string;
}

const CalibratedModel: React.FC<Props> = ({ calibratedModelId }) => {
  const { user } = useSessionWithUser();
  const { data, loading, error, refetch } = useQuery(CalibratedModelDocument, {
    variables: { id: calibratedModelId },
  });
  const [editing, showEditing] = useState<boolean>(false);
  const [revising, showRevising] = useState<boolean>(false);
  const [creatingAdjustment, showCreatingAdjustment] = useState<boolean>(false);
  const [update, { error: updateError }] = useMutation(
    UpdateBuildingCalibratedModelDocument
  );
  const createToast = useToastContext();
  const history = useHistory();
  const [reviseCalibrated, { error: reviseCalibratedError }] = useMutation(
    ReviseBuildingCalibratedModelDocument
  );
  const [createAdjustment, { error: createAdjustmentError }] = useMutation(
    CreateBuildingAdjustedModelDocument
  );
  const [deleteModel] = useDeleteEntity(DeleteBuildingCalibratedModelDocument);

  const onDeleteAdjusted = () => refetch();

  const handleDeleteCalibrated = async () => {
    try {
      await deleteModel(
        { __typename: "BuildingCalibratedModel", id: calibratedModelId },
        { input: { buildingCalibratedModelId: calibratedModel.id } }
      );
      createToast("Model successfully deleted", "success");
      history.push(calibratedModels({ buildingId: building.id }));
    } catch (e) {
      createToast(e.message, "error");
    }
  };

  if (error) throw error;
  if (loading) return <Loading variant="circle" />;

  if (!data) {
    return <Redirect to={e404()} />;
  }

  const { calibratedModel } = data;
  const { building } = calibratedModel;
  const adjustedModels = [...calibratedModel.adjustedModels].sort((m1, m2) =>
    m1.createdAt > m2.createdAt ? -1 : 1
  );

  return (
    <>
      <BackButton
        to={calibratedModels({ buildingId: building.id })}
        label="Back to Models"
      />
      <CalibratedModelDetailCard
        squareFeet={building.squareFeet}
        model={calibratedModel}
        onDelete={handleDeleteCalibrated}
        onRevise={() => showRevising(true)}
        onEdit={() => showEditing(true)}
      >
        <CalibratedModelDetailFragment
          overrides={
            calibratedModel.system
              ? calibratedModel.buildingModel.parameters
              : calibratedModel.overrides!
          }
          squareFeet={building.squareFeet}
        />
      </CalibratedModelDetailCard>
      <Box
        display="flex"
        width={1}
        justifyContent="space-between"
        alignItems="center"
        pb={1}
        pt={2}
      >
        <SectionTitle>
          Related Scenarios
          <Typography variant="body2" color="textSecondary">
            Adjustments, projections, and hypotheticals.
          </Typography>
        </SectionTitle>
        {!isReadOnly(calibratedModel.building.access) && (
          <div>
            <Button
              startIcon={<AddIcon />}
              size="small"
              primary
              onClick={() => showCreatingAdjustment(true)}
            >
              Create scenario
            </Button>
          </div>
        )}
      </Box>

      <Grid container spacing={2}>
        {adjustedModels.map((am) => (
          <Grid item xs={12} md={12} key={`${am.id}`}>
            <AdjustedModelCard
              buildingId={building.id}
              calibratedModelId={calibratedModelId}
              model={am}
              onDelete={onDeleteAdjusted}
            />
          </Grid>
        ))}
      </Grid>
      {editing && (
        <EditModelDetailsDialog
          name={calibratedModel.name || ""}
          description={calibratedModel.description || ""}
          notes={calibratedModel.notes || ""}
          onSubmit={async ({ name, description, notes }) => {
            await update({
              variables: {
                input: {
                  buildingCalibratedModelId: calibratedModel.id,
                  name,
                  description,
                  notes,
                },
              },
            });
            showEditing(false);
            createToast("Calibrated model details edited", "success");
          }}
          onCancel={() => showEditing(false)}
          error={updateError?.graphQLErrors[0]}
        />
      )}
      {creatingAdjustment && (
        <NewAdjustedModelDialog
          onSubmit={async ({
            modelName,
            modelDescription,
            modelNotes,
            adjustments,
          }) => {
            await createAdjustment({
              variables: {
                input: {
                  buildingCalibratedModelId: calibratedModelId,
                  name: modelName,
                  description: modelDescription,
                  notes: modelNotes,
                  adjustments,
                },
              },
            });
            showCreatingAdjustment(false);
            refetch();
            createToast("New scenario was created", "success");
          }}
          onCancel={() => showCreatingAdjustment(false)}
          calibratedModelName={modelName(calibratedModel)}
          calibratedParameters={calibratedModel.buildingModel.parameters}
          error={createAdjustmentError?.graphQLErrors[0]}
        />
      )}
      {revising && (
        <NewCalibratedModelDialog
          buildingSquareFeet={building.squareFeet}
          calibratedModelName={modelName(calibratedModel)}
          parameters={calibratedModel.buildingModel.parameters}
          initialOverrides={calibratedModel.overrides}
          onSubmit={async ({ name, description, notes, overrides }) => {
            const { data } = await reviseCalibrated({
              variables: {
                input: {
                  calibratedModelId: calibratedModel.id,
                  name,
                  description,
                  notes,
                  overrides,
                },
              },
            });
            const newModelId = data?.reviseBuildingCalibratedModel
              .buildingCalibratedModel.id as string;
            showRevising(false);
            createToast("Revised calibrated model has been created", "success");
            history.push(
              buildingCalibratedModel({
                buildingId: building.id,
                calibratedModelId: newModelId,
              })
            );
          }}
          onCancel={() => showRevising(false)}
          error={reviseCalibratedError?.graphQLErrors[0]}
          superuser={user.superuser}
        />
      )}
    </>
  );
};

export default CalibratedModel;
