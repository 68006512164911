import { useMemo } from "react";
import { UseFormMethods } from "react-hook-form";
import { WeatherSource } from "../api/graphql";
import Energy from "../lib/Energy";
import sum from "../lib/sum";
import { calculateIRR, calculateNPV } from "./finance";
import {
  CalibratedModelData,
  FormSections,
  ScenarioModelData,
} from "./FinancialModelDashboard";
import {
  calculateRevenue,
  createCashFlows,
  createEnergySourceConfigs,
} from "./helpers";

interface ModelData {
  calibratedModelData: CalibratedModelData;
  adjustedModelActualWeatherData: ScenarioModelData[];
  adjustedModelNormalWeatherData: ScenarioModelData[];
}

const useTrackFormCalculations = (
  { watch }: UseFormMethods<FormSections>,
  {
    calibratedModelData,
    adjustedModelActualWeatherData,
    adjustedModelNormalWeatherData,
  }: ModelData
) => {
  const scenarioAndRates = watch("scenarioAndRates");
  const projectData = watch("projectData");
  const expenses = watch("expenses");

  const pickedAdjustedModelWeather: ScenarioModelData[] =
    scenarioAndRates.weatherSource === WeatherSource.ACTUAL
      ? adjustedModelActualWeatherData
      : adjustedModelNormalWeatherData;

  const selectedScenario = scenarioAndRates.usingCustomScenario
    ? scenarioAndRates.customScenario
    : pickedAdjustedModelWeather.find(
        (m) => m.id === scenarioAndRates.selectedModelId
      )!;
  const selectedRates = scenarioAndRates.usingCustomRates
    ? scenarioAndRates.customRates
    : scenarioAndRates.defaultRates;

  const energySourceConfigs = createEnergySourceConfigs(
    calibratedModelData.sources.map((s) => ({
      energySource: s.energySource,
      baseline: new Energy({ quantity: s.baseline, unit: s.unit }),
    })),
    selectedScenario.sources,
    selectedRates
  );

  const annualCashFlows = useMemo(
    () =>
      createCashFlows(
        energySourceConfigs,
        projectData.term,
        projectData.projectUncertainty,
        expenses,
        projectData.discountRate
      ),
    [energySourceConfigs, projectData, expenses]
  );

  const nominalAnnualCashflows = useMemo(
    () =>
      createCashFlows(
        energySourceConfigs,
        projectData.term,
        projectData.projectUncertainty,
        expenses,
        0 // Discount rate of 0 for nominal
      ),
    [energySourceConfigs, projectData, expenses]
  );

  const averageNominalAnnualCashFlows = nominalAnnualCashflows.map(
    (d) => d.average
  );

  const IRR = useMemo(
    () =>
      calculateIRR(
        projectData.projectCost.value,
        averageNominalAnnualCashFlows
      ),
    [averageNominalAnnualCashFlows, projectData]
  );

  const eeValuePerSource = energySourceConfigs.map((config) => ({
    energySource: config.source,
    value: calculateRevenue(config, projectData.term),
  }));

  const NPV = Math.floor(
    calculateNPV(
      projectData.projectCost.value,
      projectData.discountRate,
      averageNominalAnnualCashFlows
    )
  );

  const averageNominalAnnualCashFlow = projectData.term
    ? sum(averageNominalAnnualCashFlows) / projectData.term
    : 0;

  return {
    IRR,
    NPV,
    annualCashFlows,
    averageNominalAnnualCashFlow,
    eeValuePerSource,
    energySourceConfigs,
  };
};

export default useTrackFormCalculations;
