import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  makeStyles,
} from "@material-ui/core";
import ClearFiltersIcon from "@material-ui/icons/Autorenew";
import queryString from "query-string";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Building } from ".";
import { appBarHeight } from "../ApplicationLayout";
import { applicationNavigationWidth } from "../ApplicationNavigation";
import { buildingsList } from "../lib/endpoints";
import { useSessionWithUser } from "../SessionWithUser";
import FillHeight from "../ui/FillHeight";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import { createDefaultFormOptions } from "./defaultFormOptionsReducer";
import ExportBuildingsButton from "./ExportBuildingsButton";
import FilterForm from "./FilterForm";
import { filterBuildings, Filters } from "./filterReducer";
import ListMetrics from "./ListMetrics";
import ProspectingList from "./ProspectingList";
import createSortComparator from "./sortReducer";
import useToggleFilterDrawer from "./useToggleFilterDrawer";

/**
 * Shallow diffs two objects based on their JSON.stringified values
 */
export function diffFilters<T>(original: T, compare: T): Partial<T> {
  const compareCopy = { ...compare };
  let key: keyof T;

  for (key in original) {
    if (JSON.stringify(compare[key]) === JSON.stringify(original[key])) {
      delete compareCopy[key];
    }
  }

  return compareCopy;
}

export function toQueryString(filters: Partial<Filters>): string {
  return queryString.stringify(filters, {
    arrayFormat: "index",
  });
}

type Props = {
  buildings: Building[];
  onImport: () => void;
  onAddBuilding: () => void;
};

const filterDrawerWidth = 275;

const useStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    [breakpoints.up("md")]: {
      left: applicationNavigationWidth,
    },
    [breakpoints.down("sm")]: {
      top: appBarHeight,
    },
    backgroundColor: "transparent",
    border: "none",
    width: filterDrawerWidth,
    marginTop: appBarHeight,
    height: "calc(100vh - 48px)",
  },
  drawerOpen: {
    marginLeft: filterDrawerWidth,
    width: "100%",
  },
  drawerClosed: {
    marginLeft: 0,
    width: "100%",
  },
}));

const ProspectedBuildingsList: FC<Props> = ({
  buildings,
  onAddBuilding,
  onImport,
}) => {
  const history = useHistory();
  const routerLocation = useLocation();
  const { paper, ...classes } = useStyles();
  const [drawerOpen, toggleDrawer] = useToggleFilterDrawer();
  const { isOrgAdmin, isSuperuser } = useSessionWithUser();

  const defaultFormOptions = useMemo(() => {
    return createDefaultFormOptions(buildings);
  }, [buildings]);

  // Set all array values to empty initially
  const defaultFilterValues = useMemo(() => {
    return {
      ...defaultFormOptions,
      location: [],
      modelFit: [],
      majorTenant: [],
      buildingUseType: [],
      leaseType: [],
      energySources: [],
    };
  }, [defaultFormOptions]);

  // Retrieve filters from queryParams, if they exist
  const parsedFilters: Partial<Filters> = queryString.parse(
    routerLocation.search,
    {
      arrayFormat: "index",
      parseNumbers: true,
      parseBooleans: true,
    }
  ) as Partial<Filters>;

  // Merge defaults garnered from buildings with queryParam filters
  const mergedDefaults: Filters = {
    ...defaultFilterValues,
    ...parsedFilters,
    buildingName: parsedFilters.buildingName
      ? parsedFilters.buildingName.toString()
      : "", // Override parseNumbers: true if user searches for numerical title
  };

  const [filters, setFilters] = useState<Filters>(mergedDefaults);

  // Set buildings with the initial merged filters
  const [filteredBuildings, setFilteredBuildings] = useState<Building[]>(
    filterBuildings(buildings, mergedDefaults).sort(
      createSortComparator(mergedDefaults.sortBy)
    )
  );

  useEffect(() => {
    const diff = diffFilters(defaultFilterValues, filters);

    history.push({
      pathname: buildingsList(),
      search: toQueryString(diff),
    });

    setFilteredBuildings(
      filterBuildings(buildings, filters).sort(
        createSortComparator(filters.sortBy)
      )
    );
  }, [defaultFilterValues, filters, buildings, history]);

  return (
    <Box display="flex">
      <FillHeight>
        <Drawer
          open={drawerOpen}
          variant="persistent"
          transitionDuration={0}
          classes={{ paper }}
        >
          <Box pt={2}>
            <FilterForm
              filters={filters}
              defaultFormOptions={defaultFormOptions}
              onFilterChange={setFilters}
            />
          </Box>
        </Drawer>
      </FillHeight>
      <div className={drawerOpen ? classes.drawerOpen : classes.drawerClosed}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Button
              disableRipple
              size="small"
              color="primary"
              onClick={() => toggleDrawer()}
            >
              {drawerOpen ? "Hide" : "Show"} filters
            </Button>
            <Button
              disableRipple
              size="small"
              color="primary"
              onClick={() => setFilters(defaultFilterValues)}
            >
              <ClearFiltersIcon fontSize="small" />
              Clear filters
            </Button>
          </Box>
          <ButtonGroup color="primary" size="small">
            {isSuperuser && (
              <ExportBuildingsButton buildings={filteredBuildings} />
            )}
            {isSuperuser && <Button onClick={onImport}>Import</Button>}
            {isOrgAdmin && (
              <Button onClick={onAddBuilding}>Add building</Button>
            )}
          </ButtonGroup>
        </Box>
        {filteredBuildings.length > 0 ? (
          <>
            <Box pt={2}>
              <ListMetrics buildings={filteredBuildings} />
            </Box>
            <FillHeight>
              <ProspectingList buildings={filteredBuildings} />
            </FillHeight>
          </>
        ) : (
          <Box pt={2}>
            <PlaceholderContainer>
              <PlaceholderSegment
                header="No buildings found"
                buttonText="Clear filters"
                onButtonClick={() => setFilters(defaultFilterValues)}
              />
            </PlaceholderContainer>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default ProspectedBuildingsList;
