import MUIDataTable, { MUIDataTableProps } from "mui-datatables";
import React from "react";
import colors from "./colors";

type Props = Omit<MUIDataTableProps, "tbd" | "title">;

export const MUIDataTableOverrides = {
  MUIDataTableToolbar: {
    root: {
      color: colors.white,
      backgroundColor: colors.blue.main,
      minHeight: 40,
    },
    titleText: {
      fontSize: "inherit",
    },
  },
  MUIDataTableHeadCell: {
    root: {
      color: colors.black,
      borderTop: `5px solid ${colors.blue.main}`,
    },
  },
  MuiTableSortLabel: {
    icon: {
      "& path": {
        fill: colors.blue.main,
      },
    },
  },
  MUIDataTableBodyRow: {
    root: {
      "&:nth-child(odd)": {
        backgroundColor: colors.gray.xxLight,
      },
      "& > td": {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
  MUIDataTable: {
    paper: {
      border: `1px solid ${colors.gray.xLight}`,
    },
  },
  MuiTableFooter: {
    root: {
      backgroundColor: colors.gray.xxLight,
      "& td": {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
};

const DataTable: React.FC<Props> = ({ options, ...rest }) => (
  <MUIDataTable
    options={{
      responsive: "simple",
      download: false,
      filter: false,
      print: false,
      viewColumns: false,
      search: false,
      selectableRowsHeader: false,
      selectableRows: "none",
      elevation: 0,
      rowHover: false,
      ...options,
    }}
    title=""
    {...rest}
  />
);

export default DataTable;
