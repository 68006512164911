import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import removeEmpty from "../lib/removeEmpty";
import { FieldErrors, lengthError, presenceError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import { TextField } from "../ui/TextField";
import { CreateOrganizationDocument } from "./CreateOrganization.generated";

interface Props {
  onCancel: () => void;
  onComplete: () => void;
}

interface Fields {
  name: string;
}

const validations = (values: Fields): FieldErrors<Fields> =>
  removeEmpty({
    name:
      presenceError(values.name) ||
      lengthError(values.name, { min: 3, max: 100 }),
  });

const AddOrganizationDialog: React.FC<Props> = ({ onCancel, onComplete }) => {
  const [createOrganization, { error }] = useMutation(
    CreateOrganizationDocument
  );
  const {
    errors,
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm<Fields>({
    defaultValues: { name: "" },
    resolver: validatorsResolver(validations),
  });

  const doCreateOrg = async ({ name }: Fields) => {
    await createOrganization({
      variables: { input: { name } },
    });
    onComplete();
  };

  return (
    <FormDialog
      title="Add Organization"
      error={error}
      onSubmit={handleSubmit(doCreateOrg)}
      onCancel={onCancel}
      isSubmitting={isSubmitting}
    >
      <TextField
        inputRef={register}
        name="name"
        type="text"
        label="Organization Name"
        required
        autoComplete="off"
        error={errors.name?.message}
      />
    </FormDialog>
  );
};

export default AddOrganizationDialog;
