import {
  Box,
  Divider as MuiDivider,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import { GraphQLError } from "graphql";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import modelName from "../lib/modelName";
import { dateError, presenceError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import { DatePickerController } from "../ui/DatePicker";
import FormDialog from "../ui/FormDialog";
import FormSection from "../ui/FormSection";
import { SelectFieldController, SelectItem } from "../ui/SelectField";

export interface Props {
  onSubmit: (v: Fields) => Promise<void>;
  error?: GraphQLError;
  onCancel: () => void;
  buildingList: {
    id: string;
    name: string;
    calibratedModels: {
      id: string;
      name: string | null;
      adjustedModels: { id: string; name: string | null }[];
    }[];
  }[];
}

interface Fields {
  buildingId: string;
  initialModelId: string;
  comparisonModelId: string;
  startOn: Date;
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    minWidth: "150px",
  },
}));

const Divider = () => (
  <Box py={2}>
    <MuiDivider orientation="horizontal" />
  </Box>
);

const validations = ({ initialModelId, buildingId, startOn }: Fields) => ({
  buildingId: presenceError(buildingId),
  initialModelId: presenceError(initialModelId),
  startOn: dateError(startOn),
});

const NewDeltaMeterDialog: React.FC<Props> = ({
  onSubmit,
  onCancel,
  buildingList,
  error,
}) => {
  const { formControl } = useStyles();
  const defaultValues: Fields = {
    buildingId: "",
    initialModelId: "",
    comparisonModelId: "",
    startOn: new Date(),
  };
  const {
    watch,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
    setValue,
  } = useForm<Fields>({
    defaultValues,
    resolver: validatorsResolver(validations),
  });

  const buildingId = watch("buildingId");
  useEffect(() => {
    setValue("initialModelId", "");
  }, [setValue, buildingId]);

  const initialModelId = watch("initialModelId");
  useEffect(() => {
    setValue("comparisonModelId", "");
  }, [setValue, initialModelId]);

  const building = buildingList.find((b) => b.id === buildingId);
  const calibratedModel = building?.calibratedModels.find(
    (cm) => cm.id === initialModelId
  );

  return (
    <FormDialog
      title="Request a DeltaMeter"
      submitName="Request"
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      error={error}
      isSubmitting={isSubmitting}
    >
      <FormSection label="Building selection">
        <SelectFieldController
          label="Select a building"
          name="buildingId"
          control={control}
          error={errors.buildingId?.message}
        >
          {buildingList.map((b) => (
            <SelectItem key={`bldg-${b.id}`} value={b.id}>
              {b.name}
            </SelectItem>
          ))}
        </SelectFieldController>
      </FormSection>
      <Divider />
      <FormSection label="Model selection">
        <SelectFieldController
          label="Baseline model"
          name="initialModelId"
          control={control}
          error={errors.initialModelId?.message}
          disabled={!buildingId}
        >
          {building?.calibratedModels.map((cm) => (
            <SelectItem key={`bldg-${cm.id}`} value={cm.id}>
              {modelName({
                __typename: "BuildingCalibratedModel",
                name: cm.name,
              })}
            </SelectItem>
          ))}
        </SelectFieldController>

        <SelectFieldController
          label="Projected model (optional)"
          name="comparisonModelId"
          control={control}
          error={errors.comparisonModelId?.message}
          disabled={!initialModelId}
        >
          {calibratedModel?.adjustedModels.map((am) => (
            <SelectItem key={`bldg-${am.id}`} value={am.id}>
              {modelName({
                __typename: "BuildingAdjustedModel",
                name: am.name,
              })}
            </SelectItem>
          ))}
        </SelectFieldController>
      </FormSection>
      <Divider />
      <FormSection label="Performance Period">
        <FormControl className={formControl}>
          <DatePickerController
            label="Select a start date"
            name="startOn"
            control={control}
            error={errors.startOn?.message}
          />
        </FormControl>
      </FormSection>
    </FormDialog>
  );
};

export default NewDeltaMeterDialog;
