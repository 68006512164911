import { makeStyles } from "@material-ui/core";
import queryString from "query-string";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import AuthenticationLayout from "./AuthenticationLayout";
import { useAuthN, useAuthNErrors } from "./lib/AuthN";
import removeEmpty from "./lib/removeEmpty";
import ServerErrors from "./lib/ServerErrors";
import { FieldErrors, matchError, presenceError } from "./lib/validators";
import validatorsResolver from "./lib/validatorsResolver";
import Button from "./ui/Button";
import { TextField } from "./ui/TextField";

interface Props {}

interface Fields {
  password: string;
  passwordConfirmation: string;
}

const validations = (values: Fields): FieldErrors<Fields> =>
  removeEmpty({
    password: presenceError(values.password),
    passwordConfirmation: matchError(
      values.password,
      values.passwordConfirmation
    ),
  });

const Messages = {
  password_MISSING: "Password is required.",
  password_INSECURE: "Password is weak.",
  account_LOCKED: "That account is no longer active.",
  token_INVALID_OR_EXPIRED: "The URL has expired. Please start over.",
  default: "Something went wrong.",
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword: React.FC<Props> = () => {
  const history = useHistory();
  const { resetPassword } = useAuthN();
  const [authNErrors, catchErrors] = useAuthNErrors();
  const {
    errors: formErrors,
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm<Fields>({
    defaultValues: { password: "", passwordConfirmation: "" },
    resolver: validatorsResolver(validations),
  });

  const doReset = async (fields: Fields) => {
    const qs = queryString.parse(history.location.search);
    catchErrors(async () => {
      await resetPassword({
        password: fields.password,
        token: (qs.token || "").toString(),
      });
      history.push("/");
    });
  };

  const classes = useStyles();
  return (
    <AuthenticationLayout title="Reset Your Password">
      <form noValidate onSubmit={handleSubmit(doReset)}>
        <ServerErrors errors={authNErrors} messages={Messages} />
        <TextField
          inputRef={register}
          name="password"
          type="password"
          label="New Password"
          required
          autoComplete="new-password"
          error={formErrors.password?.message}
        />

        <TextField
          inputRef={register}
          name="passwordConfirmation"
          type="password"
          label="Confirm New Password"
          required
          autoComplete="new-password"
          error={formErrors.passwordConfirmation?.message}
        />

        <Button
          type="submit"
          disabled={isSubmitting}
          fullWidth
          primary
          className={classes.submit}
        >
          Update Password
        </Button>
      </form>
    </AuthenticationLayout>
  );
};

export default ResetPassword;
