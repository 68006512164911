import { Button, TextField, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { CardContent, CardHeader } from "../ui/Card";
import Gaps from "../ui/Gaps";
import StripeCard from "../ui/StripeCard";
import { FormSections } from "./FinancialModelDashboard";

interface Props {
  onSubmit: (fields: FormSections) => Promise<void>;
}

const ProjectMetadataForm: FC<Props> = ({ onSubmit }) => {
  const { register, errors, handleSubmit } = useFormContext<FormSections>();

  return (
    <StripeCard>
      <CardHeader
        title={
          <Typography variant="button" color="primary">
            Financial model info
          </Typography>
        }
      />
      <CardContent>
        <Gaps spacing={2}>
          <TextField
            fullWidth
            inputRef={register({
              required: {
                value: true,
                message: "Required",
              },
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum length is 50 characters",
              },
            })}
            name="nameAndNotes.name"
            size="small"
            label="Model name"
            error={!!errors.nameAndNotes}
            helperText={errors.nameAndNotes?.name?.message}
          />
          <TextField
            fullWidth
            inputRef={register}
            name="nameAndNotes.notes"
            label="Notes"
            multiline
            variant="outlined"
            rowsMax={4}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          >
            Save changes
          </Button>
        </Gaps>
      </CardContent>
    </StripeCard>
  );
};

export default ProjectMetadataForm;
