import { useQuery } from "@apollo/client";
import { Divider, List } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import React, { FC, Fragment } from "react";
import { useHistory } from "react-router";
import DismissDialog from "../DocumentCenter/DismissDialog";
import { PreviewFile } from "../DocumentCenter/FilenamePreview";
import { useOpenDocument } from "../DocumentCenter/useOpenDocument";
import clampFileName from "../lib/clampFileName";
import { documentCenter } from "../lib/endpoints";
import { millifyFilesize } from "../lib/filesize";
import pluckNodes from "../lib/pluckNodes";
import sortChronological from "../lib/sortChronological";
import { recentlyViewedDocuments } from "../lib/useRecentlyViewedDocuments";
import { useSessionWithUser } from "../SessionWithUser";
import { SkeletonListItem, SkeletonTypography } from "../ui/Bones";
import Button from "../ui/Button";
import DashboardCard from "../ui/DashboardCard";
import MetaData from "../ui/MetaData";
import PlaceholderSegment from "../ui/PlaceholderSegment";
import RecentItemTile from "./RecentItemTile";
import { RecentlyAddedDocumentsDocument } from "./RecentlyAddedDocuments.generated";
import { RecentlyViewedDocumentsDocument } from "./RecentlyViewedDocuments.generated";

interface Props {}

const AllDocumentsButton: FC = () => {
  const history = useHistory();

  return (
    <Button size="small" onClick={() => history.push(documentCenter())}>
      View documents
    </Button>
  );
};

const RecentDocumentsSkeleton: FC = () => (
  <DashboardCard label={<SkeletonTypography size="medium" />}>
    <List>
      <SkeletonListItem />
      <Divider />
      <SkeletonListItem />
      <Divider />
      <SkeletonListItem />
    </List>
  </DashboardCard>
);

const RecentlyViewedDocuments: FC<{
  documentIds: string[];
}> = ({ documentIds }) => {
  const { organization } = useSessionWithUser();

  const { data, loading, error } = useQuery(RecentlyViewedDocumentsDocument, {
    variables: { documentIds, organizationId: organization.id },
  });
  const { open, closeDialog, data: dialogData, fullscreen } = useOpenDocument();

  if (error) throw error;
  if (loading || !data) return <RecentDocumentsSkeleton />;

  const documents = pluckNodes(data.organization.documents).slice(0, 3);

  return (
    <DashboardCard
      label="Recently viewed documents"
      action={<AllDocumentsButton />}
    >
      <List>
        {documents.map((d, idx) => (
          <Fragment key={`recent-docs-${d.id}`}>
            {dialogData && dialogData.organizationDocument.id === d.id && (
              <DismissDialog
                fullScreen={fullscreen}
                maxWidth="md"
                title={clampFileName(
                  dialogData.organizationDocument.file.filename
                )}
                onDismiss={closeDialog}
              >
                <PreviewFile orgDocument={dialogData.organizationDocument} />
              </DismissDialog>
            )}
            <RecentItemTile
              title={clampFileName(d.file.filename)}
              icon={<InsertDriveFileIcon />}
              onClick={() => open(d)}
              metadata={
                <MetaData>
                  <span>{millifyFilesize(d.file.size)}</span>
                  <span>Uploaded by {d.createdBy.name}</span>
                </MetaData>
              }
            />
            {idx !== documents.length - 1 && <Divider />}
          </Fragment>
        ))}
      </List>
    </DashboardCard>
  );
};

const RecentlyAddedDocuments: FC = () => {
  const { organization } = useSessionWithUser();
  const { data, loading, error } = useQuery(RecentlyAddedDocumentsDocument, {
    fetchPolicy: "cache-first",
    variables: { organizationId: organization.id },
  });
  const { open, closeDialog, data: dialogData, fullscreen } = useOpenDocument();

  if (error) throw error;
  if (loading || !data) {
    return <RecentDocumentsSkeleton />;
  }

  const documents = pluckNodes(data.organization.documents)
    .sort((a, b) => sortChronological(a.createdAt, b.createdAt, "desc"))
    .slice(0, 3);

  if (documents.length === 0) {
    return (
      <DashboardCard
        label="Recently added documents"
        action={<AllDocumentsButton />}
      >
        <PlaceholderSegment
          icon={InsertDriveFileIcon}
          header="No documents"
          subheader="Documents haven't been uploaded yet"
        />
      </DashboardCard>
    );
  }

  return (
    <DashboardCard
      label="Recently added documents"
      action={<AllDocumentsButton />}
    >
      <List>
        {documents.map((d, idx) => (
          <Fragment key={`recent-docs-${d.id}`}>
            {dialogData && dialogData.organizationDocument.id === d.id && (
              <DismissDialog
                fullScreen={fullscreen}
                maxWidth="md"
                title={clampFileName(
                  dialogData.organizationDocument.file.filename
                )}
                onDismiss={closeDialog}
              >
                <PreviewFile orgDocument={dialogData.organizationDocument} />
              </DismissDialog>
            )}
            <RecentItemTile
              title={clampFileName(d.file.filename)}
              icon={<InsertDriveFileIcon />}
              onClick={() => open(d)}
              metadata={
                <MetaData>
                  <span>{millifyFilesize(d.file.size)}</span>
                  <span>Uploaded by {d.createdBy.name}</span>
                </MetaData>
              }
            />
            {idx !== documents.length - 1 && <Divider />}
          </Fragment>
        ))}
      </List>
    </DashboardCard>
  );
};

const RecentDocuments: FC<Props> = () => {
  const { organization } = useSessionWithUser();
  const recentDocuments = recentlyViewedDocuments(organization.id);

  return (
    <>
      {recentDocuments.length === 0 ? (
        <RecentlyAddedDocuments />
      ) : (
        <RecentlyViewedDocuments documentIds={recentDocuments} />
      )}
    </>
  );
};

export default RecentDocuments;
