import { makeStyles, Typography, TypographyProps } from "@material-ui/core";
import React, { FC } from "react";

type HeadingProps = Omit<TypographyProps, "variant" | "component">;

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const HeaderTitle: FC<HeadingProps> = ({ children, ...props }) => (
  <Typography {...props} variant="h5">
    {children}
  </Typography>
);

export const KeyMetricSmall: FC<HeadingProps> = ({ children, ...props }) => (
  <Typography {...props} variant="h6">
    {children}
  </Typography>
);

export const KeyMetricLarge: FC<HeadingProps> = ({ children, ...props }) => (
  <Typography {...props} variant="h5">
    {children}
  </Typography>
);

export const PageTitle: FC<HeadingProps> = ({ children, ...props }) => (
  <Typography {...props} variant="h6">
    {children}
  </Typography>
);

export const SectionTitle: FC<HeadingProps> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Typography {...props} variant="subtitle1" className={classes.sectionTitle}>
      {children}
    </Typography>
  );
};
