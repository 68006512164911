import { Grid, styled, Typography } from "@material-ui/core";
import React, { FC } from "react";

interface Props {
  label: React.ReactNode;
}

const Centered = styled(Grid)({
  display: "inline-flex",
  alignItems: "center",
});

const LineItem: FC<Props> = ({ label, children }) => (
  <Grid container justify="space-between">
    <Centered item>
      <Typography>{label}</Typography>
    </Centered>
    <Centered item>{children}</Centered>
  </Grid>
);

export default LineItem;
