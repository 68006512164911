import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { FC } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { InvoiceModelFragment } from "../api/fragments/InvoiceModel.generated";
import { InvoiceStatus } from "../api/graphql";
import { EntityStatusChip } from "../ui/EntityStatusChip";
import StripeCard from "../ui/StripeCard";
import { InvoiceDialogButton } from "./InvoiceDialog";

export interface Props {
  invoice: InvoiceModelFragment;
  timeZone: string;
  title?: string;
}

export const StatusChip: FC<{ status: InvoiceStatus }> = ({ status }) => {
  const { palette } = useTheme();

  const statusColors: Record<InvoiceStatus, string> = {
    OPEN: palette.warning.main,
    PAID: palette.primary.main,
    VOID: palette.grey[500],
  };

  return (
    <EntityStatusChip
      backgroundColor={statusColors[status]}
      size="small"
      label={status}
    />
  );
};

const InvoiceCard: FC<Props> = ({ invoice, timeZone, title = "Invoice" }) => {
  return (
    <StripeCard>
      <CardHeader
        title={
          <Typography variant="button" color="primary">
            {title}
          </Typography>
        }
        action={<StatusChip status={invoice.status} />}
      />
      <CardContent>
        <Typography variant="body2">
          Invoice number: {invoice.reference}
        </Typography>
        <Typography variant="body2">
          Date of issue:{" "}
          <FormattedDate value={invoice.issuedAt} timeZone={timeZone} />
        </Typography>
        <Typography variant="body2">
          Date due: <FormattedDate value={invoice.dueAt} timeZone={timeZone} />
        </Typography>
        <Typography variant="body2">
          <b>
            Subtotal:{" "}
            <FormattedNumber
              value={invoice.totalCost.value}
              style={`currency`}
              currency="USD"
            />
          </b>
        </Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="flex-end" width={1}>
          <InvoiceDialogButton invoice={invoice} timeZone={timeZone} />
        </Box>
      </CardActions>
    </StripeCard>
  );
};

export default InvoiceCard;
