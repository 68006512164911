import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeactivateOrganizationPermissionMutationVariables = Types.Exact<{
  input: Types.DeactivateOrganizationPermissionInput;
}>;


export type DeactivateOrganizationPermissionMutation = { deactivateOrganizationPermission: { __typename: 'DeactivateOrganizationPermissionResponse', organizationPermission: Types.Maybe<{ __typename: 'OrganizationPermission', role: Types.OrganizationRoleType }> } };


export const DeactivateOrganizationPermissionDocument: DocumentNode<DeactivateOrganizationPermissionMutation, DeactivateOrganizationPermissionMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeactivateOrganizationPermission"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeactivateOrganizationPermissionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deactivateOrganizationPermission"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationPermission"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}}]};