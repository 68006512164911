type Model = {
  __typename: "BuildingAdjustedModel" | "BuildingCalibratedModel";
  description?: string | null;
};

const CalibratedModelDescription =
  "Automatically calibrated from imported building data and available building configuration details.";
const AdjustedModelDescription =
  "EnergyRM calculates adjustments across a range of building parameters, providing a broad first look at energy efficiency opportunity.";

const modelDescription = (model: Model | null): string => {
  if (!model) return "";
  return (
    model.description ||
    (model.__typename === "BuildingAdjustedModel"
      ? AdjustedModelDescription
      : CalibratedModelDescription)
  );
};

export default modelDescription;
