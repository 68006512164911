import { Grid, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  label: string;
}

const FormSection: React.FC<Props> = ({ label, children }) => {
  return (
    <Grid container>
      <Grid item sm={4} xs={12}>
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item sm={8} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FormSection;
