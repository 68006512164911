import React from "react";
import {
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EnergySource } from "../api/graphql";
import createTicks from "../lib/createTicks";
import Energy from "../lib/Energy";
import { energySourceName } from "../lib/energySource";
import formatter from "../lib/formatter";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
} from "../lib/reporting/config";
import { Reference } from "../lib/types/Reports";
import unique from "../lib/unique";
import assignedEnergyColor from "./assignedEnergyColor";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  buildingSquareFeet: number;
  data: Reference[];
}

interface DataPoint {
  energySource: EnergySource;
  airTemp: number;
  reference: number;
  modeled: number;
}

const watts = (energyInPeriod: Energy, daysInPeriod: number): number =>
  energyInPeriod.wh / 24 / daysInPeriod;

export function formatReport(
  buildingSquareFeet: number,
  data: Reference[]
): DataPoint[] {
  return data
    .map(
      ({
        interval: { daysInPeriod },
        energySource,
        airTemp,
        billed,
        modeled,
      }) => ({
        energySource,
        airTemp,
        reference: watts(new Energy(billed), daysInPeriod) / buildingSquareFeet,
        modeled: watts(new Energy(modeled), daysInPeriod) / buildingSquareFeet,
      })
    )
    .sort((a, b) => a.airTemp - b.airTemp);
}

const EnergySignatureChart: React.FC<Props> = ({
  buildingSquareFeet,
  data,
  aspect,
}) => {
  const sources = unique(data.map((es) => es.energySource));
  const report = formatReport(buildingSquareFeet, data);
  const yMax = Math.ceil(
    Math.max(...report.map((p) => p.modeled), ...report.map((p) => p.reference))
  );

  return (
    <ResponsiveContainer aspect={aspect}>
      <ComposedChart data={report}>
        <XAxis
          type="number"
          dataKey="airTemp"
          interval="preserveStartEnd"
          domain={[
            (dataMin) => Math.floor(dataMin * 0.2) / 0.2,
            (dataMax) => Math.ceil(dataMax * 0.2) / 0.2,
          ]}
          ticks={createTicks(
            report[0].airTemp,
            report[report.length - 1].airTemp,
            { interval: 5 }
          )}
          tickLine={false}
          height={48}
          tick={{ fontSize }}
        >
          <Label
            value="Temperature (°F)"
            position="insideBottom"
            offset={10}
            fontSize={fontSize}
          />
        </XAxis>
        <YAxis
          domain={[0, (dataMax) => Math.ceil(dataMax)]}
          interval={0}
          allowDecimals={false}
          ticks={createTicks(0, yMax, { interval: 1 })}
          tickLine={false}
          width={30}
          tick={{ fontSize }}
        >
          <Label
            value="Watts / Ft²"
            position="insideLeft"
            angle={-90}
            offset={0}
            fontSize={fontSize}
          />
        </YAxis>
        <Legend height={40} verticalAlign="top" align="center" />
        <Tooltip
          isAnimationActive={tooltipAnimationActive}
          content={
            <CustomTooltip
              labelFormatting={(value) => `${formatter(value)} °F`}
              formatting={(value) => `${formatter(value)} Watts / Ft²`}
            />
          }
        />
        {assignedEnergyColor(sources).map(({ energySource, color }) => (
          <Line
            key={`${energySource}-modeled`}
            type="monotoneX"
            dataKey={(point) =>
              point.energySource === energySource ? point.modeled : null
            }
            connectNulls
            stroke={color}
            strokeWidth={3}
            dot={false}
            activeDot={false}
            isAnimationActive={chartAnimationActive}
            name={`Modeled ${energySourceName(energySource)}`}
          />
        ))}
        {assignedEnergyColor(sources).map(({ energySource, color }) => (
          <Scatter
            key={`${energySource}-reference`}
            shape="triangle"
            legendType="triangle"
            fill={color}
            dataKey={(point) =>
              point.energySource === energySource ? point.reference : null
            }
            isAnimationActive={chartAnimationActive}
            name={`Billed ${energySourceName(energySource)}`}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default EnergySignatureChart;
