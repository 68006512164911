import { useQuery } from "@apollo/client";
import React, { FC, useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import useHasModels from "../Building/useHasModels";
import BuildingDashboard from "../BuildingDashboard/BuildingDashboard";
import BuildingDocumentCenter from "../BuildingDocumentCenter/BuildingDocumentCenter";
import BuildingEdit from "../BuildingEdit";
import BuildingInsights from "../BuildingInsights";
import CalibratedModel from "../CalibratedModel";
import CalibratedModelReference from "../CalibratedModelReference/CalibratedModel";
import CalibratedModelsReferenceList from "../CalibratedModelsReferenceList/CalibratedModelsReferenceList";
import DeltaMeterDashboard from "../DeltaMeter";
import DeltaMeterReading from "../DeltaMeterReading";
import {
  FinancialModelContainer,
  NewFinancialModelContainer,
} from "../FinancialModel";
import InvestmentList from "../FinancialModelsList";
import assertFeature from "../lib/assertFeature";
import {
  buildingById,
  buildingCalibratedModel,
  buildingDeltaMeter,
  buildingDeltaMeterReading,
  buildingDocumentCenter,
  buildingEditInfo,
  buildingInsights,
  buildingTransaction,
  buildingTransactionInvoices,
  buildingUtilityMeter,
  calibratedModels,
  financialModelById,
  financialModels,
  newFinancialModel,
  utilityMeters,
} from "../lib/endpoints";
import { trackBuildingView } from "../lib/useRecentlyViewedBuildings";
import TransactionInvoices from "../TransactionInvoices/TransactionInvoices";
import TransactionDashboard from "../Transactions/TransactionDashboard";
import { AppRouteParams } from "../ui/AppRouteParams";
import Loading from "../ui/Loading";
import ProductCopy from "../ui/ProductCopy";
import StepperButtons from "../ui/StepperButtons";
import UtilityMeter from "../UtilityMeter";
import UtilityMetersList from "../UtilityMetersList";
import BuildingLayout from "./BuildingLayout";
import { BuildingMetadataQueryDocument } from "./BuildingMetadataQuery.generated";

interface Props {
  id: string;
}

const Building: FC<Props> = ({ id }) => {
  const { hasModels } = useHasModels(id);

  const { data, loading, error } = useQuery(BuildingMetadataQueryDocument, {
    variables: { buildingId: id },
  });

  // Adds the building to the 'recently viewed' list
  useEffect(() => {
    if (data) {
      trackBuildingView(data.building);
    }
  }, [data]);

  if (error) throw error;
  if (loading || !data) return <Loading variant="circle" />;

  const { building } = data;

  return (
    <BuildingLayout building={building}>
      <Switch>
        <Route path={buildingById({ id: AppRouteParams.buildingId })}>
          <Switch>
            <Route exact path={buildingById({ id: AppRouteParams.buildingId })}>
              <BuildingDashboard buildingId={id} />
            </Route>
            <Route
              exact
              path={buildingInsights({
                buildingId: AppRouteParams.buildingId,
              })}
            >
              <BuildingInsights id={id} />
            </Route>
            <Route
              exact
              path={buildingEditInfo({
                buildingId: AppRouteParams.buildingId,
              })}
            >
              <BuildingEdit building={building} hasModels={hasModels} />
              <br />
              {!hasModels && (
                <StepperButtons
                  next={{
                    to: utilityMeters({ buildingId: id }),
                    buttonText: `Next: ${ProductCopy.UTILITY_METERS}`,
                  }}
                />
              )}
            </Route>
            <Route
              exact
              path={calibratedModels({
                buildingId: AppRouteParams.buildingId,
              })}
            >
              <CalibratedModelsReferenceList buildingId={id} />
            </Route>
            <Route
              exact
              path={buildingCalibratedModel({
                buildingId: AppRouteParams.buildingId,
                calibratedModelId: AppRouteParams.calibratedModelId,
              })}
              render={({
                match,
              }: RouteComponentProps<{ calibratedModelId: string }>) =>
                assertFeature("REF_PERIOD_SELECTION") ? (
                  <CalibratedModelReference
                    calibratedModelId={match.params.calibratedModelId}
                  />
                ) : (
                  <CalibratedModel
                    calibratedModelId={match.params.calibratedModelId}
                  />
                )
              }
            />
            <Route
              exact
              path={utilityMeters({ buildingId: AppRouteParams.buildingId })}
            >
              <UtilityMetersList buildingId={id} />
              {!hasModels && (
                <StepperButtons
                  next={{
                    to: calibratedModels({ buildingId: id }),
                    buttonText: `Next: ${ProductCopy.ENERGY_MODELS}`,
                  }}
                />
              )}
            </Route>
            <Route
              exact
              path={buildingUtilityMeter({
                buildingId: AppRouteParams.buildingId,
                utilityMeterId: AppRouteParams.utilityMeterId,
              })}
              render={({
                match,
              }: RouteComponentProps<{
                utilityMeterId: string;
              }>) => (
                <UtilityMeter utilityMeterId={match.params.utilityMeterId} />
              )}
            />
            <Route
              exact
              path={financialModels({
                buildingId: AppRouteParams.buildingId,
              })}
              render={({ match }: RouteComponentProps<{ id: string }>) => (
                <InvestmentList
                  buildingId={match.params.id}
                  hasBuildingModels={hasModels}
                />
              )}
            />
            <Route
              exact
              path={buildingDocumentCenter({
                buildingId: AppRouteParams.buildingId,
              })}
              render={({ match }: RouteComponentProps<{ id: string }>) => (
                <BuildingDocumentCenter buildingId={match.params.id} />
              )}
            />
            <Route
              exact
              path={newFinancialModel({
                buildingId: AppRouteParams.buildingId,
              })}
              render={({ match }: RouteComponentProps<{ id: string }>) => (
                <NewFinancialModelContainer buildingId={match.params.id} />
              )}
            />
            <Route
              exact
              path={financialModelById({
                buildingId: AppRouteParams.buildingId,
                financialModelId: AppRouteParams.financialModelId,
              })}
              render={({
                match,
              }: RouteComponentProps<{
                id: string;
                financialModelId: string;
              }>) => (
                <FinancialModelContainer
                  financialModelId={match.params.financialModelId}
                  buildingId={match.params.id}
                />
              )}
            />
            <Route
              exact
              path={buildingDeltaMeter({
                buildingId: AppRouteParams.buildingId,
              })}
              render={({
                match,
              }: RouteComponentProps<{
                id: string;
              }>) => <DeltaMeterDashboard buildingId={match.params.id} />}
            />
            <Route
              exact
              path={buildingDeltaMeterReading({
                buildingId: AppRouteParams.buildingId,
                readingId: AppRouteParams.deltaMeterReadingId,
              })}
              render={({
                match,
              }: RouteComponentProps<{
                readingId: string;
              }>) => <DeltaMeterReading readingId={match.params.readingId} />}
            />
            <Route
              exact
              path={buildingTransaction({
                buildingId: AppRouteParams.buildingId,
              })}
              render={({
                match,
              }: RouteComponentProps<{
                id: string;
              }>) => <TransactionDashboard buildingId={match.params.id} />}
            />
            <Route
              exact
              path={buildingTransactionInvoices({
                buildingId: AppRouteParams.buildingId,
              })}
              render={({
                match,
              }: RouteComponentProps<{
                id: string;
              }>) => <TransactionInvoices buildingId={match.params.id} />}
            />
            <Route>
              <Redirect to={buildingById({ id })} />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </BuildingLayout>
  );
};

export default Building;
