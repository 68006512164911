import { ResolverResult } from "react-hook-form";
import removeEmpty from "./removeEmpty";

type Errors<Fields> = { [k in keyof Fields]?: string | null };
type Validator<Fields> = (f: Fields) => Errors<Fields>;
type Resolver<Fields> = (f: Fields) => ResolverResult<Fields>;

/**
 * react-hook-form resolver for our simple validators utilities.
 *
 * does not currently support error types, or nested structures.
 */
function validatorsResolver<Fields>(
  validate: Validator<Fields>
): Resolver<Fields> {
  return (values: Fields) => {
    const errors = removeEmpty(validate(values));

    if (Object.keys(errors).length) {
      return {
        values: {},
        errors: Object.entries(errors).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: { type: "", message: v },
          }),
          {}
        ),
      };
    }

    return {
      values,
      errors: {},
    };
  };
}

export default validatorsResolver;
