import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { FormattedDate } from "react-intl";
import modelDescription from "../lib/modelDescription";
import modelName from "../lib/modelName";
import modelUserName from "../lib/modelUserName";
import DefaultModel from "../ui/DefaultModel";
import Loading from "../ui/Loading";
import MetaData from "../ui/MetaData";
import { PageTitle } from "../ui/Typography";
import { ModelMetadataQueryDocument } from "./ModelMetadataQuery.generated";

interface Props {
  calibratedModelId: string;
}

const ModelMetadata: FC<Props> = ({ calibratedModelId }) => {
  const { data, loading, error } = useQuery(ModelMetadataQueryDocument, {
    variables: { id: calibratedModelId },
  });

  if (error) throw error;

  // TODO: Skeletonize!
  if (loading || !data) return <Loading variant="circle" />;

  const { calibratedModel: model } = data;

  return (
    <>
      <PageTitle>{modelName(model)}</PageTitle>
      <MetaData>
        {model.default && (
          <span>
            <DefaultModel model={model} />
          </span>
        )}
        <Typography variant="caption" color="textSecondary">
          Created: <FormattedDate value={new Date(model.createdAt)} /> by{" "}
          {modelUserName(model.user)}
        </Typography>
      </MetaData>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography>{modelDescription(model)}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ModelMetadata;
