import { Grid } from "@material-ui/core";
import React from "react";
import LabelledValue from "../lib/LabelledValue";
import millifyWatts from "../lib/millifyWatts";
import { Overrides } from "./NewCalibratedModelDialog";

interface Props {
  overrides: Overrides;
  squareFeet: number;
}

const CalibratedModelDetailFragment: React.FC<Props> = ({
  overrides,
  squareFeet,
}) => {
  const exteriorEnergy = overrides.baseload?.exterior?.electricity ?? -1;

  return (
    <Grid container spacing={2}>
      {overrides.lighting && (
        <Grid item>
          <LabelledValue
            title="Interior lighting"
            value={`${overrides.lighting!.toFixed(2)} W/Ft²`}
          />
        </Grid>
      )}
      {exteriorEnergy >= 0 && (
        <Grid item>
          <LabelledValue
            title="Exterior energy consumption"
            value={`${millifyWatts(exteriorEnergy * squareFeet)}`}
          />
        </Grid>
      )}
      {overrides.hotWater?.setTemp && (
        <Grid item>
          <LabelledValue
            title="Domestic hot water set point"
            value={`${overrides.hotWater.setTemp}°F`}
          />
        </Grid>
      )}
      {overrides.spaceHeating?.fanPortion != null && (
        <Grid item>
          <LabelledValue
            title="Forced Air Heating"
            value={
              overrides.spaceHeating.fanPortion > 0 ? "Enabled" : "Disabled"
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CalibratedModelDetailFragment;
