import { Grid, InputAdornment, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { calibratedModelName } from "../lib/modelName";
import FormDialog from "../ui/FormDialog";
import Gaps from "../ui/Gaps";
import { SelectFieldController, SelectItem } from "../ui/SelectField";
import { TextField } from "../ui/TextField";

interface Props {
  calibratedModels: { id: string; name?: string | null }[];
  onSubmit: (fields: Fields) => void;
  onCancel: () => void;
}

interface Fields {
  calibratedModelId: string;
  discountRate: number;
  projectCost: number | undefined;
}

const NewFinancialModelDialog: FC<Props> = ({
  calibratedModels,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    errors,
    register,
    control,
    formState: { isSubmitting },
  } = useForm<Fields>();

  return (
    <FormDialog
      title="Add financial model"
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitName="Add"
      isSubmitting={isSubmitting}
      maxWidth="sm"
    >
      <Gaps spacing={2}>
        <SelectFieldController
          required
          variant="outlined"
          name="calibratedModelId"
          label="Select calibrated model *"
          control={control}
          error={errors.calibratedModelId?.message}
        >
          {calibratedModels.map((calibratedModel) => (
            <SelectItem key={calibratedModel.id} value={calibratedModel.id}>
              {calibratedModelName(calibratedModel.name)}
            </SelectItem>
          ))}
        </SelectFieldController>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              required
              name="discountRate"
              inputRef={register({
                valueAsNumber: true,
                required: {
                  value: true,
                  message: "Required",
                },
                min: {
                  value: 0,
                  message: "Value cannot be negative",
                },
                max: {
                  value: 100,
                  message: "Value must be from 0 to 100",
                },
              })}
              error={errors.discountRate?.message}
              type="number"
              label="Discount rate"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              name="projectCost"
              inputRef={register({
                setValueAs: (v) => (v === "" ? undefined : Number(v)),
                min: {
                  value: 0,
                  message: "Value cannot be negative",
                },
              })}
              type="number"
              label="Capital investment"
              size="small"
              error={errors.projectCost?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">USD</InputAdornment>
                ),
              }}
            />

            <Typography variant="caption" color="textSecondary">
              If not specified, EnergyRM will calculate the maximum investment
              available to fund this project
            </Typography>
          </Grid>
        </Grid>
      </Gaps>
    </FormDialog>
  );
};

export default NewFinancialModelDialog;
