import { DeltaMeterAdjustmentReason } from "../api/graphql";
import { assertNever } from "./assertNever";

export const adjustmentReason = (
  reason: DeltaMeterAdjustmentReason
): string => {
  switch (reason) {
    case DeltaMeterAdjustmentReason.EQUIPMENT:
      return "Equipment";
    case DeltaMeterAdjustmentReason.OCCUPANCY_CHANGE:
      return "Occupancy change";
    case DeltaMeterAdjustmentReason.OUTAGE:
      return "Outage";
    case DeltaMeterAdjustmentReason.OTHER:
      return "Other";
    default:
      assertNever(reason);
  }
};
