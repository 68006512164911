import * as Types from '../api/graphql';

import { OrganizationModelFragment } from '../api/fragments/OrganizationModel.generated';
import { UserInviteModelFragment } from '../api/fragments/UserInviteModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationModelFragmentDoc } from '../api/fragments/OrganizationModel.generated';
import { UserInviteModelFragmentDoc } from '../api/fragments/UserInviteModel.generated';
export type UsersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type UsersQuery = { organization: (
    { __typename: 'Organization', users: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string, name: string, email: string, role: Types.Maybe<Types.OrganizationRoleType> } }> }, userInvites: Array<(
      { __typename: 'UserInvite', role: Types.OrganizationRoleType }
      & UserInviteModelFragment
    )> }
    & OrganizationModelFragment
  ) };


export const UsersDocument: DocumentNode<UsersQuery, UsersQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Users"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationModel"}},{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"role"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}]}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"userInvites"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserInviteModel"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]}},...OrganizationModelFragmentDoc.definitions,...UserInviteModelFragmentDoc.definitions]};