import { Link, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, SyntheticEvent, useState } from "react";
import assertFeature from "../lib/assertFeature";
import clampFileName from "../lib/clampFileName";
import { fileTooLarge } from "../lib/filesize";
import Loading from "../ui/Loading";
import DismissDialog from "./DismissDialog";
import { Document } from "./DocumentCenter";
import { FilePreviewQuery } from "./FilePreviewQuery.generated";
import { isMsExcel, isMsWord } from "./isMsOffice";
import NoPreview, { FileTooLarge } from "./NoPreview";
import { useOpenDocument } from "./useOpenDocument";

interface Props {
  doc: Document;
}

const useStyles = makeStyles((theme) => ({
  iframeContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // Subtract an arbitrary amount to cover dialog padding
      minWidth: theme.breakpoints.width("md") - theme.spacing(15),
      height: "70vh",
    },
    // Lower than `md` is fullscreen and requires full width and height
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "fill",
  },
  imageHidden: {
    display: "none",
  },
  iframe: {
    width: "100%",
    height: "100%",
  },
}));

/**
 * PDF previews of the file via Google docs previewer
 */
const PreviewPDF: FC<{ url: string; size: number }> = ({ url, size }) => {
  const classes = useStyles();

  const encoded = encodeURIComponent(url);
  const rootUrl = "https://docs.google.com/viewer";
  const iframeUrl = `${rootUrl}?embedded=true&url=${encoded}`;

  if (fileTooLarge(size, 8)) return <FileTooLarge />;

  return (
    <div className={classes.iframeContainer}>
      <iframe
        className={classes.iframe}
        title="google-preview"
        src={iframeUrl}
      />
    </div>
  );
};

/**
 * Image previews of the file
 *
 * I'm electing to suppress previews above 5MB
 */
const PreviewImage: FC<{ url: string; size: number }> = ({ url, size }) => {
  const classes = useStyles();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  if (fileTooLarge(size, 5)) return <FileTooLarge />;

  return (
    <div>
      {!imageLoaded && <Loading variant="circle" />}
      <img
        className={clsx({
          [classes.image]: imageLoaded,
          [classes.imageHidden]: !imageLoaded,
        })}
        alt="preview"
        src={url}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

/**
 * Generates a preview of non-excel doc (word) files using Google docs previewer
 */
const PreviewWord: FC<{ url: string; size: number }> = ({ url, size }) => {
  const classes = useStyles();

  const encoded = encodeURIComponent(url);
  const rootUrl = "https://docs.google.com/viewer";
  const iframeUrl = `${rootUrl}?embedded=true&url=${encoded}`;

  if (fileTooLarge(size, 8)) return <FileTooLarge />;

  return (
    <div className={classes.iframeContainer}>
      <iframe className={classes.iframe} title="ms-preview" src={iframeUrl} />
    </div>
  );
};

/**
 * Generates a preview of Excel files using MS Office's preview link
 */
const PreviewSpreadsheet: FC<{ url: string; size: number }> = ({
  url,
  size,
}) => {
  const classes = useStyles();

  const encoded = encodeURIComponent(url);
  const rootUrl = "https://view.officeapps.live.com/op/embed.aspx";
  const iframeUrl = `${rootUrl}?src=${encoded}`;

  if (fileTooLarge(size, 5)) return <FileTooLarge />;

  return (
    <div className={classes.iframeContainer}>
      <iframe className={classes.iframe} title="ms-preview" src={iframeUrl} />
    </div>
  );
};

export const isImage = (mimetype: string) => mimetype.startsWith("image");
export const isPdf = (mimetype: string) => mimetype === "application/pdf";

export const PreviewFile: FC<{
  orgDocument: FilePreviewQuery["organizationDocument"];
}> = ({ orgDocument }) => {
  const { mimetype, url, size } = orgDocument.file;

  if (isImage(mimetype)) {
    return <PreviewImage url={url} size={size} />;
  } else if (isPdf(mimetype) && assertFeature("THIRD_PARTY_FILE_PREVIEWS")) {
    return <PreviewPDF url={url} size={size} />;
  } else if (
    isMsExcel(mimetype) &&
    assertFeature("THIRD_PARTY_FILE_PREVIEWS")
  ) {
    return <PreviewSpreadsheet url={url} size={size} />;
  } else if (isMsWord(mimetype) && assertFeature("THIRD_PARTY_FILE_PREVIEWS")) {
    return <PreviewWord url={url} size={size} />;
  } else return <NoPreview />;
};

const FilenamePreviewModalLink: FC<Props> = ({ doc }) => {
  const { open, closeDialog, data, loading, fullscreen } = useOpenDocument();

  return (
    <>
      {data && (
        <DismissDialog
          fullScreen={fullscreen}
          maxWidth="md"
          title={clampFileName(data.organizationDocument.file.filename)}
          onDismiss={closeDialog}
        >
          <PreviewFile orgDocument={data.organizationDocument} />
        </DismissDialog>
      )}
      <Link
        href="#"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          open(doc);
        }}
      >
        <Typography component="span">
          {clampFileName(doc.file.filename)}{" "}
          {loading && <Loading variant="circle" size={15} inline />}
        </Typography>
      </Link>
    </>
  );
};

export default FilenamePreviewModalLink;
