import {
  BuildingUseType,
  Currency,
  EnergySource,
  EnergyUnitType,
  ImportBuildingInput,
  LeaseType,
  MeterSource,
} from "../api/graphql";
import groupBy from "../lib/groupBy";
import removeEmpty from "../lib/removeEmpty";
import sheetParser, {
  parseAsBuildingUseType,
  parseAsDate,
  parseAsEnergySource,
  parseAsEnergyUnit,
  parseAsInt,
  parseAsLeaseType,
  parseAsMeterSource,
  parseAsMoney,
  parseAsString,
  parseAsUSState,
  parseAsUSZip,
  parseAsYear,
  Row,
  Sheet,
} from "../lib/spreadsheetParsers";
import usaStates from "../lib/usaStates";
import { enumError, numberError, presenceError } from "../lib/validators";

export type BuildingImport = Omit<ImportBuildingInput, "organizationId">;

const parseBuildings = sheetParser((row: Row) => ({
  name: parseAsString(row["name"]) ?? "",
  yearBuilt: parseAsYear(row["year built (optional)"]) ?? undefined,
  externalId: parseAsString(row["building id"]) ?? "",
  leaseType: parseAsLeaseType(row["lease type (optional)"]) ?? undefined,
  majorTenant: parseAsString(row["major tenant (optional)"]) ?? undefined,
  squareFeet: parseAsInt(row["square feet"]) ?? 0,
  buildingUseType: parseAsBuildingUseType(row["primary function"]) ?? "",
  streetAddress: parseAsString(row["address"]) ?? "",
  locality: parseAsString(row["city"]) ?? "",
  region: parseAsUSState(row["state"]) ?? "",
  postalCode: parseAsUSZip(row["zip"]) ?? "",
  country: "US",
}));

const parseReadings = sheetParser((row: Row) => ({
  buildingID: parseAsString(row["building id"]) ?? "",
  meterID: parseAsString(row["meter id"]) ?? "",
  energySource: parseAsEnergySource(row["meter type"]) ?? "",
  meterSource: parseAsMeterSource(row["meter type"]) ?? undefined,
  startOn: parseAsDate(row["reading start date"]) ?? undefined,
  endOn: parseAsDate(row["reading end date"]) ?? undefined,
  quantity: parseAsInt(row["usage"]) ?? 0,
  unit: parseAsEnergyUnit(row["unit"]) ?? "",
  cost: parseAsMoney(row["cost (optional)"], Currency.USD) ?? undefined,
  utilityName: parseAsString(row["utility name (optional)"]) ?? undefined,
}));

type ReadingInput = BuildingImport["utilityMeters"][number]["utilityMeterReadings"][number];
function removeDuplicateReadings(readings: ReadingInput[]): ReadingInput[] {
  return readings.filter(
    (a, idx) =>
      !readings
        .slice(idx + 1)
        .some(
          (b) =>
            a.startOn.getTime() === b.startOn.getTime() &&
            a.endOn.getTime() === b.endOn.getTime() &&
            a.quantity === b.quantity &&
            a.unit === b.unit
        )
  );
}

const normalizeKeys = (sheet: Sheet): Sheet =>
  sheet.map((row) =>
    Object.keys(row).reduce(
      (memo, k) => ({ ...memo, [k.trim().toLowerCase()]: row[k] }),
      {}
    )
  );

export function parseLoadFile(
  rawBuildings: Row[],
  rawReadings: Row[]
): BuildingImport[] {
  const buildings = parseBuildings(normalizeKeys(rawBuildings));

  const readings = parseReadings(normalizeKeys(rawReadings));
  const readingsByBuilding = groupBy(readings, (r) => r.buildingID);

  return buildings.map((building) => {
    // identify the implied meters
    const readingsByMeter = groupBy(
      readingsByBuilding[building.externalId] ?? [],
      (r) => r.meterID
    );

    return {
      ...building,
      buildingUseType: building.buildingUseType as BuildingUseType,
      leaseType: building.leaseType as LeaseType | undefined,
      utilityMeters: Object.entries(readingsByMeter).map(
        ([externalId, readings]) => ({
          externalId,
          energySource: (readings[0].energySource || "") as EnergySource,
          meterSource: readings[0].meterSource,
          unit: readings[0].unit as EnergyUnitType,
          utilityName: readings[0].utilityName,
          utilityMeterReadings: removeDuplicateReadings(
            readings.map(({ quantity, unit, startOn, endOn, cost }) => ({
              quantity,
              unit: unit as EnergyUnitType,
              startOn,
              endOn,
              cost,
            }))
          ),
        })
      ),
    };
  });
}

const firstError = (errorMap: Record<string, string | null>): string | null => {
  return Object.values(removeEmpty(errorMap))[0];
};

/**
 * validateBuildingImport is responsible for performing the minimum validation necessary for the object to be
 * accepted by GraphQL's schema.
 */
export const validateBuildingImport = (
  building: BuildingImport
): string | null =>
  firstError({
    name: presenceError(building.name),
    yearBuilt: null,
    externalId: presenceError(building.externalId),
    leaseType: enumError(building.leaseType, Object.values(LeaseType)),
    majorTenant: null,
    squareFeet: numberError(building.squareFeet, { gt: 0 }),
    buildingUseType:
      presenceError(building.buildingUseType) ||
      enumError(building.buildingUseType, Object.values(BuildingUseType)),
    streetAddress: presenceError(building.streetAddress),
    locality: presenceError(building.locality),
    region:
      presenceError(building.region) ||
      enumError(
        building.region,
        usaStates.map((state) => state.abbreviation)
      ),
    postalCode: presenceError(building.postalCode),
    country: presenceError(building.country),
    utilityMeters: building.utilityMeters.map((um) =>
      firstError({
        externalId: presenceError(um.externalId),
        energySource:
          presenceError(um.energySource) ||
          enumError(um.energySource, Object.values(EnergySource)),
        meterSource: enumError(um.meterSource, Object.values(MeterSource)),
        unit:
          presenceError(um.unit) ||
          enumError(um.unit, Object.values(EnergyUnitType)),
        utilityName: null,
        utilityMeterReadings: um.utilityMeterReadings.map((umr) =>
          firstError({
            quantity: numberError(umr.quantity),
            unit: enumError(umr.unit, Object.values(EnergyUnitType)),
            startOn: presenceError(umr.startOn),
            endOn: presenceError(umr.endOn),
            cost: null,
          })
        )[0],
      })
    )[0],
  });
