import * as Types from '../api/graphql';

import { OrganizationModelFragment } from '../api/fragments/OrganizationModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationModelFragmentDoc } from '../api/fragments/OrganizationModel.generated';
export type OrganizationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationsQuery = { organizations: { __typename: 'OrganizationConnection', edges: Array<{ __typename: 'OrganizationEdge', node: (
        { __typename: 'Organization' }
        & OrganizationModelFragment
      ) }> } };


export const OrganizationsDocument: DocumentNode<OrganizationsQuery, OrganizationsQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Organizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationModel"}}]}}]}}]}}]}},...OrganizationModelFragmentDoc.definitions]};