export function escapeCsvQuotes(arg: any): any {
  if (typeof arg === "string") {
    return arg.replace(/"/g, `""`); // Escape CSV quotes
  }
  return arg;
}

export function wrapInQuotes(arg: any): string {
  return arg ? `"${arg.toString()}"` : "";
}

export default function createCsvRows<T>(data: Array<T[]>): string {
  return data
    .map((d) => d.map(escapeCsvQuotes).map(wrapInQuotes).join(","))
    .join("\n");
}
