import { Paper, TableContainer } from "@material-ui/core";
import React, { FC } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { DistributionModelFragment } from "../api/fragments/DistributionModel.generated";
import { InvoicePaymentModelFragment } from "../api/fragments/InvoicePaymentModel.generated";
import { assertNever } from "../lib/assertNever";
import sum from "../lib/sum";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../ui/Table";
import { InvoiceDialogLink } from "./InvoiceDialog";

export type LedgerOperationOptions =
  | InvoicePaymentModelFragment
  | DistributionModelFragment;

export interface Props {
  ledgerItems: Array<LedgerOperationOptions>;
  timeZone: string;
}

interface InvoicePaymentRowProps {
  invoicePayment: InvoicePaymentModelFragment;
  timeZone: string;
}

interface DistributionRowProps {
  distribution: DistributionModelFragment;
  timeZone: string;
}

const displayOperationType = (operationType: LedgerOperationOptions) => {
  switch (operationType.__typename) {
    case "Distribution":
      return "Payout";
    case "InvoicePayment":
      return "Payment processed";
    default:
      assertNever(operationType);
  }
};

const InvoicePaymentRow: FC<InvoicePaymentRowProps> = ({
  invoicePayment,
  timeZone,
}) => {
  const { amount, date, partyName, invoice } = invoicePayment;

  return (
    <>
      <TableRow>
        <TableCell>
          <FormattedDate value={date} timeZone={timeZone} />
        </TableCell>
        <TableCell>
          <InvoiceDialogLink invoice={invoice} timeZone={timeZone} />
        </TableCell>
        <TableCell>{displayOperationType(invoicePayment)}</TableCell>
        <TableCell>{partyName}</TableCell>
        <TableCell />
        <TableCell>
          <FormattedNumber
            value={amount.value}
            style={`currency`}
            currency="USD"
          />
        </TableCell>
      </TableRow>
    </>
  );
};

const DistributionRow: FC<DistributionRowProps> = ({
  distribution,
  timeZone,
}) => {
  const { id, amount, date, partyName } = distribution;

  return (
    <TableRow>
      <TableCell>
        <FormattedDate value={date} timeZone={timeZone} />
      </TableCell>
      <TableCell>{id}</TableCell>
      <TableCell>{displayOperationType(distribution)}</TableCell>
      <TableCell>{partyName}</TableCell>
      <TableCell>
        (
        <FormattedNumber
          value={amount.value}
          style={`currency`}
          currency="USD"
        />
        )
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

const Ledger: FC<Props> = ({ ledgerItems, timeZone }) => {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Operation ID</TableCell>
            <TableCell>Operation</TableCell>
            <TableCell>Party</TableCell>
            <TableCell>Debit</TableCell>
            <TableCell>Credit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ledgerItems.map((item) =>
            item.__typename === "Distribution" ? (
              <DistributionRow
                distribution={item}
                key={item.id}
                timeZone={timeZone}
              />
            ) : (
              <InvoicePaymentRow
                invoicePayment={item}
                key={item.id}
                timeZone={timeZone}
              />
            )
          )}
          <TableRow>
            <TableCell colSpan={4}>
              <b>Subtotal</b>
            </TableCell>
            <TableCell>
              <b>
                (
                <FormattedNumber
                  value={sum(
                    ledgerItems
                      .filter((item) => item.__typename === "Distribution")
                      .map((item) => item.amount.value)
                  )}
                  style={`currency`}
                  currency="USD"
                />
                )
              </b>
            </TableCell>
            <TableCell>
              <b>
                <FormattedNumber
                  value={sum(
                    ledgerItems
                      .filter((item) => item.__typename === "InvoicePayment")
                      .map((item) => item.amount.value)
                  )}
                  style={`currency`}
                  currency="USD"
                />
              </b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Ledger;
