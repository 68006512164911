import { Card, CardHeader, makeStyles } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import { CardContent } from "./Card";
import { SectionTitle } from "./Typography";

interface Props {
  label: ReactNode;
  action?: ReactNode;
  subheader?: ReactNode;
}

const useStyles = makeStyles({
  cardContentPadding: {
    padding: 0,
    paddingBottom: 0,
    // TODO: Why is the MUI card adding last-child padding?
    "&:last-child": {
      padding: 0,
    },
  },
});

const DashboardCard: FC<Props> = ({ label, action, subheader, children }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardHeader
        title={<SectionTitle>{label}</SectionTitle>}
        subheader={subheader}
        action={action}
      />
      <CardContent classes={{ root: classes.cardContentPadding }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
