import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteDeltaMeterAdjustmentMutationVariables = Types.Exact<{
  input: Types.DeleteDeltaMeterAdjustmentInput;
}>;


export type DeleteDeltaMeterAdjustmentMutation = { deleteDeltaMeterAdjustment: { __typename: 'DeleteDeltaMeterAdjustmentResponse', deltaMeterAdjustment: { __typename: 'DeltaMeterAdjustment', id: string } } };


export const DeleteDeltaMeterAdjustmentDocument: DocumentNode<DeleteDeltaMeterAdjustmentMutation, DeleteDeltaMeterAdjustmentMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteDeltaMeterAdjustment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteDeltaMeterAdjustmentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteDeltaMeterAdjustment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deltaMeterAdjustment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]};