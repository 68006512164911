import millify from "millify";

export function megabytes(bytes: number) {
  return bytes / Math.pow(1024, 2);
}

/**
 * Determines if the passed filesize in bytes is larger than
 * a specified amount of megabytes
 *
 * @param numBytes - the number of bytes
 * @param max - the max filesize in MB
 */
export function fileTooLarge(numBytes: number, max: number) {
  return megabytes(numBytes) >= max;
}

export function millifyFilesize(bytes: number) {
  return millify(bytes, {
    units: ["B", "KB", "MB", "GB"],
    space: true,
  });
}
