import { useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useState } from "react";
import { BuildingPermissionModelFragmentDoc } from "../api/fragments/BuildingPermissionModel.generated";
import { BuildingAccessType, OrganizationRoleType } from "../api/graphql";
import {
  DeleteBuildingPermissionDocument,
  UpdateBuildingPermissionDocument,
} from "../BuildingPermission";
import useDeleteEntity from "../lib/useDeleteEntity";
import useLazyDialogData from "../lib/useLazyDialogData";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import MetaData from "../ui/MetaData";
import Switch from "../ui/Switch";
import { useToastContext } from "../ui/ToastProvider";
import { SectionTitle } from "../ui/Typography";
import {
  BuildingUserPermissionsDocument,
  BuildingUserPermissionsQuery,
} from "./BuildingPermissionsQuery.generated";
import { CreateBuildingPermissionDocument } from "./CreateBuildingPermission.generated";

type OrgUser = BuildingUserPermissionsQuery["organization"]["users"]["edges"][number]["node"];
type BuildingPermission = BuildingUserPermissionsQuery["building"]["permissions"][number];

interface DialogProps {
  data: BuildingUserPermissionsQuery;
  onClose: () => void;
}

const ShareBuildingDialog: FC<DialogProps> = ({ data, onClose }) => {
  const { building, organization } = data;
  const createToast = useToastContext();

  const [
    createBuildingPermission,
    { loading: createBuildingPermissionLoading },
  ] = useMutation(CreateBuildingPermissionDocument);
  const [
    updateBuildingPermission,
    { loading: updateBuildingPermissionLoading },
  ] = useMutation(UpdateBuildingPermissionDocument);

  const [
    deleteEntity,
    { loading: deleteBuildingPermissionLoading },
  ] = useDeleteEntity(DeleteBuildingPermissionDocument);

  const formLocked =
    createBuildingPermissionLoading ||
    updateBuildingPermissionLoading ||
    deleteBuildingPermissionLoading;

  /**
   * Initialize the source users array excluding users with existing permissions
   */
  const usersWithoutPermission = organization.users.edges
    .map((u) => u.node)
    .filter(
      (user) => !building.permissions.some((cu) => cu.user.id === user.id)
    );

  const [selectedUser, setSelectedUser] = useState<OrgUser | null>(null);

  /**
   * Adds a user to the building permission list
   */
  const append = async (newUser: OrgUser) => {
    try {
      await createBuildingPermission({
        variables: {
          input: {
            access: BuildingAccessType.READ,
            userId: newUser.id,
            buildingId: building.id,
          },
        },
        update(cache, { data }) {
          cache.modify({
            id: cache.identify({
              __typename: "Building",
              id: building.id,
            }),
            fields: {
              permissions(existingPermissionRefs = []) {
                const newPermissionsRef = cache.writeFragment({
                  data: data?.createBuildingPermission.buildingPermission,
                  fragment: BuildingPermissionModelFragmentDoc,
                });

                return [...existingPermissionRefs, newPermissionsRef];
              },
            },
          });
        },
      });
      createToast("Member added with read-only access", "success");
    } catch (err) {
      createToast(err.message || "Error saving permissions", "error");
    }
  };

  /**
   * Removes a user from the building permission list
   */
  const remove = async (permission: BuildingPermission) => {
    try {
      deleteEntity(permission, {
        input: {
          userId: permission.user.id,
          buildingId: permission.building.id,
        },
      });
      createToast("Access revoked for user", "success");
    } catch (err) {
      createToast(err.message || "Error saving permissions", "error");
    }
  };

  /**
   * Sets a building permission user's access level to READ or WRITE
   */
  const toggleAccess = async (userId: string, access: BuildingAccessType) => {
    try {
      await updateBuildingPermission({
        variables: {
          input: {
            access,
            userId,
            buildingId: building.id,
          },
        },
      });
      createToast(
        `User modified with ${access.toLowerCase()} access`,
        "success"
      );
    } catch (err) {
      createToast(err.message || "Error editing permissions", "error");
    }
  };

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>Share building</DialogTitle>
      {formLocked && <Loading variant="line" size={20} />}
      <DialogContent dividers>
        {
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Autocomplete
                  options={usersWithoutPermission}
                  noOptionsText="No users available"
                  value={selectedUser}
                  onChange={(_, newValue) => setSelectedUser(newValue)}
                  getOptionLabel={(opt) => opt.name}
                  renderInput={(params) => (
                    <TextField
                      label="Search for a user"
                      {...params}
                      size="small"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  primary
                  onClick={() => {
                    !!selectedUser && append(selectedUser);
                    setSelectedUser(null);
                  }}
                  disabled={!selectedUser || formLocked}
                >
                  Share building with user
                </Button>
              </Grid>
            </Grid>
            <Box pt={2}>
              <SectionTitle>Users with building access:</SectionTitle>
            </Box>
            <List dense>
              {building.permissions.map((permission) => {
                return (
                  <ListItem key={permission.id} disableGutters divider>
                    <ListItemText
                      primary={<Typography>{permission.user.name}</Typography>}
                      secondary={
                        <MetaData>
                          <span>Role: {permission.user.role}</span>
                          {(permission.user.role ===
                            OrganizationRoleType.OWNER ||
                            permission.user.role ===
                              OrganizationRoleType.ADMIN) && (
                            <span>Full portfolio access</span>
                          )}
                        </MetaData>
                      }
                    />
                    {permission.user.role === OrganizationRoleType.MEMBER && (
                      <Box display="inline-flex">
                        <Switch
                          disabled={formLocked}
                          label={
                            <Typography variant="body2">
                              Write access
                            </Typography>
                          }
                          value={permission.access === BuildingAccessType.WRITE}
                          onChange={(v) =>
                            toggleAccess(
                              permission.user.id,
                              v
                                ? BuildingAccessType.WRITE
                                : BuildingAccessType.READ
                            )
                          }
                        />
                        <Box display="flex" alignItems="center">
                          <Button
                            size="small"
                            disabled={formLocked}
                            onClick={() => remove(permission)}
                          >
                            Revoke access
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button disabled={formLocked} size="small" onClick={onClose}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ShareBuildingButtonProps = {
  buildingId: string;
  organizationId: string;
};

export const ShareBuildingButton: React.FC<ShareBuildingButtonProps> = ({
  buildingId,
  organizationId,
}) => {
  const { isOrgAdmin } = useSessionWithUser();
  const {
    openDialog,
    closeDialog,
    data: dialogData,
    loading: dialogLoading,
  } = useLazyDialogData(BuildingUserPermissionsDocument);

  if (!isOrgAdmin) return null;

  return (
    <>
      {dialogData && (
        <ShareBuildingDialog data={dialogData} onClose={closeDialog} />
      )}
      <Button
        size="small"
        onClick={() => openDialog({ buildingId, organizationId })}
      >
        {dialogLoading ? <CircularProgress size={15} /> : "Share"}
      </Button>
    </>
  );
};

export default ShareBuildingDialog;
