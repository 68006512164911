import React from "react";
import { useIntl } from "react-intl";
import {
  Area,
  AreaChart,
  Label,
  LabelFormatter,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EnergyEndUse } from "../api/graphql";
import { endUseName, energyEndUseColor } from "../lib/endUse";
import { energySourceName } from "../lib/energySource";
import formatter from "../lib/formatter";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
  yAxisOffset,
} from "../lib/reporting/config";
import {
  formatEnergyEfficiencyCurrencyReport,
  formatEnergyEfficiencyReport,
} from "../lib/reporting/seasonalPerformanceChart";
import { SeasonalPerformance } from "../lib/types/Reports";
import unique from "../lib/unique";
import colors from "../ui/colors";
import assignedEnergyColor from "./assignedEnergyColor";
import { Rates } from "./BuildingInsightsReport";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  model: SeasonalPerformance[];
  compareModel: SeasonalPerformance[];
  rates: Rates;
  showCurrency?: boolean;
}

const SeasonalPerformanceDelta: React.FC<Props> = ({
  aspect,
  model,
  compareModel,
  rates,
  showCurrency = false,
}) => {
  const intl = useIntl();

  const dateFormatter: LabelFormatter = (date) =>
    intl.formatDate(date, { year: "numeric", month: "short" });

  let EEreport;

  if (showCurrency) {
    EEreport = formatEnergyEfficiencyCurrencyReport(model, compareModel, rates);
  } else {
    EEreport = formatEnergyEfficiencyReport(model, compareModel);
  }

  const energySourcesUniq = unique(
    model[0].endUses.map((el) => el.energySource)
  );

  return (
    <ResponsiveContainer aspect={aspect}>
      <AreaChart data={EEreport} throttleDelay={0}>
        <XAxis
          dataKey="month"
          tickFormatter={dateFormatter}
          tickLine={false}
          axisLine={false}
          tick={{ fontSize }}
        ></XAxis>
        <YAxis tickFormatter={formatter} tickLine={false} tick={{ fontSize }}>
          <Label
            value={showCurrency ? "USD ($)" : "kBTU"}
            position="insideLeft"
            angle={-90}
            offset={yAxisOffset}
            fontSize={fontSize}
          />
        </YAxis>
        <Tooltip
          isAnimationActive={tooltipAnimationActive}
          content={
            <CustomTooltip
              labelFormatting={dateFormatter}
              formatting={(v) => {
                if (showCurrency) return `$${formatter(v)}`;
                return `${formatter(v)}  kBTU`;
              }}
            />
          }
        />
        <Legend
          height={40}
          verticalAlign="top"
          align="center"
          iconType="circle"
        />
        {[
          ...assignedEnergyColor(energySourcesUniq).map(
            ({ energySource, color }) => ({
              dataKey: energySource,
              color,
              name: `${energySourceName(energySource)} Baseload`,
            })
          ),
          ...[EnergyEndUse.SPACE_HEATING, EnergyEndUse.SPACE_COOLING].map(
            (e) => ({
              dataKey: e,
              color: energyEndUseColor(e),
              name: endUseName(e),
            })
          ),
          {
            dataKey: "ENERGY_EFFICIENCY",
            color: colors.green.main,
            name: "Energy Efficiency",
          },
        ].map(({ dataKey, color, name }, idx) => (
          <Area
            isAnimationActive={chartAnimationActive}
            legendType="rect"
            key={`perf-area-${idx}`}
            type="monotone"
            name={name}
            dataKey={dataKey}
            stackId="1"
            stroke={color}
            fill={color}
            fillOpacity={1}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default SeasonalPerformanceDelta;
