import { FormHelperText, TextField } from "@material-ui/core";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
} from "@material-ui/lab";
import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";

type ControlledAutocompleteProps<T> = Omit<
  AutocompleteProps<T, undefined, undefined, undefined>,
  "renderInput" | "onChange"
> & {
  placeholder?: string;
  onChange: (v: T | null) => void;
  label: string;
  errorMessage?: string;
};

export const ControlledAutocomplete = function <T extends {}>({
  onChange,
  label,
  errorMessage,
  ...props
}: ControlledAutocompleteProps<T>) {
  return (
    <>
      <MuiAutocomplete
        onChange={(_, v) => onChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            variant="outlined"
            error={!!errorMessage}
          />
        )}
        closeIcon={null}
        {...props}
      />
      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};

type AutocompleteControllerProps<T> = Omit<
  ControlledAutocompleteProps<T>,
  "value" | "onChange"
> & {
  name: string;
  control: Control;
  rules?: RegisterOptions;
};

const AutocompleteController = function <T extends {}>({
  name,
  control,
  rules = {},
  ...props
}: AutocompleteControllerProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ value, onChange }) => (
        <ControlledAutocomplete value={value} onChange={onChange} {...props} />
      )}
    />
  );
};

export default AutocompleteController;
