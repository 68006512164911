import React from "react";
import { SvgIcon } from "@material-ui/core";

function SvgChangeSmOpt(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.792 29.548" {...props}>
      <g paintOrder="markers fill stroke">
        <path
          d="M44.793 0L.001 29.548h44.792z"
          fillOpacity={0.3}
          style={{
            fontVariationSettings: "normal",
          }}
        />
        <path
          d="M14.136 20.22L0 29.545h14.136z"
          style={{
            fontVariationSettings: "normal",
          }}
        />
      </g>
    </svg>
  );
}

const ChangeSm = () => (
  <SvgIcon component={SvgChangeSmOpt} viewBox="0 5 50 24" fontSize="large" />
);

export default ChangeSm;
