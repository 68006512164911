import React from "react";
import {
  Area,
  AreaChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EnergyEndUse, EnergySource } from "../api/graphql";
import { endUseName, energyEndUseColor } from "../lib/endUse";
import { energySourceName } from "../lib/energySource";
import formatter from "../lib/formatter";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
  yAxisOffset,
} from "../lib/reporting/config";
import {
  EnergyEfficiency,
  formatProjectedChart,
} from "../lib/reporting/performanceChart";
import { TemperaturePerformance } from "../lib/types/Reports";
import unique from "../lib/unique";
import colors from "../ui/colors";
import assignedEnergyColor from "./assignedEnergyColor";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  initialModel: TemperaturePerformance[];
  comparisonModel: TemperaturePerformance[];
}

interface SeriesData {
  dataKey: EnergySource | EnergyEndUse | EnergyEfficiency;
  color: string;
  name: string;
}

const PerformanceChartDelta: React.FC<Props> = ({
  initialModel,
  comparisonModel,
  aspect,
}) => {
  const formattedData = formatProjectedChart(initialModel, comparisonModel);
  const energySources = unique(
    initialModel.flatMap(({ endUses }) =>
      endUses.map(({ energySource }) => energySource)
    )
  );

  // Create chart data specifically for the view
  const series: SeriesData[] = [
    ...assignedEnergyColor(energySources).map(({ energySource, color }) => ({
      dataKey: energySource,
      color,
      name: `${energySourceName(energySource)} Baseload`,
    })),
    ...[EnergyEndUse.SPACE_HEATING, EnergyEndUse.SPACE_COOLING].map((e) => ({
      dataKey: e,
      color: energyEndUseColor(e),
      name: endUseName(e),
    })),
    {
      dataKey: EnergyEfficiency.ENERGY_EFFICIENCY,
      color: colors.green.main,
      name: "Energy Efficiency",
    },
  ];

  return (
    <ResponsiveContainer aspect={aspect}>
      <AreaChart data={formattedData} throttleDelay={0}>
        <XAxis
          dataKey="airTemp"
          tickLine={false}
          axisLine={false}
          tick={{ fontSize }}
        >
          <Label
            value="Avg. Daily Temperature (°F)"
            position="insideBottom"
            offset={-3}
            fontSize={fontSize}
          />
        </XAxis>
        <YAxis tickFormatter={formatter} tickLine={false} tick={{ fontSize }}>
          <Label
            value="kBTU / Month"
            position="insideLeft"
            angle={-90}
            offset={yAxisOffset}
            fontSize={fontSize}
          />
        </YAxis>
        <Tooltip
          isAnimationActive={tooltipAnimationActive}
          content={
            <CustomTooltip
              formatting={(v) => formatter(v) + " kBTU"}
              labelFormatting={(v) => formatter(v) + " °F"}
            />
          }
        />
        <Legend
          height={40}
          verticalAlign="top"
          align="center"
          iconType="circle"
        />
        {series.map(({ dataKey, color, name }, idx) => (
          <Area
            isAnimationActive={chartAnimationActive}
            legendType="rect"
            key={`perf-area-${idx}`}
            type="monotone"
            name={name}
            dataKey={dataKey}
            stackId="1"
            stroke={color}
            fill={color}
            fillOpacity={1}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default PerformanceChartDelta;
