import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { financialModels } from "../lib/endpoints";
import { defaultEnergySourceUnit } from "../lib/energySource";
import BackButton from "../ui/BackButton";
import Loading from "../ui/Loading";
import ProductCopy from "../ui/ProductCopy";
import FinancialModelDashboard from "./FinancialModelDashboard";
import { FinancialModelQueryDocument } from "./FinancialModelQuery.generated";

interface Props {
  financialModelId: string;
  buildingId: string;
}

export interface InvestmentState {
  fromNew: boolean;
}

const FinancialModelContainer: FC<Props> = ({
  financialModelId,
  buildingId,
}) => {
  const { state } = useLocation<InvestmentState>();
  const { data, loading, error } = useQuery(FinancialModelQueryDocument, {
    variables: { financialModelId },
    fetchPolicy: !!state && state.fromNew ? "cache-only" : "network-only",
  });

  if (error) throw error;
  if (loading || !data) return <Loading variant="circle" />;

  const { financialModel } = data;
  const {
    calibratedModel,
    adjustedModel,
    customRates,
    defaultRates,
    energyEfficiencyDepths,
    cashFlows,
    weatherSource,
  } = financialModel;

  return (
    <>
      <BackButton
        to={financialModels({ buildingId })}
        label={`Back to ${ProductCopy.FINANCIAL_MODELS}`}
      />
      <FinancialModelDashboard
        buildingId={buildingId}
        financialModelId={financialModelId}
        calibratedModelId={calibratedModel.id}
        term={financialModel.term}
        discountRate={financialModel.discountRate}
        projectCost={financialModel.projectCost.value}
        projectUncertainty={financialModel.projectUncertainty}
        defaultMetadata={{
          name: financialModel.name,
          notes: financialModel.notes,
        }}
        calibratedModelData={calibratedModel}
        adjustedModelActualWeatherData={calibratedModel.adjustedModels}
        adjustedModelNormalWeatherData={calibratedModel.adjustedModels}
        defaultRates={defaultRates}
        customRates={customRates ?? defaultRates}
        usingCustomScenario={!adjustedModel}
        usingCustomRates={!!customRates}
        selectedModelId={adjustedModel?.id || undefined}
        customDepths={{
          sources: energyEfficiencyDepths.map((ed) => ({
            energySource: ed.energySource,
            unit: defaultEnergySourceUnit(ed.energySource),
            depth: ed.depth,
          })),
        }}
        expenses={cashFlows}
        weatherSource={weatherSource}
      />
    </>
  );
};

export default FinancialModelContainer;
