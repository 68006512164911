import { Box, Button } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { EnergySource, EnergyUnitType } from "../api/graphql";
import displayUnit from "../lib/displayUnit";
import { buildingDeltaMeterReading } from "../lib/endpoints";
import { energySourceName } from "../lib/energySource";
import sortChronological from "../lib/sortChronological";
import targetUnitConverter from "../lib/targetUnitConverter";
import DataTable from "../ui/DataTable";
import { DeltaMeterQuery } from "./DeltaMeterQuery.generated";

type DeltaMeterQueryReadingEdgeNode = NonNullable<
  DeltaMeterQuery["building"]["deltaMeter"]
>["readings"]["edges"][number]["node"];

export interface Props {
  buildingId: string;
  data: DeltaMeterQueryReadingEdgeNode[];
  timeZone: string;
}

type Interval = { start: string; end: string };

interface DataPoint {
  id: string;
  energySource: EnergySource;
  interval: Interval;
  baseline: number;
  netAdjustment: number;
  actual: number;
  energyEfficiency: number;
}

export const reduceTableData = (
  data: DeltaMeterQueryReadingEdgeNode[],
  unitConverter: (n: number, unit: EnergyUnitType) => number
): DataPoint[] => {
  return data
    .sort((a, b) =>
      sortChronological(a.interval.start, b.interval.start, "asc")
    )
    .map((d) => ({
      id: d.id,
      energySource: d.energySource,
      netAdjustment:
        unitConverter(d.adjustedBaseline, d.unit) -
        unitConverter(d.baseline, d.unit),
      interval: {
        start: d.interval.start,
        end: d.interval.end,
      },
      baseline: unitConverter(d.baseline, d.unit),
      actual: unitConverter(d.actual, d.unit),
      energyEfficiency:
        unitConverter(d.adjustedBaseline, d.unit) -
        unitConverter(d.actual, d.unit),
    }));
};

const DeltaMeterReadingsTable: React.FC<Props> = ({
  data,
  timeZone,
  buildingId,
}) => {
  const targetUnit = EnergyUnitType.KBTU;
  const converter = targetUnitConverter(targetUnit);
  const { formatNumber, formatDate } = useIntl();
  const history = useHistory();

  const columns = [
    {
      name: "energySource",
      label: "Energy source",
      options: {
        customBodyRender: (v: EnergySource) => energySourceName(v),
      },
    },
    {
      name: "interval",
      label: "Reading period",
      options: {
        customBodyRender: ({ start, end }: Interval) =>
          `${formatDate(start, { timeZone })} — ${formatDate(end, {
            timeZone,
          })}`,
        sortCompare: (order: "asc" | "desc") => (
          obj1: { data: Interval },
          obj2: { data: Interval }
        ) => sortChronological(obj1.data.start, obj2.data.start, order),
      },
    },
    {
      name: "baseline",
      label: `Baseline (${displayUnit(targetUnit)})`,
      options: {
        customBodyRender: (v: number) => formatNumber(Math.round(v)),
      },
    },
    {
      name: "netAdjustment",
      label: `Net adjustment (${displayUnit(targetUnit)})`,
      options: {
        customBodyRender: (v: number) => formatNumber(Math.round(v)),
      },
    },
    {
      name: "actual",
      label: `Utility use (${displayUnit(targetUnit)})`,
      options: {
        customBodyRender: (v: number) => formatNumber(Math.round(v)),
      },
    },
    {
      name: "energyEfficiency",
      label: `Energy efficiency (${displayUnit(targetUnit)})`,
      options: {
        customBodyRender: (v: number) => formatNumber(Math.round(v)),
      },
    },
    {
      name: "id",
      label: "",
      options: {
        customHeadLabelRender: () => "", // Hide the header
        customBodyRender: (id: string) => (
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() =>
                history.push(
                  buildingDeltaMeterReading({
                    buildingId,
                    readingId: id,
                  })
                )
              }
            >
              View
            </Button>
          </Box>
        ),
      },
    },
  ];

  return (
    <DataTable
      data={reduceTableData(data, converter)}
      columns={columns}
      options={{
        pagination: true,
        rowsPerPage: 10,
        rowsPerPageOptions: [],
      }}
    />
  );
};

export default DeltaMeterReadingsTable;
