import { Avatar, styled } from "@material-ui/core";
import queryString from "query-string";
import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface Props {
  diameter: number;
  streetAddress: string;
  locality: string;
  region: string;
  postalCode: string;
  markerSize?: "small" | "large";
}

const StyledAvatar = styled(Avatar)({
  height: ({ height }: { width: number; height: number }) => height,
  width: ({ width }: { width: number; height: number }) => width,
});

const MapsAvatar: FC<Props> = ({
  diameter,
  streetAddress,
  locality,
  region,
  postalCode,
  markerSize = "large",
}) => {
  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";
  const linkUrl = "http://maps.google.com/maps/search/";
  const fullAddress = `${streetAddress}, ${locality} ${region} ${postalCode}`;
  const mapDiameter = Math.round(diameter * 1.5);
  const mapsMarkerSize = markerSize === "large" ? "med" : "small";

  const sourceUrl = queryString.stringifyUrl({
    url: baseUrl,
    query: {
      center: fullAddress,
      markers: `size:${mapsMarkerSize}|${fullAddress}`,
      zoom: "10",
      size: `${mapDiameter}x${mapDiameter}`,
      key: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
    },
  });

  const outgoingUrl = queryString.stringifyUrl({
    url: linkUrl,
    query: {
      q: fullAddress,
    },
  });

  return (
    <Link to={{ pathname: outgoingUrl }} target="_blank">
      <StyledAvatar
        width={diameter}
        height={diameter}
        alt="map-location"
        src={sourceUrl}
      />
    </Link>
  );
};

export default MapsAvatar;
