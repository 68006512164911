import {
  Box,
  Divider,
  FormHelperText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { BlockRounded } from "@material-ui/icons";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { updatedDiff } from "deep-object-diff";
import { GraphQLError } from "graphql";
import React from "react";
import { useForm } from "react-hook-form";
import { BuildingModelParametersFieldsFragment } from "../api/fragments/BuildingModelParametersFields.generated";
import { EnergySource } from "../api/graphql";
import BulbLg from "../assets/BulbLg";
import BulbMd from "../assets/BulbMd";
import BulbSm from "../assets/BulbSm";
import ChangeLg from "../assets/ChangeLg";
import ChangeMd from "../assets/ChangeMd";
import ChangeSm from "../assets/ChangeSm";
import { energySourceName } from "../lib/energySource";
import { calibratedModelName as makeModelName } from "../lib/modelName";
import {
  descriptionError,
  longTextError,
  nameError,
  textFieldLimits,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import { ButtonDetails } from "../ui/ButtonGroupSelect";
import { ButtonsWithSliderController } from "../ui/ButtonsWithSlider";
import FormDialog from "../ui/FormDialog";
import InputChars from "../ui/InputChars";
import { SliderWithLabelController } from "../ui/SliderWithLabel";
import { TextField } from "../ui/TextField";

export interface Props {
  onSubmit: (
    values: Omit<Fields, "adjustments"> & { adjustments: Partial<Adjustments> }
  ) => Promise<void>;
  onCancel: () => void;
  calibratedModelName: string;
  calibratedParameters: BuildingModelParametersFieldsFragment;
  error?: GraphQLError;
}

interface Adjustments {
  shellHeatLoss: number;
  spaceHeatingElectric: number;
  spaceHeatingGas: number;
  spaceHeatingPropane: number;
  spaceHeatingFuelOil2: number;
  spaceCoolingElectric: number;
  spaceCoolingGas: number;
  spaceCoolingWater: number;
  spaceCoolingPropane: number;
  lighting: number;
  hotWaterElectric: number;
  hotWaterGas: number;
  hotWaterPropane: number;
  hotWaterFuelOil2: number;
  hotWaterVolume: number;
  interiorElectric: number;
  exteriorElectric: number;
  heatingSetTemp: number;
  coolingSetTemp: number;
}

export interface Fields {
  adjustments: Adjustments;
  modelName: string;
  modelDescription: string;
  modelNotes: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  buttonWidth: {
    minWidth: spacing(12),
  },
  dividerPadding: {
    marginBottom: spacing(2),
  },
}));

const validations = ({ modelName, modelDescription, modelNotes }: Fields) => ({
  modelName: nameError(modelName),
  modelDescription: descriptionError(modelDescription),
  modelNotes: longTextError(modelNotes),
});

const maxPercentage = 0.5;
const improvementButtons: ButtonDetails[] = [
  {
    icon: <ChangeSm />,
    label: "Small change",
    value: 0.1,
  },
  {
    icon: <ChangeMd />,
    label: "Medium change",
    value: 0.25,
  },
  {
    icon: <ChangeLg />,
    label: "Large change",
    value: 0.35,
  },
  {
    icon: <BlockRounded fontSize="large" />,
    label: "No change",
    value: 0,
  },
];

const lightingButtons = (v: number): ButtonDetails[] => [
  {
    icon: <BulbSm />,
    label: "Small change",
    value: v * 0.7,
  },
  {
    icon: <BulbMd />,
    label: "Medium change",
    value: v * 0.5,
  },
  {
    icon: <BulbLg />,
    label: "Large change",
    value: v * 0.3,
  },
  {
    icon: <BlockRounded fontSize="large" />,
    label: "No change",
    value: v * 1,
  },
];

const heatingSourceAdjustments: [EnergySource, keyof Adjustments][] = [
  [EnergySource.ELECTRICITY, "spaceHeatingElectric"],
  [EnergySource.NATURAL_GAS, "spaceHeatingGas"],
  [EnergySource.PROPANE, "spaceHeatingPropane"],
  [EnergySource.FUEL_OIL2, "spaceHeatingFuelOil2"],
];

const coolingSourceAdjustments: [EnergySource, keyof Adjustments][] = [
  [EnergySource.ELECTRICITY, "spaceCoolingElectric"],
  [EnergySource.NATURAL_GAS, "spaceCoolingGas"],
  [EnergySource.CHILLED_WATER, "spaceCoolingWater"],
  [EnergySource.PROPANE, "spaceCoolingPropane"],
];

const hotWaterSourceAdjustments: [EnergySource, keyof Adjustments][] = [
  [EnergySource.ELECTRICITY, "hotWaterElectric"],
  [EnergySource.NATURAL_GAS, "hotWaterGas"],
  [EnergySource.PROPANE, "hotWaterPropane"],
  [EnergySource.FUEL_OIL2, "hotWaterFuelOil2"],
];

const decimalToPercent = (v: number): string => `${Math.round(v * 100)}%`;

const NewAdjustedModelDialog: React.FC<Props> = ({
  onCancel,
  calibratedModelName,
  calibratedParameters,
  error,
  ...props
}) => {
  const [formError, setFormError] = React.useState("");
  const { dividerPadding } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultValues: Fields = {
    adjustments: {
      shellHeatLoss: 0,
      spaceHeatingElectric: 0,
      spaceHeatingGas: 0,
      spaceHeatingPropane: 0,
      spaceHeatingFuelOil2: 0,
      spaceCoolingElectric: 0,
      spaceCoolingGas: 0,
      spaceCoolingWater: 0,
      spaceCoolingPropane: 0,
      hotWaterElectric: 0,
      hotWaterGas: 0,
      hotWaterPropane: 0,
      hotWaterFuelOil2: 0,
      hotWaterVolume: 0,
      interiorElectric: 0,
      exteriorElectric: 0,
      lighting: calibratedParameters.lighting,
      heatingSetTemp: calibratedParameters.spaceHeating.setTemp,
      coolingSetTemp: calibratedParameters.spaceCooling.setTemp,
    },
    modelName: "",
    modelDescription: "",
    modelNotes: "",
  };
  const {
    watch,
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<Fields>({
    defaultValues,
    resolver: validatorsResolver(validations),
  });

  const heatingSource = heatingSourceAdjustments.find(([source]) =>
    calibratedParameters.spaceHeating.sources.find(
      (s) => s.energySource === source && s.portion > 0.5
    )
  );
  const coolingSource = coolingSourceAdjustments.find(([source]) =>
    calibratedParameters.spaceCooling.sources.find(
      (s) => s.energySource === source && s.portion > 0.5
    )
  );
  const hotWaterSource = hotWaterSourceAdjustments.find(([source]) =>
    calibratedParameters.hotWater.sources.find(
      (s) => s.energySource === source && s.portion > 0.5
    )
  );

  const onSubmit = async ({ adjustments, ...values }: Fields) => {
    const updatedAdjustments = updatedDiff(
      defaultValues.adjustments,
      adjustments
    );

    if (Object.keys(updatedAdjustments).length === 0) {
      setFormError("You must make at least one adjustment to the model.");
      return;
    } else {
      setFormError("");
    }

    await props.onSubmit({
      adjustments: updatedAdjustments,
      ...values,
    });
  };

  return (
    <FormDialog
      title={`Create scenario from ${makeModelName(calibratedModelName)}`}
      submitName="Save"
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      error={error}
      isSubmitting={isSubmitting}
      maxWidth="md"
    >
      <Box display="flex" flexDirection={matches ? "column" : "row"}>
        <Box
          display="flex"
          flexDirection="column"
          flexBasis={0}
          flexGrow={1}
          px={2}
          position={matches ? "static" : "fixed"}
          width={matches ? "100%" : 400}
        >
          <TextField
            name="modelName"
            inputRef={register}
            error={errors.modelName?.message}
            type="text"
            label="Model name"
            required
            size="small"
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.name.max,
                minLength: textFieldLimits.name.min,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.name.max}
            current={watch("name")}
          />
          <TextField
            name="modelDescription"
            inputRef={register}
            error={errors.modelDescription?.message}
            type="text"
            label="Description"
            required
            size="small"
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.description.max,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.description.max}
            current={watch("description")}
          />
          <TextField
            name="modelNotes"
            inputRef={register}
            error={errors.modelNotes?.message}
            type="text"
            label="Notes"
            size="small"
            multiline
            rows={4}
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.notes.max,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.notes.max}
            current={watch("notes")}
          />
        </Box>
        <Divider orientation="vertical" />
        <Box
          display="flex"
          flexDirection="column"
          flexBasis={0}
          flexGrow={1}
          px={2}
          alignItems="flex-end"
        >
          <Box
            display="flex"
            width={matches ? "100%" : "50%"}
            flexDirection="column"
          >
            {formError && <FormHelperText error>{formError}</FormHelperText>}
            <Typography>
              How much will the building envelope performance improve?
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <em>(Examples include insulation, windows, and ventilation)</em>
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="adjustments.shellHeatLoss"
                control={control}
                buttonConfig={improvementButtons}
                formatter={decimalToPercent}
                min={0}
                max={maxPercentage}
                step={0.05}
              />
            </Box>
            <Divider className={dividerPadding} />

            {heatingSource && (
              <>
                <Typography>
                  How much will the {energySourceName(heatingSource[0])} space
                  heating system efficiency improve?
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <em>
                    (Examples include furnaces, ducts, ventilation, and
                    equipment)
                  </em>
                </Typography>
                <Box p={2}>
                  <ButtonsWithSliderController
                    name={`adjustments.${heatingSource[1]}`}
                    control={control}
                    buttonConfig={improvementButtons}
                    formatter={decimalToPercent}
                    min={0}
                    max={maxPercentage}
                    step={0.05}
                  />
                </Box>
                <Divider className={dividerPadding} />
              </>
            )}

            {coolingSource && (
              <>
                <Typography>
                  How much will the {energySourceName(coolingSource[0])} space
                  cooling system efficiency improve?
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <em>
                    (Examples include windows, ducts, ventilation, and
                    equipment)
                  </em>
                </Typography>
                <Box p={2}>
                  <ButtonsWithSliderController
                    name={`adjustments.${coolingSource[1]}`}
                    control={control}
                    buttonConfig={improvementButtons}
                    formatter={decimalToPercent}
                    min={0}
                    max={maxPercentage}
                    step={0.05}
                  />
                </Box>
                <Divider className={dividerPadding} />
              </>
            )}

            <Typography>
              Specify the new interior lighting efficiency.
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="adjustments.lighting"
                control={control}
                buttonConfig={lightingButtons(
                  defaultValues.adjustments.lighting
                )}
                caption="Watts / Ft²"
                min={0.2}
                max={0.6}
                step={0.05}
                formatter={(v) => v.toFixed(2)}
              />
            </Box>
            <Divider className={dividerPadding} />

            {hotWaterSource && (
              <>
                <Typography>
                  How much improvement will be made to{" "}
                  {energySourceName(hotWaterSource[0])} hot water system
                  efficiency?
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <em>(Related to burners and tank loss)</em>
                </Typography>
                <Box p={2}>
                  <ButtonsWithSliderController
                    name={`adjustments.${hotWaterSource[1]}`}
                    control={control}
                    buttonConfig={improvementButtons}
                    formatter={decimalToPercent}
                    min={0}
                    max={maxPercentage}
                    step={0.05}
                  />
                </Box>
                <Divider className={dividerPadding} />
              </>
            )}

            <Typography>
              How much reduction will be made to hot water consumption
              (gallons)?
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <em>(Related to showerhead and faucet efficiency)</em>
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="adjustments.hotWaterVolume"
                control={control}
                buttonConfig={improvementButtons}
                formatter={decimalToPercent}
                min={0}
                max={maxPercentage}
                step={0.05}
              />
            </Box>
            <Divider className={dividerPadding} />

            <Typography>
              How much reduction will be made to the interior{" "}
              {energySourceName(EnergySource.ELECTRICITY)} baseload?
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <em>
                (Examples include computers, equipment, and recirculation fans)
              </em>
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="adjustments.interiorElectric"
                control={control}
                buttonConfig={improvementButtons}
                formatter={decimalToPercent}
                min={0}
                max={maxPercentage}
                step={0.05}
              />
            </Box>
            <Divider className={dividerPadding} />

            <Typography>
              How much reduction will be made to the exterior{" "}
              {energySourceName(EnergySource.ELECTRICITY)} baseload?
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <em>
                (In systems that may include lighting and underground garage
                fans)
              </em>
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="adjustments.exteriorElectric"
                control={control}
                buttonConfig={improvementButtons}
                formatter={decimalToPercent}
                min={0}
                max={maxPercentage}
                step={0.05}
              />
            </Box>
            <Divider className={dividerPadding} />

            <Typography>
              What will the building’s average heating and cooling set point be?
            </Typography>
            <Box p={2}>
              <Typography variant="body2">
                <em>Heating set point</em>
              </Typography>
              <SliderWithLabelController
                icon={<WhatshotIcon color="primary" fontSize="small" />}
                name="adjustments.heatingSetTemp"
                control={control}
                min={60}
                max={90}
                formatter={(v) => `${Math.round(v)}°F`}
              />
              <Typography variant="body2">
                <em>Cooling set point</em>
              </Typography>
              <SliderWithLabelController
                icon={<AcUnitIcon color="primary" fontSize="small" />}
                name="adjustments.coolingSetTemp"
                control={control}
                min={60}
                max={90}
                formatter={(v) => `${Math.round(v)}°F`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </FormDialog>
  );
};

export default NewAdjustedModelDialog;
