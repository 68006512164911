import { useApolloClient } from "@apollo/client";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { CurrentUserModelFragment } from "./api/fragments/CurrentUserModel.generated";
import { OrganizationModelFragment } from "./api/fragments/OrganizationModel.generated";
import useLazyMeQuery from "./api/useLazyMeQuery";
import { isOrganizationAdmin } from "./lib/permissions";
import useToggleKeyPress from "./lib/useToggleKeyPress";

interface Props {
  children: (props: {
    getCurrentUser: () => void;
    clearCurrentUser: () => void;
  }) => React.ReactNode;
}

export interface SessionContext {
  user: CurrentUserModelFragment | null;
  organization?: { id: string; name: string } | null;
  loggingIn: boolean;
  isOrgAdmin: boolean;
  isSuperuser: boolean;
  refetch: () => Promise<void>;
  setUser: (u: CurrentUserModelFragment) => void;
  setOrganization: (o?: OrganizationModelFragment) => void;
}

export const initialState = {
  user: null,
  loggingIn: false,
  isOrgAdmin: false,
  isSuperuser: false,
  refetch: async () => undefined,
  setUser: () => null,
  setOrganization: () => null,
};

export const Session = React.createContext<SessionContext>(initialState);

export const useSessionManager = () => useContext(Session);

const currentOrganizationIdKey = "currentOrganizationId";
const currentOrganizationNameKey = "currentOrganizationName";

function identify(me: CurrentUserModelFragment | null | undefined): void {
  if (me) {
    (window as any).analytics?.identify(me.id, {
      name: me.name,
      email: me.email,
    });
  }
  if (me?.organization) {
    (window as any).analytics?.group(me.organization.id, {
      name: me.organization.name,
    });
  }
}

const SessionManager: React.FC<Props> = ({ children }) => {
  const client = useApolloClient();
  const [user, setUser] = useState<SessionContext["user"]>(null);
  const [organization, setOrganization] = useState<
    SessionContext["organization"]
  >();
  const [loggingIn, setLoggingIn] = useState<boolean>(true);
  const getMe = useLazyMeQuery();
  const keysPressed = useToggleKeyPress(["Shift", "~"]);

  // things we do when the organization changes
  const handleOrganization = (org: SessionContext["organization"]): void => {
    setOrganization(org);
    if (org) {
      window.sessionStorage.setItem(currentOrganizationIdKey, org.id);
      window.sessionStorage.setItem(currentOrganizationNameKey, org.name);
    } else {
      window.sessionStorage.removeItem(currentOrganizationIdKey);
      window.sessionStorage.removeItem(currentOrganizationNameKey);
    }
  };

  // things we do when user arrives (authenticates or restores session)
  const getCurrentUser = useCallback(async () => {
    setLoggingIn(true);
    const { data } = await getMe();
    identify(data?.me ?? null);
    if (data?.me) {
      setUser(data.me);

      // we trust this value only enough to render a UI around it.
      // - if a user tampers with this, or has since been removed from the org,
      //   the backend will tell us with a forbidden error.
      // - if a user has switched accounts, this should not exist
      const id = window.sessionStorage.getItem(currentOrganizationIdKey);
      const name = window.sessionStorage.getItem(currentOrganizationNameKey);
      const org = id && name ? { id, name } : data.me.organization;
      handleOrganization(org);
    }
    setLoggingIn(false);
  }, [getMe]);

  // things we do when user leaves (logout)
  const clearCurrentUser = useCallback(() => {
    setLoggingIn(false);
    setUser((currentUser) => {
      // when we clear an existing user, we want to delete the apollo store
      if (currentUser != null) {
        client.clearStore();
      }
      return null;
    });
    handleOrganization(undefined);
  }, [client]);

  const isOrgAdmin = useMemo(
    () =>
      organization
        ? isOrganizationAdmin(user, organization.id)
        : !!user?.superuser,
    [organization, user]
  );

  return (
    <Session.Provider
      value={{
        user,
        organization,
        loggingIn,
        isOrgAdmin,
        isSuperuser: user ? user.superuser && keysPressed : false,
        refetch: getCurrentUser,
        setUser: (u: CurrentUserModelFragment) => {
          setUser(u);
          handleOrganization(u.organization);
        },
        setOrganization: handleOrganization,
      }}
    >
      {children({ getCurrentUser, clearCurrentUser })}
    </Session.Provider>
  );
};

export default SessionManager;
