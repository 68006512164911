import { Box, Typography } from "@material-ui/core";
import { Legend, LegendItem, LegendLabel } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import { Circle, Line } from "@visx/shape";
import React, { ReactElement } from "react";

export type ChartLegendItem = (
  | ChartLegendLineItem
  | ChartLegendCircleItem
  | ChartLegendCustomItem
) & {
  label: string;
  color?: string;
  colorOpacity?: number;
};

type ChartLegendLineItem = {
  shape: "line";
  strokeDasharray?: string;
};

type ChartLegendCircleItem = {
  shape: "circle";
};

type ChartLegendCustomItem = {
  shape: (size: number) => React.ReactElement;
};

interface Props {
  items: ChartLegendItem[];
}

const legendShapeSize = 16;

const ChartLegend: React.FC<Props> = ({ items }) => {
  const scale = scaleOrdinal<string, ReactElement>({
    domain: items.map((i) => i.label),
    range: items.map((i) =>
      i.shape === "line" ? (
        <Line
          from={{ x: 0, y: legendShapeSize / 2 }}
          to={{ x: legendShapeSize, y: legendShapeSize / 2 }}
          strokeWidth={3}
          stroke={i.color}
          strokeOpacity={i.colorOpacity}
          pointerEvents="none"
          strokeDasharray={i.strokeDasharray}
        />
      ) : i.shape === "circle" ? (
        <Circle
          cx={legendShapeSize / 2}
          cy={legendShapeSize / 2}
          r={legendShapeSize / 2}
          fill={i.color}
          fillOpacity={i.colorOpacity}
        />
      ) : (
        i.shape(legendShapeSize)
      )
    ),
  });

  return (
    <Legend scale={scale}>
      {(labels) => (
        <Box display="flex">
          {labels.map((label, idx) => (
            <Box pr={2} key={idx}>
              <LegendItem flexDirection="row" alignItems="center">
                <Box pr={1}>
                  <svg width={legendShapeSize} height={legendShapeSize}>
                    {scale(label.datum)}
                  </svg>
                </Box>
                <LegendLabel>
                  <Typography variant="body2">{label.text}</Typography>
                </LegendLabel>
              </LegendItem>
            </Box>
          ))}
        </Box>
      )}
    </Legend>
  );
};

export default ChartLegend;
