enum ProductCopy {
  ENERGY_RM = "EnergyRM",
  DASHBOARD = "Dashboard",
  INSIGHTS = "Insights",
  ENERGY_ANALYTICS = "Energy analytics",
  UTILITY_METERS = "Utility meters",
  ENERGY_MODELS = "Energy models",
  FINANCIAL_MODELS = "Financial models",
  DELTAMETER = "DeltaMeter",
  TRANSACTIONS = "Transactions",
  USERS = "Users",
}

export default ProductCopy;
