import { useMutation } from "@apollo/client";
import { Box, Button, CardHeader, Grid, Typography } from "@material-ui/core";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { FormattedDate } from "react-intl";
import { useHistory } from "react-router-dom";
import { buildingInsights } from "../lib/endpoints";
import modelDescription from "../lib/modelDescription";
import modelName from "../lib/modelName";
import modelUserName from "../lib/modelUserName";
import useDeleteEntity from "../lib/useDeleteEntity";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import Card, { CardActions, CardContent } from "../ui/Card";
import DefaultModel from "../ui/DefaultModel";
import MetaData from "../ui/MetaData";
import { useToastContext } from "../ui/ToastProvider";
import AdjustedModelDetailFragment from "./AdjustedModelDetailFragment";
import { CalibratedModelQuery } from "./CalibratedModel.generated";
import { DeleteBuildingAdjustedModelDocument } from "./DeleteBuildingAdjustedModel.generated";
import EditModelDetailsDialog from "./EditModelDetailsDialog";
import { UpdateBuildingAdjustedModelDocument } from "./UpdateBuildingAdjustedModel.generated";

type Model = CalibratedModelQuery["calibratedModel"]["adjustedModels"][number];

export interface Props {
  buildingId: string;
  calibratedModelId: string;
  model: Model;
  onDelete: () => void;
}

const AdjustedModelCard: React.FC<Props> = ({
  buildingId,
  calibratedModelId,
  model,
  onDelete,
}) => {
  const [editing, showEditing] = useState<boolean>(false);
  const [update, { error: updateError }] = useMutation(
    UpdateBuildingAdjustedModelDocument
  );
  const [deleteAdjusted] = useDeleteEntity(DeleteBuildingAdjustedModelDocument);
  const history = useHistory();
  const createToast = useToastContext();

  return (
    <>
      <Card>
        <CardHeader
          title={<Typography variant="h6">{modelName(model)}</Typography>}
          action={<DefaultModel model={model} />}
        />
        <CardContent>
          <Grid container>
            <Grid item md={5} xs={12}>
              <Box pr={2}>
                <Typography>
                  <b>Description</b>
                </Typography>
                <Typography>{modelDescription(model)}</Typography>
                {model.notes && (
                  <>
                    <Typography>
                      <b>Notes</b>
                    </Typography>
                    <Typography>{model.notes}</Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Typography>
                <b>Adjustments</b>
              </Typography>
              <AdjustedModelDetailFragment adjustments={model.adjustments!} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <MetaData>
            <Typography variant="caption" color="textSecondary">
              Created: <FormattedDate value={new Date(model.createdAt)} /> by{" "}
              {modelUserName(model.user)}
            </Typography>
          </MetaData>
          <Box width={1} display="flex" justifyContent="flex-end">
            {!model.system && (
              <Box pr={1}>
                <Button
                  startIcon={<EditIcon />}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => showEditing(true)}
                >
                  Edit
                </Button>
              </Box>
            )}
            {!model.system && (
              <ButtonWithConfirmation
                header="Delete this scenario?"
                description="This action cannot be undone"
                onClick={async () => {
                  await deleteAdjusted(model, {
                    input: { buildingAdjustedModelId: model.id },
                  });
                  createToast("Scenario successfully deleted", "success");
                  onDelete();
                }}
                size="small"
                startIcon={<DeleteIcon />}
              >
                Delete
              </ButtonWithConfirmation>
            )}
            <Box pl={1}>
              <Button
                startIcon={<CompareArrowsIcon />}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push(buildingInsights({ buildingId }), {
                    calibratedModelId,
                    adjustedModelId: model.id,
                  });
                }}
              >
                Compare with calibrated
              </Button>
            </Box>
          </Box>
        </CardActions>
      </Card>
      {editing && (
        <EditModelDetailsDialog
          name={modelName(model)}
          description={model.description || ""}
          notes={model.notes || ""}
          onSubmit={async ({ name, description, notes }) => {
            await update({
              variables: {
                input: {
                  buildingAdjustedModelId: model.id,
                  name,
                  description,
                  notes,
                },
              },
            });
            createToast("Scenario successfully updated", "success");
            showEditing(false);
          }}
          onCancel={() => showEditing(false)}
          error={updateError?.graphQLErrors[0]}
        />
      )}
    </>
  );
};

export default AdjustedModelCard;
