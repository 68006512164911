import { SvgIcon } from "@material-ui/core";
import * as React from "react";

function SvgGasFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 33.174"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.626 33.048c-1.233-.341-2.747-1.036-4.237-1.944-.73-.444-1.177-.794-2.123-1.662-1.36-1.248-1.8-1.803-2.562-3.238-.66-1.242-.708-1.486-.704-3.58.005-2.368.214-3.515.925-5.07.616-1.344 1.572-2.585 3.92-5.081 1.917-2.04 2.674-2.91 3.444-3.962C9.463 6.909 10 5.706 11.282 1.797c.38-1.16.59-1.552.935-1.752.364-.21 1.014.26 3.64 2.634 3.936 3.56 5.473 5.149 6.988 7.228.987 1.353 2.204 3.498 2.724 4.798.98 2.448 1.426 4.792 1.431 7.508.004 2.289-.233 3.357-1.097 4.928-.839 1.525-2.575 3.29-4.225 4.293-2.095 1.273-4.672 2.021-5.662 1.642-.234-.09-.267-.133-.267-.355 0-.352.279-.826 1.184-2.013 1.876-2.458 2.354-3.466 2.477-5.225.11-1.582-.177-2.878-.957-4.306-.43-.787-1.391-2.224-1.952-2.917-.315-.39-.407-.464-.483-.389-.051.052-.206.474-.343.938-.532 1.805-1.01 2.726-1.774 3.428-.275.253-.692.555-.925.67-.395.197-.479.211-1.232.209-.671-.002-.932-.038-1.529-.208-.535-.152-.752-.186-.847-.13-.191.11-.38.44-.689 1.204l-.278.69v1.2c0 1.052.022 1.265.177 1.728.245.731.795 1.757 1.591 2.966 1.093 1.66 1.213 1.992.858 2.37-.233.248-.757.29-1.402.112z"
        paintOrder="markers fill stroke"
      />
    </svg>
  );
}

const ElectricFilledIcon = (props) => (
  <SvgIcon component={SvgGasFilled} viewBox="0 0 35 35" {...props} />
);

export default ElectricFilledIcon;
