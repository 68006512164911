import { useQuery } from "@apollo/client";
import {
  Box,
  Divider,
  Grid,
  List,
  styled,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FolderIcon from "@material-ui/icons/Folder";
import React, { FC } from "react";
import { FormattedDate } from "react-intl";
import EndUsesDeltaGrouped from "../BuildingInsights/EndUsesDeltaGrouped";
import BuildingModelStatus from "../BuildingModelStatus";
import digits from "../lib/digits";
import { buildingInsights } from "../lib/endpoints";
import modelDescription from "../lib/modelDescription";
import modelName from "../lib/modelName";
import modelUserName from "../lib/modelUserName";
import {
  SkeletonChart,
  SkeletonListItem,
  SkeletonTypography,
} from "../ui/Bones";
import colors from "../ui/colors";
import DashboardCard from "../ui/DashboardCard";
import Gaps from "../ui/Gaps";
import MetaData from "../ui/MetaData";
import Metric from "../ui/Metric";
import PlaceholderSegment from "../ui/PlaceholderSegment";
import {
  EnergyModelsDocument,
  EnergyModelsQuery,
} from "./EnergyModelsQuery.generated";
import ViewMoreLink from "./ViewMoreLink";

type Building = EnergyModelsQuery["building"];
type CalibratedModel = NonNullable<Building["calibratedModel"]>;
type AdjustedModel = NonNullable<CalibratedModel["adjustedModel"]>;

interface Props {
  buildingId: string;
}

const createInsightsMetrics = (
  squareFeet: number,
  comparisonModel: AdjustedModel
) => {
  const eeOpportunity = digits(comparisonModel.opportunity.energy.quantity, 3);
  const eeValue = digits(comparisonModel.opportunity.money.value, 3);
  const projectedEui = Math.round(
    comparisonModel.annualizedEnergy.quantity / squareFeet
  );

  return {
    eeOpportunity,
    eeValue,
    projectedEui,
  };
};

const EnergyModelsSkeleton: FC = () => {
  return (
    <DashboardCard label={<SkeletonTypography size="medium" />}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box px={2} pb={3}>
            <Grid container justify="space-between">
              <Grid item>
                <SkeletonTypography size="small" />
                <SkeletonTypography size="medium" />
              </Grid>
              <Grid item>
                <SkeletonTypography size="small" />
                <SkeletonTypography size="medium" />
              </Grid>
              <Grid item>
                <SkeletonTypography size="small" />
                <SkeletonTypography size="medium" />
              </Grid>
            </Grid>
          </Box>
          <Box p={2}>
            <SkeletonChart />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <List>
            <SkeletonListItem textOnly />
            <SkeletonListItem textOnly />
          </List>
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

const Metrics: FC<{ building: Building; adjustedModel: AdjustedModel }> = ({
  building,
  adjustedModel,
}) => {
  const { eeOpportunity, eeValue, projectedEui } = createInsightsMetrics(
    building.squareFeet,
    adjustedModel
  );

  return (
    <Grid container justify="space-between">
      <Grid item>
        <Metric
          value={eeOpportunity}
          subheader={"Energy efficiency opportunity"}
          labelFormatter={(v) => (
            <>
              {v}{" "}
              <Typography component="span" variant="body2">
                kBTU
              </Typography>
            </>
          )}
        />
      </Grid>
      <Grid item>
        <Metric
          value={eeValue}
          subheader={"Energy efficiency value"}
          labelFormatter={(v) => `$${v}`}
        />
      </Grid>
      <Grid item>
        <Metric
          value={projectedEui}
          subheader={"Projected EUI"}
          labelFormatter={(v) => (
            <>
              {v}{" "}
              <Typography component="span" variant="body2">
                kBTU/Ft²
              </Typography>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

const Dot = styled(FiberManualRecordIcon)({
  verticalAlign: "text-bottom",
});

const BaselineAndProjected: FC<{
  calibratedModel: CalibratedModel;
  adjustedModel: AdjustedModel;
}> = ({ calibratedModel, adjustedModel }) => {
  return (
    <Gaps spacing={2}>
      <Box>
        <Typography>
          <Dot fontSize="small" style={{ fill: colors.blue.main }} /> Baseline:{" "}
          {modelName(calibratedModel)}{" "}
          <BuildingModelStatus model={{ fit: calibratedModel.fit }} />
        </Typography>
        <MetaData>
          <Typography variant="caption" color="textSecondary">
            Created:{" "}
            <FormattedDate value={new Date(calibratedModel.createdAt)} /> by{" "}
            {modelUserName(calibratedModel.user)}
          </Typography>
        </MetaData>
        <Typography variant="body2" color="textSecondary">
          {modelDescription(calibratedModel)}
        </Typography>
      </Box>
      <Box>
        <Typography>
          <Dot fontSize="small" style={{ fill: colors.green.main }} />
          Projected: {modelName(adjustedModel)}
        </Typography>
        <MetaData>
          <Typography variant="caption" color="textSecondary">
            Created: <FormattedDate value={new Date(adjustedModel.createdAt)} />{" "}
            by {modelUserName(adjustedModel.user)}
          </Typography>
        </MetaData>
        <Typography variant="body2" color="textSecondary">
          {modelDescription(adjustedModel)}
        </Typography>
      </Box>
    </Gaps>
  );
};

const EnergyModels: FC<Props> = ({ buildingId }) => {
  const { data, loading, error } = useQuery(EnergyModelsDocument, {
    variables: { buildingId },
  });

  if (error) throw error;

  if (loading || !data) {
    return <EnergyModelsSkeleton />;
  }

  const { building } = data;
  const { calibratedModel } = building;

  // If we don't have any models, prompt for setup
  if (!calibratedModel || !calibratedModel.adjustedModel) {
    return (
      <DashboardCard label="Default energy model comparison">
        <PlaceholderSegment
          fadeText
          icon={FolderIcon}
          header="Energy models unavailable"
        />
      </DashboardCard>
    );
  }

  return (
    <DashboardCard label="Default energy model comparison">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box px={2} pb={3}>
            <Metrics
              building={building}
              adjustedModel={calibratedModel.adjustedModel}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <EndUsesDeltaGrouped
            aspect={2}
            initialModel={calibratedModel.annualizedEndUses}
            comparisonModel={calibratedModel.adjustedModel.annualizedEndUses}
            rates={data.building.rates}
            showLegend={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BaselineAndProjected
            calibratedModel={calibratedModel}
            adjustedModel={calibratedModel.adjustedModel}
          />
        </Grid>
      </Grid>
      <Divider />
      <ViewMoreLink path={buildingInsights({ buildingId })}>
        View energy analytics
      </ViewMoreLink>
    </DashboardCard>
  );
};

export default EnergyModels;
