export enum AppRouteParams {
  buildingId = ":id",
  organizationId = ":organizationId",
  calibratedModelId = ":calibratedModelId",
  utilityMeterId = ":utilityMeterId",
  financialModelId = ":financialModelId",
  deltaMeterId = ":deltaMeterId",
  deltaMeterReadingId = ":readingId",
  transactionId = ":transactionId",
  userId = ":userId",
  documentId = ":documentId",
}
