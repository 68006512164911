import * as Types from './graphql';

import { CurrentUserModelFragment } from './fragments/CurrentUserModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CurrentUserModelFragmentDoc } from './fragments/CurrentUserModel.generated';
export type MeQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQueryQuery = { me: Types.Maybe<(
    { __typename: 'User' }
    & CurrentUserModelFragment
  )> };


export const MeQueryDocument: DocumentNode<MeQueryQuery, MeQueryQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MeQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentUserModel"}}]}}]}},...CurrentUserModelFragmentDoc.definitions]};