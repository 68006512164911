import { UtilityReadingLockReason } from "../api/graphql";

const lockedReason = (reason: UtilityReadingLockReason): string => {
  switch (reason) {
    case UtilityReadingLockReason.DELTA_METER_READING:
      return "A DeltaMeter reading refers to this data";
    case UtilityReadingLockReason.REFERENCE_PERIOD:
      return "Building models refer to this data";
  }
};

export default lockedReason;
