import { SvgIcon } from "@material-ui/core";
import * as React from "react";

function SvgElectricFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 33.867 33.867"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.612 33.509c-.273-.142-.456-.472-.4-.723.073-.336 1.107-2.15 4.577-8.033 2.363-4.005 3.252-5.571 3.203-5.643-.063-.094-.597-.12-4.322-.206-4.25-.098-4.857-.146-5.173-.408-.194-.16-.127-.412.346-1.294 1.415-2.639 8.468-14.303 9.54-15.778.164-.225.37-.434.476-.482.161-.074.855-.085 5.158-.08 5.994.007 6.134.017 6.43.495.09.144.063.205-.85 1.868-.52.945-1.66 2.944-2.535 4.443-2.12 3.634-2.38 4.109-2.275 4.168.142.08.918.116 4.357.204 3.613.092 4.234.135 4.66.327.236.106.283.154.3.311a.594.594 0 01-.106.372c-.167.246-10.576 9.714-14.271 12.98-6.103 5.394-8.373 7.348-8.732 7.52-.14.067-.186.062-.383-.041zm4.114-5.209c0-.031-.028-.057-.062-.057s-.062.026-.062.057.028.057.062.057.062-.026.062-.057z"
        paintOrder="markers fill stroke"
      />
    </svg>
  );
}
const ElectricFilledIcon = (props) => (
  <SvgIcon component={SvgElectricFilled} viewBox="0 0 35 35" {...props} />
);

export default ElectricFilledIcon;
