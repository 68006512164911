import { Grid } from "@material-ui/core";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import React from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

type Props = {};

const NoCalibratedModelsPlaceholder: React.FC<Props> = () => {
  return (
    <Grid item xs={12}>
      <PlaceholderContainer>
        <PlaceholderSegment
          icon={ShowChartIcon}
          header="Energy models required"
          subheader="Financial analysis can be performed after the building has been modeled. Generate a model to continue."
        />
      </PlaceholderContainer>
    </Grid>
  );
};

export default NoCalibratedModelsPlaceholder;
