type RGB = [number, number, number];

/**
 * `color` is a hex code in the format `#rrggbb`
 * `offset` is a value from 0 to 1
 */
interface Stop {
  color: string;
  offset: number;
}

// assumes a well-formed hex color string
const hexToRGB = (hex: string): RGB =>
  hex
    .replace("#", "")
    .match(/../g)
    ?.map((s) => parseInt(s, 16)) as RGB;

// assumes rgb array values between 0 and 255
const RGBToHex = (rgb: RGB): string =>
  `#${rgb
    .map((n) => n.toString(16))
    .map((s) => s.padStart(2, "0"))
    .join("")}`;

// given two hex color codes, returns a function that generates the transition
// color at any point between 0 (start) and 1 (stop).
const gradient = (startColor: string, stopColor: string) => {
  const start = hexToRGB(startColor);
  const stop = hexToRGB(stopColor);

  return (position: number): string =>
    RGBToHex(
      start.map((n, i) => Math.round(n + (stop[i]! - n) * position)) as RGB
    );
};

// given a sequence of any number of hex color code stops, returns a function that
// generates the transition color at any point between 0 (start) and 1 (stop)
const generateTransitions = (stops: Stop[]) => {
  if (stops[0].offset !== 0) throw new Error(`missing offset=0 stop`);
  if (stops[stops.length - 1].offset !== 1)
    throw new Error(`missing offset=1 stop`);

  const gradients: {
    color: (position: number) => string;
    range: [number, number];
  }[] = [];
  for (let i = 0; i < stops.length - 1; i++) {
    gradients.push({
      range: [stops[i].offset, stops[i + 1].offset],
      color: gradient(stops[i].color, stops[i + 1].color),
    });
  }

  return (position: number) => {
    // find gradient for this position
    const { range, color } = gradients.find(
      ({ range }) => range[0] <= position && position <= range[1]
    )!;
    // find color for position within gradient
    return color((position - range[0]) / (range[1] - range[0]));
  };
};

export default generateTransitions;
