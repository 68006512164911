import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CalibratedModelScoreQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CalibratedModelScoreQuery = { calibratedModel: { __typename: 'BuildingCalibratedModel', id: string, score: number, scoreStats: Array<{ __typename: 'BuildingCalibratedModel_EnergySourceScore', energySource: Types.EnergySource, r2: number, cvrmse: number, nmbe: number, yMean: number }> } };


export const CalibratedModelScoreDocument: DocumentNode<CalibratedModelScoreQuery, CalibratedModelScoreQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CalibratedModelScore"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"calibratedModel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"score"}},{"kind":"Field","name":{"kind":"Name","value":"scoreStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"energySource"}},{"kind":"Field","name":{"kind":"Name","value":"r2"}},{"kind":"Field","name":{"kind":"Name","value":"cvrmse"}},{"kind":"Field","name":{"kind":"Name","value":"nmbe"}},{"kind":"Field","name":{"kind":"Name","value":"yMean"}}]}}]}}]}}]};