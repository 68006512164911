import {
  Box,
  Slider,
  SliderProps,
  styled,
  Typography,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import SliderValueLabel from "./SliderValueLabel";

interface Props extends Omit<SliderProps, "value" | "onChange"> {
  max: number;
  min: number;
  value: [number, number];
  onChange: (val: [number, number]) => void;
  labelFormatter?: (val: number) => string;
  /** Will trigger an immediate change as the slider moves */
  updateImmediately?: boolean;
}

const SliderStyle = styled(Slider)({
  paddingBottom: 5,
});

export const RangeSlider: FC<Props> = ({
  max,
  min,
  value,
  onChange,
  labelFormatter,
  updateImmediately,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<[number, number]>(value);

  // Listener that will reset the internal value when we set value from the parent
  useEffect(() => {
    setInternalValue(value);
  }, [setInternalValue, value]);

  return (
    <Box display="flex" flexDirection="column">
      <SliderStyle
        valueLabelDisplay="auto"
        ValueLabelComponent={SliderValueLabel}
        min={min}
        max={max}
        value={internalValue}
        onChange={(_, val) => {
          if (updateImmediately) {
            onChange(val instanceof Array ? [val[0], val[1]] : [0, 0]);
          } else {
            setInternalValue(val instanceof Array ? [val[0], val[1]] : [0, 0]);
          }
        }}
        onChangeCommitted={(_, val) => {
          if (!updateImmediately) {
            onChange(val instanceof Array ? [val[0], val[1]] : [0, 0]);
          }
        }}
        {...rest}
      />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="caption" color="textSecondary">
          {labelFormatter ? labelFormatter(min) : min}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {labelFormatter ? labelFormatter(max) : max}
        </Typography>
      </Box>
    </Box>
  );
};

type RangeSliderControllerProps = Omit<Props, "value" | "onChange"> & {
  name: string;
  control: Control;
};

const RangeSliderController: FC<RangeSliderControllerProps> = ({
  name,
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <RangeSlider value={value} onChange={onChange} {...props} />
      )}
    />
  );
};

export default RangeSliderController;
