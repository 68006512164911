import { Box, Typography } from "@material-ui/core";
import React from "react";

export interface Props {
  limit: number;
  current: number | string;
}

const InputChars: React.FC<Props> = ({ limit, current }) => {
  const currentNumChars =
    typeof current === "string" ? current.length : current;
  const hideBelow = 0.6;

  return (
    <>
      {currentNumChars / limit > hideBelow && (
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="caption" color="textSecondary">
            {`${Math.min(currentNumChars, limit)}/${limit}`}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default InputChars;
