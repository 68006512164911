import { Theme } from "@material-ui/core";
import { Palette } from "@material-ui/core/styles/createPalette";

export type ThemeColor = keyof Pick<
  Palette,
  "primary" | "secondary" | "error" | "warning" | "info" | "success"
>;

/**
 * Allows us to yield colors in a predictable way through our predefined theme hues
 */
export default function themeColor(theme: Theme, color?: ThemeColor): string {
  switch (color) {
    case "primary":
      return theme.palette.primary.main;
    case "secondary":
      return theme.palette.secondary.main;
    case "error":
      return theme.palette.error.main;
    case "warning":
      return theme.palette.warning.main;
    case "info":
      return theme.palette.info.main;
    case "success":
      return theme.palette.success.main;
    default:
      return theme.palette.primary.main;
  }
}
