import { useMutation } from "@apollo/client";
import React, { FC, useState } from "react";
import Button from "../ui/Button";
import { useToastContext } from "../ui/ToastProvider";
import { Document } from "./DocumentCenter";
import { RestoreDocumentDocument } from "./RestoreDocument.generated";

interface Props {
  entity: Document;
}

const RestoreFromTrashButton: FC<Props> = ({ entity }) => {
  const createToast = useToastContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [restoreDocument] = useMutation(RestoreDocumentDocument);

  const onClick = async () => {
    try {
      setLoading(true);
      await restoreDocument({
        variables: { input: { documentId: entity.id } },
      });
      createToast("Document was successfully restored", "success");
    } catch (err) {
      createToast(err.message || "Restore error", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button onClick={onClick} size="small" loading={loading}>
      Restore
    </Button>
  );
};

export default RestoreFromTrashButton;
