import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  CircularProgress,
  styled,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import Lockable from "./Lockable";

export type ButtonProps = Omit<MUIButtonProps, "variant" | "color"> & {
  primary?: boolean;
  tooltip?: string;
  locked?: boolean;
  loading?: boolean;
};

const StyledCircularProgress = styled(CircularProgress)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.grey[500],
    margin: spacing(1) / 4,
  })
);

const Button: React.FC<ButtonProps> = ({
  primary,
  tooltip,
  locked,
  disabled,
  loading,
  children,
  ...props
}) => {
  const button = (
    <MUIButton
      disableElevation
      variant={primary ? "contained" : "outlined"}
      color="primary"
      disabled={disabled || locked}
      {...props}
    >
      <Lockable locked={!!locked}>{children}</Lockable>
    </MUIButton>
  );

  const loadingButton = (
    <MUIButton
      disableElevation
      variant={primary ? "contained" : "outlined"}
      color="primary"
      disabled
      {...props}
    >
      <StyledCircularProgress size={20} />
    </MUIButton>
  );

  if (loading) {
    return loadingButton;
  }

  if (!tooltip) {
    return button;
  }

  return (
    <Tooltip title={tooltip}>
      <div>{button}</div>
    </Tooltip>
  );
};

export default Button;
