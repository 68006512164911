import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { UploadUrlDocument } from "./UploadUrlQuery.generated";

const useLazyGetUploadUrlQuery = () => {
  const client = useApolloClient();
  return useCallback(
    () => client.query({ query: UploadUrlDocument, fetchPolicy: "no-cache" }),
    [client]
  );
};

export default useLazyGetUploadUrlQuery;
