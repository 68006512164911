import React, { useEffect, useState } from "react";

/**
 * Calculates the offset top in the ref'd element while
 * the height is 100% to dynamically fill available space
 */
const useDetectOffsetTop = (ref: React.RefObject<HTMLDivElement>) => {
  const [offsetTop, setOffsetTop] = useState<number>(0);

  useEffect(() => {
    function updateSize() {
      setOffsetTop(ref.current?.getClientRects()[0]?.top || 0);
    }

    updateSize();

    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, [setOffsetTop, ref]);

  return offsetTop;
};

export default useDetectOffsetTop;
