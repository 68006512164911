import { IconProps } from "@material-ui/core";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import React, { FC } from "react";
import { EnergySource } from "../api/graphql";
import ElectricFilledIcon from "../assets/ElectricFilled";
import FuelOil2FilledIcon from "../assets/FuelOil2Filled";
import GasFilledIcon from "../assets/GasFilled";

type EnergySourceIconProps = {
  energySource: EnergySource;
  solar?: boolean;
} & IconProps;

const EnergySourceIcon: FC<EnergySourceIconProps> = ({
  energySource,
  solar,
  ...props
}) => {
  if (solar) return <WbSunnyIcon color="primary" />;

  switch (energySource) {
    case EnergySource.ELECTRICITY:
      return <ElectricFilledIcon color="primary" {...props} />;
    case EnergySource.NATURAL_GAS:
      return <GasFilledIcon color="primary" {...props} />;
    case EnergySource.FUEL_OIL2:
      return <FuelOil2FilledIcon color="primary" {...props} />;
    default:
      return null;
  }
};

export default EnergySourceIcon;
