import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  styled,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC, ReactNode } from "react";
import Button from "../ui/Button";

type Props = Omit<DialogProps, "onClose" | "open" | "title"> & {
  title: ReactNode;
  onDismiss: () => void;
  onConfirm?: () => Promise<void> | void;
  confirmText?: string;
};

const DialogTitleContents = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const DismissDialog: FC<Props> = ({
  title,
  onDismiss,
  onConfirm,
  confirmText,
  children,
  ...props
}) => {
  return (
    <Dialog open maxWidth="lg" onClose={onDismiss} {...props}>
      <DialogTitle>
        <DialogTitleContents>
          <div>{title}</div>
          <IconButton onClick={onDismiss}>
            <CloseIcon />
          </IconButton>
        </DialogTitleContents>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {!!onConfirm && (
        <DialogActions>
          <Button primary onClick={onConfirm}>
            {confirmText || "Confirm"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DismissDialog;
