import { styled, Typography } from "@material-ui/core";

/**
 * Allows us to control the vertical overflow of a textbox with a pleasing ellipsis.
 *
 * `-webkit-line-clamp` is supported by most browsers as of 2021, IE excluded
 * https://caniuse.com/css-line-clamp
 */
const ClampTextLines = styled(Typography)({
  minHeight: 50,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
});

export default ClampTextLines;
