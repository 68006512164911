import { Box, Button, styled } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";

type ButtonConfig = {
  to: string;
  buttonText: string;
};

interface Props {
  back?: ButtonConfig;
  next?: ButtonConfig;
}

const Footer = styled(Box)({
  position: "sticky",
  bottom: 0,
  backgroundColor: "rgba(245, 248, 250, 0.75)",
});

const StepperButtons: FC<Props> = ({ back, next }) => {
  return (
    <Footer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        {!!back ? (
          <BackButton to={back.to} label={back.buttonText} />
        ) : (
          <div></div>
        )}
        {!!next ? (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={next.to}
          >
            {next.buttonText}
          </Button>
        ) : (
          <div></div>
        )}
      </Box>
    </Footer>
  );
};

export default StepperButtons;
