import ShowChartIcon from "@material-ui/icons/ShowChart";
import React from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

type Props = { onCreateNew: () => void };

const NoFinancialModelsPlaceholder: React.FC<Props> = ({ onCreateNew }) => (
  <PlaceholderContainer>
    <PlaceholderSegment
      header="No Financial Models"
      subheader="There are no financial models for this building."
      buttonText="Create Your First Financial Model"
      onButtonClick={onCreateNew}
      icon={ShowChartIcon}
    />
  </PlaceholderContainer>
);

export default NoFinancialModelsPlaceholder;
