import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import modelName from "../lib/modelName";
import { useSessionWithUser } from "../SessionWithUser";
import ProductCopy from "../ui/ProductCopy";
import { PageTitle } from "../ui/Typography";
import { BuildingInsightsQueryQuery } from "./BuildingInsightsQuery.generated";
import CalibratedModelScore from "./CalibratedModelScore";
import DeltaDataTable from "./DeltaDataTable";
import EndUsesChart from "./EndUsesChart";
import EndUsesDeltaGrouped from "./EndUsesDeltaGrouped";
import EndUsesDeltasChart from "./EndUsesDeltasChart";
import EnergySignatureChart from "./EnergySignatureChart";
import InsightsKeyMetrics from "./InsightsKeyMetrics";
import ModelCompare from "./ModelCompare";
import ModelParameters from "./ModelParameters";
import PerformanceChart from "./PerformanceChart";
import PerformanceChartDelta from "./PerformanceChartDelta";
import ReferencePeriodChart from "./ReferencePeriodChart";
import ReportCard from "./ReportCard";
import SeasonalPerformance from "./SeasonalPerformance";
import SeasonalPerformanceDelta from "./SeasonalPerformanceDelta";

export type Building = BuildingInsightsQueryQuery["building"];
export type CalibratedModel = NonNullable<
  BuildingInsightsQueryQuery["building"]["calibratedModel"]
>;
export type CalibratedModels = Building["calibratedModels"];
export type CalibratedModelsItem = CalibratedModels[number];
export type AdjustedModel = NonNullable<CalibratedModel["adjustedModel"]>;
export type AdjustedModels = CalibratedModels[number]["adjustedModels"];
export type AdjustedModelsItem = CalibratedModels[number]["adjustedModels"][number];
export type Reference = CalibratedModel["reference"];
export type SeasonalPerformanceModel = CalibratedModel["seasonalPerformance"];
export type TemperaturePerformance = CalibratedModel["temperaturePerformance"];
export type TemperaturePerformanceEndUses = CalibratedModel["temperaturePerformance"][number]["endUses"];
export type Rates = Building["rates"];
interface Props {
  building: Building;
  initialModel: CalibratedModel;
  comparisonModel: AdjustedModel;
}

const BuildingInsightsReport: React.FC<Props> = ({
  building,
  initialModel,
  comparisonModel,
}) => {
  const { isSuperuser } = useSessionWithUser();
  const ASPECT = 20 / 9;

  return (
    <Grid item xs={12}>
      <PageTitle>{ProductCopy.ENERGY_ANALYTICS}</PageTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <ModelCompare
          building={building}
          initialModel={initialModel}
          comparisonModel={comparisonModel}
        />
      </Box>
      <Box pb={2}>
        <InsightsKeyMetrics
          building={building}
          initialModel={initialModel}
          comparisonModel={comparisonModel}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ReportCard name="End Use Category Consumption (Annual)">
            <EndUsesDeltaGrouped
              aspect={ASPECT}
              initialModel={initialModel.annualizedEndUses}
              comparisonModel={comparisonModel.annualizedEndUses}
              rates={building.rates}
            />
          </ReportCard>
        </Grid>
        <Grid item xs={6}>
          <ReportCard name="Projected Consumption By End Use (Temp)">
            <PerformanceChartDelta
              aspect={ASPECT}
              initialModel={initialModel.temperaturePerformance}
              comparisonModel={comparisonModel.temperaturePerformance}
            />
          </ReportCard>
        </Grid>
      </Grid>
      <Box pb={2} pt={5}>
        <PageTitle>Baseline Profile</PageTitle>
        <Typography color="textSecondary" variant="body2">
          Energy expenditure modeled by <b>{modelName(initialModel)}</b> during
          the baseline reference period.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {"reference" in initialModel && (
          <ReportCard name="Baseline Model Fit, By Fuel" xs={6}>
            <EnergySignatureChart
              aspect={ASPECT}
              buildingSquareFeet={building.squareFeet}
              data={initialModel.reference}
            />
          </ReportCard>
        )}
        {"reference" in initialModel && (
          <ReportCard name="Reference Period" xs={6}>
            <ReferencePeriodChart
              aspect={ASPECT}
              data={initialModel.reference}
            />
          </ReportCard>
        )}
        <ReportCard name="Consumption By End Use (Temp)" xs={6}>
          <PerformanceChart
            aspect={ASPECT}
            data={initialModel.temperaturePerformance}
          />
        </ReportCard>
        <ReportCard name="Consumption By End Use (Monthly)" xs={6}>
          <SeasonalPerformance
            aspect={ASPECT}
            model={initialModel.seasonalPerformance}
          />
        </ReportCard>
        <ReportCard name="Energy End Uses" xs={6}>
          <EndUsesChart aspect={ASPECT} data={initialModel.annualizedEndUses} />
        </ReportCard>
      </Grid>
      <Box pb={2} pt={5}>
        <PageTitle>Projected Profile</PageTitle>
        <Typography color="textSecondary" variant="body2">
          Energy efficiency projected by <b>{modelName(comparisonModel)}</b>{" "}
          during the baseline reference period.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <ReportCard name="End Use Category Consumption (Annual)" xs={6}>
          <EndUsesDeltaGrouped
            aspect={ASPECT}
            initialModel={initialModel.annualizedEndUses}
            comparisonModel={comparisonModel.annualizedEndUses}
            rates={building.rates}
          />
        </ReportCard>
        <ReportCard name="End Use Category Consumption (Annual, USD)" xs={6}>
          <EndUsesDeltaGrouped
            aspect={ASPECT}
            initialModel={initialModel.annualizedEndUses}
            comparisonModel={comparisonModel.annualizedEndUses}
            rates={building.rates}
            showUsd
          />
        </ReportCard>
        <ReportCard name="Projected Consumption By End Use (Monthly)" xs={6}>
          <SeasonalPerformanceDelta
            aspect={ASPECT}
            model={initialModel.seasonalPerformance}
            compareModel={comparisonModel.seasonalPerformance}
            rates={building.rates}
          />
        </ReportCard>
        <ReportCard name="Projected Cash Breakdown (Monthly)" xs={6}>
          <SeasonalPerformanceDelta
            aspect={ASPECT}
            model={initialModel.seasonalPerformance}
            compareModel={comparisonModel.seasonalPerformance}
            rates={building.rates}
            showCurrency
          />
        </ReportCard>
        <ReportCard name="Projected Consumption By End Use (Temp)" xs={6}>
          <PerformanceChartDelta
            aspect={ASPECT}
            initialModel={initialModel.temperaturePerformance}
            comparisonModel={comparisonModel.temperaturePerformance}
          />
        </ReportCard>
        <ReportCard name="EE End Use Value Contribution" xs={6}>
          <EndUsesDeltasChart
            aspect={ASPECT}
            initialModel={initialModel.annualizedEndUses}
            comparisonModel={comparisonModel.annualizedEndUses}
            rates={building.rates}
          />
        </ReportCard>
      </Grid>
      <Box pb={2} pt={5}>
        <PageTitle>Details</PageTitle>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DeltaDataTable
            model={initialModel.annualizedEndUses}
            comparisonModel={comparisonModel.annualizedEndUses}
            rates={building.rates}
          />
        </Grid>
        {isSuperuser && (
          <>
            <ReportCard name="Baseline Parameters" xs={6}>
              <ModelParameters model={initialModel} />
            </ReportCard>
            <ReportCard name="Projected Parameters" xs={6}>
              <ModelParameters model={comparisonModel} />
            </ReportCard>
            <ReportCard name="Calibration Score" xs={6}>
              <CalibratedModelScore calibratedModelId={initialModel.id} />
            </ReportCard>
            <ReportCard name="Weather" xs={6}>
              <Typography>
                Weather Station: {initialModel.weatherStation}
              </Typography>
            </ReportCard>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default BuildingInsightsReport;
