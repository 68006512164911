/**
 * Creates an Array.prototype.sort() callback that sorts dates in ascending or descending order
 */
const sortChronological = (
  date1: string,
  date2: string,
  order: "asc" | "desc"
): number => {
  return (
    (new Date(date1).valueOf() - new Date(date2).valueOf()) *
    (order === "asc" ? 1 : -1)
  );
};

export default sortChronological;
