import { useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  lighten,
  makeStyles,
  styled,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { SpeedDialAction } from "@material-ui/lab";
import { saveAs } from "file-saver";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import BuildingNavigation from "../BuildingNavigation";
import { DeleteBuildingDocument } from "../BuildingsList/DeleteBuilding.generated";
import useBuildingLoadFileQuery from "../BuildingsList/useBuildingLoadFileQuery";
import base64ToBlob from "../lib/base64ToBlob";
import { buildingsList } from "../lib/endpoints";
import { useSessionWithUser } from "../SessionWithUser";
import colors from "../ui/colors";
import ConfirmationDialog from "../ui/ConfirmationDialog";
import MapsAvatar from "../ui/MapsAvatar";
import SpeedDial from "../ui/SpeedDial";
import { useToastContext } from "../ui/ToastProvider";
import { BuildingMetadataQueryQuery } from "./BuildingMetadataQuery.generated";
import BuildingProfileCard from "./BuildingProfileCard";

interface Props {
  building: BuildingMetadataQueryQuery["building"];
}

const ExportBuildingDialAction: FC<{ buildingId: string; open: boolean }> = ({
  buildingId,
  open,
}) => {
  const [getExport, { loading }] = useBuildingLoadFileQuery();

  const saveExport = async () => {
    const { data: result } = await getExport(buildingId);
    if (result?.building?.loadFile) {
      saveAs(base64ToBlob(result.building.loadFile), `${buildingId}.zip`);
    }
  };

  return (
    <SpeedDialAction
      icon={loading ? <CircularProgress size={20} /> : <SaveAltIcon />}
      tooltipTitle="Export"
      onClick={loading ? undefined : saveExport}
      tooltipOpen
      open={open}
    />
  );
};

const DeleteBuildingDialAction: FC<{
  buildingId: string;
  open: boolean;
}> = ({ buildingId, open }) => {
  const history = useHistory();
  const [deleteBuilding] = useMutation(DeleteBuildingDocument);
  const createToast = useToastContext();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      {dialogOpen && (
        <ConfirmationDialog
          onClose={() => setDialogOpen(false)}
          onConfirm={() =>
            deleteBuilding({
              variables: { input: { buildingId } },
            })
              .then(() => createToast("Building was deleted", "success"))
              .then(() => history.push(buildingsList()))
              .catch((e) => createToast(e.message, "warning"))
          }
          header="Delete this building?"
          description="This action cannot be undone"
        />
      )}
      <SpeedDialAction
        icon={<DeleteIcon />}
        tooltipTitle="Delete"
        onClick={() => setDialogOpen(true)}
        tooltipOpen
        open={open}
      />
    </>
  );
};

export const buildingNavigationWidth = 160;

const BuildingHeader = styled("div")(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  backgroundColor: colors.blue.xxlight,
  position: "sticky",
  top: 0,
  boxShadow: `0px 0px ${theme.spacing(1)}px 0px ${lighten(
    theme.palette.common.black,
    0.75
  )}`,
  "@media print": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const BuildingMenu = styled("div")(({ theme }) => ({
  position: "fixed",
  width: buildingNavigationWidth,
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  "@media print": {
    display: "none",
  },
}));

const useDesktopLayout = makeStyles((theme) => ({
  content: {
    marginLeft: buildingNavigationWidth,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    "@media print": {
      marginLeft: 0,
    },
  },
}));

const DesktopBuildingFrame: FC<Props> = ({ building }) => {
  const { address } = building;

  return (
    <>
      <BuildingHeader>
        <BuildingProfileCard
          building={building}
          media={<MapsAvatar diameter={48} markerSize="small" {...address} />}
        />
      </BuildingHeader>

      <BuildingMenu>
        <BuildingNavigation building={building} />
      </BuildingMenu>
    </>
  );
};

const useMobileLayout = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  paper: {
    backgroundColor: colors.blue.xxlight,
  },
}));

const MobileBuildingFrame: FC<Props> = ({ building }) => {
  const { paper } = useMobileLayout();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const handleToggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <>
      <BuildingHeader>
        <BuildingProfileCard
          building={building}
          media={
            <Box displayPrint="none">
              <IconButton
                color="primary"
                size="small"
                onClick={handleToggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          }
        />
      </BuildingHeader>

      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleToggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{ paper }}
      >
        <BuildingNavigation building={building} />
      </Drawer>
    </>
  );
};

/**
 * BuildingLayout implements a responsive layout that will not unmount children
 * on a breakpoint change. This is critical to print mode.
 */
const BuildingLayout: FC<Props> = ({ building, children }) => {
  const { isSuperuser } = useSessionWithUser();
  const { breakpoints } = useTheme();
  const desktop = useMediaQuery(breakpoints.up("sm"));
  const Frame = desktop ? DesktopBuildingFrame : MobileBuildingFrame;

  const desktopClasses = useDesktopLayout();
  const mobileClasses = useMobileLayout();
  const classes = desktop ? desktopClasses : mobileClasses;

  return (
    <>
      <Helmet>
        <title>{building.name} – EnergyRM</title>
      </Helmet>

      {isSuperuser && (
        <SpeedDial>
          {(open: boolean) => {
            return (
              <div>
                <ExportBuildingDialAction
                  buildingId={building.id}
                  open={open}
                />
                <DeleteBuildingDialAction
                  buildingId={building.id}
                  open={open}
                />
              </div>
            );
          }}
        </SpeedDial>
      )}

      <Frame building={building} />
      <div className={classes.content}>{children}</div>
    </>
  );
};

export default BuildingLayout;
