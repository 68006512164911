import { Button, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DateTime } from "luxon";
import React, { FC, MouseEvent, useState } from "react";
import { Document } from "./DocumentCenter";

interface Props {
  filter: Filters;
  onChange: (filter: Filters) => void;
}

export enum Filters {
  NONE = "NONE",
  MINE = "MINE",
  SHARED_WITH_ME = "SHARED_WITH_ME",
  RECENTLY_MODIFIED = "RECENTLY_MODIFIED",
  TRASH = "TRASH",
}

/**
 * Pretty names for the filters for users
 */
const filterName = (filter: Filters): string => {
  switch (filter) {
    case Filters.NONE:
      return "None";
    case Filters.MINE:
      return "Owned by me";
    case Filters.SHARED_WITH_ME:
      return "Shared with me";
    case Filters.RECENTLY_MODIFIED:
      return "Recently modified (7 days)";
    case Filters.TRASH:
      return "Trash";
  }
};

export const filterCallbacks: Record<
  keyof typeof Filters,
  (...args: any[]) => (d: Document) => boolean
> = {
  NONE: () => () => true,
  // Documents in which my user id is the createdBy user id
  MINE: (userId: string) => (d) => d.createdBy.id === userId,
  RECENTLY_MODIFIED: () => (d) => {
    const updatedAt = DateTime.fromISO(d.updatedAt);
    const now = DateTime.local();
    const weekAgo = now.minus({ day: 7 });
    return weekAgo < updatedAt && updatedAt < now;
  },
  // Documents in which I am included in permissions but not the creator
  SHARED_WITH_ME: (userId) => (d) => {
    return (
      d.users.map((u) => u.id).includes(userId) && d.createdBy.id !== userId
    );
  },
  TRASH: () => (d) => Boolean(d.deletedAt),
};

/**
 * Reducer for the file list documents - the onChange function
 */
export const onFilterSelect = (
  documents: Document[],
  filter: Filters,
  userId: string
): Document[] => documents.filter(filterCallbacks[filter](userId));

/**
 * Iterable MenuItem component for the filter menu
 */
const FilterMenuItem: FC<{
  filter: Filters;
  currentValue: Filters;
  onItemSelect: (v: Filters) => void;
}> = ({ filter, currentValue, onItemSelect }) => (
  <MenuItem
    selected={currentValue === filter}
    onClick={() => {
      onItemSelect(filter);
    }}
  >
    {filterName(filter)}
  </MenuItem>
);

const FilterDocuments: FC<Props> = ({ filter, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);

  const onItemSelect = (v: Filters) => {
    onChange(v);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClick}
        size="small"
        endIcon={<ArrowDropDownIcon fontSize="small" />}
      >
        Filter: {filterName(filter)}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {Object.values(Filters).map((f, idx) => (
          <FilterMenuItem
            key={idx}
            currentValue={filter}
            filter={f}
            onItemSelect={onItemSelect}
          />
        ))}
      </Menu>
    </>
  );
};

export default FilterDocuments;
