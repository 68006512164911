import { useMutation } from "@apollo/client";
import { FormHelperText } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { FileAssetInput } from "../api/graphql";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import FormDialog from "../ui/FormDialog";
import { useToastContext } from "../ui/ToastProvider";
import FileInput from "../Uploads/FileInput";
import { UploadDocumentDocument } from "./UploadDocument.generated";

interface Props {
  onSuccess?: (documentId: string) => Promise<void>;
  disabled?: boolean;
}

interface Field {
  file: FileAssetInput[];
}

const UploadDocumentButton: FC<Props> = ({ onSuccess, disabled }) => {
  const { organization } = useSessionWithUser();
  const createToast = useToastContext();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [uploadDocument] = useMutation(UploadDocumentDocument);
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<Field>({
    defaultValues: {
      file: [],
    },
  });

  const onSubmit = async (field: Field) => {
    const { key, filename, mimetype } = field.file[0];

    try {
      const res = await uploadDocument({
        variables: {
          input: {
            organizationId: organization.id,
            file: { key, filename, mimetype },
          },
        },
      });
      setShowDialog(false);
      if (onSuccess && res.data) {
        await onSuccess(res.data.createOrganizationDocument.document.id);
      }
    } catch (err) {
      createToast(err.message || "Upload error", "error");
    }
  };

  const files = watch("file");

  return (
    <>
      {showDialog && (
        <FormDialog
          title="Upload a file"
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          submitName={files[0] && !files[0].key ? "Uploading ..." : "Upload"}
        >
          <FileInput
            name="file"
            control={control}
            label="File"
            rules={{
              validate: {
                length: (value: Field["file"]) =>
                  value.length === 1 || "File is required",
              },
            }}
          />
          {errors.file && (
            <FormHelperText error>
              {(errors.file as any).message}
            </FormHelperText>
          )}
        </FormDialog>
      )}
      <Button
        size="small"
        primary
        onClick={() => setShowDialog(true)}
        disabled={disabled}
      >
        Upload document
      </Button>
    </>
  );
};

export default UploadDocumentButton;
