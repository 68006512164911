import { useQuery } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { toQueryString } from "../BuildingsList";
import { buildingsList } from "../lib/endpoints";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import { useSessionWithUser } from "../SessionWithUser";
import { SkeletonChart, SkeletonTypography } from "../ui/Bones";
import ResponsiveChart from "../ui/charting/ResponsiveChart";
import colors from "../ui/colors";
import DashboardCard from "../ui/DashboardCard";
import Metric, { MetricSkeleton } from "../ui/Metric";
import {
  BuildingsChartDocument,
  BuildingsChartQuery,
} from "./BuildingsChartQuery.generated";

type BuildingUseType = BuildingsChartQuery["organization"]["buildings"]["countByBuildingUseType"];

const palette = [
  colors.blue.light,
  colors.green.main,
  colors.orange.main,
  colors.gray.main,
];

const transformPieData = (data: BuildingUseType) => {
  return data
    .map((but) => ({
      ...but,
      buildingUseName: `${primaryFunctionTitle(but.buildingUseType)} (${
        but.count
      })`,
    }))
    .sort((a, b) => b.count - a.count);
};

const transformMetrics = (
  data: BuildingsChartQuery["organization"]["buildings"]
): {
  numBuildings: number;
  opportunityValue: number;
  totalSquareFeet: number;
} => {
  return {
    numBuildings: data.count,
    opportunityValue: data.edges
      .map((e) => e.node)
      .reduce(
        (acc, c) =>
          acc +
          (c.calibratedModel?.adjustedModel?.opportunity.money.value || 0),
        0
      ),
    totalSquareFeet: data.squareFeet,
  };
};

const BuildingsChartSkeleton: FC<{}> = () => {
  return (
    <DashboardCard label={<SkeletonTypography size="medium" />}>
      <Grid container justify="space-between">
        <Grid>
          <MetricSkeleton />
        </Grid>
        <Grid>
          <MetricSkeleton />
        </Grid>
        <Grid>
          <MetricSkeleton />
        </Grid>
      </Grid>
      <SkeletonChart />
    </DashboardCard>
  );
};

const BuildingsChart: FC<{}> = () => {
  const history = useHistory();
  const { organization } = useSessionWithUser();
  const { data, loading, error } = useQuery(BuildingsChartDocument, {
    variables: {
      organizationId: organization.id,
    },
  });

  if (error) throw error;

  if (loading || !data) {
    return <BuildingsChartSkeleton />;
  }

  const queryData = data.organization.buildings.countByBuildingUseType;
  const metrics = transformMetrics(data.organization.buildings);

  return (
    <DashboardCard label="Buildings overview">
      <Box px={2}>
        <Grid container justify="space-between">
          <Grid>
            <Metric value={metrics.numBuildings} subheader="Total buildings" />
          </Grid>
          <Grid>
            <Metric
              value={metrics.opportunityValue}
              subheader="Total opportunity value"
              labelFormatter={(v) => `$${v}`}
            />
          </Grid>
          <Grid>
            <Metric
              value={metrics.totalSquareFeet}
              subheader="Square feet in system"
            />
          </Grid>
        </Grid>
      </Box>
      <ResponsiveChart ratio={2.2}>
        {({ width, height }) => (
          <PieChart width={width} height={height}>
            <Legend verticalAlign="middle" align="right" layout="vertical" />
            <Pie
              data={transformPieData(queryData)}
              nameKey="buildingUseName"
              dataKey="count"
              animationDuration={500}
              onClick={({ buildingUseType }) =>
                history.push({
                  pathname: buildingsList(),
                  search: toQueryString({
                    buildingUseType: [buildingUseType],
                  }),
                })
              }
            >
              {queryData.map((_, index) => (
                <Cell key={index} fill={palette[index % palette.length]} />
              ))}
            </Pie>
            <Tooltip animationDuration={500} />
          </PieChart>
        )}
      </ResponsiveChart>
    </DashboardCard>
  );
};

export default BuildingsChart;
