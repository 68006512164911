import * as Types from '../api/graphql';

import { DeltaMeterModelFragmentFragment } from '../api/fragments/DeltaMeterModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { DeltaMeterModelFragmentFragmentDoc } from '../api/fragments/DeltaMeterModel.generated';
export type ActivateDeltaMeterMutationVariables = Types.Exact<{
  input: Types.ActivateDeltaMeterInput;
}>;


export type ActivateDeltaMeterMutation = { activateDeltaMeter: { __typename: 'ActivateDeltaMeterResponse', deltaMeter: (
      { __typename: 'DeltaMeter' }
      & DeltaMeterModelFragmentFragment
    ) } };


export const ActivateDeltaMeterDocument: DocumentNode<ActivateDeltaMeterMutation, ActivateDeltaMeterMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"activateDeltaMeter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ActivateDeltaMeterInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activateDeltaMeter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deltaMeter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DeltaMeterModelFragment"}}]}}]}}]}},...DeltaMeterModelFragmentFragmentDoc.definitions]};