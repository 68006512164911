import TimelineIcon from "@material-ui/icons/Timeline";
import React, { FC } from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

interface Props {}

const NoDeltaMeter: FC<Props> = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderSegment
        icon={TimelineIcon}
        header="No DeltaMeter"
        subheader="No DeltaMeter has been activated for this building."
      />
    </PlaceholderContainer>
  );
};

export const DraftDeltaMeter: FC<Props> = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderSegment
        icon={TimelineIcon}
        header="Pending activation"
        subheader="This DeltaMeter is currently pending activation. Please check back later."
      />
    </PlaceholderContainer>
  );
};

export default NoDeltaMeter;
