import { makeStyles, Paper, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { EnergySource } from "../api/graphql";
import { tooltipFontSize } from "../lib/reporting/config";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(1),
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  bodySize: {
    fontSize: tooltipFontSize,
  },
}));

interface Payload {
  name: string | number;
  value: string | number;
  color?: string;
  energySource?: EnergySource;
  payload?: Payload;
}

type Formatting = number | string | (string | number)[];

interface Props {
  payload?: Payload[];
  label?: number | string;
  formatting?: (input: Formatting) => string | number;
  labelFormatting?: (input: string | number) => ReactNode;
  bodyOverride?: (input: Payload) => string;
}

const CustomTooltip: React.FC<Props> = ({
  payload,
  label,
  formatting,
  labelFormatting,
  bodyOverride,
}) => {
  const { paper, bodySize } = useStyles();

  return (
    <Paper className={paper}>
      <Typography variant="subtitle1">
        {labelFormatting && label ? labelFormatting(label) : label}
      </Typography>
      {payload!.map((p, idx) => {
        let label;

        if (bodyOverride) label = bodyOverride(p);
        else {
          label = formatting
            ? `${p.name}: ${formatting(p.value)}`
            : `${p.name}: ${p.value}`;
        }

        return (
          <Typography
            key={`tooltip-${idx}`}
            style={{ color: p.color }}
            variant="body2"
            className={bodySize}
          >
            {label}
          </Typography>
        );
      })}
    </Paper>
  );
};

export default CustomTooltip;
