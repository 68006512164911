import {
  Box,
  Grid,
  makeStyles,
  Paper,
  styled,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import { Alert } from "@material-ui/lab";
import millify from "millify";
import React, { FC, ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { EnergySource } from "../api/graphql";
import pluralize from "../lib/pluralize";
import sum from "../lib/sum";
import colors from "../ui/colors";
import { KeyMetricLarge } from "../ui/Typography";
import { calculateInvestmentStatus, InvestmentStatus } from "./finance";
import { FormSections } from "./FinancialModelDashboard";
import { calculateCumulativeSum, DataPoint } from "./helpers";

interface Props {
  IRR: number | undefined;
  averageAnnualCashFlow: number;
  NPV: number;
  annualCashFlows: DataPoint[];
  eeValuePerSource: {
    energySource: EnergySource;
    value: number;
  }[];
}

interface MetricProps {
  title: string;
  subheader: ReactNode;
}

const StandardMetric: FC<MetricProps> = ({ title, subheader, children }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2">{title.toUpperCase()}</Typography>
      <KeyMetricLarge color="primary">
        <b data-testid="metric">{children}</b>
      </KeyMetricLarge>
      <Typography variant="caption" color="textSecondary">
        {subheader}
      </Typography>
    </Box>
  );
};

interface EmphasizedMetricProps {
  title: string;
  subheader: ReactNode;
  status: InvestmentStatus;
}

const useStyles = makeStyles((theme) => ({
  whiteText: {
    color: theme.palette.common.white,
  },
  coloredPaper: {
    backgroundColor: ({ status }: { status: InvestmentStatus }) =>
      statusColors[status],
  },
}));

const StyledText = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.common.white,
}));

const statusColors: Record<InvestmentStatus, string> = {
  good: colors.green.main,
  warn: colors.orange.main,
  bad: colors.red.main,
};

const statusIcons: Record<InvestmentStatus, ReactNode> = {
  good: <CheckCircleIcon fontSize="large" />,
  warn: <WarningIcon fontSize="large" />,
  bad: <CancelIcon fontSize="large" />,
};

const EmphasizedMetric: FC<EmphasizedMetricProps> = ({
  title,
  subheader,
  status,
  children,
}) => {
  const classes = useStyles({ status });

  return (
    <Paper variant="outlined" className={classes.coloredPaper}>
      <Box p={1}>
        <StyledText variant="body2">{title.toUpperCase()}</StyledText>
        <Box display="flex" alignItems="center">
          <Box color="white">{statusIcons[status]}</Box>
          <Grid container direction="column" alignItems="center">
            <KeyMetricLarge className={classes.whiteText}>
              <b data-testid="metric">{children}</b>
            </KeyMetricLarge>
            <StyledText variant="caption">{subheader}</StyledText>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

const calculateBreakevenDate = (
  annualCashFlows: DataPoint[],
  projectCost: number
): [year: number, month: number] => {
  const cumulativeCashFlows = calculateCumulativeSum(annualCashFlows);
  // 0-indexed breakeven year
  const year = cumulativeCashFlows.findIndex((d) => d.average > projectCost);
  // We didn't breakeven!
  if (year < 0) return [-1, -1];
  const cashflowThatYear = annualCashFlows[year].average;
  // calculate initial value of investment at start of breakeven year
  const breakevenYearStartingDeficit =
    (cumulativeCashFlows[year - 1]?.average ?? 0) - projectCost;
  // find what % through the year you break even
  const percentThroughBreakevenYear =
    Math.abs(breakevenYearStartingDeficit) / cashflowThatYear;
  // convert that to # of months!
  const month = Math.round(percentThroughBreakevenYear * 12);
  return [month === 12 ? year + 1 : year, month === 12 ? 0 : month];
};

const BreakevenDate: React.FC<{ year: number; month: number }> = ({
  year,
  month,
}) => {
  if (year < 0) {
    return <>No breakeven over term</>;
  }
  if (year === 0 && month === 0) {
    return <>Breakeven in less than a month</>;
  }
  const formattedYear = year > 0 ? pluralize(`${year} year`, year) : "";
  const formattedMonth = month > 0 ? pluralize(`${month} month`, month) : "";
  const delimiter = !!formattedYear && !!formattedMonth ? " and " : "";
  return (
    <>
      Breakeven in {formattedYear}
      {delimiter}
      {formattedMonth}
    </>
  );
};

/** formatKpi millifies while also guaranteeing a fixed 2 decimal places always display */
const formatKpi = (value: number): string => {
  const millified = millify(value, { precision: 2, space: true });
  const [formattedNumber, unitAbbr] = millified.split(" ");
  return `${parseFloat(formattedNumber).toFixed(2)}${unitAbbr}`;
};

const KeyMetrics: FC<Props> = ({
  IRR,
  NPV,
  annualCashFlows,
  eeValuePerSource,
  averageAnnualCashFlow,
}) => {
  const { watch } = useFormContext<FormSections>();
  const projectData = watch("projectData");
  const [breakevenYear, breakevenMonth] = calculateBreakevenDate(
    annualCashFlows,
    projectData.projectCost.value
  );

  const investmentStatus = calculateInvestmentStatus(
    (IRR || 0) * 100,
    projectData.discountRate * 100
  );

  return (
    <Box pb={3} pt={investmentStatus === "bad" ? 0 : 3}>
      {investmentStatus === "bad" && (
        <Box pb={1}>
          <Alert severity="warning">
            Investment, depth and expenses need to be adjusted for viable
            financial model
          </Alert>
        </Box>
      )}
      <Grid container justify="space-between" alignItems="center">
        <StandardMetric
          title="Energy efficiency value"
          subheader={`Over a ${projectData.term}-year term`}
        >
          ${formatKpi(sum(eeValuePerSource.map((s) => s.value)))}
        </StandardMetric>
        <StandardMetric
          title="Capital investment"
          subheader={
            <BreakevenDate year={breakevenYear} month={breakevenMonth} />
          }
        >
          ${formatKpi(projectData.projectCost.value)}
        </StandardMetric>
        <StandardMetric
          title="Net present value"
          subheader={
            <>
              Nominal annual cash flow: ${formatKpi(averageAnnualCashFlow)} avg.
            </>
          }
        >
          ${formatKpi(NPV)}
        </StandardMetric>
        <EmphasizedMetric
          title="Internal rate of return"
          subheader={
            <>
              Discount rate:{" "}
              <FormattedNumber
                value={projectData.discountRate * 100}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
              %
            </>
          }
          status={investmentStatus}
        >
          {IRR ? (
            <FormattedNumber
              value={IRR}
              style={`percent`}
              minimumFractionDigits={2}
            />
          ) : (
            "—"
          )}
        </EmphasizedMetric>
      </Grid>
    </Box>
  );
};

export default KeyMetrics;
