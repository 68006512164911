import { Box, Grid, styled, Typography } from "@material-ui/core";
import millify from "millify";
import React, { FC } from "react";
import { FormattedDate } from "react-intl";
import {
  calculateInvestmentStatus,
  calculateIRR,
} from "../FinancialModel/finance";
import {
  calculateRevenue,
  createCashFlows,
  createEnergySourceConfigs,
} from "../FinancialModel/helpers";
import { financialModelById } from "../lib/endpoints";
import Energy from "../lib/Energy";
import sum from "../lib/sum";
import { CardLinkedArea } from "../ui/Card";
import MetaData from "../ui/MetaData";
import StripeCard from "../ui/StripeCard";
import { KeyMetricSmall } from "../ui/Typography";
import { FinancialModelsQueryQuery } from "./FinancialModelsQuery.generated";
import IrrStatus from "./IrrStatus";

type Props = {
  financialModel: FinancialModelsQueryQuery["building"]["financialModels"][number];
  buildingId: string;
};

const GridContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "grid",
  gridGap: spacing(3),
  gridTemplateColumns: "repeat(3, 1fr)",
}));

const FinancialModelCard: FC<Props> = ({ financialModel, buildingId }) => {
  // reconstruct energySourceConfigs
  const energySourceConfigs = (() => {
    const sources = financialModel.calibratedModel.annualizedEnergySources.map(
      (e) => ({
        energySource: e.energySource,
        unit: e.energy.unit,
        baseline: e.energy.quantity,
      })
    );

    return createEnergySourceConfigs(
      sources.map(({ energySource, baseline, unit }) => ({
        energySource,
        baseline: new Energy({ quantity: baseline, unit }),
      })),
      financialModel.energyEfficiencyDepths,
      financialModel.customRates || financialModel.defaultRates
    );
  })();

  // key metric: EE Value
  const eeValue = sum(
    energySourceConfigs.map((config) =>
      calculateRevenue(config, financialModel.term)
    )
  );

  // key metric: IRR
  const irr = (() => {
    const averageAnnualCashFlows = createCashFlows(
      energySourceConfigs,
      financialModel.term,
      0,
      financialModel.cashFlows,
      0
    ).map((d) => d.average);

    return calculateIRR(
      financialModel.projectCost.value,
      averageAnnualCashFlows
    );
  })();

  // key metric: IRR status
  const status = calculateInvestmentStatus(
    (irr || 0) * 100,
    financialModel.discountRate * 100
  );

  return (
    <Grid item xs={12}>
      <StripeCard orientation="left">
        <CardLinkedArea
          to={financialModelById({
            financialModelId: financialModel.id,
            buildingId,
          })}
        >
          <Box p={2} width={1} display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h6">{financialModel.name}</Typography>
              <MetaData>
                <span>{financialModel.term}-year term</span>
                <span>
                  Last edited <FormattedDate value={financialModel.updatedAt} />{" "}
                  by {financialModel.updatedBy.name}
                </span>
              </MetaData>
            </Box>

            <GridContainer>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="overline" color="textSecondary">
                  Energy Efficiency Value
                </Typography>
                <KeyMetricSmall align="center" color="primary">
                  ${millify(eeValue)}
                </KeyMetricSmall>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="overline" color="textSecondary">
                  Capital Investment
                </Typography>
                <KeyMetricSmall align="center" color="primary">
                  ${millify(financialModel.projectCost.value)}
                </KeyMetricSmall>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="overline" color="textSecondary">
                  IRR
                </Typography>
                <IrrStatus status={status} irr={irr} />
              </Box>
            </GridContainer>
          </Box>
        </CardLinkedArea>
      </StripeCard>
    </Grid>
  );
};

export default FinancialModelCard;
