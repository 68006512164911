import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";

export interface ButtonDetails<T extends React.ReactText = React.ReactText> {
  icon: ReactNode;
  label: string;
  value: T;
}

export interface Props<T extends React.ReactText = React.ReactText> {
  buttonConfig: ButtonDetails<T>[];
  value: T;
  onChange: (val: T) => void;
  disabled?: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: spacing(8),
    width: spacing(8),
  },
  labelWidth: {
    width: spacing(8),
  },
}));

const ButtonWithLabel: React.FC<{ label: string }> = ({ label, children }) => {
  const { labelWidth } = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box pb={1}>{children}</Box>
      <Typography align="center" className={labelWidth} variant="caption">
        {label}
      </Typography>
    </Box>
  );
};

const ButtonGroupSelect = function <T extends React.ReactText>({
  value,
  onChange,
  buttonConfig,
  disabled,
}: Props<T>) {
  const { root } = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
    >
      {buttonConfig.map(({ label, icon, value: buttonValue }, idx) => (
        <ButtonWithLabel label={label} key={`${label}-button-${idx}`}>
          <Button
            color="primary"
            variant={buttonValue === value ? "contained" : "outlined"}
            className={root}
            onClick={() => onChange(buttonValue)}
            disabled={disabled}
          >
            {icon}
          </Button>
        </ButtonWithLabel>
      ))}
    </Box>
  );
};

export default ButtonGroupSelect;

type ControllerProps<T extends React.ReactText> = Omit<Props<T>, "value"> & {
  name: string;
  control: Control;
  onChange?: Props<T>["onChange"];
};

export const ButtonGroupSelectController = function <
  T extends React.ReactText
>({ name, control, onChange, ...props }: ControllerProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange: controllerOnChange }) => (
        <ButtonGroupSelect
          value={value}
          onChange={(v) => {
            if (onChange) {
              onChange(v);
            }
            controllerOnChange(v);
          }}
          {...props}
        />
      )}
    />
  );
};
