import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import DocumentSharing from "../DocumentSharing/DocumentSharing";
import { insights } from "../lib/endpoints";
import pluckNodes from "../lib/pluckNodes";
import { useSessionWithUser } from "../SessionWithUser";
import BackButton from "../ui/BackButton";
import ProductCopy from "../ui/ProductCopy";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import DocumentCenterList from "./DocumentCenterList";
import DocumentCenterListSkeleton from "./DocumentCenterListSkeleton";
import FilterDocuments, { Filters, onFilterSelect } from "./FilterDocuments";
import NoDocuments from "./NoDocuments";
import {
  OrganizationDocumentsDocument,
  OrganizationDocumentsQuery,
} from "./OrganizationDocumentsQuery.generated";
import UploadDocumentButton from "./UploadDocumentButton";

export type Document = OrganizationDocumentsQuery["organization"]["documents"]["edges"][number]["node"];

interface Props {}

const DocumentCenter: FC<Props> = () => {
  const createToast = useToastContext();
  const { organization, user } = useSessionWithUser();
  const [filter, setFilter] = useState<Filters>(Filters.NONE);
  // Holds the documentId for the recently uploaded document
  const [documentSharing, setDocumentSharing] = useState<string | null>(null);
  const { data, loading, error, refetch } = useQuery(
    OrganizationDocumentsDocument,
    {
      variables: {
        organizationId: organization.id,
        deleted: filter === Filters.TRASH,
      },
      // avoid stale cache when switching in/out of trash
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );

  const documents = useMemo(() => {
    if (loading || !data) {
      return [];
    }
    return pluckNodes(data.organization.documents);
  }, [data, loading]);

  const filteredDocuments = useMemo(() => {
    return onFilterSelect(documents, filter, user.id);
  }, [documents, filter, user.id]);

  const onUploadSuccess = async (documentId: string) => {
    createToast("File was successfully uploaded", "success");
    await refetch({ organizationId: organization.id, deleted: false });
    await refetch();
    setDocumentSharing(documentId);
  };

  if (error) throw error;
  if (!data || loading)
    return (
      <>
        <BackButton
          label={`Back to ${ProductCopy.DASHBOARD}`}
          to={insights()}
        />
        <Box pb={1}>
          <PageTitle>Document center</PageTitle>
        </Box>
        <DocumentCenterListSkeleton />
      </>
    );

  return (
    <>
      {documentSharing && (
        <DocumentSharing
          documentId={documentSharing}
          onDismiss={() => {
            setDocumentSharing(null);
          }}
        />
      )}
      <BackButton label={`Back to ${ProductCopy.DASHBOARD}`} to={insights()} />
      <Box pb={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <PageTitle>Document center</PageTitle>
          <Box>
            <Box display="inline" pr={2}>
              <FilterDocuments filter={filter} onChange={(v) => setFilter(v)} />
            </Box>
            <UploadDocumentButton
              onSuccess={onUploadSuccess}
              disabled={filter === Filters.TRASH}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        {documents.length === 0 && filter !== Filters.TRASH ? (
          <NoDocuments />
        ) : (
          <DocumentCenterList documents={filteredDocuments} />
        )}
      </Box>
    </>
  );
};

export default DocumentCenter;
