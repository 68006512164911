import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Tooltip,
} from "@material-ui/core";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";
import Lockable from "./Lockable";

export interface Props {
  label: string;
  value: string | null | undefined;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
  locked?: boolean;
  lockExplanation?: string;
  variant?: "outlined" | "standard";
}

const StyledLabel = styled(InputLabel)({
  backgroundColor: "white",
  paddingLeft: 5,
  paddingRight: 5,
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const SelectItem = MenuItem;

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    minWidth: "150px",
  },
}));

const SelectField: FC<Props> = ({
  label,
  value,
  onChange,
  error,
  disabled,
  locked,
  lockExplanation,
  variant,
  children,
}) => {
  const { formControl } = useStyles();
  const labelId = `select-${label}-label`;
  return (
    <Tooltip title={(locked && lockExplanation) || ""}>
      <StyledFormControl
        className={formControl}
        error={!!error}
        disabled={disabled || locked}
        variant={variant || "standard"}
        size="small"
      >
        <StyledLabel id={labelId}>
          <Lockable locked={!!locked}>{label}</Lockable>
        </StyledLabel>
        <Select
          labelId={labelId}
          inputProps={{
            "data-testid": labelId,
          }}
          value={value || ""}
          onChange={(e) => onChange(e.target.value as string)}
        >
          {children}
        </Select>
        <FormHelperText error>{error}</FormHelperText>
      </StyledFormControl>
    </Tooltip>
  );
};

export default SelectField;

type SelectFieldControllerProps = Omit<Props, "value" | "onChange"> & {
  name: string;
  control: Control;
  required?: boolean;
  onChange?: (v: string) => void;
};

export const SelectFieldController: FC<SelectFieldControllerProps> = ({
  name,
  control,
  required,
  onChange,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ value, onChange: controllerOnChange }) => (
      <SelectField
        value={value}
        onChange={(v) => {
          if (onChange) onChange(v);
          controllerOnChange(v);
        }}
        {...props}
      />
    )}
    rules={{
      required: required ? "Required" : undefined,
    }}
  />
);
