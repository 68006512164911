import { Box, Link, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import TextIcon from "../ui/TextIcon";

interface Props {
  path: string;
}

const ViewMoreLink: FC<Props> = ({ path, children }) => {
  return (
    <Box px={2} py={1}>
      <Typography>
        <Link component={RouterLink} variant="body2" to={path}>
          {children}
        </Link>
        <TextIcon icon={<ArrowForwardIcon />} />
      </Typography>
    </Box>
  );
};

export default ViewMoreLink;
