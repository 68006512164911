import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import Button from "./Button";

interface Props {
  title: React.ReactNode;
  submitName?: React.ReactNode;
  dismissName?: React.ReactNode;
  onCancel: () => void;
  onSubmit: () => Promise<void> | void;
  error?: { message: string };
  isSubmitting: boolean;
  maxWidth?: "sm" | "md" | "lg";
}

const useStyles = makeStyles(({ spacing }) => ({
  buttonWidth: {
    minWidth: spacing(12),
  },
  dividerPadding: {
    marginBottom: spacing(2),
  },
}));

function FormDialog({
  submitName,
  title,
  onCancel,
  onSubmit,
  error,
  isSubmitting,
  children,
  maxWidth,
  dismissName,
}: React.PropsWithChildren<Props>) {
  const { buttonWidth } = useStyles();
  return (
    <Dialog open fullWidth maxWidth={maxWidth || "sm"} scroll="paper">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={onSubmit} noValidate>
          {error && <FormHelperText error>{error.message}</FormHelperText>}
          {children}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className={buttonWidth}
          disabled={isSubmitting}
          size="small"
          onClick={onCancel}
        >
          {dismissName || "Cancel"}
        </Button>
        <Button
          className={buttonWidth}
          disabled={isSubmitting}
          size="small"
          primary
          type="submit"
          onClick={onSubmit}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            submitName || "Submit"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FormDialog;
