import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Copyright from "./Copyright";

interface Props {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const LegalLayout: React.FC<Props> = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography component="h1" variant="h3">
          {title}
        </Typography>
        {children}
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LegalLayout;
