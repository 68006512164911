import React from "react";
import {
  Bar,
  BarChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Currency, EnergyUnitType } from "../api/graphql";
import { endUseName, energyEndUses } from "../lib/endUse";
import Energy from "../lib/Energy";
import formatter from "../lib/formatter";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
  yAxisOffset,
} from "../lib/reporting/config";
import { EndUse as AnnualizedEndUse } from "../lib/types/Reports";
import colors from "../ui/colors";
import { Rates } from "./BuildingInsightsReport";
import CustomAxisTick from "./CustomAxisTick";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  rates: Rates;
  initialModel: AnnualizedEndUse[];
  comparisonModel: AnnualizedEndUse[];
  showUsd?: boolean;
  showLegend?: boolean;
}

enum ModelName {
  BASELINE = "Baseline",
  PROJECTED = "Projected",
}

interface ModelColors {
  [ModelName.BASELINE]: string;
  [ModelName.PROJECTED]: string;
}

enum Labels {
  USD = "USD ($)",
}

interface DataPoint {
  endUse: string;
  [ModelName.BASELINE]: number;
  [ModelName.PROJECTED]: number;
}

export function formatChartData(
  baseline: AnnualizedEndUse[],
  comparison: AnnualizedEndUse[],
  rates: Rates,
  usd?: boolean
): DataPoint[] {
  const unit = EnergyUnitType.KBTU;

  return energyEndUses.map((endUse) => {
    const totalModeled = baseline
      .filter((d) => d.endUse === endUse)
      .map(({ energySource, energy }) => {
        const rate = rates.find(
          (r) => r.energySource === (energySource as any)
        ) || {
          cost: { value: NaN, unit: Currency.USD },
          unit: EnergyUnitType.KWH,
        };

        const modeled = new Energy(energy);

        return usd
          ? modeled.as(rate.unit).quantity * rate.cost.value
          : modeled.as(unit).quantity;
      });

    const totalComparison = comparison
      .filter((d) => d.endUse === endUse)
      .map(({ energySource, energy }) => {
        const rate = rates.find(
          (r) => r.energySource === (energySource as any)
        ) || {
          cost: { value: NaN, unit: Currency.USD },
          unit: EnergyUnitType.KWH,
        };

        const modeled = new Energy(energy);

        return usd
          ? modeled.as(rate.unit).quantity * rate.cost.value
          : modeled.as(unit).quantity;
      });

    return {
      endUse: endUseName(endUse),
      [ModelName.BASELINE]: totalModeled.reduce((a, b) => a + b, 0),
      [ModelName.PROJECTED]: totalComparison.reduce((a, b) => a + b, 0),
    };
  });
}

const EndUsesAnnualizedChart: React.FC<Props> = ({
  initialModel,
  comparisonModel,
  rates,
  aspect,
  showUsd = false,
  showLegend = true,
}) => {
  const formatted = formatChartData(
    initialModel,
    comparisonModel,
    rates,
    showUsd
  );

  const barColor: ModelColors = {
    Baseline: colors.blue.main,
    Projected: colors.green.main,
  };

  return (
    <>
      <ResponsiveContainer aspect={aspect}>
        <BarChart data={formatted} barGap={2} barCategoryGap={15}>
          <XAxis
            dataKey="endUse"
            interval={0}
            tick={(props) => <CustomAxisTick {...props} />}
            axisLine={false}
            tickLine={false}
          />
          <YAxis tickFormatter={formatter} tickLine={false} tick={{ fontSize }}>
            <Label
              value={showUsd ? Labels.USD : "kBTU"}
              position="insideLeft"
              angle={-90}
              offset={yAxisOffset}
              fontSize={fontSize}
            />
          </YAxis>
          <Tooltip
            content={
              <CustomTooltip
                formatting={(v) =>
                  showUsd ? `$${formatter(v)}` : formatter(v)
                }
              />
            }
            isAnimationActive={tooltipAnimationActive}
          />
          {showLegend && (
            <Legend
              verticalAlign="top"
              align="center"
              iconType="circle"
              wrapperStyle={{ top: -10 }}
            />
          )}
          {Object.values(ModelName).map((name, idx) => (
            <Bar
              isAnimationActive={chartAnimationActive}
              key={`end-use-contributions-${idx}`}
              dataKey={name}
              fill={barColor[name]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default EndUsesAnnualizedChart;
