import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GenerateStatementMutationVariables = Types.Exact<{
  input: Types.CreateApprovedDeltaMeterStatementInput;
}>;


export type GenerateStatementMutation = { createApprovedDeltaMeterStatement: { __typename: 'CreateApprovedDeltaMeterStatementResponse', deltaMeterStatement: { __typename: 'DeltaMeterStatement', id: string } } };


export const GenerateStatementDocument: DocumentNode<GenerateStatementMutation, GenerateStatementMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateStatement"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateApprovedDeltaMeterStatementInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createApprovedDeltaMeterStatement"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deltaMeterStatement"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]};