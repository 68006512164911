import { makeStyles } from "@material-ui/core";
import React from "react";
import { Text } from "recharts";
import { axisLabelFontSize as fontSize } from "../lib/reporting/config";
import colors from "../ui/colors";

const useStyles = makeStyles(() => ({
  root: {
    fill: colors.gray.dark,
    font: `${fontSize}px sans-serif`,
  },
}));

interface Props {
  x: number;
  y: number;
  payload: {
    value: string;
  };
  formatter?: Function;
}

const CustomAxisTick: React.FC<Props> = ({ x, y, payload, formatter }) => {
  const { root } = useStyles();

  return (
    <Text
      className={root}
      x={x}
      y={y}
      width={20}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {formatter ? formatter(payload.value) : payload.value}
    </Text>
  );
};

export default CustomAxisTick;
