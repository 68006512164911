import {
  Box,
  Divider,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { FC, KeyboardEvent } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { Currency } from "../api/graphql";
import sum from "../lib/sum";
import Button from "../ui/Button";
import { CardContent, CardHeader } from "../ui/Card";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import StripeCard from "../ui/StripeCard";
import { Expense, FormSections } from "./FinancialModelDashboard";
import MoneyField from "./MoneyField";
import PercentField from "./PercentField";

interface Props {}

// TODO: Pull out into /ui
export const StyledTextField = styled(TextField)(
  ({ theme: { typography } }) => ({
    "& .MuiInputBase-root": {
      fontSize: typography.body1.fontSize,
    },
  })
);

const AnnualExpensesForm: FC<Props> = () => {
  const { register, errors, control, watch } = useFormContext<FormSections>();

  const { fields, append, remove } = useFieldArray<Expense>({
    control,
    name: "expenses",
  });

  const watchedFields = watch("expenses");
  const addNewExpense = () => {
    append({ amount: { value: 0, unit: Currency.USD } });
  };

  return (
    <StripeCard>
      <CardHeader
        title={
          <Box
            width={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="button" color="primary">
              ANNUAL EXPENSES
            </Typography>
            <Button size="small" onClick={addNewExpense}>
              Add
            </Button>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid xs={5} item>
            Expense name
          </Grid>
          <Grid xs={4} item>
            Amount
          </Grid>
          <Grid xs={2} item>
            Escalation
          </Grid>
        </Grid>
        <Box py={1}>
          <Divider />
        </Box>
        {fields.length > 0 ? (
          <form>
            {fields.map((item, idx) => {
              return (
                <Grid container spacing={2} key={item.id}>
                  <Grid item xs={5}>
                    <StyledTextField
                      InputLabelProps={{ shrink: false }}
                      fullWidth
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Required",
                        },
                      })}
                      name={`expenses[${idx}].name`}
                      defaultValue={item.name ? `${item.name}` : ""}
                      size="small"
                      placeholder="Name"
                      error={
                        !!errors.expenses &&
                        !!errors.expenses[idx]?.name?.message
                      }
                      helperText={
                        errors.expenses && errors.expenses[idx]?.name?.message
                      }
                      inputProps={{
                        "data-lpignore": true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MoneyField
                      name={`expenses[${idx}].amount.value`}
                      control={control}
                      rules={{
                        valueAsNumber: true,
                        required: {
                          value: true,
                          message: "Required",
                        },
                      }}
                      defaultValue={item.amount?.value}
                      errorMsg={
                        errors.expenses &&
                        errors.expenses[idx]?.amount?.value?.message
                      }
                      placeholder="Cost"
                      precision={0}
                      inputProps={{
                        "data-lpignore": true,
                      }}
                    />
                    <input
                      type="hidden"
                      ref={register()}
                      name={`expenses[${idx}].amount.unit`}
                      defaultValue={Currency.USD}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <PercentField
                      name={`expenses[${idx}].escalation`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Required",
                        },
                        min: {
                          value: -100,
                          message: "Value must be from -100 to 100",
                        },
                        max: {
                          value: 100,
                          message: "Value must be from -100 to 100",
                        },
                      }}
                      onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          addNewExpense();
                        }
                      }}
                      defaultValue={item.escalation}
                      placeholder="Rate"
                      errorMsg={
                        errors.expenses &&
                        errors.expenses[idx]?.escalation?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        size="small"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => remove(idx)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </form>
        ) : (
          <PlaceholderContainer>
            <PlaceholderSegment subheader="No expenses have been added yet" />
          </PlaceholderContainer>
        )}
        {fields.length > 0 && (
          <>
            <Box py={1}>
              <Divider />
            </Box>
            <Grid container spacing={2}>
              <Grid xs={5} item>
                <Typography>
                  <b>Subtotal</b>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  <b>
                    <FormattedNumber
                      value={sum(
                        watchedFields.map((f) =>
                          f.amount.value ? f.amount.value : 0
                        )
                      )}
                      style={`currency`}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                      currency="USD"
                    />
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </StripeCard>
  );
};

export default AnnualExpensesForm;
