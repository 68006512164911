import { Divider, Grid, InputAdornment } from "@material-ui/core";
import { GraphQLError } from "graphql";
import React from "react";
import { useForm } from "react-hook-form";
import {
  EnergySource,
  EnergyUnitType,
  UtilityReadingLockReason,
} from "../api/graphql";
import { energySourceName } from "../lib/energySource";
import LabelledValue from "../lib/LabelledValue";
import { dateError, numberError, presenceError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import { DatePickerController } from "../ui/DatePicker";
import FormDialog from "../ui/FormDialog";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";
import { TextField } from "../ui/TextField";

export interface Props {
  meterName: string;
  energySource: EnergySource;
  unit: EnergyUnitType;
  onCancel: () => void;
  onSubmit: (v: Fields) => Promise<void>;
  error?: GraphQLError;
  startOn?: Date;
  endOn?: Date;
  quantity?: number;
  cost?: number;
  locked?: UtilityReadingLockReason | null;
}

interface Fields {
  startOn: Date | null;
  endOn: Date | null;
  quantity: number | null;
  cost: number | null;
}

const validations = ({ startOn, endOn, quantity, cost }: Fields) => ({
  startOn:
    presenceError(startOn) ||
    dateError(startOn!, {
      lt: new Date(),
      message: "Must be a valid date.",
    }),
  endOn:
    presenceError(endOn) ||
    dateError(endOn!, {
      lt: new Date(),
      gt: startOn ?? undefined,
      message: "Must be after the start date",
    }),
  quantity:
    presenceError(quantity) ||
    numberError(quantity!, { integer: true, gte: 0 }),
  cost: cost ? numberError(cost, { gte: 0 }) : null,
});

const referenceValidations = ({ cost }: Fields) => ({
  cost: cost ? numberError(cost, { gte: 0 }) : null,
});

const NewUtilityMeterReadingDialog: React.FC<Props> = ({
  meterName,
  energySource,
  unit,
  onCancel,
  onSubmit,
  error,
  startOn,
  endOn,
  quantity,
  cost,
  locked,
}) => {
  const {
    control,
    errors,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    register,
  } = useForm<Fields>({
    defaultValues: {
      startOn: startOn || null,
      endOn: endOn || null,
      quantity: quantity ?? null,
      cost: cost ?? null,
    },
    resolver: validatorsResolver(!!locked ? referenceValidations : validations),
  });

  return (
    <FormDialog
      title="Edit utility meter reading"
      onCancel={onCancel}
      onSubmit={handleSubmit((fields) =>
        onSubmit(fields)
          .then(() => reset())
          .catch(console.log)
      )}
      error={error}
      isSubmitting={isSubmitting}
      maxWidth="sm"
      dismissName="Close"
    >
      <Gaps spacing={2}>
        <FormSection label="Meter info">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabelledValue title="Name" value={meterName} />
            </Grid>
            <Grid item xs={6}>
              <LabelledValue
                title="Energy source"
                value={energySourceName(energySource)}
              />
            </Grid>
          </Grid>
        </FormSection>
        <Divider orientation="horizontal" />
        <FormSection label="Billing period">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DatePickerController
                locked={!!locked}
                lockExplanation="A building model depends on this field."
                label="Start date"
                name="startOn"
                control={control}
                error={errors.startOn?.message}
                disableFuture
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePickerController
                locked={!!locked}
                lockExplanation="A building model depends on this field."
                label="End date"
                name="endOn"
                control={control}
                error={errors.endOn?.message}
                disableFuture
              />
            </Grid>
          </Grid>
        </FormSection>
        <Divider orientation="horizontal" />
        <FormSection label="Reading">
          <TextField
            locked={!!locked}
            lockExplanation="A building model depends on this field."
            label="Quantity"
            name="quantity"
            type="number"
            required
            inputRef={register}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
            }}
          />

          <TextField
            label="Cost"
            name="cost"
            type="number"
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </FormSection>
      </Gaps>
    </FormDialog>
  );
};

export default NewUtilityMeterReadingDialog;
