import * as Types from '../api/graphql';

import { FileAssetModelFragment } from '../api/fragments/FileAssetModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FileAssetModelFragmentDoc } from '../api/fragments/FileAssetModel.generated';
export type UpdateDocumentMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationDocumentInput;
}>;


export type UpdateDocumentMutation = { updateOrganizationDocument: { __typename: 'UpdateOrganizationDocumentResponse', document: { __typename: 'OrganizationDocument', id: string, updatedAt: string, file: (
        { __typename: 'FileAsset' }
        & FileAssetModelFragment
      ) } } };


export const UpdateDocumentDocument: DocumentNode<UpdateDocumentMutation, UpdateDocumentMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateDocument"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateOrganizationDocumentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateOrganizationDocument"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"document"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FileAssetModel"}}]}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}},...FileAssetModelFragmentDoc.definitions]};