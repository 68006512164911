import { Tooltip } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import React from "react";

type Props = {
  locked: boolean;
  description?: string;
};

export const Locked = () => (
  <LockIcon fontSize="small" style={{ verticalAlign: "text-bottom" }} />
);

const Lockable: React.FC<Props> = ({ locked, description, children }) => (
  <Tooltip title={(locked && description) || ""}>
    <span>
      {children}
      {locked && <Locked />}
    </span>
  </Tooltip>
);

export default Lockable;
