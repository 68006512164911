import {
  Card as MUICard,
  CardActionArea as MUICardActionArea,
  CardActionAreaProps as MUICardActionAreaProps,
  CardProps as MUICardProps,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

type CardProps = Omit<MUICardProps, "variant">;

/**
 * Card is EnergyRM's standard card design
 */
const Card: React.FC<CardProps> = (props) => {
  return <MUICard variant="outlined" {...props} />;
};
export default Card;

type CardLinkedAreaProps = Omit<MUICardActionAreaProps<Link>, "onClick"> & {
  to: string;
};

/**
 * CardLinkedArea defines a card area that links to another route when clicked. It
 * should be an area that does not contain any other actions, to avoid misclicks.
 */
export const CardLinkedArea: React.FC<CardLinkedAreaProps> = ({
  to,
  ...props
}) => <MUICardActionArea {...props} component={Link} to={to} />;

export { CardActions, CardContent, CardHeader } from "@material-ui/core";
