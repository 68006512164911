import {
  Box,
  Divider,
  FormHelperText,
  makeStyles,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { updatedDiff } from "deep-object-diff";
import deepmerge from "deepmerge";
import { GraphQLError } from "graphql";
import millify from "millify";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { DeepNullable, DeepPartial } from "ts-essentials";
import { BuildingModelParametersFieldsFragment } from "../api/fragments/BuildingModelParametersFields.generated";
import BulbLg from "../assets/BulbLg";
import BulbMd from "../assets/BulbMd";
import BulbSm from "../assets/BulbSm";
import ChangeLg from "../assets/ChangeLg";
import ChangeMd from "../assets/ChangeMd";
import ChangeSm from "../assets/ChangeSm";
import {
  descriptionError,
  longTextError,
  nameError,
  textFieldLimits,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import { ButtonDetails } from "../ui/ButtonGroupSelect";
import { ButtonsWithSliderController } from "../ui/ButtonsWithSlider";
import FormDialog from "../ui/FormDialog";
import InputChars from "../ui/InputChars";
import { TextField } from "../ui/TextField";
import { CalibratedModelQuery } from "./CalibratedModel.generated";

// TODO: where do we keep domain types
export type Overrides = DeepNullable<
  DeepPartial<{
    hotWater: { setTemp: number };
    lighting: number;
    baseload: { exterior: { electricity: number } };
    spaceHeating: { fanPortion: number };
  }>
>;

type CalibratedModelParameters = BuildingModelParametersFieldsFragment;
type CalibratedModelOverrides = CalibratedModelQuery["calibratedModel"]["overrides"];

export interface Props {
  onSubmit: (values: Fields) => Promise<void>;
  onCancel: () => void;
  buildingSquareFeet: number;
  calibratedModelName: string;
  parameters: CalibratedModelParameters;
  initialOverrides: CalibratedModelOverrides;
  error?: GraphQLError;
  superuser?: boolean;
}

interface Fields {
  overrides: Overrides;
  name: string;
  description: string;
  notes: string;
}

const LightingEfficiencyLimits = {
  MIN: 0.2,
  MED: 0.4,
  MAX: 0.6,
  STEP: 0.05,
};

const createExteriorBaseloadLimits = (limit: number) => ({
  MIN: 0,
  LOW: 0.1 * limit,
  MID: 0.3 * limit,
  HIGH: 0.5 * limit,
  MAX: limit,
  STEP: 0.1,
});

const DomesticHotWaterLimits = {
  MIN: 90,
  LOW: 115,
  DEFAULT: 125,
  HIGH: 140,
  MAX: 150,
  STEP: 5,
};

const useStyles = makeStyles(({ spacing }) => ({
  dividerPadding: {
    marginBottom: spacing(2),
  },
}));

const lightingButtonConfig = (v: number): ButtonDetails[] => [
  {
    icon: <Check fontSize="large" />,
    label: "EnRM Calculated",
    value: v,
  },
  {
    icon: <BulbLg />,
    label: "Efficient",
    value: LightingEfficiencyLimits.MIN,
  },
  {
    icon: <BulbMd />,
    label: "Conventional",
    value: LightingEfficiencyLimits.MED,
  },
  {
    icon: <BulbSm />,
    label: "Inefficient",
    value: LightingEfficiencyLimits.MAX,
  },
];

const domesticHotWaterButtonConfig = (v: number): ButtonDetails[] => [
  {
    icon: <Check fontSize="large" />,
    label: "EnRM Calculated",
    value: v,
  },
  {
    icon: <ChangeSm />,
    label: "Below average",
    value: DomesticHotWaterLimits.LOW,
  },
  {
    icon: <ChangeLg />,
    label: "Above average",
    value: DomesticHotWaterLimits.HIGH,
  },
];

// Fixes button range in relation to the maximum available electric baseload
const exteriorEnergyButtonConfig = (
  v: number,
  parameters: CalibratedModelParameters
): ButtonDetails[] => {
  const { LOW, MID, HIGH } = createExteriorBaseloadLimits(
    sumElectricBaseload(parameters)
  );

  return [
    {
      icon: <Check fontSize="large" />,
      label: "EnRM Calculated",
      value: v,
    },
    {
      icon: <ChangeSm />,
      label: "Below average",
      value: LOW,
    },
    {
      icon: <ChangeMd />,
      label: "Average",
      value: MID,
    },
    {
      icon: <ChangeLg />,
      label: "Above average",
      value: HIGH,
    },
  ];
};

const validations = ({ name, description, notes }: Fields) => ({
  name: nameError(name),
  description: descriptionError(description),
  notes: longTextError(notes),
});

/**
 * deep cloning, limited to JSON-compatible objects
 */
function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Removes identifier property from a JSON-compatible object
 * (Used to pluck __typename)
 */
function omitDeep<T>(obj: T, identifier: string): T {
  return JSON.parse(
    JSON.stringify(obj, (k, v) => (k === identifier ? undefined : v))
  );
}

/**
 * Creates a sum of the electrical baseload for use in validation
 */
export function sumElectricBaseload(
  obj: CalibratedModelParameters | Overrides
): number {
  const values = [obj.lighting, obj.baseload?.exterior?.electricity];

  if (obj.baseload) {
    if ("interior" in obj.baseload) {
      values.push(obj?.baseload?.interior?.electricity);
    }
  }

  return values.reduce<number>((a, c) => a + (c || 0), 0);
}

const NewCalibratedModelDialog: React.FC<Props> = ({
  onCancel,
  calibratedModelName,
  parameters,
  buildingSquareFeet,
  initialOverrides,
  error,
  ...props
}) => {
  const [formError, setFormError] = React.useState("");
  const { dividerPadding } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const exteriorBaseloadLimits = createExteriorBaseloadLimits(
    sumElectricBaseload(parameters)
  );

  const defaultValues: Fields = {
    name: "",
    description: "",
    notes: "",
    overrides: clone(parameters),
  };
  const {
    watch,
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<Fields>({
    defaultValues,
    resolver: validatorsResolver(validations),
  });

  const onSubmit = async ({ overrides, ...values }: Fields) => {
    const updatedOverrides = updatedDiff(parameters, overrides);

    if (Object.keys(updatedOverrides).length === 0 && !props.superuser) {
      setFormError("You must change a property of the model.");
      return;
    } else {
      setFormError("");
    }

    const submittedOverrides = initialOverrides
      ? deepmerge(omitDeep(initialOverrides, "__typename"), updatedOverrides)
      : updatedOverrides;

    if (
      sumElectricBaseload(submittedOverrides) > sumElectricBaseload(parameters)
    ) {
      setFormError(
        "Lighting and/or exterior energy settings exceed available electrical baseload. Please lower at least one value."
      );
      return;
    } else {
      setFormError("");
    }

    await props.onSubmit({
      ...values,
      overrides: submittedOverrides,
    });
  };

  return (
    <FormDialog
      title={`Create calibration from ${calibratedModelName}`}
      submitName="Create calibration"
      error={error}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      maxWidth="md"
    >
      <Box display="flex" flexDirection={matches ? "column" : "row"}>
        <Box
          display="flex"
          flexDirection="column"
          flexBasis={0}
          flexGrow={1}
          px={2}
          position={matches ? "static" : "fixed"}
          width={matches ? "100%" : 400}
        >
          <TextField
            name="name"
            inputRef={register}
            error={errors.name?.message}
            type="text"
            id="name"
            label="Model name"
            required
            size="small"
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.name.max,
                minLength: textFieldLimits.name.min,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.name.max}
            current={watch("name")}
          />
          <TextField
            name="description"
            inputRef={register}
            error={errors.description?.message}
            type="text"
            id="description"
            label="Description"
            required
            size="small"
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.description.max,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.description.max}
            current={watch("description")}
          />
          <TextField
            name="notes"
            inputRef={register}
            error={errors.notes?.message}
            type="text"
            id="notes"
            label="Model notes"
            size="small"
            multiline
            rows={4}
            InputProps={{
              inputProps: {
                maxLength: textFieldLimits.notes.max,
              },
            }}
          />
          <InputChars
            limit={textFieldLimits.notes.max}
            current={watch("notes")}
          />
        </Box>
        <Divider orientation="vertical" />
        <Box
          display="flex"
          flexDirection="column"
          flexBasis={0}
          flexGrow={1}
          px={2}
          alignItems="flex-end"
        >
          <Box
            display="flex"
            width={matches ? "100%" : "50%"}
            flexDirection="column"
          >
            {formError && <FormHelperText error>{formError}</FormHelperText>}
            <Typography>
              Specify the building’s interior lighting efficiency
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="overrides.lighting"
                control={control}
                buttonConfig={lightingButtonConfig(parameters.lighting)}
                caption="Watts / Ft²"
                formatter={(v) => v.toFixed(2)}
                min={LightingEfficiencyLimits.MIN}
                max={LightingEfficiencyLimits.MAX}
                step={LightingEfficiencyLimits.STEP}
              />
            </Box>

            <Divider className={dividerPadding} />

            <Typography>
              Specify the building’s exterior energy consumption (including
              outdoor lighting)
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="overrides.baseload.exterior.electricity"
                control={control}
                buttonConfig={exteriorEnergyButtonConfig(
                  parameters.baseload.exterior?.electricity || 0,
                  parameters
                )}
                caption="Watts"
                min={exteriorBaseloadLimits.MIN}
                max={exteriorBaseloadLimits.MAX}
                step={exteriorBaseloadLimits.STEP}
                formatter={(v) =>
                  millify(Math.floor(v * buildingSquareFeet), {
                    precision: 0,
                  })
                }
              />
            </Box>

            <Divider className={dividerPadding} />

            <Typography>
              Specify the building's domestic hot water heating set point
            </Typography>
            <Box p={2}>
              <ButtonsWithSliderController
                name="overrides.hotWater.setTemp"
                control={control}
                buttonConfig={domesticHotWaterButtonConfig(
                  parameters.hotWater.setTemp
                )}
                formatter={(v) => `${v}°F`}
                min={DomesticHotWaterLimits.MIN}
                max={DomesticHotWaterLimits.MAX}
                step={DomesticHotWaterLimits.STEP}
              />
            </Box>

            <Divider className={dividerPadding} />

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>Forced air central heating system</Typography>
              <Box>
                <Controller
                  control={control}
                  name="overrides.spaceHeating.fanPortion"
                  render={({ value, onChange }) => (
                    <Switch
                      color="primary"
                      checked={value > 0}
                      onChange={({ target: { checked } }) => {
                        onChange(
                          checked ? parameters.spaceHeating.fanPortion : 0
                        );
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </FormDialog>
  );
};

export default NewCalibratedModelDialog;
