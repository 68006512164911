import { styled, TextField, TextFieldProps } from "@material-ui/core";
import React, { FC, useEffect, useRef, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { useIntl } from "react-intl";
import safeNumber from "../lib/safeNumber";

export type ControlledFormattedNumberFieldProps = Omit<
  TextFieldProps,
  | "value"
  | "onChange"
  | "defaultValue"
  | "error"
  | "helperText"
  | "type"
  | "size"
  | "variant"
> & {
  value: number;
  onChange: (v: number) => void;
  places?: number;
  errorMsg?: string;
};

const StyledTextField = styled(TextField)({
  "& .MuiInput-input": {
    textAlign: "right",
  },
});

function maybeTruncate(value: number, places: number | undefined | null) {
  if (typeof places !== "number") return value;
  const scale = Math.pow(10, places || 0);
  return Math.floor(value * scale) / scale;
}

export const ControlledFormattedNumberField: FC<ControlledFormattedNumberFieldProps> = ({
  value,
  onChange,
  errorMsg,
  places,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string | null>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const { formatNumber } = useIntl();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!inputRef.current || cursorPosition === null) {
      return;
    }
    const positionFromStart = inputRef.current.value.length - cursorPosition;
    inputRef.current.setSelectionRange(positionFromStart, positionFromStart);
  }, [cursorPosition, value]);

  return (
    <StyledTextField
      value={internalValue || formatNumber(value ?? 0)}
      onChange={(e) => {
        setCursorPosition(
          e.target.value.length - (e.target.selectionStart || 0)
        );
        if (e.target.value === "") {
          onChange(0);
          setInternalValue(null);
          return;
        }

        const num = parseFloat(e.target.value.replace(/[^\d.-]/g, ""));
        if (isNaN(num) || num === (value ?? 0)) {
          // number is in progress!
          setInternalValue(e.target.value);
        } else if (safeNumber(num)) {
          onChange(maybeTruncate(num, places));
          setInternalValue(null);
        }
      }}
      size="small"
      fullWidth
      error={!!errorMsg}
      helperText={errorMsg}
      onFocus={(e) => e.target.select()}
      inputRef={inputRef}
      {...props}
    />
  );
};

type FormattedNumberFieldProps = Omit<
  ControlledFormattedNumberFieldProps,
  "value" | "onChange"
> & {
  control: Control;
  name: string;
  defaultValue?: number;
  rules?: RegisterOptions;
};

/**
 * FormattedNumberField is a numeric input type that parses and formats numbers.
 */
const FormattedNumberField: FC<FormattedNumberFieldProps> = ({
  control,
  name,
  defaultValue,
  rules,
  id,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || null}
      render={({ value, onChange }) => (
        <ControlledFormattedNumberField
          value={value}
          onChange={onChange}
          id={id || name}
          {...props}
        />
      )}
    />
  );
};

export default FormattedNumberField;
