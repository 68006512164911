import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { DeltaMeterStatementModelFragment } from "../api/fragments/DeltaMeterStatementModel.generated";
import {
  DeltaMeterStatementState,
  EnergySource,
  EnergyUnitType,
} from "../api/graphql";
import { earliest, latest } from "../lib/dates";
import displayUnit from "../lib/displayUnit";
import { energySourceName } from "../lib/energySource";
import FormattedRange from "../lib/FormattedRange";
import sum from "../lib/sum";
import targetUnitConverter from "../lib/targetUnitConverter";
import unique from "../lib/unique";
import { EntityStatusChip } from "../ui/EntityStatusChip";
import Gaps from "../ui/Gaps";

type DeltaMeterReadings = DeltaMeterStatementModelFragment["readings"];

interface DataPoint {
  energySource: EnergySource;
  baselineKbtu: number;
  nonroutineAdjustmentsKbtu: number;
  actualUtilityUsageKbtu: number;
  readingPeriodStart: Date;
  readingPeriodEnd: Date;
  totalEEProduced: number;
}

interface Props {
  entity: DeltaMeterStatementModelFragment;
  timeZone: string;
}

const BottomRow = styled(TableRow)({
  "& > td": {
    borderBottom: "unset",
  },
});

export const StatusChip: FC<{ status: DeltaMeterStatementState }> = ({
  status,
}) => {
  const { palette } = useTheme();

  const statusColors: Record<DeltaMeterStatementState, string> = {
    DRAFT: palette.warning.main,
    APPROVED: palette.primary.main,
    VOID: palette.grey[500],
  };

  return (
    <EntityStatusChip
      backgroundColor={statusColors[status]}
      size="small"
      label={status}
    />
  );
};

/**
 * Intakes an array of source-mixed DeltaMeter readings and yields statement subtotal rows
 * for each energy source
 */
const createAggregateStatementReadingRows = (
  readings: DeltaMeterReadings
): DataPoint[] => {
  return unique(readings.map((r) => r.energySource)).map((energySource) => {
    const filtered = readings.filter((r) => r.energySource === energySource);

    const converter = targetUnitConverter(EnergyUnitType.KBTU);
    const actualUtilityUsageKbtu = sum(
      filtered.map((f) => converter(f.actual, f.unit))
    );
    const baselineKbtu = sum(
      filtered.map((f) => converter(f.baseline, f.unit))
    );
    const adjustedBaselineKbtu = sum(
      filtered.map((f) => converter(f.adjustedBaseline, f.unit))
    );
    const nonroutineAdjustmentsKbtu = adjustedBaselineKbtu - baselineKbtu;
    const readingPeriodStart = earliest(
      filtered.map((d) => new Date(d.interval.start))
    );
    const readingPeriodEnd = latest(
      filtered.map((d) => new Date(d.interval.end))
    );
    const totalEEProduced =
      baselineKbtu + nonroutineAdjustmentsKbtu - actualUtilityUsageKbtu;

    return {
      energySource,
      baselineKbtu,
      nonroutineAdjustmentsKbtu,
      actualUtilityUsageKbtu,
      readingPeriodStart,
      readingPeriodEnd,
      totalEEProduced,
    };
  });
};

const DeltaMeterStatementDialog: FC<Props> = ({ entity, timeZone }) => {
  const {
    readings,
    shortId,
    createdAt,
    deltaMeter: { buildingCalibratedModel, building },
    state: statementState,
  } = entity;

  const { streetAddress, locality, region, postalCode } = building.address;

  const earliestReferenceDate = earliest(
    buildingCalibratedModel.reference.map((r) => new Date(r.interval.start))
  );
  const latestReferenceDate = latest(
    buildingCalibratedModel.reference.map((r) => new Date(r.interval.end))
  );
  const earliestReadingDate = earliest(
    readings.map((r) => new Date(r.interval.start))
  );
  const latestReadingDate = latest(
    readings.map((r) => new Date(r.interval.end))
  );
  const daysInStatement = Math.round(
    DateTime.fromJSDate(latestReadingDate).diff(
      DateTime.fromJSDate(earliestReadingDate),
      "days"
    ).days
  );

  const serviceAddress = `${streetAddress}, ${locality} ${region}, ${postalCode}`;

  return (
    <Gaps spacing={2}>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            Status
            <Box display="inline" pl={1}>
              <StatusChip status={statementState} />
            </Box>
          </Typography>
          <Typography variant="body2">
            Created <FormattedDate value={createdAt} />
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          DeltaMeter ID #{shortId}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          Reference period:{" "}
          <FormattedRange
            from={earliestReferenceDate}
            to={latestReferenceDate}
            timeZone={timeZone}
          />
        </Typography>
        <Typography variant="body2">
          Statement included:{" "}
          <FormattedRange
            from={earliestReadingDate}
            to={latestReadingDate}
            timeZone={timeZone}
          />
        </Typography>
        <Typography variant="body2">
          Days in statement: {daysInStatement}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          Service address: {serviceAddress}
        </Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Reading period</b>
            </TableCell>
            <TableCell>
              <b>Energy source</b>
            </TableCell>
            <TableCell colSpan={2}>
              <b>EE calculated ({displayUnit(EnergyUnitType.KBTU)})</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {createAggregateStatementReadingRows(readings).map((row) => {
            const {
              energySource,
              baselineKbtu,
              nonroutineAdjustmentsKbtu,
              actualUtilityUsageKbtu,
              readingPeriodStart,
              readingPeriodEnd,
              totalEEProduced,
            } = row;

            return (
              <TableRow>
                <TableCell>
                  <FormattedRange
                    from={readingPeriodStart}
                    to={readingPeriodEnd}
                    timeZone={timeZone}
                  />
                </TableCell>
                <TableCell>{energySourceName(energySource)}</TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap>
                    <em>Baseline</em>
                  </Typography>
                  <Typography variant="body2" noWrap>
                    <em>Nonroutine adjustment(s)</em>
                  </Typography>
                  <Typography variant="body2" noWrap>
                    <em>Less utility usage</em>
                  </Typography>
                  <Typography variant="body2" noWrap>
                    <b>Subtotal</b>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2">
                    <FormattedNumber value={baselineKbtu} />
                  </Typography>
                  <Typography variant="body2">
                    <FormattedNumber value={nonroutineAdjustmentsKbtu} />
                  </Typography>
                  <Typography variant="body2">
                    <FormattedNumber value={-actualUtilityUsageKbtu} />
                  </Typography>
                  <Typography variant="body2">
                    <b>
                      <FormattedNumber value={totalEEProduced} />
                    </b>
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
          <BottomRow>
            <TableCell colSpan={3}>
              <b>
                Total energy efficiency produced (
                {displayUnit(EnergyUnitType.KBTU)})
              </b>
            </TableCell>
            <TableCell align="right">
              <b>
                <FormattedNumber
                  value={sum(
                    createAggregateStatementReadingRows(readings).map(
                      (r) => r.totalEEProduced
                    )
                  )}
                />
              </b>
            </TableCell>
          </BottomRow>
        </TableBody>
      </Table>
    </Gaps>
  );
};

export default DeltaMeterStatementDialog;
