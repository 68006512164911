import React, { FC } from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

const NoInvoiceActivity: FC<{}> = () => (
  <PlaceholderContainer>
    <PlaceholderSegment
      header="No invoices"
      subheader="There are no invoices for this Transaction yet"
    />
  </PlaceholderContainer>
);

export default NoInvoiceActivity;
