import { makeStyles } from "@material-ui/core";
import { SpeedDial as MUISpeedDial } from "@material-ui/lab";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import React, { FC, useState } from "react";

interface Props {}

const useStyles = makeStyles(({ spacing }) => ({
  speedDial: {
    position: "fixed",
    bottom: spacing(2),
    right: spacing(2),
  },
}));

const SpeedDial: FC<Props> = ({ children }) => {
  const { speedDial } = useStyles();
  const [dialOpen, setDialOpen] = useState<boolean>(false);

  return (
    <MUISpeedDial
      className={speedDial}
      ariaLabel="admin-speed-dial"
      open={dialOpen}
      onClose={(_: any, reason: string) =>
        reason === "toggle" && setDialOpen(false)
      }
      onOpen={(_: any, reason: string) =>
        reason === "toggle" && setDialOpen(true)
      }
      icon={<SpeedDialIcon />}
      direction="up"
    >
      {typeof children === "function" ? children(dialOpen) : children}
    </MUISpeedDial>
  );
};

export default SpeedDial;
