import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedDate, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Currency,
  EnergySource,
  MeterSource,
  ReadingOutlierAlert,
  ReadingsGapAlert,
  ReadingsOverlapAlert,
  UtilityReadingLockReason,
} from "../api/graphql";
import assertFeature from "../lib/assertFeature";
import displayUnit from "../lib/displayUnit";
import { utilityMeters } from "../lib/endpoints";
import { energySourceName } from "../lib/energySource";
import isReadOnly from "../lib/isReadOnly";
import pluckNodes from "../lib/pluckNodes";
import useDeleteEntity from "../lib/useDeleteEntity";
import { dateError, numberError, presenceError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import BackButton from "../ui/BackButton";
import Button from "../ui/Button";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import colors from "../ui/colors";
import { DatePickerController } from "../ui/DatePicker";
import Loading from "../ui/Loading";
import MetaData from "../ui/MetaData";
import { TablePlaceholder } from "../ui/PlaceholderSegment";
import ProductCopy from "../ui/ProductCopy";
import { TextField } from "../ui/TextField";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle, SectionTitle } from "../ui/Typography";
import NewUtilityMeterDialog, {
  Solar,
} from "../UtilityMetersList/NewUtilityMeterDialog";
import { CreateUtilityReadingDocument } from "./CreateUtilityMeterReading.generated";
import DeleteReadingButton from "./DeleteReadingButton";
import { DeleteUtilityMeterDocument } from "./DeleteUtilityMeter.generated";
import EditReadingButton from "./EditReadingButton";
import OverlapAlerts from "./OverlapAlerts";
import { UpdateUtilityMeterDocument } from "./UpdateUtilityMeter.generated";
import {
  UtilityMeterDocument,
  UtilityMeterQuery,
} from "./UtilityMeterQuery.generated";

interface Props {
  utilityMeterId: string;
}

interface Fields {
  startOn: Date | null;
  endOn: Date | null;
  quantity: number | null;
  cost: number | null;
}

export interface MixedReading {
  __typename:
    | "ReadingsGapAlert"
    | "ReadingsOverlapAlert"
    | "UtilityMeterReading";
  id?: string | null;
  unit?: string;
  startOn: Date;
  endOn: Date;
  quantity?: number | null;
  cost?: number | null;
  locked?: UtilityReadingLockReason | null;
}

const useStyles = makeStyles(({ palette }) => ({
  container: {
    maxHeight: "75vh",
  },
  headerCell: {
    color: palette.common.black,
    backgroundColor: palette.grey[300],
  },
}));

const WarningCell = styled(TableCell)({
  backgroundColor: colors.yellow.light,
});

const validations = ({ startOn, endOn, quantity, cost }: Fields) => ({
  startOn:
    presenceError(startOn) ||
    dateError(startOn!, {
      lt: new Date(),
      message: "Must be a valid date.",
    }),
  endOn:
    presenceError(endOn) ||
    dateError(endOn!, {
      lt: new Date(),
      gt: startOn ?? undefined,
      message: "Must be after the start date",
    }),
  quantity:
    presenceError(quantity) ||
    numberError(quantity!, { integer: true, gte: 0 }),
  cost: cost ? numberError(cost, { gte: 0 }) : null,
});

const NewReading: React.FC<{
  onSubmit: () => void;
  utilityMeter: UtilityMeterQuery["utilityMeter"];
}> = ({ utilityMeter, onSubmit }) => {
  const createToast = useToastContext();
  const [
    createUtilityReading,
    { error: utilityMeterReadingError },
  ] = useMutation(CreateUtilityReadingDocument);
  const firstInput = useRef<HTMLInputElement>();

  const {
    control,
    errors,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
    register,
  } = useForm<Fields>({
    defaultValues: {
      startOn: null,
      endOn: null,
      quantity: null,
      cost: null,
    },
    resolver: validatorsResolver(validations),
  });

  const doSubmit = async ({ quantity, startOn, endOn, cost }: Fields) => {
    try {
      await createUtilityReading({
        variables: {
          input: {
            utilityMeterId: utilityMeter.id,
            unit: utilityMeter.unit,
            quantity: Number(quantity) || 0,
            startOn,
            endOn,
            cost:
              cost != null
                ? {
                    value: Number(cost),
                    unit: Currency.USD,
                  }
                : null,
          },
        },
      });
      onSubmit();
      createToast(
        `New reading of ${quantity} ${utilityMeter.unit}s added`,
        "success"
      );
    } catch (err) {
      if (assertFeature("READINGS_TABLE")) {
        createToast(err.message, "error");
      }
    }
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      firstInput.current?.focus();
    }
  }, [isSubmitSuccessful, reset]);

  if (assertFeature("READINGS_TABLE")) {
    return (
      <TableRow>
        <TableCell>
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <DatePickerController
              name="startOn"
              control={control}
              error={errors.startOn?.message}
              suppressInternalErrors
              disableFuture
              inputProps={{ ref: firstInput }}
            />
            &nbsp;-&nbsp;
            <DatePickerController
              name="endOn"
              control={control}
              error={errors.endOn?.message}
              suppressInternalErrors
              disableFuture
            />
          </Grid>
        </TableCell>
        <TableCell>
          <TextField
            size="small"
            label="Quantity"
            name="quantity"
            type="number"
            required
            error={errors.quantity?.message}
            inputRef={register}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {displayUnit(utilityMeter.unit)}
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            size="small"
            label="Cost"
            name="cost"
            type="number"
            error={errors.quantity?.message}
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <Button
            disabled={isSubmitting}
            size="small"
            primary
            onClick={handleSubmit(doSubmit)}
            type="submit"
          >
            {isSubmitting ? <CircularProgress size={20} /> : "Add"}
          </Button>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {utilityMeterReadingError && (
        <FormHelperText error>
          {utilityMeterReadingError.message}
        </FormHelperText>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePickerController
            label="Start date"
            name="startOn"
            control={control}
            error={errors.startOn?.message}
            disableFuture
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerController
            label="End date"
            name="endOn"
            control={control}
            error={errors.endOn?.message}
            disableFuture
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            label="Quantity"
            name="quantity"
            type="number"
            required
            inputRef={register}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {displayUnit(utilityMeter.unit)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            label="Cost"
            name="cost"
            type="number"
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Box pt={2} width={1} display="flex" justifyContent="flex-end">
        <Button
          disabled={isSubmitting}
          size="small"
          primary
          onClick={handleSubmit(doSubmit)}
          type="submit"
        >
          {isSubmitting ? <CircularProgress size={20} /> : "Add reading"}
        </Button>
      </Box>
    </>
  );
};

const UtilityMeter: React.FC<Props> = ({ utilityMeterId }) => {
  const { formatNumber } = useIntl();
  const history = useHistory();
  const { data, loading, error, refetch } = useQuery(UtilityMeterDocument, {
    variables: { id: utilityMeterId },
  });
  const { container, headerCell } = useStyles();
  const [updateUtilityMeter, { error: utilityMeterUpdateError }] = useMutation(
    UpdateUtilityMeterDocument
  );
  const [deleteUtilityMeter] = useDeleteEntity(DeleteUtilityMeterDocument);
  const [showEditMeterDialog, setShowEditMeterDialog] = useState<boolean>(
    false
  );
  const createToast = useToastContext();

  if (error) throw error;

  if (loading || !data) return <Loading variant="circle" />;

  const { utilityMeter } = data;
  const hasModels = !!utilityMeter.building.calibratedModel;

  const meterReadings: MixedReading[] = pluckNodes(utilityMeter.readings).map(
    (r) => ({
      __typename: r.__typename,
      id: r.id,
      startOn: new Date(r.startOn),
      endOn: new Date(r.endOn),
      cost: r.cost ? r.cost.value : null,
      quantity: r.quantity,
      locked: r.locked,
    })
  );

  const gapAlerts: MixedReading[] = utilityMeter.alerts
    .filter((a): a is ReadingsGapAlert => a.__typename === "ReadingsGapAlert")
    .map((a) => ({
      __typename: a.__typename,
      startOn: a.interval.start,
      endOn: a.interval.end,
    }));

  const outlierAlerts = utilityMeter.alerts.filter(
    (a): a is ReadingOutlierAlert => a.__typename === "ReadingOutlierAlert"
  );

  // TODO: Integrate this with the table's cells
  const overlapAlerts: MixedReading[] = utilityMeter.alerts
    .filter(
      (a): a is ReadingsOverlapAlert => a.__typename === "ReadingsOverlapAlert"
    )
    .map((a) => ({
      __typename: a.__typename,
      startOn: a.interval.start,
      endOn: a.interval.end,
    }));

  const mixedReadings: MixedReading[] = meterReadings
    .concat(gapAlerts)
    .sort((a, b) => new Date(b.endOn).getTime() - new Date(a.endOn).getTime());

  return (
    <>
      <BackButton
        to={utilityMeters({
          buildingId: utilityMeter.building.id,
        })}
        label={`Back to ${ProductCopy.UTILITY_METERS}`}
      />
      <div>
        {showEditMeterDialog && (
          <NewUtilityMeterDialog
            title="Edit utility meter"
            submitButtonName="Save"
            hasModels={hasModels}
            onCancel={() => setShowEditMeterDialog(false)}
            onSubmit={async ({ meterId, unit, energySource, utilityName }) => {
              try {
                await updateUtilityMeter({
                  variables: {
                    input: {
                      utilityMeterId,
                      externalId: meterId,
                      unit,
                      utilityName: utilityName || null,
                      energySource:
                        energySource === "SOLAR"
                          ? EnergySource.ELECTRICITY
                          : energySource,
                      source:
                        energySource === "SOLAR"
                          ? MeterSource.SOLAR
                          : MeterSource.GRID,
                    },
                  },
                });

                refetch();
                setShowEditMeterDialog(false);
                createToast("Meter successfully edited", "success");
              } catch (err) {
                // Errors are handled through the 'error' prop of the form, via form helper text
              }
            }}
            meterId={utilityMeter.externalId || ""}
            energySource={
              utilityMeter.energySource === EnergySource.ELECTRICITY &&
              utilityMeter.source === MeterSource.SOLAR
                ? Solar.SOLAR
                : utilityMeter.energySource
            }
            unit={utilityMeter.unit}
            utilityName={utilityMeter.utilityName || ""}
            error={utilityMeterUpdateError?.graphQLErrors[0]}
          />
        )}
        <Box
          pb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <PageTitle>{utilityMeter.externalId || utilityMeter.id}</PageTitle>
            <MetaData>
              <Typography component="span">
                {energySourceName(utilityMeter.energySource)}{" "}
                {utilityMeter.source === MeterSource.SOLAR && "(solar)"}
              </Typography>
              <Typography component="span">
                Unit: {displayUnit(utilityMeter.unit)}
              </Typography>
              <Typography component="span">
                {utilityMeter.readings.count} readings
              </Typography>
              {utilityMeter.utilityName && (
                <Typography component="span">
                  Utility company: {utilityMeter.utilityName}
                </Typography>
              )}
            </MetaData>
          </div>
          <Box display="flex" alignItems="center">
            {!isReadOnly(utilityMeter.building.access) && (
              <Box pr={1}>
                <Button
                  size="small"
                  onClick={() => setShowEditMeterDialog(true)}
                >
                  Edit meter
                </Button>
              </Box>
            )}
            {!isReadOnly(utilityMeter.building.access) && (
              <ButtonWithConfirmation
                size="small"
                header="Delete this meter?"
                description="This action cannot be undone"
                onClick={async () => {
                  try {
                    await deleteUtilityMeter(utilityMeter, {
                      input: { utilityMeterId: utilityMeter.id },
                    });

                    history.push(
                      utilityMeters({
                        buildingId: utilityMeter.building.id,
                      })
                    );

                    createToast("Meter was deleted", "success");
                  } catch (err) {
                    createToast(err.message, "error");
                  }
                }}
                locked={hasModels}
                tooltip={
                  hasModels ? "Building models refer to this data" : undefined
                }
              >
                Delete meter
              </ButtonWithConfirmation>
            )}
          </Box>
        </Box>
        <Grid container spacing={2}>
          {!assertFeature("READINGS_TABLE") &&
            !isReadOnly(utilityMeter.building.access) && (
              <Grid item sm={12} md={5}>
                <Card>
                  <CardHeader
                    title={<SectionTitle>Add reading</SectionTitle>}
                  />
                  <CardContent>
                    <NewReading
                      onSubmit={refetch}
                      utilityMeter={utilityMeter}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
          <Grid
            item
            sm={12}
            md={
              assertFeature("READINGS_TABLE") ||
              isReadOnly(utilityMeter.building.access)
                ? 12
                : 7
            }
          >
            {overlapAlerts.length > 0 && !hasModels && (
              <>
                <Alert severity="warning">
                  <AlertTitle>Date overlaps exist</AlertTitle>
                  Too many readings for these time periods:
                  <OverlapAlerts
                    timeZone={utilityMeter.building.address.timeZone}
                    overlapAlerts={overlapAlerts}
                  />
                </Alert>
                <br />
              </>
            )}
            {outlierAlerts.length > 0 && (
              <>
                {outlierAlerts.map((oa, idx) => {
                  const reading = meterReadings.find(
                    (r) => r.id === oa.readingId
                  );

                  return (
                    <Alert key={`outlier-alert-${idx}`} severity="info">
                      The reading from{" "}
                      <FormattedDate
                        value={new Date(reading!.startOn)}
                        timeZone={utilityMeter.building.address.timeZone}
                      />{" "}
                      to{" "}
                      <FormattedDate
                        value={new Date(reading!.endOn)}
                        timeZone={utilityMeter.building.address.timeZone}
                      />{" "}
                      seems either too high or too low
                    </Alert>
                  );
                })}
                <br />
              </>
            )}
            <TableContainer
              component={Paper}
              variant="outlined"
              className={container}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={headerCell}>Billing Period</TableCell>
                    <TableCell className={headerCell}>Reading</TableCell>
                    <TableCell className={headerCell}>Cost</TableCell>
                    <TableCell className={headerCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assertFeature("READINGS_TABLE") &&
                    !isReadOnly(utilityMeter.building.access) && (
                      <NewReading
                        onSubmit={refetch}
                        utilityMeter={utilityMeter}
                      />
                    )}
                  {mixedReadings.length === 0 && (
                    <TablePlaceholder
                      colSpan={4}
                      subheader="No meter readings have been added yet"
                    />
                  )}
                  {mixedReadings.map((reading, idx) => {
                    if (reading.__typename === "UtilityMeterReading") {
                      return (
                        <TableRow key={`readings-${idx}`}>
                          <TableCell>
                            <FormattedDate
                              value={new Date(reading.startOn)}
                              timeZone={utilityMeter.building.address.timeZone}
                            />
                            {" - "}
                            <FormattedDate
                              value={new Date(reading.endOn)}
                              timeZone={utilityMeter.building.address.timeZone}
                            />
                          </TableCell>
                          <TableCell>
                            {formatNumber(reading.quantity!)} {reading.unit}
                          </TableCell>
                          <TableCell>
                            {reading.cost
                              ? formatNumber(reading.cost, {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : "—"}
                          </TableCell>
                          <TableCell align="right">
                            <ButtonGroup>
                              {!isReadOnly(utilityMeter.building.access) && (
                                <DeleteReadingButton reading={reading} />
                              )}
                              {!isReadOnly(utilityMeter.building.access) && (
                                <EditReadingButton
                                  externalId={
                                    utilityMeter.externalId || "Meter"
                                  }
                                  energySource={utilityMeter.energySource}
                                  unit={utilityMeter.unit}
                                  timeZone={
                                    utilityMeter.building.address.timeZone
                                  }
                                  reading={reading}
                                />
                              )}
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      );
                    } else if (reading.__typename === "ReadingsGapAlert") {
                      return (
                        <TableRow key={`readings-${idx}`}>
                          <WarningCell colSpan={4}>
                            <Box display="flex" alignItems="center">
                              <ImportExportIcon fontSize="small" />
                              <Typography variant="caption">
                                This time period is missing utility meter data.
                              </Typography>
                            </Box>
                          </WarningCell>
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <br />
      </div>
    </>
  );
};

export default UtilityMeter;
