import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import StripeCard from "../ui/StripeCard";
import { KeyMetricLarge } from "../ui/Typography";

export interface Props {
  statistic: ReactNode;
  unit: ReactNode;
  title: ReactNode;
  percentChange?: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  textColor: {
    color: palette.primary.main,
  },
}));

const KpiStatistic: FC<Props> = ({ statistic, unit, title, percentChange }) => {
  const { textColor } = useStyles();

  return (
    <StripeCard orientation="left">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={1}
      >
        <Typography variant="overline" color="textSecondary">
          {title}
        </Typography>
        <KeyMetricLarge className={textColor}>
          {statistic}
          <Typography component="span" variant="subtitle1">
            &nbsp;{unit}
          </Typography>
          {percentChange && (
            <Typography component="span" variant="subtitle1">
              &nbsp;({percentChange})
            </Typography>
          )}
        </KeyMetricLarge>
      </Box>
    </StripeCard>
  );
};

export default KpiStatistic;
