import { useMutation, useQuery } from "@apollo/client";
import { Box, CircularProgress } from "@material-ui/core";
import TimelineIcon from "@material-ui/icons/Timeline";
import React from "react";
import sortAlpha from "../lib/sortAlpha";
import requireMeterUpdate, { notEmptyDates } from "../lib/staleMeters";
import useDeleteEntity from "../lib/useDeleteEntity";
import useLazyDialogData from "../lib/useLazyDialogData";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import { ActivateDeltaMeterDocument } from "./ActivateDeltaMeter.generated";
import { AllBuildingModelsDocument } from "./BuildingModels.generated";
import { CreateDeltaMeterDocument } from "./CreateDeltaMeter.generated";
import { DeleteDeltaMeterDocument } from "./DeleteDeltaMeter.generated";
import { DeltaMetersDocument } from "./DeltaMetersListQuery.generated";
import DeltaMetersTable from "./DeltaMetersTable";
import NewDeltaMeterDialog from "./NewDeltaMeterDialog";

interface Props {}

const DeltaMetersList: React.FC<Props> = () => {
  const { organization, isOrgAdmin } = useSessionWithUser();
  const { data, loading, error, refetch } = useQuery(DeltaMetersDocument, {
    fetchPolicy: "network-only",
    variables: { organizationId: organization.id },
    notifyOnNetworkStatusChange: true,
  });
  const {
    openDialog,
    closeDialog,
    data: dialogData,
    loading: dialogLoading,
  } = useLazyDialogData(AllBuildingModelsDocument, {
    fetchPolicy: "network-only",
  });

  const [createDeltaMeter, { error: createError }] = useMutation(
    CreateDeltaMeterDocument
  );
  const [activateDeltaMeter, { error: activationError }] = useMutation(
    ActivateDeltaMeterDocument
  );
  const [deleteDeltaMeter] = useDeleteEntity(DeleteDeltaMeterDocument);
  const createToast = useToastContext();

  if (error) throw error;
  if (!data || loading) return <Loading variant="circle" />;

  const deltaMeters = data.organization.deltaMeters.edges.map(({ node }) => ({
    ...node,
    lastStatementDate: node.lastStatementDate
      ? new Date(node.lastStatementDate)
      : null,
    statements: [],
    metersNeedUpdate: requireMeterUpdate(
      notEmptyDates(
        node.building.utilityMeters.map((um) => um.readings.lastReadOn)
      )
    ),
  }));

  return (
    <>
      <Box
        pb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <PageTitle>DeltaMeters</PageTitle>
        {isOrgAdmin &&
          (dialogLoading ? (
            <Button primary disabled size="small">
              <CircularProgress size={15} />
            </Button>
          ) : (
            <Button
              size="small"
              primary
              onClick={() => openDialog({ organizationId: organization.id })}
            >
              Request activation
            </Button>
          ))}
      </Box>
      {dialogData && (
        <NewDeltaMeterDialog
          onSubmit={async (fields) => {
            await createDeltaMeter({
              variables: {
                input: {
                  buildingCalibratedModelId: fields.initialModelId,
                  buildingAdjustedModelId: fields.comparisonModelId || null,
                  startOn: fields.startOn,
                },
              },
            });
            closeDialog();
            createToast("DeltaMeter was created", "success");
            refetch();
          }}
          error={(createError || activationError)?.graphQLErrors[0]}
          onCancel={closeDialog}
          buildingList={
            dialogData.organization?.buildings.edges
              .map((e) => e.node)
              .filter((b) => b.calibratedModels.length > 0)
              .sort((a, b) => sortAlpha(a.name, b.name)) || []
          }
        />
      )}
      {deltaMeters.length > 0 ? (
        <DeltaMetersTable
          onApprove={(deltaMeterId) =>
            activateDeltaMeter({
              variables: { input: { deltaMeterId } },
            }).then(() => createToast("DeltaMeter was activated", "success"))
          }
          onDelete={(id) =>
            deleteDeltaMeter(
              { __typename: "DeltaMeter", id },
              { input: { deltaMeterId: id } }
            )
              .then(() => refetch())
              .then(() => createToast("DeltaMeter was deleted", "success"))
          }
          deltaMeters={deltaMeters}
        />
      ) : isOrgAdmin ? (
        <PlaceholderContainer>
          <PlaceholderSegment
            icon={TimelineIcon}
            subheader="There are no DeltaMeters for this account."
            buttonText="Request your first DeltaMeter activation"
            onButtonClick={() =>
              openDialog({ organizationId: organization.id })
            }
          />
        </PlaceholderContainer>
      ) : (
        <PlaceholderContainer>
          <PlaceholderSegment
            icon={TimelineIcon}
            header="No DeltaMeters yet"
            subheader="There are no DeltaMeters for this account."
          />
        </PlaceholderContainer>
      )}
    </>
  );
};

export default DeltaMetersList;
