import React from "react";
import useDeleteEntity from "../lib/useDeleteEntity";
import ConfirmationDialog from "../ui/ConfirmationDialog";
import { useToastContext } from "../ui/ToastProvider";
import { DeleteUserInviteDocument } from "./DeleteUserInvite.generated";

type Props = {
  email: string;
  name: string;
  userInviteId: string;
  onClose: () => void;
};

const DeleteUserInviteConfirmation: React.FC<Props> = ({
  email,
  name,
  userInviteId,
  onClose,
}) => {
  const [deleteInvite] = useDeleteEntity(DeleteUserInviteDocument);
  const createToast = useToastContext();

  const doDelete = async () => {
    try {
      await deleteInvite(
        { __typename: "UserInvite", id: userInviteId },
        { input: { userInviteId } }
      );
      createToast("Invite has been deleted.", "success");
    } catch (e) {
      createToast(e.message, "error");
    }

    onClose();
  };

  return (
    <ConfirmationDialog
      header="Revoke Invite?"
      description={`Deletes the invite for ${name} (${email}). The user's invite link will no longer work.`}
      onConfirm={doDelete}
      onClose={onClose}
    />
  );
};

export default DeleteUserInviteConfirmation;
