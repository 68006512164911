import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { FC } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { InvoiceModelFragment } from "../api/fragments/InvoiceModel.generated";
import { InvoiceStatus } from "../api/graphql";
import useLazyDialogData from "../lib/useLazyDialogData";
import Button from "../ui/Button";
import {
  DeltaMeterStatementPdfDocument,
  DeltaMeterStatementPdfQuery,
} from "./DeltaMeterStatementPdfQuery.generated";
import { StatusChip } from "./InvoiceCard";

type DeltaMeterStatementPdf = DeltaMeterStatementPdfQuery["deltaMeterStatement"]["pdf"];
interface Props {
  invoice: InvoiceModelFragment;
  deltaMeterStatementPdf: DeltaMeterStatementPdf;
  onClose: () => void;
  timeZone: string;
}

const BottomRow = styled(TableRow)({
  "& > td": {
    borderBottom: "unset",
  },
});

const statusText: Record<InvoiceStatus, string> = {
  OPEN: "Invoice sent",
  PAID: "Payment processed",
  VOID: "Invoice voided",
};

const InvoiceDialog: FC<Props> = ({
  invoice,
  deltaMeterStatementPdf,
  onClose,
  timeZone,
}) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Invoice summary</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center">
            <Typography variant="body2">
              {statusText[invoice.status]}
            </Typography>
            <Box pl={1}>
              <StatusChip status={invoice.status} />
            </Box>
          </Box>
          <Typography variant="body2">
            Issued{" "}
            <FormattedDate value={invoice.issuedAt} timeZone={timeZone} />
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          Invoice #{invoice.reference}
        </Typography>
        <br />
        <Typography variant="body2">
          Description: {invoice.description}
        </Typography>
        <br />
        <Box display="flex">
          <GetAppIcon color="disabled" />
          <Link href={invoice.pdf} underline="none" download>
            Download invoice PDF
          </Link>
        </Box>
        <Box display="flex">
          <GetAppIcon color="disabled" />
          {deltaMeterStatementPdf !== null ? (
            <Link href={deltaMeterStatementPdf.url} underline="none" download>
              Download DeltaMeter statement PDF
            </Link>
          ) : (
            <Typography>No DeltaMeter statement PDF generated.</Typography>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Description</b>
              </TableCell>
              <TableCell align="right">
                <b>Quantity @ unit cost</b>
              </TableCell>
              <TableCell align="right">
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.lineItems.map((item) => (
              <TableRow>
                <TableCell>{item.description}</TableCell>
                <TableCell align="right">
                  <FormattedNumber value={item.quantity} /> @{" "}
                  <FormattedNumber
                    value={item.unitPrice.value}
                    style={`currency`}
                    currency="USD"
                  />
                </TableCell>
                <TableCell align="right">
                  <FormattedNumber
                    value={item.subtotal.value}
                    style={`currency`}
                    currency="USD"
                  />
                </TableCell>
              </TableRow>
            ))}
            <BottomRow>
              <TableCell>
                <b>Subtotal</b>
              </TableCell>
              <TableCell />
              <TableCell align="right">
                <b>
                  <FormattedNumber
                    value={invoice.totalCost.value}
                    style={`currency`}
                    currency="USD"
                  />
                </b>
              </TableCell>
            </BottomRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface InvoiceDialogButtonProps {
  invoice: InvoiceModelFragment;
  timeZone: string;
}

export const InvoiceDialogButton: FC<InvoiceDialogButtonProps> = ({
  invoice,
  timeZone,
}) => {
  const {
    openDialog,
    closeDialog,
    data: dialogData,
    loading: dialogLoading,
  } = useLazyDialogData(DeltaMeterStatementPdfDocument, {
    fetchPolicy: "network-only",
  });

  return (
    <>
      {!!dialogData && (
        <InvoiceDialog
          invoice={invoice}
          deltaMeterStatementPdf={dialogData.deltaMeterStatement.pdf}
          onClose={closeDialog}
          timeZone={timeZone}
        />
      )}

      <Button
        size="small"
        onClick={() =>
          openDialog({
            deltaMeterStatementId: invoice.deltaMeterStatement.id,
          })
        }
      >
        {dialogLoading ? <CircularProgress size={15} /> : "View details"}
      </Button>
    </>
  );
};

interface InvoiceDialogLinkProps {
  invoice: InvoiceModelFragment;
  timeZone: string;
}

export const InvoiceDialogLink: FC<InvoiceDialogLinkProps> = ({
  invoice,
  timeZone,
}) => {
  const { openDialog, closeDialog, data: dialogData } = useLazyDialogData(
    DeltaMeterStatementPdfDocument,
    {
      fetchPolicy: "network-only",
    }
  );

  return (
    <>
      {!!dialogData && (
        <InvoiceDialog
          invoice={invoice}
          deltaMeterStatementPdf={dialogData.deltaMeterStatement.pdf}
          onClose={closeDialog}
          timeZone={timeZone}
        />
      )}

      <Link
        href="#"
        underline="none"
        onClick={() =>
          openDialog({
            deltaMeterStatementId: invoice.deltaMeterStatement.id,
          })
        }
      >
        {invoice.id}
      </Link>
    </>
  );
};

export default InvoiceDialog;
