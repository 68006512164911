import { useQuery } from "@apollo/client";
import React from "react";
import Loading from "../ui/Loading";
import { AdjustedModelParametersDocument } from "./AdjustedModelParametersQuery.generated";
import { CalibratedModelParametersDocument } from "./CalibratedModelParametersQuery.generated";
import ModelDetails from "./ModelDetails";

interface Props {
  model: {
    id: string;
    __typename: "BuildingCalibratedModel" | "BuildingAdjustedModel";
  };
}

const CalibratedModelParameters: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useQuery(CalibratedModelParametersDocument, {
    variables: { id },
    fetchPolicy: "cache-first",
  });

  if (loading || !data) {
    return <Loading variant="circle" />;
  }

  return (
    <ModelDetails parameters={data.calibratedModel.buildingModel.parameters} />
  );
};

const AdjustedModelParameters: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useQuery(AdjustedModelParametersDocument, {
    variables: { id },
    fetchPolicy: "cache-first",
  });

  if (loading || !data) {
    return <Loading variant="circle" />;
  }

  return (
    <ModelDetails parameters={data.adjustedModel.buildingModel.parameters} />
  );
};

const ModelParameters: React.FC<Props> = ({ model }) =>
  model.__typename === "BuildingCalibratedModel" ? (
    <CalibratedModelParameters id={model.id} />
  ) : (
    <AdjustedModelParameters id={model.id} />
  );

export default ModelParameters;
