import { Button } from "@material-ui/core";
import { saveAs } from "file-saver";
import React, { FC, useState } from "react";
import { Building } from ".";
import createCsvRows from "../lib/createCsvRows";

interface Props {
  buildings: Building[];
}

const header = [
  "Building ID",
  "EnergyRM ID",
  "Building Name",
  "Primary Function",
  "Square Feet",
  "Address",
  "City",
  "State",
  "Zip",
  "Year Built (optional)",
  "Lease Type (optional",
  "Major Tenant (optional)",
  "Opportunity EE depth (%)",
  "Opportunity size (USD)",
  "Opportunity size (kBTU)",
  "Model fit",
];

const ExportBuildingsButton: FC<Props> = ({ buildings }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const saveExport = async () => {
    setLoading(true);
    const rows = createCsvRows(
      buildings.map((b) => [
        b.externalId,
        b.id,
        b.name,
        b.buildingUseType,
        b.squareFeet,
        b.address.streetAddress,
        b.address.locality,
        b.address.region,
        b.address.postalCode,
        b.yearBuilt,
        b.leaseType,
        b.majorTenant,
        (
          (b.calibratedModel?.adjustedModel?.opportunity.energyDepth || 0) * 100
        ).toFixed(2),
        Math.round(
          b.calibratedModel?.adjustedModel?.opportunity.money.value || 0
        ),
        Math.round(
          b.calibratedModel?.adjustedModel?.opportunity.energy.quantity || 0
        ),
        b.calibratedModel?.fit ?? "NONE",
      ])
    );

    const fileName = `prospecting-search_${new Date().valueOf()}_${
      buildings.length
    }-buildings.csv`;

    const blob = new Blob([header.join(",") + "\n" + rows], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, fileName);
    setLoading(false);
  };

  return (
    <Button
      color="primary"
      size="small"
      variant="outlined"
      disabled={loading}
      onClick={saveExport}
    >
      Export result set
    </Button>
  );
};

export default ExportBuildingsButton;
