import * as Types from '../api/graphql';

import { BuildingModelParametersFieldsFragment } from '../api/fragments/BuildingModelParametersFields.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { BuildingModelParametersFieldsFragmentDoc } from '../api/fragments/BuildingModelParametersFields.generated';
export type AdjustedModelParametersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AdjustedModelParametersQuery = { adjustedModel: { __typename: 'BuildingAdjustedModel', id: string, buildingModel: { __typename: 'BuildingModel', id: string, parameters: (
        { __typename: 'ModelParameters' }
        & BuildingModelParametersFieldsFragment
      ) } } };


export const AdjustedModelParametersDocument: DocumentNode<AdjustedModelParametersQuery, AdjustedModelParametersQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"adjustedModelParameters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adjustedModel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"buildingModel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"parameters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BuildingModelParametersFields"}}]}}]}}]}}]}},...BuildingModelParametersFieldsFragmentDoc.definitions]};