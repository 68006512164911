import { useQuery } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { buildingTransactionInvoices } from "../lib/endpoints";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import { PageTitle, SectionTitle } from "../ui/Typography";
import DeleteTransactionButton from "./DeleteTransactionButton";
import InvoiceCard from "./InvoiceCard";
import Ledger from "./Ledger";
import NoInvoiceActivity from "./NoInvoiceActivity";
import NoLedgerActivity from "./NoLedgerActivity";
import NoTransaction from "./NoTransaction";
import TransactionNotes from "./TransactionNotes";
import { TransactionQueryDocument } from "./TransactionQuery.generated";

interface Props {
  buildingId: string;
}

const TransactionDashboard: FC<Props> = ({ buildingId }) => {
  const history = useHistory();
  const { data, loading, error } = useQuery(TransactionQueryDocument, {
    variables: { buildingId },
  });

  if (error) throw error;

  if (!data || loading) return <Loading variant="circle" />;

  if (!data.building.transaction) {
    return (
      <>
        <PageTitle>Transaction</PageTitle>
        <NoTransaction />
      </>
    );
  }

  const {
    building: { transaction },
  } = data;
  const { building, invoices, ledger } = transaction;
  const { address } = building;
  const { timeZone } = address;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pb={1}>
          <PageTitle>Transaction</PageTitle>
        </Box>
        <Box display="flex" justifyContent="space-between" pb={1}>
          <SectionTitle>Recent activity</SectionTitle>
          {invoices.length > 0 && (
            <Button
              size="small"
              onClick={() =>
                history.push(
                  buildingTransactionInvoices({
                    buildingId: building.id,
                  })
                )
              }
            >
              View all
            </Button>
          )}
        </Box>
        {invoices.length > 0 ? (
          <Grid item container spacing={2}>
            {!!invoices[0] && (
              <Grid item xs={6}>
                <InvoiceCard
                  invoice={invoices[0]}
                  timeZone={timeZone}
                  title="Current invoice"
                />
              </Grid>
            )}
            {!!invoices[1] && (
              <Grid item xs={6}>
                <InvoiceCard
                  invoice={invoices[1]}
                  timeZone={timeZone}
                  title="Previous invoice"
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <NoInvoiceActivity />
        )}
      </Grid>
      <Grid item xs={12}>
        <SectionTitle>Ledger</SectionTitle>
        {ledger.length > 0 ? (
          <Ledger ledgerItems={ledger} timeZone={timeZone} />
        ) : (
          <NoLedgerActivity />
        )}
      </Grid>
      <TransactionNotes
        transactionId={transaction.id}
        notes={transaction.notes}
      />
      <DeleteTransactionButton transactionId={transaction.id} />
    </Grid>
  );
};

export default TransactionDashboard;
