import { EnergyUnitType } from "../api/graphql";
import sum from "../lib/sum";
import targetUnitConverter from "../lib/targetUnitConverter";
import { CombinedReadingAdjustment } from "./AdjustmentAnnotations";
import { DeltaMeterQuery } from "./DeltaMeterQuery.generated";

type DeltaMeterQueryReadingEdgeNode = NonNullable<
  DeltaMeterQuery["building"]["deltaMeter"]
>["readings"]["edges"][number]["node"];

/**
 * Reduces the adjustments for all energy source's readings to create adjustment tooltips
 */
export default function readingAdjustmentReducer(
  report: DeltaMeterQueryReadingEdgeNode[],
  targetUnit: EnergyUnitType
): CombinedReadingAdjustment[] {
  const toTargetUnit = targetUnitConverter(targetUnit);

  return report
    .filter((r) => r.adjustments.length > 0)
    .map((r) => {
      const { adjustments, interval, energySource } = r;

      return {
        readingId: r.id,
        interval,
        energySource: energySource,
        netQuantity: sum(
          adjustments.map((a) => toTargetUnit(a.quantity, targetUnit))
        ),
        baseline: toTargetUnit(r.baseline, r.unit) / interval.daysInPeriod,
        adjustments: r.adjustments,
      };
    });
}
