import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import BuildingsChart from "./BuildingsChart";
import Greeting from "./Greeting";
import OrgUsers from "./OrgUsers";
import RecentBuildings from "./RecentBuildings";
import RecentDocuments from "./RecentDocuments";

interface Props {}

const OrgDashboard: FC<Props> = () => {
  return (
    <>
      <Box pb={3}>
        <Greeting />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BuildingsChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentBuildings />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentDocuments />
        </Grid>
        <Grid item xs={12} md={6}>
          <OrgUsers />
        </Grid>
      </Grid>
    </>
  );
};

export default OrgDashboard;
