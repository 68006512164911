import * as Types from '../api/graphql';

import { FileAssetModelFragment } from '../api/fragments/FileAssetModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { FileAssetModelFragmentDoc } from '../api/fragments/FileAssetModel.generated';
export type DownloadUrlQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
  preview: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type DownloadUrlQuery = { organizationDocument: { __typename: 'OrganizationDocument', id: string, file: (
      { __typename: 'FileAsset', url: string }
      & FileAssetModelFragment
    ) } };


export const DownloadUrlDocument: DocumentNode<DownloadUrlQuery, DownloadUrlQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DownloadUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"documentId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"preview"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationDocument"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"documentId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FileAssetModel"}},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"preview"},"value":{"kind":"Variable","name":{"kind":"Name","value":"preview"}}}]}]}}]}}]}},...FileAssetModelFragmentDoc.definitions]};