import { useQuery } from "@apollo/client";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/Speed";
import React, { FC } from "react";
import { MeterSource } from "../api/graphql";
import {
  buildingUtilityMeter,
  utilityMeters as utilityMetersPath,
} from "../lib/endpoints";
import { energySourceName } from "../lib/energySource";
import pluckNodes from "../lib/pluckNodes";
import pluralize from "../lib/pluralize";
import { SkeletonListItem, SkeletonTypography } from "../ui/Bones";
import { CardLinkedArea } from "../ui/Card";
import DashboardCard from "../ui/DashboardCard";
import EnergySourceIcon from "../ui/EnergySourceIcon";
import MetaData from "../ui/MetaData";
import PlaceholderSegment from "../ui/PlaceholderSegment";
import ProductCopy from "../ui/ProductCopy";
import Sparkline, { DataPoint } from "../ui/Sparkline";
import {
  UtilityMetersDocument,
  UtilityMetersQuery,
} from "./UtilityMetersQuery.generated";
import ViewMoreLink from "./ViewMoreLink";

interface Props {
  buildingId: string;
}

type Meter = UtilityMetersQuery["building"]["utilityMeters"][number];
type Reading = Meter["readings"]["edges"][number]["node"];

// This is mostly decorative data. If we want to get fancy and
// support tooltips, we need to aggregate this and label properly while
// converting units.
const createSparklineData = (readings: Reading[]): DataPoint[] => {
  return readings.map((r) => ({
    x: new Date(r.startOn).valueOf(),
    xLabel: "date",
    y: r.quantity,
    yLabel: "quantity",
  }));
};

const UtilityMetersSkeleton: FC = () => {
  return (
    <DashboardCard label={<SkeletonTypography size="medium" />}>
      <List disablePadding>
        <SkeletonListItem />
        <Divider />
        <SkeletonListItem />
        <Divider />
        <SkeletonListItem />
      </List>
    </DashboardCard>
  );
};

const MeterListItem: FC<{ meter: Meter; buildingId: string }> = ({
  meter,
  buildingId,
}) => {
  const readings = pluckNodes(meter.readings);
  const chartData = createSparklineData(readings);
  const xDomain: [number, number] = [
    Math.min(...chartData.map((cd) => cd.x)),
    Math.max(...chartData.map((cd) => cd.x)),
  ];

  return (
    <>
      <CardLinkedArea
        to={buildingUtilityMeter({
          buildingId,
          utilityMeterId: meter.id,
        })}
      >
        <ListItem>
          <ListItemIcon>
            <EnergySourceIcon
              energySource={meter.energySource}
              solar={meter.source === MeterSource.SOLAR}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography>
              <b>{meter.externalId || meter.id}</b>
            </Typography>
            <MetaData>
              <Typography component="span" variant="body2">
                {energySourceName(meter.energySource)}{" "}
                {meter.source === MeterSource.SOLAR && "(solar)"}
              </Typography>
            </MetaData>
          </ListItemText>
          {readings.length > 4 ? (
            <Sparkline
              disableTooltip
              data={createSparklineData(readings)}
              xDomain={xDomain}
              width={300}
              aspect={8}
            />
          ) : (
            <Typography color="textSecondary">
              {readings.length} {pluralize("reading", readings.length)}
            </Typography>
          )}
        </ListItem>
      </CardLinkedArea>
      <Divider />
    </>
  );
};

// TODO: granular state based on building setup status
const UtilityMeters: FC<Props> = ({ buildingId }) => {
  const { data, loading, error } = useQuery(UtilityMetersDocument, {
    variables: { buildingId },
  });

  if (error) throw error;

  if (loading || !data) {
    return <UtilityMetersSkeleton />;
  }

  const { building } = data;
  const { utilityMeters } = building;

  // TODO: integrate this as an alert
  // const staleMetersExist = utilityMeters.some((um) =>
  //   meterIsStale(um.readings.lastReadOn)
  // );

  // If we don't have any meters, display fallback
  if (utilityMeters.length === 0) {
    return (
      <DashboardCard label={ProductCopy.UTILITY_METERS}>
        <PlaceholderSegment
          fadeText
          icon={SpeedIcon}
          header="Utility meter data unavailable"
          subheader="Utility meter data is required to simulate building energy usage patterns"
        />
      </DashboardCard>
    );
  }

  const displayMeters = utilityMeters.slice(0, 3);
  const remainder = utilityMeters.length - displayMeters.length;

  return (
    <DashboardCard label={ProductCopy.UTILITY_METERS}>
      <List disablePadding>
        {displayMeters.map((um) => {
          return (
            <MeterListItem key={um.id} meter={um} buildingId={buildingId} />
          );
        })}
        <ViewMoreLink path={utilityMetersPath({ buildingId })}>
          {remainder > 0
            ? `${remainder} more ${pluralize("meter", remainder)}`
            : "Manage meters"}
        </ViewMoreLink>
      </List>
    </DashboardCard>
  );
};

export default UtilityMeters;
