import { EnergySource } from "../api/graphql";

const order: EnergySource[] = [
  EnergySource.ELECTRICITY,
  EnergySource.NATURAL_GAS,
  EnergySource.PROPANE,
  EnergySource.CHILLED_WATER,
  EnergySource.STEAM,
];

export const fuelOrder = <T extends { energySource: EnergySource }>(
  arg: T
): number => {
  return order.indexOf(arg.energySource);
};
