import { Box, styled, Typography } from "@material-ui/core";
import millify from "millify";
import React, { FC, ReactText } from "react";
import { Building } from ".";
import { OpportunityModelFragment } from "../api/fragments/OpportunityModel.generated";
import { EnergyUnitType } from "../api/graphql";
import Energy from "../lib/Energy";
import pluralize from "../lib/pluralize";
import safeAverage from "../lib/safeAverage";

const Container = styled("div")(({ theme: { spacing } }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  paddingBottom: spacing(1),
}));

const SearchResultsContainer = styled("div")({
  gridColumn: "auto",
});

const MetricsContainer = styled("div")({
  gridColumn: "1 / span 4",
  display: "flex",
  justifyContent: "space-between",
});

const Metric: FC<{ label: string; value: ReactText; average?: ReactText }> = ({
  value,
  label,
  average,
}) => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <div>
        <Typography component="span" variant="h6" color="textSecondary">
          {value}
        </Typography>{" "}
        {!!average && (
          <Typography component="span" variant="caption" color="textSecondary">
            (Avg. {average})
          </Typography>
        )}
      </div>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
    </Box>
  );
};

interface Props {
  buildings: Building[];
}

const ListMetrics: FC<Props> = React.memo(({ buildings }) => {
  const opportunities: OpportunityModelFragment[] = buildings
    .map((b) => b.calibratedModel?.adjustedModel?.opportunity)
    .filter((b): b is OpportunityModelFragment => !!b);

  const totalEnergy = opportunities.reduce((acc, c) => {
    return acc + new Energy(c.energy).as(EnergyUnitType.KBTU).quantity;
  }, 0);

  const totalMoney = opportunities.reduce((acc, c) => acc + c.money.value, 0);

  const averageEnergy = safeAverage(totalEnergy, opportunities.length);
  const averageMoney = safeAverage(totalMoney, opportunities.length);

  const averageDepth = safeAverage(
    opportunities.reduce((acc, c) => acc + c.energyDepth, 0),
    opportunities.length
  );

  const totalSquareFeet = buildings.reduce((acc, c) => acc + c.squareFeet, 0);
  const averageSquareFeet = safeAverage(totalSquareFeet, buildings.length);

  return (
    <Container>
      <MetricsContainer>
        <Metric
          label="Total opportunity value"
          value={`$${millify(totalMoney)}`}
          average={`$${millify(averageMoney)}`}
        />
        <Metric
          label="Total opportunity size (kBTU)"
          value={`${millify(totalEnergy)}`}
          average={`${millify(averageEnergy)}`}
        />
        <Metric
          label="Total building size (ft²)"
          value={`${millify(totalSquareFeet)}`}
          average={`${millify(averageSquareFeet)}`}
        />
        <Metric
          label="Average EE depth"
          value={`${millify(averageDepth * 100, { precision: 1 })}%`}
        />
      </MetricsContainer>
      <SearchResultsContainer>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
          width={1}
          height={1}
        >
          <Typography color="textSecondary" variant="body2">
            {buildings.length} {pluralize("building", buildings.length)}
          </Typography>
        </Box>
      </SearchResultsContainer>
    </Container>
  );
});

export default ListMetrics;
