import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { FC } from "react";
import { ResponsiveContainer } from "recharts";

type TextSize = "small" | "medium" | "large";

export const SkeletonTypography: FC<{
  size?: TextSize;
  fullWidth?: boolean;
}> = ({ size = "small", fullWidth = false }) => {
  const { spacing } = useTheme();

  const sizing: Record<TextSize, number> = {
    small: spacing(10),
    medium: spacing(20),
    large: spacing(40),
  };

  return (
    <Skeleton width={fullWidth ? "100%" : sizing[size]}>
      <Typography>&nbsp;</Typography>
    </Skeleton>
  );
};

export const SkeletonChart: FC<{
  aspect?: number;
}> = ({ aspect = 2 }) => {
  return (
    <ResponsiveContainer aspect={aspect}>
      <Skeleton variant="rect" />
    </ResponsiveContainer>
  );
};

export const SkeletonAvatar: FC = () => {
  return (
    <Skeleton variant="circle">
      <Avatar />
    </Skeleton>
  );
};

export const SkeletonListItem: FC<{ textOnly?: boolean }> = ({ textOnly }) => {
  return (
    <ListItem>
      {!textOnly && (
        <ListItemAvatar>
          <SkeletonAvatar />
        </ListItemAvatar>
      )}
      <Box width={1}>
        <SkeletonTypography size="small" />
        <SkeletonTypography fullWidth />
      </Box>
    </ListItem>
  );
};
