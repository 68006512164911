import { EnergySource } from "../api/graphql";

const order: Record<EnergySource, number> = {
  ELECTRICITY: 1,
  NATURAL_GAS: 2,
  PROPANE: 3,
  CHILLED_WATER: 4,
  STEAM: 5,
  FUEL_OIL2: 6,
};

const energySourceOrder = (arg: EnergySource): number => order[arg];

export default energySourceOrder;
