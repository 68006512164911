import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CalibratedModelsQueryDocument } from "./CalibratedModelsQuery.generated";

const useHasModels = (id: string) => {
  const [hasModels, setHasModels] = useState<boolean>(false);
  const { data, error, refetch } = useQuery(CalibratedModelsQueryDocument, {
    variables: { buildingId: id },
  });

  useEffect(() => {
    if (data) {
      setHasModels(!!data.building.calibratedModel);
    }
  }, [data, setHasModels]);

  if (error) throw error;

  return { hasModels, refetch };
};

export default useHasModels;
