import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    const analytics = (window as any).analytics;
    if (!analytics) return;
    analytics.page({ url: location.pathname });
  }, [location.pathname]);
}

const PageTracker = () => {
  usePageViews();
  return null;
};

export default PageTracker;
