import { useQuery } from "@apollo/client";
import * as React from "react";
import Loading from "../ui/Loading";
import { CalibratedModelScoreDocument } from "./CalibratedModelScoreQuery.generated";

interface Props {
  calibratedModelId: string;
}

const CalibratedModelScore: React.FC<Props> = ({ calibratedModelId }) => {
  const { data, loading } = useQuery(CalibratedModelScoreDocument, {
    variables: { id: calibratedModelId },
    fetchPolicy: "cache-first",
  });

  if (loading || !data) {
    return <Loading variant="circle" />;
  }

  return (
    <>
      {parseFloat(data.calibratedModel.score.toFixed(5))}
      <pre>
        {JSON.stringify(
          data.calibratedModel.scoreStats,
          (k, v) =>
            k === "__typename"
              ? undefined
              : typeof v === "number"
              ? parseFloat(v.toFixed(5))
              : v,
          2
        )}
      </pre>
    </>
  );
};

export default CalibratedModelScore;
