import { useQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@material-ui/core";
import React, { FC, Fragment } from "react";
import assertFeature from "../lib/assertFeature";
import findDefaultModel from "../lib/findDefaultModel";
import FormattedRange from "../lib/FormattedRange";
import { sortBy } from "../lib/sortBy";
import NewModelDialogButton from "../ReferencePeriodSelection/NewModelDialogButton";
import Loading from "../ui/Loading";
import ProductCopy from "../ui/ProductCopy";
import { PageTitle, SectionTitle } from "../ui/Typography";
import CalibratedModelCard from "./CalibratedModelCard";
import { ReferencePeriodModelsDocument } from "./CalibratedModelsReferenceQuery.generated";
import GenerateModels from "./GenerateModels";

interface Props {
  buildingId: string;
}

const CalibratedModelsReferenceList: FC<Props> = ({ buildingId }) => {
  const { data, loading, error } = useQuery(ReferencePeriodModelsDocument, {
    variables: { buildingId },
  });

  if (error) throw error;

  // TODO: Skeletonize!
  if (loading || !data) return <Loading variant="circle" />;

  const { referencePeriods } = data.building;

  const defaultModel = findDefaultModel(
    referencePeriods.flatMap((rp) => rp.calibratedModels)
  );

  return (
    <>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <PageTitle>
          {ProductCopy.ENERGY_MODELS}
          <Typography variant="body2" color="textSecondary">
            Calibrated from the building’s historical utility data
          </Typography>
        </PageTitle>
        {assertFeature("REF_PERIOD_SELECTION") && (
          <div>
            <NewModelDialogButton buildingId={buildingId} />
          </div>
        )}
      </Box>

      {assertFeature("REF_PERIOD_SELECTION") && defaultModel && (
        <>
          <SectionTitle>Default model</SectionTitle>
          <Box pb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} key={defaultModel.id}>
                <CalibratedModelCard model={defaultModel} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {!assertFeature("REF_PERIOD_SELECTION") &&
      referencePeriods.length === 0 ? (
        <GenerateModels buildingId={buildingId} />
      ) : (
        <>
          {referencePeriods.map((rp) => {
            const { start, end } = rp.interval;

            return (
              <Fragment key={rp.id}>
                <SectionTitle>
                  Reference period:{" "}
                  <FormattedRange from={new Date(start)} to={new Date(end)} />
                </SectionTitle>
                <Grid container spacing={2}>
                  {sortBy(
                    rp.calibratedModels,
                    (m) => -1 * new Date(m.createdAt).getMilliseconds()
                  ).map((cm) => {
                    return (
                      <Grid item xs={12} sm={6} key={cm.id}>
                        <CalibratedModelCard model={cm} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default CalibratedModelsReferenceList;
