import * as Types from '../api/graphql';

import { InvoiceModelFragment } from '../api/fragments/InvoiceModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { InvoiceModelFragmentDoc } from '../api/fragments/InvoiceModel.generated';
export type InvoicesQueryQueryVariables = Types.Exact<{
  buildingId: Types.Scalars['ID'];
}>;


export type InvoicesQueryQuery = { building: { __typename: 'Building', id: string, transaction: Types.Maybe<{ __typename: 'Transaction', id: string, building: { __typename: 'Building', id: string, address: { __typename: 'PostalAddress', timeZone: string } }, deltaMeter: { __typename: 'DeltaMeter', id: string }, invoices: Array<(
        { __typename: 'Invoice' }
        & InvoiceModelFragment
      )> }> } };


export const InvoicesQueryDocument: DocumentNode<InvoicesQueryQuery, InvoicesQueryQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"InvoicesQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"buildingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"building"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"buildingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"transaction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"building"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"timeZone"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"deltaMeter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"invoices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InvoiceModel"}}]}}]}}]}}]}},...InvoiceModelFragmentDoc.definitions]};