import React from "react";
import { Redirect } from "react-router-dom";
import {
  acceptInvite as acceptInviteURL,
  register as registerUrl,
  withQuery,
} from "../lib/endpoints";
import useSearchQueryValue from "../lib/useSearchQueryValue";
import { useSessionManager } from "../SessionManager";
import Loading from "../ui/Loading";
import AcceptOrgInvite from "./AcceptOrgInvite";
import { useLazyUserInviteQuery } from "./useUserInviteQuery";

const AcceptInvite: React.FC = () => {
  const { user, loggingIn } = useSessionManager();
  const token = useSearchQueryValue("token");
  const userInviteId = useSearchQueryValue("inviteId");
  const { getInvite, data, loading, called } = useLazyUserInviteQuery();

  React.useEffect(() => {
    // ensure we try to load the invite after attempting to log in.
    if (!loggingIn && !called) {
      getInvite(userInviteId, token);
    }
  }, [loggingIn, called, userInviteId, token, getInvite]);

  if (loading || loggingIn || !called) {
    return <Loading variant="circle" />;
  }

  // if there is no user we want to redirect them to user registration/login
  // no data means the token or invite id is invalid, or the invite no longer exists.
  if (user || !data?.userInvite) {
    return (
      <AcceptOrgInvite
        token={token}
        inviteId={userInviteId}
        organizationName={data?.userInvite?.organizationName}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: registerUrl(),
        state: {
          then: withQuery(acceptInviteURL(), { token }),
          inviteToken: token,
          message: `You have been invited to ${data.userInvite.organizationName} on EnergyRM. Sign up or sign in to existing account to accept.`,
          email: data.userInvite.email,
        },
      }}
    />
  );
};

export default AcceptInvite;
