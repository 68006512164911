import { DateTime, Duration } from "luxon";
import { EnergySource } from "../api/graphql";
import pluckNodes from "../lib/pluckNodes";
import { UtilityMeter } from "./NewModelDialogButton";
import { AggregateEnergyReadingModelFragment } from "./ReferencePeriodMetersQuery.generated";

interface ConnectorPoint {
  date: Date;
  readingData: AggregateEnergyReadingModelFragment;
}

export interface BillingPeriodConnector {
  energySource: EnergySource;
  points: [ConnectorPoint, ConnectorPoint];
}

/**
 * Determines if date range is no longer than the specified number of days
 */
function daysWithinRange(start: Date, end: Date, numDays: number) {
  return (
    Math.abs(
      DateTime.fromJSDate(start).diff(DateTime.fromJSDate(end)).valueOf()
    ) < Duration.fromObject({ days: numDays }).valueOf()
  );
}

function createBillingPeriodConnectors(
  utilityMeter: UtilityMeter,
  fidelity: number
): BillingPeriodConnector[] {
  const connectors: BillingPeriodConnector[] = [];
  const readings = pluckNodes(utilityMeter.readings);

  for (let i = 0; i < readings.length - 1; i++) {
    const currentReading = readings[i];
    const nextReading = readings[i + 1];

    const currentReadingEnd = new Date(currentReading.interval.end);
    const nextReadingStart = new Date(nextReading.interval.start);

    // If the current and next reading's respective end and start dates have a gap of less than X,
    // where X is a configurable amount, then we have a connection
    if (daysWithinRange(currentReadingEnd, nextReadingStart, fidelity)) {
      connectors.push({
        energySource: utilityMeter.energySource,
        points: [
          {
            date: currentReadingEnd,
            readingData: currentReading,
          },
          {
            date: nextReadingStart,
            readingData: nextReading,
          },
        ],
      });
      // Otherwise, we have a gap, and create a connector line from the current reading's end to
      // the origin, and from the origin to the next reading's start (two separate connectors)
    } else {
      connectors.push({
        energySource: utilityMeter.energySource,
        points: [
          {
            date: currentReadingEnd,
            readingData: currentReading,
          },
          {
            date: currentReadingEnd,
            readingData: { ...currentReading, quantity: 0 },
          },
        ],
      });

      connectors.push({
        energySource: utilityMeter.energySource,
        points: [
          {
            date: nextReadingStart,
            readingData: { ...nextReading, quantity: 0 },
          },
          {
            date: nextReadingStart,
            readingData: nextReading,
          },
        ],
      });
    }
  }

  return connectors;
}

export default createBillingPeriodConnectors;
