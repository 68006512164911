import { changePassword } from "keratin-authn";
import React from "react";
import { useForm } from "react-hook-form";
import { useAuthNErrors } from "../lib/AuthN";
import removeEmpty from "../lib/removeEmpty";
import ServerErrors from "../lib/ServerErrors";
import { FieldErrors, matchError, presenceError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import Button from "../ui/Button";
import { TextField } from "../ui/TextField";
import { useToastContext } from "../ui/ToastProvider";
import { SectionTitle } from "../ui/Typography";

interface Props {}

interface Fields {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const validations = (values: Fields): FieldErrors<Fields> =>
  removeEmpty({
    currentPassword: presenceError(values.currentPassword),
    password: presenceError(values.password),
    passwordConfirmation: matchError(
      values.password,
      values.passwordConfirmation
    ),
  });

const Messages = {
  credentials_FAILED: "Current password does not match.",
  password_MISSING: "Password is required.",
  password_INSECURE: "Password is weak.",
  account_LOCKED: "That account is no longer active.",
  account_NOT_FOUND: "That account is no longer active.",
  default: "Something went wrong.",
};

const MyAccountPassword: React.FC<Props> = () => {
  const [authNErrors, catchErrors] = useAuthNErrors();
  const createToast = useToastContext();
  const {
    errors,
    formState: { isSubmitting },
    handleSubmit,
    register,
    reset,
  } = useForm<Fields>({
    defaultValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
    resolver: validatorsResolver(validations),
  });

  const doChangePassword: (fields: Fields) => Promise<void> = async (
    fields
  ) => {
    catchErrors(async () => {
      await changePassword({
        currentPassword: fields.currentPassword,
        password: fields.password,
      });
      reset();
      createToast("Your password has been changed", "success");
    });
  };

  return (
    <>
      <SectionTitle>Change My Password</SectionTitle>
      <form noValidate onSubmit={handleSubmit(doChangePassword)}>
        <ServerErrors errors={authNErrors} messages={Messages} />

        <TextField
          inputRef={register}
          name="currentPassword"
          type="password"
          label="Current Password"
          required
          autoComplete="current-password"
          error={errors.currentPassword?.message}
        />

        <TextField
          inputRef={register}
          name="password"
          type="password"
          label="New Password"
          required
          autoComplete="new-password"
          error={errors.password?.message}
        />

        <TextField
          inputRef={register}
          name="passwordConfirmation"
          type="password"
          label="Confirm New Password"
          required
          autoComplete="new-password"
          error={errors.passwordConfirmation?.message}
        />

        <Button type="submit" disabled={isSubmitting} fullWidth primary>
          Change Password
        </Button>
      </form>
    </>
  );
};

export default MyAccountPassword;
