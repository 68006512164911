import { EnergyUnitType } from "../api/graphql";
import Energy from "./Energy";

/**
 * Returns a function that converts energy of quantity and unit to a specified unit
 */
const targetUnitConverter = (targetUnit: EnergyUnitType) => (
  quantity: number,
  unit: EnergyUnitType
) => new Energy({ quantity, unit }).as(targetUnit).quantity;

export default targetUnitConverter;
