import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateBuildingAdjustedModelMutationVariables = Types.Exact<{
  input: Types.UpdateBuildingAdjustedModelInput;
}>;


export type UpdateBuildingAdjustedModelMutation = { updateBuildingAdjustedModel: { __typename: 'UpdateBuildingAdjustedModelResponse', buildingAdjustedModel: { __typename: 'BuildingAdjustedModel', id: string, name: Types.Maybe<string>, description: Types.Maybe<string>, notes: Types.Maybe<string> } } };


export const UpdateBuildingAdjustedModelDocument: DocumentNode<UpdateBuildingAdjustedModelMutation, UpdateBuildingAdjustedModelMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBuildingAdjustedModel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateBuildingAdjustedModelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBuildingAdjustedModel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"buildingAdjustedModel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"notes"}}]}}]}}]}}]};