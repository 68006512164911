import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { relayStylePagination } from "@apollo/client/utilities";
import { SentryLink } from "apollo-link-sentry";
import * as AuthN from "keratin-authn";
import { TypedTypePolicies } from "./api/apollo-helpers";

const httpLink = new BatchHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  if (AuthN.session()) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${AuthN.session()}`,
      },
    });
  }

  return forward(operation);
});

const typePolicies: TypedTypePolicies = {
  ActivityConnection: relayStylePagination(),
  BuildingAdjustedModel_Opportunity: { merge: true },
  BuildingConnection: relayStylePagination(),
  DeltaMeterConnection: relayStylePagination(),
  DeltaMeterReadingConnection: relayStylePagination(),
  DocumentConnection: relayStylePagination(),
  OrganizationConnection: relayStylePagination(),
  PortfolioConnection: relayStylePagination(),
  PostalAddress: { merge: true },
  TransactionConnection: relayStylePagination(),
  UserConnection: relayStylePagination(),
  UtilityMeterReadingConnection: relayStylePagination(),
  Organization: {
    fields: {
      buildings: {
        keyArgs: ["buildingIds"],
      },
    },
  },
  OrganizationDocument: {
    fields: {
      users: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  /**
   * You'll also want to include the mocked
   * properties here so they can resolve to a
   * predictable value, using a `read` function
   *
   * https://www.apollographql.com/docs/react/development-testing/client-schema-mocking/#2-define-a-read-function
   */
  // EntityName: {
  //   fields: {
  //     propertyName: {
  //       read() {
  //         return "My value here";
  //       },
  //     },
  //   },
  // },
};

const cache = new InMemoryCache({ typePolicies });

/**
 * This is the place to extend API entities
 * should you find yourself working on client-side
 * mocks prior to a backend release
 *
 * Include the typeDefs in the new ApolloClient({...}) invocation as well
 */
// const typeDefs = gql`
//   extend type EntityName {
//     propertyName: String
//   }
// `;

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([new SentryLink(), authMiddleware, httpLink]),
  defaultOptions: {
    // affects useQuery
    watchQuery: {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: (firstFetchPolicy) =>
        firstFetchPolicy === "cache-and-network"
          ? "cache-first"
          : firstFetchPolicy,
    },
  },
});

export default client;
