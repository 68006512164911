import { EnergyUnitType } from "../api/graphql";
import Energy from "../lib/Energy";
import sum from "../lib/sum";

/**
 * Calculates the annually compounded rate, determined by the year
 */
export const calculateCompoundedRate = (rate: number, year: number) => {
  return Math.pow(1 + rate, year);
};

/**
 * Yields a compounded value (usually interest, in this case escalation) for a given amount within the term
 *
 * Reference: https://en.wikipedia.org/wiki/Compound_interest - Periodic compounding
 * Assume compounded annually, so `n = 1`
 *
 * @param principal - The initial amount
 * @param rate - The growth/interest rate
 * @param year - The target year within the term
 */
export const calculateCompoundedPrincipal = (
  principal: number,
  rate: number,
  year: number
) => principal * calculateCompoundedRate(rate, year);

/**
 * Calculates the cash inflow (not taking into account expenses) for one energy source per year
 *
 * @param averageYearlyEE - The average annual energy efficiency return
 * @param energyRate - The cost of a unit of energy
 * @param escalation - The escalation rate for the unit rate
 * @param year - The target year within the term
 */
export const calculateNominalAnnualCashInflow = (
  averageYearlyEE: Energy,
  energyRate: { cost: { value: number }; unit: EnergyUnitType },
  escalation: number,
  year: number
) =>
  averageYearlyEE.times(
    calculateCompoundedPrincipal(
      convertRate(energyRate.cost.value, energyRate.unit, averageYearlyEE.unit),
      escalation,
      year
    )
  ).quantity;

/**
 * Calculates the Net Present Value (NPV) of an investment
 *
 * @param initialInvestment - The initial investment
 * @param discountRate - The discount rate, as a decimal
 * @param cashFlows - An array of cash flows for the term of the investment
 *
 * Note: We do not discount in the first year
 *
 * Reference: https://www.investopedia.com/ask/answers/032615/what-formula-calculating-net-present-value-npv.asp
 */
export const calculateNPV = (
  initialInvestment: number,
  discountRate: number,
  cashFlows: number[]
) =>
  sum(cashFlows.map((el, idx) => el / Math.pow(1 + discountRate, idx))) -
  initialInvestment;

/**
 * Calculates the Internal Rate of Return for a given investment
 *
 * @param initialInvestment - The initial investment
 * @param cashFlows - An array of cash flows for the term of the investment
 *
 * We have created our NPV and IRR calcs equal to XNPV and XIRR,
 * where the investment and first year cashflow happen on the same day.
 * This IRR function is accurate to Excel's XIRR percentage 2 decimal places
 *
 * Refactored from https://stackoverflow.com/questions/15089151/javascript-irr-internal-rate-of-return-formula-accuracy
 */
export const calculateIRR = (
  initialInvestment: number,
  cashFlows: number[]
): number | undefined => {
  const MAX_LOOPS = 1000;
  const SPECIFICITY = 0.00001;
  let loopCount = 0;
  let minGuess = 0;
  let maxGuess = 100000000;
  let NPV: number;
  let guess: number;

  do {
    guess = (minGuess + maxGuess) / 2;
    NPV = calculateNPV(initialInvestment, guess, cashFlows);
    NPV > 0 ? (minGuess = guess) : (maxGuess = guess);
    loopCount++;
  } while (Math.abs(NPV) > SPECIFICITY && loopCount < MAX_LOOPS);

  if (loopCount >= MAX_LOOPS) return undefined;
  return guess;
};

/**
 * Calculates the maximum initial investment for a financial model to the nearest dollar,
 * given cash flows and a discount rate.
 *
 * @param discountRate - The discount rate
 * @param cashFlows - An array of cash flows for the term of the investment
 */
export const calculateInitialInvestment = (
  discountRate: number, // Decimal percentage
  cashFlows: number[]
): number | undefined =>
  Math.floor(Math.max(0, calculateNPV(0, discountRate, cashFlows)));

export type InvestmentStatus = "good" | "warn" | "bad";

export const calculateInvestmentStatus = (
  irr: number,
  discountRate: number
): InvestmentStatus => {
  if (irr - discountRate >= 0) return "good";
  if (irr - discountRate >= -0.005) return "warn";
  return "bad";
};

/**
 * convertRate will convert a utility rate's unit cost to a target energy type
 */
export const convertRate = (
  unitCost: number,
  currentUnit: EnergyUnitType,
  targetUnit: EnergyUnitType
): number =>
  unitCost /
  new Energy({ quantity: 1, unit: currentUnit }).as(targetUnit).quantity;
