import { useMutation } from "@apollo/client";
import { Switch } from "@material-ui/core";
import React, { ChangeEvent, FC, useState } from "react";
import { BuildingAccessType } from "../api/graphql";
import { UpdateBuildingPermissionDocument } from "../BuildingPermission";
import HelpMe from "../ui/HelpMe";
import { useToastContext } from "../ui/ToastProvider";
import { BuildingPermission } from "./UserBuildings";

interface Props {
  permission: BuildingPermission;
}

const AccessToggler: FC<Props> = ({ permission }) => {
  const createToast = useToastContext();
  const [editAccess] = useMutation(UpdateBuildingPermissionDocument);
  const [buildingAccess, setBuildingAccess] = useState<boolean>(
    permission.access === BuildingAccessType.WRITE
  );

  const onChange = async (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    try {
      await editAccess({
        variables: {
          input: {
            access: checked
              ? BuildingAccessType.WRITE
              : BuildingAccessType.READ,
            buildingId: permission.building.id,
            userId: permission.user.id,
          },
        },
      });

      setBuildingAccess(!!checked);

      createToast("Building access level changed", "success");
    } catch (err) {
      createToast(err.message, "error");
    }
  };

  return (
    <>
      <Switch checked={buildingAccess} color="primary" onChange={onChange} />
      Building admin
      <HelpMe
        title="Building administrator"
        content="Building administrators have the same capabilities as an Owner or Admin on explicitly authorized buildings. This includes, but is not limited to, editing building properties, utility data, and energy or financial models."
      />
    </>
  );
};

export default AccessToggler;
