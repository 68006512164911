import React, { FC } from "react";
import useDeleteEntity from "../lib/useDeleteEntity";
import { useSessionWithUser } from "../SessionWithUser";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import { useToastContext } from "../ui/ToastProvider";
import { DeleteStatementDocument } from "./DeleteStatementMutation.generated";

interface Props {
  deltaMeterStatementId: string;
}

const VoidStatementButton: FC<Props> = ({ deltaMeterStatementId }) => {
  const { isSuperuser } = useSessionWithUser();
  const [deleteStatement] = useDeleteEntity(DeleteStatementDocument);
  const createToast = useToastContext();

  if (!isSuperuser) return null;

  const onDelete = async () => {
    try {
      await deleteStatement(
        {
          id: deltaMeterStatementId,
          __typename: "DeltaMeterStatement",
        },
        {
          input: {
            deltaMeterStatementId,
          },
        }
      );
      createToast("Statement was voided", "success");
    } catch (err) {
      createToast(err.message || "Statement could not be voided", "error");
    }
  };

  return (
    <ButtonWithConfirmation
      size="small"
      header="Void statement?"
      description="The current statement will be voided. This can't be undone."
      onClick={onDelete}
    >
      Void
    </ButtonWithConfirmation>
  );
};

export default VoidStatementButton;
