import React, { FC } from "react";
import EnergySignatureChart from "./EnergySignatureChart";
import ModelMetadata from "./ModelMetadata";
import ModelParameters from "./ModelParameters";
import ReferencePeriodChart from "./ReferencePeriodChart";

interface Props {
  calibratedModelId: string;
}

const CalibratedModel: FC<Props> = ({ calibratedModelId }) => {
  return (
    <>
      <ModelMetadata calibratedModelId={calibratedModelId} />
      <EnergySignatureChart calibratedModelId={calibratedModelId} />
      <ReferencePeriodChart calibratedModelId={calibratedModelId} />
      <ModelParameters calibratedModelId={calibratedModelId} />
    </>
  );
};

export default CalibratedModel;
