import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  makeStyles,
  Paper,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { ReactNode, useState } from "react";
import { Control, Controller } from "react-hook-form";
import containsToken from "../lib/containsTerm";

const Container = styled("div")({
  maxWidth: 500,
});

const PaperScroll = styled(Paper)(({ theme: { spacing } }) => ({
  maxHeight: 175,
  overflowX: "hidden",
  overflowY: "auto",
  padding: spacing(0),
  backgroundColor: "#fafdfe",
}));

const LinkText = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.primary.main,
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const FormLabelStyle = styled(FormControlLabel)(({ theme: { spacing } }) => ({
  height: spacing(4),
}));

const SearchWrapper = styled("div")({
  position: "sticky",
  top: 0,
  backgroundColor: "#fafdfe",
  zIndex: 1,
});

const FieldWrapper = styled(TextField)(({ theme: { spacing } }) => ({
  width: "100%",
  paddingTop: spacing(1),
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
  margin: 0,
}));

const FormControlWrapper = styled(FormControl)(({ theme: { spacing } }) => ({
  padding: spacing(1),
}));

const FixedLabel = styled(Typography)({
  width: 175,
});

const useStyles = makeStyles({
  hide: {
    display: "none",
  },
});

export type ControllerProps = Omit<CheckboxProps, "value" | "onChange"> & {
  name: string;
  control: Control;
};

interface CheckboxListProps<T> {
  options: T[];
  title?: string;
  showSearchBar?: boolean;
  labelFormatter?: (label: T) => ReactNode;
  onChange: (value: T[]) => void;
  value: T[];
  disableReset?: boolean;
}

export const CheckboxList = <T extends string = string>({
  options,
  title,
  showSearchBar,
  labelFormatter,
  value,
  onChange,
  disableReset,
}: CheckboxListProps<T>) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const classes = useStyles();

  // Uses type narrowing to determine whether formatted labels should be used to search on
  // TODO: Will have to figure out how to search on union type label value (ReactNode) before making this our default checkbox component
  const labelContainsToken = (opt: T) => {
    let labelContainsToken: boolean = containsToken(opt, searchTerm);

    if (!!labelFormatter) {
      const formattedLabel = labelFormatter(opt);

      if (typeof formattedLabel === "string") {
        labelContainsToken = containsToken(formattedLabel, searchTerm);
      }
    }

    return labelContainsToken;
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between">
        {title && <Typography variant="body2">{title}</Typography>}
        {!disableReset && value.length > 0 && (
          <LinkText variant="caption" onClick={() => onChange([])}>
            Reset
          </LinkText>
        )}
      </Box>
      <PaperScroll variant="outlined">
        {showSearchBar && (
          <SearchWrapper>
            <FieldWrapper
              size="small"
              value={searchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              hiddenLabel
              onChange={({ target: { value } }) => setSearchTerm(value)}
            />
          </SearchWrapper>
        )}
        <FormControlWrapper>
          <FormGroup>
            {options.map((opt, idx) => {
              return (
                <div
                  key={`form-label-${idx}`}
                  className={labelContainsToken(opt) ? "" : classes.hide}
                >
                  <FormLabelStyle
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={value.includes(opt)}
                        value={value.includes(opt)}
                        name={opt}
                        onChange={(e) => {
                          if (e.target.checked) {
                            onChange([...(value as T[]), e.target.name as T]);
                          } else {
                            onChange(
                              value.filter((v: string) => v !== e.target.name)
                            );
                          }
                        }}
                      />
                    }
                    label={
                      <FixedLabel variant="body2" noWrap>
                        {labelFormatter ? labelFormatter(opt) : opt}
                      </FixedLabel>
                    }
                  />
                </div>
              );
            })}
          </FormGroup>
        </FormControlWrapper>
      </PaperScroll>
    </Container>
  );
};

const CheckboxListController = <T extends string = string>({
  name,
  control,
  ...props
}: ControllerProps & Omit<CheckboxListProps<T>, "value" | "onChange">) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }) => {
        return <CheckboxList onChange={onChange} value={value} {...props} />;
      }}
    />
  );
};

export default CheckboxListController;
