import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { MeQueryDocument } from "./MeQuery.generated";

const useLazyMeQuery = () => {
  const client = useApolloClient();
  return useCallback(
    () => client.query({ query: MeQueryDocument, fetchPolicy: "no-cache" }),
    [client]
  );
};

// useLazyQuery does not return a Promise
// TODO: revisit with apollo v3
export default useLazyMeQuery;
