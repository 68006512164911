import { makeStyles, Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import React, { FC } from "react";

type Model = {
  __typename: "BuildingAdjustedModel" | "BuildingCalibratedModel";
  default: boolean;
};

interface Props {
  model: Model;
}

const useStyles = makeStyles({
  center: {
    verticalAlign: "middle",
  },
});

const DefaultModel: FC<Props> = ({ model }) => {
  const { center } = useStyles();

  if (!model.default) return null;

  return (
    <Typography variant="caption" color="textSecondary">
      <StarIcon className={center} color="disabled" fontSize="small" />
      Default model
    </Typography>
  );
};

export default DefaultModel;
