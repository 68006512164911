import { EnergyEndUse, EnergySource, EnergyUnitType } from "../../api/graphql";
import { Rates } from "../../BuildingInsights/BuildingInsightsReport";
import { energySourceName } from "../../lib/energySource";
import { EndUse, SeasonalPerformance } from "../../lib/types/Reports";
import unique from "../../lib/unique";
import Energy from "../Energy";

type DataPoint = {
  [key in EnergySource]?: number;
} &
  {
    [key in EnergyEndUse]?: number;
  } & {
    ENERGY_EFFICIENCY?: number;
  };

function formatEfficiencyReport(
  initialPerformance: SeasonalPerformance[],
  comparisonPerformance: SeasonalPerformance[],
  converter: (endUse: EndUse) => number
): DataPoint[] {
  const calcDerivedBaseline = initialPerformance.map(({ endUses }) => {
    return endUses.map(converter).reduce((acc, c) => acc + c, 0);
  });

  const calcChildBaseline = comparisonPerformance.map(({ endUses }) => {
    return endUses.map(converter).reduce((acc, c) => acc + c, 0);
  });

  const formattedChild = formatModelReport(comparisonPerformance, converter);

  return formattedChild.map((data, idx) => {
    const derivedBaseline = calcDerivedBaseline[idx];
    const childBaseline = calcChildBaseline[idx];

    const energyEfficiency = Math.max(derivedBaseline - childBaseline, 0);

    return { ...data, ENERGY_EFFICIENCY: energyEfficiency };
  });
}

function formatModelReport(
  performance: SeasonalPerformance[],
  converter: (endUses: EndUse) => number
): DataPoint[] {
  return performance.map(({ month, endUses }) => {
    const baseloads: { [key in EnergySource]?: number } = {};

    unique(performance[0].endUses.map((el) => el.energySource)).forEach(
      (source) => {
        const sourceSum = endUses
          .filter(
            (el) =>
              energySourceName(el.energySource) === energySourceName(source)
          )
          .filter((eu) => eu.endUse !== EnergyEndUse.SPACE_HEATING)
          .filter((eu) => eu.endUse !== EnergyEndUse.SPACE_COOLING)
          .map(converter)
          .reduce((acc, c) => acc + c, 0);

        return (baseloads[source] = sourceSum);
      }
    );

    const heating = endUses
      .filter((eu) => eu.endUse === EnergyEndUse.SPACE_HEATING)
      .map(converter)
      .reduce((acc, c) => acc + c, 0);

    const cooling = endUses
      .filter((eu) => eu.endUse === EnergyEndUse.SPACE_COOLING)
      .map(converter)
      .reduce((acc, c) => acc + c, 0);

    return {
      month,
      ...baseloads,
      [EnergyEndUse.SPACE_HEATING]: heating,
      [EnergyEndUse.SPACE_COOLING]: cooling,
    };
  });
}

export function formatEnergyEfficiencyReport(
  initialPerformance: SeasonalPerformance[],
  comparisonPerformance: SeasonalPerformance[]
): DataPoint[] {
  return formatEfficiencyReport(
    initialPerformance,
    comparisonPerformance,
    (el) => {
      return new Energy(el.energy).as(EnergyUnitType.KBTU).quantity;
    }
  );
}

export function formatEnergyEfficiencyCurrencyReport(
  initialPerformance: SeasonalPerformance[],
  comparisonPerformance: SeasonalPerformance[],
  rates: Rates
): DataPoint[] {
  return formatEfficiencyReport(
    initialPerformance,
    comparisonPerformance,
    (el) => {
      const { cost, unit } = rates.find(
        (r) => r.energySource === el.energySource
      )!;

      return new Energy(el.energy).as(unit).quantity * cost.value;
    }
  );
}

export function formatModelEnergyReport(
  performance: SeasonalPerformance[]
): DataPoint[] {
  return formatModelReport(performance, (el) => {
    return new Energy(el.energy).as(EnergyUnitType.KBTU).quantity;
  });
}
