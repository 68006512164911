import React from "react";
import { SvgIcon } from "@material-ui/core";

function SvgBulbMdOpt(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23.52 36.446" {...props}>
      <g strokeWidth={0.132}>
        <path
          d="M10.3 36.078a2.932 2.932 0 01-1.14-1.007c-.236-.351-.43-.691-.43-.756 0-.065 1.361-.117 3.043-.117 1.682 0 3.043.052 3.043.117s-.194.405-.43.756a3.133 3.133 0 01-2.613 1.376c-.577 0-.936-.09-1.473-.369zM6.402 32.93c-.158-.379-.168-.566-.049-.928l.15-.453 10.611.069.11.427c.077.296.051.57-.084.893l-.194.466H6.6zm.004-2.587c-.244-.472-.175-1.087.164-1.461.203-.224.554-.24 5.203-.24 4.65 0 5.001.016 5.204.24.339.374.408.989.164 1.46l-.215.416H6.62l-.215-.415zm-.049-2.685c-.087-.104-.214-.595-.283-1.091-.314-2.245-.757-3.242-2.9-6.525C1.117 16.887.593 15.732.2 13.48-.459 9.704.5 6.37 3.04 3.597 4.33 2.19 6.49.9 8.552.301c.894-.259 1.343-.3 3.221-.3 1.879 0 2.328.041 3.222.3 2.053.595 4.226 1.892 5.493 3.278 2.455 2.686 3.433 5.926 2.879 9.54-.371 2.422-1.028 3.903-3.15 7.102-.73 1.102-1.463 2.272-1.628 2.6-.542 1.075-.983 2.484-1.12 3.584-.075.595-.204 1.162-.286 1.262-.228.275-10.597.266-10.826-.009z"
          fillOpacity={0.35}
        />
        <path d="M.15 13.121c.018.12.033.238.054.359.393 2.252.916 3.408 2.975 6.563 2.142 3.282 2.585 4.28 2.9 6.524.069.496.196.987.282 1.092.229.275 10.598.283 10.826.008.083-.1.212-.667.286-1.262.138-1.1.579-2.51 1.12-3.584.166-.328.898-1.497 1.63-2.6 2.12-3.198 2.777-4.679 3.148-7.1zm11.628 15.52c-4.65 0-5 .016-5.203.24-.34.375-.408.99-.164 1.462l.214.415H16.93l.215-.415c.245-.473.176-1.087-.163-1.462-.203-.224-.555-.24-5.204-.24zm-5.27 2.907l-.15.454c-.12.361-.11.549.049.927l.198.474h10.346l.195-.466c.135-.323.16-.596.084-.893l-.11-.426-5.306-.035zm5.27 2.65c-1.682 0-3.043.052-3.043.117 0 .064.194.404.43.756.297.44.65.752 1.14 1.007.536.279.896.368 1.473.368a3.133 3.133 0 002.613-1.375c.236-.352.43-.692.43-.756 0-.065-1.361-.118-3.043-.118z" />
      </g>
    </svg>
  );
}

const BulbMd = () => (
  <SvgIcon component={SvgBulbMdOpt} viewBox="0 0 24 35" fontSize="large" />
);

export default BulbMd;
