import { useMutation } from "@apollo/client";
import { InputAdornment, MenuItem, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import removeEmpty from "../lib/removeEmpty";
import { nameError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import { TextField } from "../ui/TextField";
import { useToastContext } from "../ui/ToastProvider";
import { Document } from "./DocumentCenter";
import { UpdateDocumentDocument } from "./UpdateDocument.generated";

type Props = { doc: Document };

type Fields = {
  filename: string;
};

const validations = (values: Fields) =>
  removeEmpty({
    filename: nameError(values.filename),
  });

/**
 * super simple parsing of extension
 * this has degenerate cases when a filename has multiple extensions (ex. .tar.gz)
 * or when the file has no real extension but contains periods.
 * but the usefulness of not accidentally destroying the extension seem to outweigh the downsides.
 */
export const parseNameAndExtension = (filename: string): [string, string] => {
  const pieces = filename.split(".");
  // if the file starts with a dot, we don't count the first chunk as an extension
  const minPiecesCount = filename.startsWith(".") ? 2 : 1;
  const ext = pieces.length > minPiecesCount ? `.${pieces.pop()}` : "";
  return [pieces.join("."), ext];
};

const RenameDocumentDialog: React.FC<{
  doc: Document;
  onClose: () => void;
}> = ({ doc, onClose }) => {
  const createToast = useToastContext();
  const [updateDocument] = useMutation(UpdateDocumentDocument);

  const [originalFilename, extension] = useMemo(
    () => parseNameAndExtension(doc.file.filename),
    [doc.file.filename]
  );

  const {
    errors,
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = useForm<Fields>({
    defaultValues: { filename: originalFilename },
    resolver: validatorsResolver(validations),
  });

  const doRename = async ({ filename }: Fields) => {
    try {
      await updateDocument({
        variables: {
          input: { documentId: doc.id, filename: `${filename}${extension}` },
        },
      });
      createToast("File was successfully renamed", "success");
      onClose();
    } catch (err) {
      createToast(err.message || "Rename error", "error");
    }
  };

  return (
    <FormDialog
      title="Rename Document"
      onCancel={onClose}
      onSubmit={handleSubmit(doRename)}
      submitName="Rename"
      isSubmitting={isSubmitting}
    >
      <Typography>Enter a new name for the file.</Typography>
      <TextField
        name="filename"
        type="filename"
        label="New Filename"
        inputRef={register}
        error={errors.filename?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{extension}</InputAdornment>
          ),
        }}
      />
    </FormDialog>
  );
};

const RenameDocumentMenuItem: React.FC<Props> = ({ doc }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>Rename</MenuItem>
      {open && (
        <RenameDocumentDialog doc={doc} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default RenameDocumentMenuItem;
