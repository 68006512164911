import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { GetUserInviteDocument } from "./UserInviteQuery.generated";

export const useLazyUserInviteQuery = () => {
  const [fetchInvite, { data, called, error, loading }] = useLazyQuery(
    GetUserInviteDocument
  );

  const getInvite = useCallback(
    (id: string, token: string) => {
      fetchInvite({
        variables: token ? { id: null, token } : { id, token: null },
      });
    },
    [fetchInvite]
  );

  return { getInvite, data, error, loading, called };
};
