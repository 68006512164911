import { Avatar, Box, Container, makeStyles } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import Copyright from "./Copyright";
import { PageTitle } from "./ui/Typography";

interface Props {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const AuthenticationLayout: React.FC<Props> = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <PageTitle>{title}</PageTitle>
        {children}
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default AuthenticationLayout;
