import {
  Box,
  CardActions,
  Grid,
  styled,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React from "react";
import { FormattedDate } from "react-intl";
import { DeltaMeterState } from "../api/graphql";
import assertFeature from "../lib/assertFeature";
import { buildingDeltaMeter } from "../lib/endpoints";
import { useSessionWithUser } from "../SessionWithUser";
import AlertChip from "../ui/AlertChip";
import Button from "../ui/Button";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import { CardLinkedArea } from "../ui/Card";
import colors from "../ui/colors";
import MetaData from "../ui/MetaData";
import StripeCard from "../ui/StripeCard";
import { useToastContext } from "../ui/ToastProvider";

interface DeltaMeter {
  id: string;
  lastStatementDate?: Date | null;
  shortId: string;
  building: {
    id: string;
    name: string;
    address: {
      timeZone: string;
    };
  };
  statements: { date: string }[];
  state: DeltaMeterState;
  startOn: string;
  metersNeedUpdate: boolean;
}

export interface Props {
  onApprove: (deltaMeterId: string) => Promise<void>; // Admin clicks line-item to approve deltameter
  onDelete: (deltaMeterId: string) => Promise<void>;
  deltaMeters: DeltaMeter[];
}

type FontProps = TypographyProps & {
  state: DeltaMeterState;
};

const StatusColor = styled(Box)({
  color: ({ state }: FontProps) =>
    state === "ACTIVE" ? colors.black : colors.gray.main,
});

const DeltaMetersTable: React.FC<Props> = ({
  onApprove,
  onDelete,
  deltaMeters,
}) => {
  const createToast = useToastContext();
  const { isSuperuser } = useSessionWithUser();

  return (
    <Grid container spacing={2}>
      {deltaMeters.map((dm) => {
        return (
          <Grid item xs={12} key={dm.id}>
            <StripeCard orientation="left">
              <CardLinkedArea
                disabled={dm.state === DeltaMeterState.DRAFT}
                to={buildingDeltaMeter({
                  buildingId: dm.building.id,
                })}
              >
                <Box
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Box display="inline-flex" alignItems="center">
                      <Typography variant="h6">
                        <StatusColor state={dm.state}>
                          {dm.building.name}
                        </StatusColor>
                      </Typography>
                      <Box pl={1}>
                        {dm.state === DeltaMeterState.ACTIVE ? (
                          dm.metersNeedUpdate && (
                            <AlertChip label="Action required" />
                          )
                        ) : (
                          <StatusColor state={dm.state}>
                            <Box display="flex" alignItems="center">
                              <LockOpenIcon fontSize="small" />
                              <Typography variant="body2">
                                Activation pending
                              </Typography>
                            </Box>
                          </StatusColor>
                        )}
                      </Box>
                    </Box>
                    <MetaData>
                      <span>ID: {dm.shortId}</span>
                      <span>
                        Start date:{" "}
                        <FormattedDate
                          value={new Date(dm.startOn)}
                          timeZone={dm.building.address.timeZone}
                        />
                      </span>
                      <span>
                        Statement last generated:{" "}
                        {dm.lastStatementDate ? (
                          <FormattedDate
                            value={dm.lastStatementDate}
                            timeZone={dm.building.address.timeZone}
                          />
                        ) : (
                          "N/A"
                        )}
                      </span>
                    </MetaData>
                  </Box>
                  {dm.state === DeltaMeterState.ACTIVE ? (
                    <LockIcon color="disabled" />
                  ) : (
                    <LockOpenIcon color="disabled" />
                  )}
                </Box>
              </CardLinkedArea>
              {isSuperuser && (
                <CardActions>
                  <Box display="flex" flexGrow={1} justifyContent="flex-end">
                    {dm.state === "DRAFT" && (
                      <Button
                        size="small"
                        onClick={() =>
                          onApprove(dm.id).catch((err) => {
                            createToast(err.message, "error");
                          })
                        }
                      >
                        Approve
                      </Button>
                    )}
                    {assertFeature("DELETE_DELTAMETERS") && (
                      <Box pl={1}>
                        <ButtonWithConfirmation
                          size="small"
                          onClick={() =>
                            onDelete(dm.id).catch((err) => {
                              createToast(err.message, "error");
                            })
                          }
                          header="Delete this DeltaMeter?"
                          description="This can't be undone"
                        >
                          Delete
                        </ButtonWithConfirmation>
                      </Box>
                    )}
                  </Box>
                </CardActions>
              )}
            </StripeCard>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DeltaMetersTable;
