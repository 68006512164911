import { Box, Slider, SliderProps, Typography } from "@material-ui/core";
import React from "react";
import { Control, Controller } from "react-hook-form";

export interface Props extends Omit<SliderProps, "value" | "onChange"> {
  formatter?: (value: number) => React.ReactNode;
  icon?: React.ReactNode;
  caption?: string;
  value: number;
  onChange: (value: number) => void;
}

const SliderWithLabel: React.FC<Props> = ({
  icon,
  formatter,
  caption,
  value,
  onChange,
  ...props
}) => {
  return (
    <Box display="flex" alignItems="center">
      {icon && <Box px={1}>{icon}</Box>}
      <Box width="85%">
        <Slider
          {...props}
          value={value}
          onChange={(_, value) => onChange(value as number)}
        />
      </Box>
      <Box
        width="15%"
        pl={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography color="primary" variant="h5">
          {formatter ? formatter(value) : value}
        </Typography>
        {caption && (
          <Typography noWrap color="primary" variant="caption">
            {caption}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SliderWithLabel;

export const SliderWithLabelController: React.FC<
  Omit<Props, "value" | "onChange"> & { name: string; control: Control }
> = ({ name, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ value, onChange }) => (
      <SliderWithLabel value={value} onChange={onChange} {...props} />
    )}
  />
);
