import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import sortAlpha from "../lib/sortAlpha";
import { ControlledAutocomplete } from "../ui/Autocomplete";
import MetaData from "../ui/MetaData";
import { Building } from "./DocumentSharing";

interface Props {
  buildings: Building[];
  onBuildingSelect: (v: Building) => void;
}

const BuildingSearch: FC<Props> = ({ buildings, onBuildingSelect }) => {
  return (
    <ControlledAutocomplete
      label="Search buildings"
      placeholder="Search buildings"
      options={[...buildings].sort((a, b) => sortAlpha(a.name, b.name))}
      getOptionLabel={(building) => building.name}
      onChange={(v) => v && onBuildingSelect(v)}
      renderOption={(option) => (
        <div>
          {option.name}
          <Typography variant="caption">
            <MetaData>
              <span>
                {option.address.locality}, {option.address.region}
              </span>
            </MetaData>
          </Typography>
        </div>
      )}
    />
  );
};

export default BuildingSearch;
