import { Typography } from "@material-ui/core";
import { requestPasswordReset } from "keratin-authn";
import React from "react";
import { useForm } from "react-hook-form";
import removeEmpty from "./lib/removeEmpty";
import { emailError, FieldErrors, presenceError } from "./lib/validators";
import validatorsResolver from "./lib/validatorsResolver";
import FormDialog from "./ui/FormDialog";
import { TextField } from "./ui/TextField";

interface Props {
  onClose: () => void;
  onComplete: (email: string) => void;
}

interface Fields {
  email: string;
}

const validations = (values: Fields): FieldErrors<Fields> =>
  removeEmpty({
    email: presenceError(values.email) || emailError(values.email),
  });

const ForgotPasswordDialog: React.FC<Props> = (props) => {
  const onSubmit = async ({ email }: Fields) => {
    await requestPasswordReset(email);
    props.onComplete(email);
  };

  const {
    errors,
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = useForm<Fields>({
    defaultValues: {},
    resolver: validatorsResolver(validations),
  });

  return (
    <FormDialog
      title="Reset your password"
      onCancel={props.onClose}
      onSubmit={handleSubmit(onSubmit)}
      submitName="Send Email"
      isSubmitting={isSubmitting}
    >
      <Typography variant="body1">
        Enter your email address and we will send a link that allows you to
        reset your password.
      </Typography>
      <TextField
        name="email"
        type="email"
        label="Email Address"
        autoComplete="email"
        inputRef={register}
        error={errors.email?.message}
      />
    </FormDialog>
  );
};

export default ForgotPasswordDialog;
