import { useState } from "react";

/**
 * Persists the state of the filter toggle to local storage. Defaults to false
 */
const useToggleFilterDrawer = (): [boolean, () => void] => {
  const [filterOpen, setFilterOpen] = useState<boolean>(() => {
    const filterDrawerState = localStorage.getItem("filterDrawerState");
    return filterDrawerState === "true";
  });

  const setLocalStorageValue = (value: boolean) => {
    setFilterOpen(value);
    localStorage.setItem("filterDrawerState", JSON.stringify(value));
  };

  return [filterOpen, () => setLocalStorageValue(!filterOpen)];
};

export default useToggleFilterDrawer;
