import { useMutation } from "@apollo/client";
import { FormHelperText } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { DeltaMeterStatementModelFragment } from "../api/fragments/DeltaMeterStatementModel.generated";
import { FileAssetInput } from "../api/graphql";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import FormDialog from "../ui/FormDialog";
import { useToastContext } from "../ui/ToastProvider";
import FileInput from "../Uploads/FileInput";
import { UploadStatementPdfDocument } from "./UploadStatementPdfMutation.generated";

interface Props {
  deltaMeterStatementId: string;
  currentPdf: DeltaMeterStatementModelFragment["pdf"];
}

interface Field {
  file: FileAssetInput[];
}

const UploadStatementPdfButton: FC<Props> = ({
  deltaMeterStatementId,
  currentPdf,
}) => {
  const { isSuperuser } = useSessionWithUser();
  const createToast = useToastContext();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [uploadPdf] = useMutation(UploadStatementPdfDocument);
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Field>({
    defaultValues: {
      file: currentPdf ? [currentPdf] : [],
    },
  });

  const onSubmit = async (field: Field) => {
    const { key, filename, mimetype } = field.file[0];

    try {
      await uploadPdf({
        variables: {
          input: {
            deltaMeterStatementId,
            pdf: {
              key,
              filename,
              mimetype,
            },
          },
        },
      });
      createToast("File was successfully uploaded", "success");
      setShowDialog(false);
    } catch (err) {
      createToast(err.message || "Upload error", "error");
    }
  };

  if (!isSuperuser) return null;

  return (
    <>
      {showDialog && (
        <FormDialog
          title={`${!!currentPdf ? "Replace" : "Attach"} statement PDF`}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
        >
          <FileInput
            name="file"
            control={control}
            label="Statement PDF"
            type="application/pdf"
            rules={{
              validate: {
                length: (value: Field["file"]) =>
                  value.length === 1 || "File is required",
              },
            }}
          />
          {errors.file && (
            <FormHelperText error>
              {(errors.file as any).message}
            </FormHelperText>
          )}
        </FormDialog>
      )}
      <Button size="small" onClick={() => setShowDialog(true)}>
        {!!currentPdf ? "Replace PDF" : "Attach PDF"}
      </Button>
    </>
  );
};

export default UploadStatementPdfButton;
