import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import React, { FC } from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

const NoDocuments: FC = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderSegment
        icon={InsertDriveFileIcon}
        header="No documents"
        subheader="Documents haven't been uploaded yet"
      />
    </PlaceholderContainer>
  );
};

export default NoDocuments;
