import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Currency, WeatherSource } from "../api/graphql";
import { financialModels } from "../lib/endpoints";
import { defaultEnergySourceUnit } from "../lib/energySource";
import BackButton from "../ui/BackButton";
import Loading from "../ui/Loading";
import { convertRate } from "./finance";
import FinancialModelDashboard, {
  Expense,
  ProjectMetadata,
  Rate,
} from "./FinancialModelDashboard";
import { InitializeFinancialModelFormDocument } from "./InitializeFinancialModelForm.generated";

interface Props {
  buildingId: string;
}

export interface NewInvestmentState {
  calibratedModelId: string;
  discountRate: number;
  projectCost?: number;
}

// TODO: users should never get to this component if there is no calibrated model
const NewFinancialModelContainer: FC<Props> = ({ buildingId }) => {
  const location = useLocation<NewInvestmentState>();

  const { data, loading, error } = useQuery(
    InitializeFinancialModelFormDocument,
    {
      variables: {
        buildingId,
        calibratedModelId: location.state?.calibratedModelId,
      },
      skip: !location.state?.calibratedModelId,
    }
  );

  if (!location.state?.calibratedModelId)
    return <Redirect to={financialModels({ buildingId })} />;
  if (error) throw error;
  if (loading || !data) return <Loading variant="circle" />;
  if (!data.building.calibratedModel)
    throw new Error("Building has no calibrated model");

  const {
    building: { calibratedModel, squareFeet },
  } = data;

  const { projectCost, discountRate } = location.state;

  // Transform default rates
  const defaultRates: Rate[] = data.building.rates.map((r) => ({
    energySource: r.energySource,
    cost: {
      value: convertRate(
        r.cost.value,
        r.unit,
        defaultEnergySourceUnit(r.energySource)
      ),
      unit: r.cost.unit,
    },
    unit: defaultEnergySourceUnit(r.energySource),
    escalation: r.escalation,
    eiaSource: r.eiaSource,
  }));

  const defaultMetadata: ProjectMetadata = {
    name: "",
    notes: "",
  };

  // Create default expenses
  const expenses: Expense[] = [
    {
      name: "DeltaMeter & Transactions",
      amount: { value: 0.09 * squareFeet, unit: Currency.USD },
      escalation: 0.05,
    },
  ];

  return (
    <>
      <Box>
        <BackButton
          to={financialModels({ buildingId })}
          label="Back to financial models"
        />
      </Box>
      <FinancialModelDashboard
        buildingId={buildingId}
        calibratedModelId={location.state.calibratedModelId}
        calibratedModelData={calibratedModel}
        adjustedModelActualWeatherData={calibratedModel.adjustedModels}
        adjustedModelNormalWeatherData={calibratedModel.adjustedModels}
        defaultRates={defaultRates}
        defaultMetadata={defaultMetadata}
        projectCost={projectCost}
        discountRate={discountRate}
        expenses={expenses}
        weatherSource={WeatherSource.ACTUAL}
      />
      ;
    </>
  );
};

export default NewFinancialModelContainer;
