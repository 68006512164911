import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { DocumentCenterList, UploadDocumentButton } from "../DocumentCenter";
import DocumentCenterListSkeleton from "../DocumentCenter/DocumentCenterListSkeleton";
import FilterDocuments, {
  Filters,
  onFilterSelect,
} from "../DocumentCenter/FilterDocuments";
import NoDocuments from "../DocumentCenter/NoDocuments";
import pluckNodes from "../lib/pluckNodes";
import { useSessionWithUser } from "../SessionWithUser";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import BuildingDocumentSharing from "./BuildingDocumentSharing";
import { BuildingDocumentsDocument } from "./BuildingDocumentsQuery.generated";
import { LinkDocumentToBuildingDocument } from "./LinkDocumentToBuilding.generated";

interface Props {
  buildingId: string;
}

const BuildingDocumentCenter: FC<Props> = ({ buildingId }) => {
  const { user } = useSessionWithUser();
  const [filter, setFilter] = useState<Filters>(Filters.NONE);
  // Holds the documentId for the recently uploaded document
  const [documentSharing, setDocumentSharing] = useState<string | null>(null);
  const { data, loading, error, refetch } = useQuery(
    BuildingDocumentsDocument,
    {
      variables: {
        buildingId,
        deleted: filter === Filters.TRASH,
      },
      // avoid stale cache when switching in/out of trash
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );
  const [setDocBuildings] = useMutation(LinkDocumentToBuildingDocument);
  const createToast = useToastContext();

  const documents = useMemo(() => {
    if (loading || !data) {
      return [];
    }
    return pluckNodes(data.building.documents);
  }, [data, loading]);

  const filteredDocuments = useMemo(() => {
    return onFilterSelect(documents, filter, user.id);
  }, [documents, filter, user.id]);

  const onUploadSuccess = async (documentId: string) => {
    // link document to this building.
    try {
      await setDocBuildings({
        variables: { input: { documentId, buildingIds: [buildingId] } },
      });
      createToast("Document uploaded and linked to building", "success");
      await refetch({ buildingId, deleted: false });
      setDocumentSharing(documentId);
      // TODO: prompt for sharing doc w. building users?
    } catch (err) {
      createToast(err.message || "Error linking document to building", "error");
    }
  };

  if (error) throw error;
  if (!data || loading)
    return (
      <>
        <Box pb={1}>
          <PageTitle>Document center</PageTitle>
        </Box>
        <DocumentCenterListSkeleton />
      </>
    );

  return (
    <>
      {documentSharing && (
        <BuildingDocumentSharing
          buildingId={buildingId}
          documentId={documentSharing}
          onDismiss={() => {
            setDocumentSharing(null);
          }}
        />
      )}
      <Box pb={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <PageTitle>Document center</PageTitle>
          <Box>
            <Box display="inline" pr={2}>
              <FilterDocuments filter={filter} onChange={(v) => setFilter(v)} />
            </Box>
            <UploadDocumentButton
              onSuccess={onUploadSuccess}
              disabled={filter === Filters.TRASH}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        {documents.length === 0 && filter !== Filters.TRASH ? (
          <NoDocuments />
        ) : (
          <DocumentCenterList
            documents={filteredDocuments}
            buildingId={buildingId}
          />
        )}
      </Box>
    </>
  );
};

export default BuildingDocumentCenter;
