import { Typography } from "@material-ui/core";
import React from "react";
import LegalLayout from "./LegalLayout";

const TermsOfService = () => {
  return (
    <LegalLayout title="Terms of Service">
      <Typography paragraph>
        When acting on behalf of the organization through which you are gaining
        access to the EnergyRM Platform site, you must comply with the Access
        Rights set forth in the access agreement executed between the
        organization and EnergyRM. If you have questions concerning the terms of
        use or any restrictions that apply to your use of the EnergyRM Platform,
        please contact the organization through which you have received access
        to the EnergyRM Platform.
      </Typography>
    </LegalLayout>
  );
};

export default TermsOfService;
