import {
  Grid,
  Link,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AuthenticationLayout from "./AuthenticationLayout";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import assertFeature from "./lib/assertFeature";
import { useAuthN } from "./lib/AuthN";
import { register as registerUrl } from "./lib/endpoints";
import removeEmpty from "./lib/removeEmpty";
import ServerErrors from "./lib/ServerErrors";
import useAuthNErrors from "./lib/useAuthNErrors";
import { emailError, FieldErrors, presenceError } from "./lib/validators";
import validatorsResolver from "./lib/validatorsResolver";
import { AccountCreationLocationState } from "./Register";
import Button from "./ui/Button";
import Loading from "./ui/Loading";
import { TextField } from "./ui/TextField";
import { useToastContext } from "./ui/ToastProvider";

interface Props {}

interface Fields {
  email: string;
  password: string;
}

const validations = (values: Fields): FieldErrors<Fields> =>
  removeEmpty({
    email: presenceError(values.email) || emailError(values.email),
    password: presenceError(values.password),
  });

const Messages = {
  credentials_FAILED: "Unknown email or password",
  credentials_EXPIRED: "Password has expired. Please reset.",
  account_LOCKED: "That account is no longer active.",
  default: "Something went wrong.",
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  linkButton: {
    cursor: "pointer",
  },
}));

const Signin: React.FC<Props> = () => {
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState<boolean>(
    false
  );
  const createToast = useToastContext();
  const history = useHistory<AccountCreationLocationState>();
  const { logIn } = useAuthN();
  const [authNErrors, catchAuthNErrors] = useAuthNErrors();

  const onSubmit = async (fields: Fields) =>
    catchAuthNErrors(async () => {
      await logIn({ username: fields.email, password: fields.password });
      history.push(history.location.state?.then || "/");
    });

  const {
    errors,
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = useForm<Fields>({
    defaultValues: {
      email: history.location.state?.email ?? "",
      password: "",
    },
    resolver: validatorsResolver(validations),
  });

  const theme = useTheme();
  const classes = useStyles();
  const showRegistrationLink =
    assertFeature("SIGN_UP") || history.location.state?.inviteToken;
  return (
    <AuthenticationLayout title="Sign in">
      {history.location.state?.message && (
        <Typography>{history.location.state.message}</Typography>
      )}
      {forgotPasswordDialog && (
        <ForgotPasswordDialog
          onClose={() => setForgotPasswordDialog(false)}
          onComplete={(email) => {
            createToast(`Password reset sent to ${email}`, "success");
            setForgotPasswordDialog(false);
          }}
        />
      )}

      <form
        noValidate
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ServerErrors errors={authNErrors} messages={Messages} />

        <TextField
          name="email"
          type="email"
          label="Email Address"
          required
          inputRef={register}
          error={errors.email?.message}
          autoComplete="email"
        />

        <TextField
          name="password"
          type="password"
          label="Password"
          required
          inputRef={register}
          error={errors.email?.message}
          autoComplete="current-password"
        />

        <Button
          type="submit"
          disabled={isSubmitting}
          fullWidth
          primary
          className={classes.submit}
          endIcon={
            isSubmitting && (
              <Loading variant="circle" size={theme.typography.fontSize} />
            )
          }
        >
          Sign In
        </Button>

        <Grid container justify="space-between">
          <Grid item>
            <Link
              className={classes.linkButton}
              onClick={() => setForgotPasswordDialog(true)}
            >
              Forgot your password?
            </Link>
          </Grid>
          <Grid item>
            {showRegistrationLink && (
              <Link
                component={RouterLink}
                to={{
                  pathname: registerUrl(),
                  state: history.location.state,
                }}
                variant="body2"
              >
                Don't have an account? Register
              </Link>
            )}
          </Grid>
          {!showRegistrationLink && (
            <Grid item>
              <Typography align="center" variant="body2">
                Don't have an account? Contact your organization administrator
                for an invitation.
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </AuthenticationLayout>
  );
};

export default Signin;
