import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { isValidDate } from "../lib/validators";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import { DatePickerController } from "../ui/DatePicker";
import FormDialog from "../ui/FormDialog";
import { useToastContext } from "../ui/ToastProvider";
import { GenerateStatementDocument } from "./GenerateStatementMutation.generated";

interface Props {
  deltaMeterId: string;
  onSuccess?: () => Promise<unknown>;
}

interface Field {
  date: string;
}

const NewDeltaMeterStatementButton: FC<Props> = ({
  deltaMeterId,
  onSuccess,
}) => {
  const { isSuperuser } = useSessionWithUser();
  const createToast = useToastContext();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<Field>({
    defaultValues: { date: DateTime.fromJSDate(new Date()).toISO() },
  });

  const [generateStatement] = useMutation(GenerateStatementDocument);

  const onSubmit = async ({ date }: Field) => {
    try {
      await generateStatement({
        variables: {
          input: {
            deltaMeterId,
            date,
          },
        },
      });
      setShowDialog(false);
      createToast("DeltaMeter statement was generated and approved", "success");
      if (onSuccess) await onSuccess();
    } catch (err) {
      createToast(err.message || "No new statements were generated", "error");
    }
  };

  if (!isSuperuser) return null;

  return (
    <>
      {showDialog && (
        <FormDialog
          title="Generate a statement"
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
        >
          <DatePickerController
            label="Select a statement date"
            name="date"
            control={control}
            disableFuture
            inputVariant="outlined"
            error={errors.date?.message}
            rules={{
              required: "Date is required",
              validate: isValidDate,
            }}
          />
        </FormDialog>
      )}
      <Button size="small" onClick={() => setShowDialog(true)}>
        Generate statement
      </Button>
    </>
  );
};

export default NewDeltaMeterStatementButton;
