import { Building } from ".";
import {
  BuildingModelFit,
  BuildingUseType,
  EnergySource,
  LeaseType,
  UtilityMeter,
} from "../api/graphql";
import containsToken from "../lib/containsTerm";
import sortAlpha from "../lib/sortAlpha";
import { SortBy } from "./sortReducer";

export type MinMax = [number, number];

export type ModelFit = BuildingModelFit | "NONE";

export enum LogicalOperator {
  AND = "AND",
  OR = "OR",
  EXACTLY = "EXACTLY",
}

export type Filters = {
  energySourcesLogicalOperator: LogicalOperator;
  eePercent: MinMax;
  eeEnergy: MinMax;
  eeMoney: MinMax;
  eeEnergyPerSquareUnit: MinMax;
  eeMoneyPerSquareUnit: MinMax;
  buildingSize: MinMax;
  yearBuilt: MinMax;
  buildingName: string;
  sortBy: SortBy;
  leaseType: LeaseType[];
  majorTenant: string[];
  buildingUseType: BuildingUseType[];
  location: string[];
  energySources: EnergySource[];
  modelFit: ModelFit[];
};

export const isWithin = (min: number, value: number, max: number): boolean => {
  return min <= value && value <= max;
};

export const distinctEnergySources = (
  utilityMeters: Pick<UtilityMeter, "id" | "energySource">[]
): EnergySource[] => {
  return Array.from(new Set(utilityMeters.map((r) => r.energySource)));
};

export const filterCallbacks: Record<
  keyof Omit<Filters, "sortBy" | "energySourcesLogicalOperator">,
  (...args: any[]) => (b: Building) => boolean
> = {
  eeEnergy: (min: number, max: number) => (b) =>
    b.calibratedModel
      ? isWithin(
          min,
          b.calibratedModel?.adjustedModel?.opportunity.energy.quantity || 0,
          max
        )
      : true,
  eePercent: (min: number, max: number) => (b) =>
    b.calibratedModel
      ? isWithin(
          min,
          (b.calibratedModel?.adjustedModel?.opportunity.energyDepth || 0) *
            100,
          max
        )
      : true,
  eeMoney: (min: number, max: number) => (b) =>
    b.calibratedModel
      ? isWithin(
          min,
          b.calibratedModel?.adjustedModel?.opportunity.money.value || 0,
          max
        )
      : true,
  eeEnergyPerSquareUnit: (min: number, max: number) => (b) =>
    b.calibratedModel
      ? isWithin(
          min,
          b.calibratedModel?.adjustedModel?.opportunity.energyPerSquareFoot
            .quantity || 0,
          max
        )
      : true,
  eeMoneyPerSquareUnit: (min: number, max: number) => (b) =>
    b.calibratedModel
      ? isWithin(
          min,
          b.calibratedModel?.adjustedModel?.opportunity.moneyPerSquareFoot
            .value || 0,
          max
        )
      : true,
  buildingSize: (min: number, max: number) => (b) =>
    isWithin(min, b.squareFeet, max),
  yearBuilt: (min: number, max: number) => (b) =>
    b.yearBuilt ? isWithin(min, b.yearBuilt || 0, max) : true,
  buildingName: (name: string) => (b) =>
    !!name ? containsToken(b.name, name) : true,
  leaseType: (leaseTypes: LeaseType[]) => (b) => {
    if (leaseTypes.length === 0) return true;
    return b.leaseType ? leaseTypes.includes(b.leaseType) : true;
  },
  majorTenant: (majorTenants: string[]) => (b) => {
    if (majorTenants.length === 0) return true;
    return b.majorTenant ? majorTenants.includes(b.majorTenant) : true;
  },
  buildingUseType: (buildingUseType: BuildingUseType[]) => (b) => {
    if (buildingUseType.length === 0) return true;
    return buildingUseType.includes(b.buildingUseType);
  },
  location: (locations: string[]) => (b) => {
    if (locations.length === 0) return true;
    return locations.includes(b.address.region);
  },
  modelFit: (fits: ModelFit[]) => (b): boolean => {
    if (fits.length === 0) return true;
    return b.calibratedModel
      ? fits.includes(b.calibratedModel.fit)
      : fits.includes("NONE");
  },
  energySources: (
    selectedSources: EnergySource[],
    operator: LogicalOperator
  ) => (b) => {
    if (selectedSources.length === 0) return true; // Handle all unselected

    const sources = distinctEnergySources(b.utilityMeters);

    switch (operator) {
      case LogicalOperator.AND:
        return selectedSources.every((s) => sources.includes(s));
      case LogicalOperator.OR:
        return selectedSources.some((s) => sources.includes(s));
      case LogicalOperator.EXACTLY:
        return (
          JSON.stringify([...sources].sort((a, b) => sortAlpha(a, b))) ===
          JSON.stringify([...selectedSources].sort((a, b) => sortAlpha(a, b)))
        );
    }
  },
};

// Map all the callbacks for reduce in filterBuildings
const createFilterCallbacks = (filters: Filters) => {
  return Object.keys(filters).map((key) => {
    if (key === "eePercent") {
      const [min, max] = filters[key];
      return filterCallbacks[key](min, max);
    }
    if (key === "eeMoney") {
      const [min, max] = filters[key];
      return filterCallbacks[key](min, max);
    }
    if (key === "eeEnergy") {
      const [min, max] = filters[key];
      return filterCallbacks[key](min, max);
    }
    if (key === "eeEnergyPerSquareUnit") {
      const [min, max] = filters[key];
      return filterCallbacks[key](min, max);
    }
    if (key === "eeMoneyPerSquareUnit") {
      const [min, max] = filters[key];
      return filterCallbacks[key](min, max);
    }
    if (key === "buildingName") {
      return filterCallbacks[key](filters.buildingName);
    }
    if (key === "buildingSize") {
      const [min, max] = filters[key];
      return filterCallbacks[key](min, max);
    }
    if (key === "yearBuilt") {
      const [min, max] = filters[key];
      return filterCallbacks[key](min, max);
    }
    if (key === "leaseType") {
      return filterCallbacks[key](filters.leaseType);
    }
    if (key === "majorTenant") {
      return filterCallbacks[key](filters.majorTenant);
    }
    if (key === "buildingUseType") {
      return filterCallbacks[key](filters.buildingUseType);
    }
    if (key === "location") {
      return filterCallbacks[key](filters.location);
    }
    if (key === "energySources") {
      return filterCallbacks[key](
        filters.energySources,
        filters.energySourcesLogicalOperator
      );
    }
    if (key === "modelFit") {
      return filterCallbacks[key](filters.modelFit);
    }
    return () => true;
  });
};

export const filterBuildings = (buildings: Building[], filters: Filters) => {
  return createFilterCallbacks(filters).reduce(
    (acc, curr) => acc.filter(curr),
    buildings
  );
};
