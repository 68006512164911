import useLocalStorageState, {
  getLocalStorage,
  setLocalStorage,
} from "./useLocalStorage";

interface Building {
  id: string;
  organization: {
    id: string;
  };
}

const MAX_LIST_LENGTH = 10;

const key = (organizationId: string) => `recent-buildings-${organizationId}`;

/**
 * trackBuildingView records the building in localStorage.
 *
 * This is stateless.
 */
export const trackBuildingView = (building: Building) => {
  const storageKey = key(building.organization.id);
  const previous = getLocalStorage<string[]>(storageKey) || [];
  const remaining = previous.filter((id) => id !== building.id);
  const current = [building.id, ...remaining].slice(0, MAX_LIST_LENGTH);
  setLocalStorage(storageKey, current);
};

const useRecentlyViewedBuildings = (organizationId: string) => {
  const storageKey = key(organizationId);
  const [recentBuildings] = useLocalStorageState<string[]>(storageKey, []);

  return recentBuildings;
};

/** Stateless retrieval of recently-viewed buildings */
export const recentlyViewedBuildings = (organizationId: string): string[] =>
  getLocalStorage<string[]>(key(organizationId)) ?? [];

export default useRecentlyViewedBuildings;
