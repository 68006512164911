import { styled, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import millify from "millify";
import React, { FC, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { AutoSizer, List as VirtualizedList } from "react-virtualized";
import { Building } from ".";
import { BuildingModelFit, EnergyUnitType } from "../api/graphql";
import LeaseIcon from "../assets/Lease";
import displayUnit from "../lib/displayUnit";
import { buildingById } from "../lib/endpoints";
import Energy from "../lib/Energy";
import displayLeaseType from "../lib/LeaseType";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import colors, { modelFit as modelFitColor } from "../ui/colors";

export interface Props {
  buildings: Building[];
}

const PercentChange: FC<{ number: number }> = ({ number }) => (
  <>
    {number > 0 ? (
      <ArrowDownward fontSize="small" />
    ) : (
      <ArrowUpward fontSize="small" />
    )}
    {number.toFixed(1)}%
  </>
);

const CustomMeta = styled("div")({
  "& > *": {
    color: colors.gray.main,
  },
  "& > :not(:first-child)::before": {
    content: '" • "',
  },
});

const CustomPaper: FC<{
  id: string;
  modelFit: BuildingModelFit | undefined;
  height: number;
}> = ({ modelFit, height, children }) => {
  const [hover, setHover] = useState<boolean>(false);
  const {
    palette: { grey, common },
  } = useTheme();

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height,
        width: "100%",
        border: `1px solid ${grey[300]}`,
        borderRadius: "5px",
        borderLeft: `5px solid ${
          modelFit
            ? modelFit === BuildingModelFit.GOOD
              ? modelFitColor.good
              : modelFit === BuildingModelFit.MODERATE
              ? modelFitColor.moderate
              : modelFitColor.inadequate
            : modelFitColor.missing
        }`,
        backgroundColor: hover ? grey[100] : common.white,
        cursor: hover ? "pointer" : "default",
      }}
    >
      {children}
    </div>
  );
};

const CustomGridContainer: FC = ({ children }) => (
  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
    {children}
  </div>
);

const CustomGridItem: FC = ({ children }) => (
  <div style={{ gridColumn: "auto", padding: 8, overflow: "auto" }}>
    {children}
  </div>
);

const CustomColumn: FC = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    }}
  >
    {children}
  </div>
);

const CustomFlex: FC = ({ children }) => (
  <div
    style={{
      display: "inline-flex",
      alignItems: "center",
    }}
  >
    {children}
  </div>
);

const PadIcon: FC = ({ children }) => (
  <div style={{ paddingRight: 3, display: "inline-flex" }}>{children}</div>
);

const StyledAnchor: FC<{
  id: string;
}> = ({ id, children }) => {
  return (
    <Link
      to={buildingById({ id })}
      style={{ color: "inherit", textDecoration: "none" }}
    >
      {children}
    </Link>
  );
};

const Row: FC<{
  building: Building;
  height: number;
}> = ({ building, height }) => {
  const { formatNumber } = useIntl();

  const {
    id,
    name,
    buildingUseType,
    squareFeet,
    address,
    yearBuilt,
    majorTenant,
    leaseType,
  } = building;

  const { region } = address;

  const opportunity = building.calibratedModel?.adjustedModel?.opportunity;
  const eui = building.calibratedModel?.annualizedEnergyPerSquareFoot;
  const modelFit = building.calibratedModel?.fit;

  return (
    <StyledAnchor id={id}>
      <CustomPaper id={id} modelFit={modelFit} height={height}>
        <CustomGridContainer>
          <CustomGridItem>
            <CustomColumn>
              <Typography variant="h6" noWrap>
                {name}
              </Typography>
              <CustomMeta>
                <span>{primaryFunctionTitle(buildingUseType)}</span>
                <span>{`${formatNumber(squareFeet)} ft²`}</span>
                <span>{region}</span>
                {yearBuilt && <span>{`Built ${yearBuilt}`}</span>}
              </CustomMeta>
              <div>
                {!!leaseType && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    display="inline"
                  >
                    <CustomFlex>
                      <LeaseIcon fontSize="small" />
                      {displayLeaseType(leaseType)}
                    </CustomFlex>{" "}
                  </Typography>
                )}
                {!!majorTenant && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    display="inline"
                  >
                    <CustomFlex>
                      <PadIcon>
                        <PersonPinIcon fontSize="small" />
                      </PadIcon>
                      {majorTenant}
                    </CustomFlex>
                  </Typography>
                )}
              </div>
            </CustomColumn>
          </CustomGridItem>
          <CustomGridItem>
            {!!opportunity ? (
              <CustomColumn>
                <CustomColumn>
                  <Typography variant="h6" color="primary" align="right">
                    {millify(
                      new Energy(opportunity.energy).as(EnergyUnitType.KBTU)
                        .quantity
                    )}
                    <Typography variant="subtitle2" component="span">
                      <CustomFlex>
                        (
                        <PercentChange number={opportunity.energyDepth * 100} />
                        )
                      </CustomFlex>
                    </Typography>
                  </Typography>
                  <Typography variant="caption" align="right">
                    Est. annual yield ({displayUnit(EnergyUnitType.KBTU)})
                  </Typography>
                </CustomColumn>
                <Typography component="div" align="right" variant="body2">
                  <CustomMeta>
                    {!!eui && (
                      <span>EUI: {Math.round(new Energy(eui).quantity)}</span>
                    )}
                    <span>
                      {formatNumber(opportunity.moneyPerSquareFoot.value, {
                        style: "currency",
                        currency: "USD",
                      })}
                      /ft²
                    </span>
                    <span>
                      {new Energy(
                        opportunity.energyPerSquareFoot
                      ).quantity.toFixed(1)}{" "}
                      {displayUnit(
                        new Energy(opportunity.energyPerSquareFoot).unit
                      )}
                      /ft²
                    </span>
                    <span>
                      <CustomFlex>
                        ${millify(Math.round(opportunity.money.value))}
                        (<PercentChange number={opportunity.moneyDepth * 100} />
                        )
                      </CustomFlex>
                    </span>
                  </CustomMeta>
                </Typography>
              </CustomColumn>
            ) : (
              <CustomColumn>
                <Typography align="right" color="primary" variant="button">
                  <CustomFlex>
                    Continue to setup
                    <ArrowForwardIcon fontSize="small" />
                  </CustomFlex>
                </Typography>
              </CustomColumn>
            )}
          </CustomGridItem>
        </CustomGridContainer>
      </CustomPaper>
    </StyledAnchor>
  );
};

const ProspectingList: FC<Props> = ({ buildings }) => {
  const { spacing, breakpoints } = useTheme();
  const matches = useMediaQuery(breakpoints.up("md"));
  const cardHeight = spacing(matches ? 12 : 20);
  const rowGap = 10;

  return (
    <AutoSizer>
      {({ width, height }) => (
        <VirtualizedList
          style={{ outline: "none" }}
          rowRenderer={({ key, index, style }) => {
            return (
              <div style={style} key={key}>
                <Row building={buildings[index]} height={cardHeight} />
              </div>
            );
          }}
          rowCount={buildings.length}
          rowHeight={cardHeight + rowGap}
          height={height}
          width={width}
        />
      )}
    </AutoSizer>
  );
};

export default ProspectingList;
