import { useEffect, useState } from "react";

export const setLocalStorage = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = <T>(key: string) => {
  const item = localStorage.getItem(key);
  return item ? (JSON.parse(item) as T) : null;
};

/**
 * Syncs state to window.localStorage
 *
 * @param value - the value to store. Must be JSON.stringify compatible for best results
 */
const useLocalStorageState = <T>(key: string, startValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(
    getLocalStorage(key) || startValue
  );

  useEffect(() => {
    setStoredValue(getLocalStorage(key) || startValue);
  }, [key, startValue, setStoredValue]);

  const setValue = (valueToStore: T) => {
    setStoredValue(valueToStore);
    setLocalStorage(key, valueToStore);
  };

  return [storedValue, setValue] as const;
};

export default useLocalStorageState;
