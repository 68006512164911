import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import React, { FC, useEffect, useMemo, useState } from "react";
import { FormattedDate } from "react-intl";
import { UserModelFragment } from "../api/fragments/UserModel.generated";
import { Maybe, OrganizationRoleType } from "../api/graphql";
import DismissDialog from "../DocumentCenter/DismissDialog";
import { SetDocumentPermissionsDocument } from "../DocumentSharing/SetDocumentPermissions.generated";
import UserSearch from "../DocumentSharing/UserSearch";
import clampFileName from "../lib/clampFileName";
import pluckNodes from "../lib/pluckNodes";
import sortAlpha from "../lib/sortAlpha";
import { avatarInitials } from "../OrgDashboard/OrgUsers";
import { useSessionWithUser } from "../SessionWithUser";
import { SkeletonListItem, SkeletonTypography } from "../ui/Bones";
import Button from "../ui/Button";
import MetaData from "../ui/MetaData";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import { BuildingDocumentQueryDocument } from "./BuildingDocumentQuery.generated";

interface Props {
  documentId: string;
  buildingId: string;
  onDismiss: () => void;
}

type User = UserModelFragment & {
  role: Maybe<OrganizationRoleType>;
};

const UserListItem: FC<{
  user: User;
  onUnstage: (u: User) => void;
  isDocumentOwner?: boolean;
}> = ({ user, onUnstage, isDocumentOwner }) => {
  const { user: currentUser } = useSessionWithUser();

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>{avatarInitials(user.name)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography>
            {user.name}{" "}
            {isDocumentOwner && (
              <Chip
                component="span"
                label="Document owner"
                variant="outlined"
                size="small"
              />
            )}
          </Typography>
        }
        secondary={user.email}
      />
      {user.role === OrganizationRoleType.MEMBER &&
        user.id !== currentUser.id &&
        !isDocumentOwner && (
          <ListItemSecondaryAction>
            <Button size="small" onClick={() => onUnstage(user)}>
              Remove
            </Button>
          </ListItemSecondaryAction>
        )}
    </ListItem>
  );
};

const BuildingDocumentSharing: FC<Props> = ({
  documentId,
  onDismiss,
  buildingId,
}) => {
  const { organization } = useSessionWithUser();
  const createToast = useToastContext();
  const [stagedUsers, setStagedUsers] = useState<User[]>([]);

  const [setDocumentPermissions] = useMutation(SetDocumentPermissionsDocument);
  const { data, loading, error } = useQuery(BuildingDocumentQueryDocument, {
    variables: {
      organizationId: organization.id,
      documentId,
    },
  });

  useEffect(() => {
    if (data) {
      const { users: docUsers } = data.organizationDocument;

      setStagedUsers(docUsers);
    }
  }, [data]);

  // Diffed from the existing users on the document
  const searchableUsers = useMemo(() => {
    if (!data) return [];

    const { users: orgUsers } = data.organization;

    return pluckNodes(orgUsers).filter(
      (u) => !stagedUsers.some((du) => du.id === u.id)
    );
  }, [data, stagedUsers]);

  if (error) throw error;
  if (loading || !data)
    return (
      <DismissDialog
        fullWidth
        maxWidth="sm"
        onDismiss={onDismiss}
        confirmText="Save preferences"
        title={<PageTitle>Linked users</PageTitle>}
      >
        <SkeletonTypography fullWidth />
        <List>
          <SkeletonListItem />
          <SkeletonListItem />
          <SkeletonListItem />
        </List>
      </DismissDialog>
    );

  const onStageUser = (user: User) => {
    setStagedUsers([...stagedUsers, user]);
  };

  const unstageUser = (user: User) => {
    setStagedUsers([...stagedUsers.filter((su) => su.id !== user.id)]);
  };

  const onSave = async () => {
    try {
      await setDocumentPermissions({
        variables: {
          usersInput: {
            documentId,
            userIds: stagedUsers.map((su) => su.id),
          },
          buildingsInput: {
            documentId,
            // Must preserve the current building permission preferences on upsert
            buildingIds: [
              buildingId,
              ...pluckNodes(data.organizationDocument.buildings).map(
                (b) => b.id
              ),
            ],
          },
        },
      });

      onDismiss(); // Close the dialog when preferences saved
      createToast("Sharing preferences were saved", "success");
    } catch (err) {
      createToast(err.message || "Error saving permissions", "error");
    }
  };

  const ownerUsers = stagedUsers.filter(
    (u) => u.role === OrganizationRoleType.OWNER
  );
  const adminUsers = stagedUsers.filter(
    (u) => u.role === OrganizationRoleType.ADMIN
  );
  const memberUsers = stagedUsers.filter(
    (u) => u.role === OrganizationRoleType.MEMBER
  );

  return (
    <DismissDialog
      onDismiss={onDismiss}
      onConfirm={onSave}
      confirmText="Save preferences"
      title={
        <>
          <PageTitle>Linked users</PageTitle>
          <Typography variant="body2">
            <MetaData>
              <span>
                {clampFileName(data.organizationDocument.file.filename)}
              </span>
              <span>Created by {data.organizationDocument.createdBy.name}</span>
              <span>
                Last edited{" "}
                <FormattedDate value={data.organizationDocument.updatedAt} />
              </span>
            </MetaData>
          </Typography>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserSearch users={searchableUsers} onUserSelect={onStageUser} />
          <List>
            {[ownerUsers, adminUsers, memberUsers].map((collection) => {
              return [...collection]
                .sort((a, b) => sortAlpha(a.name, b.name))
                .map((u) => (
                  <UserListItem
                    user={u}
                    key={u.id}
                    onUnstage={unstageUser}
                    isDocumentOwner={
                      data.organizationDocument.createdBy.id === u.id
                    }
                  />
                ));
            })}
          </List>
        </Grid>
      </Grid>
    </DismissDialog>
  );
};

export default BuildingDocumentSharing;
