import React, { FC } from "react";
import createGradient from "../lib/gradient";

interface DataPoint {
  yValue: number;
  xValue: number;
}

type Domain = Readonly<[number, number]>;

interface GradientStop {
  color: string;
  yValue: number;
}

interface Props {
  id: string;
  data: DataPoint[];
  domainMin: number;
  domainMax: number;
  gradientStops: GradientStop[];
}

const calculateOffset = (n: number, domain: Domain): number =>
  (n - domain[0]) / (domain[1] - domain[0]);

const Gradient: FC<Props> = ({
  id,
  data,
  domainMin,
  domainMax,
  gradientStops,
}) => {
  const domain: Domain = [domainMin, domainMax];

  const gradient = createGradient(
    gradientStops.map((gs) => ({
      color: gs.color,
      offset: calculateOffset(gs.yValue, domain),
    }))
  );

  const xMin = Math.min(...data.map((d) => d.xValue));
  const xMax = Math.max(...data.map((d) => d.xValue));

  return (
    <defs>
      <linearGradient id={id}>
        {data.map(({ xValue, yValue }, idx) => (
          <stop
            key={`${xValue}-${idx}`}
            offset={(xValue - xMin) / (xMax - xMin)}
            stopColor={gradient(calculateOffset(yValue, domain))}
          />
        ))}
      </linearGradient>
    </defs>
  );
};

export default Gradient;
