import React, { FC } from "react";
import sortAlpha from "../lib/sortAlpha";
import { ControlledAutocomplete } from "../ui/Autocomplete";
import { User } from "./DocumentSharing";

interface Props {
  users: User[];
  onUserSelect: (v: User) => void;
}

const UserSearch: FC<Props> = ({ users, onUserSelect }) => {
  return (
    <ControlledAutocomplete
      label="Search users"
      placeholder="Search users"
      options={[...users].sort((a, b) => sortAlpha(a.name, b.name))}
      getOptionLabel={(user) => user.name}
      onChange={(v) => v && onUserSelect(v)}
    />
  );
};

export default UserSearch;
