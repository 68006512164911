import { makeStyles } from "@material-ui/core";
import React from "react";
import colors from "./colors";

const useStyles = makeStyles(() => ({
  metadata: {
    "& > *": {
      whiteSpace: "nowrap",
      color: colors.gray.main,
    },
    "& > :not(:first-child)::before": {
      content: '" • "',
    },
  },
}));

const MetaData: React.FC = ({ children }) => {
  const { metadata } = useStyles();
  return <div className={metadata}>{children}</div>;
};

export default MetaData;
