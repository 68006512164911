import {
  styled,
  Table as MUITable,
  TableHead as MUITableHead,
} from "@material-ui/core";

// unmodified table components
export { TableBody, TableCell, TableRow } from "@material-ui/core";

export const Table = styled(MUITable)(({ theme: { palette, spacing } }) => ({
  borderTop: `5px solid ${palette.primary.main}`,
  "& > tbody tr:nth-child(odd)": {
    backgroundColor: palette.grey[200],
  },
  "& > tbody td": {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
}));

export const TableHead = styled(MUITableHead)({
  "& .MuiTableCell-root": {
    fontWeight: "bold",
  },
});
