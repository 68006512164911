import {
  Box,
  CardActionArea,
  Grid,
  Icon,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import themeColor, { ThemeColor } from "../lib/themeColor";
import { CardLinkedArea } from "../ui/Card";

interface RecentItemTileProps {
  color?: ThemeColor;
  title: string;
  metadata: ReactNode;
  icon: ReactNode;
  /** If provided, the tile will link to the specified URL */
  to?: string;
  /**
   * If provided, the tile will accept an onClick handler
   *
   * overrides the `to` prop
   */
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  styledText: {
    color: (props: { color?: ThemeColor }) => themeColor(theme, props.color),
  },
  gridColor: {
    borderLeft: (props: { color?: ThemeColor }) =>
      `4px solid ${themeColor(theme, props.color)}`,
  },
  styledIcon: {
    "& > *": {
      verticalAlign: "middle",
    },
  },
}));

const RecentItemTile: FC<RecentItemTileProps> = ({
  color = "primary",
  title,
  metadata,
  icon,
  to,
  onClick,
}) => {
  const { styledText, gridColor, styledIcon } = useStyles({ color });

  const contents = (
    <ListItem>
      <Box p={1}>
        <Grid container className={gridColor}>
          <Grid item>
            <Box px={1}>
              <Icon className={styledIcon} color="disabled">
                {icon}
              </Icon>
            </Box>
          </Grid>
          <Grid item>
            <div>
              <Typography className={styledText}>
                <b>{title}</b>
              </Typography>
              {metadata}
            </div>
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  );

  if (onClick) {
    return <CardActionArea onClick={onClick}>{contents}</CardActionArea>;
  }

  if (to) {
    return <CardLinkedArea to={to}>{contents}</CardLinkedArea>;
  }

  return contents;
};

export default RecentItemTile;
