import { useQuery } from "@apollo/client";
import { Box, Chip, Grid, styled, Typography } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import React, { FC, useEffect, useMemo, useState } from "react";
import { FormattedDate } from "react-intl";
import { useHistory } from "react-router-dom";
import { DeltaMeterState, EnergySource, EnergyUnitType } from "../api/graphql";
import { utilityMeters } from "../lib/endpoints";
import formatter from "../lib/formatter";
import modelName from "../lib/modelName";
import requireMeterUpdate, {
  notEmptyDates,
  numStaleMeters,
} from "../lib/staleMeters";
import AlertChip from "../ui/AlertChip";
import ResponsiveChart from "../ui/charting/ResponsiveChart";
import Loading from "../ui/Loading";
import MetaData from "../ui/MetaData";
import { PageTitle, SectionTitle } from "../ui/Typography";
import ActivityLog from "./ActivityLog";
import aggregateReport, {
  distinctEnergySources,
  singleFuelReport,
} from "./combinedChartReducer";
import DeltaMeterChart from "./DeltaMeterChart";
import DeltaMeterChartCard from "./DeltaMeterChartCard";
import { DeltaMeterDocument } from "./DeltaMeterQuery.generated";
import DeltaMeterReadingsTable from "./DeltaMeterReadingsTable";
import FilterButtons from "./FilterButtons";
import KpiStatistic from "./KpiStatistic";
import NewDeltaMeterStatementButton from "./NewDeltaMeterStatementButton";
import NoDeltaMeter, { DraftDeltaMeter } from "./NoDeltaMeter";
import readingAdjustmentReducer from "./readingAdjustmentReducer";
import WeatherAdjustmentChart from "./WeatherAdjustmentChart";

interface Props {
  buildingId: string;
}

const GridContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridGap: theme.spacing(3),
  gridTemplateColumns: "repeat(3, 1fr)",
}));

const GridKpi = styled(Box)({
  gridColumn: "auto",
});

const DeltaMeterDashboard: FC<Props> = ({ buildingId }) => {
  const { data, loading, error, refetch } = useQuery(DeltaMeterDocument, {
    variables: { id: buildingId },
  });

  const [selectedEnergy, setSelectedEnergy] = useState<EnergySource | null>(
    null
  );
  const [metersNeedUpdate, setMetersNeedUpdate] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (data && data.building.deltaMeter) {
      const numSources = distinctEnergySources(data.building.deltaMeter.report)
        .length;
      const { report } = data.building.deltaMeter;
      const { utilityMeters } = data.building.deltaMeter.building;

      setMetersNeedUpdate(
        requireMeterUpdate(
          notEmptyDates(utilityMeters.map((um) => um.readings.lastReadOn))
        )
      );

      if (numSources > 1) {
        setSelectedEnergy(null);
      } else {
        setSelectedEnergy(distinctEnergySources(report)[0]);
      }
    }
  }, [data]);

  const chartData = useMemo(() => {
    if (!data || !data.building.deltaMeter) return null;

    if (!selectedEnergy) {
      return aggregateReport(
        data.building.deltaMeter.report,
        EnergyUnitType.KBTU
      );
    }

    return singleFuelReport(
      data.building.deltaMeter.report.filter(
        (r) => r.energySource === selectedEnergy
      ),
      EnergyUnitType.KBTU
    );
  }, [data, selectedEnergy]);

  const adjustmentData = useMemo(() => {
    if (!data || !data.building.deltaMeter) return null;

    return readingAdjustmentReducer(
      data.building.deltaMeter.readings.edges
        .map((r) => r.node)
        .filter((r) =>
          selectedEnergy ? r.energySource === selectedEnergy : true
        ),
      EnergyUnitType.KBTU
    );
  }, [data, selectedEnergy]);

  if (error) throw error;
  if (loading || !data) {
    return <Loading variant="circle" />;
  }

  if (!data.building.deltaMeter) {
    return (
      <>
        <PageTitle>DeltaMeter</PageTitle>
        <NoDeltaMeter />
      </>
    );
  }

  if (
    data.building.deltaMeter.state === DeltaMeterState.DRAFT ||
    !chartData ||
    !adjustmentData
  ) {
    return (
      <>
        <PageTitle>DeltaMeter</PageTitle>
        <DraftDeltaMeter />
      </>
    );
  }

  const {
    building: { deltaMeter },
  } = data;
  const { building, report, readings, id: deltaMeterId } = deltaMeter;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageTitle>
          DeltaMeter
          <Box component="span" pl={1}>
            <Typography component="span" color="textSecondary" variant="body2">
              ID# {deltaMeter.shortId}
            </Typography>
            <Box component="span" pl={2}>
              <NewDeltaMeterStatementButton
                deltaMeterId={deltaMeterId}
                onSuccess={refetch}
              />
            </Box>
          </Box>
        </PageTitle>
        <MetaData>
          <Typography component="span" color="textSecondary">
            Start date:{" "}
            <FormattedDate
              value={new Date(deltaMeter.startOn)}
              timeZone={building.address.timeZone}
            />
          </Typography>
          <Typography component="span" color="textSecondary">
            Weather station: {deltaMeter.weatherStation}
          </Typography>
        </MetaData>
        <MetaData>
          <Typography component="span">
            Baseline model: {modelName(deltaMeter.buildingCalibratedModel)}
          </Typography>
          {deltaMeter.buildingAdjustedModel != null && (
            <Typography component="span" color="textSecondary">
              Projected model: {modelName(deltaMeter.buildingAdjustedModel)}
            </Typography>
          )}
        </MetaData>
      </Grid>
      <Grid item xs={12}>
        <GridContainer>
          <GridKpi>
            <KpiStatistic
              statistic={formatter(deltaMeter.kpis.energyEfficiency)}
              unit="kBTU"
              title="Lifetime Efficiency Production"
            />
          </GridKpi>
          {deltaMeter.kpis.projectedEnergyEfficiency != null && (
            <GridKpi>
              <KpiStatistic
                statistic={formatter(
                  deltaMeter.kpis.energyEfficiency -
                    deltaMeter.kpis.projectedEnergyEfficiency
                )}
                unit="kBTU"
                title="Lifetime Efficiency Performance"
                percentChange={
                  deltaMeter.kpis.projectedEnergyEfficiency > 0
                    ? `${Math.round(
                        (deltaMeter.kpis.energyEfficiency /
                          deltaMeter.kpis.projectedEnergyEfficiency) *
                          100 -
                          100
                      )}%`
                    : 0
                }
              />
            </GridKpi>
          )}
          {deltaMeter.buildingAdjustedModel != null && (
            <GridKpi>
              <KpiStatistic
                statistic={Math.round(deltaMeter.kpis.meanVariance * 100)}
                unit="%"
                title="Average absolute variance"
              />
            </GridKpi>
          )}
        </GridContainer>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <SectionTitle>Metered energy efficiency</SectionTitle>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              <Box pr={1} display="inline">
                {metersNeedUpdate && (
                  <AlertChip
                    label={`${numStaleMeters(
                      notEmptyDates(
                        building.utilityMeters.map(
                          (um) => um.readings.lastReadOn
                        )
                      )
                    )} utilities require readings`}
                    onClick={() =>
                      history.push(utilityMeters({ buildingId: building.id }))
                    }
                  />
                )}
              </Box>
              {deltaMeter.state === DeltaMeterState.ACTIVE && (
                <Chip
                  size="small"
                  icon={<LockIcon fontSize="small" />}
                  label="Active"
                />
              )}
            </Box>
          </Box>
        </Box>
        <DeltaMeterChartCard>
          <ResponsiveChart ratio={20 / 7}>
            {({ width, height }) => (
              <DeltaMeterChart
                buildingId={building.id}
                data={chartData}
                adjustmentData={adjustmentData}
                width={width}
                height={height}
              />
            )}
          </ResponsiveChart>
          <Box width={1} pt={2} ml={6}>
            <FilterButtons
              onSelectCombined={() => {
                setSelectedEnergy(null);
              }}
              onSelectSingle={(sourceName) => {
                setSelectedEnergy(sourceName);
              }}
              energySources={distinctEnergySources(report)}
              selectedEnergy={selectedEnergy}
            />
          </Box>
        </DeltaMeterChartCard>
      </Grid>
      <Grid item xs={12}>
        <SectionTitle>Automated baseline adjustments</SectionTitle>
        <DeltaMeterChartCard>
          <ResponsiveChart ratio={20 / 4}>
            {({ width, height }) => (
              <WeatherAdjustmentChart
                data={chartData}
                width={width}
                height={height}
              />
            )}
          </ResponsiveChart>
        </DeltaMeterChartCard>
      </Grid>
      <Grid item xs={12}>
        <SectionTitle>Activity log</SectionTitle>
        <ActivityLog
          activities={deltaMeter.activities.edges.map((e) => e.node)}
          timeZone={deltaMeter.building.address.timeZone}
        />
      </Grid>
      {readings.edges.length > 0 && (
        <Grid item xs={12}>
          <SectionTitle>DeltaMeter reading analysis</SectionTitle>
          <DeltaMeterReadingsTable
            buildingId={deltaMeter.building.id}
            data={readings.edges.map((e) => e.node)}
            timeZone={deltaMeter.building.address.timeZone}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DeltaMeterDashboard;
