import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteBuildingCalibratedModelMutationVariables = Types.Exact<{
  input: Types.DeleteBuildingCalibratedModelInput;
}>;


export type DeleteBuildingCalibratedModelMutation = { deleteBuildingCalibratedModel: { __typename: 'DeleteBuildingCalibratedModelResponse', buildingCalibratedModel: Types.Maybe<{ __typename: 'BuildingCalibratedModel', id: string }> } };


export const DeleteBuildingCalibratedModelDocument: DocumentNode<DeleteBuildingCalibratedModelMutation, DeleteBuildingCalibratedModelMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteBuildingCalibratedModel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteBuildingCalibratedModelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteBuildingCalibratedModel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"buildingCalibratedModel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]};