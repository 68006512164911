import { LeaseType } from "../api/graphql";

const leaseTypeNames: Record<LeaseType, string> = {
  [LeaseType.SINGLE_NET_LEASE]: "Single net lease",
  [LeaseType.DOUBLE_NET_LEASE]: "Double net lease",
  [LeaseType.TRIPLE_NET_LEASE]: "Triple net lease",
  [LeaseType.BONDABLE_NET_LEASE]: "Bondable net lease",
  [LeaseType.GROSS_LEASE]: "Gross lease",
  [LeaseType.MODIFIED_GROSS_LEASE]: "Modified gross lease",
  [LeaseType.PERCENTAGE_LEASE]: "Percentage lease",
  [LeaseType.OPERATING_AGREEMENT]: "Operating agreement",
};

const leaseType = (name: LeaseType) => leaseTypeNames[name] || name;

export default leaseType;

const normalizeName = (name: string): string => name.toLowerCase();

const leaseTypesByName: Record<string, LeaseType> = Object.entries(
  leaseTypeNames
).reduce((memo, [k, v]) => ({ ...memo, [normalizeName(v)]: k }), {});

export const leaseTypeByName = (name: string): LeaseType | undefined =>
  leaseTypesByName[normalizeName(name)];
