import { EnergyUnitType } from "../api/graphql";

const wattHoursIn = {
  BTU: 1 / 3.413,
  CF: 301.58265,
  LBS: 349.941,
  GJ: 3.6e-6,
  THERM: 29307,
} as const;

const volumetricUnits: EnergyUnitType[] = [
  EnergyUnitType.CF, // cubic foot
  EnergyUnitType.CCF, // 100 cubic feet
  EnergyUnitType.KCF, // 1000 cubic feet
  EnergyUnitType.M3, // cubic meter
  EnergyUnitType.GAL, // gallons
];

const cubicFeetInCubicMeters = 35.3147;

// coefficients to yield the watts in a unit
const whCoefficients: { [key in EnergyUnitType]: number } = {
  WH: 1,
  KWH: 1000,
  MWH: 1000000,
  THERM: wattHoursIn.THERM,
  DTH: 10 * wattHoursIn.THERM,
  BTU: wattHoursIn.BTU,
  KBTU: 1000 * wattHoursIn.BTU,
  MMBTU: 1000000 * wattHoursIn.BTU,
  CF: wattHoursIn.CF,
  HCF: 100 * wattHoursIn.CF,
  CCF: 100 * wattHoursIn.CF,
  KCF: 1000 * wattHoursIn.CF,
  MCF: 1000 * wattHoursIn.CF,
  M3: wattHoursIn.CF * cubicFeetInCubicMeters,
  GAL: 91452 * wattHoursIn.BTU,
  LBS: wattHoursIn.LBS,
  KLBS: 1000 * wattHoursIn.LBS,
  MLBS: 1000 * wattHoursIn.LBS,
  GJ: wattHoursIn.GJ,
};

export default class Energy {
  public readonly quantity: number;
  public readonly unit: EnergyUnitType;

  constructor(energy: { quantity: number; unit: EnergyUnitType }) {
    this.quantity = energy.quantity;
    this.unit = energy.unit;
  }

  get kwh(): number {
    return this.wh / 1000;
  }

  as(unit: EnergyUnitType): Energy {
    // We cannot accurately convert volumetric units on the fronted yet
    if (volumetricUnits.includes(unit))
      throw new Error("Cannot convert volumetric units");
    return new Energy({ quantity: this.wh / whCoefficients[unit], unit });
  }

  get wh(): number {
    // We cannot assume that we will be able to get accurate watt-hours from a volumetric unit
    if (volumetricUnits.includes(this.unit))
      throw new Error("Cannot convert volumetric units");
    return this.quantity * whCoefficients[this.unit];
  }

  /**
   * Adds together two Energies and returns their sum, as KBTU
   */
  add(energy: Energy): Energy {
    const currentEnergy = new Energy(this).as(EnergyUnitType.KBTU);
    const incomingEnergy = new Energy(energy).as(EnergyUnitType.KBTU);

    return new Energy({
      quantity: currentEnergy.quantity + incomingEnergy.quantity,
      unit: EnergyUnitType.KBTU,
    });
  }

  /**
   * Subtracts two Energies and returns as KBTU
   */
  subtract(energy: Energy): Energy {
    return this.add(
      new Energy({ quantity: -energy.quantity, unit: energy.unit })
    );
  }

  /**
   * Multiplies an Energy by a number and returns in its native unit
   */
  times(multiplier: number): Energy {
    return new Energy({
      quantity: this.quantity * multiplier,
      unit: this.unit,
    });
  }

  /**
   * Divides an Energy by a number and returns in its native unit
   */
  divide(multiplier: number): Energy {
    return this.times(1 / multiplier);
  }

  /**
   * Sums together a list of energies and returns in KBTU
   */
  public static sumEnergies(energies: Energy[]) {
    return energies.reduce(
      (acc, c) => acc.add(c),
      new Energy({ quantity: 0, unit: EnergyUnitType.KBTU })
    );
  }
}
