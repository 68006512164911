import { Chip, ChipProps, styled } from "@material-ui/core";
import React from "react";

type Props = Omit<ChipProps, "size" | "color">;

const ChipStyle = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.error.dark}`,
  color: theme.palette.error.dark,
  "&:hover": {
    backgroundColor: ({ onClick }: Props) =>
      onClick ? theme.palette.error.light : theme.palette.common.white,
    color: ({ onClick }: Props) =>
      onClick ? theme.palette.common.white : theme.palette.error.dark,
  },
}));

const AlertChip: React.FC<Props> = ({ ...props }) => {
  return <ChipStyle size="small" {...props} />;
};

export default AlertChip;
