import { EnergySource, EnergyUnitType } from "../../api/graphql";
import { Rates } from "../../BuildingInsights/BuildingInsightsReport";
import { endUseName } from "../endUse";
import Energy from "../Energy";
import { energySourceName } from "../energySource";
import { EndUse as AnnualizedEndUse, EndUseEnergy } from "../types/Reports";

export interface RowData {
  END_USE?: string;
  ENERGY_SOURCE: string;
  BASELINE: number;
  PROJECTED: number;
  DELTA_END_USE: number;
  EE_POTENTIAL: number;
  DELTA_EE_POTENTIAL: number;
  EE_VALUE: number;
  DELTA_EE_VALUE: number;
}

function calcPercentChange(before: number, after: number): number {
  return ((before - after) / before) * 100;
}

export function calculateEndUseSum(
  model: AnnualizedEndUse[],
  converter: (energy: EndUseEnergy, energySource: EnergySource) => number
) {
  return model
    .map(({ energy, energySource }) => converter(energy, energySource))
    .reduce((acc, c) => acc + c, 0);
}

export const toEnergy = (energy: EndUseEnergy) =>
  new Energy(energy).as(EnergyUnitType.KBTU).quantity;

// Closes over rates and returns an appropriate converter callback
export const toCurrency = (rates: Rates) => {
  return (energy: EndUseEnergy, energySource: EnergySource) => {
    const { cost, unit } = rates.find((r) => r.energySource === energySource)!;

    return new Energy(energy).as(unit).quantity * cost.value;
  };
};

export function formatEndUseTableReport(
  baseModel: AnnualizedEndUse[],
  comparisonModel: AnnualizedEndUse[],
  rates: Rates
): RowData[] {
  const targetUnit = EnergyUnitType.KBTU;

  const totalDerivedBaselineEnergy = calculateEndUseSum(baseModel, toEnergy);
  const totalChildBaselineEnergy = calculateEndUseSum(
    comparisonModel,
    toEnergy
  );
  const totalDerivedBaselineCurrency = calculateEndUseSum(
    baseModel,
    toCurrency(rates)
  );
  const totalChildBaselineCurrency = calculateEndUseSum(
    comparisonModel,
    toCurrency(rates)
  );

  return (
    baseModel
      .map((endUse, idx) => {
        const derivedEndUse = endUse;
        const childEndUse = comparisonModel[idx];

        if (derivedEndUse.endUse !== childEndUse.endUse) {
          throw new Error("Modeled endUses do not match");
        }

        const { cost, unit } = rates.find(
          (r) => r.energySource === derivedEndUse.energySource
        )!;

        const derivedBaseline = new Energy(derivedEndUse.energy).as(targetUnit)
          .quantity;

        const childBaseline = new Energy(childEndUse.energy).as(targetUnit)
          .quantity;

        const derivedBaselineCurrency =
          new Energy(derivedEndUse.energy).as(unit).quantity * cost.value;

        const childBaselineCurrency =
          new Energy(childEndUse.energy).as(unit).quantity * cost.value;

        const deltaEEPotential =
          ((derivedBaseline - childBaseline) /
            (totalDerivedBaselineEnergy - totalChildBaselineEnergy)) *
          100;

        const deltaEEValue =
          ((derivedBaselineCurrency - childBaselineCurrency) /
            (totalDerivedBaselineCurrency - totalChildBaselineCurrency)) *
          100;

        return {
          END_USE: endUseName(derivedEndUse.endUse),
          ENERGY_SOURCE: energySourceName(derivedEndUse.energySource),
          BASELINE: derivedBaseline,
          PROJECTED: childBaseline,
          DELTA_END_USE: calcPercentChange(derivedBaseline, childBaseline),
          EE_POTENTIAL: derivedBaseline - childBaseline,
          DELTA_EE_POTENTIAL: deltaEEPotential,
          EE_VALUE: derivedBaselineCurrency - childBaselineCurrency,
          DELTA_EE_VALUE: deltaEEValue,
        };
      })
      // Remove endUses that have no values for baseline and projected
      .filter((data) => data.BASELINE > 0 || data.PROJECTED > 0)
  );
}

export function formatEnergySourceTableReport(
  baseModel: AnnualizedEndUse[],
  comparisonModel: AnnualizedEndUse[],
  rates: Rates
): RowData[] {
  const totalDerivedBaselineEnergy = calculateEndUseSum(baseModel, toEnergy);
  const totalChildBaselineEnergy = calculateEndUseSum(
    comparisonModel,
    toEnergy
  );
  const totalDerivedBaselineCurrency = calculateEndUseSum(
    baseModel,
    toCurrency(rates)
  );
  const totalChildBaselineCurrency = calculateEndUseSum(
    comparisonModel,
    toCurrency(rates)
  );

  const sources = Object.keys(EnergySource) as (keyof typeof EnergySource)[];

  return sources
    .map((source) => {
      const mySource: EnergySource =
        EnergySource[source as keyof typeof EnergySource];

      const derivedEndUses = baseModel.filter(
        (eu) => eu.energySource === source
      );
      const childEndUses = comparisonModel.filter(
        (eu) => eu.energySource === source
      );

      const derivedEnergy = calculateEndUseSum(derivedEndUses, toEnergy);
      const childEnergy = calculateEndUseSum(childEndUses, toEnergy);

      const derivedCurrency = calculateEndUseSum(
        derivedEndUses,
        toCurrency(rates)
      );
      const childCurrency = calculateEndUseSum(childEndUses, toCurrency(rates));

      const deltaEEPotential =
        ((derivedEnergy - childEnergy) /
          (totalDerivedBaselineEnergy - totalChildBaselineEnergy)) *
        100;

      const deltaEEValue =
        ((derivedCurrency - childCurrency) /
          (totalDerivedBaselineCurrency - totalChildBaselineCurrency)) *
        100;

      return {
        ENERGY_SOURCE: energySourceName(mySource),
        BASELINE: derivedEnergy,
        PROJECTED: childEnergy,
        DELTA_END_USE: calcPercentChange(derivedEnergy, childEnergy),
        EE_POTENTIAL: derivedEnergy - childEnergy,
        DELTA_EE_POTENTIAL: deltaEEPotential,
        EE_VALUE: derivedCurrency - childCurrency,
        DELTA_EE_VALUE: deltaEEValue,
      };
    })
    .filter((data) => data.BASELINE > 0 || data.PROJECTED > 0);
}
