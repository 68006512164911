import { useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { BuildingAccessType, BuildingUseType, LeaseType } from "../api/graphql";
import { BuildingMetadataQueryQuery } from "../Building/BuildingMetadataQuery.generated";
import isReadOnly from "../lib/isReadOnly";
import prettyLeaseType from "../lib/LeaseType";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import regions from "../lib/usaStates";
import {
  nameError,
  presenceError,
  textFieldLimits,
  zipCodeError,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import Card, { CardActions, CardContent } from "../ui/Card";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";
import { SelectFieldController, SelectItem } from "../ui/SelectField";
import { TextField } from "../ui/TextField";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import { UpdateBuildingDocument } from "./UpdateBuilding.generated";

type Fields = {
  name: string;
  externalId: string | null;
  buildingUseType: BuildingUseType;
  locality: string;
  region: string;
  streetAddress: string;
  postalCode: string;
  squareFeet: number;
  yearBuilt: number | null;
  leaseType: LeaseType | null;
  majorTenant: string | null;
  access: BuildingAccessType | null;
};

type Props = {
  building: BuildingMetadataQueryQuery["building"];
  hasModels: boolean;
};

const hasModelValidations = ({ name, streetAddress }: Fields) => ({
  name: nameError(name),
  streetAddress: nameError(streetAddress),
});

const validations = ({
  name,
  buildingUseType,
  locality,
  region,
  streetAddress,
  postalCode,
  squareFeet,
}: Fields) => ({
  name: nameError(name),
  buildingUseType: presenceError(buildingUseType),
  locality: nameError(locality),
  region: presenceError(region),
  streetAddress: nameError(streetAddress),
  postalCode: presenceError(postalCode) || zipCodeError(postalCode),
  squareFeet: presenceError(squareFeet),
});

const BuildingEdit: React.FC<Props> = ({ hasModels, building }) => {
  const [update, { error: updateError }] = useMutation(UpdateBuildingDocument);
  const { isSuperuser } = useSessionWithUser();
  const createToast = useToastContext();

  const {
    id,
    name,
    externalId,
    buildingUseType,
    address,
    squareFeet,
    yearBuilt,
    leaseType,
    majorTenant,
    access,
  } = building;
  const { locality, region, streetAddress, postalCode } = address;

  const {
    handleSubmit,
    errors,
    register,
    control,
    formState: { isSubmitting, isDirty },
  } = useForm<Fields>({
    defaultValues: {
      name,
      externalId,
      buildingUseType,
      squareFeet,
      streetAddress,
      locality,
      region,
      postalCode,
      yearBuilt,
      leaseType,
      majorTenant,
    },
    resolver: validatorsResolver(hasModels ? hasModelValidations : validations),
  });

  const onSubmit = async ({
    name,
    externalId,
    buildingUseType,
    squareFeet,
    streetAddress,
    locality,
    region,
    postalCode,
    majorTenant,
    leaseType,
    yearBuilt,
  }: Fields) => {
    try {
      await update({
        variables: {
          input: {
            buildingId: id,
            name,
            externalId: isSuperuser ? externalId : undefined,
            buildingUseType,
            streetAddress,
            locality,
            region,
            postalCode,
            squareFeet: squareFeet,
            majorTenant: majorTenant || null,
            leaseType: leaseType || null,
            yearBuilt: yearBuilt || null,
          },
        },
      });

      createToast("Building was updated", "success");
    } catch (err) {
      createToast("Building was not updated", "error");
    }
  };

  return (
    <Box>
      <Box pb={1}>
        <PageTitle>Building fields</PageTitle>
      </Box>
      <Card>
        <CardContent>
          {updateError && (
            <FormHelperText error>{updateError.message}</FormHelperText>
          )}
          <Gaps spacing={4}>
            <FormSection label="Building info">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={isReadOnly(access)}
                    required
                    size="small"
                    name="name"
                    inputRef={register}
                    type="text"
                    id="name"
                    label="Building name"
                    error={errors.name?.message}
                    InputProps={{
                      inputProps: {
                        maxLength: textFieldLimits.name.max,
                        minLength: textFieldLimits.name.min,
                      },
                    }}
                  />
                </Grid>
                {isSuperuser && (
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      name="externalId"
                      inputRef={register}
                      type="text"
                      label="External Id"
                      error={errors.externalId?.message}
                      InputProps={{
                        inputProps: {
                          maxLength: textFieldLimits.name.max,
                          minLength: textFieldLimits.name.min,
                        },
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <SelectFieldController
                    variant="outlined"
                    disabled={isReadOnly(access)}
                    locked={hasModels}
                    lockExplanation="A building model depends on this field."
                    name="buildingUseType"
                    label="Primary use"
                    control={control}
                    error={errors.buildingUseType?.message}
                  >
                    {Object.values(BuildingUseType).map((v, idx) => (
                      <SelectItem key={`buildingUseType-${idx}`} value={v}>
                        {primaryFunctionTitle(v)}
                      </SelectItem>
                    ))}
                  </SelectFieldController>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={isReadOnly(access)}
                    locked={hasModels}
                    lockExplanation="A building model depends on this field."
                    required
                    size="small"
                    name="squareFeet"
                    inputRef={register({ valueAsNumber: true })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ft²</InputAdornment>
                      ),
                    }}
                    type="number"
                    id="squareFeet"
                    label="Square footage"
                    error={errors.squareFeet?.message}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection label="Building location">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isReadOnly(access)}
                    size="small"
                    name="streetAddress"
                    inputRef={register}
                    type="text"
                    id="streetAddress"
                    label="Street address"
                    error={errors.streetAddress?.message}
                    InputProps={{
                      inputProps: {
                        maxLength: textFieldLimits.name.max,
                        minLength: textFieldLimits.name.min,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={isReadOnly(access)}
                    required
                    size="small"
                    name="locality"
                    inputRef={register}
                    type="text"
                    id="locality"
                    label="City"
                    error={errors.locality?.message}
                    InputProps={{
                      inputProps: {
                        maxLength: textFieldLimits.name.max,
                        minLength: textFieldLimits.name.min,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SelectFieldController
                    variant="outlined"
                    disabled={isReadOnly(access)}
                    name="region"
                    label="State"
                    control={control}
                    error={errors.region?.message}
                  >
                    {regions.map((r, idx) => (
                      <SelectItem key={`region-${idx}`} value={r.abbreviation}>
                        {r.name}
                      </SelectItem>
                    ))}
                  </SelectFieldController>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    disabled={hasModels || isReadOnly(access)}
                    locked={hasModels}
                    lockExplanation="A building model depends on this field."
                    required
                    size="small"
                    name="postalCode"
                    inputRef={register}
                    type="text"
                    id="postalCode"
                    label="Zip"
                    error={errors.postalCode?.message}
                    InputProps={{
                      inputProps: {
                        maxLength: textFieldLimits.name.max,
                        minLength: textFieldLimits.name.min,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection label="Additional data">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    disabled={isReadOnly(access)}
                    size="small"
                    name="yearBuilt"
                    inputRef={register({ valueAsNumber: true })}
                    type="number"
                    id="yearBuilt"
                    label="Year built"
                    error={errors.yearBuilt?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    disabled={isReadOnly(access)}
                    size="small"
                    name="majorTenant"
                    inputRef={register}
                    type="text"
                    id="majorTenant"
                    label="Major tenant"
                    error={errors.majorTenant?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SelectFieldController
                    disabled={isReadOnly(access)}
                    name="leaseType"
                    label="Lease type"
                    control={control}
                    error={errors.leaseType?.message}
                    variant="outlined"
                  >
                    <SelectItem value={undefined}>None</SelectItem>
                    {Object.values(LeaseType).map((lt, idx) => (
                      <SelectItem key={`leaseType-${idx}`} value={lt}>
                        {prettyLeaseType(lt)}
                      </SelectItem>
                    ))}
                  </SelectFieldController>
                </Grid>
              </Grid>
            </FormSection>
          </Gaps>
        </CardContent>
        <CardActions>
          <Box width={1} display="flex" justifyContent="flex-end">
            {!isReadOnly(access) && (
              <Button
                disabled={isSubmitting || !isDirty}
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? <CircularProgress size={20} /> : "Save changes"}
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default BuildingEdit;
