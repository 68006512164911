import { CurrentUserModelFragment } from "../api/fragments/CurrentUserModel.generated";
import { OrganizationRoleType } from "../api/graphql";

/** checks that a user has at least Admin privileges in org */
export const isOrganizationAdmin = (
  user: CurrentUserModelFragment | null,
  organizationId: string
): boolean => {
  if (!user) {
    return false;
  }

  const permission = user.organizationPermissions.find(
    (p) => p.organization.id === organizationId
  );
  return (
    user.superuser ||
    (!!permission && roleHasOrgAdminPermissions(permission.role))
  );
};

/** checks that a role has at least Admin privileges */
const roleHasOrgAdminPermissions = (role: OrganizationRoleType): boolean => {
  return (
    role === OrganizationRoleType.ADMIN || role === OrganizationRoleType.OWNER
  );
};
