import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import AlertIcon from "@material-ui/icons/EmojiObjects";
import { GraphQLError } from "graphql";
import React from "react";
import { useForm } from "react-hook-form";
import { BuildingUseType } from "../api/graphql";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import regions from "../lib/usaStates";
import {
  nameError,
  presenceError,
  stringNumberError,
  textFieldLimits,
  zipCodeError,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";
import { SelectFieldController, SelectItem } from "../ui/SelectField";
import { TextField } from "../ui/TextField";

interface Fields {
  buildingName: string;
  primaryUse: BuildingUseType;
  city: string;
  region: string;
  streetAddress: string;
  postalCode: string;
  squareFeet: string;
}

interface Props {
  onCancel: () => void;
  onSubmit: (values: Fields) => Promise<void>;
  error?: GraphQLError;
}

const defaultValues: Fields = {
  buildingName: "",
  primaryUse: BuildingUseType.OTHER,
  city: "",
  region: "",
  streetAddress: "",
  postalCode: "",
  squareFeet: "",
};

const validations = ({
  buildingName,
  primaryUse,
  city,
  region,
  streetAddress,
  postalCode,
  squareFeet,
}: Fields) => ({
  buildingName: nameError(buildingName),
  primaryUse: presenceError(primaryUse),
  city: nameError(city),
  region: presenceError(region),
  streetAddress: nameError(streetAddress),
  postalCode: presenceError(postalCode) || zipCodeError(postalCode),
  squareFeet: presenceError(squareFeet) || stringNumberError(squareFeet),
});

const NewBuildingDialog: React.FC<Props> = ({ onCancel, onSubmit, error }) => {
  const {
    handleSubmit,
    errors,
    register,
    control,
    formState: { isSubmitting },
  } = useForm<Fields>({
    defaultValues,
    resolver: validatorsResolver(validations),
  });

  return (
    <FormDialog
      title="Add a building"
      error={error}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitName="Add"
      isSubmitting={isSubmitting}
      maxWidth="md"
    >
      <Gaps spacing={2}>
        <FormSection label="Building info">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                name="buildingName"
                inputRef={register}
                type="text"
                id="buildingName"
                label="Building name"
                error={errors.buildingName?.message}
                InputProps={{
                  inputProps: {
                    maxLength: textFieldLimits.name.max,
                    minLength: textFieldLimits.name.min,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectFieldController
                variant="outlined"
                name="primaryUse"
                label="Primary use"
                control={control}
                error={errors.primaryUse?.message}
              >
                {Object.values(BuildingUseType).map((v, idx) => (
                  <SelectItem key={`primaryUse-${idx}`} value={v}>
                    {primaryFunctionTitle(v)}
                  </SelectItem>
                ))}
              </SelectFieldController>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                size="small"
                name="squareFeet"
                inputRef={register}
                type="text"
                id="squareFeet"
                label="Square footage"
                error={errors.squareFeet?.message}
                InputProps={{
                  inputProps: {
                    maxLength: textFieldLimits.name.max,
                    minLength: textFieldLimits.name.min,
                  },
                  endAdornment: (
                    <InputAdornment position="end">ft²</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FormSection>
        <Divider />
        <FormSection label="Building location">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                name="streetAddress"
                inputRef={register}
                type="text"
                id="streetAddress"
                label="Street address"
                error={errors.streetAddress?.message}
                InputProps={{
                  inputProps: {
                    maxLength: textFieldLimits.name.max,
                    minLength: textFieldLimits.name.min,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                size="small"
                name="city"
                inputRef={register}
                type="text"
                id="city"
                label="City"
                error={errors.city?.message}
                InputProps={{
                  inputProps: {
                    maxLength: textFieldLimits.name.max,
                    minLength: textFieldLimits.name.min,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectFieldController
                variant="outlined"
                name="region"
                label="State"
                control={control}
                error={errors.region?.message}
              >
                {regions.map((r, idx) => (
                  <SelectItem key={`primaryUse-${idx}`} value={r.abbreviation}>
                    {r.name}
                  </SelectItem>
                ))}
              </SelectFieldController>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                size="small"
                name="postalCode"
                inputRef={register}
                type="text"
                id="postalCode"
                label="Zip"
                error={errors.postalCode?.message}
                InputProps={{
                  inputProps: {
                    maxLength: textFieldLimits.name.max,
                    minLength: textFieldLimits.name.min,
                  },
                }}
              />
            </Grid>
          </Grid>
        </FormSection>
        <Box display="flex" pt={3}>
          <AlertIcon color="primary" />
          <Typography color="textSecondary">
            <em>
              Quick tip: Too much data to type? Download our{" "}
              <a href={process.env.REACT_APP_BUILDING_IMPORT_TEMPLATE_URL}>
                <b>data template</b>
              </a>{" "}
              and email it to support@energyrm.com
            </em>
          </Typography>
        </Box>
      </Gaps>
    </FormDialog>
  );
};

export default NewBuildingDialog;
