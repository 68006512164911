import * as Types from '../api/graphql';

import { DeltaMeterModelFragmentFragment } from '../api/fragments/DeltaMeterModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { DeltaMeterModelFragmentFragmentDoc } from '../api/fragments/DeltaMeterModel.generated';
export type DeltaMetersQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID'];
}>;


export type DeltaMetersQuery = { organization: { __typename: 'Organization', id: string, deltaMeters: { __typename: 'DeltaMeterConnection', edges: Array<{ __typename: 'DeltaMeterEdge', node: (
          { __typename: 'DeltaMeter' }
          & DeltaMeterModelFragmentFragment
        ) }> } } };


export const DeltaMetersDocument: DocumentNode<DeltaMetersQuery, DeltaMetersQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"deltaMeters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"deltaMeters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DeltaMeterModelFragment"}}]}}]}}]}}]}}]}},...DeltaMeterModelFragmentFragmentDoc.definitions]};