import * as Types from '../api/graphql';

import { OrganizationModelFragment } from '../api/fragments/OrganizationModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { OrganizationModelFragmentDoc } from '../api/fragments/OrganizationModel.generated';
export type CreateOrganizationMutationVariables = Types.Exact<{
  input: Types.CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { createOrganization: { __typename: 'CreateOrganizationResponse', organization: Types.Maybe<(
      { __typename: 'Organization' }
      & OrganizationModelFragment
    )> } };


export const CreateOrganizationDocument: DocumentNode<CreateOrganizationMutation, CreateOrganizationMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateOrganizationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOrganization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationModel"}}]}}]}}]}},...OrganizationModelFragmentDoc.definitions]};