import { CardHeader, styled, Typography } from "@material-ui/core";
import React from "react";
import { FormattedDate } from "react-intl";
import BuildingModelStatus from "../BuildingModelStatus";
import { buildingCalibratedModel } from "../lib/endpoints";
import modelDescription from "../lib/modelDescription";
import modelName from "../lib/modelName";
import modelUserName from "../lib/modelUserName";
import pluralize from "../lib/pluralize";
import { CardActions, CardLinkedArea } from "../ui/Card";
import ClampTextLines from "../ui/ClampTextLines";
import DefaultModel from "../ui/DefaultModel";
import MetaData from "../ui/MetaData";
import StripeCard from "../ui/StripeCard";
import { SectionTitle } from "../ui/Typography";
import { ReferencePeriodModelsQuery } from "./CalibratedModelsReferenceQuery.generated";

type CalibratedModel = ReferencePeriodModelsQuery["building"]["referencePeriods"][number]["calibratedModels"][number];

export interface Props {
  model: CalibratedModel;
}

const StyledCardActions = styled(CardActions)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

const CalibratedModelCard: React.FC<Props> = ({ model }) => {
  const {
    fit,
    createdAt,
    id: calibratedModelId,
    building: { id: buildingId },
  } = model;
  const numAdjustments = model.adjustedModels.length;

  return (
    <StripeCard orientation="left">
      <CardLinkedArea
        to={buildingCalibratedModel({
          buildingId,
          calibratedModelId,
        })}
      >
        <CardHeader
          title={
            <SectionTitle>
              {modelName(model)} <BuildingModelStatus model={{ fit }} />
            </SectionTitle>
          }
          subheader={
            <ClampTextLines variant="body1">
              {modelDescription(model)}
            </ClampTextLines>
          }
        />
        <StyledCardActions>
          <MetaData>
            {model.default && (
              <span>
                <DefaultModel model={model} />
              </span>
            )}
            <Typography variant="caption" color="textSecondary">
              Created: <FormattedDate value={new Date(createdAt)} /> by{" "}
              {modelUserName(model.user)}
            </Typography>
          </MetaData>
          <MetaData>
            {numAdjustments > 0 && (
              <Typography variant="caption" color="textSecondary">
                {pluralize(`${numAdjustments} Scenario`, numAdjustments)}
              </Typography>
            )}
          </MetaData>
        </StyledCardActions>
      </CardLinkedArea>
    </StripeCard>
  );
};

export default CalibratedModelCard;
