import React from "react";
import useDeleteEntity from "../lib/useDeleteEntity";
import ConfirmationDialog from "../ui/ConfirmationDialog";
import { useToastContext } from "../ui/ToastProvider";
import { DeactivateOrganizationPermissionDocument } from "./DeactivateOrganizationPermission.generated";

type Props = {
  name: string;
  organizationId: string;
  userId: string;
  onClose: () => Promise<void>;
};

const DeactivateUserConfirmation: React.FC<Props> = ({
  name,
  organizationId,
  userId,
  onClose,
}) => {
  const [deactivateUser] = useDeleteEntity(
    DeactivateOrganizationPermissionDocument
  );
  const createToast = useToastContext();

  const doDeactivate = async () => {
    try {
      await deactivateUser(
        { __typename: "User", id: userId },
        { input: { organizationId, userId } }
      );
      createToast("User has been deactivated.", "success");
    } catch (e) {
      createToast(e.message, "error");
    }

    await onClose();
  };

  return (
    <ConfirmationDialog
      header="Deactivate User?"
      description={`Deactivates user ${name} on this organization. The user will lose all access and must be reinvited to join again.`}
      onConfirm={doDeactivate}
      onClose={onClose}
    />
  );
};

export default DeactivateUserConfirmation;
