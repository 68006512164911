import { Box } from "@material-ui/core";
import { AxisBottom, AxisLeft, AxisRight, TickFormatter } from "@visx/axis";
import { curveMonotoneX } from "@visx/curve";
import { GlyphCircle } from "@visx/glyph";
import { Group } from "@visx/group";
import { Legend, LegendItem, LegendLabel } from "@visx/legend";
import { PatternLines } from "@visx/pattern";
import { scaleLinear, scaleOrdinal, scaleTime } from "@visx/scale";
import { AreaClosed, BarRounded, Circle, Line, LinePath } from "@visx/shape";
import { Accessor } from "@visx/shape/lib/types";
import { NumberValue } from "d3-scale";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import formatter from "../lib/formatter";
import minZero from "../lib/minZero";
import Tip, { TooltipLine } from "../ui/charting/ChartTooltip";
import useChartTooltip from "../ui/charting/useChartTooltip";
import { ReportPeriod } from "./DeltaMeterChart";
import colors from "./deltameterColors";

export interface Props {
  data: ReportPeriod[];
  width: number;
  height: number;
}

type NumAccessor = Accessor<ReportPeriod, number>;

// Legend size
const legendShapeSize = 16;

// Date accessor
const dateAccessor: NumAccessor = (d) => new Date(d.interval.end).valueOf();

// Temperature accessors
const typicalAirTempAccessor: NumAccessor = (d) => d.forecast.airTemp;
const actualTempAccessor: NumAccessor = (d) => d.reading?.airTemp || 0;

// Energy accessor
const deltaBaselineAccessor: NumAccessor = (d) => {
  if (!!d.reading) {
    return d.forecast.baseline - d.reading.baseline;
  }
  return 0;
};

// Axes label config
const tickFormatter: TickFormatter<NumberValue> = (v) =>
  formatter(typeof v === "number" ? v : v.valueOf());

// space required for ticks and axis labels
const margin = {
  top: 5,
  right: 55,
  bottom: 55,
  left: 55,
};

// tooltip data display
const formatTooltipData = (tooltipData: ReportPeriod): TooltipLine[] => {
  const tipLines = [
    {
      label: "Typical temp",
      value: `${tooltipData.forecast.airTemp.toFixed(1)}°F`,
      color: colors.blue.light,
    },
  ];

  if (tooltipData.reading) {
    tipLines.push({
      label: "Actual temp",
      value: `${tooltipData.reading.airTemp.toFixed(1)}°F`,
      color: colors.gray.dark,
    });
    tipLines.push({
      label: "Baseline change",
      value: `${Math.round(deltaBaselineAccessor(tooltipData))} kBTU`,
      color: colors.blue.main,
    });
  }

  return tipLines;
};

const WeatherAdjustmentChart: React.FC<Props> = ({ data, width, height }) => {
  // Splitting into performance and forecast
  // Find the first index of forecast-only data
  const forecastStart = data.findIndex((d) => !d.reading);

  // Capture the performance period
  const performanceData = data.slice(0, forecastStart);

  // Capture the forecast period, including the end of the performance period
  const forecastData = data.slice(forecastStart === 0 ? 0 : forecastStart - 1);

  // Bounds
  const xMax = minZero(width - margin.left - margin.right);
  const yMax = minZero(height - margin.top - margin.bottom);

  // Scales
  const xScale = scaleTime<number>({
    range: [0, xMax],
    domain: [
      Math.min(...data.map(dateAccessor)),
      Math.max(...data.map(dateAccessor)),
    ],
  });

  const yScaleTemperature = scaleLinear<number>({
    range: [yMax, 0],
    domain: [
      Math.min(
        ...data.map((el) =>
          el.reading
            ? Math.min(el.reading.airTemp, el.forecast.airTemp)
            : el.forecast.airTemp
        )
      ),
      Math.max(
        ...data.map((el) =>
          el.reading
            ? Math.max(el.reading.airTemp, el.forecast.airTemp)
            : el.forecast.airTemp
        )
      ),
    ],
  });

  // Capture the real domain before padding it to center 0
  const energyDomain = [
    Math.min(...data.map(deltaBaselineAccessor)),
    Math.max(...data.map(deltaBaselineAccessor)),
  ];

  // Find largest endpoint and increase by 15% to pad
  const paddedEndpoint = Math.max(
    ...energyDomain.map((n) => Math.abs(n) * 1.15)
  );

  const yScaleEnergy = scaleLinear<number>({
    range: [yMax, 0],
    domain: [-paddedEndpoint, paddedEndpoint],
  });

  // Legend Scale
  const legendText = {
    DELTA_BASELINE: "Baseline change",
    TYPICAL: "Typical Temperature",
    ACTUAL_TEMP: "Actual temperature",
  };

  const legendShapeScale = scaleOrdinal<string, ReactElement>({
    domain: [
      legendText.DELTA_BASELINE,
      legendText.TYPICAL,
      legendText.ACTUAL_TEMP,
    ],
    range: [
      <Circle
        cx={legendShapeSize / 2}
        cy={legendShapeSize / 2}
        r={legendShapeSize / 2}
        fill={colors.blue.main}
      />,
      <Line
        from={{ x: 0, y: legendShapeSize / 2 }}
        to={{ x: legendShapeSize, y: legendShapeSize / 2 }}
        strokeWidth={3}
        stroke={colors.blue.light}
        pointerEvents="none"
        strokeDasharray="4,3"
      />,
      <Circle
        cx={legendShapeSize / 2}
        cy={legendShapeSize / 2}
        r={legendShapeSize / 2}
        fill={colors.gray.light}
      />,
    ],
  });

  // Tooltips
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    hideTooltip,
    handleTooltip,
  } = useChartTooltip({
    xScale,
    offset: margin.left,
    data,
    xAccessor: dateAccessor,
  });

  // Date formatter
  const { formatDate } = useIntl();

  return (
    <div style={{ width, height }}>
      <Box display="flex" justifyContent="center">
        <Legend scale={legendShapeScale}>
          {(labels) => (
            <Box display="flex">
              {labels.map((label, idx) => {
                // Conditionally skip rendering these if no performance data
                if (
                  label.datum === legendText.ACTUAL_TEMP ||
                  label.datum === legendText.DELTA_BASELINE
                ) {
                  if (performanceData.length === 0) {
                    return null;
                  }
                }

                const shape = legendShapeScale(label.datum);

                return (
                  <Box pr={2} key={`dm-legend-${idx}`}>
                    <LegendItem flexDirection="row" alignItems="center">
                      <Box pr={1}>
                        <svg width={legendShapeSize} height={legendShapeSize}>
                          {shape}
                        </svg>
                      </Box>
                      <LegendLabel>{label.text}</LegendLabel>
                    </LegendItem>
                  </Box>
                );
              })}
            </Box>
          )}
        </Legend>
      </Box>
      {tooltipData && (
        <Tip
          tooltipLines={formatTooltipData(tooltipData)}
          title={formatDate(new Date(tooltipData.interval.end), {
            timeZone: "UTC",
          })}
          left={tooltipLeft! + margin.left}
          top={tooltipTop}
        />
      )}
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={colors.transparent}
        />
        <Group top={margin.top} left={margin.left}>
          <PatternLines
            id="pattern-lines-weather"
            height={13}
            width={13}
            stroke={colors.gray.light}
            strokeWidth={5}
            orientation={["diagonalRightToLeft"]}
          />
          <AreaClosed
            data={performanceData}
            x={(d) => xScale(dateAccessor(d)) || 0}
            y={(d) => yScaleTemperature(actualTempAccessor(d)) || 0}
            yScale={yScaleTemperature}
            fill={colors.gray.light}
            curve={curveMonotoneX}
          />
          <AreaClosed
            data={forecastData}
            x={(d) => xScale(dateAccessor(d)) || 0}
            y={(d) => yScaleTemperature(typicalAirTempAccessor(d)) || 0}
            yScale={yScaleTemperature}
            fill="url(#pattern-lines-weather)"
            curve={curveMonotoneX}
          />
          <LinePath
            data={data}
            x={(d) => xScale(dateAccessor(d)) || 0}
            y={(d) => yScaleTemperature(typicalAirTempAccessor(d)) || 0}
            stroke={colors.blue.light}
            strokeWidth="2"
            strokeDasharray="4,3"
            strokeOpacity="1"
            curve={curveMonotoneX}
          />
          {performanceData.map((d, idx) => {
            const barHeight = Math.abs(
              (yScaleEnergy(deltaBaselineAccessor(d)) || 0) -
                (yScaleEnergy(0) || 0)
            );

            // Bar is drawn from top-down; if Δ baseline is negative, bar top starts at 0
            const barY =
              deltaBaselineAccessor(d) > 0
                ? yScaleEnergy(deltaBaselineAccessor(d))
                : yScaleEnergy(0);

            return (
              <BarRounded
                key={`bar-${idx}`}
                x={xScale(dateAccessor(d)) || 0}
                y={barY || 0}
                width={10}
                height={barHeight}
                fill={colors.blue.main}
                radius={0}
              />
            );
          })}
          <AxisLeft
            scale={yScaleEnergy}
            tickFormat={tickFormatter}
            hideTicks
            label="kBTU / day"
          />
          <AxisRight
            scale={yScaleTemperature}
            tickFormat={tickFormatter}
            left={xMax}
            hideTicks
            label="Temperature (°F)"
          />
          {performanceData.length && (
            <Line
              from={{ x: 0, y: yScaleEnergy(0) }}
              to={{ x: xMax, y: yScaleEnergy(0) }}
              stroke="black"
              strokeOpacity="0.33"
              strokeWidth={1}
              pointerEvents="none"
            />
          )}
          <AxisBottom scale={xScale} top={yMax} hideAxisLine hideTicks />
          {tooltipData && (
            <Group>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: yMax }}
                stroke="black"
                strokeOpacity="0.33"
                strokeWidth={1}
                pointerEvents="none"
              />
              <GlyphCircle
                key={`glyph-tooltip-forecast`}
                left={tooltipLeft}
                top={yScaleTemperature(typicalAirTempAccessor(tooltipData))}
                size={50}
                fill={colors.blue.light}
                strokeWidth={2}
              />
              {!!tooltipData.reading && (
                <GlyphCircle
                  key={`glyph-tooltip-reading`}
                  left={tooltipLeft}
                  top={yScaleTemperature(actualTempAccessor(tooltipData))}
                  size={50}
                  fill={colors.gray.dark}
                  strokeWidth={2}
                />
              )}
            </Group>
          )}
        </Group>
        <rect
          x={margin.left}
          y={margin.top}
          width={xMax}
          height={yMax}
          fill={colors.transparent}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
      </svg>
    </div>
  );
};

export default WeatherAdjustmentChart;
