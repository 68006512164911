import { styled, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { Currency, EnergySource } from "../api/graphql";
import { clamp } from "../lib/clamp";
import { energySourceName } from "../lib/energySource";
import { sortBy } from "../lib/sortBy";
import sum from "../lib/sum";
import { CardContent, CardHeader } from "../ui/Card";
import StripeCard from "../ui/StripeCard";
import { FormSections } from "./FinancialModelDashboard";
import { fuelOrder } from "./fuelOrder";
import LineItem from "./LineItem";
import MoneyField from "./MoneyField";
import { ControlledNumberField } from "./NumberField";
import PercentField from "./PercentField";

interface Props {
  IRR: number | undefined;
  eeValuePerSource: {
    energySource: EnergySource;
    value: number;
  }[];
}

export const StyledMoneyField = styled(MoneyField)(
  ({ theme: { spacing } }) => ({
    maxWidth: spacing(15),
  })
);

const ProjectDataForm: FC<Props> = ({ IRR, eeValuePerSource }) => {
  const { register, errors, control } = useFormContext<FormSections>();

  return (
    <StripeCard>
      <CardHeader
        title={
          <Typography variant="button" color="primary">
            Capital investment
          </Typography>
        }
      />
      <CardContent>
        <LineItem label="Capital investment">
          <StyledMoneyField
            name="projectData.projectCost.value"
            control={control}
            rules={{
              valueAsNumber: true,
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Value cannot be negative",
              },
            }}
            errorMsg={errors.projectData?.projectCost?.value?.message}
            placeholder="Investment"
            precision={0}
          />
          <input
            type="hidden"
            ref={register}
            name="projectData.projectCost.unit"
            defaultValue={Currency.USD}
          />
        </LineItem>
        <LineItem label="Uncertainty band">
          <PercentField
            control={control}
            name="projectData.projectUncertainty"
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Value cannot be negative",
              },
              max: {
                value: 100,
                message: "Value must be from 0 to 100",
              },
            }}
            placeholder="Uncertainty band"
            errorMsg={errors.projectData?.projectUncertainty?.message}
          />
        </LineItem>
        <LineItem label="Term length">
          <Controller
            control={control}
            name="projectData.term"
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Value cannot be negative",
              },
            }}
            render={({ value, onChange }) => (
              <ControlledNumberField
                value={value}
                onChange={(v) => onChange(clamp(v, { min: 0, max: 50 }))}
                errorMsg={errors.projectData?.term?.message}
                placeholder="Term length"
                append="years"
              />
            )}
          />
        </LineItem>
        <LineItem label="Discount rate">
          <PercentField
            control={control}
            name="projectData.discountRate"
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Value cannot be negative",
              },
              max: {
                value: 100,
                message: "Value must be from 0 to 100",
              },
            }}
            placeholder="Discount rate"
            errorMsg={errors.projectData?.discountRate?.message}
          />
        </LineItem>
        <br />
        <LineItem label={<b>IRR</b>}>
          {!!IRR ? (
            <Typography>
              <b>
                <FormattedNumber
                  value={IRR}
                  style={`percent`}
                  minimumFractionDigits={2}
                />
              </b>
            </Typography>
          ) : (
            "—"
          )}
        </LineItem>
      </CardContent>
      <CardHeader
        title={
          <Typography variant="button" color="primary">
            Revenue (Lifetime energy efficiency value)
          </Typography>
        }
      />
      <CardContent>
        {sortBy(eeValuePerSource, fuelOrder).map((d, idx) => (
          <LineItem label={energySourceName(d.energySource)} key={idx}>
            <Typography>
              $<FormattedNumber value={Math.round(d.value)} />
            </Typography>
          </LineItem>
        ))}
        <br />
        <LineItem label={<b>Total</b>}>
          <Typography>
            <b>
              $
              <FormattedNumber
                value={Math.round(sum(eeValuePerSource.map((d) => d.value)))}
              />
            </b>
          </Typography>
        </LineItem>
      </CardContent>
    </StripeCard>
  );
};

export default ProjectDataForm;
