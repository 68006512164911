import { Line } from "@visx/shape";
import React, { FC } from "react";

interface Props {
  yMax: number;
  offsetLeft: number;
}

const TooltipLine: FC<Props> = ({ offsetLeft, yMax }) => (
  <Line
    from={{ x: offsetLeft, y: 0 }}
    to={{ x: offsetLeft, y: yMax }}
    stroke="black"
    strokeOpacity="0.33"
    strokeWidth={1}
    pointerEvents="none"
    strokeDasharray="3,2"
  />
);

export default TooltipLine;
