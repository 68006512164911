import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateUtilityMeterMutationVariables = Types.Exact<{
  input: Types.CreateUtilityMeterInput;
}>;


export type CreateUtilityMeterMutation = { createUtilityMeter: { __typename: 'CreateUtilityMeterResponse', utilityMeter: { __typename: 'UtilityMeter', externalId: Types.Maybe<string> } } };


export const CreateUtilityMeterDocument: DocumentNode<CreateUtilityMeterMutation, CreateUtilityMeterMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createUtilityMeter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUtilityMeterInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUtilityMeter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"utilityMeter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"externalId"}}]}}]}}]}}]};