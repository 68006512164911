import { Divider, Grid, GridProps, makeStyles } from "@material-ui/core";
import React from "react";
import Card, { CardContent, CardHeader } from "../ui/Card";

interface ReportCardProps extends GridProps {
  name: string;
}

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  root: {
    padding: spacing(1),
  },
  item: {
    height: "100%",
  },
  title: {
    fontSize: typography.fontSize,
    fontWeight: typography.fontWeightMedium,
    color: palette.text.secondary,
  },
}));

const ReportCard: React.FC<ReportCardProps> = ({ name, children, ...rest }) => {
  const { root, title, item } = useStyles();

  return (
    <Grid item xs={12} {...rest} className={item}>
      <Card className={item}>
        <CardHeader title={name} classes={{ root, title }} />
        <Divider />
        <CardContent>{children}</CardContent>
      </Card>
    </Grid>
  );
};

export default ReportCard;
