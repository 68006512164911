import { Divider, FormHelperText, Grid } from "@material-ui/core";
import { GraphQLError } from "graphql";
import React from "react";
import { useForm } from "react-hook-form";
import { EnergySource, EnergyUnitType } from "../api/graphql";
import displayUnit from "../lib/displayUnit";
import { defaultEnergySourceUnit, energySourceName } from "../lib/energySource";
import { presenceError, textFieldLimits } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import FormSection from "../ui/FormSection";
import Gaps from "../ui/Gaps";
import { SelectFieldController, SelectItem } from "../ui/SelectField";
import { TextField } from "../ui/TextField";

export enum Solar {
  SOLAR = "SOLAR",
}

type UtilityMeterSource = EnergySource | Solar;

interface Fields {
  meterId: string;
  energySource: UtilityMeterSource;
  unit: EnergyUnitType;
  utilityName: string;
}

type Props = {
  onCancel: () => void;
  onSubmit: (v: Fields) => Promise<void>;
  error?: GraphQLError;
  title?: string;
  hasModels?: boolean;
  submitButtonName?: string;
} & Partial<Fields>;

const validations = ({ meterId, energySource, unit }: Fields) => ({
  meterId: presenceError(meterId),
  energySource: presenceError(energySource),
  unit: presenceError(unit),
});

const lockedValidations = ({ meterId }: Fields) => ({
  meterId: presenceError(meterId),
});

const NewUtilityMeterDialog: React.FC<Props> = ({
  onCancel,
  onSubmit,
  error,
  meterId = "",
  energySource = EnergySource.ELECTRICITY,
  unit = defaultEnergySourceUnit(EnergySource.ELECTRICITY),
  title = "Add a utility meter",
  hasModels = false,
  utilityName = "",
  submitButtonName = "Add",
}) => {
  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
    register,
    watch,
  } = useForm<Fields>({
    defaultValues: {
      meterId,
      energySource,
      unit,
      utilityName,
    },
    resolver: validatorsResolver(hasModels ? lockedValidations : validations),
  });

  const onSourceSelection = (newSource: string): void => {
    let source: EnergySource;

    switch (newSource) {
      case Solar.SOLAR:
        source = EnergySource.ELECTRICITY;
        break;
      case EnergySource.ELECTRICITY:
        source = EnergySource.ELECTRICITY;
        break;
      case EnergySource.NATURAL_GAS:
        source = EnergySource.NATURAL_GAS;
        break;
      case EnergySource.FUEL_OIL2:
        source = EnergySource.FUEL_OIL2;
        break;
      default:
        source = EnergySource.ELECTRICITY;
    }

    control.setValue("unit", defaultEnergySourceUnit(source));
  };

  return (
    <FormDialog
      title={title}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitName={submitButtonName}
      error={error}
      isSubmitting={isSubmitting}
      maxWidth="sm"
    >
      <Gaps spacing={2}>
        <FormSection label="Energy source">
          <Grid item xs={12}>
            <SelectFieldController
              variant="outlined"
              name="energySource"
              label="Energy source"
              control={control}
              error={errors.energySource?.message}
              onChange={(v) => onSourceSelection(v)}
              locked={hasModels}
              lockExplanation="Building models depend on this data."
            >
              <SelectItem value={EnergySource.ELECTRICITY}>
                {energySourceName(EnergySource.ELECTRICITY)}
              </SelectItem>
              <SelectItem value={Solar.SOLAR}>Electric (solar) *</SelectItem>
              <SelectItem value={EnergySource.NATURAL_GAS}>
                {energySourceName(EnergySource.NATURAL_GAS)}
              </SelectItem>
              <SelectItem value={EnergySource.FUEL_OIL2}>
                {energySourceName(EnergySource.FUEL_OIL2)}
              </SelectItem>
            </SelectFieldController>
            {watch("energySource") === "SOLAR" && (
              <FormHelperText>
                * Solar meters are considered fully utilized electricity sources
                in energy modeling, but can be considered free energy sources in
                annual value estimations.
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <SelectFieldController
              name="unit"
              label="Measurement unit"
              control={control}
              error={errors.unit?.message}
              locked={hasModels}
              lockExplanation="Building models depend on this data."
            >
              {Object.values(EnergyUnitType).map((v, idx) => (
                <SelectItem key={`unit-${idx}`} value={v}>
                  {displayUnit(v)}
                </SelectItem>
              ))}
            </SelectFieldController>
          </Grid>
        </FormSection>
        <Divider />
        <FormSection label="Meter info">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                name="meterId"
                inputRef={register}
                type="text"
                id="meterId"
                label="Meter ID"
                error={errors.meterId?.message}
                InputProps={{
                  inputProps: {
                    maxLength: textFieldLimits.name.max,
                    minLength: textFieldLimits.name.min,
                  },
                }}
              />
              <TextField
                size="small"
                name="utilityName"
                inputRef={register}
                type="text"
                id="utilityName"
                label="Utility company"
                error={errors.utilityName?.message}
                InputProps={{
                  inputProps: {
                    maxLength: textFieldLimits.name.max,
                    minLength: textFieldLimits.name.min,
                  },
                }}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Gaps>
    </FormDialog>
  );
};

export default NewUtilityMeterDialog;
