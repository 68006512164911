import { useMutation, useQuery } from "@apollo/client";
import { Box, Typography } from "@material-ui/core";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import React, { FC, useState } from "react";
import { BuildingAccessType } from "../api/graphql";
import { organizationById } from "../lib/endpoints";
import pluckNodes from "../lib/pluckNodes";
import pluralize from "../lib/pluralize";
import BackButton from "../ui/BackButton";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import ProductCopy from "../ui/ProductCopy";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle, SectionTitle } from "../ui/Typography";
import {
  CreateBuildingPermissionsDocument,
  CreateBuildingPermissionsMutation,
} from "./CreateBuildingPermissions.generated";
import { CreateUserDocumentPermissionsDocument } from "./CreateUserDocumentPermissions.generated";
import ConfirmDocumentSharing from "./DocSharingConfirmDialog";
import OmniSearch from "./OmniSearch";
import {
  OrganizationUserBuildingsDocument,
  OrganizationUserBuildingsQuery,
} from "./OrganizationUserBuildingsQuery.generated";
import UserBuildings from "./UserBuildings";

export type Building = OrganizationUserBuildingsQuery["organization"]["buildings"]["edges"][number]["node"];
export type MutationBuildingPermissions = CreateBuildingPermissionsMutation["createBuildingPermissions"]["user"]["buildingPermissions"];
export type OrganizationDocument = MutationBuildingPermissions[number]["building"]["documents"]["edges"][number]["node"];

interface Props {
  organizationId: string;
  userId: string;
}

/**
 * Yields those documents not shared with the passed userId
 */
export const findUnsharedDocuments = (
  userId: string,
  buildingPermissions: MutationBuildingPermissions
): OrganizationDocument[] => {
  return buildingPermissions
    .flatMap((bp) => pluckNodes(bp.building.documents))
    .filter((d) => !d.users.some((du) => du.id === userId));
};

const OrganizationUser: FC<Props> = ({ organizationId, userId }) => {
  const createToast = useToastContext();
  const { data, loading, error } = useQuery(OrganizationUserBuildingsDocument, {
    variables: { organizationId, userId },
  });

  const [createBuildingPermissions, { loading: createLoading }] = useMutation(
    CreateBuildingPermissionsDocument
  );
  const [
    createUserDocumentPermissions,
    { loading: docPermissionsLoading },
  ] = useMutation(CreateUserDocumentPermissionsDocument);

  const [stagedBuildings, setStagedBuildings] = useState<Building[]>([]);
  const [unsharedDocuments, setUnsharedDocuments] = useState<
    OrganizationDocument[]
  >([]);

  const onAddBuildingsToUser = async () => {
    try {
      const { data } = await createBuildingPermissions({
        variables: {
          input: {
            access: BuildingAccessType.READ,
            userId,
            buildingIds: stagedBuildings.map(({ id }) => id),
          },
          organizationId,
        },
      });

      // Drill into the building documents to find building outliers
      if (data) {
        const { buildingPermissions } = data.createBuildingPermissions.user;
        setUnsharedDocuments(
          findUnsharedDocuments(userId, buildingPermissions)
        );
      }

      setStagedBuildings([]);
      createToast("Buildings were successfully shared", "success");
    } catch (err) {
      createToast(err.message, "error");
    }
  };

  if (!data || loading) return <Loading variant="circle" />;

  if (error) throw error;

  const userBuildings = data.user.buildingPermissions;

  // Filter out elements that appear in the user's buildingPermission list
  const buildingDropdownOptions = pluckNodes(
    data.organization.buildings
  ).filter((b) => !userBuildings.some((ub) => ub.building.id === b.id));

  return (
    <>
      {unsharedDocuments.length > 0 && (
        <ConfirmDocumentSharing
          onCancel={() => setUnsharedDocuments([])}
          onSubmit={async () => {
            try {
              await createUserDocumentPermissions({
                variables: {
                  input: {
                    userId: data.user.id,
                    documentIds: unsharedDocuments.map((d) => d.id),
                  },
                },
              });
              createToast("Building documents were shared", "success");
              setUnsharedDocuments([]);
            } catch (err) {
              createToast(err.message || "Error sharing documents", "error");
            }
          }}
          loading={docPermissionsLoading}
          unsharedDocuments={unsharedDocuments}
          user={data.user}
        />
      )}
      <BackButton
        to={organizationById({ id: organizationId })}
        label={`Back to ${ProductCopy.USERS}`}
      />
      <Box pb={1}>
        <PageTitle>Member Settings: {data.user.name}</PageTitle>
      </Box>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <OmniSearch
            placeholder="Search building names, state names, or major tenants"
            options={buildingDropdownOptions}
            onChange={setStagedBuildings}
            value={stagedBuildings}
            disabled={createLoading}
          />
        </Box>
        <Box pl={2}>
          <Button
            disabled={createLoading}
            primary
            loading={createLoading}
            onClick={() => onAddBuildingsToUser()}
          >
            <Typography noWrap variant="button">
              Share buildings
            </Typography>
          </Button>
        </Box>
      </Box>
      <br />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SectionTitle>Buildings shared with {data.user.name}</SectionTitle>
        {userBuildings.length > 0 && (
          <Typography color="textSecondary">
            {userBuildings.length} {pluralize("building", userBuildings.length)}
          </Typography>
        )}
      </Box>
      {userBuildings.length > 0 ? (
        <UserBuildings buildingPermissions={userBuildings} />
      ) : (
        <Box pt={2}>
          <PlaceholderContainer>
            <PlaceholderSegment
              icon={FolderSharedIcon}
              header="No buildings shared"
              subheader="Search for buildings to share them with this user"
            />
          </PlaceholderContainer>
        </Box>
      )}
    </>
  );
};

export default OrganizationUser;
