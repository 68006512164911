import { withStyles } from "@material-ui/core";
import Card from "../ui/Card";

interface Props {
  orientation?: "left" | "top";
}

const StripeCard = withStyles(({ palette }) => ({
  root: {
    borderTop: ({ orientation }: Props) =>
      orientation === "top" || !orientation
        ? `5px solid ${palette.primary.main}`
        : undefined,
    borderLeft: ({ orientation }: Props) =>
      orientation === "left" ? `5px solid ${palette.primary.main}` : undefined,
  },
}))(Card);

export default StripeCard;
