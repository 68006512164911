import React from "react";
import { useIntl } from "react-intl";
import {
  Area,
  AreaChart,
  Label,
  LabelFormatter,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EnergyEndUse } from "../api/graphql";
import { endUseName, energyEndUseColor } from "../lib/endUse";
import { energySourceName } from "../lib/energySource";
import formatter from "../lib/formatter";
import {
  axisLabelFontSize as fontSize,
  chartAnimationActive,
  tooltipAnimationActive,
  yAxisOffset,
} from "../lib/reporting/config";
import { formatModelEnergyReport } from "../lib/reporting/seasonalPerformanceChart";
import { SeasonalPerformance as SeasonalPerformanceType } from "../lib/types/Reports";
import unique from "../lib/unique";
import assignedEnergyColor from "./assignedEnergyColor";
import CustomTooltip from "./CustomTooltip";

interface Props {
  aspect: number;
  model: SeasonalPerformanceType[];
}

const SeasonalPerformance: React.FC<Props> = ({ aspect, model }) => {
  const intl = useIntl();

  const dateFormatter: LabelFormatter = (date) =>
    intl.formatDate(date, { year: "numeric", month: "short" });

  const derivedModelReport = formatModelEnergyReport(model);

  const energySourcesUniq = unique(
    model[0].endUses.map((el) => el.energySource)
  );

  return (
    <ResponsiveContainer aspect={aspect}>
      <AreaChart data={derivedModelReport} throttleDelay={0}>
        <XAxis
          dataKey="month"
          tickFormatter={dateFormatter}
          tickLine={false}
          axisLine={false}
          tick={{ fontSize }}
        ></XAxis>
        <YAxis tickFormatter={formatter} tickLine={false} tick={{ fontSize }}>
          <Label
            value="kBTU"
            position="insideLeft"
            angle={-90}
            offset={yAxisOffset}
            fontSize={fontSize}
          />
        </YAxis>
        <Tooltip
          isAnimationActive={tooltipAnimationActive}
          content={
            <CustomTooltip
              formatting={(value) => formatter(value) + " kBTU"}
              labelFormatting={dateFormatter}
            />
          }
        />
        <Legend
          height={40}
          verticalAlign="top"
          align="center"
          iconType="circle"
        />
        {[
          ...assignedEnergyColor(energySourcesUniq).map(
            ({ energySource, color }) => ({
              dataKey: energySource,
              color,
              name: `${energySourceName(energySource)} Baseload`,
            })
          ),
          ...[EnergyEndUse.SPACE_HEATING, EnergyEndUse.SPACE_COOLING].map(
            (e) => ({
              dataKey: e,
              color: energyEndUseColor(e),
              name: endUseName(e),
            })
          ),
        ].map(({ dataKey, color, name }, idx) => (
          <Area
            isAnimationActive={chartAnimationActive}
            legendType="rect"
            key={`perf-area-${idx}`}
            type="monotone"
            name={name}
            dataKey={dataKey}
            stackId="1"
            stroke={color}
            fill={color}
            fillOpacity={1}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default SeasonalPerformance;
