import { useMutation } from "@apollo/client";
import { Box, CardContent, Grid, TextField } from "@material-ui/core";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import Gaps from "../ui/Gaps";
import StripeCard from "../ui/StripeCard";
import { useToastContext } from "../ui/ToastProvider";
import { UpdateTransactionDocument } from "./UpdateTransactionMutation.generated";

interface Props {
  transactionId: string;
  notes: string;
}

interface Field {
  notes: string;
}

const TransactionNotes: FC<Props> = ({ transactionId, notes }) => {
  const { isSuperuser } = useSessionWithUser();
  const createToast = useToastContext();
  const [updateTransaction] = useMutation(UpdateTransactionDocument);
  const { handleSubmit, register } = useForm<Field>({
    defaultValues: {
      notes,
    },
  });

  const onSubmit = async ({ notes }: Field) => {
    try {
      await updateTransaction({
        variables: {
          input: {
            transactionId,
            notes,
          },
        },
      });
      createToast("Transaction successfully updated.", "success");
    } catch (err) {
      createToast(err.message || "Error updating Transaction", "error");
    }
  };

  if (!isSuperuser) return null;

  return (
    <Grid item xs={12}>
      <StripeCard orientation="top">
        <CardContent>
          <Gaps spacing={2}>
            <form>
              <TextField
                fullWidth
                inputRef={register}
                name="notes"
                label="Notes"
                multiline
                variant="outlined"
              />
            </form>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleSubmit(onSubmit)}>Save notes</Button>
            </Box>
          </Gaps>
        </CardContent>
      </StripeCard>
    </Grid>
  );
};

export default TransactionNotes;
