import { useQuery } from "@apollo/client";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { NewInvestmentState } from "../FinancialModel";
import { newFinancialModel } from "../lib/endpoints";
import useLazyDialogData from "../lib/useLazyDialogData";
import Loading from "../ui/Loading";
import ProductCopy from "../ui/ProductCopy";
import { PageTitle } from "../ui/Typography";
import { CalibratedModelsQueryDocument } from "./CalibratedModelsQuery.generated";
import FinancialModelCard from "./FinancialModelCard";
import { FinancialModelsQueryDocument } from "./FinancialModelsQuery.generated";
import NewFinancialModelDialog from "./NewFinancialModelDialog";
import NoCalibratedModelsPlaceholder from "./NoCalibratedModelsPlaceholder";
import NoFinancialModelsPlaceholder from "./NoFinancialModelsPlaceholder";

interface Props {
  buildingId: string;
  hasBuildingModels: boolean;
}

const InvestmentList: FC<Props> = ({ buildingId, hasBuildingModels }) => {
  const history = useHistory();

  const {
    openDialog,
    closeDialog,
    data: dialogData,
    loading: dialogLoading,
  } = useLazyDialogData(CalibratedModelsQueryDocument);

  const { data, loading, error } = useQuery(FinancialModelsQueryDocument, {
    variables: { buildingId },
  });

  if (error) throw error;
  if (loading) return <Loading variant="circle" />;

  const financialModels = data?.building.financialModels || [];

  return (
    <>
      {dialogData && (
        <NewFinancialModelDialog
          calibratedModels={dialogData.building.calibratedModels}
          onSubmit={(fields) => {
            const state: NewInvestmentState = {
              calibratedModelId: fields.calibratedModelId,
              discountRate: fields.discountRate / 100,
              projectCost: fields.projectCost,
            };
            history.push(newFinancialModel({ buildingId }), state);
          }}
          onCancel={() => closeDialog()}
        />
      )}
      <Box pb={1} display="flex" justifyContent="space-between">
        <PageTitle>{ProductCopy.FINANCIAL_MODELS}</PageTitle>
        {hasBuildingModels && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => openDialog({ buildingId })}
          >
            {dialogLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Create Financial model"
            )}
          </Button>
        )}
      </Box>
      <Grid container spacing={2}>
        {!hasBuildingModels ? (
          <NoCalibratedModelsPlaceholder />
        ) : financialModels.length === 0 ? (
          <Grid item xs={12}>
            <NoFinancialModelsPlaceholder
              onCreateNew={() => openDialog({ buildingId })}
            />
          </Grid>
        ) : (
          financialModels.map((model) => (
            <FinancialModelCard
              key={model.id}
              financialModel={model}
              buildingId={buildingId}
            />
          ))
        )}
      </Grid>
    </>
  );
};

export default InvestmentList;
