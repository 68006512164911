import { useQuery } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { buildingTransaction } from "../lib/endpoints";
import InvoiceCard from "../Transactions/InvoiceCard";
import BackButton from "../ui/BackButton";
import Loading from "../ui/Loading";
import { SectionTitle } from "../ui/Typography";
import { InvoicesQueryDocument } from "./Invoices.generated";

interface Props {
  buildingId: string;
}

const TransactionInvoices: FC<Props> = ({ buildingId }) => {
  const { data, loading, error } = useQuery(InvoicesQueryDocument, {
    variables: { buildingId },
  });

  if (error) throw error;
  // TODO: fallback for no transaction
  if (!data || !data.building.transaction || loading)
    return <Loading variant="circle" />;

  const { invoices } = data.building.transaction;
  const { timeZone } = data.building.transaction.building.address;

  return (
    <>
      <Box pb={1}>
        <BackButton
          label="Back to Transaction"
          to={buildingTransaction({ buildingId })}
        />
        <SectionTitle>All invoices</SectionTitle>
      </Box>
      <Grid container spacing={2}>
        {invoices.map((invoice) => (
          <Grid item xs={12} sm={6} key={invoice.id}>
            <InvoiceCard invoice={invoice} timeZone={timeZone} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TransactionInvoices;
