import React from "react";
import { SvgIcon } from "@material-ui/core";

function SvgChangeLgOpt(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.792 29.548" {...props}>
      <g paintOrder="markers fill stroke">
        <path
          d="M39.768 3.312L0 29.546h39.77z"
          style={{
            fontVariationSettings: "normal",
          }}
        />
        <path
          d="M44.792 0l-5.024 3.314v26.234h5.024z"
          fillOpacity={0.3}
          style={{
            fontVariationSettings: "normal",
          }}
        />
      </g>
    </svg>
  );
}

const ChangeLg = () => (
  <SvgIcon component={SvgChangeLgOpt} viewBox="0 5 50 24" fontSize="large" />
);

export default ChangeLg;
