import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import { EnergySource } from "../api/graphql";
import { energySourceName } from "../lib/energySource";

interface Props {
  onSelectCombined: () => void;
  onSelectSingle: (name: EnergySource) => void;
  energySources: EnergySource[];
  selectedEnergy: EnergySource | null;
}

const useStyles = makeStyles(({ palette }) => ({
  buttonSelected: {
    color: palette.common.white,
    backgroundColor: palette.grey[400],
    "&:hover": {
      backgroundColor: palette.grey[400],
    },
  },
  buttonText: {
    color: palette.grey[500],
  },
}));

const FilterButtons: React.FC<Props> = ({
  onSelectCombined,
  onSelectSingle,
  energySources,
  selectedEnergy,
}) => {
  const { buttonSelected, buttonText } = useStyles();

  return (
    <Box display="flex">
      {energySources.length > 1 && (
        <Box pr={1}>
          <Button
            size="small"
            className={!selectedEnergy ? buttonSelected : buttonText}
            onClick={onSelectCombined}
          >
            Combined
          </Button>
        </Box>
      )}
      {energySources.map((sourceName, idx) => (
        <Box pr={1} key={`filter-buttons-${idx}`}>
          <Button
            size="small"
            className={
              selectedEnergy === sourceName ? buttonSelected : buttonText
            }
            onClick={() => onSelectSingle(sourceName)}
          >
            {energySourceName(sourceName)}
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default FilterButtons;
