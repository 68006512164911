import { styled } from "@material-ui/core";
import * as React from "react";
import { PageTitle } from "./ui/Typography";

const folderId = "1SvL5OUeEp8jxrnwK6tIRmP1kKEL8Wvjs";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
});

const FlexFixed = styled("div")(({ theme: { spacing } }) => ({
  flex: "0 0 auto",
  marginTop: spacing(3),
  marginLeft: spacing(6),
}));

const Documentation: React.FC = () => {
  return (
    <Container>
      <FlexFixed>
        <PageTitle>EnergyRM Documentation</PageTitle>
      </FlexFixed>
      <iframe
        title="EnergyRM Documentation"
        src={`https://drive.google.com/embeddedfolderview?id=${folderId}#grid`}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </Container>
  );
};

export default Documentation;
