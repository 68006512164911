import millify from "millify";
import { EnergyUnitType } from "../api/graphql";
import displayUnit from "../lib/displayUnit";

export const dollars = (v: number) => `$${millify(v, { precision: 1 })}`;

export const energyPerFootSquared = (unit: EnergyUnitType) => (v: number) =>
  `${millify(v, { precision: 1 })} ${displayUnit(unit)}/ft²`;

export const dollarsPerFootSquared = (v: number) =>
  `$${millify(v, { precision: 1 })}/ft²`;

export const perFootSquared = (v: number) =>
  `${millify(v, { precision: 1 })}/ft²`;
