import { makeStyles } from "@material-ui/core";
import React from "react";
import LabelledValue from "../lib/LabelledValue";
import { CalibratedModelQuery } from "./CalibratedModel.generated";

type AdjustedModelAdjustments = CalibratedModelQuery["calibratedModel"]["adjustedModels"][number]["adjustments"];

type Index = keyof Partial<Omit<AdjustedModelAdjustments, "__typename">>;

type AdjustmentView = {
  [key in Index]: {
    name: string;
    unit: string;
  };
};

const adjustmentView: AdjustmentView = {
  lighting: {
    name: "Interior lighting",
    unit: "W/Ft²",
  },
  heatingSetTemp: {
    name: "Heating set temp",
    unit: "°F",
  },
  coolingSetTemp: {
    name: "Cooling set temp",
    unit: "°F",
  },
  shellHeatLoss: {
    name: "Building envelope",
    unit: "%",
  },
  spaceHeatingElectric: {
    name: "Space heating (electric)",
    unit: "%",
  },
  spaceHeatingGas: {
    name: "Space heating (gas)",
    unit: "%",
  },
  spaceHeatingPropane: {
    name: "Space heating (propane)",
    unit: "%",
  },
  spaceHeatingFuelOil2: {
    name: "Space heating (fuel oil no. 2)",
    unit: "%",
  },
  spaceCoolingElectric: {
    name: "Space cooling (electric)",
    unit: "%",
  },
  spaceCoolingGas: {
    name: "Space cooling (gas)",
    unit: "%",
  },
  spaceCoolingWater: {
    name: "Space cooling (water)",
    unit: "%",
  },
  hotWaterElectric: {
    name: "Hot water (electric)",
    unit: "%",
  },
  hotWaterGas: {
    name: "Hot water (gas)",
    unit: "%",
  },
  hotWaterPropane: {
    name: "Hot water (propane)",
    unit: "%",
  },
  hotWaterFuelOil2: {
    name: "Hot water (fuel oil no. 2)",
    unit: "%",
  },
  hotWaterVolume: {
    name: "Hot water gallons",
    unit: "%",
  },
  interiorElectric: {
    name: "Interior electric",
    unit: "%",
  },
  exteriorElectric: {
    name: "Exterior electric",
    unit: "%",
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

interface Props {
  adjustments: AdjustedModelAdjustments;
}

const precision = (n: number) => n.toPrecision(2);

const AdjustedModelDetailFragment: React.FC<Props> = ({ adjustments }) => {
  const { gridContainer } = useStyles();

  return (
    <div className={gridContainer}>
      {adjustments &&
        Object.entries(adjustments)
          .filter(([_, value]) => value && value > 0)
          .map(([key, value]) => {
            const { name, unit } = adjustmentView[key as Index]!;
            if (!value || typeof value !== "number") return <></>;
            return (
              <div key={key}>
                <LabelledValue
                  title={name}
                  value={`${
                    unit === "%" ? precision(value * 100) : precision(value)
                  }${unit}`}
                />
              </div>
            );
          })}
    </div>
  );
};

export default AdjustedModelDetailFragment;
