import { styled } from "@material-ui/core";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GlyphCircle } from "@visx/glyph";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaStack, Line, LinePath } from "@visx/shape";
import millify from "millify";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import formatter from "../lib/formatter";
import minZero from "../lib/minZero";
import ChartTooltip from "../ui/charting/ChartTooltip";
import TooltipLine from "../ui/charting/TooltipLine";
import useChartTooltip from "../ui/charting/useChartTooltip";
import colors from "../ui/colors";
import { Expense, FormSections } from "./FinancialModelDashboard";
import {
  calculateCumulativeSum,
  createCashFlows,
  DataPoint,
  EnergySourceConfig,
  Money,
} from "./helpers";

interface Props {
  width: number;
  height: number;
  discounted: boolean;
  energySourceConfigs: EnergySourceConfig[];
}

type Accessor = (d: DataPoint) => number;

// Chart containing element must be position relative and intake width
// to correctly render tooltip using <TooltipWithBounds />
const ChartContainer = styled("div")({
  position: "relative",
  width: ({ width }: { width: number }) => width,
});

const margin = { top: 5, right: 20, bottom: 55, left: 55 };

export const formatChartData = (
  sources: EnergySourceConfig[],
  termLength: number,
  projectUncertainty: number,
  expenses: Expense[],
  initialInvestment: Money,
  discountRate: number
): DataPoint[] =>
  calculateCumulativeSum([
    {
      low: -initialInvestment.value,
      average: -initialInvestment.value,
      high: -initialInvestment.value,
      year: 0,
    },
    ...createCashFlows(
      sources,
      termLength,
      projectUncertainty,
      expenses,
      discountRate
    ),
  ]);

// Accessors
const yearAccessor: Accessor = (d) => d.year;
const averageAccessor: Accessor = (d) => d.average;
const lowAccessor: Accessor = (d) => d.low;
const highAccessor: Accessor = (d) => d.high;

const CumulativeCashFlowChart: FC<Props> = ({
  height,
  width,
  discounted,
  energySourceConfigs,
}) => {
  // TODO: remove the need for watchers here?
  const { watch } = useFormContext<FormSections>();
  const projectData = watch("projectData");
  const expenses = watch("expenses");
  const chartData = formatChartData(
    energySourceConfigs,
    projectData.term,
    projectData.projectUncertainty,
    expenses,
    projectData.projectCost,
    discounted ? projectData.discountRate : 0
  );

  // Scales
  const xMax = minZero(width - margin.left - margin.right);
  const yMax = minZero(height - margin.bottom - margin.top);

  const xScale = scaleLinear<number>({
    range: [0, xMax],
    domain: [
      Math.min(...chartData.map(yearAccessor)),
      Math.max(...chartData.map(yearAccessor)),
    ],
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [
      Math.min(...chartData.map(lowAccessor)),
      Math.max(...chartData.map(highAccessor)),
    ],
  });

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    hideTooltip,
    handleTooltip,
  } = useChartTooltip<DataPoint>({
    xScale,
    offset: margin.left,
    data: chartData,
    xAccessor: yearAccessor,
  });

  return (
    <ChartContainer width={width}>
      {tooltipData && (
        <ChartTooltip
          tooltipLines={[
            {
              label: "High",
              value: `$${formatter(tooltipData.high)}`,
            },
            {
              label: "Average",
              value: `$${formatter(tooltipData.average)}`,
            },
            {
              label: "Low",
              value: `$${formatter(tooltipData.low)}`,
            },
          ]}
          title={`Year ${tooltipData.year}`}
          left={tooltipLeft! + margin.left}
          top={tooltipTop}
        />
      )}
      <svg
        width={width}
        height={height}
        onMouseMove={handleTooltip}
        onMouseLeave={() => hideTooltip()}
      >
        <Group top={margin.top} left={margin.left}>
          {tooltipData && (
            <Group>
              <TooltipLine yMax={yMax} offsetLeft={tooltipLeft || 0} />
              <GlyphCircle
                key={`glyph-tooltip-${Math.random()}`}
                left={tooltipLeft}
                top={yScale(averageAccessor(tooltipData))}
                size={50}
                fill={colors.blue.main}
                strokeWidth={2}
              />
            </Group>
          )}
          <AreaStack
            top={0}
            left={0}
            keys={["CumulativeSum"]}
            data={chartData}
            x={(d) => xScale(yearAccessor(d.data)) || 0}
            y0={(d) => yScale(lowAccessor(d.data)) || 0}
            y1={(d) => yScale(highAccessor(d.data)) || 0}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack) || ""}
                  stroke="transparent"
                  fill={colors.blue.main}
                  opacity={0.33}
                />
              ))
            }
          </AreaStack>
          <LinePath
            data={chartData}
            x={(d) => xScale(yearAccessor(d)) || 0}
            y={(d) => yScale(averageAccessor(d)) || 0}
            stroke={colors.blue.main}
            strokeWidth={2}
            strokeOpacity={1}
          />
          <Line
            from={{ x: 0, y: yScale(0) }}
            to={{ x: xMax, y: yScale(0) }}
            stroke={colors.black}
            strokeWidth={0.75}
            strokeOpacity={0.33}
          />
          <AxisLeft
            scale={yScale}
            tickFormat={(v) => millify(Number(v))}
            hideTicks
            label="Dollars (USD)"
          />
          <AxisBottom
            scale={xScale}
            top={yMax}
            tickFormat={(v) => `Year ${v}`}
            hideAxisLine
            hideTicks
          />
        </Group>
      </svg>
    </ChartContainer>
  );
};

export default CumulativeCashFlowChart;
