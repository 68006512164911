import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import React, { FC, useState } from "react";
import { DeltaMeterAdjustmentModelFragmentDoc } from "../api/fragments/DeltaMeterAdjustmentModel.generated";
import { DeltaMeterAdjustmentReason, EnergyUnitType } from "../api/graphql";
import { useToastContext } from "../ui/ToastProvider";
import { CreateDeltaMeterAdjustmentDocument } from "./CreateDeltaMeterAdjustment.generated";
import NewAdjustmentDialog, { Fields } from "./NewAdjustmentDialog";

interface Props {
  deltaMeterReadingId: string;
  adjustmentUnit: EnergyUnitType;
}

const NewAdjustmentButton: FC<Props> = ({
  deltaMeterReadingId,
  adjustmentUnit,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [createAdjustment, { error: createAdjustmentError }] = useMutation(
    CreateDeltaMeterAdjustmentDocument
  );

  const createToast = useToastContext();

  const onSubmit = async ({ reason, notes, quantity }: Fields) => {
    try {
      await createAdjustment({
        variables: {
          input: {
            deltaMeterReadingId,
            quantity: quantity as number,
            reason: reason as DeltaMeterAdjustmentReason,
            notes,
            unit: adjustmentUnit,
          },
        },
        update(cache, { data }) {
          cache.modify({
            id: cache.identify({
              __typename: "DeltaMeterReading",
              id: deltaMeterReadingId,
            }),
            fields: {
              adjustments(existingAdjustmentRefs = []) {
                const newAdjustmentRef = cache.writeFragment({
                  data: data?.createDeltaMeterAdjustment.deltaMeterAdjustment,
                  fragment: DeltaMeterAdjustmentModelFragmentDoc,
                });

                return [...existingAdjustmentRefs, newAdjustmentRef];
              },
            },
          });
        },
      });

      setShowDialog(false);
      createToast("Adjustment was created", "success");
    } catch (err) {
      createToast(err.message, "error");
    }
  };

  return (
    <>
      {showDialog && (
        <NewAdjustmentDialog
          onSubmit={(fields) => onSubmit(fields)}
          onCancel={() => setShowDialog(false)}
          error={createAdjustmentError?.graphQLErrors[0]}
          adjustmentUnit={adjustmentUnit}
        />
      )}
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setShowDialog(true)}
      >
        Add
      </Button>
    </>
  );
};

export default NewAdjustmentButton;
