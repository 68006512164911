import { makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import React from "react";
import { BuildingModelFit } from "./api/graphql";

interface Props {
  model: { fit: BuildingModelFit } | null;
  size?: "small" | "large";
}

const useStyles = makeStyles({
  center: {
    verticalAlign: "middle",
  },
});

export const THRESHOLD = 0.85;

const BuildingModelStatus: React.FC<Props> = ({ model, size = "small" }) => {
  const { center } = useStyles();

  return !model ? (
    <HourglassEmptyIcon
      fontSize={size}
      titleAccess="Modeling is happening, or failed."
    />
  ) : model.fit === BuildingModelFit.GOOD ? (
    <CheckCircleIcon
      className={center}
      fontSize={size}
      color="primary"
      titleAccess="Model completed with good results."
    />
  ) : (
    <ReportProblemOutlinedIcon
      className={center}
      fontSize={size}
      color="secondary"
      titleAccess="Model completed but has data issues."
    />
  );
};

export default BuildingModelStatus;
