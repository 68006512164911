import { useQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { EnergyUnitType } from "../api/graphql";
import displayUnit from "../lib/displayUnit";
import { buildingDeltaMeter } from "../lib/endpoints";
import Energy from "../lib/Energy";
import { energySourceName } from "../lib/energySource";
import { sortBy } from "../lib/sortBy";
import targetUnitConverter from "../lib/targetUnitConverter";
import { useSessionWithUser } from "../SessionWithUser";
import BackButton from "../ui/BackButton";
import Loading from "../ui/Loading";
import MetaData from "../ui/MetaData";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import { PageTitle } from "../ui/Typography";
import AdjustmentCard from "./AdjustmentCard";
import DeleteAdjustmentButton from "./DeleteAdjustmentButton";
import { DeltaMeterReadingDocument } from "./DeltaMeterReadingQuery.generated";
import EditAdjustmentButton from "./EditAdjustmentButton";
import NewAdjustmentButton from "./NewAdjustmentButton";

interface Props {
  readingId: string;
}

const DeltaMeterReading: FC<Props> = ({ readingId }) => {
  const { data, loading, error } = useQuery(DeltaMeterReadingDocument, {
    variables: { readingId },
  });
  const { isSuperuser } = useSessionWithUser();
  const { formatDate, formatNumber } = useIntl();

  if (error) throw error;
  if (loading || !data) return <Loading variant="circle" />;

  const {
    deltaMeterReading: {
      adjustments,
      baseline,
      interval,
      energySource,
      unit: readingUnit,
      adjustedBaseline,
      deltaMeter: {
        building: { utilityMeters },
      },
    },
  } = data;

  // Unit to use in the adjustment form
  const adjustmentUnit: EnergyUnitType = utilityMeters.filter(
    (um) => um.energySource === energySource
  )[0].unit;

  const toTargetUnit = targetUnitConverter(readingUnit);

  const netAdjustmentQuantity =
    toTargetUnit(adjustedBaseline, readingUnit) -
    toTargetUnit(baseline, readingUnit);

  return (
    <div>
      <BackButton
        to={buildingDeltaMeter({
          buildingId: data.deltaMeterReading.deltaMeter.building.id,
        })}
        label="Back to DeltaMeter"
      />
      <Box
        pb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <PageTitle>DeltaMeter reading adjustments</PageTitle>
          <MetaData>
            <Typography component="span" color="textSecondary">
              {energySourceName(energySource)} meter
            </Typography>
            <Typography component="span" color="textSecondary">
              Reading period: {formatDate(interval.start)} —{" "}
              {formatDate(interval.end)}
            </Typography>
            <Typography component="span" color="textSecondary">
              Net adjustment quantity: {formatNumber(netAdjustmentQuantity)}{" "}
              {displayUnit(readingUnit)}
            </Typography>
          </MetaData>
        </div>
        <div>
          {isSuperuser && (
            <NewAdjustmentButton
              deltaMeterReadingId={readingId}
              adjustmentUnit={adjustmentUnit}
            />
          )}
        </div>
      </Box>
      {adjustments.length > 0 ? (
        <Grid container spacing={2}>
          {sortBy(adjustments, (adj) => adj.quantity, "desc").map((adj) => (
            <Grid item xs={12}>
              <AdjustmentCard
                key={`adjustment-${adj.id}`}
                reason={adj.reason}
                quantity={adj.quantity}
                unit={adj.unit}
                percentBaseline={
                  (new Energy({
                    quantity: adj.quantity,
                    unit: adjustmentUnit,
                  }).as(EnergyUnitType.KBTU).quantity /
                    new Energy({
                      quantity: baseline,
                      unit: readingUnit,
                    }).as(EnergyUnitType.KBTU).quantity) *
                  100
                }
                notes={adj.notes}
                editAdjustment={
                  isSuperuser && (
                    <EditAdjustmentButton
                      deltaMeterAdjustmentId={adj.id}
                      defaultFields={{
                        notes: adj.notes || "",
                        quantity: adj.quantity,
                        reason: adj.reason,
                      }}
                      adjustmentUnit={adjustmentUnit}
                    />
                  )
                }
                deleteAdjustment={
                  isSuperuser && (
                    <DeleteAdjustmentButton
                      readingId={readingId}
                      adjustmentId={adj.id}
                    />
                  )
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <PlaceholderContainer>
          <PlaceholderSegment
            icon={TuneIcon}
            header="No adjustments"
            subheader="There are no adjustments for this DeltaMeter reading"
          />
        </PlaceholderContainer>
      )}
    </div>
  );
};

export default DeltaMeterReading;
