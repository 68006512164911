import { Box, styled, Typography } from "@material-ui/core";
import { defaultStyles, TooltipWithBounds } from "@visx/tooltip";
import { TooltipWithBoundsProps } from "@visx/tooltip/lib/tooltips/TooltipWithBounds";
import React, { ReactNode } from "react";
import colors from "../colors";

type Props = {
  tooltipLines: TooltipLine[];
  title: ReactNode;
} & Omit<TooltipWithBoundsProps, "style" | "data">;

export interface TooltipLine {
  label: string;
  value: string;
  color?: string;
}

const TitleFont = styled(Typography)({
  fontSize: 16,
});

type LabelFontProps = { hue?: string };

const LabelFont = styled(Typography)({
  fontSize: 12,
  color: ({ hue }: LabelFontProps) => (hue ? hue : colors.black),
});

const ChartTooltip: React.FC<Props> = ({ tooltipLines, title, left, top }) => {
  return (
    <TooltipWithBounds
      key={Math.random()}
      left={left}
      top={top}
      style={{
        ...defaultStyles,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      }}
      offsetLeft={20}
    >
      <Box p={1}>
        <TitleFont>
          <b>{title}</b>
        </TitleFont>

        {tooltipLines.map((tl, idx) => {
          return (
            <LabelFont
              key={`tooltip-${idx}`}
              hue={tl.color ? tl.color : undefined}
            >
              {tl.label}: {tl.value}
            </LabelFont>
          );
        })}
      </Box>
    </TooltipWithBounds>
  );
};

export default ChartTooltip;
