import { withStyles } from "@material-ui/core";
import { RangeSlider } from "./RangeSlider";

const BrandRangeSlider = withStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: palette.common.white,
    border: "2px solid currentColor",
    marginTop: -5,
    marginLeft: -9,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(RangeSlider);

export default BrandRangeSlider;
