import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateDefaultModelsMutationVariables = Types.Exact<{
  input: Types.SetDefaultBuildingAdjustedModelInput;
}>;


export type UpdateDefaultModelsMutation = { setDefaultBuildingAdjustedModel: { __typename: 'SetDefaultBuildingAdjustedModelResult', adjustedModel: { __typename: 'BuildingAdjustedModel', id: string, default: boolean, buildingCalibratedModel: { __typename: 'BuildingCalibratedModel', id: string, default: boolean, building: { __typename: 'Building', id: string, calibratedModels: Array<{ __typename: 'BuildingCalibratedModel', id: string, default: boolean, adjustedModels: Array<{ __typename: 'BuildingAdjustedModel', id: string, default: boolean }> }> } } } } };


export const UpdateDefaultModelsDocument: DocumentNode<UpdateDefaultModelsMutation, UpdateDefaultModelsMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateDefaultModels"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetDefaultBuildingAdjustedModelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setDefaultBuildingAdjustedModel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adjustedModel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"default"}},{"kind":"Field","name":{"kind":"Name","value":"buildingCalibratedModel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"default"}},{"kind":"Field","name":{"kind":"Name","value":"building"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"calibratedModels"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"default"}},{"kind":"Field","name":{"kind":"Name","value":"adjustedModels"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"default"}}]}}]}}]}}]}}]}}]}}]}}]};