import { useMutation } from "@apollo/client";
import React, { FC } from "react";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import { useToastContext } from "../ui/ToastProvider";
import { DeleteUtilityMeterReadingDocument } from "./DeleteUtilityMeterReading.generated";
import lockedReason from "./lockedReason";
import { MixedReading } from "./UtilityMeter";

interface Props {
  reading: MixedReading;
}

const DeleteReadingButton: FC<Props> = ({ reading }) => {
  const createToast = useToastContext();
  const [deleteUtilityMeterReading] = useMutation(
    DeleteUtilityMeterReadingDocument
  );

  return (
    <ButtonWithConfirmation
      size="small"
      header="Delete this reading?"
      description="This can't be undone"
      onClick={() =>
        deleteUtilityMeterReading({
          variables: {
            input: {
              utilityMeterReadingId: reading.id!,
            },
          },
          update(cache, { data }) {
            const readingToRemove = cache.identify({
              __typename: "UtilityMeterReading",
              id: data?.deleteUtilityMeterReading.utilityMeterReading?.id,
            });
            cache.evict({ id: readingToRemove });
            cache.gc();
          },
        })
          .then(() => createToast("Reading was deleted", "success"))
          .catch((err) => {
            createToast(err.message, "error");
          })
      }
      locked={!!reading.locked}
      tooltip={!!reading.locked ? lockedReason(reading.locked) : undefined}
    >
      Delete
    </ButtonWithConfirmation>
  );
};

export default DeleteReadingButton;
