import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import React, { FC } from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import ProductCopy from "../ui/ProductCopy";

interface Props {}

const NoTransactions: FC<Props> = () => {
  return (
    <PlaceholderContainer>
      <PlaceholderSegment
        icon={MonetizationOnIcon}
        header={`No ${ProductCopy.TRANSACTIONS}`}
        subheader={`No ${ProductCopy.TRANSACTIONS} have been activated for this account. Send a request
          to support@energyrm.com`}
      />
    </PlaceholderContainer>
  );
};

export default NoTransactions;
