import { useQuery } from "@apollo/client";
import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useSessionWithUser } from "../SessionWithUser";
import { TransactionsQueryDocument } from "../TransactionsList/TransactionsQuery.generated";
import Loading from "../ui/Loading";
import ProductCopy from "../ui/ProductCopy";
import { PageTitle } from "../ui/Typography";
import NewTransactionButton from "./NewTransactionButton";
import NoTransactions from "./NoTransactions";
import TransactionCard from "./TransactionCard";

interface Props {}

const TransactionsList: FC<Props> = () => {
  const { organization } = useSessionWithUser();
  const { data, loading, error } = useQuery(TransactionsQueryDocument, {
    variables: { orgId: organization.id },
    // TODO: we were getting cache issues on address going to and from the DM list
    fetchPolicy: "network-only",
  });

  if (error) throw error;
  if (loading || !data) return <Loading variant="circle" />;

  return (
    <Box>
      <Box pb={1} display="flex" justifyContent="space-between">
        <PageTitle>{ProductCopy.TRANSACTIONS}</PageTitle>
        <NewTransactionButton />
      </Box>
      {data.organization.transactions.edges.length > 0 ? (
        <Grid container spacing={2}>
          {data.organization.transactions.edges.map(({ node }) => (
            <TransactionCard transaction={node} key={node.id} />
          ))}
        </Grid>
      ) : (
        <NoTransactions />
      )}
    </Box>
  );
};

export default TransactionsList;
