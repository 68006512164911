import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { useToastContext } from "../ui/ToastProvider";
import {
  DownloadUrlDocument,
  DownloadUrlQuery,
} from "./DownloadUrlQuery.generated";

type UseDocumentUrlResponse = {
  getUrl: (documentId: string, preview: boolean) => Promise<string>;
  downloadFile: (documentId: string) => Promise<void>;
};

/**
 * useDocumentDownload handles fetching a fresh download link for an organization document
 * the urls are presigned and expire, so it is useful to fetch a fresh link before use
 */
const useDocumentDownload = (): UseDocumentUrlResponse => {
  const client = useApolloClient();
  const createToast = useToastContext();

  const getUrl = useCallback(
    async (documentId: string, preview = false) => {
      const result = await client.query<DownloadUrlQuery>({
        query: DownloadUrlDocument,
        variables: { documentId, preview },
        fetchPolicy: "network-only",
      });

      if (!result.data) {
        throw result.error || "Error fetching document url";
      }
      return result.data.organizationDocument.file.url;
    },
    [client]
  );

  const downloadFile = useCallback(
    async (documentId: string) => {
      try {
        const url = await getUrl(documentId);
        window.open(url, "erm_download");
      } catch (e) {
        createToast(e.message, "error");
      }
    },
    [createToast, getUrl]
  );

  return { getUrl, downloadFile };
};

export default useDocumentDownload;
