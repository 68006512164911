import { BuildingAdjustedModel, BuildingCalibratedModel } from "../api/graphql";

// this model type gets around the fact that models from different query instances may have different props
type Model = {
  __typename:
    | BuildingAdjustedModel["__typename"]
    | BuildingCalibratedModel["__typename"];
  name?: string | null;
};

export const adjustedModelName = (name: string | null | undefined): string =>
  name || "EnergyRM Generated Projection";

export const calibratedModelName = (name: string | null | undefined) =>
  name || "EnergyRM Generated Calibration";

const modelName = (model: Model | null): string => {
  if (model?.__typename === "BuildingAdjustedModel") {
    return adjustedModelName(model?.name);
  }
  return calibratedModelName(model?.name);
};

export default modelName;
