import { useQuery } from "@apollo/client";
import {
  Box,
  Button as MUIButton,
  ButtonGroup,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { UserInviteModelFragment } from "../api/fragments/UserInviteModel.generated";
import { OrganizationRoleType } from "../api/graphql";
import assertFeature from "../lib/assertFeature";
import displayUserRole from "../lib/displayUserRole";
import { organizationUser } from "../lib/endpoints";
import sortAlpha from "../lib/sortAlpha";
import useDeleteEntity from "../lib/useDeleteEntity";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import Loading from "../ui/Loading";
import { TablePlaceholder } from "../ui/PlaceholderSegment";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle, SectionTitle } from "../ui/Typography";
import DeactivateUserConfirmation from "./DeactivateUserConfirmation";
import { DeleteOrganizationDocument } from "./DeleteOrganization.generated";
import DeleteUserInviteConfirmation from "./DeleteUserInviteConfirmation";
import EditUserDialog from "./EditUserDialog";
import NewUserDialog from "./NewUserDialog";
import ResendUserInviteConfirmation from "./ResendUserInviteConfirmation";
import { UsersDocument, UsersQuery } from "./UsersQuery.generated";

type User = UsersQuery["organization"]["users"]["edges"][number]["node"];

interface Props {
  id: string;
}

const StyledText = styled(Typography)(({ theme: { palette } }) => ({
  display: "inline-block",
  color: palette.primary.main,
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const Organization: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const createToast = useToastContext();
  const {
    isOrgAdmin,
    user,
    organization,
    setOrganization,
    refetch: refetchMe,
    isSuperuser,
  } = useSessionWithUser();
  const { data, loading, error, refetch } = useQuery(UsersDocument, {
    variables: { organizationId: id },
    notifyOnNetworkStatusChange: true,
  });
  const [newUserDialogOpen, setNewUserDialogOpen] = useState<boolean>(false);
  const [userToDeactivate, setUserToDeactivate] = useState<User | null>(null);
  const [
    inviteToResend,
    setInviteToResend,
  ] = useState<UserInviteModelFragment | null>(null);
  const [
    inviteToDelete,
    setInviteToDelete,
  ] = useState<UserInviteModelFragment | null>(null);
  const [userToModify, setUserToModify] = useState<User | null>(null);

  const [deleteOrganization] = useDeleteEntity(DeleteOrganizationDocument);

  if (error) throw error;
  if (loading || !data) return <Loading variant="circle" />;

  const users = data.organization.users.edges
    .map((e) => e.node)
    .sort((a, b) => sortAlpha(a.name, b.name));
  const invites = [...data.organization.userInvites].sort((a, b) =>
    sortAlpha(a.name, b.name)
  );

  const onOrgDelete = async () => {
    if (organization) {
      try {
        await deleteOrganization(
          { __typename: "Organization", id },
          { input: { organizationId: id } }
        );
        createToast(`Successfully deleted ${organization.name}`, "success");
        setOrganization(undefined);
        refetchMe();
        history.push("/");
      } catch (err) {
        createToast(err.message, "error");
      }
    }
  };

  // keeping these permissions explicit here to support an easy transition
  // to an ✨RBAC✨
  const userCanInvite = isOrgAdmin;

  const canModifyUser = (u: User): boolean =>
    isOrgAdmin && u.id !== user.id && u.role !== OrganizationRoleType.OWNER;

  return (
    <>
      <Box pb={1}>
        <PageTitle>{data.organization.name} Users</PageTitle>
      </Box>
      {newUserDialogOpen && (
        <NewUserDialog
          organizationId={id}
          onCancel={() => setNewUserDialogOpen(false)}
          onComplete={() => {
            refetch();
            setNewUserDialogOpen(false);
          }}
        />
      )}
      {userToDeactivate && (
        <DeactivateUserConfirmation
          userId={userToDeactivate.id}
          organizationId={data.organization.id}
          name={userToDeactivate.name}
          onClose={async () => {
            await refetch().then(() => setUserToDeactivate(null));
          }}
        />
      )}
      {inviteToResend && (
        <ResendUserInviteConfirmation
          userInviteId={inviteToResend.id}
          email={inviteToResend.email}
          name={inviteToResend.name}
          onClose={() => setInviteToResend(null)}
          onError={refetch}
        />
      )}
      {inviteToDelete && (
        <DeleteUserInviteConfirmation
          userInviteId={inviteToDelete.id}
          email={inviteToDelete.email}
          name={inviteToDelete.name}
          onClose={() => setInviteToDelete(null)}
        />
      )}
      {userToModify && (
        <EditUserDialog
          organizationId={id}
          userId={userToModify.id}
          onCancel={() => setUserToModify(null)}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableWithInviteLink
            title="Users"
            onAdd={userCanInvite ? () => setNewUserDialogOpen(true) : undefined}
          >
            {users.map((u) => (
              <TableRow key={u.email}>
                <TableCell>
                  <Typography>{u.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{u.email}</Typography>
                </TableCell>
                <TableCell>
                  {canModifyUser(u) && u.role !== OrganizationRoleType.OWNER ? (
                    <StyledText onClick={() => setUserToModify(u)}>
                      {u.role ? displayUserRole(u.role) : "—"}
                    </StyledText>
                  ) : (
                    <Typography>
                      {u.role ? displayUserRole(u.role) : "—"}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  <ButtonGroup
                    size="small"
                    color="primary"
                    variant="outlined"
                    aria-label={`actions for user ${u.name}`}
                  >
                    {canModifyUser(u) && (
                      <MUIButton onClick={() => setUserToDeactivate(u)}>
                        Deactivate
                      </MUIButton>
                    )}
                    {isOrgAdmin && u.role === OrganizationRoleType.MEMBER && (
                      <MUIButton
                        onClick={() =>
                          history.push(
                            organizationUser({
                              organizationId: organization.id,
                              userId: u.id,
                            })
                          )
                        }
                      >
                        Manage
                      </MUIButton>
                    )}
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableWithInviteLink>
        </Grid>

        {invites.length > 0 && (
          <Grid item xs={12}>
            <TableWithInviteLink
              title="Invited Users"
              subtitle="Invitations have been sent to the following future users."
            >
              {invites.map((u) => (
                <TableRow key={u.email}>
                  <TableCell>
                    <Typography>{u.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{u.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{displayUserRole(u.role)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {userCanInvite ? (
                      <ButtonGroup
                        size="small"
                        color="primary"
                        variant="outlined"
                        aria-label={`actions for ${u.name}'s invitation`}
                      >
                        <MUIButton onClick={() => setInviteToResend(u)}>
                          Resend
                        </MUIButton>
                        <MUIButton onClick={() => setInviteToDelete(u)}>
                          Remove
                        </MUIButton>
                      </ButtonGroup>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableWithInviteLink>
          </Grid>
        )}

        {isSuperuser && assertFeature("DELETE_ORGS") && (
          <Grid item xs={12}>
            <ButtonWithConfirmation
              header="Delete this organization?"
              description="Deletes the organization with all buildings, meters, readings, and user permissions. This cannot be undone!"
              onClick={onOrgDelete}
            >
              Delete Org
            </ButtonWithConfirmation>
          </Grid>
        )}
      </Grid>
    </>
  );
};

type TableWithInviteLinkProps = {
  title: string;
  subtitle?: string;
  onAdd?: () => void;
};

const TableWithInviteLink: React.FC<TableWithInviteLinkProps> = ({
  title,
  subtitle,
  onAdd,
  children,
}) => {
  return (
    <Paper variant="outlined">
      <Box p={2}>
        <SectionTitle>{title}</SectionTitle>
        {subtitle && <Typography>{subtitle}</Typography>}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell align="right">
                {onAdd && (
                  <Button size="small" onClick={() => onAdd()}>
                    Add
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(children) && children.length === 0 ? (
              <TablePlaceholder
                colSpan={4}
                subheader={`There are no ${title.toLowerCase()}.`}
              />
            ) : (
              children
            )}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default Organization;
