export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Query = {
  __typename: 'Query';
  building: Building;
  portfolio: Portfolio;
  adjustedModel: BuildingAdjustedModel;
  calibratedModel: BuildingCalibratedModel;
  deltaMeter: DeltaMeter;
  deltaMeterReading: DeltaMeterReading;
  deltaMeterStatement: DeltaMeterStatement;
  organizationDocument: OrganizationDocument;
  financialModel: FinancialModel;
  organization: Organization;
  organizations: OrganizationConnection;
  transaction: Transaction;
  me?: Maybe<User>;
  user: User;
  userInvite?: Maybe<UserInvite>;
  utilityMeter: UtilityMeter;
  utilityMeterReading: UtilityMeterReading;
  reportsZip?: Maybe<Scalars['String']>;
  uploadUrl: SignedUrlResponse;
  weather: Array<Weather>;
};


export type QueryBuildingArgs = {
  id: Scalars['ID'];
};


export type QueryPortfolioArgs = {
  id: Scalars['ID'];
};


export type QueryAdjustedModelArgs = {
  id: Scalars['ID'];
};


export type QueryCalibratedModelArgs = {
  id: Scalars['ID'];
};


export type QueryDeltaMeterArgs = {
  id: Scalars['ID'];
};


export type QueryDeltaMeterReadingArgs = {
  id: Scalars['ID'];
};


export type QueryDeltaMeterStatementArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryFinancialModelArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserInviteArgs = {
  id?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
};


export type QueryUtilityMeterArgs = {
  id: Scalars['ID'];
};


export type QueryUtilityMeterReadingArgs = {
  id: Scalars['ID'];
};


export type QueryWeatherArgs = {
  location: Scalars['String'];
  startOn: Scalars['Date'];
  endOn: Scalars['Date'];
};

export type Mutation = {
  __typename: 'Mutation';
  createBuilding: CreateBuildingResponse;
  deleteBuilding: DeleteBuildingResponse;
  updateBuilding: UpdateBuildingResponse;
  importBuilding: ImportBuildingResponse;
  createBuildingPermission: CreateBuildingPermissionResponse;
  createBuildingPermissions: CreateBuildingPermissionsResponse;
  deleteBuildingPermission: DeleteBuildingPermissionResponse;
  updateBuildingPermission: UpdateBuildingPermissionResponse;
  createPortfolio: CreatePortfolioResponse;
  setDefaultBuildingAdjustedModel: SetDefaultBuildingAdjustedModelResult;
  createBuildingAdjustedModel: CreateBuildingAdjustedModelResponse;
  updateBuildingAdjustedModel: UpdateBuildingAdjustedModelResponse;
  deleteBuildingAdjustedModel: DeleteBuildingAdjustedModelResponse;
  reviseBuildingCalibratedModel: ReviseBuildingCalibratedModelResponse;
  updateBuildingCalibratedModel: UpdateBuildingCalibratedModelResponse;
  deleteBuildingCalibratedModel: DeleteBuildingCalibratedModelResponse;
  generateBuildingModels: GenerateBuildingModelsResponse;
  createDeltaMeter: CreateDeltaMeterResponse;
  activateDeltaMeter: ActivateDeltaMeterResponse;
  deactivateDeltaMeter: DeactivateDeltaMeterResponse;
  deleteDeltaMeter: DeleteDeltaMeterResponse;
  createDeltaMeterAdjustment: CreateDeltaMeterAdjustmentResponse;
  updateDeltaMeterAdjustment: UpdateDeltaMeterAdjustmentResponse;
  deleteDeltaMeterAdjustment: DeleteDeltaMeterAdjustmentResponse;
  createApprovedDeltaMeterStatement: CreateApprovedDeltaMeterStatementResponse;
  updateDeltaMeterStatement: UpdateDeltaMeterStatementResponse;
  voidDeltaMeterStatement: VoidDeltaMeterStatementResponse;
  deleteDeltaMeterStatement: DeleteDeltaMeterStatementResponse;
  createOrganizationDocument: CreateOrganizationDocumentResponse;
  updateOrganizationDocument: UpdateOrganizationDocumentResponse;
  deleteOrganizationDocument: DeleteOrganizationDocumentResponse;
  restoreOrganizationDocument: RestoreOrganizationDocumentResponse;
  setDocumentBuildings: SetDocumentBuildingsResponse;
  createUserDocumentPermissions: CreateUserDocumentPermissionsResponse;
  setDocumentUsers: SetDocumentUsersResponse;
  deleteFinancialModel: DeleteFinancialModelResponse;
  upsertFinancialModel: UpsertFinancialModelResponse;
  createOrganization: CreateOrganizationResponse;
  deleteOrganization: DeleteOrganizationResponse;
  createOrganizationPermission: CreateOrganizationPermissionResponse;
  updateOrganizationPermission: UpdateOrganizationPermissionResponse;
  deactivateOrganizationPermission: DeactivateOrganizationPermissionResponse;
  createTransaction: CreateTransactionResponse;
  updateTransaction: UpdateTransactionResponse;
  deleteTransaction: DeleteTransactionResponse;
  createUser: CreateUserResponse;
  updateUser: UpdateUserResponse;
  createUserInvite: CreateUserInviteResponse;
  updateUserInvite: UpdateUserInviteResponse;
  deleteUserInvite: DeleteUserInviteResponse;
  resendUserInvite: ResendUserInviteResponse;
  createUtilityMeter: CreateUtilityMeterResponse;
  deleteUtilityMeter: DeleteUtilityMeterResponse;
  updateUtilityMeter: UpdateUtilityMeterResponse;
  createUtilityMeterReading: CreateUtilityMeterReadingResponse;
  deleteUtilityMeterReading: DeleteUtilityMeterReadingResponse;
  updateUtilityMeterReading: UpdateUtilityMeterReadingResponse;
  createReferencePeriod: CreateReferencePeriodResponse;
};


export type MutationCreateBuildingArgs = {
  input: CreateBuildingInput;
};


export type MutationDeleteBuildingArgs = {
  input: DeleteBuildingInput;
};


export type MutationUpdateBuildingArgs = {
  input: UpdateBuildingInput;
};


export type MutationImportBuildingArgs = {
  input: ImportBuildingInput;
};


export type MutationCreateBuildingPermissionArgs = {
  input: CreateBuildingPermissionInput;
};


export type MutationCreateBuildingPermissionsArgs = {
  input: CreateBuildingPermissionsInput;
};


export type MutationDeleteBuildingPermissionArgs = {
  input: DeleteBuildingPermissionInput;
};


export type MutationUpdateBuildingPermissionArgs = {
  input: UpdateBuildingPermissionInput;
};


export type MutationCreatePortfolioArgs = {
  input: CreatePortfolioInput;
};


export type MutationSetDefaultBuildingAdjustedModelArgs = {
  input: SetDefaultBuildingAdjustedModelInput;
};


export type MutationCreateBuildingAdjustedModelArgs = {
  input: CreateBuildingAdjustedModelInput;
};


export type MutationUpdateBuildingAdjustedModelArgs = {
  input: UpdateBuildingAdjustedModelInput;
};


export type MutationDeleteBuildingAdjustedModelArgs = {
  input: DeleteBuildingAdjustedModelInput;
};


export type MutationReviseBuildingCalibratedModelArgs = {
  input: ReviseBuildingCalibratedModelInput;
};


export type MutationUpdateBuildingCalibratedModelArgs = {
  input: UpdateBuildingCalibratedModelInput;
};


export type MutationDeleteBuildingCalibratedModelArgs = {
  input: DeleteBuildingCalibratedModelInput;
};


export type MutationGenerateBuildingModelsArgs = {
  input: GenerateBuildingModelsInput;
};


export type MutationCreateDeltaMeterArgs = {
  input: CreateDeltaMeterInput;
};


export type MutationActivateDeltaMeterArgs = {
  input: ActivateDeltaMeterInput;
};


export type MutationDeactivateDeltaMeterArgs = {
  input: DeactivateDeltaMeterInput;
};


export type MutationDeleteDeltaMeterArgs = {
  input: DeleteDeltaMeterInput;
};


export type MutationCreateDeltaMeterAdjustmentArgs = {
  input: CreateDeltaMeterAdjustmentInput;
};


export type MutationUpdateDeltaMeterAdjustmentArgs = {
  input: UpdateDeltaMeterAdjustmentInput;
};


export type MutationDeleteDeltaMeterAdjustmentArgs = {
  input: DeleteDeltaMeterAdjustmentInput;
};


export type MutationCreateApprovedDeltaMeterStatementArgs = {
  input: CreateApprovedDeltaMeterStatementInput;
};


export type MutationUpdateDeltaMeterStatementArgs = {
  input: UpdateDeltaMeterStatementInput;
};


export type MutationVoidDeltaMeterStatementArgs = {
  input: VoidDeltaMeterStatementInput;
};


export type MutationDeleteDeltaMeterStatementArgs = {
  input: DeleteDeltaMeterStatementInput;
};


export type MutationCreateOrganizationDocumentArgs = {
  input: CreateOrganizationDocumentInput;
};


export type MutationUpdateOrganizationDocumentArgs = {
  input: UpdateOrganizationDocumentInput;
};


export type MutationDeleteOrganizationDocumentArgs = {
  input: DeleteOrganizationDocumentInput;
};


export type MutationRestoreOrganizationDocumentArgs = {
  input: RestoreOrganizationDocumentInput;
};


export type MutationSetDocumentBuildingsArgs = {
  input: SetDocumentBuildingsInput;
};


export type MutationCreateUserDocumentPermissionsArgs = {
  input: CreateUserDocumentPermissionsInput;
};


export type MutationSetDocumentUsersArgs = {
  input: SetDocumentUsersInput;
};


export type MutationDeleteFinancialModelArgs = {
  input: DeleteFinancialModelInput;
};


export type MutationUpsertFinancialModelArgs = {
  input: UpsertFinancialModelInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationCreateOrganizationPermissionArgs = {
  input: CreateOrganizationPermissionInput;
};


export type MutationUpdateOrganizationPermissionArgs = {
  input: UpdateOrganizationPermissionInput;
};


export type MutationDeactivateOrganizationPermissionArgs = {
  input: DeactivateOrganizationPermissionInput;
};


export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


export type MutationUpdateTransactionArgs = {
  input: UpdateTransactionInput;
};


export type MutationDeleteTransactionArgs = {
  input: DeleteTransactionInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationCreateUserInviteArgs = {
  input: CreateUserInviteInput;
};


export type MutationUpdateUserInviteArgs = {
  input: UpdateUserInviteInput;
};


export type MutationDeleteUserInviteArgs = {
  input: DeleteUserInviteInput;
};


export type MutationResendUserInviteArgs = {
  input: ResendUserInviteInput;
};


export type MutationCreateUtilityMeterArgs = {
  input: CreateUtilityMeterInput;
};


export type MutationDeleteUtilityMeterArgs = {
  input: DeleteUtilityMeterInput;
};


export type MutationUpdateUtilityMeterArgs = {
  input: UpdateUtilityMeterInput;
};


export type MutationCreateUtilityMeterReadingArgs = {
  input: CreateUtilityMeterReadingInput;
};


export type MutationDeleteUtilityMeterReadingArgs = {
  input: DeleteUtilityMeterReadingInput;
};


export type MutationUpdateUtilityMeterReadingArgs = {
  input: UpdateUtilityMeterReadingInput;
};


export type MutationCreateReferencePeriodArgs = {
  input: CreateReferencePeriodInput;
};

export enum BuildingAccessType {
  READ = 'READ',
  WRITE = 'WRITE'
}

export enum BuildingProductStep {
  NONE = 'NONE',
  ENERGY_MODELS = 'ENERGY_MODELS',
  FINANCIAL_MODELS = 'FINANCIAL_MODELS',
  DELTA_METER = 'DELTA_METER',
  TRANSACTION = 'TRANSACTION'
}

export enum BuildingUseType {
  ADULT_EDUCATION = 'ADULT_EDUCATION',
  AUTOMOBILE_DEALERSHIP = 'AUTOMOBILE_DEALERSHIP',
  BANK_BRANCH = 'BANK_BRANCH',
  BAR_OR_NIGHTCLUB = 'BAR_OR_NIGHTCLUB',
  BARRACKS = 'BARRACKS',
  COLLEGE_OR_UNIVERSITY = 'COLLEGE_OR_UNIVERSITY',
  CONVENIENCE_STORE_WITH_GAS_STATION = 'CONVENIENCE_STORE_WITH_GAS_STATION',
  CONVENIENCE_STORE_WITHOUT_GAS_STATION = 'CONVENIENCE_STORE_WITHOUT_GAS_STATION',
  CONVENTION_CENTER = 'CONVENTION_CENTER',
  COURTHOUSE = 'COURTHOUSE',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  ENCLOSED_MALL = 'ENCLOSED_MALL',
  FAST_FOOD_RESTAURANT = 'FAST_FOOD_RESTAURANT',
  FINANCIAL_OFFICE = 'FINANCIAL_OFFICE',
  FIRE_STATION = 'FIRE_STATION',
  GROCERY_STORE = 'GROCERY_STORE',
  HOSPITAL_GENERAL = 'HOSPITAL_GENERAL',
  HOSPITAL_OTHER = 'HOSPITAL_OTHER',
  HOSPITAL_SPECIALTY = 'HOSPITAL_SPECIALTY',
  HOSPITAL_SURGICAL = 'HOSPITAL_SURGICAL',
  HOTEL = 'HOTEL',
  K12_SCHOOL = 'K12_SCHOOL',
  LABORATORY = 'LABORATORY',
  LIBRARY = 'LIBRARY',
  LIFESTYLE_CENTER = 'LIFESTYLE_CENTER',
  MAILING_CENTER_OR_POST_OFFICE = 'MAILING_CENTER_OR_POST_OFFICE',
  MEDICAL_OFFICE = 'MEDICAL_OFFICE',
  MULTIFAMILY_HOUSING = 'MULTIFAMILY_HOUSING',
  NONREFRIGERATED_WAREHOUSE = 'NONREFRIGERATED_WAREHOUSE',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
  OTHER_RESTAURANT_OR_BAR = 'OTHER_RESTAURANT_OR_BAR',
  OUTPATIENT_REHAB_OR_PHYSICAL_THERAPY = 'OUTPATIENT_REHAB_OR_PHYSICAL_THERAPY',
  POLICE_STATION = 'POLICE_STATION',
  PRESCHOOL_OR_DAYCARE = 'PRESCHOOL_OR_DAYCARE',
  PRISON_OR_INCARCERATION = 'PRISON_OR_INCARCERATION',
  REFRIGERATED_WAREHOUSE = 'REFRIGERATED_WAREHOUSE',
  RESIDENCE_HALL_OR_DORMITORY = 'RESIDENCE_HALL_OR_DORMITORY',
  RESIDENTIAL_CARE_FACILITY = 'RESIDENTIAL_CARE_FACILITY',
  RESTAURANT = 'RESTAURANT',
  RETAIL_STORE = 'RETAIL_STORE',
  SELFSTORAGE_FACILITY = 'SELFSTORAGE_FACILITY',
  SENIOR_CARE_COMMUNITY = 'SENIOR_CARE_COMMUNITY',
  SOCIAL_OR_MEETING_HALL = 'SOCIAL_OR_MEETING_HALL',
  STRIP_MALL = 'STRIP_MALL',
  TECHNOLOGY_OR_SCIENCE = 'TECHNOLOGY_OR_SCIENCE',
  TRANSPORTATION_TERMINAL_OR_STATION = 'TRANSPORTATION_TERMINAL_OR_STATION',
  URGENT_CARE_OR_CLINIC_OR_OTHER_OUTPATIENT = 'URGENT_CARE_OR_CLINIC_OR_OTHER_OUTPATIENT',
  VETERINARY_OFFICE = 'VETERINARY_OFFICE',
  VOCATIONAL_SCHOOL = 'VOCATIONAL_SCHOOL',
  WHOLESALE_CLUB_OR_SUPERCENTER = 'WHOLESALE_CLUB_OR_SUPERCENTER'
}

/** WeatherSource indicates what type of weather is used for default model calculations */
export enum WeatherSource {
  /** actual weather from the reference period */
  ACTUAL = 'ACTUAL',
  /** normalized weather from NOAA's 30-year normals */
  NORMAL = 'NORMAL'
}

export type Building = {
  __typename: 'Building';
  id: Scalars['ID'];
  shortId: Scalars['String'];
  organization: Organization;
  aggregateMeters: Array<AggregateMeter>;
  utilityMeters: Array<UtilityMeter>;
  calibratedModels: Array<BuildingCalibratedModel>;
  calibratedModel?: Maybe<BuildingCalibratedModel>;
  documents: DocumentConnection;
  financialModels: Array<FinancialModel>;
  /** a list of rates for each _successfully modeled_ energy source at the building. */
  rates: Array<EnergyRate>;
  referencePeriods: Array<ReferencePeriod>;
  referenceWeather: Array<WeatherInterval>;
  name: Scalars['String'];
  yearBuilt?: Maybe<Scalars['Int']>;
  buildingUseType: BuildingUseType;
  completedSteps: Array<BuildingProductStep>;
  squareFeet: Scalars['Int'];
  externalId?: Maybe<Scalars['String']>;
  leaseType?: Maybe<LeaseType>;
  majorTenant?: Maybe<Scalars['String']>;
  address: PostalAddress;
  loadFile?: Maybe<Scalars['String']>;
  /** the access level that the requesting user has to the building */
  access?: Maybe<BuildingAccessType>;
  deltaMeter?: Maybe<DeltaMeter>;
  permissions: Array<BuildingPermission>;
  transaction?: Maybe<Transaction>;
  weatherSource: WeatherSource;
  createdAt: Scalars['Date'];
};


export type BuildingCalibratedModelArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type BuildingDocumentsArgs = {
  deleted?: Maybe<Scalars['Boolean']>;
};

export type PostalAddress = {
  __typename: 'PostalAddress';
  streetAddress: Scalars['String'];
  locality: Scalars['String'];
  region: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  timeZone: Scalars['String'];
};

export type Portfolio = {
  __typename: 'Portfolio';
  id: Scalars['ID'];
  name: Scalars['String'];
  buildings: BuildingConnection;
};

export type BuildingPermission = {
  __typename: 'BuildingPermission';
  id: Scalars['ID'];
  user: User;
  building: Building;
  access: BuildingAccessType;
};

export type BuildingConnection = {
  __typename: 'BuildingConnection';
  pageInfo: PageInfo;
  edges: Array<BuildingEdge>;
  count: Scalars['Int'];
  countByBuildingUseType: Array<BuildingConnection_CountByBuildingUseType>;
  squareFeet: Scalars['Int'];
};

export type BuildingConnection_CountByBuildingUseType = {
  __typename: 'BuildingConnection_CountByBuildingUseType';
  count: Scalars['Int'];
  buildingUseType: BuildingUseType;
};

export type BuildingEdge = {
  __typename: 'BuildingEdge';
  cursor: Scalars['String'];
  node: Building;
};

export type PortfolioConnection = {
  __typename: 'PortfolioConnection';
  pageInfo: PageInfo;
  edges: Array<PortfolioEdge>;
};

export type PortfolioEdge = {
  __typename: 'PortfolioEdge';
  cursor: Scalars['String'];
  node: Portfolio;
};

export type CreateBuildingInput = {
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  squareFeet: Scalars['Int'];
  buildingUseType: BuildingUseType;
  streetAddress: Scalars['String'];
  /** Name of city or town */
  locality: Scalars['String'];
  /** Two letter region abbreviation */
  region: Scalars['String'];
  /** Zip code */
  postalCode: Scalars['String'];
  /** Only US & CA supported */
  country: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  leaseType?: Maybe<LeaseType>;
  majorTenant?: Maybe<Scalars['String']>;
  yearBuilt?: Maybe<Scalars['Int']>;
};

export type CreateBuildingResponse = {
  __typename: 'CreateBuildingResponse';
  building: Building;
};

export type DeleteBuildingInput = {
  buildingId: Scalars['ID'];
};

export type DeleteBuildingResponse = {
  __typename: 'DeleteBuildingResponse';
  building: Building;
};

export type UpdateBuildingInput = {
  buildingId: Scalars['ID'];
  buildingUseType?: Maybe<BuildingUseType>;
  externalId?: Maybe<Scalars['String']>;
  leaseType?: Maybe<LeaseType>;
  majorTenant?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  squareFeet?: Maybe<Scalars['Int']>;
  yearBuilt?: Maybe<Scalars['Int']>;
  streetAddress?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UpdateBuildingResponse = {
  __typename: 'UpdateBuildingResponse';
  building: Building;
};

export type CreateBuildingPermissionInput = {
  access: BuildingAccessType;
  buildingId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateBuildingPermissionResponse = {
  __typename: 'CreateBuildingPermissionResponse';
  buildingPermission: BuildingPermission;
};

export type CreateBuildingPermissionsInput = {
  access: BuildingAccessType;
  buildingIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type CreateBuildingPermissionsResponse = {
  __typename: 'CreateBuildingPermissionsResponse';
  /** list of the newly created permission */
  buildingPermissions: Array<BuildingPermission>;
  user: User;
};

export type DeleteBuildingPermissionInput = {
  buildingId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteBuildingPermissionResponse = {
  __typename: 'DeleteBuildingPermissionResponse';
  buildingPermission: BuildingPermission;
};

export type UpdateBuildingPermissionInput = {
  access: BuildingAccessType;
  buildingId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UpdateBuildingPermissionResponse = {
  __typename: 'UpdateBuildingPermissionResponse';
  buildingPermission: BuildingPermission;
};

export type CreatePortfolioInput = {
  buildingIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreatePortfolioResponse = {
  __typename: 'CreatePortfolioResponse';
  portfolio: Portfolio;
};

export type SetDefaultBuildingAdjustedModelInput = {
  adjustedModelId: Scalars['ID'];
  buildingId: Scalars['ID'];
};

export type SetDefaultBuildingAdjustedModelResult = {
  __typename: 'SetDefaultBuildingAdjustedModelResult';
  adjustedModel: BuildingAdjustedModel;
};

export type ImportBuildingInput = {
  organizationId: Scalars['String'];
  name: Scalars['String'];
  squareFeet: Scalars['Int'];
  buildingUseType: BuildingUseType;
  streetAddress: Scalars['String'];
  locality: Scalars['String'];
  region: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  leaseType?: Maybe<LeaseType>;
  majorTenant?: Maybe<Scalars['String']>;
  yearBuilt?: Maybe<Scalars['Int']>;
  utilityMeters: Array<ImportBuildingInput_UtilityMeter>;
};

export type ImportBuildingInput_UtilityMeter = {
  externalId: Scalars['String'];
  energySource: EnergySource;
  unit: EnergyUnitType;
  utilityName?: Maybe<Scalars['String']>;
  meterSource?: Maybe<MeterSource>;
  utilityMeterReadings: Array<ImportBuildingInput_UtilityMeterReading>;
};

export type ImportBuildingInput_UtilityMeterReading = {
  startOn: Scalars['Date'];
  endOn: Scalars['Date'];
  quantity: Scalars['Float'];
  unit: EnergyUnitType;
  cost?: Maybe<MoneyInput>;
};

export type ImportBuildingResponse = {
  __typename: 'ImportBuildingResponse';
  building?: Maybe<Building>;
};

export type BuildingAdjustedModel = {
  __typename: 'BuildingAdjustedModel';
  id: Scalars['ID'];
  buildingModel: BuildingModel;
  buildingCalibratedModel: BuildingCalibratedModel;
  default: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  system: Scalars['Boolean'];
  annualizedEndUses: Array<EnergySourceEndUse>;
  annualizedEnergy: Energy;
  annualizedCost: Money;
  /**
   * adjusted model's EE opportunity.
   * optional `WeatherSource` argument changes the weather data used to calculate the opportunity.
   * defaults to building's configured WeatherSource.
   */
  opportunity: BuildingAdjustedModel_Opportunity;
  temperaturePerformance: Array<BuildingModelTemperaturePerformance>;
  seasonalPerformance: Array<BuildingModelSeasonalPerformance>;
  parameters: ModelParameters;
  adjustments?: Maybe<BuildingAdjustedModel_Adjustments>;
  createdAt: Scalars['Date'];
};


export type BuildingAdjustedModelOpportunityArgs = {
  weatherSource?: Maybe<WeatherSource>;
};

export type BuildingAdjustedModel_Adjustments = {
  __typename: 'BuildingAdjustedModel_Adjustments';
  lighting?: Maybe<Scalars['Float']>;
  heatingSetTemp?: Maybe<Scalars['Float']>;
  coolingSetTemp?: Maybe<Scalars['Float']>;
  spaceHeatingElectricPortion?: Maybe<Scalars['Float']>;
  spaceHeatingGasPortion?: Maybe<Scalars['Float']>;
  hotWaterElectricPortion?: Maybe<Scalars['Float']>;
  hotWaterGasPortion?: Maybe<Scalars['Float']>;
  shellHeatLoss?: Maybe<Scalars['Float']>;
  spaceHeatingElectric?: Maybe<Scalars['Float']>;
  spaceHeatingGas?: Maybe<Scalars['Float']>;
  spaceHeatingFuelOil2?: Maybe<Scalars['Float']>;
  spaceHeatingPropane?: Maybe<Scalars['Float']>;
  spaceCoolingElectric?: Maybe<Scalars['Float']>;
  spaceCoolingGas?: Maybe<Scalars['Float']>;
  spaceCoolingWater?: Maybe<Scalars['Float']>;
  hotWaterElectric?: Maybe<Scalars['Float']>;
  hotWaterGas?: Maybe<Scalars['Float']>;
  hotWaterFuelOil2?: Maybe<Scalars['Float']>;
  hotWaterPropane?: Maybe<Scalars['Float']>;
  hotWaterVolume?: Maybe<Scalars['Float']>;
  interiorElectric?: Maybe<Scalars['Float']>;
  exteriorElectric?: Maybe<Scalars['Float']>;
};

export type BuildingAdjustedModel_Opportunity = {
  __typename: 'BuildingAdjustedModel_Opportunity';
  byEnergySource: Array<EnergySourceOpportunity>;
  energy: Energy;
  energyDepth: Scalars['Float'];
  energyPerSquareFoot: Energy;
  money: Money;
  moneyDepth: Scalars['Float'];
  moneyPerSquareFoot: Money;
};

export type EnergySourceOpportunity = {
  __typename: 'EnergySourceOpportunity';
  energySource: EnergySource;
  energy: Energy;
  energyDepth: Scalars['Float'];
  money: Money;
  moneyDepth: Scalars['Float'];
};

export type CreateBuildingAdjustedModelInput = {
  buildingCalibratedModelId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  adjustments: BuildingAdjustedModel_AdjustmentsInput;
};

export type BuildingAdjustedModel_AdjustmentsInput = {
  lighting?: Maybe<Scalars['Float']>;
  heatingSetTemp?: Maybe<Scalars['Float']>;
  coolingSetTemp?: Maybe<Scalars['Float']>;
  spaceHeatingElectricPortion?: Maybe<Scalars['Float']>;
  spaceHeatingGasPortion?: Maybe<Scalars['Float']>;
  hotWaterElectricPortion?: Maybe<Scalars['Float']>;
  hotWaterGasPortion?: Maybe<Scalars['Float']>;
  shellHeatLoss?: Maybe<Scalars['Float']>;
  spaceHeatingElectric?: Maybe<Scalars['Float']>;
  spaceHeatingGas?: Maybe<Scalars['Float']>;
  spaceHeatingFuelOil2?: Maybe<Scalars['Float']>;
  spaceHeatingPropane?: Maybe<Scalars['Float']>;
  spaceCoolingElectric?: Maybe<Scalars['Float']>;
  spaceCoolingGas?: Maybe<Scalars['Float']>;
  spaceCoolingWater?: Maybe<Scalars['Float']>;
  hotWaterElectric?: Maybe<Scalars['Float']>;
  hotWaterGas?: Maybe<Scalars['Float']>;
  hotWaterFuelOil2?: Maybe<Scalars['Float']>;
  hotWaterPropane?: Maybe<Scalars['Float']>;
  hotWaterVolume?: Maybe<Scalars['Float']>;
  interiorElectric?: Maybe<Scalars['Float']>;
  exteriorElectric?: Maybe<Scalars['Float']>;
};

export type CreateBuildingAdjustedModelResponse = {
  __typename: 'CreateBuildingAdjustedModelResponse';
  buildingAdjustedModel: BuildingAdjustedModel;
};

export type UpdateBuildingAdjustedModelInput = {
  buildingAdjustedModelId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateBuildingAdjustedModelResponse = {
  __typename: 'UpdateBuildingAdjustedModelResponse';
  buildingAdjustedModel: BuildingAdjustedModel;
};

export type DeleteBuildingAdjustedModelInput = {
  buildingAdjustedModelId: Scalars['ID'];
};

export type DeleteBuildingAdjustedModelResponse = {
  __typename: 'DeleteBuildingAdjustedModelResponse';
  buildingAdjustedModel?: Maybe<BuildingAdjustedModel>;
};

export enum BuildingModelFit {
  GOOD = 'GOOD',
  MODERATE = 'MODERATE',
  POOR = 'POOR'
}

export type BuildingCalibratedModel = {
  __typename: 'BuildingCalibratedModel';
  id: Scalars['ID'];
  buildingModel: BuildingModel;
  building: Building;
  default: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  fit: BuildingModelFit;
  score: Scalars['Float'];
  scoreStats: Array<BuildingCalibratedModel_EnergySourceScore>;
  system: Scalars['Boolean'];
  reference: Array<BuildingModelReference>;
  referencePeriod: ReferencePeriod;
  financialModels: Array<FinancialModel>;
  annualizedCost: Money;
  annualizedEndUses: Array<EnergySourceEndUse>;
  annualizedEnergy: Energy;
  /** aka EUI */
  annualizedEnergyPerSquareFoot: Energy;
  annualizedEnergySources: Array<SourceEnergy>;
  temperaturePerformance: Array<BuildingModelTemperaturePerformance>;
  seasonalPerformance: Array<BuildingModelSeasonalPerformance>;
  adjustedModels: Array<BuildingAdjustedModel>;
  adjustedModel?: Maybe<BuildingAdjustedModel>;
  parameters: ModelParameters;
  overrides?: Maybe<BuildingCalibratedModel_Overrides>;
  weatherStation: Scalars['String'];
  createdAt: Scalars['Date'];
};


export type BuildingCalibratedModelAdjustedModelArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type BuildingModelReference = {
  __typename: 'BuildingModelReference';
  interval: Interval;
  airTemp: Scalars['Float'];
  energySource: EnergySource;
  billed: Energy;
  modeled: Energy;
  unbilled: Energy;
};

export type BuildingCalibratedModel_EnergySourceScore = {
  __typename: 'BuildingCalibratedModel_EnergySourceScore';
  energySource: EnergySource;
  r2: Scalars['Float'];
  cvrmse: Scalars['Float'];
  nmbe: Scalars['Float'];
  yMean: Scalars['Float'];
};

export type BuildingCalibratedModel_Overrides = {
  __typename: 'BuildingCalibratedModel_Overrides';
  lighting?: Maybe<Scalars['Float']>;
  hotWater?: Maybe<BuildingCalibratedModel_Overrides_HotWater>;
  baseload?: Maybe<BuildingCalibratedModel_Overrides_Baseload>;
  spaceHeating?: Maybe<BuildingCalibratedModel_Overrides_SpaceHeating>;
};

export type BuildingCalibratedModel_Overrides_HotWater = {
  __typename: 'BuildingCalibratedModel_Overrides_HotWater';
  setTemp?: Maybe<Scalars['Float']>;
};

export type BuildingCalibratedModel_Overrides_Baseload = {
  __typename: 'BuildingCalibratedModel_Overrides_Baseload';
  exterior?: Maybe<BuildingCalibratedModel_Overrides_Baseload_Exterior>;
};

export type BuildingCalibratedModel_Overrides_Baseload_Exterior = {
  __typename: 'BuildingCalibratedModel_Overrides_Baseload_Exterior';
  electricity?: Maybe<Scalars['Float']>;
};

export type BuildingCalibratedModel_Overrides_SpaceHeating = {
  __typename: 'BuildingCalibratedModel_Overrides_SpaceHeating';
  fanPortion?: Maybe<Scalars['Float']>;
};

export type ReviseBuildingCalibratedModelInput = {
  calibratedModelId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  overrides: CalibrationOverrides;
};

export type CalibrationOverrides = {
  lighting?: Maybe<Scalars['Float']>;
  hotWater?: Maybe<CalibrationOverrides_HotWater>;
  baseload?: Maybe<CalibrationOverrides_Baseload>;
  spaceHeating?: Maybe<CalibrationOverrides_SpaceHeating>;
};

export type CalibrationOverrides_HotWater = {
  setTemp?: Maybe<Scalars['Float']>;
};

export type CalibrationOverrides_Baseload = {
  exterior?: Maybe<CalibrationOverrides_Baseload_Exterior>;
};

export type CalibrationOverrides_Baseload_Exterior = {
  electricity?: Maybe<Scalars['Float']>;
};

export type CalibrationOverrides_SpaceHeating = {
  fanPortion?: Maybe<Scalars['Float']>;
};

export type ReviseBuildingCalibratedModelResponse = {
  __typename: 'ReviseBuildingCalibratedModelResponse';
  buildingCalibratedModel: BuildingCalibratedModel;
};

export type UpdateBuildingCalibratedModelInput = {
  buildingCalibratedModelId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateBuildingCalibratedModelResponse = {
  __typename: 'UpdateBuildingCalibratedModelResponse';
  buildingCalibratedModel: BuildingCalibratedModel;
};

export type DeleteBuildingCalibratedModelInput = {
  buildingCalibratedModelId: Scalars['ID'];
};

export type DeleteBuildingCalibratedModelResponse = {
  __typename: 'DeleteBuildingCalibratedModelResponse';
  buildingCalibratedModel?: Maybe<BuildingCalibratedModel>;
};

export type BuildingModel = {
  __typename: 'BuildingModel';
  id: Scalars['ID'];
  parameters: ModelParameters;
};

export type BuildingModelTemperaturePerformance = {
  __typename: 'BuildingModelTemperaturePerformance';
  airTemp: Scalars['Float'];
  endUses: Array<EnergySourceEndUse>;
};

export type BuildingModelSeasonalPerformance = {
  __typename: 'BuildingModelSeasonalPerformance';
  /** Month is described using UTC */
  month: Scalars['Date'];
  endUses: Array<EnergySourceEndUse>;
};

export type ModelParameters = {
  __typename: 'ModelParameters';
  hotWater: ModelParameters_HotWater;
  spaceHeating: ModelParameters_SpaceHeating;
  spaceCooling: ModelParameters_SpaceCooling;
  lighting: Scalars['Float'];
  baseload: ModelParameters_Baseload;
  shellHeatLoss: Scalars['Float'];
};

export type ModelParameters_HotWater = {
  __typename: 'ModelParameters_HotWater';
  setTemp: Scalars['Float'];
  volume: Scalars['Float'];
  pipeLoss: Scalars['Float'];
  sources: Array<EnergySourceContribution>;
  electricity?: Maybe<EndUseContribution>;
  fuelOil2?: Maybe<EndUseContribution>;
  naturalGas?: Maybe<EndUseContribution>;
  propane?: Maybe<EndUseContribution>;
};

export type ModelParameters_SpaceHeating = {
  __typename: 'ModelParameters_SpaceHeating';
  setTemp: Scalars['Float'];
  fanPortion: Scalars['Float'];
  sources: Array<EnergySourceContribution>;
  electricity?: Maybe<EndUseContribution>;
  fuelOil2?: Maybe<EndUseContribution>;
  naturalGas?: Maybe<EndUseContribution>;
  propane?: Maybe<EndUseContribution>;
};

export type ModelParameters_SpaceCooling = {
  __typename: 'ModelParameters_SpaceCooling';
  areaPortion: Scalars['Float'];
  setTemp: Scalars['Float'];
  performance: Scalars['Float'];
  sources: Array<EnergySourceContribution>;
  electricity?: Maybe<EndUseContribution>;
  chilledWater?: Maybe<EndUseContribution>;
  naturalGas?: Maybe<EndUseContribution>;
  propane?: Maybe<EndUseContribution>;
};

export type EndUseContribution = {
  __typename: 'EndUseContribution';
  portion: Scalars['Float'];
  performance: Scalars['Float'];
};

export type EnergySourceContribution = {
  __typename: 'EnergySourceContribution';
  energySource: EnergySource;
  portion: Scalars['Float'];
  performance: Scalars['Float'];
};

export type ModelParameters_Baseload = {
  __typename: 'ModelParameters_Baseload';
  interior?: Maybe<BaseloadContribution>;
  exterior?: Maybe<BaseloadContribution>;
};

export type BaseloadContribution = {
  __typename: 'BaseloadContribution';
  electricity?: Maybe<Scalars['Float']>;
  naturalGas?: Maybe<Scalars['Float']>;
  propane?: Maybe<Scalars['Float']>;
};

export type EnergySourceEndUse = {
  __typename: 'EnergySourceEndUse';
  energySource: EnergySource;
  endUse: EnergyEndUse;
  energy: Energy;
};

export type SourceEnergy = {
  __typename: 'SourceEnergy';
  energySource: EnergySource;
  energy: Energy;
};

export type GenerateBuildingModelsInput = {
  buildingId: Scalars['ID'];
};

export type GenerateBuildingModelsResponse = {
  __typename: 'GenerateBuildingModelsResponse';
  building: Building;
  calibratedModel: BuildingCalibratedModel;
};

export enum DeltaMeterState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum DeltaMeterAdjustmentReason {
  EQUIPMENT = 'EQUIPMENT',
  OCCUPANCY_CHANGE = 'OCCUPANCY_CHANGE',
  OUTAGE = 'OUTAGE',
  OTHER = 'OTHER'
}

export enum DeltaMeterStatementState {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  VOID = 'VOID'
}

export type DeltaMeter = {
  __typename: 'DeltaMeter';
  id: Scalars['ID'];
  shortId: Scalars['String'];
  building: Building;
  buildingCalibratedModel: BuildingCalibratedModel;
  buildingAdjustedModel?: Maybe<BuildingAdjustedModel>;
  startOn: Scalars['Date'];
  endOn?: Maybe<Scalars['Date']>;
  state: DeltaMeterState;
  statements: Array<DeltaMeterStatement>;
  lastStatementDate?: Maybe<Scalars['Date']>;
  activities: ActivityConnection;
  report: Array<DeltaMeterReportPeriod>;
  kpis: DeltaMeterKpis;
  weatherStation: Scalars['String'];
  readings: DeltaMeterReadingConnection;
};

export type DeltaMeterReading = {
  __typename: 'DeltaMeterReading';
  id: Scalars['ID'];
  deltaMeter: DeltaMeter;
  interval: Interval;
  energySource: EnergySource;
  /** baseline value adjusted for adjustments, if they exist */
  adjustedBaseline: Scalars['Float'];
  baseline: Scalars['Float'];
  tracker?: Maybe<Scalars['Float']>;
  actual: Scalars['Float'];
  unit: EnergyUnitType;
  airTemp: Scalars['Float'];
  adjustments: Array<DeltaMeterAdjustment>;
};

export type DeltaMeterAdjustment = {
  __typename: 'DeltaMeterAdjustment';
  id: Scalars['ID'];
  reading: DeltaMeterReading;
  quantity: Scalars['Float'];
  unit: EnergyUnitType;
  reason: DeltaMeterAdjustmentReason;
  notes?: Maybe<Scalars['String']>;
};

export type DeltaMeterStatement = {
  __typename: 'DeltaMeterStatement';
  id: Scalars['ID'];
  shortId?: Maybe<Scalars['String']>;
  state: DeltaMeterStatementState;
  date: Scalars['Date'];
  deltaMeter: DeltaMeter;
  reference?: Maybe<Scalars['Int']>;
  adjustments: Array<DeltaMeterAdjustment>;
  readings: Array<DeltaMeterReading>;
  createdAt: Scalars['Date'];
  /** PDF FileAsset of the statement */
  pdf?: Maybe<FileAsset>;
};

export type DeltaMeterKpis = {
  __typename: 'DeltaMeterKpis';
  /** Energy Efficiency to Date, in kBTU */
  energyEfficiency: Scalars['Float'];
  /** Energy Efficiency depth percentage as a decimal */
  energyEfficiencyDepth: Scalars['Float'];
  /** Mean Variance to Date */
  meanVariance: Scalars['Float'];
  /** Energy Efficiency to Date expected based on model projections, in kBTU */
  projectedEnergyEfficiency?: Maybe<Scalars['Float']>;
};

export type DeltaMeterConnection = {
  __typename: 'DeltaMeterConnection';
  pageInfo: PageInfo;
  edges: Array<DeltaMeterEdge>;
};

export type DeltaMeterEdge = {
  __typename: 'DeltaMeterEdge';
  cursor: Scalars['String'];
  node: DeltaMeter;
};

export type DeltaMeterReadingConnection = {
  __typename: 'DeltaMeterReadingConnection';
  pageInfo: PageInfo;
  edges: Array<DeltaMeterReadingEdge>;
};

export type DeltaMeterReadingEdge = {
  __typename: 'DeltaMeterReadingEdge';
  cursor: Scalars['String'];
  node: DeltaMeterReading;
};

export type ActivityConnection = {
  __typename: 'ActivityConnection';
  edges: Array<ActivityEdge>;
};

export type ActivityEdge = {
  __typename: 'ActivityEdge';
  node: Activity;
};

export enum ActivityVerb {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  APPROVED = 'APPROVED',
  VOIDED = 'VOIDED',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export type ActivityEntity = DeltaMeter | DeltaMeterStatement | DeltaMeterAdjustment | UtilityMeterReading;

export type Activity = {
  __typename: 'Activity';
  date: Scalars['Date'];
  entity: ActivityEntity;
  user?: Maybe<User>;
  verb: ActivityVerb;
};

export type DeltaMeterReportPeriod = {
  __typename: 'DeltaMeterReportPeriod';
  energySource: EnergySource;
  interval: Interval;
  forecast: DeltaMeterReportPeriod_Forecast;
  reading?: Maybe<DeltaMeterReading>;
  unit: EnergyUnitType;
};

export type DeltaMeterReportPeriod_Forecast = {
  __typename: 'DeltaMeterReportPeriod_Forecast';
  airTemp: Scalars['Float'];
  baseline: Scalars['Float'];
  tracker?: Maybe<Scalars['Float']>;
};

export type CreateDeltaMeterInput = {
  buildingCalibratedModelId: Scalars['ID'];
  buildingAdjustedModelId?: Maybe<Scalars['ID']>;
  startOn: Scalars['Date'];
  endOn?: Maybe<Scalars['Date']>;
};

export type CreateDeltaMeterResponse = {
  __typename: 'CreateDeltaMeterResponse';
  deltaMeter: DeltaMeter;
};

export type ActivateDeltaMeterInput = {
  deltaMeterId: Scalars['ID'];
};

export type ActivateDeltaMeterResponse = {
  __typename: 'ActivateDeltaMeterResponse';
  deltaMeter: DeltaMeter;
};

export type DeactivateDeltaMeterInput = {
  deltaMeterId: Scalars['ID'];
};

export type DeactivateDeltaMeterResponse = {
  __typename: 'DeactivateDeltaMeterResponse';
  deltaMeter: DeltaMeter;
};

export type DeleteDeltaMeterInput = {
  deltaMeterId: Scalars['ID'];
};

export type DeleteDeltaMeterResponse = {
  __typename: 'DeleteDeltaMeterResponse';
  deltaMeter: DeltaMeter;
};

export type CreateDeltaMeterAdjustmentInput = {
  deltaMeterReadingId: Scalars['ID'];
  quantity: Scalars['Float'];
  unit: EnergyUnitType;
  reason: DeltaMeterAdjustmentReason;
  notes?: Maybe<Scalars['String']>;
};

export type CreateDeltaMeterAdjustmentResponse = {
  __typename: 'CreateDeltaMeterAdjustmentResponse';
  deltaMeterAdjustment: DeltaMeterAdjustment;
};

export type UpdateDeltaMeterAdjustmentInput = {
  deltaMeterAdjustmentId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type UpdateDeltaMeterAdjustmentResponse = {
  __typename: 'UpdateDeltaMeterAdjustmentResponse';
  deltaMeterAdjustment: DeltaMeterAdjustment;
};

export type DeleteDeltaMeterAdjustmentInput = {
  deltaMeterAdjustmentId: Scalars['ID'];
};

export type DeleteDeltaMeterAdjustmentResponse = {
  __typename: 'DeleteDeltaMeterAdjustmentResponse';
  deltaMeterAdjustment: DeltaMeterAdjustment;
};

export type CreateApprovedDeltaMeterStatementInput = {
  deltaMeterId: Scalars['ID'];
  date: Scalars['Date'];
};

export type CreateApprovedDeltaMeterStatementResponse = {
  __typename: 'CreateApprovedDeltaMeterStatementResponse';
  deltaMeterStatement: DeltaMeterStatement;
};

export type UpdateDeltaMeterStatementInput = {
  deltaMeterStatementId: Scalars['ID'];
  pdf?: Maybe<FileAssetInput>;
};

export type UpdateDeltaMeterStatementResponse = {
  __typename: 'UpdateDeltaMeterStatementResponse';
  deltaMeterStatement: DeltaMeterStatement;
};

export type VoidDeltaMeterStatementInput = {
  deltaMeterStatementId: Scalars['ID'];
};

export type VoidDeltaMeterStatementResponse = {
  __typename: 'VoidDeltaMeterStatementResponse';
  deltaMeterStatement: DeltaMeterStatement;
};

export type DeleteDeltaMeterStatementInput = {
  deltaMeterStatementId: Scalars['ID'];
};

export type DeleteDeltaMeterStatementResponse = {
  __typename: 'DeleteDeltaMeterStatementResponse';
  id: Scalars['ID'];
};

export type OrganizationDocument = {
  __typename: 'OrganizationDocument';
  id: Scalars['ID'];
  file: FileAsset;
  buildings: BuildingConnection;
  organization: Organization;
  permissions: Array<DocumentPermission>;
  users: Array<User>;
  createdAt: Scalars['Date'];
  createdBy: User;
  /** date time of deletion. only populated if file has been trashed. */
  deletedAt?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
};

export type DocumentPermission = {
  __typename: 'DocumentPermission';
  id: Scalars['ID'];
  user: User;
};

export type DocumentConnection = {
  __typename: 'DocumentConnection';
  edges: Array<DocumentEdge>;
};

export type DocumentEdge = {
  __typename: 'DocumentEdge';
  node: OrganizationDocument;
};

export type CreateOrganizationDocumentInput = {
  organizationId: Scalars['ID'];
  file: FileAssetInput;
};

export type CreateOrganizationDocumentResponse = {
  __typename: 'CreateOrganizationDocumentResponse';
  document: OrganizationDocument;
};

export type UpdateOrganizationDocumentInput = {
  documentId: Scalars['ID'];
  file?: Maybe<FileAssetInput>;
  filename?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationDocumentResponse = {
  __typename: 'UpdateOrganizationDocumentResponse';
  document: OrganizationDocument;
};

export type DeleteOrganizationDocumentInput = {
  documentId: Scalars['ID'];
};

export type DeleteOrganizationDocumentResponse = {
  __typename: 'DeleteOrganizationDocumentResponse';
  document: OrganizationDocument;
};

export type RestoreOrganizationDocumentInput = {
  documentId: Scalars['ID'];
};

export type RestoreOrganizationDocumentResponse = {
  __typename: 'RestoreOrganizationDocumentResponse';
  document: OrganizationDocument;
};

export type SetDocumentBuildingsInput = {
  documentId: Scalars['ID'];
  buildingIds: Array<Scalars['ID']>;
};

export type SetDocumentBuildingsResponse = {
  __typename: 'SetDocumentBuildingsResponse';
  document: OrganizationDocument;
};

export type CreateUserDocumentPermissionsInput = {
  userId: Scalars['ID'];
  documentIds: Array<Scalars['ID']>;
};

export type CreateUserDocumentPermissionsResponse = {
  __typename: 'CreateUserDocumentPermissionsResponse';
  user: User;
};

export type SetDocumentUsersInput = {
  documentId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type SetDocumentUsersResponse = {
  __typename: 'SetDocumentUsersResponse';
  document: OrganizationDocument;
};

export type FinancialModel = {
  __typename: 'FinancialModel';
  id: Scalars['ID'];
  name: Scalars['String'];
  notes: Scalars['String'];
  calibratedModel: BuildingCalibratedModel;
  adjustedModel?: Maybe<BuildingAdjustedModel>;
  energyEfficiencyDepths: Array<FinancialModel_EnergyEfficiencyDepth>;
  /** % uncertainty for project outcome as a decimal */
  projectUncertainty: Scalars['Float'];
  discountRate: Scalars['Float'];
  projectCost: Money;
  /** term of project in years */
  term: Scalars['Int'];
  cashFlows: Array<FinancialModel_CashFlow>;
  weatherSource: WeatherSource;
  /**
   * custom user overrides for energy source rates.
   * if null, user is using the default rates.
   */
  customRates?: Maybe<Array<EnergyRate>>;
  defaultRates: Array<EnergyRate>;
  createdAt: Scalars['Date'];
  createdBy: User;
  updatedAt: Scalars['Date'];
  updatedBy: User;
};

export type FinancialModel_CashFlow = {
  __typename: 'FinancialModel_CashFlow';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  amount: Money;
  /** annual escalation rate as a decimal */
  escalation: Scalars['Float'];
};

export type FinancialModel_EnergyEfficiencyDepth = {
  __typename: 'FinancialModel_EnergyEfficiencyDepth';
  /** expected energy efficiency depth % for this energy source as a decimal */
  depth: Scalars['Float'];
  energySource: EnergySource;
};

export type UpsertFinancialModelInput = {
  /** id of model to update. a new model will be created if null. */
  id?: Maybe<Scalars['ID']>;
  calibratedModelId: Scalars['ID'];
  adjustedModelId?: Maybe<Scalars['ID']>;
  projectCost: MoneyInput;
  projectUncertainty: Scalars['Float'];
  /** % discount rate as a decimal */
  discountRate: Scalars['Float'];
  name: Scalars['String'];
  notes: Scalars['String'];
  term: Scalars['Int'];
  weatherSource: WeatherSource;
  cashFlows: Array<UpsertFinancialModel_CashFlowInput>;
  customRates?: Maybe<Array<EnergyRateInput>>;
  defaultRates: Array<EnergyRateInput>;
  energyEfficiencyDepths: Array<UpsertFinancialModel_DepthInput>;
};

export type UpsertFinancialModel_CashFlowInput = {
  name: Scalars['String'];
  amount: MoneyInput;
  escalation: Scalars['Float'];
};

export type UpsertFinancialModel_DepthInput = {
  depth: Scalars['Float'];
  energySource: EnergySource;
};

export type UpsertFinancialModelResponse = {
  __typename: 'UpsertFinancialModelResponse';
  financialModel: FinancialModel;
};

export type DeleteFinancialModelInput = {
  financialModelId: Scalars['ID'];
};

export type DeleteFinancialModelResponse = {
  __typename: 'DeleteFinancialModelResponse';
  financialModel: FinancialModel;
};

export type Organization = {
  __typename: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  /**
   * buildings for the organization
   * if optional ids array is provided only buildings user can access that exist in the org are returned
   * requesting ids of buildings that have been deleted or the user has no access to will not error.
   */
  buildings: BuildingConnection;
  documents: DocumentConnection;
  portfolios: PortfolioConnection;
  userInvites: Array<UserInvite>;
  users: UserConnection;
  deltaMeters: DeltaMeterConnection;
  transactions: TransactionConnection;
};


export type OrganizationBuildingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type OrganizationDocumentsArgs = {
  deleted?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type OrganizationPermission = {
  __typename: 'OrganizationPermission';
  id: Scalars['ID'];
  organization: Organization;
  role: OrganizationRoleType;
  user: User;
};

export enum OrganizationRoleType {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  DEACTIVATED = 'DEACTIVATED'
}

export type OrganizationConnection = {
  __typename: 'OrganizationConnection';
  pageInfo: PageInfo;
  edges: Array<OrganizationEdge>;
};

export type OrganizationEdge = {
  __typename: 'OrganizationEdge';
  cursor: Scalars['String'];
  node: Organization;
};

export type CreateOrganizationInput = {
  /** organization name */
  name: Scalars['String'];
};

export type CreateOrganizationResponse = {
  __typename: 'CreateOrganizationResponse';
  organization?: Maybe<Organization>;
};

export type DeleteOrganizationInput = {
  organizationId: Scalars['ID'];
};

export type DeleteOrganizationResponse = {
  __typename: 'DeleteOrganizationResponse';
  success: Scalars['Boolean'];
};

export type CreateOrganizationPermissionInput = {
  /** invite token containing UserInviteId */
  token?: Maybe<Scalars['String']>;
  /** the user invite id the user is trying to accept */
  inviteId?: Maybe<Scalars['String']>;
};

export type CreateOrganizationPermissionResponse = {
  __typename: 'CreateOrganizationPermissionResponse';
  organizationPermission?: Maybe<OrganizationPermission>;
};

export type UpdateOrganizationPermissionInput = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: OrganizationRoleType;
};

export type UpdateOrganizationPermissionResponse = {
  __typename: 'UpdateOrganizationPermissionResponse';
  organizationPermission?: Maybe<OrganizationPermission>;
};

export type DeactivateOrganizationPermissionInput = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type DeactivateOrganizationPermissionResponse = {
  __typename: 'DeactivateOrganizationPermissionResponse';
  organizationPermission?: Maybe<OrganizationPermission>;
};

export enum InvoiceStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
  VOID = 'VOID'
}

export enum LedgerOperationType {
  DISTRIBUTION = 'DISTRIBUTION',
  INVOICE_PAYMENT = 'INVOICE_PAYMENT'
}

export type Transaction = {
  __typename: 'Transaction';
  id: Scalars['ID'];
  building: Building;
  deltaMeter: DeltaMeter;
  forecastRates: Array<EnergyRate>;
  invoices: Array<Invoice>;
  ledger: Array<LedgerOperation>;
  notes: Scalars['String'];
};

export type Invoice = {
  __typename: 'Invoice';
  id: Scalars['ID'];
  reference: Scalars['String'];
  deltaMeterStatement: DeltaMeterStatement;
  description?: Maybe<Scalars['String']>;
  dueAt: Scalars['Date'];
  issuedAt: Scalars['Date'];
  lineItems: Array<Invoice_LineItem>;
  /** url to Stripe's Invoice PDF */
  pdf: Scalars['String'];
  recipientName: Scalars['String'];
  status: InvoiceStatus;
  /** Total after tax & discounts. (excludes credits & previous outstanding balances) */
  totalCost: Money;
  /** Amount of money that has been paid towards this invoice. */
  totalPaid: Money;
};

export type Invoice_LineItem = {
  __typename: 'Invoice_LineItem';
  id: Scalars['ID'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Money;
  subtotal: Money;
};

export type LedgerOperation = InvoicePayment | Distribution;

export type InvoicePayment = {
  __typename: 'InvoicePayment';
  id: Scalars['ID'];
  amount: Money;
  date: Scalars['Date'];
  partyName: Scalars['String'];
  invoice: Invoice;
};

export type Distribution = {
  __typename: 'Distribution';
  id: Scalars['ID'];
  amount: Money;
  date: Scalars['Date'];
  partyName: Scalars['String'];
};

export type TransactionConnection = {
  __typename: 'TransactionConnection';
  edges: Array<TransactionEdge>;
};

export type TransactionEdge = {
  __typename: 'TransactionEdge';
  node: Transaction;
};

export type CreateTransactionInput = {
  deltaMeterId: Scalars['ID'];
};

export type CreateTransactionResponse = {
  __typename: 'CreateTransactionResponse';
  transaction: Transaction;
};

export type UpdateTransactionInput = {
  transactionId: Scalars['ID'];
  notes: Scalars['String'];
};

export type UpdateTransactionResponse = {
  __typename: 'UpdateTransactionResponse';
  transaction: Transaction;
};

export type DeleteTransactionInput = {
  transactionId: Scalars['ID'];
};

export type DeleteTransactionResponse = {
  __typename: 'DeleteTransactionResponse';
  id: Scalars['ID'];
};

export type User = {
  __typename: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  superuser: Scalars['Boolean'];
  organization?: Maybe<Organization>;
  organizationPermissions: Array<OrganizationPermission>;
  userInvites: Array<UserInvite>;
  role?: Maybe<OrganizationRoleType>;
  buildingPermissions: Array<BuildingPermission>;
};


export type UserRoleArgs = {
  organizationId: Scalars['ID'];
};


export type UserBuildingPermissionsArgs = {
  organizationId: Scalars['ID'];
};

export type UserInvite = {
  __typename: 'UserInvite';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  organization: Organization;
  organizationName: Scalars['String'];
  role: OrganizationRoleType;
};

export type UserConnection = {
  __typename: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<UserEdge>;
};

export type UserEdge = {
  __typename: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type CreateUserInput = {
  /** token sent on user invite that ensures user has permission to join */
  inviteToken?: Maybe<Scalars['String']>;
  /** full name */
  name: Scalars['String'];
  /** email address */
  email: Scalars['String'];
};

export type CreateUserResponse = {
  __typename: 'CreateUserResponse';
  user?: Maybe<User>;
};

export type UpdateUserInput = {
  userId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateUserResponse = {
  __typename: 'UpdateUserResponse';
  user: User;
};

export type CreateUserInviteInput = {
  /** full name */
  name: Scalars['String'];
  /** email address */
  email: Scalars['String'];
  /** Organization where the user should reside */
  organizationId: Scalars['String'];
  /**
   * desired role of added user
   * defaults to Owner if first user invited to org
   */
  role: OrganizationRoleType;
};

export type CreateUserInviteResponse = {
  __typename: 'CreateUserInviteResponse';
  userInvite?: Maybe<UserInvite>;
};

export type UpdateUserInviteInput = {
  userInviteId: Scalars['String'];
  role?: Maybe<OrganizationRoleType>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateUserInviteResponse = {
  __typename: 'UpdateUserInviteResponse';
  userInvite?: Maybe<UserInvite>;
};

export type DeleteUserInviteInput = {
  userInviteId: Scalars['String'];
};

export type DeleteUserInviteResponse = {
  __typename: 'DeleteUserInviteResponse';
  userInvite?: Maybe<UserInvite>;
};

export type ResendUserInviteInput = {
  userInviteId: Scalars['String'];
};

export type ResendUserInviteResponse = {
  __typename: 'ResendUserInviteResponse';
  userInvite?: Maybe<UserInvite>;
};

export enum MeterSource {
  SOLAR = 'SOLAR',
  GRID = 'GRID'
}

/** UtilityReadingLockReason is an enum of reasons a meter read can no longer be updated. */
export enum UtilityReadingLockReason {
  /** locked because the reading is part of a delta meter reading */
  DELTA_METER_READING = 'DELTA_METER_READING',
  /** locked because the reading is part of the reference period */
  REFERENCE_PERIOD = 'REFERENCE_PERIOD'
}

export type EnergyMeter = {
  energySource: EnergySource;
  readings: EnergyReadingConnection;
  unit: EnergyUnitType;
};

export type EnergyReading = {
  interval: Interval;
  quantity: Scalars['Float'];
  unit: EnergyUnitType;
};

export type EnergyReadingConnection = {
  count: Scalars['Int'];
  edges: Array<EnergyReadingEdge>;
  interval?: Maybe<Interval>;
};

export type EnergyReadingEdge = {
  node: EnergyReading;
};

export type UtilityMeter = EnergyMeter & {
  __typename: 'UtilityMeter';
  id: Scalars['ID'];
  building: Building;
  readings: UtilityMeterReadingConnection;
  externalId?: Maybe<Scalars['String']>;
  energySource: EnergySource;
  utilityName?: Maybe<Scalars['String']>;
  unit: EnergyUnitType;
  alerts: Array<MeterAlert>;
  source: MeterSource;
};

export type UtilityMeterReading = EnergyReading & {
  __typename: 'UtilityMeterReading';
  id: Scalars['ID'];
  utilityMeter: UtilityMeter;
  interval: Interval;
  startOn: Scalars['Date'];
  endOn: Scalars['Date'];
  cost?: Maybe<Money>;
  quantity: Scalars['Float'];
  unit: EnergyUnitType;
  /**
   * indication of whether or not updates should be allowed to this utility meter reading.
   * if not null the enum value indicates why the reading is locked.
   */
  locked?: Maybe<UtilityReadingLockReason>;
};

/**
 * Aggregate meters are used during reference period selection.
 * They represent the combined utility meter data for a building and keep a common interface with
 * UtilityMeterReadings.
 */
export type AggregateMeter = EnergyMeter & {
  __typename: 'AggregateMeter';
  energySource: EnergySource;
  readings: AggregateMeterReadingConnection;
  unit: EnergyUnitType;
};

export type AggregateMeterReading = EnergyReading & {
  __typename: 'AggregateMeterReading';
  interval: Interval;
  quantity: Scalars['Float'];
  unit: EnergyUnitType;
};

export type MeterAlert = ReadingsGapAlert | ReadingsMissingAlert | ReadingsOverlapAlert | ReadingOutlierAlert;

export type ReadingsGapAlert = {
  __typename: 'ReadingsGapAlert';
  interval: Interval;
};

export type ReadingsMissingAlert = {
  __typename: 'ReadingsMissingAlert';
  /** date range over which readings exist. null if no readings on meter. */
  interval?: Maybe<Interval>;
};

export type ReadingsOverlapAlert = {
  __typename: 'ReadingsOverlapAlert';
  interval: Interval;
};

export type ReadingOutlierAlert = {
  __typename: 'ReadingOutlierAlert';
  readingId: Scalars['ID'];
};

export type UtilityMeterReadingConnection = EnergyReadingConnection & {
  __typename: 'UtilityMeterReadingConnection';
  count: Scalars['Int'];
  edges: Array<UtilityMeterReadingEdge>;
  interval?: Maybe<Interval>;
  lastReadOn?: Maybe<Scalars['Date']>;
};

export type UtilityMeterReadingEdge = EnergyReadingEdge & {
  __typename: 'UtilityMeterReadingEdge';
  node: UtilityMeterReading;
};

export type AggregateMeterReadingConnection = EnergyReadingConnection & {
  __typename: 'AggregateMeterReadingConnection';
  count: Scalars['Int'];
  edges: Array<AggregateMeterReadingEdge>;
  interval?: Maybe<Interval>;
};

export type AggregateMeterReadingEdge = EnergyReadingEdge & {
  __typename: 'AggregateMeterReadingEdge';
  node: AggregateMeterReading;
};

export type CreateUtilityMeterInput = {
  buildingId: Scalars['ID'];
  energySource: EnergySource;
  externalId: Scalars['String'];
  unit: EnergyUnitType;
  utilityName?: Maybe<Scalars['String']>;
  source?: Maybe<MeterSource>;
};

export type CreateUtilityMeterResponse = {
  __typename: 'CreateUtilityMeterResponse';
  utilityMeter: UtilityMeter;
};

export type DeleteUtilityMeterInput = {
  utilityMeterId: Scalars['ID'];
};

export type DeleteUtilityMeterResponse = {
  __typename: 'DeleteUtilityMeterResponse';
  utilityMeter?: Maybe<UtilityMeter>;
};

export type UpdateUtilityMeterInput = {
  utilityMeterId: Scalars['ID'];
  energySource?: Maybe<EnergySource>;
  externalId?: Maybe<Scalars['String']>;
  unit?: Maybe<EnergyUnitType>;
  utilityName?: Maybe<Scalars['String']>;
  source?: Maybe<MeterSource>;
};

export type UpdateUtilityMeterResponse = {
  __typename: 'UpdateUtilityMeterResponse';
  utilityMeter: UtilityMeter;
};

export type CreateUtilityMeterReadingInput = {
  utilityMeterId: Scalars['ID'];
  startOn: Scalars['Date'];
  endOn: Scalars['Date'];
  cost?: Maybe<MoneyInput>;
  quantity: Scalars['Float'];
  unit?: Maybe<EnergyUnitType>;
};

export type CreateUtilityMeterReadingResponse = {
  __typename: 'CreateUtilityMeterReadingResponse';
  utilityMeterReading: UtilityMeterReading;
};

export type DeleteUtilityMeterReadingInput = {
  utilityMeterReadingId: Scalars['ID'];
};

export type DeleteUtilityMeterReadingResponse = {
  __typename: 'DeleteUtilityMeterReadingResponse';
  utilityMeterReading?: Maybe<UtilityMeterReading>;
};

export type UpdateUtilityMeterReadingInput = {
  utilityMeterReadingId: Scalars['ID'];
  startOn?: Maybe<Scalars['Date']>;
  endOn?: Maybe<Scalars['Date']>;
  cost?: Maybe<MoneyInput>;
  quantity?: Maybe<Scalars['Float']>;
};

export type UpdateUtilityMeterReadingResponse = {
  __typename: 'UpdateUtilityMeterReadingResponse';
  utilityMeterReading: UtilityMeterReading;
};


export enum EnergySource {
  ELECTRICITY = 'ELECTRICITY',
  NATURAL_GAS = 'NATURAL_GAS',
  FUEL_OIL2 = 'FUEL_OIL2',
  PROPANE = 'PROPANE',
  CHILLED_WATER = 'CHILLED_WATER',
  STEAM = 'STEAM'
}

export enum EnergyUnitType {
  /** watt-hours */
  WH = 'WH',
  /** kilowatt-hours */
  KWH = 'KWH',
  /** megawatt-hours */
  MWH = 'MWH',
  /** therms */
  THERM = 'THERM',
  /** 10 therms */
  DTH = 'DTH',
  /** british thermal units */
  BTU = 'BTU',
  /** 1,000 BTUs */
  KBTU = 'KBTU',
  /** 1 million BTUs */
  MMBTU = 'MMBTU',
  /** cubic feet */
  CF = 'CF',
  /** 100 cubic feet */
  CCF = 'CCF',
  /** 100 cubic feet */
  HCF = 'HCF',
  /** 1,000 cubic feet */
  KCF = 'KCF',
  /** 1,000 cubic feet */
  MCF = 'MCF',
  /** cubic meters */
  M3 = 'M3',
  /** gallons */
  GAL = 'GAL',
  /** "pounds" */
  LBS = 'LBS',
  /** 1,000 "pounds" */
  KLBS = 'KLBS',
  /** 1,000 "pounds" */
  MLBS = 'MLBS',
  /** gigajoules */
  GJ = 'GJ'
}

export enum EnergyEndUse {
  HOT_WATER = 'HOT_WATER',
  LIGHTING = 'LIGHTING',
  SPACE_HEATING = 'SPACE_HEATING',
  SPACE_COOLING = 'SPACE_COOLING',
  INTERIOR_BASELOAD = 'INTERIOR_BASELOAD',
  EXTERIOR_BASELOAD = 'EXTERIOR_BASELOAD'
}

export enum LeaseType {
  SINGLE_NET_LEASE = 'SINGLE_NET_LEASE',
  DOUBLE_NET_LEASE = 'DOUBLE_NET_LEASE',
  TRIPLE_NET_LEASE = 'TRIPLE_NET_LEASE',
  BONDABLE_NET_LEASE = 'BONDABLE_NET_LEASE',
  GROSS_LEASE = 'GROSS_LEASE',
  MODIFIED_GROSS_LEASE = 'MODIFIED_GROSS_LEASE',
  PERCENTAGE_LEASE = 'PERCENTAGE_LEASE',
  OPERATING_AGREEMENT = 'OPERATING_AGREEMENT'
}

export enum Currency {
  USD = 'USD'
}

export type PageInfo = {
  __typename: 'PageInfo';
  startCursor: Scalars['String'];
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
};

export type ReferencePeriod = {
  __typename: 'ReferencePeriod';
  id: Scalars['ID'];
  /** all calibrated models that use this reference period */
  calibratedModels: Array<BuildingCalibratedModel>;
  /** total date range covered by reference period */
  interval: Interval;
};

export type Report = {
  __typename: 'Report';
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type Energy = {
  __typename: 'Energy';
  quantity: Scalars['Float'];
  unit: EnergyUnitType;
};

export type FileAsset = {
  __typename: 'FileAsset';
  id: Scalars['ID'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  key: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
  user?: Maybe<User>;
};


export type FileAssetUrlArgs = {
  preview?: Maybe<Scalars['Boolean']>;
};

export type FileAssetInput = {
  key: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type Money = {
  __typename: 'Money';
  value: Scalars['Float'];
  unit: Currency;
};

export type MoneyInput = {
  value: Scalars['Float'];
  unit: Currency;
};

export type Interval = {
  __typename: 'Interval';
  id: Scalars['ID'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  midpoint: Scalars['Date'];
  daysInPeriod: Scalars['Int'];
};

export type SignedUrlResponse = {
  __typename: 'SignedUrlResponse';
  url: Scalars['String'];
  key: Scalars['String'];
};

export type EnergyRate = {
  __typename: 'EnergyRate';
  energySource: EnergySource;
  /** expected annual increase of rate % for this energy source as a decimal */
  escalation: Scalars['Float'];
  /** price per unit for fuel */
  cost: Money;
  unit: EnergyUnitType;
  /** description of where from EIA.gov this rate came from */
  eiaSource?: Maybe<Scalars['String']>;
};

export type EnergyRateInput = {
  energySource: EnergySource;
  /** annual escalation rate % as a decimal */
  escalation: Scalars['Float'];
  /** price per unit for fuel */
  cost: MoneyInput;
  unit: EnergyUnitType;
  /** description of where from EIA.gov this rate came from */
  eiaSource?: Maybe<Scalars['String']>;
};

export type Weather = {
  __typename: 'Weather';
  date: Scalars['Date'];
  airTemp?: Maybe<Scalars['Float']>;
};

export type WeatherInterval = {
  __typename: 'WeatherInterval';
  interval: Interval;
  airTemp?: Maybe<Scalars['Float']>;
};

/**
 * Creates a new reference period within the start & end bounds
 * and generates system models for that reference period.
 */
export type CreateReferencePeriodInput = {
  buildingId: Scalars['ID'];
  startBound: Scalars['Date'];
  endBound: Scalars['Date'];
};

export type CreateReferencePeriodResponse = {
  __typename: 'CreateReferencePeriodResponse';
  referencePeriod: ReferencePeriod;
  calibratedModel: BuildingCalibratedModel;
};
