import { EnergyEndUse } from "../api/graphql";
import colors from "../ui/colors";
import { assertNever } from "./assertNever";
import sortAlpha from "./sortAlpha";

/**
 * energyEndUses is all the end use enum values, in alphabetical order.
 *
 * typed document node queries preserve the order they are listed on the backend which
 * unnecessarily couples the orderings when looping through the values on the frontend.
 * alphabetizing standardizes the order everywhere they are used here, preventing test failures
 * simply because the backend reorders them in the schema.
 */
export const energyEndUses = Object.values(EnergyEndUse).sort(sortAlpha);

export const endUseName = (code: EnergyEndUse): string => {
  switch (code) {
    case EnergyEndUse.EXTERIOR_BASELOAD:
      return "Exterior Baseload";
    case EnergyEndUse.INTERIOR_BASELOAD:
      return "Interior Baseload";
    case EnergyEndUse.LIGHTING:
      return "Lighting";
    case EnergyEndUse.SPACE_COOLING:
      return "Cooling";
    case EnergyEndUse.SPACE_HEATING:
      return "Heating";
    case EnergyEndUse.HOT_WATER:
      return "Hot Water";
    default:
      assertNever(code);
  }
};

export function energyEndUseColor(endUse: EnergyEndUse): string {
  switch (endUse) {
    case EnergyEndUse.SPACE_HEATING:
      return colors.redAlt.main;
    case EnergyEndUse.SPACE_COOLING:
      return colors.blueAlt.main;
    default:
      return colors.gray.dark;
  }
}
