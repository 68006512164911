import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { CurrentUserModelFragment } from "./api/fragments/CurrentUserModel.generated";
import { signin as signInUrl } from "./lib/endpoints";
import { useSessionManager } from "./SessionManager";

interface Props {
  children:
    | React.ReactNode
    | ((user: CurrentUserModelFragment) => React.ReactNode);
  superuserOnly?: boolean;
}

type AuthenticatedRouteProps = RouteProps & {
  superuserOnly?: boolean;
};

const RequireUser = ({ children, superuserOnly }: Props) => {
  const { user, loggingIn } = useSessionManager();
  const location = useLocation();

  if (user) {
    if (superuserOnly) {
      if (!user.superuser) return <Redirect to="/" />;
    }
    if (typeof children === "function") {
      return <>{children(user)}</>;
    } else {
      return <>{children}</>;
    }
  } else if (loggingIn) {
    return null;
  } else {
    return (
      <Redirect
        to={{ pathname: signInUrl(), state: { then: location.pathname } }}
      />
    );
  }
};

export default RequireUser;

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  children,
  render,
  superuserOnly,
  ...props
}) => {
  if (render && !children) {
    return (
      <Route
        {...props}
        render={(routeProps) => (
          <RequireUser superuserOnly={superuserOnly}>
            {() => render(routeProps)}
          </RequireUser>
        )}
      />
    );
  }

  // TODO: merge SessionWithUser's logic into this file's components
  return (
    <Route {...props}>
      <RequireUser superuserOnly={superuserOnly}>{children}</RequireUser>
    </Route>
  );
};
