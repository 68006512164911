import { useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Divider,
  Link,
  List,
  ListItem as MuiListItem,
  ListItemAvatar,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Skeleton } from "@material-ui/lab";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import displayUserRole from "../lib/displayUserRole";
import { organizationById } from "../lib/endpoints";
import pluralize from "../lib/pluralize";
import { useSessionWithUser } from "../SessionWithUser";
import { SkeletonTypography } from "../ui/Bones";
import DashboardCard from "../ui/DashboardCard";
import MetaData from "../ui/MetaData";
import TextIcon from "../ui/TextIcon";
import { OrgUsersDocument, OrgUsersQuery } from "./OrgUsers.generated";

type User = OrgUsersQuery["organization"]["users"]["edges"][number]["node"];

interface ListItemProps {
  user: User;
}

/**
 * Usually gets the first and last name of a user given the format
 * firstName <space> lastName.
 */
export const avatarInitials = (name: string): string => {
  const split = name.split(" ");

  // If the user has one name, return the first letter
  if (split.length === 1) {
    return split[0][0].toUpperCase();
  }

  // Otherwise return the first letter of the first and the first letter of the last
  return `${split[0][0]}${split[split.length - 1][0]}`.toUpperCase();
};

const ListItem: FC<ListItemProps> = ({ user }) => {
  return (
    <>
      <MuiListItem>
        <ListItemAvatar>
          <Avatar>{avatarInitials(user.name)}</Avatar>
        </ListItemAvatar>
        <Box
          display="flex"
          justifyContent="space-between"
          width={1}
          alignItems="center"
        >
          <Box>
            <Typography>
              <b>{user.name}</b>
            </Typography>
            <MetaData>
              <span>{user.email}</span>
            </MetaData>
          </Box>
          {user.role && (
            <Typography color="textSecondary" variant="body2">
              {displayUserRole(user.role)}
            </Typography>
          )}
        </Box>
      </MuiListItem>
      <Divider />
    </>
  );
};

const OrgUsersTileSkeleton: FC<{}> = () => {
  return (
    <MuiListItem>
      <ListItemAvatar>
        <Skeleton variant="circle">
          <Avatar />
        </Skeleton>
      </ListItemAvatar>
      <div>
        <SkeletonTypography size="small" />
        <SkeletonTypography size="large" />
      </div>
    </MuiListItem>
  );
};

const OrgUsersSkeleton: FC<{}> = () => {
  return (
    <List component={Paper}>
      {[...new Array(3)].map((_, idx, arr) => (
        <React.Fragment key={idx}>
          <OrgUsersTileSkeleton />
          {idx !== arr.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

const OrgUsers: FC<{}> = () => {
  const { organization } = useSessionWithUser();
  const { data, loading, error } = useQuery(OrgUsersDocument, {
    variables: { organizationId: organization.id },
  });

  if (error) throw error;

  if (loading || !data) {
    return <OrgUsersSkeleton />;
  }

  const users = data.organization.users.edges.map((e) => e.node);
  const displayUsers = users.slice(0, 3);
  const remainder = users.length - displayUsers.length;

  return (
    <DashboardCard label="Users">
      <List disablePadding>
        {displayUsers.map((u) => {
          return <ListItem key={u.id} user={u} />;
        })}
        <Box px={2} py={1}>
          <Typography>
            <Link
              component={RouterLink}
              variant="body2"
              to={organizationById({ id: organization.id })}
            >
              {remainder > 0
                ? `${remainder} more ${pluralize("user", remainder)}`
                : "Manage users"}
            </Link>
            <TextIcon icon={<ArrowForwardIcon />} />
          </Typography>
        </Box>
      </List>
    </DashboardCard>
  );
};

export default OrgUsers;
