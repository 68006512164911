import { useMutation } from "@apollo/client";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { transactions } from "../lib/endpoints";
import { useSessionWithUser } from "../SessionWithUser";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import { useToastContext } from "../ui/ToastProvider";
import { DeleteTransactionDocument } from "./DeleteTransaction.generated";

interface Props {
  transactionId: string;
}

const DeleteTransactionButton: FC<Props> = ({ transactionId }) => {
  const [deleteTransaction] = useMutation(DeleteTransactionDocument);
  const { isSuperuser } = useSessionWithUser();
  const createToast = useToastContext();
  const history = useHistory();

  if (!isSuperuser) return null;

  return (
    <ButtonWithConfirmation
      header="Delete Transaction?"
      description="This action cannot be undone"
      onClick={async () => {
        try {
          await deleteTransaction({ variables: { input: { transactionId } } });
          createToast("Transaction was deleted", "success");
          history.push(transactions());
        } catch (err) {
          createToast(err.message, "error");
        }
      }}
    >
      Delete Transaction
    </ButtonWithConfirmation>
  );
};

export default DeleteTransactionButton;
