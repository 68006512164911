import { Link, Typography } from "@material-ui/core";
import React from "react";

const copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    Copyright ©&nbsp;
    <Link color="inherit" href="https://energyrm.com/">
      EnergyRM
    </Link>
    &nbsp;
    {new Date().getFullYear()}
  </Typography>
);

export default copyright;
