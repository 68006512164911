import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import primaryFunctionTitle from "../lib/primaryFunctionTitle";
import sortAlpha from "../lib/sortAlpha";
import MetaData from "../ui/MetaData";
import AccessToggler from "./AccessToggler";
import { OrganizationUserBuildingsQuery } from "./OrganizationUserBuildingsQuery.generated";
import RevokeAccessConfirmation from "./RevokeAccessConfirmation";

export type BuildingPermission = OrganizationUserBuildingsQuery["user"]["buildingPermissions"][number];

interface Props {
  buildingPermissions: BuildingPermission[];
}

const UserBuildings: FC<Props> = ({ buildingPermissions }) => {
  const { formatNumber } = useIntl();

  return (
    <Grid>
      <Paper variant="outlined">
        <List dense>
          {[...buildingPermissions]
            .sort((a, b) => sortAlpha(a.building.name, b.building.name))
            .map((bp) => {
              const { building } = bp;

              return (
                <ListItem key={`buildingPermission-${bp.id}`}>
                  <ListItemText
                    primary={<Typography>{building.name}</Typography>}
                    secondary={
                      <Typography variant="body2">
                        <MetaData>
                          <span>
                            {primaryFunctionTitle(building.buildingUseType)}
                          </span>
                          <span>{`${formatNumber(
                            building.squareFeet
                          )} ft²`}</span>
                          <span>{building.address.region}</span>
                          {building.yearBuilt && (
                            <span>{`Built ${building.yearBuilt}`}</span>
                          )}
                          {building.majorTenant && (
                            <Box
                              component="span"
                              display="inline-flex"
                              alignItems="center"
                            >
                              <PersonPinIcon fontSize="inherit" />
                              {building.majorTenant}
                            </Box>
                          )}
                        </MetaData>
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box display="inline" pr={2}>
                      <AccessToggler permission={bp} />
                    </Box>
                    <RevokeAccessConfirmation permission={bp} />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </Paper>
    </Grid>
  );
};

export default UserBuildings;
