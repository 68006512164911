import React from "react";
import { FormattedDate } from "react-intl";
import pluralize from "../lib/pluralize";
import { MixedReading } from "./UtilityMeter";

type Props = {
  overlapAlerts: MixedReading[];
  timeZone: string;
};

const OverlapAlerts: React.FC<Props> = ({ overlapAlerts, timeZone }) => {
  const firstFew = overlapAlerts.slice(0, 3);
  const remainder = overlapAlerts.length - firstFew.length;

  return (
    <ul>
      {firstFew.map((oa, idx) => (
        <li key={`overlap-alert-${idx}`}>
          <FormattedDate value={new Date(oa.startOn)} timeZone={timeZone} /> -{" "}
          <FormattedDate value={new Date(oa.endOn)} timeZone={timeZone} />
        </li>
      ))}
      {!!remainder && (
        <li>
          And {remainder} {pluralize("other", remainder)}...
        </li>
      )}
    </ul>
  );
};

export default OverlapAlerts;
