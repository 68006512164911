import { Box } from "@material-ui/core";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { TickFormatter } from "@visx/axis/lib/types";
import { RectClipPath } from "@visx/clip-path";
import { curveMonotoneX } from "@visx/curve";
import { GlyphCircle } from "@visx/glyph";
import { Group } from "@visx/group";
import { MarkerCircle } from "@visx/marker";
import { PatternLines } from "@visx/pattern";
import { scaleLinear, scaleTime } from "@visx/scale";
import { AreaStack, Line, LinePath } from "@visx/shape";
import { Accessor, SeriesPoint } from "@visx/shape/lib/types";
import { useTooltip } from "@visx/tooltip";
import { NumberValue } from "d3-scale";
import React from "react";
import { useIntl } from "react-intl";
import { EnergySource, EnergyUnitType } from "../api/graphql";
import formatter from "../lib/formatter";
import minZero from "../lib/minZero";
import unique from "../lib/unique";
import ChartLegend, { ChartLegendItem } from "../ui/charting/ChartLegend";
import ChartTooltip, { TooltipLine } from "../ui/charting/ChartTooltip";
import useChartTooltip from "../ui/charting/useChartTooltip";
import AdjustmentAnnotations, {
  CombinedReadingAdjustment,
} from "./AdjustmentAnnotations";
import AdjustmentTooltip from "./AdjustmentTooltip";
import colors from "./deltameterColors";
import PeriodIndicator from "./PeriodIndicator";

export interface Forecast {
  airTemp: number;
  baseline: number;
  tracker: number | null;
}

export type Reading = Forecast & {
  actual: number;
};

export interface ReportPeriod {
  energySource: EnergySource | null;
  interval: {
    start: string;
    end: string;
    daysInPeriod: number;
  };
  forecast: Forecast;
  reading: Reading | null;
  unit: EnergyUnitType;
  adjustments?: any[];
}

type NumAccessor = Accessor<ReportPeriod, number>;
type SeriesAccessor = Accessor<SeriesPoint<ReportPeriod>, number>;

export interface Props {
  /** Only used for routing from the adjustment annotation */
  buildingId: string;
  data: ReportPeriod[];
  adjustmentData: CombinedReadingAdjustment[];
  width: number;
  height: number;
}

// Legend settings
const legendText = {
  UTILITY_USE_PROJECTED: "Utility use (projected)",
  EE: "Energy efficiency",
  BASELINE_ACTUAL: "Baseline",
  UTILITY_USE_ACTUAL: "Utility use (actual)",
  PROJECTED_EE: "Projected EE",
};

// Axes label config
const tickFormatter: TickFormatter<NumberValue> = (v) =>
  formatter(typeof v === "number" ? v : v.valueOf());

// Date accessor
const dateAccessor: NumAccessor = (d) => new Date(d.interval.end).valueOf();

// Baseline accessors
const getForecastY: NumAccessor = (d) => d.forecast.baseline;
const getPerformanceY: NumAccessor = (d) => d.reading?.baseline || 0;
const getBaselineY: NumAccessor = (d) =>
  d.reading ? d.reading.baseline : d.forecast.baseline;

// Utility accessor
const getProjectedUtilityY: NumAccessor = (d) => {
  if (!!d.reading?.tracker) return d.reading.tracker;
  if (!!d.forecast.tracker) return d.forecast.tracker;
  return 0;
};
const getUtilityY: NumAccessor = (d) => {
  if (!!d.reading) {
    return d.reading.actual;
  }
  if (!!d.forecast.tracker) return d.forecast.tracker;
  return 0;
};

// Utility reading accessors
const y0utility: SeriesAccessor = () => 0;
const y1utility: SeriesAccessor = ({ data }) => getUtilityY(data);

// EE accessors
const y0efficiency: SeriesAccessor = ({ data }) => getUtilityY(data);
const y0projectedEfficiency: SeriesAccessor = ({ data }) =>
  getProjectedUtilityY(data);
const y1efficiency: SeriesAccessor = ({ data }) => getBaselineY(data);

// EE calculation accessors
const actualEE: NumAccessor = (d) =>
  (d.reading?.baseline || 0) - (d.reading?.actual || 0);
const projectedEE = (baseline: number, tracker: number): number =>
  baseline - tracker;

// tooltip data display
const formatTooltipData = (tooltipData: ReportPeriod): TooltipLine[] => {
  const tipLines = [
    {
      label: "Baseline",
      value: `${formatter(getBaselineY(tooltipData))} kBTU`,
      color: colors.blue.main,
    },
  ];

  if (tooltipData.reading) {
    tipLines.push({
      label: "Actual utility use",
      value: `${formatter(getUtilityY(tooltipData))} kBTU`,
      color: colors.yellow,
    });
  }

  if (
    tooltipData.reading?.tracker != null ||
    tooltipData.forecast.tracker != null
  ) {
    tipLines.push({
      label: "Projected utility use",
      value: `${formatter(getProjectedUtilityY(tooltipData))} kBTU`,
      color: colors.red,
    });
  }

  // energy efficiency calculations
  if (tooltipData.reading != null) {
    tipLines.push({
      label: "Energy efficiency",
      value: `${formatter(actualEE(tooltipData))} kBTU`,
      color: colors.green.main,
    });
  } else if (tooltipData.forecast.tracker != null) {
    tipLines.push({
      label: "Projected energy efficiency",
      value: `${formatter(
        projectedEE(tooltipData.forecast.baseline, tooltipData.forecast.tracker)
      )} kBTU`,
      color: colors.green.main,
    });
  }

  return tipLines;
};

// space required for ticks and axis labels
const margin = { top: 5, right: 55, bottom: 55, left: 55 };

const DeltaMeterChart: React.FC<Props> = ({
  data,
  adjustmentData,
  width,
  height,
  buildingId,
}) => {
  // Pad the data with an additional "copy" of the first datapoint (the start point of the DM)
  const fauxReading = {
    ...data[0],
    interval: {
      ...data[0].interval,
      // Set start and end to be the same
      start: data[0].interval.start,
      end: data[0].interval.start,
    },
  };

  data = [fauxReading, ...data];

  // Find the first index of forecast-only data
  const forecastStart = data.findIndex((d) => !d.reading);

  // Capture the performance period
  const performanceData = data.slice(0, forecastStart);

  // Capture the forecast period, including the end of the performance period
  const forecastData = data.slice(forecastStart === 0 ? 0 : forecastStart - 1);

  // Scales
  const xMax = minZero(width - margin.left - margin.right);
  const yMax = minZero(height - margin.top - margin.bottom);

  // Height of the adjustment widget is calculated based off the number of unique energy sources (rows) for the brushes
  const adjustmentsContainerHeight =
    unique(adjustmentData.map((a) => a.energySource)).length * 10;

  // Make space at the bottom for the adjustment widget
  const chartActiveAreaHeight = yMax - adjustmentsContainerHeight;

  const xScale = scaleTime<number>({
    range: [0, xMax],
    domain: [
      Math.min(...data.map(dateAccessor)),
      Math.max(...data.map(dateAccessor)),
    ],
  });

  const yScale = scaleLinear<number>({
    range: [chartActiveAreaHeight, 0], // reverse order, implementation plots range from top to bottom
    domain: [
      0,
      Math.max(...data.map(getForecastY), ...data.map(getPerformanceY)),
    ],
  });

  // Tooltips
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    hideTooltip,
    handleTooltip,
  } = useChartTooltip<ReportPeriod>({
    xScale,
    offset: margin.left,
    data,
    xAccessor: (d) => new Date(d.interval.end).valueOf(),
  });

  const {
    tooltipData: adjustmentTooltipData,
    tooltipLeft: adjustmentTooltipLeft,
    tooltipTop: adjustmentTooltipTop,
    showTooltip: adjustmentShowTooltip,
    hideTooltip: adjustmentHideTooltip,
  } = useTooltip<CombinedReadingAdjustment>();

  const { formatDate } = useIntl();

  const hasTracker = data.some(
    (d) => d.forecast.tracker != null || d.reading?.tracker != null
  );

  const legendItems: ChartLegendItem[] = [];
  if (hasTracker) {
    legendItems.push({
      label: legendText.UTILITY_USE_PROJECTED,
      shape: "line",
      color: colors.red,
      strokeDasharray: "4,3",
    });
  }
  if (performanceData.length) {
    legendItems.push({
      label: legendText.EE,
      shape: "circle",
      color: colors.green.main,
    });
  }
  legendItems.push({
    label: legendText.BASELINE_ACTUAL,
    shape: "circle",
    color: colors.blue.main,
  });
  if (performanceData.length) {
    legendItems.push({
      label: legendText.UTILITY_USE_ACTUAL,
      shape: "circle",
      color: colors.yellow,
    });
  }
  if (hasTracker) {
    legendItems.push({
      label: legendText.PROJECTED_EE,
      shape: (size) => (
        <Group>
          <rect
            x={0}
            y={0}
            width={size}
            height={size}
            fill={colors.green.light}
          />
          <rect
            x={0}
            y={0}
            width={size}
            height={size}
            fill="url(#pattern-lines)"
          />
        </Group>
      ),
    });
  }

  return (
    <div style={{ width, height }}>
      <Box display="flex" justifyContent="center">
        <ChartLegend items={legendItems} />
      </Box>
      {tooltipData && (
        <ChartTooltip
          tooltipLines={formatTooltipData(tooltipData)}
          title={formatDate(new Date(tooltipData.interval.end), {
            timeZone: "UTC",
          })}
          left={tooltipLeft! + margin.left}
          top={tooltipTop}
        />
      )}
      {adjustmentTooltipData && (
        <AdjustmentTooltip
          data={adjustmentTooltipData}
          top={adjustmentTooltipTop}
          left={adjustmentTooltipLeft}
        />
      )}
      <svg width={width} height={height}>
        <PatternLines
          id="pattern-lines"
          height={12}
          width={12}
          stroke="#abdcd0"
          strokeWidth={4}
          orientation={["diagonalRightToLeft"]}
        />
        <PatternLines
          id="gray-pattern-lines"
          height={12}
          width={12}
          stroke={colors.gray.light}
          strokeWidth={4}
          orientation={["diagonalRightToLeft"]}
        />
        <MarkerCircle
          id="mark-projected-utility"
          fill={colors.red}
          size={1.2}
          refX={1.2}
        />
        <MarkerCircle
          id="mark-actual-utility"
          fill={colors.yellow}
          size={1.2}
          refX={1.2}
        />
        <MarkerCircle
          id="mark-baseline"
          fill={colors.blue.main}
          size={1.2}
          refX={1.2}
        />
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={colors.transparent}
        />
        <Group top={margin.top} left={margin.left}>
          <RectClipPath
            id="performance-clip"
            x={0}
            y={0}
            height={chartActiveAreaHeight}
            width={
              performanceData.length > 0
                ? xScale(
                    dateAccessor(performanceData[performanceData.length - 1])
                  )
                : 0
            }
          />
          <RectClipPath
            id="forecast-clip"
            x={
              forecastData.length > 0
                ? xScale(dateAccessor(forecastData[0]))
                : 0
            }
            y={0}
            height={chartActiveAreaHeight}
            width={
              forecastData.length > 0
                ? xMax -
                  xScale(new Date(forecastData[0].interval.end))!.valueOf()
                : 0
            }
          />
          <AreaStack
            top={0}
            left={0}
            data={data}
            keys={["ForecastEE"]}
            x={(d) => xScale(dateAccessor(d.data)) || 0}
            y0={(d) => yScale(y0projectedEfficiency(d)) || 0}
            y1={(d) => yScale(y1efficiency(d)) || 0}
            curve={curveMonotoneX}
          >
            {({ stacks, path }) =>
              stacks.map((stack) =>
                hasTracker ? (
                  <React.Fragment key={`stack-${stack.key}`}>
                    <path
                      d={path(stack) || ""}
                      stroke={colors.transparent}
                      fill={colors.green.light}
                      clipPath="url(#forecast-clip)"
                    />
                    <path
                      d={path(stack) || ""}
                      stroke={colors.transparent}
                      fill="url(#pattern-lines)"
                      clipPath="url(#forecast-clip)"
                    />
                  </React.Fragment>
                ) : (
                  <path
                    key={`stack-${stack.key}`}
                    d={path(stack) || ""}
                    stroke={colors.transparent}
                    fill="url(#gray-pattern-lines)"
                    clipPath="url(#forecast-clip)"
                  />
                )
              )
            }
          </AreaStack>
          <AreaStack
            top={0}
            left={0}
            data={data}
            keys={["EE"]}
            x={(d) => xScale(dateAccessor(d.data)) || 0}
            y0={(d) => yScale(y0efficiency(d)) || 0}
            y1={(d) => yScale(y1efficiency(d)) || 0}
            curve={curveMonotoneX}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack) || ""}
                  stroke={colors.transparent}
                  fill={colors.green.main}
                  clipPath="url(#performance-clip)"
                />
              ))
            }
          </AreaStack>
          <AreaStack
            top={0}
            left={0}
            data={data}
            keys={["Utility"]}
            x={(d) => xScale(dateAccessor(d.data)) || 0}
            y0={(d) => yScale(y0utility(d)) || 0}
            y1={(d) => yScale(y0projectedEfficiency(d)) || 0}
            curve={curveMonotoneX}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack) || ""}
                  stroke={colors.transparent}
                  fill={colors.gray.light}
                  clipPath="url(#forecast-clip)"
                />
              ))
            }
          </AreaStack>
          <AreaStack
            top={0}
            left={0}
            data={data}
            keys={["Utility"]}
            x={(d) => xScale(dateAccessor(d.data)) || 0}
            y0={(d) => yScale(y0utility(d)) || 0}
            y1={(d) => yScale(y1utility(d)) || 0}
            curve={curveMonotoneX}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack) || ""}
                  stroke={colors.transparent}
                  fill={colors.gray.light}
                  clipPath="url(#performance-clip)"
                />
              ))
            }
          </AreaStack>
          <LinePath
            data={data}
            x={(d) => xScale(dateAccessor(d)) || 0}
            y={(d) => yScale(getUtilityY(d)) || 0}
            stroke={colors.yellow}
            strokeWidth="2"
            strokeOpacity="1"
            curve={curveMonotoneX}
            clipPath="url(#performance-clip)"
            markerMid="url(#mark-actual-utility)"
          />
          {hasTracker && (
            <LinePath
              data={data}
              x={(d) => xScale(dateAccessor(d)) || 0}
              y={(d) => yScale(getProjectedUtilityY(d)) || 0}
              stroke={colors.red}
              strokeDasharray="4,3"
              strokeWidth="2"
              strokeOpacity="1"
              curve={curveMonotoneX}
              markerMid="url(#mark-projected-utility)"
            />
          )}
          <LinePath
            data={data}
            x={(d) => xScale(dateAccessor(d)) || 0}
            y={(d) => yScale(getBaselineY(d)) || 0}
            stroke={colors.blue.main}
            strokeWidth="2"
            strokeOpacity="1"
            curve={curveMonotoneX}
            markerMid="url(#mark-baseline)"
          />
          {tooltipData && (
            <Group>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: chartActiveAreaHeight }}
                stroke="black"
                strokeOpacity="0.33"
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="3,2"
              />
              <GlyphCircle
                key={`glyph-tooltip-${Math.random()}`}
                left={tooltipLeft}
                top={yScale(getBaselineY(tooltipData))}
                size={50}
                fill={colors.blue.main}
                strokeWidth={2}
              />
              {hasTracker && (
                <GlyphCircle
                  key={`glyph-tooltip-${Math.random()}`}
                  left={tooltipLeft}
                  top={yScale(getProjectedUtilityY(tooltipData))}
                  size={50}
                  fill={colors.red}
                  strokeWidth={2}
                />
              )}
              {!!tooltipData.reading && (
                <GlyphCircle
                  key={`glyph-tooltip-${Math.random()}`}
                  left={tooltipLeft}
                  top={yScale(getUtilityY(tooltipData))}
                  size={50}
                  fill={colors.yellow}
                  strokeWidth={2}
                />
              )}
            </Group>
          )}
          <AxisLeft
            scale={yScale}
            tickFormat={tickFormatter}
            hideTicks
            label="kBTU / day"
          />
          <AxisBottom scale={xScale} top={yMax} hideAxisLine hideTicks />
          {performanceData.length > 0 && (
            <PeriodIndicator
              x={xScale(dateAccessor(forecastData[0]))}
              chartHeight={chartActiveAreaHeight}
            />
          )}
        </Group>
        {/* Tooltip interaction overlay */}
        <rect
          x={0}
          y={0}
          width={xMax}
          height={chartActiveAreaHeight}
          fill={colors.transparent}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
        {adjustmentData.length > 0 && (
          <AdjustmentAnnotations
            xScale={xScale}
            marginTop={margin.top}
            marginLeft={margin.left}
            adjustmentData={adjustmentData}
            chartHeight={chartActiveAreaHeight}
            buildingId={buildingId}
            containerHeight={adjustmentsContainerHeight}
            onMouseOver={(data, top, left) =>
              adjustmentShowTooltip({
                tooltipData: data,
                tooltipTop: top,
                tooltipLeft: left,
              })
            }
            onMouseOut={() => adjustmentHideTooltip()}
          />
        )}
      </svg>
    </div>
  );
};

export default DeltaMeterChart;
