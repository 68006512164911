import { CardContent, Grid, List } from "@material-ui/core";
import React, { FC } from "react";
import { SkeletonListItem, SkeletonTypography } from "../ui/Bones";
import StripeCard from "../ui/StripeCard";
import { SectionTitle } from "../ui/Typography";

const DocumentSharingSkeleton: FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SectionTitle>Linked users</SectionTitle>
        <StripeCard>
          <CardContent>
            <SkeletonTypography fullWidth />
            <List>
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
            </List>
          </CardContent>
        </StripeCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <SectionTitle>Linked buildings</SectionTitle>
        <StripeCard>
          <CardContent>
            <SkeletonTypography fullWidth />
            <List>
              <SkeletonListItem textOnly />
              <SkeletonListItem textOnly />
              <SkeletonListItem textOnly />
              <SkeletonListItem textOnly />
            </List>
          </CardContent>
        </StripeCard>
      </Grid>
    </Grid>
  );
};

export default DocumentSharingSkeleton;
