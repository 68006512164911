import {
  CircularProgress,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import useTimeout from "../lib/useTimeout";

interface Props {
  variant: "line" | "circle";
  size?: number;
  inline?: boolean;
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    flex: "1 1 auto",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inline: {
    display: "inline",
  },
});

const Loading: React.FC<Props> = ({ variant, size, inline }) => {
  const classes = useStyles();
  const [appear, setAppear] = useState<boolean>(false);
  const timer = useTimeout();

  useEffect(() => {
    timer.start(() => setAppear(true), 500);
  }, [timer]);

  return (
    <div
      className={clsx(classes.container, {
        [classes.centered]: variant === "circle",
        [classes.inline]: inline,
      })}
    >
      {appear &&
        (variant === "circle" ? (
          <CircularProgress size={size} />
        ) : (
          <LinearProgress />
        ))}
    </div>
  );
};

export default Loading;
