import { Box } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import React, { FC, ReactNode } from "react";
import { FormattedNumber } from "react-intl";
import { InvestmentStatus } from "../FinancialModel/finance";
import colors from "../ui/colors";
import { KeyMetricSmall } from "../ui/Typography";

interface Props {
  status: InvestmentStatus;
  irr: number | undefined;
}

export const statusColors: Record<InvestmentStatus, string> = {
  good: colors.green.main,
  warn: colors.orange.main,
  bad: colors.red.main,
};

export const statusIcons: Record<InvestmentStatus, ReactNode> = {
  good: <CheckCircleIcon />,
  warn: <WarningIcon />,
  bad: <CancelIcon />,
};

const IrrStatus: FC<Props> = ({ status, irr }) => {
  return (
    <KeyMetricSmall align="center" style={{ color: statusColors[status] }}>
      <Box
        component="span"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {statusIcons[status]}

        {irr ? (
          <FormattedNumber
            value={irr}
            style={`percent`}
            minimumFractionDigits={2}
          />
        ) : (
          "—%"
        )}
      </Box>
    </KeyMetricSmall>
  );
};

export default IrrStatus;
