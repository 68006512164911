import { BuildingAdjustedModel, BuildingCalibratedModel } from "../api/graphql";

type Model = {
  __typename:
    | BuildingAdjustedModel["__typename"]
    | BuildingCalibratedModel["__typename"];
  default:
    | BuildingAdjustedModel["default"]
    | BuildingCalibratedModel["default"];
};

/**
 * Finds the default model from a list of models.
 *
 * There should only be one, but this will return the first default model from the list.
 */
const findDefaultModel = <T extends Model>(models: T[]): T | undefined => {
  return models.find((m) => !!m.default);
};

export default findDefaultModel;
