import { useMutation } from "@apollo/client";
import { FormHelperText } from "@material-ui/core";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { OrganizationRoleType } from "../api/graphql";
import { presenceError } from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import { SelectFieldController, SelectItem } from "../ui/SelectField";
import { useToastContext } from "../ui/ToastProvider";
import { UpdateOrganizationPermissionDocument } from "./UpdateOrganizationPermission.generated";

interface Props {
  userId: string;
  organizationId: string;
  onCancel: () => void;
}

interface Fields {
  role: OrganizationRoleType | null;
}

const EditUserDialog: FC<Props> = ({ userId, organizationId, onCancel }) => {
  const [updateUserRole] = useMutation(UpdateOrganizationPermissionDocument);
  const createToast = useToastContext();

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm<Fields>({
    defaultValues: {
      role: null,
    },
    resolver: validatorsResolver(({ role }: Fields) => ({
      role: presenceError(role),
    })),
  });

  return (
    <FormDialog
      title="Edit user role"
      submitName="Submit"
      onCancel={() => onCancel()}
      onSubmit={handleSubmit(async ({ role }: Fields) => {
        try {
          await updateUserRole({
            variables: {
              input: {
                userId,
                organizationId,
                role: role as OrganizationRoleType,
              },
            },
            update(cache, { data }) {
              cache.modify({
                id: cache.identify({
                  __typename: "User",
                  id:
                    data?.updateOrganizationPermission.organizationPermission
                      ?.user.id,
                }),
                fields: {
                  role() {
                    return data?.updateOrganizationPermission
                      .organizationPermission?.role;
                  },
                },
              });
            },
          });
          createToast("User role was updated", "success");
          onCancel();
        } catch (err) {
          createToast(err.message, "error");
        }
      })}
      isSubmitting={isSubmitting}
    >
      <SelectFieldController
        label="Select role"
        name="role"
        control={control}
        error={errors.role?.message}
        variant="outlined"
      >
        <SelectItem value={OrganizationRoleType.ADMIN}>Admin</SelectItem>
        <SelectItem value={OrganizationRoleType.MEMBER}>Member</SelectItem>
      </SelectFieldController>
      {watch("role") === OrganizationRoleType.ADMIN && (
        <FormHelperText>
          User will be able to manage all organization users and buildings.
        </FormHelperText>
      )}
      {watch("role") === OrganizationRoleType.MEMBER && (
        <FormHelperText>
          User will only have access to buildings explicitly shared with them.
        </FormHelperText>
      )}
    </FormDialog>
  );
};

export default EditUserDialog;
