import * as Types from '../api/graphql';

import { BuildingModelFragment } from '../api/fragments/BuildingModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { BuildingModelFragmentDoc } from '../api/fragments/BuildingModel.generated';
export type UpdateBuildingMutationVariables = Types.Exact<{
  input: Types.UpdateBuildingInput;
}>;


export type UpdateBuildingMutation = { updateBuilding: { __typename: 'UpdateBuildingResponse', building: (
      { __typename: 'Building' }
      & BuildingModelFragment
    ) } };


export const UpdateBuildingDocument: DocumentNode<UpdateBuildingMutation, UpdateBuildingMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBuilding"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateBuildingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBuilding"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"building"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BuildingModel"}}]}}]}}]}},...BuildingModelFragmentDoc.definitions]};