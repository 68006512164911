import { EnergySource, EnergyUnitType } from "../api/graphql";
import colors, { energySourceColors } from "../ui/colors";
import { assertNever } from "./assertNever";

const energySourceNames: Record<EnergySource, string> = {
  [EnergySource.ELECTRICITY]: "Electric",
  [EnergySource.NATURAL_GAS]: "Natural Gas",
  [EnergySource.CHILLED_WATER]: "Chilled Water",
  [EnergySource.STEAM]: "Steam",
  [EnergySource.PROPANE]: "Propane",
  [EnergySource.FUEL_OIL2]: "Fuel Oil (No. 2)",
};

export const energySourceName = (code: EnergySource): string =>
  energySourceNames[code];

export function energySourceColor(energySource: EnergySource): string {
  switch (energySource) {
    case EnergySource.ELECTRICITY:
      return energySourceColors.ELECTRICITY;
    case EnergySource.NATURAL_GAS:
      return energySourceColors.NATURAL_GAS;
    case EnergySource.PROPANE:
      return energySourceColors.PROPANE;
    default:
      return colors.gray.dark;
  }
}

export function defaultEnergySourceUnit(
  energySource: EnergySource
): EnergyUnitType {
  switch (energySource) {
    case EnergySource.ELECTRICITY:
      return EnergyUnitType.KWH;
    case EnergySource.CHILLED_WATER:
      return EnergyUnitType.KBTU;
    case EnergySource.NATURAL_GAS:
      return EnergyUnitType.THERM;
    case EnergySource.STEAM:
      return EnergyUnitType.LBS;
    case EnergySource.PROPANE:
      return EnergyUnitType.GAL;
    case EnergySource.FUEL_OIL2:
      return EnergyUnitType.GAL;
    default:
      assertNever(energySource);
  }
}
