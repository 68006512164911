import React from "react";
import { FormattedDate } from "react-intl";

interface Props {
  from: Date | number;
  to: Date | number;
  timeZone?: string;
}

const FormattedRange: React.FC<Props> = ({ from, to, timeZone }) => (
  <>
    <FormattedDate value={from} timeZone={timeZone} /> —{" "}
    <FormattedDate value={to} timeZone={timeZone} />
  </>
);

export default FormattedRange;
