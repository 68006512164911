import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";
import React, { FC } from "react";

interface Props {
  x: number | undefined;
  chartHeight: number;
}

const PeriodIndicator: FC<Props> = ({ x, chartHeight }) => {
  return (
    <Group>
      <Text
        x={x}
        y={10}
        fontSize={12}
        fill="black"
        fillOpacity={0.25}
        fontFamily="Arial"
        textAnchor="end"
        dx={-5}
      >
        Performance period
      </Text>
      <Text
        x={x}
        y={10}
        fontSize={12}
        fill="black"
        fillOpacity={0.25}
        fontFamily="Arial"
        textAnchor="start"
        dx={5}
      >
        Forecast
      </Text>
      <Line
        from={{
          x: x,
          y: 0,
        }}
        to={{
          x: x,
          y: chartHeight,
        }}
        stroke="black"
        strokeOpacity={0.25}
        strokeWidth={0.5}
        pointerEvents="none"
      />
    </Group>
  );
};

export default PeriodIndicator;
