import { useQuery } from "@apollo/client";
import { Divider, List } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { buildingById, buildingsList } from "../lib/endpoints";
import pluckNodes from "../lib/pluckNodes";
import sortChronological from "../lib/sortChronological";
import { recentlyViewedBuildings } from "../lib/useRecentlyViewedBuildings";
import { useSessionWithUser } from "../SessionWithUser";
import { SkeletonListItem, SkeletonTypography } from "../ui/Bones";
import Button from "../ui/Button";
import DashboardCard from "../ui/DashboardCard";
import MetaData from "../ui/MetaData";
import { RecentBuildingsDocument } from "./RecentBuildings.generated";
import RecentItemTile from "./RecentItemTile";
import { RecentlyAddedBuildingsDocument } from "./RecentlyAddedBuildings.generated";

const AllBuildingsButton: FC<{}> = () => {
  const history = useHistory();

  return (
    <Button size="small" onClick={() => history.push(buildingsList())}>
      All buildings
    </Button>
  );
};

const RecentBuildingsSkeleton: FC<{}> = () => {
  return (
    <DashboardCard label={<SkeletonTypography size="medium" />}>
      <List>
        <SkeletonListItem />
        <Divider />
        <SkeletonListItem />
        <Divider />
        <SkeletonListItem />
      </List>
    </DashboardCard>
  );
};

export const RecentlyAddedBuildings: FC<{}> = () => {
  const { organization } = useSessionWithUser();
  const { data, loading, error } = useQuery(RecentlyAddedBuildingsDocument, {
    fetchPolicy: "cache-first",
    variables: {
      organizationId: organization.id,
    },
  });

  if (error) throw error;
  if (loading || !data) {
    return <RecentBuildingsSkeleton />;
  }

  const recentBuildings = pluckNodes(data.organization.buildings)
    .sort((a, b) => sortChronological(a.createdAt, b.createdAt, "desc"))
    .slice(0, 3);

  return (
    <DashboardCard
      label="Recently added buildings"
      action={<AllBuildingsButton />}
    >
      <List>
        {recentBuildings.map((rb, idx, arr) => {
          return (
            <div key={rb.id}>
              <RecentItemTile
                title={rb.name}
                metadata={`${rb.address.locality}, ${rb.address.region}`}
                icon={<RoomIcon />}
                to={buildingById({ id: rb.id })}
              />
              {idx !== arr.length - 1 && <Divider />}
            </div>
          );
        })}
      </List>
    </DashboardCard>
  );
};

export const RecentlyViewedBuildings: FC<{
  buildingIds: string[];
}> = ({ buildingIds }) => {
  const { organization } = useSessionWithUser();
  const { data, loading, error } = useQuery(RecentBuildingsDocument, {
    variables: { buildingIds, organizationId: organization.id },
  });

  if (error) throw error;
  if (loading || !data) {
    return <RecentBuildingsSkeleton />;
  }

  const buildings = pluckNodes(data.organization.buildings).slice(0, 3);

  if (buildings.length === 0) {
    return <RecentlyAddedBuildings />;
  }

  return (
    <DashboardCard
      label="Recently viewed buildings"
      action={<AllBuildingsButton />}
    >
      <List>
        {buildings.map((building, idx) => (
          <React.Fragment key={`recently-viewed-${building.id}`}>
            <RecentItemTile
              title={building.name}
              metadata={
                <MetaData>
                  <span>
                    {`${building.address.locality}, ${building.address.region}`}
                  </span>
                </MetaData>
              }
              icon={<RoomIcon />}
              to={buildingById({ id: building.id })}
            />
            {idx !== buildings.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </DashboardCard>
  );
};

const RecentBuildings: FC<{}> = () => {
  const { organization } = useSessionWithUser();
  const recentBuildings = recentlyViewedBuildings(organization.id);

  return (
    <>
      {recentBuildings.length === 0 ? (
        <RecentlyAddedBuildings />
      ) : (
        <RecentlyViewedBuildings buildingIds={recentBuildings} />
      )}
    </>
  );
};

export default RecentBuildings;
