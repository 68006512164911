import React, { useState } from "react";
import Button, { ButtonProps } from "./Button";
import ConfirmationDialog from "./ConfirmationDialog";

type Props = ButtonProps & {
  /** title of the dialog, concise question of what you need confirmed */
  header: string;
  /** optional content explaining decision */
  description?: string;
  onClick: () => void;
};

const ButtonWithConfirmation: React.FC<Props> = ({
  children,
  header,
  description,
  onClick,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const openConfirmation = () => setOpen(true);

  return (
    <>
      <Button {...rest} onClick={openConfirmation}>
        {children}
      </Button>
      {open && (
        <ConfirmationDialog
          onClose={() => setOpen(false)}
          onConfirm={onClick}
          header={header}
          description={description}
        />
      )}
    </>
  );
};

export default ButtonWithConfirmation;
