import { Box, Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { buildingTransaction } from "../lib/endpoints";
import { CardLinkedArea } from "../ui/Card";
import MetaData from "../ui/MetaData";
import StripeCard from "../ui/StripeCard";
import { TransactionsQueryQuery } from "./TransactionsQuery.generated";

interface Props {
  transaction: TransactionsQueryQuery["organization"]["transactions"]["edges"][number]["node"];
}

const TransactionCard: FC<Props> = ({ transaction }) => {
  const { building, deltaMeter } = transaction;

  return (
    <Grid item xs={12}>
      <StripeCard orientation="left">
        <CardLinkedArea
          to={buildingTransaction({
            buildingId: building.id,
          })}
        >
          <Box p={2} display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h6">{building.name}</Typography>
              <MetaData>
                <span>
                  {building.address.locality}, {building.address.region}
                </span>
                <span>DeltaMeter ID: {deltaMeter.shortId}</span>
              </MetaData>
            </Box>
          </Box>
        </CardLinkedArea>
      </StripeCard>
    </Grid>
  );
};

export default TransactionCard;
