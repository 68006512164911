import { Reference, StoreObject, useMutation } from "@apollo/client";
import React, { FC } from "react";
import ButtonWithConfirmation from "../ui/ButtonWithConfirmation";
import { useToastContext } from "../ui/ToastProvider";
import { DeleteDeltaMeterAdjustmentDocument } from "./DeleteDeltaMeterAdjustment.generated";

interface Props {
  readingId: string;
  adjustmentId: string;
}

const DeleteAdjustmentButton: FC<Props> = ({ readingId, adjustmentId }) => {
  const [deleteAdjustment] = useMutation(DeleteDeltaMeterAdjustmentDocument);
  const createToast = useToastContext();

  const onDelete = async (adjustmentId: string) => {
    try {
      await deleteAdjustment({
        variables: {
          input: {
            deltaMeterAdjustmentId: adjustmentId,
          },
        },
        update(cache, { data }) {
          cache.modify({
            id: cache.identify({
              __typename: "DeltaMeterReading",
              id: readingId,
            }),
            fields: {
              adjustments(existingAdjustmentRefs, { readField }) {
                return existingAdjustmentRefs.filter(
                  (adjustmentRef: StoreObject | Reference) =>
                    data?.deleteDeltaMeterAdjustment.deltaMeterAdjustment.id !==
                    readField("id", adjustmentRef)
                );
              },
            },
          });
        },
      });
      createToast("Adjustment was successfully deleted", "success");
    } catch (err) {
      createToast(err.message, "error");
    }
  };

  return (
    <ButtonWithConfirmation
      header="Delete this adjustment?"
      description="This action cannot be undone"
      onClick={() => onDelete(adjustmentId)}
      size="small"
    >
      Delete
    </ButtonWithConfirmation>
  );
};

export default DeleteAdjustmentButton;
