import { useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import sortAlpha from "../lib/sortAlpha";
import { useSessionWithUser } from "../SessionWithUser";
import NewOrganizationDialog from "./NewOrganizationDialog";
import { OrganizationsDocument } from "./OrganizationsQuery.generated";

const useStyles = makeStyles({ orgPickerTitle: { paddingBottom: 0 } });

const Organizations: React.FC = () => {
  const history = useHistory();
  const {
    user,
    organization,
    refetch: refetchMe,
    setOrganization,
    isSuperuser,
  } = useSessionWithUser();
  const { data, refetch: refetchOrgs } = useQuery(OrganizationsDocument, {
    notifyOnNetworkStatusChange: true,
  });
  const [dialog, setDialog] = useState<"add" | "pick" | false>(false);
  const classes = useStyles();

  const refetch = useMemo(() => (user.superuser ? refetchOrgs : refetchMe), [
    user,
    refetchMe,
    refetchOrgs,
  ]);

  // super users can switch to all orgs, otherwise we use the user's org permissions
  const organizations = useMemo(() => {
    const orgs = user.superuser
      ? data?.organizations.edges.map((e) => e.node)
      : user.organizationPermissions.map((p) => p.organization);
    return orgs ? orgs.sort((a, b) => sortAlpha(a.name, b.name)) : [];
  }, [data, user]);

  const currentOrganization = organizations.find(
    (o) => o.id === organization.id
  );

  return (
    <>
      {dialog === "add" && (
        <NewOrganizationDialog
          onCancel={() => setDialog("pick")}
          onComplete={() => {
            refetch();
            setDialog("pick");
          }}
        />
      )}

      {dialog === "pick" && (
        <Dialog
          open
          onClose={() => setDialog(false)}
          aria-labelledby="organization-picker-title"
        >
          <DialogTitle
            id="organization-picker-title"
            className={classes.orgPickerTitle}
          >
            Organizations
          </DialogTitle>
          <List>
            {organizations.map((o) => (
              <ListItem
                button
                key={o.id}
                onClick={() => {
                  setOrganization(o);
                  setDialog(false);
                  history.push("/");
                }}
                aria-label={`switch organization to ${o.name}`}
              >
                <Typography>{o.name}</Typography>
              </ListItem>
            ))}

            {isSuperuser && (
              <ListItem
                component={Button}
                fullWidth
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => setDialog("add")}
                aria-label="add new organization"
              >
                Add
              </ListItem>
            )}
          </List>
        </Dialog>
      )}

      <Button
        color="inherit"
        variant="outlined"
        onClick={() => setDialog("pick")}
      >
        {currentOrganization?.name}
      </Button>
    </>
  );
};

export default Organizations;
