import { useMutation } from "@apollo/client";
import { FormHelperText, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import AuthenticationLayout from "../AuthenticationLayout";
import { useSessionManager } from "../SessionManager";
import Button from "../ui/Button";
import { CreateOrganizationPermissionDocument } from "./CreateOrganizationPermission.generated";
import InvalidTokenDialog from "./InvalidTokenDialog";

interface Props {
  organizationName: string | undefined;
  token?: string;
  inviteId?: string;
}

const useStyles = makeStyles((theme) => ({
  description: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: { margin: theme.spacing(3, 0, 2) },
}));

const AcceptOrgInvite: React.FC<Props> = ({
  organizationName,
  token,
  inviteId,
}) => {
  const history = useHistory();
  // we can't use useSessionWithUser despite a guaranteed user because we are outside the provider
  const { refetch, setOrganization, user } = useSessionManager();
  const [acceptInvite, { error }] = useMutation(
    CreateOrganizationPermissionDocument
  );
  const classes = useStyles();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();

  const doAccept = async () => {
    const result = await acceptInvite({
      variables: { input: inviteId ? { inviteId } : { token } },
    });
    if (result.data?.createOrganizationPermission.organizationPermission) {
      refetch();
      setOrganization(
        result.data.createOrganizationPermission.organizationPermission
          .organization
      );
      history.push("/");
    }
  };

  const allowUserToAccept = organizationName && (token || inviteId);
  return (
    <AuthenticationLayout title="Accept Organization Invite">
      {!allowUserToAccept && <InvalidTokenDialog />}
      <Typography>You are signed in as {user?.name}.</Typography>
      <Typography className={classes.description} align="center">
        You have been invited to join <b>{organizationName}</b> on EnergyRM. By
        accepting this invitation, you will gain access to the organization's
        buildings.
      </Typography>
      <form onSubmit={handleSubmit(doAccept)}>
        {error && (
          <>
            <FormHelperText error>
              {error.graphQLErrors[0].message}
            </FormHelperText>
          </>
        )}
        <Button
          type="submit"
          disabled={isSubmitting}
          fullWidth
          primary
          className={classes.submit}
        >
          Accept Invite
        </Button>
      </form>
    </AuthenticationLayout>
  );
};

export default AcceptOrgInvite;
