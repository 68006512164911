import { useMutation } from "@apollo/client";
import { FormHelperText } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { OrganizationRoleType } from "../api/graphql";
import removeEmpty from "../lib/removeEmpty";
import {
  emailError,
  FieldErrors,
  lengthError,
  presenceError,
} from "../lib/validators";
import validatorsResolver from "../lib/validatorsResolver";
import FormDialog from "../ui/FormDialog";
import { SelectFieldController, SelectItem } from "../ui/SelectField";
import { TextField } from "../ui/TextField";
import { CreateUserInviteDocument } from "./CreateUserInvite.generated";

interface Props {
  onCancel: () => void;
  onComplete: () => void;
  organizationId: string;
}

interface Fields {
  name: string;
  email: string;
  role: OrganizationRoleType;
}

const validations = (values: Fields): FieldErrors<Fields> =>
  removeEmpty({
    name:
      presenceError(values.name) ||
      lengthError(values.name, { min: 3, max: 255 }),
    email: presenceError(values.email) || emailError(values.email),
    role: presenceError(values.role),
  });

const NewUserDialog: React.FC<Props> = ({
  onCancel,
  onComplete,
  organizationId,
}) => {
  const [createUserInvite, { error }] = useMutation(CreateUserInviteDocument);

  const onSubmit = async ({ name, email, role }: Fields) => {
    await createUserInvite({
      variables: {
        input: {
          organizationId,
          name,
          email,
          role: role!,
        },
      },
    });
    onComplete();
  };

  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
    register,
    watch,
  } = useForm<Fields>({
    defaultValues: {
      role: OrganizationRoleType.MEMBER,
    },
    resolver: validatorsResolver(validations),
  });

  const role = watch("role");

  return (
    <FormDialog
      title="Invite user"
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitName="Send invite"
      isSubmitting={isSubmitting}
    >
      {error && (
        <>
          <FormHelperText error>
            {error.graphQLErrors[0].message}
          </FormHelperText>
        </>
      )}

      <TextField
        name="name"
        type="text"
        label="Full Name"
        size="small"
        required
        inputRef={register}
        error={errors.name?.message}
        autoComplete="off"
      />

      <TextField
        name="email"
        type="email"
        label="Email Address"
        size="small"
        required
        inputRef={register}
        error={errors.email?.message}
        autoComplete="off"
      />

      <SelectFieldController
        variant="outlined"
        name="role"
        label="Role"
        control={control}
        error={errors.role?.message}
      >
        <SelectItem value={OrganizationRoleType.ADMIN}>Admin</SelectItem>
        <SelectItem value={OrganizationRoleType.MEMBER}>Member</SelectItem>
      </SelectFieldController>
      {role === OrganizationRoleType.ADMIN && (
        <FormHelperText>
          User will be able to manage all organization users and buildings.
        </FormHelperText>
      )}
      {role === OrganizationRoleType.MEMBER && (
        <FormHelperText>
          User will only have access to buildings explicitly shared with them.
        </FormHelperText>
      )}
    </FormDialog>
  );
};

export default NewUserDialog;
