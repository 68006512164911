import { styled } from "@material-ui/core";
import React from "react";

type Props = {
  className?: string;
  top: React.ReactNode;
  bottom: React.ReactNode;
};

const RatioContainer = styled("span")({
  display: "inline-flex",
  flexDirection: "column",
  justifyItems: "center",
  verticalAlign: "middle",
});

const RatioText = styled("span")({
  fontSize: "smaller",
  textAlign: "center",
});

const RatioDivider = styled("span")({
  borderTop: "1px solid",
  width: "100%",
});

/**
 * Ratio displays a fraction with a top and a bottom.
 */
const Ratio: React.FC<Props> = ({ top, bottom, className }) => (
  <RatioContainer className={className}>
    <RatioText>{top}</RatioText>
    <RatioDivider />
    <RatioText>{bottom}</RatioText>
  </RatioContainer>
);

export default Ratio;
