import { useMutation } from "@apollo/client";
import { Box, Button, CircularProgress, styled } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import React from "react";
import { useHistory } from "react-router-dom";
import { buildingInsights } from "../lib/endpoints";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import { useToastContext } from "../ui/ToastProvider";
import { GenerateBuildingModelsDocument } from "./GenerateBuildingModels.generated";

type Props = {
  buildingId: string;
};

// Copies the dimensions of the submission button
const LoadingButton = styled(Button)({
  width: 230,
  height: 36,
});

const GenerateModels: React.FC<Props> = ({ buildingId }) => {
  const [generateModels, { loading: generateModelsLoading }] = useMutation(
    GenerateBuildingModelsDocument
  );
  const createToast = useToastContext();
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <PlaceholderContainer>
        <PlaceholderSegment
          icon={FolderIcon}
          header="Calibrate models"
          subheader="Generate your first whole-building calibrated energy model after you have finished adding meters and readings."
          buttonComponent={
            <div>
              {generateModelsLoading ? (
                <LoadingButton color="primary" variant="contained" disabled>
                  <CircularProgress size={20} />
                </LoadingButton>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    try {
                      await generateModels({
                        variables: {
                          input: {
                            buildingId: buildingId,
                          },
                        },
                      });

                      createToast("Models were generated", "success");
                      history.push(buildingInsights({ buildingId }));
                    } catch (err) {
                      createToast(err.message, "error");
                    }
                  }}
                >
                  Generate energy model
                </Button>
              )}
            </div>
          }
        />
      </PlaceholderContainer>
    </Box>
  );
};

export default GenerateModels;
