import React, { FC } from "react";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";

const NoLedgerActivity: FC<{}> = () => (
  <PlaceholderContainer>
    <PlaceholderSegment
      header="No ledger activity"
      subheader="This Transaction has no ledger activity yet"
    />
  </PlaceholderContainer>
);

export default NoLedgerActivity;
