import { Box, styled, Typography } from "@material-ui/core";
import millify from "millify";
import React, { FC, ReactNode, ReactText } from "react";
import digits from "../lib/digits";
import colors from "../ui/colors";
import StripeCard from "../ui/StripeCard";
import { KeyMetricLarge } from "../ui/Typography";
import {
  AdjustedModel,
  Building,
  CalibratedModel,
} from "./BuildingInsightsReport";

interface Props {
  building: Building;
  initialModel: CalibratedModel;
  comparisonModel: AdjustedModel;
}

interface MetricProps {
  title: ReactText;
  before: ReactText;
  after: ReactText;
  percentChange: number;
  primaryMetricFormatter: (v: ReactText) => ReactNode;
  subheaderFormatter: (percentChange: number, before: ReactText) => ReactNode;
}

const StyledPercent = styled("span")({
  color: colors.green.main,
});

const GridContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "grid",
  gridGap: spacing(2),
  gridTemplateColumns: "repeat(3, 1fr)",
}));

const GridItem = styled("div")({
  gridColumn: "auto",
});

const Metric: FC<MetricProps> = ({
  title,
  before,
  after,
  percentChange,
  primaryMetricFormatter,
  subheaderFormatter,
}) => {
  return (
    <StripeCard orientation="left">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="overline" color="textSecondary">
          {title}
        </Typography>
        <KeyMetricLarge color="primary">
          {primaryMetricFormatter(after)}
        </KeyMetricLarge>
        <Typography component="span" variant="caption" color="textSecondary">
          {subheaderFormatter(percentChange, before)}
        </Typography>
      </Box>
    </StripeCard>
  );
};

const InsightsKeyMetrics: React.FC<Props> = ({
  building,
  initialModel,
  comparisonModel,
}) => {
  const initialEui =
    initialModel.annualizedEnergy.quantity / building.squareFeet;
  const projectedEui =
    comparisonModel.annualizedEnergy.quantity / building.squareFeet;

  return (
    <GridContainer>
      <GridItem>
        <Metric
          title="Energy efficiency opportunity"
          before={millify(digits(initialModel.annualizedEnergy.quantity, 3))}
          after={millify(
            digits(comparisonModel.opportunity.energy.quantity, 3)
          )}
          percentChange={Math.round(
            comparisonModel.opportunity.energyDepth * 100
          )}
          primaryMetricFormatter={(v) => (
            <>
              {v}{" "}
              <Typography component="span" variant="subtitle1">
                kBTU
              </Typography>
            </>
          )}
          subheaderFormatter={(percent, before) => (
            <Typography component="span" variant="caption">
              <StyledPercent>{percent}% </StyledPercent>

              <Typography component="span" variant="caption">
                of {before} kBTU
              </Typography>
            </Typography>
          )}
        />
      </GridItem>
      <GridItem>
        <Metric
          title="Energy efficiency value"
          before={millify(digits(initialModel.annualizedCost.value, 3))}
          after={millify(digits(comparisonModel.opportunity.money.value, 3))}
          percentChange={Math.round(
            comparisonModel.opportunity.moneyDepth * 100
          )}
          primaryMetricFormatter={(v) => `$${v}`}
          subheaderFormatter={(percent, before) => (
            <Typography component="span" variant="caption">
              <StyledPercent>{percent}% </StyledPercent>

              <Typography component="span" variant="caption">
                of ${before}
              </Typography>
            </Typography>
          )}
        />
      </GridItem>
      <GridItem>
        <Metric
          title="Projected EUI"
          before={Math.round(initialEui)}
          after={Math.round(projectedEui)}
          percentChange={Math.round((1 - projectedEui / initialEui) * 100)}
          primaryMetricFormatter={(v) => (
            <>
              {v}{" "}
              <Typography component="span" variant="subtitle1">
                kBTU/Ft²
              </Typography>
            </>
          )}
          subheaderFormatter={(percent, before) => (
            <>
              Down <StyledPercent>{percent}% </StyledPercent>
              from {before} kBTU/Ft²
            </>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};

export default InsightsKeyMetrics;
