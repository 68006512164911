import * as Types from '../api/graphql';

import { DeltaMeterAdjustmentModelFragment } from '../api/fragments/DeltaMeterAdjustmentModel.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { DeltaMeterAdjustmentModelFragmentDoc } from '../api/fragments/DeltaMeterAdjustmentModel.generated';
export type CreateDeltaMeterAdjustmentMutationVariables = Types.Exact<{
  input: Types.CreateDeltaMeterAdjustmentInput;
}>;


export type CreateDeltaMeterAdjustmentMutation = { createDeltaMeterAdjustment: { __typename: 'CreateDeltaMeterAdjustmentResponse', deltaMeterAdjustment: (
      { __typename: 'DeltaMeterAdjustment' }
      & DeltaMeterAdjustmentModelFragment
    ) } };


export const CreateDeltaMeterAdjustmentDocument: DocumentNode<CreateDeltaMeterAdjustmentMutation, CreateDeltaMeterAdjustmentMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateDeltaMeterAdjustment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateDeltaMeterAdjustmentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDeltaMeterAdjustment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deltaMeterAdjustment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DeltaMeterAdjustmentModel"}}]}}]}}]}},...DeltaMeterAdjustmentModelFragmentDoc.definitions]};