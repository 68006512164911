import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateBuildingAdjustedModelMutationVariables = Types.Exact<{
  input: Types.CreateBuildingAdjustedModelInput;
}>;


export type CreateBuildingAdjustedModelMutation = { createBuildingAdjustedModel: { __typename: 'CreateBuildingAdjustedModelResponse', buildingAdjustedModel: { __typename: 'BuildingAdjustedModel', id: string, name: Types.Maybe<string>, description: Types.Maybe<string>, notes: Types.Maybe<string> } } };


export const CreateBuildingAdjustedModelDocument: DocumentNode<CreateBuildingAdjustedModelMutation, CreateBuildingAdjustedModelMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateBuildingAdjustedModel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateBuildingAdjustedModelInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createBuildingAdjustedModel"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"buildingAdjustedModel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"notes"}}]}}]}}]}}]};