import { ParentSize } from "@visx/responsive";
import React from "react";
import AspectRatio from "../../lib/AspectRatio";

interface ChildProps {
  width: number;
  height: number;
}

interface ResponsiveProps {
  ratio: number;
  children: ({ width, height }: ChildProps) => React.ReactNode;
}

const ResponsiveChart: React.FC<ResponsiveProps> = ({ ratio, children }) => {
  if (typeof children === "function") {
    return (
      <AspectRatio ratio={ratio}>
        <ParentSize>
          {({ width, height }) => <>{children({ width, height })}</>}
        </ParentSize>
      </AspectRatio>
    );
  }
  return <>{children}</>;
};

export default ResponsiveChart;
