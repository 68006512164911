import { Building } from ".";
import fitCompare from "../lib/fitCompare";

export type SortBy =
  | "eePercent"
  | "eeMoney"
  | "eeEnergy"
  | "eeEnergyPerSquareUnit"
  | "eeMoneyPerSquareUnit"
  | "eeMoneyPercent"
  | "buildingSize"
  | "buildingName"
  | "modelFit"
  | "eui";

const buildingSizeSort = (a: Building, b: Building) =>
  b.squareFeet - a.squareFeet;

const eeEnergySort = (a: Building, b: Building) =>
  (b.calibratedModel?.adjustedModel?.opportunity.energy.quantity || 0) -
  (a.calibratedModel?.adjustedModel?.opportunity.energy.quantity || 0);

const eeMoneySort = (a: Building, b: Building) =>
  (b.calibratedModel?.adjustedModel?.opportunity.money.value || 0) -
  (a.calibratedModel?.adjustedModel?.opportunity.money.value || 0);

const eeEnergyPerSquareUnitSort = (a: Building, b: Building) =>
  (b.calibratedModel?.adjustedModel?.opportunity.energyPerSquareFoot.quantity ||
    0) -
  (a.calibratedModel?.adjustedModel?.opportunity.energyPerSquareFoot.quantity ||
    0);

const eeMoneyPerSquareUnitSort = (a: Building, b: Building) =>
  (b.calibratedModel?.adjustedModel?.opportunity.moneyPerSquareFoot.value ||
    0) -
  (a.calibratedModel?.adjustedModel?.opportunity.moneyPerSquareFoot.value || 0);

const eePercentSort = (a: Building, b: Building) =>
  (b.calibratedModel?.adjustedModel?.opportunity.energyDepth || 0) -
  (a.calibratedModel?.adjustedModel?.opportunity.energyDepth || 0);

const eeMoneyPercentSort = (a: Building, b: Building) =>
  (b.calibratedModel?.adjustedModel?.opportunity.moneyDepth || 0) -
  (a.calibratedModel?.adjustedModel?.opportunity.moneyDepth || 0);

const modelFitSort = (a: Building, b: Building) =>
  fitCompare(a.calibratedModel?.fit, b.calibratedModel?.fit) * -10 +
  a.name.localeCompare(b.name);

const euiSort = (a: Building, b: Building) =>
  (b.calibratedModel?.annualizedEnergyPerSquareFoot.quantity || 0) -
  (a.calibratedModel?.annualizedEnergyPerSquareFoot.quantity || 0);

const buildingNameSort = (a: Building, b: Building) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase());

export default function createSortComparator(
  sortBy: SortBy
): (a: Building, b: Building) => number {
  switch (sortBy) {
    case "buildingSize":
      return (a, b) => buildingSizeSort(a, b) || buildingNameSort(a, b);

    case "buildingName":
      return (a, b) => buildingNameSort(a, b);

    case "eeEnergy":
      return (a, b) => eeEnergySort(a, b) || buildingNameSort(a, b);

    case "eeMoney":
      return (a, b) => eeMoneySort(a, b) || buildingNameSort(a, b);

    case "eeEnergyPerSquareUnit":
      return (a, b) =>
        eeEnergyPerSquareUnitSort(a, b) || buildingNameSort(a, b);

    case "eeMoneyPerSquareUnit":
      return (a, b) => eeMoneyPerSquareUnitSort(a, b) || buildingNameSort(a, b);

    case "eeMoneyPercent":
      return (a, b) => eeMoneyPercentSort(a, b) || buildingNameSort(a, b);

    case "eePercent":
      return (a, b) => eePercentSort(a, b) || buildingNameSort(a, b);

    case "modelFit":
      return (a, b) => modelFitSort(a, b) || buildingNameSort(a, b);

    case "eui":
      return (a, b) => euiSort(a, b) || buildingNameSort(a, b);

    // Default to old model fit sort behavior
    default:
      return (a, b) => modelFitSort(a, b) || buildingNameSort(a, b);
  }
}
