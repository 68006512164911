import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { buildingById, buildingsImport } from "../lib/endpoints";
import { useSessionWithUser } from "../SessionWithUser";
import Button from "../ui/Button";
import Loading from "../ui/Loading";
import PlaceholderSegment, {
  PlaceholderContainer,
} from "../ui/PlaceholderSegment";
import { useToastContext } from "../ui/ToastProvider";
import { PageTitle } from "../ui/Typography";
import { AllBuildingsDocument } from "./AllBuildingsQuery.generated";
import { CreateBuildingDocument } from "./CreateBuilding.generated";
import NewBuildingDialog from "./NewBuildingDialog";
import ProspectedBuildingsList from "./ProspectedBuildingsList";

const ProspectingContainer: FC<{}> = () => {
  const history = useHistory();
  const { organization, isOrgAdmin, isSuperuser } = useSessionWithUser();
  const { data, loading, error } = useQuery(AllBuildingsDocument, {
    variables: { organizationId: organization.id },
  });

  const [newBuildingDialogOpen, setNewBuildingDialogOpen] = useState<boolean>(
    false
  );

  const [createBuilding, { error: createBuildingError }] = useMutation(
    CreateBuildingDocument
  );

  const createToast = useToastContext();

  if (loading) return <Loading variant="circle" />;
  if (error) throw error;

  const buildings = data?.organization
    ? data.organization.buildings.edges.map(({ node }) => node)
    : [];

  return (
    <>
      {newBuildingDialogOpen && (
        <NewBuildingDialog
          error={createBuildingError?.graphQLErrors[0]}
          onCancel={() => setNewBuildingDialogOpen(false)}
          onSubmit={async ({
            buildingName,
            primaryUse,
            squareFeet,
            streetAddress,
            city,
            region,
            postalCode,
          }) => {
            try {
              const res = await createBuilding({
                variables: {
                  input: {
                    organizationId: organization.id,
                    name: buildingName,
                    buildingUseType: primaryUse,
                    squareFeet: parseInt(squareFeet, 10),
                    streetAddress,
                    locality: city,
                    region,
                    postalCode,
                    country: "US",
                  },
                },
              });
              setNewBuildingDialogOpen(false);
              history.push(
                buildingById({ id: res.data!.createBuilding.building.id })
              );
            } catch (err) {
              createToast(err.message, "error");
            }
          }}
        />
      )}
      {buildings.length > 0 ? (
        <ProspectedBuildingsList
          onImport={() => history.push(buildingsImport())}
          onAddBuilding={() => setNewBuildingDialogOpen(true)}
          buildings={buildings}
        />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" pb={1}>
            <PageTitle>Buildings</PageTitle>
            {isSuperuser && (
              <Button onClick={() => history.push(buildingsImport())}>
                Import
              </Button>
            )}
          </Box>
          <PlaceholderContainer>
            {isOrgAdmin ? (
              <PlaceholderSegment
                icon={ApartmentIcon}
                header="No buildings"
                subheader="Buildings haven't been added yet"
                buttonText="Add your first building"
                onButtonClick={() => setNewBuildingDialogOpen(true)}
              />
            ) : (
              <PlaceholderSegment
                icon={ApartmentIcon}
                header="No buildings"
                subheader="There are no buildings to view yet"
              />
            )}
          </PlaceholderContainer>
        </>
      )}
    </>
  );
};

export default ProspectingContainer;
