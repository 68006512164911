import { SvgIcon } from "@material-ui/core";
import * as React from "react";

function SvgFuelOil2Filled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21.212 32.697"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.604.002c-.018 0-.035.02-.064.061-.158.228-9.284 16.872-9.517 17.358-.351.734-.653 1.657-.854 2.615-.147.7-.166.925-.169 1.976-.003 1.24.042 1.633.309 2.705.662 2.657 2.442 5.033 4.86 6.485 1.293.776 2.99 1.324 4.481 1.447.819.065 1.031.065 1.82 0 2.564-.213 4.852-1.279 6.683-3.114 1.593-1.598 2.562-3.512 2.977-5.884.121-.693.104-2.633-.029-3.314-.403-2.064-.32-1.892-5.66-11.612A2100.01 2100.01 0 0010.667.062c-.028-.041-.046-.061-.063-.061zm-7.73 22.671l.263 1.417a8.406 8.406 0 01-.224-1.125z" />
    </svg>
  );
}

const FuelOil2FilledIcon = (props) => (
  <SvgIcon component={SvgFuelOil2Filled} viewBox="0 0 35 35" {...props} />
);

export default FuelOil2FilledIcon;
