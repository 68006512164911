import MiscDocIcon from "@material-ui/icons/Description";
import PhotoIcon from "@material-ui/icons/Photo";
import PdfDocIcon from "@material-ui/icons/PictureAsPdf";
import React from "react";

type FileIconProps = { type?: string };

/** Material-UI icon for different kinds of files */
const FileIcon: React.FC<FileIconProps> = ({ type }) => {
  if (!type) {
    return <MiscDocIcon />;
  }
  if (type.startsWith("image")) {
    return <PhotoIcon />;
  }
  if (type === "application/pdf") {
    return <PdfDocIcon />;
  }
  return <MiscDocIcon />;
};

export default FileIcon;
