import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { FC, MouseEvent, useState } from "react";
import { FormattedDate } from "react-intl";
import BuildingDocumentSharing from "../BuildingDocumentCenter/BuildingDocumentSharing";
import DocumentSharing from "../DocumentSharing/DocumentSharing";
import { millifyFilesize } from "../lib/filesize";
import pluralize from "../lib/pluralize";
import Button from "../ui/Button";
import FileIcon from "../ui/FileIcon";
import MetaData from "../ui/MetaData";
import { TablePlaceholder } from "../ui/PlaceholderSegment";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../ui/Table";
import { Document } from "./DocumentCenter";
import FilenamePreviewModalLink from "./FilenamePreview";
import RenameDocumentMenuItem from "./RenameDocumentMenuItem";
import ReplaceDocumentMenuItem from "./ReplaceDocumentMenuItem";
import RestoreFromTrashButton from "./RestoreFromTrashButton";
import TrashDocumentMenuItem from "./TrashDocumentMenuItem";
import useDocumentDownload from "./useDocumentDownload";
import useUserCanModify from "./useUserCanModify";

interface MenuContentProps {
  entity: Document;
  buildingId?: string;
}

const MenuContent: FC<MenuContentProps> = ({ entity, buildingId }) => {
  const canModify = useUserCanModify(entity);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { downloadFile } = useDocumentDownload();
  const [showShareDialog, setShowShareDialog] = useState<boolean>(false);

  const handleClick = (e: MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  if (!canModify) {
    return (
      <Button size="small" onClick={() => downloadFile(entity.id)}>
        Download
      </Button>
    );
  }
  /** It's the building-level repo dialog or the org-level dialog */
  const sharingDialog = buildingId ? (
    <BuildingDocumentSharing
      buildingId={buildingId}
      documentId={entity.id}
      onDismiss={() => {
        setShowShareDialog(false);
        handleClose();
      }}
    />
  ) : (
    <DocumentSharing
      documentId={entity.id}
      onDismiss={() => {
        setShowShareDialog(false);
        handleClose();
      }}
    />
  );

  return (
    <>
      {showShareDialog && sharingDialog}
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} keepMounted onClose={handleClose}>
        <MenuItem
          onClick={(e) => {
            handleClick(e);
            setShowShareDialog(true);
            handleClose();
          }}
        >
          Share
        </MenuItem>
        <RenameDocumentMenuItem doc={entity} />
        <MenuItem onClick={() => downloadFile(entity.id)}>Download</MenuItem>
        <ReplaceDocumentMenuItem doc={entity} />
        <TrashDocumentMenuItem doc={entity} />
      </Menu>
    </>
  );
};

interface DocumentCenterListRowProps {
  document: Document;
  /** Allows us to determine whether we are on the building-level repo */
  buildingId?: string;
}

const DocumentCenterListRow: FC<DocumentCenterListRowProps> = ({
  document,
  buildingId,
}) => {
  const {
    updatedAt,
    buildings,
    file: { mimetype, size },
    users,
    createdBy,
  } = document;
  return (
    <TableRow>
      <TableCell>
        <FileIcon type={mimetype} />
      </TableCell>
      <TableCell>
        <div>
          <FilenamePreviewModalLink doc={document} />
          <MetaData>
            <span>{millifyFilesize(size)}</span>
            <span>Uploaded by {createdBy.name}</span>
          </MetaData>
        </div>
      </TableCell>
      <TableCell>
        <FormattedDate value={updatedAt} />
      </TableCell>
      {!buildingId && (
        <TableCell>
          {buildings.count} {pluralize("building", buildings.count)}
        </TableCell>
      )}
      <TableCell>
        {users.length} {pluralize("user", users.length)}
      </TableCell>
      <TableCell align="right">
        {Boolean(document.deletedAt) ? (
          <RestoreFromTrashButton entity={document} />
        ) : (
          <MenuContent entity={document} buildingId={buildingId} />
        )}
      </TableCell>
    </TableRow>
  );
};

interface Props {
  documents: Document[];
  /** Allows us to determine whether we are on the building-level repo */
  buildingId?: string;
}

const DocumentCenterList: FC<Props> = ({ documents, buildingId }) => {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Last modified</TableCell>
            {!buildingId && <TableCell>Linked buildings</TableCell>}
            <TableCell>Shared with</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.length > 0 ? (
            documents.map((d) => (
              <DocumentCenterListRow
                document={d}
                key={d.id}
                buildingId={buildingId}
              />
            ))
          ) : (
            <TablePlaceholder
              colSpan={6}
              subheader="No results match the selected filter"
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentCenterList;
