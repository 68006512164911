import {
  Box,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@material-ui/core";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ErrorIcon from "@material-ui/icons/Error";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import TuneIcon from "@material-ui/icons/Tune";
import React, { FC, ReactElement, ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { AdjustmentWithReadingModelFragment } from "../api/fragments/AdjustmentWithReadingModel.generated";
import { DeltaMeterModelActivityFragmentFragment } from "../api/fragments/DeltaMeterModelActivityFragment.generated";
import { DeltaMeterStatementModelFragment } from "../api/fragments/DeltaMeterStatementModel.generated";
import { UtilityMeterReadingModelFragment } from "../api/fragments/UtilityMeterReadingModel.generated";
import { ActivityVerb } from "../api/graphql";
import Button from "../ui/Button";
import Card from "../ui/Card";
import InfoDialog from "../ui/InfoDialog";
import { TablePlaceholder } from "../ui/PlaceholderSegment";
import AdjustmentDialog from "./AdjustmentDialog";
import DeltaMeterStatementDialog from "./DeltaMeterStatementDialog";
import UploadStatementPdfButton from "./UploadStatementPdfButton";
import UtilityMeterDialog from "./UtilityMeterDialog";
import VoidStatementButton from "./VoidStatementButton";

export interface Activity {
  __typename: "Activity";
  date: string;
  entity:
    | AdjustmentWithReadingModelFragment
    | DeltaMeterModelActivityFragmentFragment
    | DeltaMeterStatementModelFragment
    | UtilityMeterReadingModelFragment;
  user: { name: string } | null;
  verb: ActivityVerb;
}

export interface Props {
  activities: Activity[];
  timeZone: string;
}

interface ActivityRowProps {
  activity: Activity;
  timeZone: string;
}

type ActivityConfig = Record<
  string,
  {
    icon: ReactElement;
    title: ReactNode;
    crudMap: Record<string, string>;
    actionButtonText: string;
  }
>;

enum CurrentTab {
  ALL,
  UTILITY_UPDATES,
  STATEMENTS,
  DELTAMETER_STATUS,
  DELTAMETER_ADJUSTMENT,
}

const useStyles = makeStyles(({ palette }) => ({
  tableRow: {
    textTransform: "uppercase",
    backgroundColor: palette.grey[300],
  },
  noTransform: {
    textTransform: "none",
  },
}));

const StatusDialog: FC<{
  verb: string;
  date: string;
  timeZone: string;
}> = ({ verb, date, timeZone }) => {
  const { formatDate } = useIntl();

  return (
    <span>
      DeltaMeter was {verb.toLowerCase()} on {formatDate(date, { timeZone })}.
    </span>
  );
};

const activityConfig: ActivityConfig = {
  DeltaMeter: {
    icon: <ErrorIcon color="primary" />,
    title: "DeltaMeter status",
    crudMap: {
      [ActivityVerb.ACTIVATED]: "Activated",
      [ActivityVerb.APPROVED]: "Approved",
      [ActivityVerb.CREATED]: "Created",
      [ActivityVerb.DEACTIVATED]: "Deactivated",
      [ActivityVerb.UPDATED]: "Updated",
      [ActivityVerb.VOIDED]: "Voided",
    },
    actionButtonText: "View",
  },
  DeltaMeterStatement: {
    icon: <MenuBookIcon color="primary" />,
    title: "Statement",
    crudMap: {
      [ActivityVerb.CREATED]: "Generated",
      [ActivityVerb.VOIDED]: "Voided",
      [ActivityVerb.UPDATED]: "Re-generated",
      [ActivityVerb.ACTIVATED]: "Activated",
      [ActivityVerb.APPROVED]: "Approved",
      [ActivityVerb.DEACTIVATED]: "Deactivated",
    },
    actionButtonText: "Details",
  },
  DeltaMeterAdjustment: {
    icon: <TuneIcon color="primary" />,
    title: "Adjustment",
    crudMap: {
      [ActivityVerb.CREATED]: "Created",
    },
    actionButtonText: "View",
  },
  UtilityMeterReading: {
    icon: <EqualizerIcon color="primary" />,
    title: "Utility update",
    crudMap: {
      [ActivityVerb.CREATED]: "Created",
      [ActivityVerb.UPDATED]: "Re-created",
      [ActivityVerb.VOIDED]: "Voided",
      [ActivityVerb.ACTIVATED]: "Activated",
      [ActivityVerb.APPROVED]: "Approved",
      [ActivityVerb.DEACTIVATED]: "Deactivated",
    },
    actionButtonText: "Details",
  },
};

const EntityDialog: FC<{
  activity: Activity;
  onClose: () => void;
  timeZone: string;
}> = ({ activity, onClose, timeZone }) => {
  const { title } = activityConfig[activity.entity.__typename];

  return (
    <InfoDialog
      title={title}
      confirmText="OK"
      width={activity.entity.__typename === "DeltaMeterStatement" ? "md" : "sm"}
      onClose={onClose}
    >
      {activity.entity.__typename === "UtilityMeterReading" ? (
        <UtilityMeterDialog entity={activity.entity} />
      ) : activity.entity.__typename === "DeltaMeter" ? (
        <StatusDialog
          verb={activity.verb}
          date={activity.date}
          timeZone={timeZone}
        />
      ) : activity.entity.__typename === "DeltaMeterAdjustment" ? (
        <AdjustmentDialog entity={activity.entity} />
      ) : activity.entity.__typename === "DeltaMeterStatement" ? (
        <DeltaMeterStatementDialog
          entity={activity.entity}
          timeZone={timeZone}
        />
      ) : (
        <div>...</div>
      )}
    </InfoDialog>
  );
};

const ActivityRow: FC<ActivityRowProps> = ({ activity, timeZone }) => {
  const { formatDate } = useIntl();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { icon, title, crudMap, actionButtonText } = activityConfig[
    activity.entity.__typename
  ];
  const { entity } = activity;

  return (
    <>
      {showDialog && (
        <EntityDialog
          activity={activity}
          timeZone={timeZone}
          onClose={() => setShowDialog(false)}
        />
      )}
      <TableRow>
        <TableCell>
          <Box display="flex" alignItems="center">
            <Box pr={1}>{icon}</Box>
            {entity.__typename === "DeltaMeterStatement"
              ? `${title} (${formatDate(entity.date, { timeZone })})`
              : title}
          </Box>
        </TableCell>
        <TableCell>{crudMap[activity.verb]}</TableCell>
        <TableCell>{formatDate(activity.date, { timeZone })}</TableCell>
        <TableCell>{activity.user?.name}</TableCell>
        <TableCell align="right">
          {entity.__typename === "DeltaMeterStatement" && (
            <>
              <Box display="inline" pr={1}>
                <UploadStatementPdfButton
                  deltaMeterStatementId={entity.id}
                  currentPdf={entity.pdf}
                />
              </Box>
              <Box display="inline" pr={1}>
                <VoidStatementButton deltaMeterStatementId={entity.id} />
              </Box>
            </>
          )}
          <Button size="small" onClick={() => setShowDialog(true)}>
            {actionButtonText}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const ActivityLog: FC<Props> = ({ activities, timeZone }) => {
  const { noTransform, tableRow } = useStyles();
  const [currentTab, setCurrentTab] = useState<CurrentTab>(CurrentTab.ALL);

  const handleTabChange = (_: React.ChangeEvent<{}>, value: CurrentTab) => {
    setCurrentTab(value);
  };

  const filteredActivities = activities
    .filter((a) => a.entity.__typename in activityConfig)
    .filter((a) => {
      switch (currentTab) {
        case CurrentTab.DELTAMETER_STATUS:
          return a.entity.__typename === "DeltaMeter";
        case CurrentTab.STATEMENTS:
          return a.entity.__typename === "DeltaMeterStatement";
        case CurrentTab.UTILITY_UPDATES:
          return a.entity.__typename === "UtilityMeterReading";
        case CurrentTab.DELTAMETER_ADJUSTMENT:
          return a.entity.__typename === "DeltaMeterAdjustment";
        default:
          return true;
      }
    })
    .map((a, idx) => (
      <ActivityRow
        key={`activity-row=${idx}`}
        activity={a}
        timeZone={timeZone}
      />
    ));

  return (
    <Card>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="delta meter tabs"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="All" value={CurrentTab.ALL} className={noTransform} />
        <Tab
          label="Utility updates"
          value={CurrentTab.UTILITY_UPDATES}
          className={noTransform}
        />
        <Tab
          label="Statements"
          value={CurrentTab.STATEMENTS}
          className={noTransform}
        />
        <Tab
          label="DeltaMeter status"
          value={CurrentTab.DELTAMETER_STATUS}
          className={noTransform}
        />
        <Tab
          label="Adjustments"
          value={CurrentTab.DELTAMETER_ADJUSTMENT}
          className={noTransform}
        />
      </Tabs>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow className={tableRow}>
              <TableCell>
                <b>Activity type</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>User</b>
              </TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredActivities.length > 0 ? (
              filteredActivities
            ) : (
              <TablePlaceholder
                colSpan={5}
                subheader="There were no activities found."
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ActivityLog;
