import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { BuildingProductStep } from "../api/graphql";
import { ModeledActionBanner, UnmodeledActionBanner } from "./ActionBanner";
import { DashboardStateDocument } from "./DashboardStateQuery.generated";
import EnergyModels from "./EnergyModels";
import FinancialModels from "./FinancialModels";
import UtilityMeters from "./UtilityMeters";

interface Props {
  buildingId: string;
}

const BuildingDashboard: FC<Props> = ({ buildingId }) => {
  // This query informs the conditional rendering of dependent dashboard queries
  const { data, loading, error } = useQuery(DashboardStateDocument, {
    variables: {
      buildingId,
    },
    fetchPolicy: "network-only",
  });

  // Don't issue a loader for this container query; it will inform the renderable components
  if (error) throw error;
  if (loading || !data) return null;

  const { completedSteps } = data.building;

  return (
    <Grid container spacing={2}>
      {completedSteps.includes(BuildingProductStep.FINANCIAL_MODELS) ||
      completedSteps.includes(BuildingProductStep.DELTA_METER) ? (
        <></>
      ) : completedSteps.includes(BuildingProductStep.ENERGY_MODELS) ? (
        <Grid item xs={12}>
          <ModeledActionBanner buildingId={buildingId} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <UnmodeledActionBanner buildingId={buildingId} />
        </Grid>
      )}
      {completedSteps.includes(BuildingProductStep.FINANCIAL_MODELS) && (
        <Grid item xs={12}>
          <FinancialModels buildingId={buildingId} />
        </Grid>
      )}
      {completedSteps.includes(BuildingProductStep.ENERGY_MODELS) && (
        <Grid item xs={12}>
          <EnergyModels buildingId={buildingId} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <UtilityMeters buildingId={buildingId} />
      </Grid>
      {/* // TODO: add building docs link */}
      {/* // TODO: DeltaMeter component */}
      {/* // TODO: Transactions component */}
    </Grid>
  );
};

export default BuildingDashboard;
