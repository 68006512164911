import { Box, styled } from "@material-ui/core";
import React from "react";
import { BuildingModelParametersFieldsFragment } from "../api/fragments/BuildingModelParametersFields.generated";
import { EnergySourceContribution } from "../api/graphql";
import { energySourceName } from "../lib/energySource";
import { sortBy } from "../lib/sortBy";
import colors from "../ui/colors";
import Ratio from "../ui/Ratio";

type Props = {
  parameters: BuildingModelParametersFieldsFragment;
};

const hoursPerDay = 24;

const Container = styled("div")(({ theme }) => ({
  display: "grid",
  gap: `${theme.spacing(2)}px`,
  gridTemplateColumns: "1fr 1fr",
}));

const EndUse = styled("div")({});

const Header = styled("div")({
  color: colors.blue.dark,
  textTransform: "uppercase",
  fontWeight: "bold",
  flex: "0 0 auto",
});

const Label = styled("div")({
  fontWeight: "bold",
});

const Parameters = styled(Box)(({ theme }) => ({
  display: "grid",
  columnGap: `${theme.spacing(1)}px`,
  gridTemplateColumns: "2.5em 1fr",
}));

const Value = styled("span")({
  textAlign: "right",
  alignSelf: "center",
});

const Key = styled("span")({});

const SourcesGrid: React.FC<{ sources: EnergySourceContribution[] }> = ({
  sources,
}) => (
  <Parameters>
    {sortBy(sources, (s) => s.portion, "desc").map(
      ({ energySource, portion, performance }) => (
        <>
          <Value>{Math.round(portion * 100)}%</Value>
          <Key>
            {energySourceName(energySource)} (COP={performance.toFixed(2)})
          </Key>
        </>
      )
    )}
  </Parameters>
);

const ModelDetails: React.FC<Props> = ({ parameters }) => {
  return (
    <Container>
      <EndUse>
        <Header>Heating</Header>
        <Label>Sources</Label>
        <SourcesGrid sources={parameters.spaceHeating.sources} />
        {parameters.spaceHeating.fanPortion > 0 && (
          <Parameters>
            <Value>
              {Math.round(parameters.spaceHeating.fanPortion * 100)}%
            </Value>
            <Key>Forced Air Fans</Key>
          </Parameters>
        )}
        <br />
        <Label>Control Temperature</Label>
        <Parameters>
          <Value>{parameters.spaceHeating.setTemp.toFixed(1)}</Value>
          <Key>°F</Key>
        </Parameters>
      </EndUse>
      <EndUse>
        <Header>Cooling</Header>
        <Label>Sources</Label>
        <SourcesGrid sources={parameters.spaceCooling.sources} />
        <br />
        <Label>Control Temperature</Label>
        <Parameters>
          <Value>{parameters.spaceCooling.setTemp.toFixed(1)}</Value>
          <Key>°F</Key>
        </Parameters>
      </EndUse>
      <EndUse>
        <Header>Hot Water</Header>
        <Label>Sources</Label>
        <SourcesGrid sources={parameters.hotWater.sources} />
        <br />
        <Label>Control Temperature</Label>
        <Parameters>
          <Value>{parameters.hotWater.setTemp}</Value>
          <Key>°F</Key>
        </Parameters>
        <br />
        <Label>Volume, Daily</Label>
        <Parameters>
          <Value>{(parameters.hotWater.volume * hoursPerDay).toFixed(2)}</Value>
          <Key>
            <Ratio top="gal" bottom="ft²" />
          </Key>
        </Parameters>
      </EndUse>
      <EndUse>
        <Header>Electric Baseload</Header>
        <Label>Interior, Daily</Label>
        <Parameters>
          <Value>{(parameters.lighting * hoursPerDay).toFixed(1)}</Value>
          <Key>
            <Ratio top="Wh" bottom="ft²" /> lighting
          </Key>
          {parameters.baseload.interior?.electricity && (
            <>
              <Value>
                {(
                  parameters.baseload.interior.electricity * hoursPerDay
                ).toFixed(1)}
              </Value>
              <Key>
                <Ratio top="Wh" bottom="ft²" /> plugs
              </Key>
            </>
          )}
        </Parameters>
        <br />
        <Label>Exterior, Daily</Label>
        <Parameters>
          {parameters.baseload.exterior?.electricity && (
            <>
              <Value>
                {(
                  parameters.baseload.exterior.electricity * hoursPerDay
                ).toFixed(1)}
              </Value>
              <Key>
                <Ratio top="Wh" bottom="ft²" /> plugs
              </Key>
            </>
          )}
        </Parameters>
      </EndUse>
      <EndUse>
        <Header>Building Envelope</Header>
        <Label>Transmittance, Daily</Label>
        <Parameters>
          <Value>{(parameters.shellHeatLoss * hoursPerDay).toFixed(1)}</Value>
          <Key>
            <Ratio top="BTU" bottom="ft² • °F" />
          </Key>
        </Parameters>
        {parameters.spaceCooling.performance !== 1 && (
          <>
            <br />
            <Label>Cooling Multiplier</Label>
            {parameters.spaceCooling.performance.toFixed(2)}
          </>
        )}
      </EndUse>
    </Container>
  );
};

export default ModelDetails;
