import { EnergyUnitType } from "../api/graphql";

/**
 * Returns a prettier version of an allcaps unit
 */
const displayUnit = (unit: EnergyUnitType) => {
  switch (unit) {
    case "KBTU":
      return "kBTU";
    case "KWH":
      return "kWh";
    case "WH":
      return "Wh";
    case "KCF":
      return "kCF";
    case "M3":
      return "m³";
    case "LBS":
      return "lbs";
    case "MMBTU":
      return "MMBtu";
    default:
      return unit;
  }
};

export default displayUnit;
