import { Currency, EnergySource, EnergyUnitType } from "../api/graphql";
import Energy from "../lib/Energy";
import sum from "../lib/sum";
import {
  calculateCompoundedPrincipal,
  calculateCompoundedRate,
  calculateInitialInvestment,
  calculateNominalAnnualCashInflow,
} from "./finance";

export type Money = {
  value: number;
  unit: Currency;
};

type Expense = {
  amount: Money;
  escalation: number;
};

export interface DataPoint {
  low: number;
  average: number;
  high: number;
  year: number;
}

export type EnergySourceConfig = {
  source: EnergySource;
  eeQuantity: Energy;
  energyRate: { cost: Money; unit: EnergyUnitType };
  rateEscalation: number;
};

// TODO: Resolve performance issues. This is O(n^2)!
export const calculateCumulativeSum = (data: DataPoint[]): DataPoint[] =>
  data.map((d, idx, arr) => {
    const sliced = arr.slice(0, idx + 1);

    return {
      low: sum(sliced.map((s) => s.low)),
      average: sum(sliced.map((s) => s.average)),
      high: sum(sliced.map((s) => s.high)),
      year: d.year,
    };
  });

/**
 * Calculates raw cash inflows using energy source form data
 */
export const calculateCashInflows = (
  sources: EnergySourceConfig[],
  currentYear: number
): number => {
  return sum(
    sources.map((s) =>
      calculateNominalAnnualCashInflow(
        s.eeQuantity,
        s.energyRate,
        s.rateEscalation,
        currentYear
      )
    )
  );
};

/**
 * Calculates revenue for an investment's single energy source over a number of years
 */
export const calculateRevenue = (
  source: EnergySourceConfig,
  numYears: number
): number => {
  return sum(
    [...new Array(numYears)].map((_, idx) =>
      calculateNominalAnnualCashInflow(
        source.eeQuantity,
        source.energyRate,
        source.rateEscalation,
        idx
      )
    )
  );
};

/**
 * Totals up all expenses, compounded at their escalation rates, for a given term year
 */
export const calculateCashOutFlow = (
  expenses: Expense[],
  year: number
): number => {
  if (!expenses || expenses.length === 0) return 0;
  return sum(
    expenses.map((ex) => {
      // Empty expenses added via fieldArray yield nullish number values
      const amount = ex.amount.value ?? 0;
      const escalation = ex.escalation ?? 0;
      return calculateCompoundedPrincipal(amount, escalation, year);
    })
  );
};

/**
 * Generates a cash flow report
 */
export const createCashFlows = (
  sources: EnergySourceConfig[],
  term: number,
  projectUncertainty: number,
  expenses: Expense[],
  discountRate: number
) => {
  return [...new Array(term < 0 ? 0 : term)].map((_, idx) => {
    const rawCashInFlow = calculateCashInflows(sources, idx);
    const rawCashOutFlow = calculateCashOutFlow(expenses, idx);
    const compoundedDiscountRate =
      // Do not discount in first year
      idx === 0 ? 1 : calculateCompoundedRate(discountRate, idx);

    return {
      low:
        (rawCashInFlow * (1 - projectUncertainty) - rawCashOutFlow) /
        compoundedDiscountRate,
      average: (rawCashInFlow - rawCashOutFlow) / compoundedDiscountRate,
      high:
        (rawCashInFlow * (1 + projectUncertainty) - rawCashOutFlow) /
        compoundedDiscountRate,
      // We index by incrementing one year, because these cash flows occur after year 0, which is the initial investment
      year: idx + 1,
    };
  });
};

export const calculateMaxInitialInvestment = (
  energySourceConfigs: EnergySourceConfig[],
  term: number,
  projectUncertainty: number,
  expenses: Expense[],
  discountRate: number
) => {
  const cashFlows = createCashFlows(
    energySourceConfigs,
    term,
    projectUncertainty,
    expenses,
    0
  ).map((cf) => cf.average);

  return calculateInitialInvestment(discountRate, cashFlows);
};

/**
 * createEnergySourceConfigs will synthesize baseline, depth, and rates data into a useful
 * data structure for further analysis.
 */
export const createEnergySourceConfigs = (
  calibratedModelData: { energySource: EnergySource; baseline: Energy }[],
  scenarioDepths: { energySource: EnergySource; depth: number }[],
  rates: {
    energySource: EnergySource;
    cost: Money;
    unit: EnergyUnitType;
    escalation: number;
  }[]
): EnergySourceConfig[] => {
  return calibratedModelData.map((calibratedModelSource) => {
    const {
      energySource,
      baseline: calibratedModelBaseline,
    } = calibratedModelSource;

    // Target the correct source's rate
    const { cost, unit, escalation } = rates.find(
      (r) => r.energySource === energySource
    )!;

    // Find the depth specified in the depths
    const energySourceDepth = scenarioDepths.find(
      (ed) => ed.energySource === energySource
    )!.depth;

    const energyEfficiency = calibratedModelBaseline.times(energySourceDepth);

    return {
      source: energySource,
      eeQuantity: energyEfficiency,
      energyRate: { cost, unit },
      rateEscalation: escalation,
    };
  });
};
